
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal_assign: false,
    showModal_activity:false,
    showModal_Viewcontact:false,
    demorowId:[],
    rowId:[],
    rowMetadata:[],
    activityrowId:[],
    demolistdata: [],
    startDemodata:[],
    completedDemolist:[],
    deleteDemoRequest:[],
    assigndemolist:[],
    addtechactivity:[],
    getdemocolumn:[],
    gettechielists:[],
    getproductlists:[],
    demorowproducts: [],
    columnlists:[]
}

const DemoSlice = createSlice({
    name: "Demolist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },
         Viewcontact_openModal : (state, action) => {
          state.showModal_Viewcontact = true; 
        },
        Viewcontact_CloseModal: (state, action) => {
          state.showModal_Viewcontact = false; 
        },
        setDemoRowId(state, action) {
          state.demorowId = action.payload.id;
          state.demorowproducts = action.payload.products
        },

        setRowId(state, action) {
          state.rowId = action.payload;
        },
        setrowMetaData(state, action) {
          state.rowMetadata = action.payload;
        },
        addActivityRow(state, action) {
          state.activityrowId = action.payload;
        },
        setdemoListId(state, action) {
          state.demolistid = action.payload;
        },
        

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

   //Demo LIST REDUCERS START HERE
    demolistSuccess(state, action) {
      state.isFetching = false;
      state.error = false;
      state.errorPayload = null;
      state.demolistdata = action.payload;
      // toast.success("demo list data") 
    },
    demolistFailure(state, action) {
      state.isFetching = false;
      state.error = false;
      state.errorPayload = null;
      state.demolistdata = [];
      toast.error("Error in demo list data") 
    },


    startDemoSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.startDemodata = action.payload;
      toast.success("Started") 
    },
    startDemoFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.startDemodata = [];
      toast.error("Error Not start") 
    },

    completedDemolistSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.completedDemolist = action.payload;
      toast.success("completed ") 
    },
    completedDemolistFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.completedDemolist = [];
      toast.error("Error not completed ") 
    },
    deleteDemoRequestSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.deleteDemoRequest = action.payload;;
      toast.success("deleted Demo") 
    },
    deleteDemoRequestFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.deleteDemoRequest = [];
      toast.error("Error not deleted") 
    },
    assignDemolistSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.assigndemolist = action.payload;;
      toast.success("Succesfully Assigned") 
      state.showModal_assign = false;
    },
    assignDemolistFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.assigndemolist = [];
      toast.error("Error Assigned demo") 
      state.showModal_assign = true;
    },
    addTechActivitySuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addtechactivity = action.payload;;
        toast.success("Succesfully Added tech activity ") 
        state.showModal_activity = false;
      },
      addTechActivityFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addtechactivity = [];
        toast.error("Error in tech activity") 
        state.showModal_activity = true;
      },


      //demo get column
      // techie list 
      gettechielistsuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.gettechielists = action.payload;;
        
      },
      gettechielistFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.gettechielists =[];
        
      },
      // product list
      getproductlistsuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.getproductlists = action.payload;
        
      },
      getproductlistFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.getproductlists =[];
        
      },
      getcolumnlistsuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.columnlists = action.payload;
        
      },
      getcolumnlistFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.columnlists =[];
        
      },
      updateColumnsList(state, action){
        state.columnlists = action.payload
      },

  },
  
      
    
});
export const DemoActions = DemoSlice.actions;


//DEMO LIST START HERE

export const getdemolist = (type) => {

    return async (dispatch) => {
      dispatch(DemoActions.loaderStart());
      try {
        await Api.post("/demos/list", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.demolistSuccess(res?.data?.demosList));
          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.demolistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.demolistFailure("Network Error"));
      }
    };
  }; 
  
  export const startDemolist = (type) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/update-timings", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.startDemoSuccess(res.data));

            dispatch(getdemolist({type:"all"}))

          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.startDemoFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.startDemoFailure("Network Error"));
      }
    };
  }; 
  
  
  export const completedDemolist = (type) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/update-timings", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.completedDemolistSuccess(res.data));

            dispatch(getdemolist({type:"all"}))

          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.completedDemolistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.completedDemolistFailure("Network Error"));
      }
    };
  }; 
  export const deleteDemoRequestlist = (type) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/remove", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.deleteDemoRequestSuccess(res.data));

            dispatch(getdemolist({type:"all"}))

          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.deleteDemoRequestFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.deleteDemoRequestFailure("Network Error"));
      }
    };
  }; 
  
  
  
  export const assignDemolist = (updateddata) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/update", updateddata).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.assignDemolistSuccess(res.data));

            dispatch(getdemolist({type:"all"}))

          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.assignDemolistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.assignDemolistFailure("Network Error"));
      }
    };
  };


  export const add_tech_activity = (type) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/add-tech-activity", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.addTechActivitySuccess(res.data));
            dispatch(getdemolist());
          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.addTechActivityFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.addTechActivityFailure("Network Error"));
      }
    };
  }; 

   //techie column
   export const getTechieListdata = () => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
     
        await Api.get("/user/techies-list").then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.gettechielistsuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.gettechielistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.gettechielistFailure("Network Error"));
      }
    };
  };


  export const getproductlistdata = () => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
     
        await Api.get("/products/search").then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.getproductlistsuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.getproductlistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.getproductlistFailure("Network Error"));
      }
    };
  };


  
  export const getColumnlist = (data) => {
    return async (dispatch) => {
      dispatch(DemoActions.loaderStart("meetingLoader"));
      try {
        var userData = JSON.parse(localStorage.getItem("user"))
        await Api.get("/columns-hide-show/list/demos/"+ userData?.user?._id).then((res) => {
          if (res.data.status === "success") {
            dispatch(DemoActions.getcolumnlistsuccess(res?.data?.columnsList.columns_list));
          }
          if (res.data.status === "failure") {
            dispatch(DemoActions.getcolumnlistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(DemoActions.getcolumnlistFailure("Network Error"));
      }
    };
  };


  export default DemoSlice.reducer;
