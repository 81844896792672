import React,{useState,useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
function HardwareUpdateAsset({formupdateData, onInputchange,nextpage,submit,expirydateactivity,dateinputchange}) {
 
    const [TypeList, SetTypeList] = useState([]);
    const [Warrantytype, SetWarrantytype] = useState([]);
    const [Purchaseintype, SetPurchaseintype] = useState([])

    const [Statustype, Setstatustype] = useState([]);

    const getTypelist = () => {
                SetTypeList([{ label: "Laptop", value: "Laptop" }, { label: "Desktop", value: "Desktop" }, { label: "Mobile", value: "Mobile" }, { label: "Mouse", value: "Mouse" },
                { label: "Tab", value: "Tab" }, { label: "Ext Hdd", value: "Ext Hdd" }, { label: "Dongle", value: "Dongle" }, { label: "Sim Card", value: "Sim Card" }, { label: "Pendrive", value: "Pendrive" }])
            }
            const getWarrantytype = () => {
                SetWarrantytype([{ label: "No Warranty", value: "No Warranty" }, { label: "6 Months", value: "6 Months" }, { label: "1 Year", value: "1 Year" }, { label: "2 Year", value: "2 Year" }, { label: "3 Year", value: "3 Year" } ,{ label: "5 Year", value: "5 Year" }])
            }
        
            const getPurchaseintype = () => {
                SetPurchaseintype([{ label: "Amazon", value: "Amazon" }, { label: "Flipkart", value: "Flipkart" }, { label: "Offline", value: "Offline" }])
        
            }
            const getStatustype = () => {
                Setstatustype([{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }, { label: "Damaged", value: "Damaged" }])
            }
            useEffect(() => {
                getTypelist();
                getWarrantytype();
                getPurchaseintype();
                getStatustype();
        
            }, []);
        
const submitformupdatedata = (e) => {
    e.preventDefault();
    if(formupdateData.Type==="Pendrive" || formupdateData.Type==="Mouse" || formupdateData.Type=== "Dongle" || formupdateData.Type=== "Sim Card"){
        submit();

    } else {
        nextpage();
    }

    
}
   
  return (
    <>
    <form onSubmit={submitformupdatedata}>

   

     <div className='row'>
            <div className='col-md-6'>
        <TextField variant="outlined"
               InputLabelProps={{
                shrink: true,
            }}
            select
            required
            value={formupdateData.Type}
            onChange={e => onInputchange(e)}
            size="small"
            type="text" className="form-control" name="Type" label="Type"
          
        >
            {TypeList.map((options) => (
                <MenuItem key={options.value} value={options.value}>
                    {options.label}
                </MenuItem>
            ))
            }

    </TextField>
        </div>
        <div className='col-md-6'>
        <TextField
               variant="outlined"
               name="brand"
             label="Brand"
            value={formupdateData.brand}
            size="small"      
         className="form-control"
        onChange={e => onInputchange(e)}
               />

        </div>
          </div>
          <br/>
           <div className='row'>
            <div className='col-md-6'>
            <TextField
                variant="outlined"
                      name="Model"
                    label="Model"
                    required
                   value={formupdateData.Model}
                  onChange={e => onInputchange(e)}
         
                    size="small"
                    className="form-control"
                 
                        />
                         </div>
                              <div className='col-md-6'>
                            <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formupdateData.Warrantytype}
                                    onChange={e => onInputchange(e)}
                                    // required
                                    select
                                    size="small"
                                    type="text" className="form-control" name="Warrantytype" label="Warranty type"
                                >
                                    {Warrantytype.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }

                                </TextField>
                               </div>
                               </div>
                               <br/>
                               <div className='row'>
                              <div className='col-md-6'>
                              <TextField
                               variant="outlined"
                             name="Serialnumber"
                            label="Serial number"
                            // required
                           value={formupdateData.Serialnumber}
                           size="small"
                           className="form-control"
                           onChange={e => onInputchange(e)}
                                      />
                             </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => onInputchange(e)}
                                    // required
                                    label="Purchase on Date" name="PurchaseDate" className="form-control" id="preliminary-review-purchase_Date"
                                    value={formupdateData.PurchaseDate}   size="small"
                                     />
                                     </div>

                                      </div>
                                       <br/>
                                      <div className='row'>
                                      <div className="col-md-6">
                                      <TextField
                                variant="outlined"
                                name="pointofname"
                                label="Point of Contact Name"
                                // required
        
                                value={formupdateData.pointofname}
                                onChange={e => onInputchange(e)}
                                size="small"       
                                className="form-control"
        
                            />
                                     </div>


                                     <div className="col-md-6">
                                      <TextField variant="outlined"
                                                                    
                                          InputLabelProps={{
                                         shrink: true,
                                              }}
                                        select
                                    //    required
                                    value={formupdateData.Purchasein}
                                    size="small"
                                    onChange={e => onInputchange(e)}
                                    type="text" className="form-control" name="Purchasein" label="Purchase in"
                                >
                                    {Purchaseintype.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>

                                </div>
                                </div>

                                <br/>

                                <div className='row'>
                                <div className='col-md-6'>
                                <TextField
                                variant="outlined"
                                name="pointofcontact"
                                label="Point of contact"
                                // required
        
                                value={formupdateData.pointofcontact}
                                onChange={e => onInputchange(e)}
                                size="small"       
                                className="form-control"
        
                            />
                             
                            </div>
                            <div className='col-md-6'>
                            <TextField
                             variant="outlined"
                            // required
                            name="Cost"
                            label="Cost"
                            size="small"
                            className="form-control"
                            value={formupdateData.Cost}
                            onChange={e => onInputchange(e)}
                             />

                             </div>
                            
                                </div>
                                <br/>
                                <div className='row'>
                                    <div className='col-md-6'>
                                    <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                    }}
                                    select
                                    value={formupdateData.Statustype}
                                    onChange={e => onInputchange(e)}
                                    required
                                    size="small"
                                    type="text" className="form-control" name="Statustype" label="Status Type"
                                >
                                    {Statustype.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                        
                                    </div>
                                    <div className=' col-md-6'>
                                    {formupdateData.Warrantytype === "No Warranty" ? "" : 
                                     <TextField variant="outlined" type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Expiry Date" name="expirydate" className="form-control" id="preliminary-review-expirydate"
                                     value={expirydateactivity.expirydate} size="small"  onChange={e =>  dateinputchange(e)}
        
                                     />
                                    }



                                    </div>


                                </div>
                                <br/>
                                <div className='previos_submit_div1'>
                                {formupdateData.Type ==="Pendrive" || formupdateData.Type==="Dongle" || formupdateData.Type ==="Sim Card" || formupdateData.Type ==="Mouse"?
                                <button className="submitbutton">Submit</button>:<button  className="nextbutton">Next</button>} 
                                 </div>
           
                                 </form>
                                </>
 );
}

export default HardwareUpdateAsset;
