import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment-timezone';
import UpdateTimesheetModal from '../../components/pageModals/UpdateTimesheetModal';
import MaterialUiButton from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { AiFillDelete} from "react-icons/ai";
import { Button } from 'react-bootstrap';
import CreateTimesheetModal from "../../components/pageModals/CreateTimesheetModal";
import { getMuiTheme } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch, connect } from 'react-redux';

import { getTasksList, updateColumns, updateShowModal, updateDailyTask, updateTaskModal, updateViewModal, timesheetActions, getColumnsList } from '../../store/timesheet';

const TimesheetList = React.memo(({tasksList, updateTaskModalData, showUpdateTimeSheetModal, showCreateTimeSheetModal, getTasksData, updateViewModalData, updateColumnsData,updateDailyTaskData, updateShowModalData,isFetching,columnsList, getColumnsListData, updateColumns}) => {

  const [timesheetTypeActive, setTimesheetTypeActive] = useState("Open");
  // const [columnsList, setColumnsList] = useState([]);
  const [defaultTableText, setDefaultTableText] = useState("")
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");
  // const [data, setData] = useState([]);
  // const[taskStatus, setTaskStatus] = useState([])
  // const [progress, setProgress] = useState("Loading...");
  const [teamMembersList,setTeamMembersList]= useState([]);
  const [status, setStatus] = useState("Open")
  // const [showCreateTimesheetModal, setShowCreateTimesheetModal] = useState(false);
  // const [showUpdateTimesheetModal, setShowUpdateTimesheetModal] = useState(false);
  const [timesheetData, setTimesheetData] = useState({
    assignees:"",
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment(new Date()).format('YYYY-MM-DD')
  });
  const { register: timesheetRegister, handleSubmit: setTargetHandleSubmit,  setError: timesheetSetError, reset: reset, formState: { errors: timesheetErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const userRoleData = user.user?.user_role_id?.role_name; 
  const permissions = user.permissions;
  var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  // const getColumnsList = async () => {
  //   const res = await axios.get("/columns-hide-show/list/opportunities/" + userData._id);
  //   setColumnsList(res.data.columnsList ? res.data.columnsList.columns_list : []);
  // }
  const handleRowClick = (rowData, rowMeta) => {
    
    if(tasksList[rowMeta.dataIndex].task_status==="Open"){
      // setShowUpdateTimesheetModal(true);   
      // setRowMetaData();
      // setRowId() 
      
      updateTaskModalData({metaData: tasksList[rowMeta.dataIndex], rowId: tasksList[rowMeta.dataIndex]._id})
    }else{
      // setShowUpdateTimesheetModal(false);
    }
  };


  // useEffect(() => {
  //   console.log("showCreateTimeSheetModal", showCreateTimeSheetModal)
  // }, [showCreateTimeSheetModal])

  const fechData = async () => {
    
    const updatedData = {
      task_status :  status,
      from_date : timesheetData.from_date,
      to_date : timesheetData.to_date,
      filter_user_id : timesheetData.assignees,
      limit:"",
      page_number:""
    }
    getTasksData(updatedData)
    // try {
    //   const res = await axios.post("/task/list", updatedData);
    //   if (res.data.status === "success") {
    //     setData(res.data.tasks_list);
    //     setTaskStatus(res.data.tasks_list)
    //   }
    // } catch (err) {
    //   setProgress("Error in Retrieving Data.");
    // }
  };

  const closeHandle = async (event, data, meta) => {
    event.stopPropagation();

    var updatedData = {
      'task_id': meta,
      'task_status': "Closed",
    }

    updateDailyTaskData(updatedData);

    // try {
    //   await axios.post("task/update", updatedData);
    //   fechData();
    //   handleClick();
    //   setSnackBarMessage("Successfully Closed");
    // } catch (err) {
    //   setOpen(true);
    //   setTimeout(() => {
    //     setOpen(false);
    //   }, 1000);
    // }
  }
  // const deleteHandle = async (e,data, taskId) => {
  //   e.preventDefault();
  //   try {
  //     await axios.delete(`task/delete/${taskId}`);
  //     fechData();
  //     handleClick();
  //     setSnackBarMessage("Successfully deleted");
  //   } catch (error) {
  //     setOpen(true);
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 1000);
  //   }
  // };

  const getAssignedMembersList = async () => {
        
    const res = await axios.get("/user/team-members-list");
    setTeamMembersList(res.data.list);
}


  useEffect(() => {
    getAssignedMembersList();
  }, []);

 useEffect(() => {
  fechData();
},[timesheetData.assignees, status])


  useEffect(() => {
    getColumnsListData();
    
    setSnackBarMessage("Fetching Records...!");
  }, []);


  const onInputChange = (e) => {
    setTimesheetData({ ...timesheetData, [e.target.name]: e.target.value }, []);
  };

  const filterTimesheetType = async (type) => {
    setTimesheetTypeActive(type);
    setStatus(type);
  }

  // useEffect(() => {
  //   setDefaultTableText(props.progress)
  // }, [props.progress])
  /* 

  useEffect(() => {
    axios.get(`/task/user-role/${userData._id}`)
      .then((response) => {
        const role = response.data.userRole;
        setUserRole(role);
      })
      .catch((error) => {
        console.error('Error fetching user role:', error);
      });
  }, [userData._id]);  */

  const headerProps = () => ({
    style: {
      whiteSpace: "nowrap",
      background: "#e6e6e6",
      fontWeight: 'bold'
    }
  })

  const columns = [
    {
      name: "user_id",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.first_name +" "+ value.last_name: "user not found"
        ),
        display: columnsList?.includes('user_id') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "task_title",
      label: "Title",
      options: {
        setCellProps: (value, tableMeta) => (
            {
              style: {
                whiteSpace: "nowrap",
                textColor: '#FFFFFF',
                fontWeight: 'bold',
                width: '500px',
                maxWidth: '500px',
                overflow: 'auto',
                color: '#243d6a',
              }
            }),
            display: true,
            // display: columnsList?.includes('task_title') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
    }
    },
    {
      name: "task_description",
      label: "Description",
      options: {
        setCellProps: (value, tableMeta) => (
            {
              style: {
                whiteSpace: "nowrap",
                textColor: '#FFFFFF',
                fontWeight: 'bold',
                width: '500px',
                maxWidth: '500px',
                overflow: 'auto',
                color: '#243d6a',
              }
            }),
            display: true,
            // display: columnsList?.includes('task_description') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
    }
    },
    {
        name: "start_date_time",
        label: "Task Date",
        options: {
          display: columnsList?.includes('start_date_time') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
          ),
          filter: true,
          setCellHeaderProps: headerProps,
        }
    },
    {
      name: "start_date_time",
      label: "Start Time",
      options: {
        display: columnsList?.includes('start_date_time') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
        name: "end_date_time",
        label: "End Time",
        options: {
          display: columnsList?.includes('end_date_time') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
              value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
          ),
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "task_status",
        label: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          filterOptions: {},
          display: columnsList?.includes('task_status') ? true : false,
          onRowClick: false,
          setCellHeaderProps: headerProps,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "Closed") {
              return (
                <span>Completed</span>
                // <div style={{ display: "flex", justifyContent: 'center' }}>
                //   <a onClick={(e) => { deleteHandle(e, value, tableMeta.rowData[0]) }}>
                    
                //       <AiFillDelete />
                //   </a>
                // </div>
              );
              
              
            } else if (value === "Open") {
              return (
                <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <a style={{ textAlign: 'center' }} onClick={(e) => { closeHandle(e, value, tableMeta.rowData[1]) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center' }} className='badges-md badge-pill badge-success'>Close</span>
                  </a>
                </div>
              );
            } else {
              return (
                <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <a style={{ textAlign: 'center' }} onClick={(e) => { closeHandle(e, value, tableMeta.rowData[1]) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center' }} className='badges-md badge-pill badge-success'>Close</span>
                  </a>
                </div>
              );
            }
          }
        }
      }
    ];

    if (userRoleData === "techie") {
      columns.splice(2, 0, {
        name: "company_name",
        label: "Organization Name",
        options: {
          filter: true,
          display: columnsList?.includes('company_name') ? true : false,
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "white",
              zIndex: 100,
              color: '#243d6a',
              maxWidth: '500px',
              overflow: 'auto',
              fontWeight: 'bold'
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 1,
              background: "#e6e6e6",
              zIndex: 101,
              textColor: '#FFFFFF',
              maxWidth: '500px',
              overflow: 'auto',
            }
          })
        }
      });
    }

    const LoaderText = () => {
      return   isFetching ? <div className="parent-div">
      <div className="loader"></div>
    </div>:"No Data Found.." 
   }

      
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);
      }

      const updatedData = {
        user_id: userData._id,
        table_name: "timesheet",
        columns_list: columns,
      }

      updateColumnsData(updatedData);
      updateColumns(columns)
      // try {
      //   await axios.post("", updatedData);
      //   setSnackBarMessage("Successfully Updated");
      //   handleClick();

      // } catch (err) {
      //   setSnackBarMessage(err.response.data.msg)
      // }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {
        //("item", item)

        const temp = [
          item.data[0] ? item.data[0].first_name : "NA",
          item.data[1] ? item.data[1] : "NA",
          item.data[2] ? item.data[2].name : "NA",
          item.data[3] ? item.data[3] : "NA",
          item.data[4] ? moment(item.data[4].replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('ddd Do MMM YYYY hh:mm a') : "NA",
          item.data[5] ? moment(item.data[5].replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('ddd Do MMM YYYY hh:mm a') : "NA",
          item.data[6] ? moment(item.data[6].replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('ddd Do MMM YYYY hh:mm a') : "NA",
          item.data[7] ? item.data[7] : "NA",
        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val

    },
    download: false,
     onRowClick: handleRowClick,
  };
  
  return (
    <>
      {permissions.includes("createOwnTask") ?
      <>
        <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => updateShowModalData(true)} >Create</Button>
      </>
      : false}
    {permissions.includes("viewTeamMemberTasks") && 
        <div className='assignees_feilds'>
                  <TextField variant="outlined"
                  {...timesheetRegister("assignees", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={timesheetData.assignees}
                  select
                  helperText={timesheetErrors.assignees ? timesheetErrors.assignees.message : ''}
                  error={Boolean(timesheetErrors.assignees)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="assignees" label="Assignees"
                >
                  {teamMembersList?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

               </div>
     }
     <br/> <br/>
       <form onSubmit={setTargetHandleSubmit(fechData)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={timesheetData.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="To Date" name="to_date" className="form-control" value={timesheetData.to_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
      <React.Fragment>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: 'flex', flexDirection: "row" }}><div style={{ display: "flex", flexDirection: "row" }} >
              <b>Clock Time List</b>&nbsp;&nbsp;&nbsp;
            
                <>
                  <span onClick={() => filterTimesheetType("Open")} className={timesheetTypeActive === "Open" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Open </span>&nbsp;&nbsp;
                  <span onClick={() => filterTimesheetType("Closed")} className={timesheetTypeActive === "Closed" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Closed </span>&nbsp;&nbsp;
                  <span onClick={() => filterTimesheetType("Overdue")} className={timesheetTypeActive === "Overdue" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Overdue </span>&nbsp;&nbsp;
                </>
          
            </div>
        
            </div>
            }
            data={tasksList}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </React.Fragment>
        {showCreateTimeSheetModal ? <CreateTimesheetModal ListTimesheetData={timesheetData} status={status} refreshTable={() => fechData() }  show={showCreateTimeSheetModal}  /> : ""}
        {showUpdateTimeSheetModal ? <UpdateTimesheetModal refreshTable={() => fechData() } show={showUpdateTimeSheetModal}   taskId={rowId} metaData={rowMetaData} /> : ""}
    </>
  )
});

const mapStateToProps = (state) => ({
  tasksList : state.timesheet?.tasksList,
  showCreateTimeSheetModal : state.timesheet?.showCreateTimeSheetModal,
  showUpdateTimeSheetModal : state.timesheet?.showUpdateTimeSheetModal,
  isFetching : state.timesheet.isFetching,
  columnsList : state.timesheet.columnsList,
});

const mapDispatchToProps = (dispatch) => ({
  getTasksData : (data) => dispatch(getTasksList(data)),
  updateColumnsData : (data) => dispatch(updateColumns(data)),
  updateShowModalData : (data) => dispatch(updateShowModal(data)),
  updateDailyTaskData : (data) => dispatch(updateDailyTask(data)),
  updateViewModalData : (data) => dispatch(updateViewModal(data)),
  updateTaskModalData : (data) => dispatch(timesheetActions.updateViewModalDat(data)),
  getColumnsListData : () => dispatch(getColumnsList()),
  updateColumns: (data) => dispatch(timesheetActions.updateColumns(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetList);

