import React, { useState, useRef, useEffect } from 'react'; // Add these imports

import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./login.css";
import { useDispatch, useSelector } from 'react-redux';
import { loginCall, LoginButtonLoaderStart } from '../../../store/auth';
import { useHistory, Redirect } from 'react-router-dom';

export default function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const email = useRef();
  const password = useRef();
  const { user, isFetching, error, errorPayload, loginStatus } = useSelector(state => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(LoginButtonLoaderStart());
    dispatch(loginCall({ email: email.current.value, password: password.current.value }));
  }

  useEffect(() => {
    if (loginStatus) {
      history.push("/dashboard");
      window.location.reload();
      // return <Redirect to="/dashboard" />;
    }
  }, [loginStatus]);

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginLeft" style={{ marginLeft: '-60px' }}>
          <h3 className="loginLogo">KaizenForce</h3>
          <span className="loginDesc">
            Connect with Colleagues and the world around you on Kaizenforce.
          </span>
        </div>

        <div className="loginRight" style={{ marginLeft: '15px' }}>
          <form className="loginBox" onSubmit={handleClick}>
            <div className="text-center">
              <h2>User Login</h2>
            </div>
            <input placeholder="Email" type="email" required className="loginInput" ref={email} />
            <div className="password-input">
              <input
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                required
                minLength="6"
                className="loginInput password-field" // Add "password-field" class
                ref={password}
              />
              {/* Eye icon inside the password input field */}
              <div className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>
            <a href="/forgot-password" className="forgotPassword">
              Forgot Password?
            </a>
            <div className="text-center" data-testid="error">
              <span style={{ color: 'red' }}>{errorPayload}</span>
            </div>
            <br />
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (
                <CircularProgress color="white" size="20px" />
              ) : (
                "Log In"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}