import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useSelector } from 'react-redux';
import {contactcreatedata,RawdataActions} from "../../store/Rawdatabase"



  const RawDataAddNewContact = React.memo (({contactcreate,openshowModal,closeshowmodal,Rowid}) => {
  const [contactShow, setContactShow] = useState(false);
  var [deleteClicked, setDeleteClicked] = useState("notClicked");
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [industriesList, setIndustriesList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [contact, setContact] = useState({
    co_company_name: "",
    co_contact_name: "",
    co_contact_number: "",
    co_email: "",
    co_designation: "",
    co_department: "",
    co_industry: "",
    account_id:""
  });

  var [contact_id, setContactId] = useState({
    contact_id: ""
  });
  const { register: contactRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: contactErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const { co_company_name, co_contact_name, co_contact_number, co_email, co_designation, co_department, co_industry } = contact;
  const getDetailsHandle = async (id) => {
    const res = await axios.get("/user/rawdata/details/" + id);
    const contactData = res.data.rawdataDetail;

    setContact({
      co_company_name: contactData.company_name,
      co_contact_name: contactData.contact_person_name,
      co_contact_number: contactData.mobile,
      co_email: contactData.email,
      co_designation: contactData.designation,
      co_department: contactData.department,
      co_industry: contactData.industry,
      account_id:contactData.account_id
    });
    setContactId({ contact_id: id });

    if (deleteClicked === "notClicked") {
      setContactShow(true);
    }

  };
  const onInputChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value }, []);
  };
  const getIndustriesList = async () => {
    const res = await axios.get("/industries/search");
    setIndustriesList(res.data.industriesList);

  }

  const UpdateContactHandle = async (e) => {
    setDisable(true);
    const createdData = {
      account_id: contact.account_id,
      user_id: userData._id,
      company_name: contact.co_company_name,
      contact_name: contact.co_contact_name,
      contact_number: contact.co_contact_number,
      email: contact.co_email,
      designation: contact.co_designation,
      department: contact.co_department,
      user_id: userData._id,
      industry: contact.co_industry
    }

    contactcreate(createdData)
    
  }
 
  useEffect(() => {

    getDetailsHandle(Rowid);
  }, [Rowid])

  useEffect(() => {
    getIndustriesList();
  }, [])

  return (
    <>
      <Modal
      show={() => openshowModal()}
      onHide={() => closeshowmodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Add Contact"}
          </Modal.Title>
          <a onClick={() => closeshowmodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={contactHandleSubmit(UpdateContactHandle)}>
            <div className="row headerBand">
              <label>Organization Details</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("op_company_name", {
                    minLength: {
                      value: 3,
                      message: "Organization Name must be at least 3 characters"
                    }
                  })}
                  inputProps={
                    { readOnly: true, }
                  }

                  required
                  helperText={contactErrors.op_company_name ? contactErrors.op_company_name.message : ''}
                  error={Boolean(contactErrors.op_company_name)}
                  type="text" className="form-control" value={co_company_name} name="co_company_name" label="Organization Name" onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_contact_name", {
                    minLength: {
                      value: 3,
                      message: "Contact Name must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={contactErrors.co_contact_name ? contactErrors.co_contact_name.message : ''}
                  error={Boolean(contactErrors.co_contact_name)}
                  type="text" className="form-control" value={co_contact_name} name="co_contact_name" label="Contact Name" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_email", {
                    minLength: {
                      value: 3,
                      message: "Email must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={contactErrors.co_email ? contactErrors.co_email.message : ''}
                  error={Boolean(contactErrors.co_email)}
                  label="Email" name="co_email" className="form-control" value={co_email} onChange={e => onInputChange(e)} />
              </div>

              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_contact_number", {
                    minLength: {
                      value: 6,
                      message: "Contact Number must be at least 6 characters"
                    },
                    maxLength: {
                      value: 13,
                      message: "Contact Number cannot exceed 13 digits"
                    }
                  })}
                  required
                  helperText={contactErrors.co_contact_number ? contactErrors.co_contact_number.message : ''}
                  error={Boolean(contactErrors.co_contact_number)}
                  type="number" className="form-control" value={co_contact_number} name="co_contact_number" label="Contact Number" onChange={e => onInputChange(e)} />
              </div>


            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_designation", {
                    minLength: {
                      value: 3,
                      message: "Designation must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={contactErrors.co_designation ? contactErrors.co_designation.message : ''}
                  error={Boolean(contactErrors.co_designation)}
                  type="text" label="Designation" name="co_designation" className="form-control" value={co_designation} onChange={e => onInputChange(e)} />
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_department", {
                    minLength: {
                      value: 3,
                      message: "Department must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={contactErrors.co_department ? contactErrors.co_department.message : ''}
                  error={Boolean(contactErrors.co_department)}
                  type="text" label="Department" name="co_department" className="form-control" value={co_department} onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_industry", {
                    minLength: {
                      value: 1,
                      message: "Industry must be at least 1 characters"
                    }
                  })}
                  required
                  helperText={contactErrors.co_industry ? contactErrors.co_industry.message : ''}
                  error={Boolean(contactErrors.co_industry)}
                  select
                  type="text" label="Industry" name="co_industry" className="form-control"
                  value={co_industry} onChange={e => onInputChange(e)} >

                  {
                    industriesList.map((option, da_industry) => (
                      <MenuItem key={option.value} selected={da_industry === option.value} value={option.value}>

                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            </div>

            <br />
            <div className="row">
                <div >
                    <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    
    </>
  )

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    userVariable: state.auth,
    meetinglistdata:state.meetinglist.meetinglistdata,
    Meeting_columns : state.meetinglist.Meeting_columns,
    isFetching : state.meetinglist.isFetching,
    Rowid : state.Rawdatalist.rowId, 
});


const mapDispatchToProps = (dispatch) => ({

  contactcreate:(data) => dispatch(contactcreatedata(data)),
  openshowModal:(data) => dispatch(RawdataActions.contactmodal_openModal(data)),
  closeshowmodal:(data) => dispatch(RawdataActions.contactmodal_closeModal(data)),

});
export default connect(mapStateToProps,mapDispatchToProps) (RawDataAddNewContact)
