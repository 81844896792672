import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import HardwareUpdateAsset from './HardwareUpdateAsset';
import HardwareUpdateConfig from './HardwareUpdateConfig';
import moment from "moment";
import { connect, useDispatch } from 'react-redux';
import {updatehardwarelist,HardwareActions} from "../../store/Hardware"

const  HardwareUpdateModal = React.memo(({updatehardwarelist,gethardwarelist,user,hardwarelist,openshowModal,closemodal,metaData,rowId}) => {

  const dispatch = useDispatch();
  const[Hardwareupdateassetpage,SetHardwareupdateassetpage] = useState(0);
  // const [open, setOpen] = useState(false);
  const updatepagetitle =[<div className='updatelabels'> Hardware Update Assets Details </div>, <div className='updatelabels2'> Hardware Update Config </div>]
    const[formupdateData,Setformupdatedata] = useState({
        itemId:rowId,
        brand :metaData[2],
        Type  :metaData[1],
        Model : metaData[4],
        Warrantytype :metaData[8],
        Serialnumber :metaData[5],
        PurchaseDate :moment(metaData[6] ).format('YYYY-MM-DD') ,
        Purchasein :metaData[9],
        pointofname:metaData[10],
        pointofcontact :metaData[11],
        Statustype: metaData[13],
        Cost  :parseInt(metaData[3].props.children[1].replace("₹", "").replace(",", "")),
        PhysicalAddress :metaData[14],
        Hostname :metaData[15],
        RAM : metaData[16],
        Processor : metaData[17],
        Harddisk: metaData[19], 
        Harddisktype:metaData[18],
        Graphicscard:metaData[20],
    
      })
      
      
      const onInputchange = (e) =>{ 
        Setformupdatedata({...formupdateData,[e.target.name] :e.target.value},[])
    
      }
      
      const [expirydateactivity,setExpirydateactivity] = useState({expirydate: "" })
      
    
      const dateinputchange = (e) => {
       
        setExpirydateactivity({...expirydateactivity,[e.target.name] : e.target.value } , {})
      }
    

      const updatesubmits = async() => {  
       
          const  activityUpdateformdata = {
            itemId:rowId,
            brand :formupdateData.brand,
            type  :formupdateData.Type,
            model : formupdateData.Model,
            cost : formupdateData.Cost,
            serial_number :formupdateData.Serialnumber,
            purchased_on : formupdateData.PurchaseDate,
            warranty_type:formupdateData.Warrantytype,
            warranty_exp_date :expirydateactivity.expirydate ==="Invalid date" ? null : expirydateactivity.expirydate,
            purchased_in :formupdateData.Purchasein,
            point_of_name:formupdateData.pointofname,
            point_of_contact :formupdateData.pointofcontact,
            status: formupdateData.Statustype,
            physical_address : formupdateData.PhysicalAddress,
            hostname: formupdateData.Hostname,
            ram : formupdateData.RAM,
            processor: formupdateData.Processor,
            hard_disk : formupdateData.Harddisk,
            hard_disk_type:formupdateData.Harddisktype,
            graphics_card: formupdateData.Graphicscard,
      
          }
          updatehardwarelist(activityUpdateformdata)
          closemodal();
      }
 
    const nextpage = () =>{
        SetHardwareupdateassetpage((currpage) => currpage + 1)
    }
    const previouspage = () => {
        SetHardwareupdateassetpage((currpage) => currpage -1)
  
    }
  
    useEffect(() => {
     
    },[rowId])

    useEffect(() => {

      function addOneYear(date1) {
        const year = parseInt(formupdateData?.Warrantytype.replace("Year", ""))
     
        if(year===6){
          date1.setMonth(date1.getMonth() + 6);
          return date1;

        } else {
          
          date1.setFullYear(date1.getFullYear() + year);
          return date1;
        }
      
      }
      if(formupdateData.PurchaseDate && formupdateData.Warrantytype){
        const date1 = new Date(`${formupdateData?.PurchaseDate}`); 
        const newDate1 = addOneYear(date1);
        setExpirydateactivity({expirydate:moment(newDate1).format("YYYY-MM-DD")})
      }
  
    }, [formupdateData.PurchaseDate,formupdateData.Warrantytype])



  return (
    <>
     

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title> <small>Update Asset Form</small>  </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <div className='Titlebutton'>{ updatepagetitle[Hardwareupdateassetpage]}</div>
          <br/>
          
          {Hardwareupdateassetpage === 0 ? <HardwareUpdateAsset  formupdateData={formupdateData} onInputchange = {onInputchange} nextpage={nextpage} expirydateactivity={expirydateactivity} dateinputchange={dateinputchange} submit={updatesubmits} /> :<HardwareUpdateConfig formupdateData={formupdateData} onInputchange = {onInputchange} submit={updatesubmits} previouspage={previouspage}   />}
        </Modal.Body>
       
      </Modal>

     
    </>
  );

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  metaData:state.Hardwarelist.rowMetadata,
  rowId:state.Hardwarelist.rowId
});


const mapDispatchToProps = (dispatch) => ({
      updatehardwarelist : (data) => dispatch(updatehardwarelist(data)),
      openshowModal:(data) => dispatch(HardwareActions.update_openModal(data)),
      closemodal:(data) => dispatch(HardwareActions.update_closeModal(data)),
     
})

export default connect(mapStateToProps,mapDispatchToProps)(HardwareUpdateModal)