import moment from 'moment-timezone';
import { createTheme } from "@material-ui/core/styles";


export const getMuiTheme = () =>
    createTheme({
        overrides: {
            MUIDataTable: {
                root: {
                    backgroundColor: "#FF000"
                },
                paper: {
                    boxShadow: "none"
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    whiteSpace: "nowrap",
                    textColor: '#FFFFFF',
                    color: '#243d6a',
                    fontWeight: 'bold'
                }
            },
        }
    });

export const headerProps = () => ({
    style: {
        whiteSpace: "nowrap",
        background: "#e6e6e6",
    }
})

export const options = {
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyMaxHeight: '462px',
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
};




export const SalesMeetingsColumns = [
    {
        name: "lead_id",
        label: "Account Name",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.company_name) : "Account Name not found"
            ),
            filter: true,
            display: true,
        }
    },
    {
        name: "user_id",
        label: "Sales Manager",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.first_name + ' ' + value.last_name) : "User not found"
            ),
            setCellHeaderProps: headerProps
        }
    },

    {
        name: "contact_id",
        label: "Contact Person",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.contact_name) : "Contact Person not found"
            ),
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "meeting_summary",
        label: "Meeting Disucussion",
        options: {
            filter: true,
            setCellHeaderProps: headerProps,
            setCellProps: (value, tableMeta, updateValue) => (
                {
                    style: {
                        whiteSpace: "nowrap",
                        textColor: '#FFFFFF',
                        fontWeight: 'bold',
                        width: '200px',
                        maxWidth: '500px',
                        overflow: 'auto',
                    }
                })
        }
    },
    {
        name: "updated_at",
        label: "Meeting Date",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
];

export const TechActivitiesColumns = [
    {
        name: "account_name",
        label: "Account Name",
        options: {
            filter: true,
            display: true,
        }
    },
    {
        name: "requested_by",
        label: "Requested By",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "techie_name",
        label: "Techie Name",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "type",
        label: "Pre/Post",
        options: {
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "meeting_type",
        label: "Type",
        options: {
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "comments",
        label: "Comments",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "requested_on",
        label: "Requested On",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (value?.charAt(0).toUpperCase() + value?.slice(1))
             },
            setCellHeaderProps: headerProps
        }
    }
];

export const MetricDataColumns = [
    {
        name: "uploaded_by",
        label: "Added By",
        options: {
            filter: true,
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.first_name + ' ' + value.last_name) : "User not found"
            ),
        }
    },
    {
        name: "metric_name",
        label: "Metric Name",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "industry",
        label: "Industry",
        options: {
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.name) : "Industry not found"
            ),
        }
    },
    {
        name: "product",
        label: "Product",
        options: {
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.name) : "Product not found"
            ),
        }
    },
    {
        name: "file_path",
        label: "File",
        options: {
            filter: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? <a href={value} target="_blank" >View File</a> : "File Path not found"
            ),
        }
    },
    {
        name: "updated_at",
        label: "Added On",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    }

];

export const OpenDrfColumns = [
    {
        name: "company_name",
        label: "Account Name",
        options: {
            filter: true,
            display: true,
        }
    },
    {
        name: "user_id",
        label: "Sales Manager",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.first_name + ' ' + value.last_name) : "Sales Manager not found"
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "drf_status",
        label: "DRF Status",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.name) : "Drf Status not found"
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "drf_app_date",
        label: "DRF Appr. Date",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
        }
    },

];

export const ForecastColumns = [
    {
        name: "company_name",
        label: "Account Name",
        options: {
            filter: true,
            display: true,
        }
    },
    {
        name: "user_id",
        label: "Sales Manager",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.first_name + ' ' + value.last_name) : "Sales Manager not found"
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "sales_stage",
        label: "Status",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.confidence_level) : "Sales Stage not found"
            ),
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "tasks",
        label: "Action Plan",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.task_name +" - "+value.task_description) : "No Action Plan Found"
            ),
            filter: true,
            setCellProps: (value, tableMeta, updateValue) => (
            {
                style: {
                    whiteSpace: "nowrap",
                    textColor: '#FFFFFF',
                    fontWeight: 'bold',
                    width: '200px',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "po_value",
        label: "PO Value",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    textAlign:'right',
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps,
            customBodyRender: (value, meta) => {
                return (<span> {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>)
              },
        }
    },
    {
        name: "timeline_month",
        label: "Closure Month",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    }

];

export const ThisWeekDemosColumns = [
    {
        name: "account_name",
        label: "Account Name",
        options: {
            filter: true,
            display: true,
        }
    },
    {
        name: "requested_by",
        label: "Sales Manager",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "techie_name",
        label: "Techie Name",
        options: {
            filter: true,
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "type",
        label: "Pre/Post",
        options: {
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "meeting_type",
        label: "Type",
        options: {
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "requested_on",
        label: "Planned Date",
        options: {
            setCellHeaderProps: headerProps,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY HH:MM:ss') : ""
            ),
        }
    },
    {
        name: "comments",
        label: "Remarks",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps
        }
    }
];

export const MonthClosuresColumns = [
    {
        name: "company_name",
        label: "Account Name",
        options: {
            filter: true,
            display: true,
        }
    },
    {
        name: "user_id",
        label: "Sales Manager",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? (value.first_name + ' ' + value.last_name) : "Sales Manager not found"
            ),
        }
    },
    {
        name: "po_value",
        label: "Po Value",
        options: {
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    textAlign:'right',
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            customBodyRender: (value, meta) => {
                return (<span> {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>)
              },
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "revenue_to_kaizenat",
        label: "Revenue To Kaizenat",
        options: {
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    textAlign:'right',
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            customBodyRender: (value, meta) => {
                return (<span> {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>)
              },
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "po_date",
        label: "Po Date",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
        }
    },
    
];


export const MarketingActivitiesColumns = [
    {
        name: "title",
        label: "Title",
        options: {
            filter: true,
            display: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
        }
    },
    {
        name: "speakers",
        label: "Speakers",
        options: {
            filter: true,
            display: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
        }
    },
    {
        name: "persons",
        label: "Persons Involved",
        options: {
            filter: true,
            display: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
        }
    },
    {
        name: "agenda",
        label: "Agenda",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
        }
    },
    {
        name: "description",
        label: "Description",
        options: {
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '500px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "start_date",
        label: "Start Date",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY HH:mm') : ""
            ),
            setCellHeaderProps: headerProps
        }
    },
    {
        name: "end_date",
        label: "End Date",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY HH:mm') : ""
            ),
        }
    },
    
];


