
import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showCalendarEventModal: false,
    showUpdateEventModal:false,
    showBattleCardModal:false,
    rowId:[],
    rowMetadata:[],
    calendarlist:[],
    benchmarklist: [],
    benchmarktiming:[],
    createCalendarEvent:[],
    updateCalendarEvent:[],
    columnsList:[]
  
}

const CalendarSlice = createSlice({
    name: "Calendarlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showCalendarEventModal = true;
          },
          closeModal: (state, action) => {
            state.showCalendarEventModal = false; 
          },
          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },
          
          UpdateOpenModal: (state, action) => {
            state.showUpdateEventModal = true;
          },
          updateCloseModal: (state, action) => {
            state.showUpdateEventModal = false; 
          },

         
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          createCalendarEventSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createCalendarEvent = action.payload;
            toast.success("Successfully Created") 
            state.showCalendarEventModal = false;
    
          },
          createCalendarEventFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createCalendarEvent = [];
            toast.error("Error ! Not Created")
            state.showCalendarEventModal = true;
          },

          updateCalendarEventSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = false;
            state.updateCalendarEvent = action.payload;
            toast.success("Successfully Updated") 
            state.showUpdateEventModal = false;
    
          },
          updateCalendarEventFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updateCalendarEvent = [];
            toast.error("Error Not updated") 
            state.showUpdateEventModal = true;
    
          },
         deleteCalendarEventSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = false;
            toast.success(action.payload) 
            state.showUpdateEventModal = false;
    
          },
          deleteCalendarEventFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            toast.success(action.payload) 
            state.showUpdateEventModal = true;
    
          },
          CalendarlistSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.calendarlist = action.payload;
            // toast.success(" get Battle card data list") 
    
          },
          CalendarlistFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.calendarlist = [];
            toast.error(" get Battle card data list") 
    
          },
          calendarColumnsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.columnsList = action.payload
        },
        calendarColumnsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.columnsList = []
        },

          tasksListUpdateSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        tasksListUpdateFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
    
          updateColumns(state, action){
            state.columnsList = action.payload
          }
        
      

    }  
});
export const CalendarActions = CalendarSlice.actions;




export const getcalendarlist = (formdata) => {

  return async(dispatch) => {
      dispatch(CalendarActions.loaderStart("meeting loader"))

      try {
          await Api.post("/user/calender/events").then((res) => {
              if(res.data.status === "success"){
                  dispatch(CalendarActions.CalendarlistSuccess(res.data));
                  }
                  if(res.data.status ==="failure") {
                      dispatch(CalendarActions.CalendarlistFailure(res.data.msg))
                  }
          })
      }
      catch (err) {
        dispatch(CalendarActions.CalendarlistFailure("Network Error"));

      }
  }
} 


export const createCalendarEvents = (formdata) => {
  return async(dispatch) => {
      dispatch(CalendarActions.loaderStart("meetingloader"))
      try {
          await Api.post("/user/calender/create-event",formdata).then((res) => {
              if(res.data.status === "success") {
                  dispatch(CalendarActions.createCalendarEventSuccess(res.data));
                  dispatch(getcalendarlist())
              }
              if(res.data.status ==="failure") {
                  dispatch(CalendarActions.createCalendarEventFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(CalendarActions.createCalendarEventFailure("Network Error"));
      }
  }
  }

  
export const updateCalendarEvents = (data) => {
  return async(dispatch) => {
      dispatch(CalendarActions.loaderStart("meetingloader"))
      try {
        await Api.patch("/user/calender/update-event", data).then((res) => {
              if(res.data.status === "success") {
                  dispatch(CalendarActions.updateCalendarEventSuccess(res.data));
                  dispatch(getcalendarlist())
              }
              if(res.data.status ==="failure") {
                  dispatch(CalendarActions.updateCalendarEventFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(CalendarActions.updateCalendarEventFailure("Network Error"));
      }
  }
  }

  export const DeleteCalendarEvents = (data) => {

    return async(dispatch) => {
        dispatch(CalendarActions.loaderStart("meetingloader"))
        try {
          await Api.patch("/user/calender/delete-event", data).then((res) => {
                if(res.data.status === "success") {
                    dispatch(CalendarActions.deleteCalendarEventSuccess(res.data.message));
                    dispatch(getcalendarlist())
                }
                if(res.data.status ==="failure") {
                    dispatch(CalendarActions.deleteCalendarEventFailure(res.data.message))
                }
            })
        }
        catch (err) {  
          dispatch(CalendarActions.deleteCalendarEventFailure("Network Error"));
        }
    }
    }
  

    export const updateColumns = (data) => {
      return async (dispatch) => {
          dispatch(CalendarActions.loaderStart())
          try {
              await Api.post("/columns-hide-show/create", data).then((res) => {
                      if (res.data.status === "success") {
                          toast.success("Columns Updated ")
                          dispatch(CalendarActions.tasksListUpdateSuccess("Columns Updated"))
                      }
                      if (res.data.status === "failure") {
                          toast.error(res.data.msg)
                          dispatch(CalendarActions.tasksListUpdateFailure(res.data.msg))
                      }
                  })
          }
          catch (err) {
              toast.error("Network Error")
              dispatch(CalendarActions.tasksListUpdateFailure("Network Error"));
          }
      }
    }
    
    
export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(CalendarActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/calendar/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(CalendarActions.calendarColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(CalendarActions.calendarColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(CalendarActions.calendarColumnsListFailure("Network Error"));
      }
  }
}

  

  export default CalendarSlice.reducer;