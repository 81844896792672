import { createTheme } from "@material-ui/core/styles";

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: "#FF000"
        },
        paper: {
          boxShadow: "none"
        },
      },
      MUIDataTableBodyCell: {
        root: {
          whiteSpace: "nowrap",
          textColor: '#FFFFFF',
          color: '#243d6a',
          fontWeight: 'bold'
        }
      },
    }
  });

export const headerProps = () => ({
  style: {
    whiteSpace: "nowrap",
    background: "#e6e6e6",
  }
})