import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import Snackbar from '@material-ui/core/Snackbar';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { updateViewRowDetails} from "../../store/opportunities";
import { useDispatch,useSelector } from 'react-redux';

const PostPoneModal = (props) => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const rowId  = useSelector(state => state.opportunities.rowId)
  console.log("row id is in side taks modal",rowId)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [TasksDate, setTasksDate] = useState(moment().format('LLLL'));
  const { register: rejectRegister, handleSubmit: rejectHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const rejectSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
      'followup_id': props.folloupId,
      'task_status': "postponed",
      'postponed_date': TasksDate
    }
    try {
      const res = await axios.post("user/reports/tasks-followups/update", updatedData);
      setTimeout(() => {
        setOpen(true);
        setsnackBarMessage("postponed Date Updated");
        props.onHide();
      }, 2000);

      setsnackBarMessage("Postponed Date Updated");
      setRejectShow(false);
      handleClick();
      setDisable(true);
      const rowData = {
        rowId:rowId
      }
      dispatch(updateViewRowDetails(rowData))
    } catch (err) {
      setOpen(true);
      setsnackBarMessage("Error in updating postpone date");
      setDisable(false);

    }

  }
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleActionDateChange = (date) => {
    var newDate = moment(date).format('LLLL');
    setTasksDate(newDate);
  }
  return (
    <>

      <Modal
        show={props.show}
        onHide={() => setRejectShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {(userData.user_role_id.role_name === "user") ? "PostPoned" : "PostPoned"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form id="postponeForm" >
            <div className="row headerBand">
              <label>Set PostPoned Date</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    className="form-control"
                    label="Select Date"
                    inputVariant="outlined"
                    value={TasksDate}
                    onChange={handleActionDateChange}
                    showTodayButton
                    disablePast
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </MuiPickersUtilsProvider>
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col col-12">

                <button onClick={rejectHandleSubmit(rejectSubmit)} disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Update</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )

}
export default PostPoneModal;