import moment from 'moment-timezone'; 

export const options = (isFetching) => ({
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyHeight: (window.innerHeight - 220).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 220).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    textLabels: {
        body: {
            noMatch: isFetching ? <div className="loader"></div> : "No Data Found.."
        }
    }
});

export const meetingColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "contact_id",
        label: "Contact Person",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.contact_name : "NA"
            ),
        }
    },
    {
        name: "internal_contact_person_new",
        label: "Internal Contact Person",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                console.log("value", value),
                value?.map((item, index) => {
                    return (index !== 0) ? ", "+item?.first_name + '  ' + item?.last_name : item?.first_name + ' ' + item?.last_name
                })
            )
        }
    },
    {
        name: "lead_id",
        label: "Organization Name",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.company_name : "NA"
            ),
        }
    },
    {
        name: "lead_stage_id",
        label: "Lead Stage",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                (value ? value?.name : "NA")
            ),
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "meeting_type",
        label: "Meeting Type",
    },
    {
        name: "meeting_summary",
        label: "Meeting Discussion",
        options: {
            setCellProps: (value, tableMeta) => (
                {
                    style: {
                        whiteSpace: "nowrap",
                        textColor: '#FFFFFF',
                        fontWeight: 'bold',
                        width: '200px',
                        maxWidth: '500px',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        color: '#243d6a',
                    }
                }),
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "updated_at",
        label: "Meeting Date",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            filter: true,
            // setCellHeaderProps: headerProps,
        }
    }
]


export const DrfColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Company Name",
        options: {
            display: true,
            filter: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "drf_status",
        label: "DRF Status",
        options: {
            display: true,
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "drf_app_date",
        label: "DRF Approved Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    },
    {
        name: "drf_expiry_date",
        label: "DRF Expiry Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    }
]


export const TechDayColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Company Name",
        options: {
            display: true,
            filter: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
        }
    },
    {
        name: "title",
        label: "Title",
        options: {
            display: true,
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "guests",
        label: "Guests",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value?.map((item, index) => {
                    return (index !== 0) ? ", "+item?.contact_name : item?.contact_name
                    })
                },
        }
    },
    {
        name: "event_date",
        label: "Event Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    },
]


export const LunchnLearnColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Company Name",
        options: {
            display: true,
            filter: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "title",
        label: "Title",
        options: {
            display: true,
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "guests",
        label: "Guests",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value?.map((item, index) => {
                    return (index !== 0) ? ", "+item?.contact_name : item?.contact_name
                    })
                },
        }
    },
    {
        name: "event_date",
        label: "Event Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    }
]


export const LicenseSeedingColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Company Name",
        options: {
            display: true,
            filter: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
        }
    },
    {
        name: "title",
        label: "Title",
        options: {
            display: true
        }
    },
    {
        name: "department",
        label: "Department",
        options: {
            display: true
        }
    },
    {
        name: "guests",
        label: "Guests",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value?.map((item, index) => {
                    return (index !== 0) ? ", "+item?.contact_name : item?.contact_name
                    })
                },
        }
    },
    {
        name: "event_date",
        label: "Event Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    }
]


export const LunchDinnerMeetColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Company Name",
        options: {
            display: true,
            filter: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
        }
    },
    {
        name: "title",
        label: "Title",
        options: {
            display: true
        }
    },
    {
        name: "guests",
        label: "Guests",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value?.map((item, index) => {
                    return (index !== 0) ? ", "+item?.contact_name : item?.contact_name
                    })
                },
        }
    },
    {
        name: "event_date",
        label: "Event Date",
        options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            )
        }
    }
]

export const demosnPresentationColumns = [
    {
        name: "user_id",
        label: "Account Manager",
        options: {
            display: true,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' '+value?.last_name : "NA"
            ),
        }
    },
    {
        name: "company_name",
        label: "Organization Name",
        options: {
            display: true,
            // customBodyRender: (value, tableMeta, updateValue) => (
            //     value ? value?.company_name : "NA"
            // ),
        }
    },
    {
        name: "economic_buyer",
        label: "Requested by",
        options: {
            display: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ? value?.contact_name : "NA"
                )
        }
    },
    {
        name: "approved_by",
        label: "Approved By",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' ' +value?.last_name : "NA"
            ),
        }
    },
    {
        name: "assigned_to",
        label: "Techie Name",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? value?.first_name + ' ' +value?.last_name : "NA"
            ),
        }
    },
    {
        name: "duration",
        label: "Duration",
    },
    {
        name: "application",
        label: "Products",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                (value ? value?.name : "NA")
            ),
        }
    },
    {
        name: "date_time",
        label: "Start Date & Time",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
        }
    },
    {
        name: "initial_discussion",
        label: "Comments",
        options: {
            setCellProps: (value, tableMeta) => (
                {
                    style: {
                        whiteSpace: "nowrap",
                        textColor: '#FFFFFF',
                        fontWeight: 'bold',
                        maxWidth: '500px',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        color: '#243d6a',
                    }
                }),
            // filter: (user?.user_role_id?.role_name !== "user") ? true : false,
            // setCellHeaderProps: headerProps,
        }
    },
    {
        name: "updated_at",
        label: "Last Updated At",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            filter: true,
            // setCellHeaderProps: headerProps,
        }
    }
]