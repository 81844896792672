import React, { useState, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge'
import { useSelector } from 'react-redux';

const NonPerformingAccounts = () => {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const [opportunitiesList, setOpportunitiesList] = useState([]);

    console.log("NonPerformingAccounts")


    const getNonPerformingAccounts = async () => {
        const res = await axios.post('/user/reports/not-progressing-accounts', {
            user_id: userData._id
        });
        if (res.data.status === "success") {
            setOpportunitiesList(res.data.notProgressingAccountsList);
        }
    }

    useEffect(() => {
        getNonPerformingAccounts();
    }, []);


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="row" style={{ background: '#fff', borderRadius: '5px' }}>
                            {
                                opportunitiesList.map(opportunity => {
                                    return (

                                        <div className="col-md-2" style={{ backgroundColor: '#e0e0e0', borderRadius: '5px', verticalAlign: 'center', height: '120px', margin: '20px' }}>
                                            <center><span className="compant_title" style={{ fontWeight: 'bold', fontSize: '15px' }}>{opportunity._id.company_name}</span></center>
                                            <center>
                                                <Badge pill style={{ backgroundColor: '#0ca6d4', marginTop: '1rem' }}> {moment(opportunity._id.created_at).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}</Badge><br />
                                                <Badge pill style={{ backgroundColor: '#68d3f3' }}> {moment(opportunity._id.created_at).tz("Asia/Kolkata").fromNow()}</Badge>
                                            </center>
                                            <br />
                                        </div >

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NonPerformingAccounts;
//   const mapStateToProps = (state) => ({
//     salesIsalesTechUsers: state.dashboard.salesIsalesTechUsers,
//   });
  
//   const mapDispatchToProps = (dispatch) => ({
//     getAllReports: (data) => dispatch(getAllReportsData(data))
//   });
  
//   // export default InstaReport;
//   export default connect(mapStateToProps, mapDispatchToProps)(NonPerformingAccounts);