import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    demo_showModal:false,
  
    showModal_activity:false,
    ShowTechieMettingModal:false,
    
    showModal_Viewcontact:false,
    gettechielist:[],
    createtechielists:[],
    columnsList:[],

   
}

const TechiemettingSlice = createSlice({
    name: "Techiemettinglist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          demo_openModal: (state, action) => {
            state.demo_showModal = true;
          },
          demo_closeModal: (state, action) => {
            state.demo_showModal = false; 
          },
          showTechie_OpenModal : (state, action) => {
            state.ShowTechieMettingModal = true;
          },
          showTechie_CloseModal : (state, action) => {
            state.ShowTechieMettingModal = false; 
          },

          setTechieRowId(state, action) {
            state.TechierowId = action.payload;
          },
          setTechierowMetaData(state, action) {
            state.TechierowMetadata = action.payload;
          },

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },

      // get column table
      gettechielistsuccess(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.gettechielist = action.payload;
        
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      gettechielistFailure(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.gettechielist = [];
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      TechiemettinglistSuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.createtechielists =action.payload;
        toast.success("Successfully created") 
        state.ShowTechieMettingModal =  false
  
      },
      techmeetingcreateFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.createtechielists = [];
        toast.success("Error in created") 
        state.ShowTechieMettingModal =  true
  
      },
  techieColumnsListSuccess(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.columnsList = action.payload
},
techieColumnsListFailure(state, action){
    state.isFetch = false
    state.error = true
    state.errorPayload = action.payload
    state.columnsList = []
},
updateColumnsList(state, action){
  
  state.columnsList = action.payload
},

    }  
});
export const TechieMettingActions = TechiemettingSlice.actions;


 ///common 
  
export const gettechieMeetinglistHandle = (data) => {
  return async (dispatch) => {
    dispatch(TechieMettingActions.loaderStart());
    try {
   
      await Api.post("/meetings/techie-meeting/list",data).then((res) => {
        if (res.data.status === "success") {
          dispatch(TechieMettingActions.gettechielistsuccess(res?.data?.meetings));
        }
        if (res.data.status === "failure") {
          dispatch(TechieMettingActions.gettechielistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(TechieMettingActions.gettechielistFailure("Network Error"));
    }
  };
};
  export const techmeetingcreate = (updatedData) => {
    return async (dispatch) => {
      dispatch(TechieMettingActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/meetings/techie-meeting/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(TechieMettingActions.TechiemettinglistSuccess(res.data));
            dispatch(gettechieMeetinglistHandle())
          }
          if (res.data.status === "failure") {
            dispatch(TechieMettingActions.techmeetingcreateFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TechieMettingActions.techmeetingcreateFailure("Network Error"));
      }
    };
  }; 


  export const getColumnsList = () => {
    return async (dispatch) => {
        dispatch(TechieMettingActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/techiemeetings/" + userData?.user?._id).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(TechieMettingActions.techieColumnsListSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(TechieMettingActions.techieColumnsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(TechieMettingActions.techieColumnsListFailure("Network Error"));
        }
    }
  }


  export default TechiemettingSlice.reducer;