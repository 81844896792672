import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from './auth';
import targetsSliceReducer from './targets';
import meetingReducer from "./Meeting"
import contactReducer from"./Contact"
import organizationReducer from "./Organization"
import DemoReducer from "./Demo"
import BenchmarkReducer from "./Benchmark"
import LeaverequestReducer from "./Leaverequest"
import WebinarReducer from "./Webinar"
import TrainingReducer from "./Training"
import PayslipReducer from "./Payslip"
import HardwareReducer from "./Hardware"
import RawdatabaseReducer from "./Rawdatabase"
import BattlecardReducer from "./Battlecard"
import HrisReducer from "./Hris"
// import TimesheetReducer from "./Timesheet"
import TicketReducer from "./Ticketlist"
import TechiemettingReducer from "./TechieMetting"
import CasestudyReducer from "./Casestudy"
import TestimonialReducer from "./Testimonials"
import IndustryReducer from "./Industrymetric"
import EmailcampaignReducer from "./EmailCampaign"
import CallrecordsReducer from "./Callrecords"
import CalendarReducer from "./Calendar"
import dashboardReducer from './dashboard';
import leadsReducer from './leads';
import opportunitiesReducer from './opportunities';
import ProfileReducer from "./Profile"
import timesheetReducer from './timesheet';
import CommonReducer from "./Common";
import loggerMiddleware from "./loggerMiddleware";
import EventTracker from "./EventTracker";
// import logger from "redux-logger"



const store = configureStore({
    reducer: {
        auth: authReducer,
        targets : targetsSliceReducer,
        dashboard : dashboardReducer,
        leads : leadsReducer,
        opportunities : opportunitiesReducer,
        timesheet : timesheetReducer,
        meetinglist:meetingReducer,
        Contactlist:contactReducer,
        Organizationlist:organizationReducer,
        Demolist:DemoReducer,
        Benchmarklist:BenchmarkReducer,
        LeaveRequestlists:LeaverequestReducer,
        Paysliplist:PayslipReducer,
        Hardwarelist:HardwareReducer,
        // Timesheetlist:TimesheetReducer,
        Traininglist:TrainingReducer,
        Commonlist:CommonReducer,
        Webinarslist:WebinarReducer,
        Rawdatalist:RawdatabaseReducer,
        Battlecardlist:BattlecardReducer,
        Hrislist:HrisReducer,
        Ticketlist:TicketReducer,
        Techiemettinglist:TechiemettingReducer,
        Casestudylist:CasestudyReducer,
        Testimoniallist:TestimonialReducer,
        Industrylist:IndustryReducer,
        Emailcampaignlist:EmailcampaignReducer,
        Callrecordlist:CallrecordsReducer,
        Calendarlist:CalendarReducer,
        Profilelist:ProfileReducer,
        EventTracker: EventTracker
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

})

export default store;