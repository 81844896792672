import React, { useEffect, useMemo, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment-timezone';
import ViewAccount from "../../components/pageModals/ViewAccount";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import {connect } from 'react-redux';
import {ContactColumnUpdateddata,CommonsliceActions,} from "../../store/Common"
import {getorganizationlist,getColumnsList,OrganizationActions} from "../../store/Organization"

const AccountsTable = React.memo (({user,organizationlistdata,isFetching,Contactupdatedata,showModal,newrowId,newrowMetaData,openshowModal,getorganizationlistdata,getColumnsList,columnsList, updateColumnsList}) => {
  
// const accountdata = organizationlistdata?.accounts;
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const [tableData, setTableData] = useState([]);
  const userData = user.user;
  const onCellClickHandler = (meta) => {
    if (meta.colIndex === 2) {

      return;
    }
    openshowModal()
    newrowMetaData(meta)
    if(leadTypeActive === "all"){
      newrowId(organizationlistdata?.accounts[meta.dataIndex]._id); 
    }else{
      newrowId(organizationlistdata?.customers[meta.dataIndex]._id); 
    }
  }

  useMemo(() => {
    getorganizationlistdata();
  }, [])

  const columns = [
    {
      name: "user_id",
      label: "Account Manager",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? (value.first_name + ' '+ value.last_name) : "user not found"
        ),
        display: columnsList?.includes('user_id') ? true : false,
        filter: (userData.user_role_id?.role_name !== "user") ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Organization Name",
      options: {
        filter: true,
        display: true,
        // display: columnsList?.includes('company_name') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            fontWeight: 'bold',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#e6e6e6",
            zIndex: 101,
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "company_website",
      label: "Organization Website",
      options: {
        filter: true,
        display: columnsList?.includes('company_website') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? <a style={{ color: '#243d6a', }} href={value} target="_blank" ><u>{value}</u></a> : "")
        ),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            fontWeight: 'bold',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_address",
      label: "Organization Address",
      options: {
        filter: true,
        display: columnsList?.includes('company_address') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? value.street : "", value ? value.city : "")
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {

      name: "annual_revenue",
      label: "Annual Revenue (Cr)",
      options: {
        filter: true,
        display: columnsList?.includes('annual_revenue') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
      }
    },
    {
      name: "industry",
      label: "Industry",
      options: {
        filter: true,
        display: columnsList?.includes('industry') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "created_at",
      label: "Created at ",
      options: {
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "updated_at",
      label: "Updated at ",
      options: {
        display: columnsList?.includes('updated_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },


  ];

  // const handleRowClick = (rowData, rowMeta) => {
  //     setRowMetaData(rowMeta);
  //   setRowId(tableData[rowMeta.dataIndex]._id)
  // };

  useEffect(() => {
    getColumnsList();
  
  }, []);

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }

  const options = {
    textLabels: {
      body: {
         noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    // rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {

        // var index = columnsList.indexOf(changedColumn);
        // if (index !== -1) {
        //     columnsList.splice(index, 1);
        //     columnsList(columnsList);
        // }
        var columns = columns.filter(item => item !== changedColumn);
    }
      const updatedData = {
        user_id: userData ? userData._id : null,
        table_name: "accounts",
        columns_list: columns,
      }
      Contactupdatedata(updatedData)
      updateColumnsList(columns)
    },
    onDownload: (buildHead, buildBody, columns, data) => {

      let rowDatas = data.map(item => {

        const temp = [
          item.data[0] ? item.data[0].first_name : "NA",
          item.data[1] ? item.data[1] : "NA",
          item.data[2] ? item.data[2] : "NA",
          item.data[3] ? item.data[3].street + ', ' + item.data[3].postal_code + ', ' + item.data[3].state + ', ' + item.data[3].country : "NA",
          item.data[4] ? item.data[4].name : "NA",
          item.data[5] ? item.data[5].name : "NA",
        ]

        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val
    }
  };

  const filterLeadType = async (type) => {

    setLeadTypeActive(type);
    // var data = [];
    // if (type === "all") {
    //   data = organizationlistdata?.accounts;
    // } else if (type === "customers") {
    //   data = organizationlistdata?.customers
    // }
    // setTableData(data);
  }

  return (
    <>
      <React.Fragment>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: "flex", flexDirection: "row" }} >
              <b>Organizations List</b>&nbsp;&nbsp;&nbsp;
              <span onClick={() => filterLeadType("all")} className={leadTypeActive === "all" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> All </span>&nbsp;&nbsp;
              <span onClick={() => filterLeadType("customers")} className={leadTypeActive === "customers" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Customers </span>&nbsp;&nbsp;
            </div>}
            data={leadTypeActive === "all" ? organizationlistdata?.accounts : organizationlistdata?.customers }
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </React.Fragment>
      {showModal ?  <ViewAccount />  : ""}
  
    </>
  )
});
const mapStateToProps = (state) => ({
  user : state.auth.user,
  organizationlistdata:state.Organizationlist.organizationlistdata,
  accountcolumn:state.Organizationlist.accountcolumn,
  isFetching : state.Organizationlist.isFetching,
  showModal : state.Commonlist.demo_showModal,
  columnsList:state.Organizationlist.columnsList

});

const mapDispatchToProps = (dispatch) => ({
  Contactupdatedata:(data) => dispatch(ContactColumnUpdateddata(data)),
  newrowId:(data) => dispatch(CommonsliceActions.setRowId(data)),
  newrowMetaData:(data) => dispatch(CommonsliceActions.setrowMetaData(data)),
  openshowModal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
  getorganizationlistdata:(data) => dispatch(getorganizationlist(data)),
  getColumnsList:(data) => dispatch(getColumnsList(data)),
  updateColumnsList: (data) => dispatch(OrganizationActions.updateColumnsList(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (AccountsTable)
