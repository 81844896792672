import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";

const UpdateUserModal = (props) => {
    const [snackBarMessage, setSnackBarMessage] = useState(false);
    const { register: RawdataupdateRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: rawdataupdateErrors } } = useForm();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState(false);
    const [rawupdateshow, setrawupdateshow] = useState(false);
    const [userDetails, setUserDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        job_title: "",
        company: "",
        status: "",
        blocked: "",
        manager_id: "",
        user_role_id: ""
    });


    useEffect( () => {
        getUserDetails()
    }, [])

    const onInputchange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value }, []);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    const getUserDetails = async () => {

        try {
            const res = await axios.get("/user/profile/details/"+props.user_id); 
            
            if (res.data.status === "success") {
                let user = res.data.user
                setUserDetails({
                    user_id:props.user_id,
                    first_name: user.first_name,
                    last_name:user.last_name,
                    email: user.email,
                    phone: user.phone,
                    job_title: user.job_title,
                    company: user.company,
                    status: user.status,
                    blocked: user.blocked,
                    manager_id: user.manager_id,
                    user_role_id: user.user_role_id?._id
                })

                //console.log("user detail ", userDetails)

            }else{

            }

        }catch(err){

        }
    }

    const rawDataSubmitHandle = async () => {

        const inputData = {
              user_id:props.user_id,
              first_name: userDetails.first_name,
              last_name:userDetails.last_name,
              email: userDetails.email,
              phone: userDetails.phone,
              job_title: userDetails.job_title,
              company: userDetails.company,
              status: userDetails.status,
              blocked: userDetails.blocked,
              manager_id: userDetails.manager_id,
              user_role_id: userDetails.user_role_id,

        };

        try {
            const res = await axios.post("/user/profile/update_user", inputData);
            if (res.data.status === "success") {
                props.refreshRawDatas();

                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage("User Update Successfully ...!");
                    props.onHide();
                    props.onHideWebsiteHandle();
                }, 1000);

                setSnackBarMessage("User Update Successfully ...!");
                handleClick();
            } else {
                setSnackBarMessage("Error in updating User Details...!");
            }
        } catch (err) {
            setSnackBarMessage(err);
        }
    };


    return (
        <>


            <Modal
                dialogClassName="modals"
                aria-labelledby="example-custom-modal-styling-title"
                show={props.show}
                onHide={() => setrawupdateshow(false)}
                backdrop={false}
                disableBackdrop={true}>

                <Modal.Header>
                    <Modal.Title>User Update</Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">
                        Cancel
                    </a>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={contactHandleSubmit(rawDataSubmitHandle)}>
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="first_name"
                                        label="First Name"
                                        required
                                        value={userDetails.first_name}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="last_name"
                                        label="Last Name"
                                        required
                                        value={userDetails.last_name}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="blocked"
                                        label="Blocked"
                                        required
                                        value={userDetails.blocked}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="email"
                                        label="Email"
                                        required
                                        value={userDetails.email}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="job_title"
                                        label="Designation"
                                        value={userDetails.job_title}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="company"
                                        label="Company"
                                        required
                                        value={userDetails.company}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="phone"
                                        label="Phone"
                                        required
                                        value={userDetails.phone}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextField
                                        variant="outlined"
                                        name="status"
                                        label="Status"
                                        required
                                        value={userDetails.status}
                                        onChange={(e) => onInputchange(e)}
                                        size="small"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">

                            <div class="col-md-6">
                                    <TextField variant="outlined"
                                        {...RawdataupdateRegister("user_role_id", {
                                            minLength: {
                                                value: 1,
                                                message: "User Role must be at least 1 characters"
                                            }
                                        })}
                                        helperText={rawdataupdateErrors.user_role_id ? rawdataupdateErrors.user_role_id.message : ''}
                                        error={Boolean(rawdataupdateErrors.user_role_id)}
                                        select
                                        type="text" label="User Role/Team" name="user_role_id" className="form-control"
                                        value={userDetails.user_role_id} onChange={e => onInputchange(e)} >

                                        {
                                            props.userRoles.map((option, industry) => (
                                                <MenuItem key={option.label} selected={industry === option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>



                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,

                                        }}
                                        select

                                        value={userDetails.manager_id}
                                        onChange={(e) => onInputchange(e)}
                                       
                                        size="small"
                                        type="text" className="form-control" name="manager_id" label="Select Manager
                                    "
                                    >
                                        {props.usersList.map((option, manager_id) => (
                                            <MenuItem key={option.first_name} selected={manager_id === option._id} value={option._id}>
                                            {option.first_name + ' ' + option.last_name}
                                        </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                            <br />

                            <div className="row">
                                <div>
                                    <button className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn" type="submit">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>

                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />


        </>
    )

}

export default UpdateUserModal;