import React, { useState, useRef, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import moment from "moment";
import { display } from "@material-ui/system";
import { getcalendarlist } from "../../store/Calendar";
import { useDispatch } from "react-redux";
const Viewcalendardelete = (props) => {
  const dispatch =useDispatch()
  console.log("delete details are",props)

  const [userData, setUserData] = useState(null);
  console.log("userdata", userData);

  useEffect(() => {
    const storedUserData = localStorage.getItem("user");
    console.log("Stored User update Data:", storedUserData);

    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);

      if (parsedUserData.user) {
        setUserData(parsedUserData.user);
      }
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDelete = async (e) => {
    const updateobjects = {
      eventId: props.id,
      email: props?.Attendees,
      subject: props?.title,
      context: props?.description,
      startDateTime: props.start?.toISOString(),
      endDateTime: props?.end?.toISOString(),
      attendees: [
        {
          emailAddress: userData ? userData.email : "",
          name: userData ? userData.first_name : "",
        },
      ],
    };
console.log("delete details are",updateobjects)
    try {
      const res = await axios.delete("/user/calender/delete-event", {
        data: updateobjects // Use 'data' to send the request body
      });
      dispatch(getcalendarlist())
      console.log("resupdated", res);

      if (res.data.status === "success") {
        setTimeout(() => {
          setOpen(true);
          setSnackBarMessage("Item successfully deleted");
          props.onHide();
        }, 2000);
        setSnackBarMessage("Item successfully deleted");
      } else {
        setSnackBarMessage("Error in delete ...!");
      }
    } catch (err) {
      setSnackBarMessage(err.message); // Display the error message
    } finally {
      setIsDeleting(false); // Reset the isDeleting state
    }
  };

  // useEffect(() => {
  //   handleDelete();
  // }, []); // Add dependencies if necessary

  return (
    <>
      <div className="row">
        <div className="mb-3">
          <Modal
            dialogClassName="modal-width"
            aria-labelledby="example-custom-modal-styling-title"
            show={props.show}
            onHide={props.onHide}
            backdrop={false}
            disableBackdrop={true}
          >
            <Modal.Body>
              <div style={{ display: "grid", justifyContent: "center" }}>
                <div style={{ fontSize: "1.5rem" }}>
                  Are you Sure want to delete ?
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "50px",
                  }}
                >
                  <button
                    type="button"
                    onClick={handleDelete}
                    class="btn btn-danger"
                  >
                    yes
                  </button>
                  <button
                    type="button"
                    onClick={props.onHide}
                    class="btn btn-dark"
                  >
                    No
                  </button>
                </div>
              </div>
              <br />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        // autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
              
            </Button> */}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default Viewcalendardelete;
