import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { useForm } from "react-hook-form";
import RawdataUpdateModal from "./RawdataUpdateModal";
import { connect,useDispatch } from "react-redux";
import {RawdataActions} from "../../store/Rawdatabase"


  const RawdataaddingwebsiteModal = React.memo (({UpdateRawdataModal,rawupdatedata,openshowModal,closeshowmodal,showRawdataopenmodal}) => {
    const dispatch = useDispatch();
  
const[userList,setUserList]= useState([])
const { register: hardwareAssignRegister, handleSubmit: hardwarehandleSubmit, setError: setError, formState: { errors: hardwareErrors }, reset: contactsReset } = useForm();

const getUsers = async() => {
  try {
    const res = await axios.get("/user/list")
    if(res.data.status ==="success"){
      setUserList(res.data.usersList)
    }
  } catch (error) {
    alert(error)
  }
}
useEffect(() => {
  getUsers();
}, []);


const WebsiteModalHandle = () => {
  showRawdataopenmodal()
  // dispatch(RawdataActions.showUpdateRawdataModal_openmodal())
  
}

  return (
    <>
      <Modal
        dialogClassName="webiste_modal"
        aria-labelledby="example-custom-modal-styling-title"
        show={() => openshowModal()}
        onHide={() => closeshowmodal()}
        backdrop={false}
        disableBackdrop={true}
        className="rawdatacustomermodals"
      >
      <Modal.Header>
      <Modal.Title>
     <h5> Alert </h5>
     </Modal.Title>
      <a onClick={() => closeshowmodal()}  className="btn btn-sm btn-danger">Cancel</a>
      
    </Modal.Header> 
          <Modal.Body>
              <div className="row">
                <div className="col-md-9">
                    <h6> Website not found, please update the webite to proceed further</h6>

                </div>

              </div>
                <br/>
            <div className="row">
              <div  className="col-md-12">
              
              <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" onClick={WebsiteModalHandle} > Update </button>
             
              </div>
            </div>
       
          </Modal.Body>
      </Modal>
    
    {UpdateRawdataModal ? <RawdataUpdateModal  /> : ""}
    </>
  );

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    UpdateRawdataModal:state.Rawdatalist.showUpdateRawdataModal,
     
});
const mapDispatchToProps = (dispatch) => ({
     rawupdatedata:() => dispatch(RawdataActions.showUpdateRawdataModal_openmodal()),
     openshowModal:(data) => dispatch(RawdataActions.showAddingWebsiteModal_openmodal(data)),
     closeshowmodal:(data) => dispatch(RawdataActions.showAddingWebsiteModal_closemodal(data)),
     showRawdataopenmodal:() => dispatch(RawdataActions.showUpdateRawdataModal_openmodal()),
});
export default connect(mapStateToProps,mapDispatchToProps) (RawdataaddingwebsiteModal)

