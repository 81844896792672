import React, {useState,useMemo, useEffect} from "react";

const RenderDateFilter = React.memo(({filters, setFilters}) => {

    console.log("filters ", filters)

    useEffect(() => {
        console.log("filters changed")
    }, [filters])

    return(
        <>
            <div className="row">
                <div className="col-md-2">
                    <label id="startDate">Start:</label>
                </div>
                <div className="col-md-4">
                    <input 
                        id="startDate"
                        type="date" 
                        name="startDate" 
                        value = {filters?.startDate}
                        onChange={(e) => setFilters((prev) => ({
                            ...prev,
                            startDate: e.target.value
                        })) } 
                    />
                </div>
                <div className="col-md-2">
                    <label for="endDate">End:</label>
                    </div>
                    <div className="col-md-4">
                    <input 
                        id="endDate"
                        type="date" 
                        name="endDate" 
                        value={filters?.endDate}
                        onChange={(e) => setFilters((prev) => ({
                            ...prev,
                            endDate: e.target.value
                        }))}
                     />
                </div>
                {/* <div className="col-md-4">
                    <button type="submit" onClick={handleDateChnage}>Submit</button>
                </div> */}
            </div>
        </>
    )

});

export default RenderDateFilter;