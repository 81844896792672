import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import Select from 'react-select'
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import { useSelector } from 'react-redux';


const AddNewMomModal = (props) => {

    const [isRequired, setRequired] = useState(props.disableRequired);
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();
    const [companiesList, setCompaniesList] = useState();
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [industriesList, setIndustriesList] = useState([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const description = useRef();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState();
    const [usersList, setUsersList] = useState([]);
    const html = `<p>  Description...
    <br /><br />
    </p>`;
    const contentBlock = htmlToDraft(props.data ? props.data.description : html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const [productsList, setProductsList] = useState([]);
    const mailBody = useRef();
    const [activity, setActivity] = useState({
        metric_name:"",
        product:"",
        industry:""
    })
    const [title, setTitle] = useState(props.data?.title)
    useEffect(() => {
        getAllUsersList();
    }, [])

    useEffect(() => {
        //
    }, [props.data])

    const onEditorStateChange = (data) => {
        //
        setEditorState(data)
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }

    const changeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            let pdfFile = event.target.files[0];
            let pdfFileName = event.target.files[0].name
            setSelectedFile(pdfFile);
            setSelectedFileName(pdfFileName);
          }
    
     
      };

      const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY LT")}`
      };
    const addContactsHandle = async (e) => {

        if(!activity.action_date_time){
            alert('Please select action date time')
            return;
        }

        setDisable(true);
        setSnackBarMessage("Uploading Data ...!");
        setOpen(true);

        setTimeout(() => { }, 500);

        var usersArray = []
        activity.responsible_people.map(item => {
            usersArray.push(item.value)
        })



        var object = {
            "subject": activity.subject,
            "action_item": activity.action_item,
            "action_date": activity.action_date_time,
            "responsible_people": usersArray
        }

        try {

            var res;
            res = await axios.post("/user/reports/add-weekly-mom", object);

            if (res.data.status === "success") {
                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage("Minutes of Meeting Successfully Added...!");
                    props.onHide();

                }, 2000);

                setSnackBarMessage("Minutes of Meeting Successfully Added...!");
                setContactsShow(false);

                contactsReset({
                    keepErrors: false,
                    keepDirty: true,
                    keepIsSubmitted: false,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                })
                setDisable(true);
            } else {

                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage(res.data.msg);
                }, 2000);

                setContactsShow(true);
                setDisable(false);
            }

            if (props.refreshTableData()) {
                props.refreshTableData();
            }

            if (props.returnValue) {
                props.returnValue(res.data.savedContacts);
            }
        } catch (err) {
            setDisable(false);

            alert(err);
        }

    }
    const getAllUsersList = async () => {
        const res = await axios.get("/user/all-users-except-pdteam");
        setUsersList(res.data.list);
    }

    const getIndustriesList = async () => {
        const res = await axios.get("/industries/search");
        setIndustriesList(res.data.industriesList);
    }
    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            < Modal
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                className={modalHide}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <small>  Add Minutes of Meeting</small>
                      
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2} onSubmit={contactshandleSubmit(addContactsHandle)}>


                        <div class="row">
                            <div class="col-md-6">
                                <TextField variant="outlined"
                                
                                    value={activity.subject}
                                    onChange={e => onInputChange(e)}
                                    helperText={contactsErrors.subject ? contactsErrors.subject.message : ''}
                                    error={Boolean(contactsErrors.subject)}
                                    label="Subject"
                                    required
                                    type="text" className="form-control" name="subject" />
                            </div>

                            <div class="col-md-6">
                                <TextField variant="outlined"
                                
                                    value={activity.action_item}
                                    onChange={e => onInputChange(e)}
                                    helperText={contactsErrors.action_item ? contactsErrors.action_item.message : ''}
                                    error={Boolean(contactsErrors.action_item)}
                                    label="Action Item"
                                    required
                                    type="text" className="form-control" name="action_item" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div class="col-md-12">
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={usersList}
                                    onChange={(value) => setActivity({...activity, responsible_people: value })}
                                />
                            </div>
                        </div><br />
                        <div class="row">
                            <div className='col-md-6'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        className="form-control"
                                        label="Action Date Time"
                                        inputVariant="outlined"
                                        value={activity.action_date_time}
                                        onChange={(date) => setActivity({...activity, action_date_time: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
                                        labelFunc={formatWeekSelectLabel}
                                        showTodayButton
                                        required
                                        minDate={new Date()}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-6">
                                <button style={{marginLeft:'10px;'}} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                        </div>
                        </div>
                        <br />
                        
                    </form>
                </Modal.Body>
            </Modal>
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}

export default AddNewMomModal;

