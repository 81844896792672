import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import { AiOutlineDelete } from "react-icons/ai";
import { connect, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import {AiOutlinePlus} from "react-icons/ai"
import moment from 'moment';
import  Switch  from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Viewcalendardelete from './Viewcalendardelete';
// import Viewattendees from './Viewattendees';
import {createCalendarEvents,CalendarActions} from "../../store/Calendar"

const AddCalendarevent = React.memo(({user,createCalendarEvent,openshowModal,closemodal}) => { 

  const {
    register: RawdataupdateRegister,
    handleSubmit: contactshandleSubmit,
    setError: contactSetError,
    formState: { errors: rawdataupdateErrors },
  } = useForm();
const customTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&$checked': {
            color: '#006400', // Dark green color when on
          },
          '!&$checked': {
            color: '#006400', // Dark green color when on
          },
          '&$checked + $track': {
            backgroundColor: '#006400', // Dark green color for the track when on
          },
        },
        
        track: {
          backgroundColor: '#e57373', 
         // Red color for the track when off
        },
      },
    },
  },
});

  const [disable, setDisable] = React.useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "", //upward,
    attendees : "",
    start: new Date(), // Set default start date/time
    end: new Date(),   // Set default end date/time
    description: "",  //downward
  });


  const [attendees, setattendees] = useState([{emailName:"",emailAddress:""}]);

  const attendeesHandlechange = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...attendees];
    onChangeVal[i][name] = value;
    setattendees(onChangeVal);
  };
  console.log("attendeesnewval",attendees)
  const handleAdd = () => {
    setattendees([...attendees,{emailName:"",emailAddress:""}])
  }

  const handleDelete = (i) => {
    const deleteVal = [...attendees]
    deleteVal.splice(i,1)
    setattendees(deleteVal)
  }


  const AddEventHandle = async (e) => {
    setDisable(true); 
    const { title, Attendees, start, end, description } = newEvent;
    const combinedArray = {
      emailAddress: attendees.map(item => ({
        address: item.emailAddress,
        name: item.emailName
      }))
    };
    // console.log("combined_values", combinedArray);
    const obj = {
      meetingType: switchState ? 'online' : 'offline',
      email:user? user.user.email: '',
      subject: title,
      content: description,
      startDateTime: moment(start).format('YYYY-MM-DDTHH:mm:ss'),
      endDateTime: moment(end).format('YYYY-MM-DDTHH:mm:ss'),
      attendees: combinedArray
    };
    createCalendarEvent(obj)
  }

  const [switchState, setSwitchState] = useState(false);

  const handleChange = () => {
    setSwitchState(!switchState);
  };


  return (
    <>
  
  <Modal
  show={() => openshowModal()}
  onHide={() => closemodal()}
  backdrop={false}
  disableBackdrop={true}
  // className={modalHide}
  dialogClassName="modal-90w"
  arialabelledby="example-custom-modal-styling-title"
>
  <Modal.Header>
   <Modal.Title>
   <div  className="custom_header_-title"> Add new event</div>
   </Modal.Title> 
    <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">
      Cancel
    </a>
  </Modal.Header>
  <Modal.Body>

  <div style={{display:"flex" }}>
    <ThemeProvider theme={customTheme}>
    <div style={{display:"flex" }}>
      <Switch
        checked={switchState}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Switch demo' }}
        defaultChecked
      />
      <div style={{fontSize:"13px" ,margin:"8px"}} >{switchState ? 'Online' : 'Offline'}</div>
    </div>
    </ThemeProvider>
       </div>
    <br/>

    <form key={2} onSubmit={contactshandleSubmit(AddEventHandle)}>    
        <div class="row">
        <div className="col-md-6">
        <TextField
            variant="outlined"
            required
            size="small"
            type="text"
            className="form-control"
            name="title"
            label="Title"
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
          />
          </div>

          { switchState ?  <div className='col-md-6 d-flex justify-content-center'>
          <Button variant="primary" onClick={handleAdd} size="sm">
            <AiOutlinePlus size={15}/> Add Attendees
          </Button>
        </div> : (
            <div className="col-md-6">
            <TextField variant="outlined"
             
            required
            size="larger"
            type="text"
            className="form-control" 
            name="description"  
            label="Description"
            value={newEvent.description}
            onChange={(e) => setNewEvent({ ...newEvent,description : e.target.value })}
        >
           
        </TextField>
            </div>

           )}
        </div>
      <br/>

      {switchState ? (
        <div className="row">
          {attendees.map((Val,i) => 
            <div>
            <br/>
            <div className="row">  
            <div className='col-md-5'>
            <TextField variant="outlined"
                   
        required
        size="larger"
        type="text"
        className="form-control" 
        name="emailName"  
        label="Name"
        value={Val.emailName}
        onChange={(e) => attendeesHandlechange(e,i)}
      />
      </div>
      <div className='col-md-5'>
      <TextField variant="outlined"
      required
      size="larger"
      type="text"
      className="form-control" 
      name="emailAddress"  
      label="Email"
      value={Val.emailAddress}
      onChange={(e) => attendeesHandlechange(e,i)}
      />
      
      </div>
      <div className='col-md-2'>
      <Button variant="outlined" className='btn btn-sm btn-danger' color="secondary" 
      onClick={() => handleDelete(i)}>
      <AiOutlineDelete />
      </Button>
      </div>
            </div>
            </div>
            
            )
          }
        </div>
        
      ) : null}
      <br />
      {switchState ?  <br /> : null }
         <div className="row">
         <div className="col-md-6">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DateTimePicker
           className="form-control"
           label="Start Date Time*"
           inputVariant="outlined"
           showTodayButton
           format="dd-MM-yyyy hh:mm a"
           value={newEvent.start} 
           onChange={(date) => setNewEvent({ ...newEvent, start: date })} 
           InputLabelProps={{
             shrink: true,
           }}
         />
       </MuiPickersUtilsProvider>
         </div>
         <div className="col-md-6">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DateTimePicker
           className="form-control"
           label="End Date Time*"
           inputVariant="outlined"
           showTodayButton
           format="dd-MM-yyyy hh:mm a"
           value={newEvent.end} 
           onChange={(date) => setNewEvent({ ...newEvent, end: date })}
           InputLabelProps={{
             shrink: true,
           }}
         />
       </MuiPickersUtilsProvider>
         </div>
         </div>
         <br />
      <div className="row">
      { switchState ?  (
      <div className="col-md-6">
      <TextField variant="outlined"
      multiline row={2} 
      required
      size="larger"
      type="text"
      className="form-control" 
      name="description"  
      label="Description"
      value={newEvent.description}
      onChange={(e) => setNewEvent({ ...newEvent,description : e.target.value })}
  >
     
  </TextField>
      </div>
      ) : null 
      }
    </div>
    <br />
      <div className="row">
        <div>
        <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Create</button> 
          &nbsp;&nbsp;
        </div>
      </div>
    </form>
  </Modal.Body>
</Modal>

    </>
  )

});
const mapStateToProps = (state) => ({
  user : state.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
  createCalendarEvent : (data) => dispatch(createCalendarEvents(data)),
 openshowModal:(data) => dispatch(CalendarActions.openModal(data)),
    closemodal:(data) => dispatch(CalendarActions.closeModal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (AddCalendarevent)