
import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showModal_assign: false,
    updatedHardwaremodal : false,
    hardwarelist:[],
    createdhardwarelist:[],
    updatedhardwarelist:[],
    unassigneduserlist:[],
    assigneduserlist:[],
    getuserlists:[],
    getuserlistsHardware:[],
    rowMetadata:[],
    columnsList: [],
}

const HardwareSlice = createSlice({
    name: "Hardwarelist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },
          update_openModal: (state, action) => {
            state.updatedHardwaremodal = true; 
          },
          update_closeModal: (state, action) => {
            state.updatedHardwaremodal = false; 
          },

          setAssignmetaid(state, action) {
            state.assignmetaid = action.payload;
          },

          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          hardwarelistSuccess(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.hardwarelist = action.payload;
            // toast.success("Hardware list data") 
          },
          hardwarelistFailure(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.hardwarelist =[];
               toast.error("Error in hardware list data") 
          },
    
          createdHardwarelistSuccess(state,action) {
            // toast.success("Fetching hardware ") 
            state.error = false;
            state.errorPayload = null;
            state.createdhardwarelist = action.payload;
            toast.success("Successfully created") 
            state.showModal = false;
          },
          createdHardwarelistFailure(state,action) {
            // toast.success("Fetching hardware ")
            state.error = false;
            state.errorPayload = null;
            state.createdhardwarelist = action.payload;
            toast.error("Error in hardware")
            state.showModal = true; 
          },
          updatedHardwarelistSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updatedhardwarelist = action.payload;
            state.updatedHardwaremodal = false;
            toast.success("Updated hardware data") 
          },
          updatedHardwarelistFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updatedhardwarelist = [];
            toast.error("Error in updated hardware ") 
            state.updatedHardwaremodal = true;
          },

          unAssignedUserSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.unassigneduserlist = action.payload;
            toast.success("Successfully assigned") 
            
    
          },
          unAssignedUserFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.unassigneduserlist = action.payload;
            toast.error("Error in unassigned") 
    
          },
          AssignedUserSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assigneduserlist = action.payload;
            toast.success("Successfully assigned") 
            state.showModal_assign = false;
    
          },
          AssignedUserFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assigneduserlist =[];
            toast.error("Error in unassigned") 
            state.showModal_assign = true;
    
          },
          getuserslistSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserlists = action.payload;
          },
          getuserslistFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserlists = [];
            toast.error("Error in users list") 
          },
          getuserslistHardwareSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserlistsHardware = action.payload;
          },
          getuserslistHardwareFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserlistsHardware = [];
            toast.error("Error in users list") 
          },
        
        addNewTaskSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        tasksListUpdateSuccess(state, action){
          state.isFetch = false
          state.error = false
          state.errorPayload = false
          state.msg = action.payload
      },
      tasksListUpdateFailure(state, action){
          state.isFetch = false
          state.error = true
          state.errorPayload = action.payload
          state.msg = ""
      },
      columnsListSuccess(state, action){
        state.isFetch = false
        state.error = true
        state.columnsList = action.payload
        state.msg = ""
    },
    columnsListFailure(state, action){
        state.isFetch = false
        state.error = true
        state.columnsList = []
        state.msg = ""
    },
      updateColumns(state, action){
        state.columnsList = action.payload
    }
    }  
});
export const HardwareActions = HardwareSlice.actions;


///        HARDWARE LIST START HERE  



  export const updateColumns = (data) => {
    return async (dispatch) => {
        dispatch(HardwareActions.loaderStart())
        try {
            await Api.post("/columns-hide-show/create", data).then((res) => {
                    if (res.data.status === "success") {
                      toast.success("Columns Updated ")
                        dispatch(HardwareActions.tasksListUpdateSuccess("Columns Updated"))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(HardwareActions.tasksListUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(HardwareActions.tasksListUpdateFailure("Network Error"));
        }
    }
  }


  export const getColumnsList  = () => {
    return async(dispatch) => {
        dispatch(HardwareActions.loaderStart("meetingloader"))
  
        try {
          var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/hardware/"+ userData?.user?._id).then((res) => {
                if(res.data.status === "success") {
                  dispatch(HardwareActions.columnsListSuccess(res.data.columnsList?.columns_list));
                  // toast.success(`${activityData.leave_type} Successfully Added...!`)
                    // dispatch(LeaverequestActions.createleaveSuccess(res.data));
                    // dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                 
                    dispatch(HardwareActions.columnsListFailure(res.data.msg))
                }
            })
        }
        catch (err) {  
          dispatch(HardwareActions.columnsListFailure("Network Error"));
        }
    }
    }

export const gethardwarelist = (data) => {
    return async(dispatch) => {
        dispatch(HardwareActions.loaderStart())

        try {
            await Api.get("/hardware/get-list",data).then((res) => {
                if(res.data.status === "success"){
                dispatch(HardwareActions.hardwarelistSuccess(res?.data?.list));
                }
                if(res.data.status ==="failure") {
                    dispatch(HardwareActions.hardwarelistFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(HardwareActions.hardwarelistFailure("Network Error"));
        }
    }
 
}


export const createhardwarelist = (updtaed) => {
    return async(dispatch) => {
        dispatch(HardwareActions.loaderStart("meetingloader"))

        try {
            await Api.post("/hardware/create",updtaed).then((res) => {
                if(res.data.status === "success"){
                dispatch(HardwareActions.createdHardwarelistSuccess(res.data));
                dispatch(gethardwarelist())
                }
                if(res.data.status ==="failure") {
                    dispatch(HardwareActions.createdHardwarelistFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(HardwareActions.createdHardwarelistFailure("Network Error"));
        }
    }
 
}

export const updatehardwarelist = (activityUpdateformdata) => {
  return async(dispatch) => {
      dispatch(HardwareActions.loaderStart("meetingloader"))

      try {
          await Api.post("/hardware/update",activityUpdateformdata).then((res) => {
              if(res.data.status === "success"){
              dispatch(HardwareActions.updatedHardwarelistSuccess(res.data));
              dispatch(gethardwarelist())
              }
              if(res.data.status ==="failure") {
                  dispatch(HardwareActions.updatedHardwarelistFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(HardwareActions.updatedHardwarelistFailure("Network Error"));
      }
  }

}

export const unAssignedUserlist = (datas) => {
  return async(dispatch) => {
      dispatch(HardwareActions.loaderStart("meetingloader"))

      try {
          await Api.post("/hardware/unassigned",datas).then((res) => {
              if(res.data.status === "success"){
              dispatch(HardwareActions.unAssignedUserSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(HardwareActions.unAssignedUserFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(HardwareActions.unAssignedUserFailure("Network Error"));
      }
  }

}



export const HardwareAssign = (datas) => {
  return async(dispatch) => {
      dispatch(HardwareActions.loaderStart("meetingloader"))

      try {
          await Api.post("/hardware/assigne",datas).then((res) => {
              if(res.data.status === "success"){
              dispatch(HardwareActions.AssignedUserSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(HardwareActions.AssignedUserFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(HardwareActions.AssignedUserFailure("Network Error"));
      }
  }

}

export const getuserslist = (data) => {
  return async(dispatch) => {
      dispatch(HardwareActions.loaderStart())

      try {
          await Api.get("/user/list").then((res) => {
          
              if(res.data.status === "success"){
              dispatch(HardwareActions.getuserslistSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(HardwareActions.getuserslistFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(HardwareActions.getuserslistFailure("Network Error"));
      }
  }

}
export const getuserslistHardware = (data) => {
  return async(dispatch) => {
      dispatch(HardwareActions.loaderStart())

      try {
          await Api.get("/user/user-list-hardware").then((res) => {
          
              if(res.data.status === "success"){
              dispatch(HardwareActions.getuserslistHardwareSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(HardwareActions.getuserslistHardwareFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(HardwareActions.getuserslistFailure("Network Error"));
      }
  }




}


  

  export default HardwareSlice.reducer;