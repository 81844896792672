import React, { useState, useRef, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format } from "date-fns";
import Viewcalendardelete from "./Viewcalendardelete";
import { GrLocation } from "react-icons/gr";
import { IoLink } from "react-icons/io5";
import { GiCharacter } from "react-icons/gi";
import { AiOutlineCopy } from "react-icons/ai";
const Viewcalendarevents = (props) => {
  // console.log("sydviewcalendar", props);
  const formattedStart = format(
    new Date(props.start_time),
    "EEEE, MMMM d, yyyy"
  );
  const formattedStarttime = format(new Date(props.start_time), "hh:mm a");
  const formattedendtime = format(new Date(props.end_time), "hh:mm a");

  const [showdeletemodal, setshowdeleteModal] = useState(false);
  const [joinurl, setjoinurl] = useState(props.event.eventurl);
  console.log("meejoinurl", joinurl);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    const copyText = props.joinUrl;

    // Create a text area element to hold the text to be copied
    const textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Clean up: remove the temporary text area
    document.body.removeChild(textArea);

    setIsCopied(true);
  };

  return (
    <div>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header>
          <Modal.Title>My calendar</Modal.Title>
          <a
            onClick={props.onHide}
            className="btn btn-sm btn-white btn-danger bottomBarButtons"
          >
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <div>
              <h3>{props.title}</h3>
              <br />
              <h5>
                {formattedStart} {formattedStarttime} - {formattedendtime}
              </h5>
              <br />
              <div>
                {joinurl && ( // Only render when joinurl is not undefined
                  <div>
                    <button
                      onClick={() => {
                        window.location.href = joinurl; // Redirect to the joinUrl
                      }}
                      className="btn btn-primary" // You can adjust the class for the desired button style
                    >
                      Join
                    </button>
                  </div>
                )}
              </div>
              <br />
              <br />
              <div style={{ fontSize: "1.0rem" }}>
                {" "}
                <GrLocation size={25} /> Microsoft Teams Meeting
              </div>
              <br />

              <div className="meeting_url">
                <IoLink size={25} /> &nbsp;
                <div className="linkdata">
                  <span
                    style={{
                      fontSize: "1.0rem",
                      color: isCopied ? "blue" : "black",
                    }}
                  >
                    {props.joinUrl}
                  </span>
                </div>
                <AiOutlineCopy
                  size={20}
                  onClick={handleCopyClick}
                  className={isCopied ? "copied-icon" : "copy-icon"}
                />
              </div>
              <br />
              <div style={{ fontSize: "1.0rem" }}>
                <GiCharacter size={20} /> {props.attendees}
                <div style={{ fontSize: "0.8rem", marginLeft: "23px" }}>
                  Organizer
                </div>
              </div>

              <br />
              <br />

              <br />
              <br />
              <button
                onClick={() => setshowdeleteModal(true)}
                className="btn btn-sm btn-white btn-danger "
              >
                delete
              </button>
              {showdeletemodal ? (
                <Viewcalendardelete
                  show={showdeletemodal}
                  onHide={() => setshowdeleteModal(false)}
                  close={props.onHide}
                  email={props.email}
                  attendees={props.attendees}
                  start={props.start}
                  end={props.end}
                  title={props.title}
                  id={props.id}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Viewcalendarevents;
