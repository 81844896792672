import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { connect, useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core";
import Select from 'react-select';
import { toast } from "react-toastify";

const AddEventReportsModal = React.memo((props) => {

  const { show, hide, from } = props

  const { handleSubmit: contactshandleSubmit } = useForm();
  const [leadsList, setLeadsList] = useState([]);
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const userData = user?.user;

  const [companiesList, setCompaniesList] = useState();
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [disable, setDisable] = React.useState(false);

  const title = useRef();
  const department = useRef();
  const leads = useRef();
  const opportunities = useRef();
  const event_date = useRef();
  const drf_status = useRef();
  const approved_date = useRef();
  const expiry_date = useRef();

  const addContactsHandle = async (e) => {

    var internal_contact = []
    if (selectedContacts?.length > 0) internal_contact = selectedContacts?.map(item => item.data_id)

    console.log("company selected", selectedCompany)
    var eventsData = {
      user_id: userData._id,
      company_name: selectedCompany?.value,
      lead_id: leads?.current?.value,
      opportunity_id: opportunities?.current?.value,
      account_id: selectedCompany?.data_id,
      event_type: from
    };
    if (from === "License Seeding") eventsData.department = department?.current.value
    if (from === "DRF") {
      eventsData.drf_status = drf_status.current.value
      eventsData.approved_date = approved_date.current.value
      eventsData.expiry_date = expiry_date.current.value
    } else {
      eventsData.guests = internal_contact
      eventsData.title = title.current.value
      eventsData.event_date = event_date.current.value
    }

    try {
      var res = await axios.post("/event-reports/create", eventsData)
      if (res.data.status === "success") {
        toast.success("Successfully Added")
        hide()
      } else {
        toast.error(res.data.msg)
      }
    } catch (err) {
      toast.error(err?.message)
    }
  }
  
  const getCompaniesList = async (search) => {
    setCompaniesList([]);
    toast.promise(axios.get(
      "/accounts/search/" + userData._id + "/" + search.target.value
    ), {
      pending: 'Fetching companies...',
      success: 'Companies fetched successfully!',
      error: 'Error fetching companies!'
    }).then((response) => {
      setCompaniesList(response.data.AccountsList);
    }).catch((error) => {
      toast.error(error)
    })
  };

  const fetchLeadsOpportunities = async (value) => {
    const res = await axios.get(
      "/leads/search-leads-opportunities/" + userData._id + "/" + value
    );
    setLeadsList(res.data.LeadsList);
    setOpportunitiesList(res.data.Opportunitieslist);
    setContactsList(res.data.ContactsLists)
  }

  const handleSetCompanyName = (value) => {
    setSelectedCompany(value)
    if(!value?.__isNew__){
      fetchLeadsOpportunities(value.data_id)
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedGetCompaniesList = debounce(getCompaniesList, 300);

  return (
    <>
      <Modal
        show={show}
        // onHide={() => closemodal()}
        backdrop={false}
        disableBackdrop={true}
        className={modalHide}
        dialogClassName="modal-90w"
        arialabelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New {from}
          </Modal.Title>
          <a
            onClick={() => hide()}
            className="btn btn-sm btn-white btn-danger bottomBarButtons"
          >
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <form key={2}>
            {
              (from !== "DRF") &&
              <div class="row">
                <div class="col-md-7">
                  <TextField
                    variant="outlined"
                    inputRef={title}
                    label="Title"
                    type="text"
                    className="form-control"
                    name="title"
                  />
                </div>
              </div>
            }
            <br />
            <div class="row">
              <div class="col-md-7 col-md-offset-2 ">
                <CreatableSelect
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Search Organization Name*"
                  onChange={handleSetCompanyName}
                  onKeyDown={debouncedGetCompaniesList}
                ></CreatableSelect>

              </div>
            </div>
            {
              (opportunitiesList?.length > 0) &&
              <>
                <br />
                <div className="row">
                  <div class="col-md-7 col-md-offset-2 ">
                    <TextField variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      type="text"
                      className="form-control"
                      inputRef={opportunities}
                      name="opportunities"
                      label="Opportunities"
                    >
                      {opportunitiesList?.map((option) => (
                        <MenuItem key={option.data_id} value={option.data_id}>
                          {option.label}
                        </MenuItem>
                      ))
                      }
                    </TextField>
                  </div>
                </div>
              </>
            }

            {(opportunitiesList?.length < 1) && (leadsList?.length > 0) &&
              <>
                <br />
                <div className="row">
                  <div class="col-md-7 col-md-offset-2 ">
                    <TextField variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      type="text"
                      className="form-control"
                      inputRef={leads}
                      name="leads"
                      label="Leads"
                    >
                      {
                        leadsList?.map((option) => (
                          <MenuItem key={option.data_id} value={option.data_id}>
                            {option.label}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                </div>
              </>
            }
            {(from !== "DRF") &&
              <>
                <br />
                <div className="row">
                  <div className="col-md-7">
                    <Select
                      isMulti
                      placeholder="Select Contacts"
                      name="colors"
                      options={contactsList}
                      className="basic-multi-select"
                      onChange={(value) => { setSelectedContacts(value) }}
                      classNamePrefix="select">
                    </Select>
                  </div>
                </div>
              </>
            }
            {
              (from === "License Seeding") &&
              <>
                <br />
                <div className="row">
                  <div className="col-md-7">
                    <TextField
                      variant="outlined"
                      inputRef={department}
                      label="Department"
                      type="text"
                      className="form-control"
                      name="department"
                    />
                  </div>
                </div>
              </>
            }
            {
              (from !== "DRF") &&
              <>
                <br />
                <div className="row">
                  <div class="col-md-7">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      inputRef={event_date}
                      label="Event Date"
                      type="date"
                      className="form-control"
                      name="event_date"
                    />
                  </div>
                </div>
              </>
            }
            {
              (from === "DRF") &&
              <>
                <br />
                <div className="row">
                  <div class="col-md-7 col-md-offset-2 ">
                    <TextField variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      type="text"
                      className="form-control"
                      inputRef={drf_status}
                      name="drf_status"
                      label="DRF Status"
                    >
                      {["DRF-SUB", "DRF-APP", "DRF-REJ", "Dropped"]?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                      }
                    </TextField>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div class="col-md-7">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      inputRef={approved_date}
                      label="Approved Date"
                      type="date"
                      className="form-control"
                      name="approved_date"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div class="col-md-7">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      inputRef={expiry_date}
                      label="Expiry Date"
                      type="date"
                      className="form-control"
                      name="expiry_date"
                    />
                  </div>
                </div>
              </>
            }
            <br />
            <div className="row">
              <div>
                <button
                  onClick={contactshandleSubmit(addContactsHandle)}
                  disabled={disable}
                  className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn"
                >
                  Submit
                </button>
                &nbsp;&nbsp;
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
});


const mapStateToProps = (state) => ({
  isFetching: state.EventTracker.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  // createEvent: (input) => dispatch(createEventReport(input)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEventReportsModal)