import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import Navbar from "../home/Navbar";
import LicenseSeeding from './LicenseSeeding';
import LunchDinnerMeet from './LunchDinnerMeet';
import DrfList from './DrfList';
import EventMeetings from "./EventMeetings";
import LunchnLearn from './LunchnLearn';
import TechDay from './TechDay';
import DemosnPresentations from "./DemosnPresentations";
import "./index.css";
import moment from "moment";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    </>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    // 'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SalesEventTracker = () => {

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [filters, setFilters] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  })

  return (
    <>
      <Navbar value="eventTracker" className="activity_list_navbar" />
      <div>
        <Tabs style={{ alignItems: 'center' }} value={value} onChange={handleChange} aria-label="basic tabs example">

          <Tab label="Meetings" {...a11yProps(0)} />

          <Tab label="Demo & Presentations" {...a11yProps(1)} />

          <Tab label="DRF"  {...a11yProps(2)} />

          <Tab label="Technology Day" {...a11yProps(3)} />

          <Tab label="Lunch & Learn"  {...a11yProps(4)} />

          <Tab label="License Seeding" {...a11yProps(5)} />

          <Tab label="Lunch & Dinner Meet" {...a11yProps(6)} />

        </Tabs>

        <TabPanel value={value} index={0}>
          <EventMeetings filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DemosnPresentations filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DrfList filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TechDay filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <LunchnLearn filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <LicenseSeeding filters={filters} setFilters={setFilters} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <LunchDinnerMeet filters={filters} setFilters={setFilters} />
        </TabPanel>
      </div>
    </>
  );
};

export default SalesEventTracker;