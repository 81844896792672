import React, { useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  options, SalesMeetingsColumns, TechActivitiesColumns,
  MetricDataColumns, OpenDrfColumns, MarketingActivitiesColumns, ForecastColumns, MonthClosuresColumns,ThisWeekDemosColumns,  getMuiTheme, headerProps
} from "./allReportsConfig";
import MUIDataTable from "mui-datatables";
import Select from 'react-select';
import AddNewMomModal from "../pageModals/AddNewMomModal";
import moment from 'moment-timezone';
import CustomFooter from '../commonColumns/CustomFooter';
import {getAllReportsData, salesIsalesTechUsersList, updateMoMStatus} from "../../store/dashboard";
import { useDispatch, useSelector, connect } from "react-redux";
import { useMemo } from "react";

const AllReportsTable = React.memo(({salesIsalesTechUsers,AllreportLoader, allReportsData, getAllReports, salesIsalesTechUsersData, updateMoMStatusData}) => {

  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  var last_week_start_date = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
  var last_week_end_date = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')

  var start_date = moment().startOf('week').format('YYYY-MM-DD')
  var end_date = moment().endOf('week').format('YYYY-MM-DD')

  var month_start = moment().startOf('month').format('YYYY-MM-DD')
  var month_end = moment().endOf('month').format('YYYY-MM-DD')

  var quarter_start = moment().startOf('quarter').format('YYYY-MM-DD')
  var quarter_end = moment().endOf('quarter').format('YYYY-MM-DD')
  
  const [selectedTab, setSelectedTab] = useState("sales_meetings")
  const [filter, setFilter] = useState({start_date:last_week_start_date, end_date : last_week_end_date})
  // const [data, setData] = useState([])
  const [addNewMoM, setAddNewMoM] = useState(false)
  // const [accountManagers, setAccountManagers] = useState([]);
  const [disabled, setDisabled] = useState(false)
  // const [snackBarMessage, setSnackBarMessage] = useState("");
  // const [open, setOpen] = useState(false);
  const [activeMomType, setActiveMomType] = useState("pending");
  const [momData, setMomData] = useState([]);

  // const dispatch = useDispatch();
  // const {  } = useSelector(state => state.dashboard);

  // console.log("allReportsNew")

  const onChange = (tabName) => {

    
    setSelectedTab(tabName)
    // fetchData(tabName)
    if(tabName === "forecast_commit_pin"){
      setDisabled(true)
    }else if(disabled === true){
      setDisabled(false)
    }

    if(tabName === "month_closures"){
      setFilter({...filter, 
          start_date: month_start,
          end_date: month_end
      })
    }else if(tabName === "quarter_marketing_activities"){
      setFilter({...filter, 
        start_date : quarter_start,
        end_date : quarter_end
      })
    }else if(tabName === "week_demo_meetings" || tabName === "summary"){
      setFilter({...filter, 
        start_date : start_date,
        end_date : end_date
      })
    }else{
      setFilter({...filter, 
          start_date: last_week_start_date,
          end_date: last_week_end_date
      })
    }
  }
  const refreshTableData = () => {
    fetchData("summary")
  }

  useMemo(() => {
    salesIsalesTechUsersData()
  }, [])

//   const getManagers = async () => {
//     const resdata = await axios.get('user/isales-tech-sales-users');
//     if (resdata.data.status === "success") {

//         setAccountManagers(resdata.data.list);
//     } else {
//     }
// }

const filterMomType = (type) => {
  setActiveMomType(type);
  var Resdata = allReportsData.filter(function (item) {
    if (item.status === type) {
      return item;
    }
  });
  setMomData(Resdata);
}

  const fetchData = async (tabName) => {

  filter.type = tabName

  getAllReports(filter)
  filterMomType(activeMomType)
    // try {
    //   const res = await axios.post("", filter);
      
    //   setData(res.data.data);

    //   if(tabName === "summary"){
    //     
    //   }
    // } catch (err) {
    // }

  }

  useMemo(() => {
  
    var tab = selectedTab ? selectedTab : "sales_meetings"
    // console.log("filter change",tab)
    fetchData(tab)
    
  }, [filter])

  const WeeklyMeetingSummaryColumsn = [
    {
        name: "subject",
        label: "Subject",
        options: {
            filter: true,
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "action_item",
        label: "Action Item",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '300px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "responsible_people",
        label: "Responsible People",
        options: {
            filter: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    left: "0",
                    background: "white",
                    zIndex: 100,
                    color: '#243d6a',
                    fontWeight: 'bold',
                    maxWidth: '300px',
                    overflow: 'auto',
                }
            }),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "action_date",
        label: "Action Date",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
               return (value?.charAt(0).toUpperCase() + value?.slice(1))
            },
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "mail_status",
        label: "Mail Status",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (value?.charAt(0).toUpperCase() + value?.slice(1))
           },
            setCellHeaderProps: headerProps,
        }
    },
    {
        name: "_id",
        label: "Actions",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              
              if(tableMeta.rowData[4] === "pending"){
                return (
                    <>
                      <button data={value} onClick={() => updateMoMStatusData(value)} id="updateCompleted" className='btn btn-success btn-sm updateStatus'>Mark as Complete</button>
                    </>
                )
              }else{
                return (
                    <span>Completed</span>
                )
              }
            },
            setCellHeaderProps: headerProps,
        }
    },
]

// const handleClose = (event, reason) => {
//   if (reason === 'clickaway') {
//       return;
//   }
//   setOpen(false);
// };





const MonthClosuresoptions = {
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyMaxHeight: '462px',
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        const startIndex = page * rowsPerPage;
        const endIndex = (page + 1) * rowsPerPage;
        return (
          <CustomFooter
            totalPoValue = {sumPoValue(startIndex, endIndex, "po_value")}
            totalToKaizenat = {sumPoValue(startIndex, endIndex, "revenue_to_kaizenat")}
            totalPrincipal = {sumPoValue(startIndex, endIndex, "principal_in_rupees")}
            rowsPerPageOptions={[20,50,70,100,200,500,1000,2000]}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
            onChangePage={(_, page) => changePage(page)}
          />
        );
      }
};

const sumPoValue = (startIndex, endIndex, column) => {
    

    if(column == "po_value"){
      return allReportsData
      .slice(startIndex, endIndex)
      .map(a => a.po_value)
      .reduce((total, salary) => (total += salary), 0);
    }

    if(column == "revenue_to_kaizenat"){
      return allReportsData
      .slice(startIndex, endIndex)
      .map(a => a.revenue_to_kaizenat)
      .reduce((total, revenue) => (total += revenue), 0);
    }
  
};
const formatWeekSelectLabel = (date, invalidLabel) => {
  return `${moment(date).format("MMM Do YYYY")}`
};

  return (

    <>
    {AllreportLoader ? 
    
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   : <> 
      <div className="row">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-3">
                  <Select
                      closeMenuOnSelect={true}
                      isMulti
                      placeholder="Select Users"
                      options={salesIsalesTechUsers}
                      onChange={(value) => setFilter({...filter, ac_manager: value })}
                  />
                            
          </div>
          {!disabled &&
            <>
              <div className="col-md-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className="form-control"
                    label="Start Date"
                    inputVariant="outlined"
                    value={filter.start_date}
                    labelFunc={formatWeekSelectLabel}
                    onChange={(date)  => setFilter({...filter, start_date: moment(date).format('YYYY-MM-DD')})}
                    // onChange={(date) => setFilter({ ...filter, start_date: date })}
                    showTodayButton
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MuiPickersUtilsProvider>
              {/* <TextField variant="outlined" type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={disabled}
                label="Start Date" name="start_date" className="form-control" value={filter.start_date} id="preliminary-review-start"
                onChange={(e) => setFilter({ ...filter, start_date: e.target.value })}
              /> */}
              
              </div>
              <div className="col-md-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className="form-control"
                    label="End Date"
                    inputVariant="outlined"
                    value={filter.end_date} 
                    labelFunc={formatWeekSelectLabel}
                    onChange={(date)  => setFilter({...filter, end_date: moment(date).format('YYYY-MM-DD')})}
                    showTodayButton
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </MuiPickersUtilsProvider>
              {/* <TextField variant="outlined" type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={disabled}
                label="End Date" name="end_date" className="form-control" value={filter.end_date}  id="preliminary-review-start"
                onChange={(e) => setFilter({ ...filter, end_date: e.target.value })}
             /> */}
              
              </div>
            </>
          }
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="sales_meetings"  style={{cursor:'pointer'}} className={`${selectedTab==="sales_meetings" ? "active_tab_allreports":"tab_allreports"}`} onClick={() => onChange("sales_meetings")}>Sales Meetings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="technical_activities" className={`${selectedTab==="technical_activities" ? "active_tab_allreports":"tab_allreports"}`} style={{cursor:'pointer'}}  onClick={() => onChange("technical_activities")} >Technical Activities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="metric_data" style={{cursor:'pointer'}} className={`${selectedTab==="metric_data" ? "active_tab_allreports":"tab_allreports"}`} onClick={() => onChange("metric_data")}>Metric Data</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="open_approved_drf" style={{cursor:'pointer'}} className={`${selectedTab==="open_approved_drf" ? "active_tab_allreports":"tab_allreports"}`}   onClick={() => onChange("open_approved_drf")}>Open/Approved DRF </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="forecast_commit_pin" style={{cursor:'pointer'}} className={`${selectedTab==="forecast_commit_pin" ? "active_tab_allreports":"tab_allreports"}`}  onClick={() => onChange("forecast_commit_pin")}>Forecast (Commit & Pin)</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="week_demo_meetings" style={{cursor:'pointer'}} className={`${selectedTab==="week_demo_meetings" ? "active_tab_allreports":"tab_allreports"}`}  onClick={() => onChange("week_demo_meetings")}>Upcoming Demo/Meetings </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="quarter_marketing_activities" style={{cursor:'pointer'}} className={`${selectedTab==="quarter_marketing_activities" ? "active_tab_allreports":"tab_allreports"}`}  onClick={() => onChange("quarter_marketing_activities")}>Marketing Activities</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="month_closures" style={{cursor:'pointer'}} className={`${selectedTab==="month_closures" ? "active_tab_allreports":"tab_allreports"}`}   onClick={() => onChange("month_closures")}>Month Closures</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="summary" style={{cursor:'pointer'}} className={`${selectedTab==="summary" ? "active_tab_allreports":"tab_allreports"}`}  onClick={() => onChange("summary")}>Summary</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="sales_meetings">

                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Sales Meetings List"}
                        data={allReportsData}
                        columns={SalesMeetingsColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="technical_activities">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Technical Activities"}
                        data={allReportsData}
                        columns={TechActivitiesColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="metric_data">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Industry Metric Data"}
                        data={allReportsData}
                        columns={MetricDataColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="open_approved_drf">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Open Approved Drf"}
                        data={allReportsData}
                        columns={OpenDrfColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="forecast_commit_pin">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Forecast Commit/Pin (Current Quarter)"}
                        data={allReportsData}
                        columns={ForecastColumns}
                        options={MonthClosuresoptions}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="week_demo_meetings">
                
                <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Upcoming Week Demo/Trainings/Benchmarks/Meetings"}
                        data={allReportsData}
                        columns={ThisWeekDemosColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="quarter_marketing_activities">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"Marketing Activities"}
                        data={allReportsData}
                        columns={MarketingActivitiesColumns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>
                <Tab.Pane eventKey="month_closures">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={"This Month Closures"}
                        data={allReportsData}
                        columns={MonthClosuresColumns}
                        options={MonthClosuresoptions}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>

                <Tab.Pane eventKey="summary">
                  <React.Fragment>
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={<div><b>Weekly Meeting MOM</b>
                        &nbsp;&nbsp;<span onClick={() => filterMomType("pending")} className={activeMomType === "pending" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Open </span>&nbsp;&nbsp;
                        <span onClick={() => filterMomType("completed")} className={activeMomType === "completed" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Closed </span>&nbsp;&nbsp;
                        <button onClick={() => setAddNewMoM(true)} class="btn btn-sm btn-primary">Add New MOM</button></div>}
                        data={momData}
                        columns={WeeklyMeetingSummaryColumsn}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </React.Fragment>
                </Tab.Pane>

                

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      {addNewMoM ? <AddNewMomModal refreshTableData={() => refreshTableData()}   show={addNewMoM}onHide={() => setAddNewMoM(false)} /> : ""}

      {/* <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            /> */}
            </>
          }
    </>
  );
  
});

// export default AllReportsTable;
const mapStateToProps = (state) => ({
  salesIsalesTechUsers: state.dashboard.salesIsalesTechUsers,
  allReportsData : state.dashboard.allReportsData,
  AllreportLoader:state.dashboard.AllreportLoader
});

const mapDispatchToProps = (dispatch) => ({
  getAllReports: (data) => dispatch(getAllReportsData(data)),
  salesIsalesTechUsersData : () => dispatch(salesIsalesTechUsersList()),
  // updateMoMStatusData : (data) => dispatch(updateMoMStatusData(data))
});

// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(AllReportsTable);