import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const DemoModal = (props) => {

  // console.log("selected products props", props)
  
  const [contactShow, setContactShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    account_id: "",
    initial_discussion: "",
    business_unit: "",
    duration: "",
    date_time: "",
    closure_date: "",
    economic_impact_of_solution: "",
    economic_buyer: "",
    decision_criteria: "",
    timeline_validation_approval: "",
    pain_areas_compelling_events: "",
    sell_on_behalf_inside_company: "",
    products_list:[]
  });
  const [productList, setProductList] = useState([]);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [accountId, setAccountId] = useState(false);
  const [ContactsShow, setContactsShow] = useState(false);

  const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));

  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const[multiProductsData,setMultiProductsData] =useState([])
  console.log("product are inside demo", multiProductsData)
  const [durationTiming, setDurationTiming] = useState([]);
  const [economicBuyers, setEconomicBuyers] = useState([]);
  const [economicBuyerList, setEconomicBuyerList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const { register: demoRegister, handleSubmit: demoHandleSubmit, setError: demoSetError, formState: { errors: demoErrors } } = useForm();
  const {user} = useSelector(state => state.auth)

  const userData = user.user;
  const permissions = user.permissions;

  var today = new Date();
  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');

  const new_date_time = useRef();
  const closure_date = useRef();

  useEffect(() => {
 const newData= props.multiProducts.map((data) =>{
  return data.value  && !data.product_id ? {name:data.label, product_id:data?.value} : {name:data?.name, product_id:data?.product_id}
 })
 setMultiProductsData(newData)
  },[props.multiProducts])

  const ProductList = async () => {
    const res = await axios.get("/products/search")
    setProductList(res.data.productsList);
  }

  const getEconomicBuyersList = async (accountId) => {

    var account = accountId ? accountId : props?.companyName;
    console.log("account in demo modal ", account)
    const res = await axios.get("/demos/economic-buyer-list/" + account);
    setEconomicBuyerList(res.data.economicBuyerList);

  }
  const getDurationTiming = () => {
    setDurationTiming([{ label: "0.5 Hours", value: '0.5 Hours' }, { label: "1 Hours", value: '1 Hours' }, { label: "1.5 Hours", value: '1.5 Hours' }, { label: "2 Hours", value: '2 Hours' }, { label: "2.5 Hours", value: '2.5 Hours' }, { label: "3 Hours", value: '3 Hours' }, { label: "3.5 Hours", value: '3.5 Hours' }, { label: "4 Hours", value: '4 Hours' }])
  }
  useEffect(() => {
    
      
      getEconomicBuyersList(props.accountId);
      getDurationTiming();
      ProductList();
  }, []);

  const addNewDemoHandle = async (e) => {

    setSnackBarMessage("Uploading Data ...!");
    setOpen(true);
    setDisable(true);
    var actionDates = data.closure_date ? data.closure_date : threedays
    const updatedData = {
      lead_id: props.allTechieActionsDetails?.lead_id || null,
      opportunity_id: props.allTechieActionsDetails?._id || null,
      account_id: props.allTechieActionsDetails ? props.allTechieActionsDetails.account_id : null,
      initial_discussion: data.initial_discussion,
      application: props.allTechieActionsDetails ? props.allTechieActionsDetails.product : null,
      products:  data.products_list ? [data.products_list]  : [],
      duration: data.duration,
      date_time: moment(newDateTime).format('YYYY-MM-DD HH:mm:ss'),
      closure_date: moment(actionDates).format('YYYY-MM-DD HH:mm:ss'),
      economic_impact_of_solution: data.economic_impact_of_solution,
      economic_buyer: data.economic_buyer,
      decision_criteria: data.decision_criteria,
      timeline_validation_approval: data.timeline_validation_approval,
      pain_areas_compelling_events: data.pain_areas_compelling_events,
      sell_on_behalf_inside_company: data.sell_on_behalf_inside_company,
    }

    
    try {


      const res = await axios.post("/demos/create", updatedData);

      if (res.data.status === "success") {

        if(!props.allTechieActionsDetails.lead_id){
          props.refreshDemoTable();
        }
        setTimeout(() => {
          setOpen(true);
          setSnackBarMessage("Demo requested successfully");
        }, 300);

        // alert("Demo Requested Successfully");
        toast.success("Demo Requested Successfully") 

        props.onHide();

      } else {
        setDisable(false);
        alert(res.data.msg);
        setSnackBarMessage(res.data.msg);
      }
      setContactShow(false);
      setDisable(true);
    } catch (err) {

      alert(err.response.data.msg);
      setDisable(false);
      setOpen(true);
      setSnackBarMessage(err.response.data.message);

      if (err.response.status === 400) {
        demoSetError("ac_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        setSnackBarMessage(err.response.data.msg)
        setTimeout(() => {
          setOpen(true);
        }, 500);

      }

    }
  }
  const handleClick = () => {
    setOpen(true);
  };
  const getAccountsList = async (search) => {

    setCompaniesList([]);
    const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
    setCompaniesList(res.data.AccountsList);
  }

  const handleSetCompanyName = (value) => {

    setSelecteAccount(value.value)
    if (value.__isNew__ === true) {
    } else {
      setAccountId(value.data_id);
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };
  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value }, []);
  };

  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    data.new_date_time = newDate;
  }
  const handleActionDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setActionDate(newDate);
    data.closure_date = newDate;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => setContactsShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}

      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Demo Request"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form key={4} >
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...demoRegister("duration", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.duration}
                  select
                  helperText={demoErrors.duration ? demoErrors.duration.message : ''}
                  error={Boolean(demoErrors.duration)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="duration" label="Duration">
                  {durationTiming.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div class="col-md-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    className="form-control"
                    label="Demo Start Date & Time"
                    inputVariant="outlined"
                    value={newDateTime}
                    onChange={handleMeetingDateTimeChange}
                    showTodayButton
                    disablePast
                    inputRef={new_date_time}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  // format='yyyy-MM-dd'
                  />

                </MuiPickersUtilsProvider>
              </div>
            </div>

            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...demoRegister("economic_buyer", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.economic_buyer}
                  select
                  helperText={demoErrors.economic_buyer ? demoErrors.economic_buyer.message : ''}
                  error={Boolean(demoErrors.economic_buyer)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="economic_buyer" label="Economic Buyer"
                >
                  {economicBuyerList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...demoRegister("products_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.products_list}
                  select
                  helperText={demoErrors.products_list ? demoErrors.products_list.message : ''}
                  error={Boolean(demoErrors.products_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="products_list" label="Select Product"
                >
                  {multiProductsData?.map((option) => (
                    <MenuItem key={option.product_id} value={option.product_id}>
                      {option.name}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
            </div>
            <br />
            {/* <div className="row">
              <div class="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...demoRegister("economic_impact_of_solution", {
                    minLength: {
                      value: 3,
                      message: "economic impact of solution be at least 3 characters"
                    }
                  })}
                  helperText={demoErrors.economic_impact_of_solution ? demoErrors.economic_impact_of_solution.message : ''}
                  error={Boolean(demoErrors.economic_impact_of_solution)}

                  type="text" label="What is the economic impact of the solution?" name="economic_impact_of_solution" className="form-control"
                  value={data.economic_impact_of_solution} onChange={e => onInputChange(e)} >

                </TextField>
              </div>

            </div> */}
            {/* <br /> */}

            {/* <div className='row'>
              <div class="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...demoRegister("decision_criteria", {
                    minLength: {
                      value: 1,
                      message: "decision_criteria be at least 1 characters"
                    }
                  })}
                  helperText={demoErrors.decision_criteria ? demoErrors.decision_criteria.message : ''}
                  error={Boolean(demoErrors.decision_criteria)}

                  type="text" label="What is their technical, vendor and financial criteria for the decision?" name="decision_criteria" className="form-control"
                  value={data.decision_criteria} onChange={e => onInputChange(e)} >

                </TextField>
              </div>
            </div>
            <br /> */}
            {/* <div className="row">
              <div class="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...demoRegister("timeline_validation_approval", {
                    minLength: {
                      value: 1,
                      message: "timeline validation approval be at least 1 characters"
                    }
                  })}
                  helperText={demoErrors.timeline_validation_approval ? demoErrors.timeline_validation_approval.message : ''}
                  error={Boolean(demoErrors.timeline_validation_approval)}

                  type="text" label="Timeline, validation and Approval Process" name="timeline_validation_approval" className="form-control"
                  value={data.timeline_validation_approval} onChange={e => onInputChange(e)} >

                </TextField>
              </div>
            </div>
            <br /> */}
            {/* <div className="row">
              <div class="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...demoRegister("pain_areas_compelling_events", {
                    minLength: {
                      value: 1,
                      message: "pain_areas_compelling_events be at least 1 characters"
                    }
                  })}
                  helperText={demoErrors.pain_areas_compelling_events ? demoErrors.pain_areas_compelling_events.message : ''}
                  error={Boolean(demoErrors.pain_areas_compelling_events)}

                  type="text" label="What are the pains, link to key business initiatives and compelling events?" name="pain_areas_compelling_events" className="form-control"
                  value={data.pain_areas_compelling_events} onChange={e => onInputChange(e)} >

                </TextField>
              </div>
            </div>
            <br /> */}
            {/* <div className="row">
              <div class="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...demoRegister("sell_on_behalf_inside_company", {
                    minLength: {
                      value: 1,
                      message: "sell_on_behalf_inside_company be at least 1 characters"
                    }
                  })}
                  helperText={demoErrors.sell_on_behalf_inside_company ? demoErrors.sell_on_behalf_inside_company.message : ''}
                  error={Boolean(demoErrors.sell_on_behalf_inside_company)}

                  type="text" label="Who will sell on your behalf inside the organization?" name="sell_on_behalf_inside_company" className="form-control"
                  value={data.sell_on_behalf_inside_company} onChange={e => onInputChange(e)} >

                </TextField>
              </div>
            </div> */}
            {/* <br /> */}
            <div className="row">
              <div class="col-md-6">
                <TextField multiline rows={2} variant="outlined"
                  row={2}
                  {...demoRegister("initial_discussion", {
                    minLength: {
                      value: 3,
                      message: "Comments must be at least 3 characters"
                    }
                  })}
                  // required
                  helperText={demoErrors.initial_discussion ? demoErrors.initial_discussion.message : ''}
                  error={Boolean(demoErrors.initial_discussion)}
                  type="text" label="Technical Discussion (Comments)" name="initial_discussion" className="form-control"
                  value={data.initial_discussion} onChange={e => onInputChange(e)} />
              </div>
            </div>
            <div className="row">
              <div>
                <a disabled={disable} onClick={() => addNewDemoHandle()} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</a>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )

}
export default DemoModal;