import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import moment from 'moment-timezone';
import HrisUpdateModel from '../pageModals/HrisUpdateModel';
import RelieveEmployeeModal from '../pageModals/RelieveEmployeeModal';
import {ContactColumnUpdateddata} from "../../store/Common"
import {HrisActions,relievehrisdata,updateColumns,getColumnsList} from "../../store/Hris"
import { connect, useDispatch, useSelector } from 'react-redux';

const HrisDetailsTable = React.memo(({hrislist,showupdateModal,isFetching,openmodal,openRelieveModal,showupdateClosemodal,rowMetaData,rowId,relievehrisdataHandle,userIdData,showRelieveModal,hrisColumnupdatedata,updateColumnsList,columnsList,getColumnsList}) => { 
  const hrisdata = hrislist 
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const dispatch = useDispatch();
  const permissions = user.permissions;
  // const [columnsList, setColumnsList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [leadTypeActive, setLeadTypeActive] = useState("Active");
  // const [rowId, setRowId] = useState("");
  // const [rowMetaData, setRowMetaData] = useState(""); 

  const handleRowClick = (rowData, rowMeta) => {
    // setShowUpdateHrisModal(true);   
    openmodal()
    // dispatch(HrisActions.showupdateOpenmodal()); 
          // setRowMetaData(rowData);
          // setRowId(hrisdata[rowMeta.dataIndex]._id) 
          rowMetaData(rowData);
          rowId(tableData[rowMeta.dataIndex]._id)
              };
 
              const RelieveEmployeeHandle = async (event,userId, value) => {
                event.stopPropagation();
                userIdData(value)
                openRelieveModal();
                // showupdateClosemodal();
                
                      // console.log("user id is and element is",userId)
                    // var input = {user_id : value, status:"block"}
                    //  relievehrisdataHandle(input);
              
              }

              const filterLeadType = async (type) => {
                
               if(type ===false){
                setLeadTypeActive("Active");
               }else{
                setLeadTypeActive("Inactive");
               }
             
                var data = [];
                // if (type === false) {
                //   data = hrisdata;
                // } else {
                  data = hrisdata.filter(function (item) {
                    if (item.blocked.toString() === type.toString()) {
                      return item;
                    }
                  });
                // }
                setTableData(data);
              }

  useEffect(() => {
    // setTableData(hrisdata);
    getColumnsList()
    filterLeadType(false)

  }, [hrisdata]);



  const columns = [
    {
      name: "_id",
      label: "id",
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "manager_id",
      label: "Reporting Manager",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('manager_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? (value.first_name + ' '+ value.last_name) : "user not found"
        ),
        setCellHeaderProps: headerProps,
    
      }
    },
    {
        name: "first_name",
        label: "First Name",
        options: {
          display: true,
          // display: columnsList?.includes('first_name') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
          filter: true,
          setCellHeaderProps: headerProps,
        }
      },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        display: true,
        // display: columnsList?.includes('last_name') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "employee_id",
      label: "Employee Id",
      options: {
        filter: true,
        display: columnsList?.includes('employee_id') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
        setCellHeaderProps: headerProps,
      }
    },
   
    {
        name: "email",
        label: "Official Email",
         options: {
          display: columnsList?.includes('email') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "phone",
        label: "Phone",
         options: {
          display: columnsList?.includes('phone') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
    {
      name: "job_title",
      label: "Desigination",
      options: {
        display: columnsList?.includes('job_title') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
         value ? value : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    }, 

{
        name: "company",
        label: "Company",
        options: {
          display: columnsList?.includes('company') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
         value ? value : ""
        ),
          filter: true,
          setCellHeaderProps: headerProps,
        }
      }, 
    {
      name: "date_of_join",
      label: "Date Of Join",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('date_of_join') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    
{
        name: "previous_company",
        label: "Previous Company",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('previous_company') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      },
  {
          name: "no_of_yrs_of_experience",
          label: "No.Of.Years Of Experience(Before Joining Kaizenat)",
          options: {
            filter: true,
            sort: true,
            display: columnsList?.includes('no_of_yrs_of_experience') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
           value ? value : ""
          ),
            setCellHeaderProps: headerProps,
          }
        },
    {
        name: "gender",
        label: "Gender",
        options: {
            filter: true,
            sort: true,
            display: columnsList?.includes('gender') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
             value ? value : ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "DOB",
        label: "D.O.B",
        options: {
          display: columnsList?.includes('DOB') ? true : false,
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
{
          name: "blood_group",
          label: "Blood Group",
          options: {
            display: columnsList?.includes('blood_group') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
             value ? value : ""
            ),
              filter: true,
              setCellHeaderProps: headerProps,
            }
        },
   
{
          name: "highest_education_qualification",
          label: "Highest Education Qualification",
          options: {
            display: columnsList?.includes('highest_education_qualification') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
             value ? value : ""
            ),
              filter: true,
              setCellHeaderProps: headerProps,
            }
        },
  {
            name: "current_address",
            label: "Current Address",
            options: {
              setCellProps: (value, tableMeta) => (
                {
                  style: {
                    whiteSpace: "nowrap",
                    textColor: '#FFFFFF',
                    fontWeight: 'bold',
                    width: '320px',
                    maxWidth: '320px',
                    overflow: 'auto',
                    color: '#243d6a',
                  }
                }),
                display: columnsList?.includes('current_address') ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => (
                 value ? value : ""
                ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "permanent_address",
            label: "Permanent Address",
            options: {
              setCellProps: (value, tableMeta) => (
                {
                  style: {
                    whiteSpace: "nowrap",
                    textColor: '#FFFFFF',
                    fontWeight: 'bold',
                    width: '320px',
                    maxWidth: '320px',
                    overflow: 'auto',
                    color: '#243d6a',
                  }
                }),
                display: columnsList?.includes('permanent_address') ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => (
                 value ? value : ""
                ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "emergency_contact_number",
            label: "Emergency Contact Number",
            options: {
              display: columnsList?.includes('emergency_contact_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "personal_contact_number",
            label: "Personal Contact Number",
            options: {
              display: columnsList?.includes('personal_contact_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "personal_email_id",
            label: "Personal Email Id",
            options: {
              display: columnsList?.includes('personal_email_id') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "aadhar_number",
            label: "Aadhar Number",
            options: {
              display: columnsList?.includes('aadhar_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "PAN_number",
            label: "PAN Number",
            options: {
              display: columnsList?.includes('PAN_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
    
          {
            name: "person_name",
            label: "Person Name",
            options: {
              display: columnsList?.includes('person_name') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
         {
            name: "relation_with_employee",
            label: "Relation With Employee",
            options: {
              display: columnsList?.includes('relation_with_employee') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "remarks",
            label: "Remarks",
            options: {
              display: columnsList?.includes('remarks') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "leave_balance",
            label: "Leave Balance",
            options: {
              display: columnsList?.includes('leave_balance') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "name_on_pass_book",
            label: "Name On Pass Book",
            options: {
              display: columnsList?.includes('name_on_pass_book') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
         {
            name: "salary_account_number",
            label: "Salary Account Number",
            options: {
              display: columnsList?.includes('salary_account_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "IFSC_code",
            label: "IFSC Code",
            options: {
              display: columnsList?.includes('IFSC_code') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
         {
            name: "branch_name",
            label: "Branch Name",
            options: {
              display: columnsList?.includes('branch_name') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },  
          {
            name: "pf_uan_number",
            label: "PF UAN Number",
            options: {
              display: columnsList?.includes('pf_uan_number') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },  
          {
            name: "insurance_id",
            label: "Insurance Id",
            options: {
              display: columnsList?.includes('insurance_id') ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => (
               value ? value : ""
              ),
                filter: true,
                setCellHeaderProps: headerProps,
              }
          },
          {
            name: "blocked",
            label: "Action",
            options: {
              filter: true,
              sort: false,
              empty: true,
              display: columnsList?.includes('blocked') ? true : false,
              onRowClick: false,
              setCellHeaderProps: headerProps,
              customBodyRender: (value, tableMeta, updateValue) => {
                var status = tableMeta.rowData[33]
                
                if (status === "false") {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                  return (
                    <div style={{ display: "flex" }}>                                                                        
                      {/* {permissions.includes("updateOwnDemosTimings") || permissions.includes("updateOwnDemosTimings") ? */}
                        <a  onClick={(e) => { RelieveEmployeeHandle(e, value, tableMeta.rowData[0]) }}>
                          <button style={{ background: '#cd485c' }}  className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Relieve</button>
                        </a>
                        {/* : false} */}
                    </div>
                  );
                } else {
                  return "Relieved"
                }
              }
      
            }
          },
  ];
  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found..." 
 }



  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 190).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 170).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },

      onViewColumnsChange: async (changedColumn, action) => {
        var columns = Object.assign([], columnsList)
        
        if (action === "add") {
          columns.push(changedColumn);
        }
        if (action === "remove") {
          columns = columns.filter(item => item !== changedColumn);
      }
        const updatedData = {
          user_id: userData ? userData._id : null,
          table_name: "hris",
          columns_list: columns,
        }
        hrisColumnupdatedata(updatedData)
        updateColumnsList(columns)
      },

   
    download: false,

    onRowClick: handleRowClick,
  };
  return (
   <>
            <React.Fragment>
                {/* <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Human Resource Information System"}
                        data={hrisdata}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider> */}
         <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems:"center" }} >
              <b>Human Resource Information System</b>&nbsp;&nbsp;&nbsp;
              <span onClick={() => filterLeadType(false)} className={leadTypeActive === "Active" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Active </span>&nbsp;&nbsp;
              <span onClick={() => filterLeadType(true)} className={leadTypeActive === "Inactive" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Inactive </span>&nbsp;&nbsp;
            </div>
           
            }
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
   </React.Fragment>
             {showupdateModal ? <HrisUpdateModel />:""}
             {showRelieveModal ? <RelieveEmployeeModal /> : ""}
    </>                       
  )
});
const mapStateToProps = (state) => ({
  user : state.auth.user,
 hrislist : state.Hrislist.hrislist,
 showupdateModal: state.Hrislist.showupdatemodal,
 showRelieveModal  :state.Hrislist.showRelievemodal,
 isFetching : state.Hrislist.isFetching,
 columnsList:state.Hrislist.columnsList
})
const mapDispatchToProps = (dispatch) => ({
  hrisColumnupdatedata:(data) => dispatch(updateColumns(data)),
  openmodal:(data) => dispatch(HrisActions.showupdateOpenmodal(data)),
  openRelieveModal:(data) => dispatch(HrisActions.showrelieveOpenmodal(data)),
  showupdateClosemodal:(data) => dispatch(HrisActions.showupdateClosemodal(data)),
  rowMetaData:(data) => dispatch(HrisActions.setrowMetaData(data)),
  rowId:(data) => dispatch(HrisActions.setRowId(data)),
  userIdData:(data) =>dispatch(HrisActions.userIdData(data)),
  relievehrisdataHandle:(data) => dispatch(relievehrisdata(data)),
  updateColumnsList:(data) => dispatch(HrisActions.updateColumns(data)),
  getColumnsList:(data) => dispatch(getColumnsList(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (HrisDetailsTable)

