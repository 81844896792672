import React, { useState, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {CategoriesList, config, lineChartlayout,AccountMangers } from './chartConfig'
import {fetchTechiesList, getLineChartTechData} from "../../store/dashboard";
import { connect } from "react-redux";
import Badge from 'react-bootstrap/Badge'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
const PlotlyComponent = createPlotlyComponent(Plotly);

const LineChart = React.memo(({techiesList,lineChartTechData, fetchTechiesListData, getLineChartTechDataList,LinechartLoader}) => {
    const [filterTechielist, setfilterTechielist] = useState([]);
    const [filterTypeList, setfilterTypeList] = useState([]);
    // if (filterTypeList.length === 0) {
    //     setfilterTypeList([{ value: "Trainings", label: "Trainings" }]);
    //   }
    var str = "Demos";
    var new_str = str.replace(/-/g, '');
    const [columnsDisplay, setColumnsDisplay] = useState(true);
    const [showChart, setShowChart] = useState(true);
    const handle = useFullScreenHandle();
   //  console.log("ASDS",new_str);
    // console.log("techielist",filterTypeList)
    const [filter, setFilter] = useState({});
// console.log("linechartfilterss",filter)
const categoriesList = [{value: "Demos", label: "Demos"}, {value: "Trainings", label:"Trainings"}, {value: "Tickets", label:"Tickets"}, {value: "Benchmarks", label:"Benchmarks"}, {value: "Meetings", label: "Meetings"}]

 
    const handleTechielistChange = (value) => {
        // console.log("handleTechielistChange",value)
        setfilterTechielist(value);
      }
      const handleTypeChange   =  (value) => {
        console.log("handleTypeChange",value)
        setfilterTypeList(value);
      }

    const getfetchTechiesList = async () => {
if(filterTechielist && filterTechielist?.length>0){
    var Techielist = filterTechielist?.map(item => {

        return item.value;
      }); 
}
  
    var TypeList = filterTypeList?.map(item => {

        return item.value;
      });
      
    var fil = {
        type: filter.type?filter.type:str,
        user_id : Techielist,
      }
getLineChartTechDataList([fil,str])
    }
  
  
    useMemo(() => {
      
        getfetchTechiesList();
      }, [filterTechielist,filterTypeList,filter]);


      const removeMonthrvalueBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadgetype", inputValue);
        var updatedMonth = filter.type === inputValue ? "" : filter.type;
        setFilter({ ...filter, type: updatedMonth });
      }
      
  
      const removeFilterTypeBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
    
        const filtertype = filterTypeList?.filter(item => item.value !== inputValue);
        setfilterTypeList(filtertype);
      }

      const removeFilterTechielistBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
    
        const filteTechielist = filterTechielist?.filter(item => item.value !== inputValue);
        setfilterTechielist(filteTechielist);
      }

       
  const containerRef = useRef(null); 
  const downloadContent = () => {
  const container = containerRef.current
  
  toPng(container)
  .then(function(dataUrl) {
    const link = document.createElement('a')
    link.download = 'LineChart.png';
    link.href = dataUrl;
    link.click();
  })
  .catch(function(error) {
    console.log("error:",error)
  });
  
  }
  
   
  const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
    modeBarButtonsToAdd: [
      {
        name: 'Download',
        icon: Plotly.Icons.camera, // Download icon
        click: function() {
          // Handle click on custom lock icon
          // alert('Custom Lock Icon Clicked!');
          toast.success("Downloaded Successfully!")
          // Download the chart as PNG
          downloadContent();
        },
        title: 'Download As Png'
      },
  
    ],
  };
  

  const reportChange = ((state, handle) => {
    var lineChartlayoutChart = document.getElementById("lineChartlayoutChartComponents");
    if (lineChartlayoutChart) {
      lineChartlayoutChart = document.getElementById("lineChartlayoutChartComponents").getElementsByClassName("user-select-none")[0]
      lineChartlayoutChart.id = "lineChartlayoutChartComponents";
    }
    if (state) {
      if (lineChartlayoutChart) {
        document.getElementById("lineChartlayoutChartComponents").style.height = (window.innerHeight - 60).toString() + 'px';
      }
   
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (lineChartlayoutChart) {
        document.getElementById("lineChartlayoutChartComponents").style.height = "450px";
      }
      setColumnsDisplay(true);
     
    }

  });
    return (
        <>
        {LinechartLoader ? 

            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
        <>
        <div ref={containerRef}>  
        <FullScreen enabled={true} handle={handle} onChange={reportChange} > 
            <div className="container-fluid py-2 px-2">
                <div className="row" style={{ margin: '0px', padding: '0px' }}>
                    <div className="col-md-3 col-lg-6">

                  <CategoriesList
                  defaultValue={{ label: "Demos", value: "Demos" }}
                  placeholder={"Categories List"}
                  categoriesList={categoriesList}
                  // defaultValue = {filter.type}
                  disableAccountManagerFilter={filter.disableAccountManagerFilter || false}
                  updateFilter={(value) => setFilter({ ...filter, type: value })}
              />
                     {/* 
                       <CategoriesList
                            defaultValue={{ label: "Trainings", value: "Trainings" }}
                            placeholder={"Categories List"}
                            categoriesList={categoriesList}
                            disableCategoryFilter={false}
                            updateFilter={(value) => setFilter({ ...filter, type: value })} />
                             */}  
                    </div>
                    <div className="col-md-3 col-lg-6">
                    <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    options={techiesList}
                    placeholder="Users List"
                    name="techielist"
                    defaultValue={[]}
                    onChange={handleTechielistChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />   
          {/* <AccountMangers
                placeholder={"Techies List"}
                accountManagers={techiesList}
                disableAccountManagerFilter={filter.disableAccountManagerFilter || false}
                updateFilter={handleTechieFilterUpdate}
                defaultValue={[]}
            /> 
        */}
        </div>
        </div>
            </div>
            <div className="row">
            <div className="col-md-12">

              {
                filter.type ?
  
                filter.type&& 
                <Badge onClick={removeMonthrvalueBadge}
                pill data={filter.type} value={filter.type} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.type}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                    :
                    <Badge
                    pill data={"Demos"}  value={"Demos"}  style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{"Demos"} <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                  }

            {
                filterTypeList?.length > 0 ?
                filterTypeList.map((filter) => (
                    <Badge onClick={removeFilterTypeBadge}
                      pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                  ))
                  : ""
              }

            {
                filterTechielist?.length > 0 ?
                filterTechielist.map((filter) => (
                    <Badge onClick={removeFilterTechielistBadge}
                      pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                  ))
                  : ""
              }
            </div>
            </div>
                    <PlotlyComponent
                    id="lineChartlayoutChartComponents"
                        data={JSON.parse(JSON.stringify(lineChartTechData))}
                        layout={{ ...lineChartlayout, title: `Monthly Pre Sales Chart (${filter.type || 'Demo'})` }}
                        useResizeHandler
                        className="w-full h-full"
                        config={config}
                    />
                    </FullScreen>
                </div>
            </>
        }
        </>
    )
});

const mapStateToProps = (state) => ({
    techiesList : state.dashboard.techiesList,
    lineChartTechData : state.dashboard.lineChartTechData,
    LinechartLoader:state.dashboard.LinechartLoader,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetchTechiesListData: () => dispatch(fetchTechiesList()),
    getLineChartTechDataList : (data) => dispatch(getLineChartTechData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LineChart);