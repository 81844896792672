import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Navbar from '../home/Navbar';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Users from '../../components/commonColumns/Users'

export default function Permissions() {

    const [data, setData] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [projectModules, setProjectModules] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [userId, setUserId] = useState();
    const [projectModuleId, setProjectModuleId] = useState();

    const fetchUsers = async () => {
        try {
            const res = await axios.get("/user/reports//all-users-list");

            setUsersList(res.data.usersList);
        } catch (err) {

        }
    }


    const fetchProjectModules = async () => {
        try {
            const res = await axios.get("/project-modules/list");

            setProjectModules(res.data.projectModulesList);

        } catch (err) {

        }
    }

    const fetchSubModules = async (projectModuleId, userId) => {
        try {
            const res = await axios.post("/user-modules-permissions/permissions/list", { module_id: projectModuleId, user_id: userId });

            setPermissions(res.data.permissonList);
        } catch (err) {

        }
    }


    const updatePermission = async (user_id, module_id, sub_module_id, permission_status) => {

        var status = "";
        if (permission_status) {
            status = "delete";
        } else {
            status = "update";
        }

        try {

            const res = await axios.post("/user-modules-permissions/create", {
                user_id: user_id,
                module_id: module_id,
                sub_module_id: sub_module_id,
                action: status
            });



            alert(res.data.msg);

        } catch (err) {


        }


    }


    useEffect(() => {
        fetchUsers();
        fetchProjectModules();
    }, []);

    useEffect(() => {


        fetchSubModules(projectModuleId, userId);

    }, [userId, projectModuleId]);


    const onInputChange = (e) => {

        if (e.target.name === "user_id") {
            setUserId(e.target.value);
        }
        if (e.target.name === "project_module_id") {

            setProjectModuleId(e.target.value);

        }

    };


    return (
        <>
            <Navbar value="permissions" className="activity_list_navbar" />
            
            <div class="container-fluid">
                <Tab.Container id="left-tabs-example" defaultActiveKey="permissions">
                    <Row>
                        <Col sm={2} className="pad-0 bg-blue" >
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="permissions" >Permissions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links"  eventKey="users"  >Users</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="create_permissions"   >Create Permissions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="block_users"   >Block/Inactivate User</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="regional_manager"   >Regional Manager</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="update_user"   >Update User</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="user_roles"   >User Roles</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="users_new"  >Users</Nav.Link>
                                </Nav.Item>
                                
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="update_regional_manager"  >Update Regional Manager</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="update_test"  >Regional Manager</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="update"  >Region</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="test"  > Manager</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="test1"   > Manager</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav-links" eventKey="test2"  >Regional</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <br />
                            <Tab.Content>
                                <Tab.Pane eventKey="permissions">

                                    <React.Fragment>
                                        <div className='row'>
                                            <div className='col-md-3'>

                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    label="Users"
                                                    name="user_id"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        usersList.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>

                                            <div className='col-md-3'>
                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    name="project_module_id"
                                                    label="Project Modules"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        projectModules.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>
                                        </div>

                                        <br />
                                        <div className='row'>
                                            {
                                                permissions.map(permission => (

                                                    <>
                                                            <div className='col-md-1'>
                                                                <input type="checkbox"
                                                                    checked={permission.permission_exists}
                                                                    label={permission.sub_module_name}
                                                                    onChange={() => updatePermission(permission.user_id, permission.module_id, permission.sub_module_id, permission.permission_exists)}
                                                                />

                                                            </div>
                                                            <div className='col-md-3'>
                                                                <span>{permission.sub_module_name}</span>
                                                            </div>
                                                    </>

                                                )
                                                )
                                            }
                                        </div>

                                    </React.Fragment>
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <React.Fragment>
                                        <Users />
                                    </React.Fragment>
                                </Tab.Pane>

                                <Tab.Pane eventKey="">
                                    <React.Fragment>

                                    </React.Fragment>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )

}