import React, { useEffect, useState } from 'react';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { getMuiTheme } from "../../components/commonColumns/commonComponents";
import MUIDataTable from "mui-datatables";
import { LicenseSeedingColumns, options } from './commonProps';
import AddEventReportsModal from "./AddEventReportsModal";
import RenderDateFilter from "./RenderDateFilter";
import { useSelector, useDispatch, connect } from 'react-redux';
import { getEventReports } from "../../store/EventTracker";

const LicenseSeeding = (props) => {

    const { filters, setFilters, licenseSeeding, getLicenseSeeding, isFetching } = props
    const [addLicenseSeeding, setAddLicenseSeeding] = useState(false)

    const { user } = useSelector(state => state.auth)
    const permissions = user.permissions;

    const handleAddClick = async () => {
        fetchData()
        setAddLicenseSeeding(!addLicenseSeeding)
    }

    const fetchData = () => {
        let input = {
            event_type: "License Seeding",
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        getLicenseSeeding(input)
    }

    useEffect(() => {
        fetchData()
    }, [filters])

    return (
        <>
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                            <b>License Seeding List</b>&nbsp;&nbsp;&nbsp;
                            {permissions.includes("canCreateEventReport") && <span onClick={() => handleAddClick()} className={"badges badge-pill cursor-pointer badge-primary"}> Add </span>}&nbsp;&nbsp;
                            <RenderDateFilter filters={filters} setFilters={setFilters} />
                        </div>
                        }
                        data={licenseSeeding}
                        columns={LicenseSeedingColumns}
                        options={options(isFetching)}
                    />
                </MuiThemeProvider>
            </React.Fragment>
            {addLicenseSeeding && <AddEventReportsModal show={addLicenseSeeding} hide={handleAddClick} from="License Seeding" />}
        </>
    )
}

const mapStateToProps = (state) => ({
    isFetching: state.EventTracker.isFetching,
    licenseSeeding: state.EventTracker.licenseSeeding
});

const mapDispatchToProps = (dispatch) => ({
    getLicenseSeeding: (params) => dispatch(getEventReports(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseSeeding);