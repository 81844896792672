import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { connect, useDispatch, useSelector } from 'react-redux';
import {TrainingAssignUpdateddata,TrainingActions} from "../../store/Training"
import Select from 'react-select';

  const AssignTrainingModal = React.memo (({trainingListProducts,TrainingUpdated,Assign_trainingRowid,openshowModal,closemodal}) => {
    const type = "training";
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [rejectShow, setRejectShow] = useState(false);
  const [techieList, setTechieList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [demoData, setDemoData] = useState({
    techie_names: '',
    product_list: '',
    comment: "",
  })
  useEffect(() => {
    var lists = trainingListProducts.map(item => item.name);
    var filtered = productsList.filter((option) => lists.includes(option.label))
    setDemoData({product_list: filtered})
  }, [productsList])
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const getTechieList = async () => {
    const res = await axios.get("/user/techies-list");
    setTechieList(res.data.techiesList)
  }
  const getProductsList = async () => {
    const res = await axios.get("/products/search");
    setProductsList(res.data.productsList);
  }
  useEffect(() => {
    getTechieList();
    getProductsList();
  }, [])

  const assignSubmit = async (data) => {
    setDisable(true);
    var selectedProducts = demoData?.product_list?.map((option) => option.value);
    var updatedData = {
      'approval_status': "approved",
      'training_id':Assign_trainingRowid,
      'assigned_to': demoData.techie_names,
      'application': selectedProducts[0],
      'remarks': demoData.comment,
      'products': selectedProducts,
    }
    TrainingUpdated(updatedData)
  
  }
  
  const onInputChange = (e) => {
    setDemoData({ ...demoData, [e.target.name]: e.target.value }, []);
  };

  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
           <h5> {(userData.user_role_id.role_name === "user") ? "Assign Training" : "Assign Training"} </h5>
          </Modal.Title>
          <a onClick={() => closemodal()}  className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Assign Training</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...rejectRegister("techie_names", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.techie_names}
                  select
                  helperText={rejectErrors.techie_names ? rejectErrors.techie_names.message : ''}
                  error={Boolean(rejectErrors.techie_names)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="techie_names" label="Techie Name"
                >
                  {techieList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
              <div class="col-md-6">

                <Select
                  // defaultValue={}
                  isMulti
                  name="product_list"
                  label="Product"
                  placeholder="Select Products"
                  options={productsList}
                  className="basic-multi-select"
                  onChange={value => onInputChange({target: {name: "product_list", value:value}})}
                  value={demoData.product_list}
                  classNamePrefix="select">

                  </Select>

                {/* <TextField variant="outlined"
                  {...rejectRegister("product_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.product_list}
                  select
                  helperText={rejectErrors.product_list ? rejectErrors.product_list.message : ''}
                  error={Boolean(rejectErrors.product_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="product_list" label="Product List"
                >
                  {productsList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField> */}

              </div>

            </div>
            <br />

            <div className="row">
              <div className="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...rejectRegister("comment", {
                    required: {
                      value: true,
                      message: "comment is required"
                    },
                    minLength: {
                      value: 5,
                      message: "comment must be at least 5 characters"
                    }

                  })}
                  helperText={rejectErrors.comment ? rejectErrors.comment.message : ''}
                  error={Boolean(rejectErrors.comment)}
                  type="text" className="form-control" value={demoData.comment} name="comment" label="Remarks" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <button disabled={disable} type="submit"  className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  Assign_trainingRowid : state.Traininglist.trainingListid,
  trainingListProducts : state.Traininglist.trainingListProducts,
});

const mapDispatchToProps = (dispatch) => ({

    TrainingUpdated: (data) => dispatch(TrainingAssignUpdateddata(data)),
    openshowModal:(data) => dispatch(TrainingActions.assign_openModal(data)),
    closemodal:(data) => dispatch(TrainingActions.assign_closeModal(data)),
  
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignTrainingModal);
