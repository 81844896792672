import React, { useState, useEffect, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import moment from "moment";
import {SelectYear, config, OpporunitiesSalesChart,MonthlyFilter,UsersListFilter } from './chartConfig'
import Badge from 'react-bootstrap/Badge'
import {fetchTechiesList, getManagers, getLineSalesChartData} from "../../store/dashboard";
import { connect } from "react-redux";
import { opportunitiesActions } from "../../store/opportunities";
import Select from 'react-select';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineBarChart } from 'react-icons/ai';
import { AiOutlineDatabase } from 'react-icons/ai';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import OpportunitiesTable from "../commonColumns/OpportunitiesTable";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'

const PlotlyComponent = createPlotlyComponent(Plotly);


const SalesStageChart = React.memo(({yearsList, accountManagersList, lineChartSalesData, getLineSalesChartDataList, getManagersData,SalesstageLoader,setOpportunitiesDataTabel}) => {
// console.log("acoountmnagerslist",accountManagersList)

    var defaultMonth = { label: "All", value: false };
  
    var deYear = moment().format('YY');
    var deYearName = moment().format('YYYY')
    var defaultYear = {value: parseInt(deYear), label:deYearName}
  
    const currentYear = moment().year();

    const [showChart, setShowChart] = useState(true);
    const handle = useFullScreenHandle();
    const [tableHeight, setTableHeight] = useState("310px");
    const[tableData,setTableData] =useState([])
    const [clickData, setClickData] = useState(null);
   
    const [columnsDisplay, setColumnsDisplay] = useState(true);

    const [filter, setFilter] = useState({});
    console.log("salestagechartfilter",filter)
    const [filterTechielist, setfilterTechielist] = useState([]);
    // const years = [{ value: 2021, label: 2021 }, { value: 2022, label: 2022 }, { value: 2023, label: 2023 }, { value: 2024, label: 2024 }]

    // var  monthsList = [{ label: "All", value: false }, { label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }]
    var  monthsList = [{ label: "All", value: "All" }, { label: 'January', value: 'January' }, { label: 'February', value: "February" }, { label: 'March', value: "March" }, { label: 'April', value: "April" }, { label: 'May', value: "May" }, { label: 'June', value: "June" }, { label: 'July', value: "July" }, { label: 'August', value: "August" }, { label: 'September', value: "September" }, { label: 'October', value: "October" }, { label: 'November', value: "November" }, { label: 'December', value: "December" }]
    const handleFilterUpdate = (value) => {
        if (Array.isArray(value) && value.length > 0) {
            setFilter({ ...filter, user_id: value });
            // setFiltersApplied(true);
        } else {
            setFilter({ ...filter, user_id: undefined });
            // setFiltersApplied(false);
        }
    };

  

    const handleTechielistChange = (value) => {
      // console.log("handleTechielistChange",value)
      setfilterTechielist(value);
    
    }

    const getSaleschartlistChange = async () => {
      var yearvalue="";
      let monthvalue;

      if(filterTechielist && filterTechielist?.length>0){
        var Techielist = filterTechielist?.map(item => {
    
            return item.value;
          }); 
    }

  if(filter.year){
    yearvalue = filter.year.value
  }
    // Months//
if (filter?.month === "All") {
  monthvalue = false
}if (filter?.month === "January") {
monthvalue = 1;
} else if (filter?.month === "February") {
monthvalue = 2;
} else if (filter?.month === "March") {
monthvalue = 3;
} else if (filter?.month === "April") {
monthvalue = 4;
}else if (filter?.month === "May") {
monthvalue = 5;
}else if (filter?.month === "June") {
monthvalue = 6;
}else if (filter?.month === "July") {
monthvalue = 7;
}else if (filter?.month === "August") {
monthvalue = 8;
}else if (filter?.month === "September") {
monthvalue = 9;
} else if (filter?.month === "October") {
monthvalue = 10;
}else if (filter?.month === "November") {
monthvalue = 11;
} else if (filter?.month === "December") {
monthvalue = 12;
} else {
monthvalue =null;
}

   
  var fil = {
    year : yearvalue?yearvalue:currentYear,
      month: monthvalue,
      user_id :  filter.userlist ? [filter.userlist?.value] : null
    }
    getLineSalesChartDataList([fil,currentYear])
  }

    
  useMemo(() => {
    getManagersData();
  }, []);

   
  useMemo(() => {
    getSaleschartlistChange()
    // getLineSalesChartDataList(filter)
}, [filterTechielist,filter]);

const removeYearProductListBadge = (value) => {
  var inputValue = value.target.getAttribute('value');
  console.log("yearbadge1",inputValue)
  // var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
  var Yearfilter = filter.year.value.toString() === inputValue.toString() ? "" : filter.year.value;
  console.log("yearbadge1/", Yearfilter);

  setFilter({...filter, year: Yearfilter})
}

const removeMonthrvalueBadge = (value) => {
  var inputValue = value.target.getAttribute('value');
  console.log("yearbadgemonth", inputValue);
  var updatedMonth = filter.month === inputValue ? "" : filter.month;
  setFilter({ ...filter, month: updatedMonth });
}
const removeFilterTechielistBadge = (value) => {
  var inputValue = value.target.getAttribute('value');

  const filteTechielist = filterTechielist?.filter(item => item.value !== inputValue);
  setfilterTechielist(filteTechielist);
}


const removeFilterUserlistBadge = (value) => {
  var inputValue = value.target.getAttribute('value');
  console.log("yearbadge2", inputValue);
  var filteuserlist = filter.userlist.value === inputValue ? "" : filter.userlist.value;
  setFilter({ ...filter, userlist: filteuserlist });
}


useMemo(() => {
  // setOpportunitiesDataTabel(lineChartSalesData.tableDatas)
  setTableData(lineChartSalesData.tableDatas)
},[lineChartSalesData])


const reportChange = ((state, handle) => {
  var lineChartSalesDataChart = document.getElementById("lineChartSalesDataChartComponent");
  if (lineChartSalesDataChart) {
    lineChartSalesDataChart = document.getElementById("lineChartSalesDataChartComponent").getElementsByClassName("user-select-none")[0]
    lineChartSalesDataChart.id = "regionsChart";
  }
  if (state) {
    if (lineChartSalesDataChart) {
      document.getElementById("lineChartSalesDataChart").style.height = (window.innerHeight - 60).toString() + 'px';
    }

    setTableHeight((window.innerHeight - 160).toString() + 'px');
    // setRowsPerPage(15);
    setColumnsDisplay(false);
    // setFilterDisplay(false);
  } else {
    if (lineChartSalesDataChart) {
      document.getElementById("lineChartSalesDataChart").style.height = "450px";
    }

    setTableHeight('333px');
    // setRowsPerPage(10);
    setColumnsDisplay(true);
    // setFilterDisplay(true);
  }
});


const chartButtonClicked = async () => {

  setShowChart(true);
  
}
const ChartShowHandle =() => {
  setShowChart(false);
  // getLineSalesChartDataList(filter)
}

const handleClick = data => {
  if (data.points) {
    const point = data.points[0];
    console.log("sales marker point are", point)
    // alert(`You clicked on  ${point.text}.`);
  //   console.log("data are inside", dangerouslySetInnerHTML={{ __html: ${point.text} }})
  }
};

const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'Sales-Stage-chart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:",error)
});

}

 
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};

  return (
    <>
    {SalesstageLoader  ?
    
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
  <>
  <div ref={containerRef}>  

      <FullScreen enabled={true} handle={handle} onChange={reportChange} >
    
        <div className="container-fluid py-2 px-2">
        <div className="row" style={{ margin: '0px', padding: '0px' }}>
        <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => ChartShowHandle(filter)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '6%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                    <div className="col-md-3 col-lg-3">
                    <SelectYear defaultYear = {defaultYear} years={yearsList}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                    </div>
                    <div className="col-md-3 col-lg-3">
                    <MonthlyFilter defaultMonth={defaultMonth} values={filter.month} monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilter({ ...filter, month: value }) } />
                    </div>
                    <div className="col-md-3 col-lg-3">
                    <UsersListFilter values={filter.userlist}  selectSingle ={true} placeholder={"User List"} productFamilyList={accountManagersList} disableCategoryFilter={false}  updateFilter={ (value) => setFilter({ ...filter, userlist: value }) } />
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-12">
        {
          filter.year ?
          filter.year && 
            <Badge onClick={removeYearProductListBadge}
            pill data={filter.year.value} value={filter.year.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter?.year?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
        
        :
        <Badge 
        pill data={currentYear} value={currentYear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentYear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }

        {
          filter.month && 
          <Badge onClick={removeMonthrvalueBadge} pill data={filter.month} value={filter.month} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.month}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
        }
        {
          filter.userlist && 
          <Badge onClick={removeFilterUserlistBadge}
              pill data={filter.userlist?.value} value={filter.userlist?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.userlist?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
        }
       
        </div>
        </div>
                  {showChart ?
                       <PlotlyComponent
                       id="lineChartSalesDataChartComponents"
                       // data={JSON.parse(JSON.stringify(lineChartSalesData))}
                       data={lineChartSalesData?.chartData}
                       layout={OpporunitiesSalesChart}
                       useResizeHandler
                       className="w-full h-full"
                       config={config}
                       onClick={handleClick}
                       onSelected={setClickData}
                   />
                    : <React.Fragment>
                          <OpportunitiesTable  salesStageChartTableData={tableData} keyData={"salesStageChart"} tableHeight={tableHeight} />
                        {/* <OpportunitiesTable data={lineChartSalesData.tableData} oppportunityType="open" tableHeight={tableHeight} /> */}
                    </React.Fragment>}
           
         </FullScreen>
         </div>
            </>
          }
    </>
)

});



const mapStateToProps = (state) => ({
  yearsList : state.dashboard.yearsList,
  accountManagersList : state.dashboard.accountManagersList,
  lineChartSalesData : state.dashboard.lineChartSalesData,
  SalesstageLoader:state.dashboard.SalesstageLoader,
});

const mapDispatchToProps = (dispatch) => ({
  setOpportunitiesDataTabel : (data) => dispatch(opportunitiesActions.opportunitiesListSuccess(data)),
  fetchTechiesListData: () => dispatch(fetchTechiesList()),
  getLineSalesChartDataList : (data) => dispatch(getLineSalesChartData(data)),
  getManagersData : () => dispatch(getManagers())
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesStageChart);

// export default ;

