import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import PostPoneModal from "../pageModals/PostPoneModal";
import { FiClock } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';

import { updateViewRowDetails } from '../../store/leads';
import { toast } from 'react-toastify';
const TasksListTable = React.memo((props) => {
  
  const dispatch = useDispatch();
  const [defaultTableText, setDefaultTableText] = useState('Loading...');
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPostPoneModal, setShowPostPoneModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [tasksList, setTasksList] = useState([]);

  useEffect(() => {
    setTasksList(props.data);
  }, [props.data])



  const handleClose = (event, reason) => {
    setAnchorEl(false);

    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const updateTaskCompleted = async (event, data, value) => {

    event.stopPropagation();
    var updatedData = {
      'task_status': "completed",
      'followup_id': value,
    }
    setOpen(true);
    try {
      const res = await axios.post("/user/reports/tasks-followups/update", updatedData);
      if(res.data.status ==="success"){
        toast.success("Task successfully completed")
      }
      var updatedTasksList  = props.data.filter(function (item) {
        if (item._id === value) {
          // console.log("here during cliking on icon/", item.task_status.toString() === "pending" ? "completed" : item.task_status)
          // item.task_status = "completed";
          return { ...item, task_status: item.task_status.toString() === "pending" ? "completed" : item.task_status  };
        }
        return item;
      });
      
      setTasksList(updatedTasksList);
   
      setOpen(true);
      setsnackBarMessage("Task successfully completed");
    
    } catch (err) {
      setOpen(true);
      setsnackBarMessage("Error occured while updating");

    }
    // const rowData = {
    //   rowId:props.rowId
    // }
    // dispatch(updateViewRowDetails(rowData))
  }

  const updatePoponedDate = (event, data, value) => {
    event.stopPropagation();
    setTaskId(value);
    setShowPostPoneModal(true);
  
  }

  const Taskcolumns = [
    {
      name: "_id",
      label: "id",
      options: {
        filter: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "task_status",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        onRowClick: false,
        display: true,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          return (
            (value !== "completed" && value !== "postponed") ? (
              <div style={{ display: "flex" }}>
                <Tooltip title="Mark as completed" arrow >
                  <a className="hint" onClick={(e) => { updateTaskCompleted(e, value, tableMeta.rowData[0]) }}>
                    <span></span>
                    <AiOutlineCheckCircle className='tasksIcons' size={25} />
                  </a>
                </Tooltip>
                &nbsp;
                <>
                  <Tooltip title="Postpone task" arrow >
                    <a className="hint" onClick={(e) => { updatePoponedDate(e, value, tableMeta.rowData[0]) }}>
                      <FiClock className='tasksIcons' size={24} />
                    </a>
                  </Tooltip>
                </>
          
              </div>
            ) : (
              (value === "postponed") ? "Postponed" : value.toString() // Convert object to string
            )
          );
          
         
        }

      }
    },
    {
      name: "task_name",
      label: "Task Name",
      options: {
        filter: true,
        display: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "action_date",
      label: "Action Date",
      options: {
        filter: true,

        customBodyRender: (value, tableMeta, updateValue) => (
          value ? <div>{moment(value).tz("Asia/Kolkata").format('D MMM  YYYY  HH:mm:ss')}</div> : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "reminder_date",
      label: "Reminder Date",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? <div>{moment(value).tz("Asia/Kolkata").format('D MMM  YYYY HH:mm:ss')}</div> : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "postponed_date",
      label: "PostPoned Date",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? <div>{moment(value).tz("Asia/Kolkata").format('D MMM  YYYY HH:mm:ss')}</div> : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "task_description",
      label: "Task Description",
      options: {
        filter: true,
        setCellHeaderProps: headerProps,
      }
    }
  ];
  const tasksOptions = {
    textLabels: {
      body: {
        noMatch: props?.data?.length < 1 ? "No Records Found" : defaultTableText
      }
    },
    filter: false,
    filterType: false,
    responsive: "simple",
    tableBodyHeight: "300px",
    tableBodyMaxHeight: "300px",
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {

        const temp = [
          item.data[0] ? item.data[0] : "NA",
          item.data[1] ? item.data[1].replace("T", " ").replace("Z", " ") : "NA",
          item.data[2] ? item.data[2].replace("T", " ").replace("Z", " ") : "NA",
          item.data[3] ? item.data[3] : "NA",
        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val

    }
  };
  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Tasks List"}
          data={tasksList}
          columns={Taskcolumns}
          options={tasksOptions}
        />
      </MuiThemeProvider>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      {showPostPoneModal ? <PostPoneModal show={showPostPoneModal} folloupId={taskId} onHide={() => setShowPostPoneModal(false)} /> : ""}
    </>

  )
});
export default TasksListTable;