
import React, { useState, useEffect, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import Badge from 'react-bootstrap/Badge'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import {CategoriesList, config, lineChartPostlayout,AccountMangers } from './chartConfig'
import Select from 'react-select';
import {fetchTechiesList, getLineChartTechPostSalesData} from "../../store/dashboard";
import { connect } from "react-redux";
import { useMemo } from "react";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const LineChartPost = React.memo(({techiesList,lineChartTechPostSales, fetchTechiesListData, getLineChartTechPostSalesDataList,LinechartpostLoader }) => {

    // const [data, setData] = useState([]);
    // const [accountManagers, setAccountManagers] = useState([]);
    // const [techieFiltersApplied, setTechieFiltersApplied] = useState(false);
    const [filterTechielist, setfilterTechielist] = useState([]);
    
    const [filter, setFilter] = useState({});
    
    const [columnsDisplay, setColumnsDisplay] = useState(true);
    const [showChart, setShowChart] = useState(true);
    const handle = useFullScreenHandle()
    var str = "Demos";
    var new_str = str.replace(/-/g, '');


    const categoriesList = [{value: "Demos", label: "Demos"}, {value: "Trainings", label:"Trainings"}, {value: "Tickets", label:"Tickets"}, {value: "Benchmarks", label:"Benchmarks"}, {value: "Meetings", label: "Meetings"}]

    const reportChange = ((state, handle) => {
        var LineChart = document.getElementById("LineChartComponents");
        if (LineChart) {
            LineChart = document.getElementById("LineChartComponents").getElementsByClassName("user-select-none")[0]
            LineChart.id = "LineChart";
        }
        if (state) {
          if (LineChart) {
            document.getElementById("LineChart").style.height = (window.innerHeight - 60).toString() + 'px';
          }
          // setTableHeight((window.innerHeight - 160).toString() + 'px');
          // setRowsPerPage(15);
          setColumnsDisplay(false);
          // setFilterDisplay(false);
        } else {
          if (LineChart) {
            document.getElementById("LineChart").style.height = "450px";
          }
    
          // setTableHeight('333px');
          // setRowsPerPage(10);
          setColumnsDisplay(true);
          // setFilterDisplay(true);
        }
    
      });
  
      const chartButtonClicked = async () => {

        setShowChart(true);
        if (!columnsDisplay) {
          handle.enter()
        }
      }

    const handleTechielistChange = (value) => {
        setfilterTechielist(value);
      }

      const getfetchTechiesList = async () => {
              if(filterTechielist && filterTechielist?.length>0){
                var Techielist = filterTechielist?.map(item => {
            
                    return item.value;
                  }); 
            }
            var fil = {
              type: filter.type?filter.type:new_str,
              user_id : Techielist,
            }
            getLineChartTechPostSalesDataList([fil,new_str])
          }
        
        
    useMemo(() => {
        fetchTechiesListData();
    }, []);


    useMemo(() => {
        getfetchTechiesList()
        // getLineChartTechPostSalesDataList(filter);
    }, [filterTechielist,filter]);
    
    const removeMonthrvalueBadge = (value) => {
        var inputValue = value.target.getAttribute('value');

        var updatedMonth = filter.type === inputValue ? "" : filter.type;
        setFilter({ ...filter, type: updatedMonth });
      }
      
      const removetechiesListvalueBadge = (value) => {
        var inputValue = value.target.getAttribute('value');

        var updatedMonth = filter.techiesList.user_id === inputValue ? "" : filter.techiesList.user_id;
        setFilter({ ...filter, techiesList: updatedMonth });
      }

      const removeFilterProductListBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
    
        const filtertechiesList = filter.techiesList.filter(item => item.value !== inputValue);
        // setFilterProductsList(filterProductsLists);
        setFilter({...filter, techiesList: filtertechiesList})
      }

      const removeFilterTechielistBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
    
        const filteTechielist = filterTechielist?.filter(item => item.value !== inputValue);
        setfilterTechielist(filteTechielist);
      }

  
      const containerRef = useRef(null); 
      const downloadContent = () => {
      const container = containerRef.current
      
      toPng(container)
      .then(function(dataUrl) {
        const link = document.createElement('a')
        link.download = 'Linechart-Post.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(function(error) {
        console.log("error:")
      });
      
      }
      
       
      const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
        modeBarButtonsToAdd: [
          {
            name: 'Download',
            icon: Plotly.Icons.camera, // Download icon
            click: function() {
              toast.success("Downloaded Successfully!")
              // Download the chart as PNG
              downloadContent();
            },
            title: 'Download As Png'
          },
      
        ],
      };
      
    return (
        <>
        {LinechartpostLoader ?
            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
        <>
        <div ref={containerRef}>  
        <FullScreen enabled={true} handle={handle} onChange={reportChange} >
        <div className="container-fluid py-2 px-2">
        <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                    <div className="row row_gutter">
    
                    <div className="col-md-1 col-lg-1" style={{ width: '7%' }}>
                    <button onClick={chartButtonClicked}
                      className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                  </div>
                  <div className="col-md-1 col-lg-1">
                    <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                      <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                    </button>
                  </div>
                  <div className="col-md-4 col-lg-5">
                        <CategoriesList
                        defaultValue={{ label: "Demos", value: "Demos" }}
                            placeholder={"Categories List"}
                            categoriesList={categoriesList}
                            // defaultValue = {filter.type}
                            disableAccountManagerFilter={filter.disableAccountManagerFilter || false}
                            updateFilter={(value) => setFilter({ ...filter, type: value })}
                        />
                    </div>
                    <div className="col-md-4 col-lg-5">
                    <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    options={techiesList}
                   
                    placeholder="Users List"
                    name="techielist"
                    defaultValue={[]}
                    onChange={handleTechielistChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />   
      
                   {/*   <AccountMangers
                placeholder={"Techies List srsrs"}
                accountManagers={techiesList}
                disableAccountManagerFilter={filter.disableAccountManagerFilter || false}
                // updateFilter={handleTechieFilterUpdate}
                values = {filter.techiesList}
                updateFilter={(value) => setFilter({ ...filter, techiesList: value })}
            />  */}

        </div>
        </div>
            </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
              {
                filter.type ?
  
                filter.type&& 
                <Badge onClick={removeMonthrvalueBadge}
                pill data={filter.type} value={filter.type} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.type}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                    :
                    <Badge
                    pill data={"Demos"}  value={"Demos"}  style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{"Demos"} <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                  }
              {
                filterTechielist?.length > 0 ?
                filterTechielist.map((filter) => (
                    <Badge onClick={removeFilterTechielistBadge}
                      pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                  ))
                  : ""
              }
  
          
            </div>
            </div>

                    <PlotlyComponent
                    // data = {[]}
                    id="lineChartPostlayoutChartComponents"
                        data={JSON.parse(JSON.stringify(lineChartTechPostSales))}
                        layout={{ ...lineChartPostlayout, title: `Monthly Post Sales Chart (${filter.type || 'Demo'})` }}
                        useResizeHandler
                        className="w-full h-full"
                        config={config}
                    />
                </FullScreen>
                </div>
            </>
        }
        </>
    )
});

const mapStateToProps = (state) => ({
    techiesList : state.dashboard.techiesList,
    lineChartTechPostSales : state.dashboard.lineChartTechPostSales,
    LinechartpostLoader:state.dashboard.LinechartpostLoader,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetchTechiesListData: () => dispatch(fetchTechiesList()),
    getLineChartTechPostSalesDataList : (data) => dispatch(getLineChartTechPostSalesData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LineChartPost);
