import React, { useState, useEffect, useMemo, useRef } from "react";
// import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { config, SelectYear, QuartersFilter, MonthlyFilter} from './chartConfig'
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { useSelector, useDispatch, connect } from 'react-redux';
import {  getYears, getLeaveRequestChart } from "../../store/dashboard";
import Badge from 'react-bootstrap/Badge'
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const LeaveRequestChart = React.memo(({yearsList, leaveRequestChart, getLeaveRequestChartData,LeaverequestchartLoader}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  // const permissions = user.permissions;
  const handle = useFullScreenHandle();
  // const [data, setData] = useState([]);
  // const [years, setYears] = useState([]);
  // const [salesStages, setSalesStages] = useState([]);
  // const [accountManagers, setAccountManagers] = useState([]);
  // const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const [filterMonth, setFilterMonth] = useState();
  const [filterYear, setFilterYear] = useState("");
  // const [filterAccountManager, setFilterAccountManager] = useState(null);
  // const [filterSalesStage, setFilterSalesStage] = useState([]);
  const [filterQuarter, setFilterQuarter] = useState();
  // const [tableData, setTableData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  // const [tableHeight, setTableHeight] = useState("333px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  const [fromDate, setFromDate] = useState( moment().startOf('month').format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState( moment().endOf('month').format("YYYY-MM-DD"));

  // const dispatch = useDispatch();
  // const {    } = useSelector(state => state.dashboard);


  var defaultMonth = { label: "All", value: false };
  
  var deYear = moment().format('YY');
  var deYearName = moment().format('YYYY')
  var defaultYear = {value: parseInt(deYear), label:deYearName}

  const currentYear = moment().year();

  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  const reportChange = ((state, handle) => {
    var leaveRequestChart = document.getElementById("leaveRequestChartComponent");
    if (leaveRequestChart) {
      leaveRequestChart = document.getElementById("leaveRequestChartComponent").getElementsByClassName("user-select-none")[0]
      leaveRequestChart.id = "leaveRequestChart";
    }

    if (state) {
      if (leaveRequestChart) {
        document.getElementById("leaveRequestChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }
      // setTableHeight((window.innerHeight - 170).toString() + 'px');
      // setRowsPerPage(15);
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (leaveRequestChart) {
        document.getElementById("leaveRequestChart").style.height = "450px";
      }
      // setTableHeight('333px');
      // setRowsPerPage(10);
      setColumnsDisplay(true);
      // setFilterDisplay(true);
    }

  });


  const chartButtonClicked = async () => {

    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }

  const getForecastChartData = async () => {
    let quatervalue;
    let monthvalue;
    var yearvalue="";

    // var sales_stages = filterSalesStage.map(item => {
    //   return item.value;
    // });

    // if(filterYear && filterYear.length>0){
    //   yearvalue = filterYear.map(item => {
    //     return item.value
    //   })
    // }
    // if (filterYear && filterYear.length > 0) {
    //   yearvalue = filterYear[0].value;
    // }
    if(filterYear){
      yearvalue = filterYear?.value
    }
// Quater //
  if (filterQuarter === "Q1") {
    quatervalue = 1;
  } else if (filterQuarter === "Q2") {
    quatervalue = 2;
  } else if (filterQuarter === "Q3") {
    quatervalue = 3;
  } else if (filterQuarter === "Q4") {
    quatervalue = 4;
  } else {
    quatervalue = null;
  }
// 
// Months//
if (filterMonth === "All") {
  monthvalue = false;
}if (filterMonth === "January") {
monthvalue = 1;
} else if (filterMonth === "February") {
monthvalue = 2;
} else if (filterMonth === "March") {
monthvalue = 3;
} else if (filterMonth === "April") {
monthvalue = 4;
}else if (filterMonth === "May") {
monthvalue = 5;
}else if (filterMonth === "June") {
monthvalue = 6;
}else if (filterMonth === "July") {
monthvalue = 7;
}else if (filterMonth === "August") {
monthvalue = 8;
}else if (filterMonth === "September") {
monthvalue = 9;
} else if (filterMonth === "October") {
monthvalue = 10;
}else if (filterMonth === "November") {
monthvalue = 11;
} else if (filterMonth === "December") {
monthvalue = 12;
} else {
monthvalue = null;
}


    var data = {
      from_date: fromDate ,
      to_date: toDate,
      user_id: userData._id,
      year:yearvalue?yearvalue:currentYear
    }

    if(filterYear && yearvalue){
      data.year = yearvalue
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }
    
    if(filterQuarter && quatervalue){
      data.quarters = quatervalue
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }
    if(filterMonth && monthvalue > 0){
      data.months = monthvalue
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }

    getLeaveRequestChartData([data,currentYear])
   
  }


  var  monthsList = [{ label: "All", value: "All" }, { label: 'January', value: 'January' }, { label: 'February', value: "February" }, { label: 'March', value: "March" }, { label: 'April', value: "April" }, { label: 'May', value: "May" }, { label: 'June', value: "June" }, { label: 'July', value: "July" }, { label: 'August', value: "August" }, { label: 'September', value: "September" }, { label: 'October', value: "October" }, { label: 'November', value: "November" }, { label: 'December', value: "December" }]
  const quartersList = [{ value: "all", label: "Annual" }, { value: 'Q1', label: 'Q1' }, { value: 'Q2', label: "Q2" }, { value: 'Q3', label: 'Q3' }, { value: 'Q4', label: 'Q4' }]
  
  useMemo(() => {
    
    getForecastChartData();
  }, [filterMonth, filterYear, filterQuarter, fromDate, toDate]);

  useMemo(() => {

    getYears();

  }, [])


  const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'Leave-request-chart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:",error)
});

}

 
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};

  const layout = {

    paper_bgcolor: 'transparent ',
    wrap:true,
    font: {
      size: 12,
      color: '#6E8898'
    },
    xaxis: {
      tickangle: 360,
      wrap:true
     },
    margin: {
      l: 60,
      r: 60,
      b: 90,
      t: 60,
    },
    title: "Leave Request Chart",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }

  const startEndDateHandle = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
    setFilterYear(null)
    setFilterMonth(null)
    setFilterQuarter(null)
  }
  const startEndDateHandle1 = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
    setFilterYear(null)
    setFilterMonth(null)
    setFilterQuarter(null)
  }

  const removeYearProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("input value is", inputValue)
    // var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
    var Yearfilter = filterYear.value.toString() === inputValue.toString() ? "" : filterYear.value;
    console.log("input value is after", Yearfilter);
     setFilterYear(Yearfilter)
  }
  const removequatervalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var updatedQuarter = filterQuarter === inputValue ? "" : filterQuarter;
    setFilterQuarter(updatedQuarter);
  }
  
  const removeMonthrvalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadgemonth", inputValue);
    var updatedMonth = filterMonth === inputValue ? "" : filterMonth;
    setFilterMonth(updatedMonth);
  }

  return (
    <>
    {LeaverequestchartLoader  ?
    
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
  <>
  <div ref={containerRef}>  
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >
        <div className="container-fluid py-3 px-2">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter">
                <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                {/* {
                    permissions.includes("canApproveRejectLeaveRequest") ? 
                <div className="col-md-2 col-lg-2" >
                    <Select
                        className="select_value"
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={true}
                        isSearchable="true"
                        options={accountManagers}
                        allowSelectAll={false}
                        placeholder="Users"
                        name="filterMonth"
                        onChange = {(value) => setFilterAccountManager(value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </div>
             : null }  */}
                <div className="col-md-2 col-lg-2">
                  <div >
                    <SelectYear years={yearsList} disableYearFilter={false} updateFilter={ (value) => setFilterYear(value) } />
                  </div>
                </div>
                <div className="col-md-2 col-lg-2">
                  <div>
                    <MonthlyFilter monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilterMonth(value) } />
                  </div>
                </div>

                <div className="col-md-2 col-lg-2">
                   <QuartersFilter quartersList={quartersList} disableQuarterFilter={false} updateFilter={ (value) => setFilterQuarter(value) }  />
                </div>

                <div className="col-md-2 col-lg-2" >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="From Date"
                      inputVariant="outlined"
                      value={fromDate}
                      onChange={(date) => startEndDateHandle(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-md-2 col-lg-2" >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="To Date"
                      inputVariant="outlined"
                      value={toDate}
                      onChange={(date) => startEndDateHandle1(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

              </div>
            </div>
          </div>
        </div>
        
        <div>
        
        <div className="row ">
        <div className="col-md-12 ml-2">

        {
          filterYear ?
          filterYear&& 
             <Badge onClick={removeYearProductListBadge}
                pill data={filterYear.value} value={filterYear.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterYear.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
         
         :
         <Badge 
         pill data={currentYear} value={currentYear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentYear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
           }


        
      {
        filterQuarter && 
        <Badge onClick={removequatervalueBadge}
            pill data={filterQuarter} value={filterQuarter} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterQuarter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
      }
      {
        filterMonth&& 
        <Badge onClick={removeMonthrvalueBadge}
            pill data={filterMonth} value={filterMonth} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterMonth}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
      }
        </div>
        </div>
            <PlotlyComponent
              data={JSON.parse(JSON.stringify(leaveRequestChart))}
              layout={layout}
              useResizeHandler
              className="w-full h-full"
              config={config}
              id="LeaveRequestChartComponent"
            />
            </div>
      </FullScreen>
      </div>
      </>
    
    
    }
    </>
  )
});

// export default ;
const mapStateToProps = (state) => ({
  leaveRequestChart : state.dashboard.leaveRequestChart,
  yearsList : state.dashboard.yearsList,
  LeaverequestchartLoader:state.dashboard.LeaverequestchartLoader
});

const mapDispatchToProps = (dispatch) => ({
  getYearsData : () => dispatch(getYears()),
  getLeaveRequestChartData : (data) => dispatch(getLeaveRequestChart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveRequestChart);