import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Navbar from '../home/Navbar';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Users from '../../components/commonColumns/Users'
import { useDispatch,useSelector } from "react-redux";

import { getRegions, getAccountManagers, updateTargetsValues, assignSegment, getAssignedProductTechieDetail, selectAllSegments, getProductsList, getTechieUsersList, assignProductsToTechie } from "../../store/targets";



export default function AssignProducts() {

    const dispatch = useDispatch();
    const {selectedSubCategoriesList, selectedCategoriesList, selectedProductsList, techieUsersList, segmentDetails, msg, errorPayload,isFetching, ProductsCategoriesList, regionsList}  = useSelector(state => state.targets);
    const [data, setData] = useState([]);
    // const [usersList, setUsersList] = useState([]);
    // const [projectModules, setProjectModules] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [userId, setUserId] = useState();
    const [accountManagerId, setAccountManagerId] = useState();
    const [selectAll, setSelectAll] = useState(false);
    const yearRef = useRef(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [regionsData, setRegionsData] = useState([]);
    const [region, setRegion] = useState(null)

    // useEffect(() => {
    //     console.log("segmentDetails", segmentDetails)
    // }, [segmentDetails])

    // const fetchUsers = async () => {
    //     try {
    //         const res = await axios.get("/user/reports//all-users-list");

    //         setUsersList(res.data.usersList);
    //     } catch (err) {

    //     }
    // }


    // const fetchProjectModules = async () => {
    //     try {
    //         const res = await axios.get("/project-modules/list");

    //         setProjectModules(res.data.projectModulesList);

    //     } catch (err) {

    //     }
    // }

    // const fetchSubModules = async (projectModuleId, userId) => {
    //     try {
    //         const res = await axios.post("/user-modules-permissions/permissions/list", { module_id: projectModuleId, user_id: userId });

    //         setPermissions(res.data.permissonList);
    //     } catch (err) {

    //     }
    // }


    const updatePermission = async (segment_id) => {

        // var status = "";
        // if (permission_status) {
        //     status = "delete";
        // } else {
        //     status = "update";
        // }

        if(!userId){
            return alert("Please select sales representatives");
        }
        if(!accountManagerId){
            return alert("Please select accounts manager");
        }

        try {

            dispatch(assignSegment(userId, accountManagerId, segment_id, year));

        } catch (err) {

        }
    }

     console.log("regionsList ", regionsList)
    useEffect(() => {
        
        if(regionsList?.length>0){
            var regions = [] 
            var reg = []
            regionsList.forEach(item => {
                console.log('item ', item)
                var item1 = item.label?.split(" - ")
                if(!reg.includes(item1[0])){
                    reg.push(item1[0])
                    regions.push({label: item1[0], value: item.value})
                }
                // console.log("item1 ", item1, regions)
            })
            setRegionsData(regions)
        }
    }, [regionsList])

    useEffect(() => {
        dispatch(getTechieUsersList());
        dispatch(getProductsList());
        dispatch(getRegions());
    }, []);

    // useEffect(() => {
    //     if(msg){
    //         alert(msg)
    //     }else if(errorPayload){
    //         alert(errorPayload)
    //     }
        
    // }, [msg, errorPayload])

    // useEffect(() => {
    //     if(accountManager){
    //         dispatch(getRegionTargetDetails(year, accountManager));
    //     }
    //   }, [year, accountManager])

    const onInputChange = (e) => {
        console.log("yearRef", yearRef.value);
        console.log("year ", year)
        var yearValue = year ? year : new Date().getFullYear()
        if (e.target.name === "user_id") {
            dispatch(getAssignedProductTechieDetail(e.target.value, accountManagerId, yearValue, region));
            setUserId(e.target.value);
            
        }
        if (e.target.name === "account_manager_id") {
            dispatch(getAssignedProductTechieDetail(userId, e.target.value, yearValue, region));
            setAccountManagerId(e.target.value);
        }

        if(e.target.name === "region"){
            dispatch(getAssignedProductTechieDetail(userId, accountManagerId, yearValue, e.target.value));
            setRegion(e.target.value)
            // setAccountManagerId(e.target.value);
        }
        
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        // setOpen(false);
      };

    // Function to handle "Select All" checkbox change
    const handleSelectAll = (e) => {

        if(!userId){
            return alert("Please select sales representatives");
        }
        if(!accountManagerId){
            return alert("Please select accounts manager");
        }

        setSelectAll(e.target.checked);
        var selectedList = regionsList.map(item => item.value);
        console.log("selectedList", selectedList)
        dispatch(selectAllSegments(selectedList, userId, accountManagerId))
        // if (e.target.checked) {
        // // Select all regions
        // setSelectedRegions(regionsList.map(region => region.value));
        // } else {
        // // Deselect all regions
        // setSelectedRegions([]);
        // }
    };
    
    const changeYear = (e) => {
        setYear(e.target.value)
        dispatch(getAssignedProductTechieDetail(userId, accountManagerId, e.target.value, region));
    }

    const selectProduct = (category=null, subCategory=null, childProduct=null, e) => {
        
        console.log("e ", e.target.checked)
        if(!userId){
            return alert("Please select sales representatives");
        }
        if(!accountManagerId){
            return alert("Please select accounts manager");
        }
        if(!year){
            return alert("Please select year");
        }
        if(!region){
            return alert("Please select region")
        }

        // try {

            console.log('category', category, subCategory, childProduct)
            var selectedProducts = [] 
            var selectedSubCategories = []
            var selectedCategories = []
            var input = {}

            if(childProduct){
                selectedProducts.push(childProduct)
            }
            else if(subCategory){
                var categoriesLists = ProductsCategoriesList.filter(item => item.value === category)
                console.log("categoriesLists", categoriesLists)
                var subCategoriesLists = categoriesLists[0]?.subproducts?.filter(item => item.value === subCategory)
                
                selectedProducts = subCategoriesLists[0].childProducts.map(item => item.value)
                selectedSubCategories.push(subCategory)
                input.subCategories =  selectedSubCategories
            }
            else if(category){
                console.log("category category  ", category)
                var categoriesLists = ProductsCategoriesList.filter(item => item.value === category)
                console.log("categoriesLists", categoriesLists)
                categoriesLists.map(item => {
                    item.subproducts?.map(item => {
                        item.childProducts?.map(item =>{
                            selectedProducts.push(item.value)
                        })
                        //  = [...selectedProducts, childsp.map(item )]
                        selectedSubCategories.push(item.value)
                    })
                })
                selectedCategories.push(category)
                input.category = category
                input.subCategories =  selectedSubCategories
                
            }

            input.products = selectedProducts
            console.log('input', input)
            dispatch(assignProductsToTechie(userId, accountManagerId, input, year, region, e.target.checked));

        // } catch (err) {
        //     alert("Some error occured")
        // }
        
        
    }


    return (
        <>
            <Navbar value="assignSegments" className="activity_list_navbar" />
            
            <div class="container-fluid">
                <Tab.Container id="left-tabs-example" defaultActiveKey="assignSegments">
                    <Row>
                        <Col sm={10}>
                            <br />
                            <Tab.Content>
                                <Tab.Pane eventKey="assignSegments">

                                    <React.Fragment>
                                        <div className='row'>

                                        <div className='col-md-3'>
                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    name="account_manager_id"
                                                    label="Account Manager"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        techieUsersList.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>

                                            <div className='col-md-3'>
                                            <TextField variant="outlined"
                                                required
                                                select
                                                label="Select Region"
                                                name="region"
                                                type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                {
                                                    regionsData.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>

                                                            {option.label}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </div>
                                            
                                            <div className='col-md-3'>

                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    label="Users"
                                                    name="user_id"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        techieUsersList.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>
                                        

                                        <div className='col-md-3'>
                                            <TextField variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                defaultValue={new Date().getFullYear()}
                                                select
                                                ref={yearRef}
                                                onChange={changeYear}
                                                required
                                                className="form-control" name="year" label="Select Year"
                                            >
                                                {["2023", "2024", "2025"].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>

                                        </div>

                                        {/* <br /> */}
                                        {/* <input
                                            type="checkbox"
                                            label="Select All"
                                            onChange={handleSelectAll}
                                            checked={selectAll}
                                        />&nbsp;&nbsp;Select All */}
                                        <br /><br />
                                        <div className='row'>
                                        
                                            {
                                                ProductsCategoriesList.map(category => (

                                                    <>
                                                            <div className='row' style={{marginTop:'30px'}}>
                                                                <div className='row'>
                                                                    <div className='col-md-3' style={{display:'flex', flexDirection: 'row'}}>
                                                                        <input type="checkbox"
                                                                            id={category.name}
                                                                            checked={selectedCategoriesList?.includes(category.value) ? true : false}
                                                                            label={category.name}
                                                                            onChange={(e) => selectProduct(category.value, null, null, e)}
                                                                        />
                                                                        &nbsp;&nbsp;&nbsp;<label for={category.name}>{category.name}</label>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                {/* <div className='row' style={{marginTop:'30px', marginLeft: '30px'}}>
                                                                    {
                                                                        category?.subproducts.map(subProduct => (
                                                                            <>
                                                                            <div className='row' >
                                                                                <div className='col-md-12' style={{display:'flex', flexDirection: 'row'}}>
                                                                                    <input type="checkbox"
                                                                                        id={subProduct.name}
                                                                                        checked={selectedSubCategoriesList?.includes(subProduct.value) ? true : false}
                                                                                        label={subProduct.name}
                                                                                        onChange={() => selectProduct(category.value, subProduct.value)}
                                                                                    />&nbsp;&nbsp;&nbsp;
                                                                                    <label for={subProduct.name}>{subProduct.name}</label>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                {subProduct?.childProducts.map(childProduct => (
                                                                                    <div className='row' style={{marginTop:'30px', marginLeft: '30px'}}>
                                                                                        <div className='row' >
                                                                                            <div className='col-md-12' style={{display:'flex', flexDirection: 'row'}}>
                                                                                                <input type="checkbox"
                                                                                                    id={childProduct.name}
                                                                                                    checked={selectedProductsList?.includes(childProduct.value) ? true : false}
                                                                                                    label={childProduct.name}
                                                                                                    onChange={() => selectProduct(category.value, subProduct.value, childProduct.value)}
                                                                                                />&nbsp;&nbsp;&nbsp;
                                                                                                <label for={childProduct.name}>{childProduct.name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                 )) }
                                                                                 </div>
                                                                                 </>

                                                                        ) 
                                                                        )
                                                                    }
                                                                </div> */}
                                                                
                                                            </div>
                                                            <br />

                                                    </>

                                                )
                                                )
                                            }
                                        </div>


                                        

                                    </React.Fragment>
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <React.Fragment>
                                        <Users />
                                    </React.Fragment>
                                </Tab.Pane>

                                <Tab.Pane eventKey="">
                                    <React.Fragment>

                                    </React.Fragment>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            


        </>
    )

}