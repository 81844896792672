import React, { useEffect, useState } from "react";
import Navbar from "../home/Navbar";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import CallRecordsTable from "../../components/commonColumns/CallRecordsTable";
import { Button } from 'react-bootstrap';
import CreateCallRecordModal from "../../components/pageModals/CreateCallRecordModal";
import moment from "moment";
import {getCallrecorddata,CallrecordsActions} from "../../store/Callrecords"

import { connect, useSelector,useDispatch } from 'react-redux';

const CallRecord = React.memo (({callrecordlist,callrecordmodal}) => {
  const dispatch = useDispatch();
  const { register: targetRegister, handleSubmit: setCallRecordTargetHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: targetErrors } } = useForm();
    const {user} = useSelector(state => state.auth)
    const user_id = user.user._id;
    const permissions = user.permissions; 
  const [showCallRecords, setShowCallRecords] = useState(false);
  const [activity, setActivity] = useState({
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment().endOf('month').format('YYYY-MM-DD')
})

  const fechData = async () => {
    const updatedData = {
      from_date: activity.from_date.toString(),
      to_date: activity.to_date.toString(),
  }
  callrecordlist(updatedData)
   
  };
  useEffect(() => {
    fechData();
  }, []);

  const onInputChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value }, []);
}
  return (
    <>
      <Navbar value="callrecords" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
        
            <>                                                      
              <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(CallrecordsActions.callrecordmodal_openModal())} >Create Call Record</Button>
              {callrecordmodal ? <CreateCallRecordModal  /> : ""}
            </>
            
        </div>
        <br/>
       <div className="date_filter">
       <form onSubmit={setCallRecordTargetHandleSubmit(fechData)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={activity.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="To Date" name="to_date" className="form-control" value={activity.to_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
       </div>
       <br/>
      <div className="demo_table">
        <CallRecordsTable />
      </div>
    </div>
    </>
  );


});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    callrecordmodal:state.Callrecordlist.callrecordmodal,
    
});

const mapDispatchToProps = (dispatch) => ({
  callrecordlist :(data) => dispatch(getCallrecorddata(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (CallRecord)
