import React, { useState, useEffect, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import './chart.css';
import Select from 'react-select';
import TextField from "@material-ui/core/TextField";
import { components } from "react-select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useForm } from "react-hook-form";
import { AiOutlineBarChart } from 'react-icons/ai';
import { AiOutlineDatabase } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import Badge from 'react-bootstrap/Badge';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TechieMetting  from "../../store/TechieMetting";
// import { getMuiTheme, headerProps } from './commonComponents';
import { getMuiTheme,headerProps } from "../commonColumns/commonComponents";
import {fetchDynamicUsersList,fetchTechieClockTimeChart,fetchUserRoles} from "../../store/dashboard";
import {TechieMettingActions,getColumnsList} from "../../store/TechieMetting"
import {gettechieMeetinglistHandle} from "../../store/TechieMetting"
import TechieMettingTable from "../commonColumns/TechieMettingTable";
import {ContactColumnUpdateddata} from "../../store/Common"
import { toPng } from 'html-to-image'
// import Techie
import moment from "moment";

const PlotlyComponent = createPlotlyComponent(Plotly);

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const TechieClockTimeHrsChart = React.memo (({getColumnsList,columnsList,accountManagersList,fetchdynamicUserListData,userRolesList,TechieClockTimeChartData,fetchUserRolesHandle,techieClocktimeLoader,TechieClocktimeData,TechieClocktimeTableData}) => {
console.log("user roles are", userRolesList)
  const [data, setData] = useState([]);

  const [contactsList, setContactsList] = useState([]);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [accountId, setAccountId] = useState(false);
  const [filterAccountManager, setFilterAccountManager] = useState([]);
  const [filterteamsRoles, setFilterteamsRoles] = useState({});
  const [techieMeetingData, setTechieMeetingData] = useState({
    account_id: selectedAccount,
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD')
  });

console.log("team role is here", filterteamsRoles)
  const [tableData, setTableData] = useState([]);
//   console.log("techie list is",tableData)
  const [showChart, setShowChart] = useState(true);
  const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const handle = useFullScreenHandle();
  const [tableHeight, setTableHeight] = useState("310px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  // const[userRolesListData,setUserRolesListData] =useState([])
const camelCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);

}
  const userRolesListData = useMemo(() => {
return userRolesList.map((userRoles) => ({
...userRoles,
label:userRoles.label ==="marketing_team" ? "Marketing Team" : camelCase(userRoles.label),
})).filter((userRolesFilter) => !["CEO","User","Sales_manager","Technical_manager","Inside Sales"].includes(userRolesFilter.label))
  // return userRolesList.filter((userRoles) => userRoles.label !=="CEO" && userRoles.label !=="user" && userRoles.label !=="sales_manager" && userRoles.label !=="technical_manager" && userRoles.label !=="Inside Sales")
  },[userRolesList])

console.log("new list are here.,,", userRolesListData)
  const {
    register: timesheetRegister,
    setError: timesheetSetError,
    reset: reset,
    handleSubmit: setEfficiencyHandleSubmit,
    formState: { errors: timesheetErrors },
  } = useForm();

  const getTechieMeetingsChartData = async () => {
    var users_list = filterAccountManager?.map((item) => {
      return item.value
    })
 
    var fil ={
        start_date: techieMeetingData.start_date,
        end_date: techieMeetingData.end_date,
       account_id: accountId ? accountId :"", 
       user_id:users_list,
       userRoleId:filterteamsRoles?.value
    }
    // Demodata for Filters //
    var Demodata ={
     account_id: accountId ? accountId :"", 
     user_id:users_list
  }
      // Demodata for Filters //

   TechieClockTimeChartData([fil,Demodata])
    
    // dispatch(getRegionWiseRevenueChart(fil))
    // const res = await axios.post('/user/techie-charts/techie-meetings-chart', fil); 
    // await Api.post("", data).then((res) => {

    var colorsArray = ["#72C","#880","#080","#448"];

    (TechieClocktimeData).map((dataset, index) => {
    //   var yValues = dataset.y.map(item => {
    //     return item / 10000000
    //   });

      dataset.marker = {
        color: colorsArray[index],
        width: 0.5,
        opacity: 0.7,
      };

    //   dataset.y = yValues;
     

      dataset.width = dataset.x.map(data => {
        return 0.5
      });

    });

    // setData(res.data.data);
    // setTableData(res.data.tableDatas);
    // // GettechieMeetinglist();
    // // setTechiMeetindData(res.data.tableData);
    // if (res.data.tableDatas < 1) {
    //   setDefaultTableText("No Records Found");
    // }
  }

  const reportChange = ((state, handle) => {
    var regionsChart = document.getElementById("regionChartComponent");
    if (regionsChart) {
      regionsChart = document.getElementById("regionChartComponent").getElementsByClassName("user-select-none")[0]
      regionsChart.id = "regionsChart";
    }
    if (state) {
      if (regionsChart) {
        document.getElementById("regionsChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }

      setTableHeight((window.innerHeight - 160).toString() + 'px');
      // setRowsPerPage(15);
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (regionsChart) {
        document.getElementById("regionsChart").style.height = "450px";
      }

      setTableHeight('333px');
      // setRowsPerPage(10);
      setColumnsDisplay(true);
      // setFilterDisplay(true);
    }
  });


  const chartButtonClicked = async () => {
    // console.log("region wise data filters chart button change")
    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }

  useMemo(() => {
    // console.log("region wise data filters change")
    getTechieMeetingsChartData();
  }, [accountId,techieMeetingData.start_date,techieMeetingData.end_date,filterAccountManager,filterteamsRoles]);
  

  const layout = {
    paper_bgcolor: 'transparent ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    yaxis: {
    },
    xaxis: {
      tickangle: 360,
      wrap:true
     },
    margin: {
      l: 60,
      r: 60,
      b: 90,
      t: 60,
    },
   
    // height: 600,
    // width: "100%",
    showlegend: true,
    legend: { "orientation": "h" },
    title: "Clock Time Chart",
    useResizeHandler: true,
  }



  // chart config layouts////
  const containerRef = useRef(null); 
  const downloadContent = () => {
  const container = containerRef.current
  
  toPng(container)
  .then(function(dataUrl) {
    const link = document.createElement('a')
    link.download = 'Techie-Clock-Time-Chart.png';
    link.href = dataUrl;
    link.click();
  })
  .catch(function(error) {
    console.log("error:",error)
  });
  
  }
  
   
  const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
    modeBarButtonsToAdd: [
      {
        name: 'Download',
        icon: Plotly.Icons.camera, // Download icon
        click: function() {
          // Handle click on custom lock icon
          // alert('Custom Lock Icon Clicked!');
          toast.success("Downloaded Successfully!")
          // Download the chart as PNG
          downloadContent();
        },
        title: 'Download As Png'
      },
  
    ],
  };

  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#9400D3" : '#8A2BE2', // Use a violet color code
        color: "white",
        opacity: '1.5'
      };
    }
  };

  const onInputChange = (e) => {
    setTechieMeetingData({ ...techieMeetingData, [e.target.name]: e.target.value }, []);
  };

  const getAccountsList = async (search) => {
    setCompaniesList([]);
    const res = await axios.get("/accounts/search-company/" + search.target.value);
    setCompaniesList(res?.data?.AccountsList);
  }

  const handleSetCompanyName = (value) => {

    setSelecteAccount(value.value)
    if (value.__isNew__ === true) {
    } else {
      setAccountId(value.value);
      getContactList(value.value);
    }
  }

  const getContactList = async (data) => {

    setCompaniesList([]);
    const res = await axios.get("/demos/economic-buyer-list/" + data);
    setContactsList(res.data.economicBuyerList);

  }
  useEffect(() => {
    setCompaniesList(techieMeetingData.account_id);
  
  }, [])

useEffect(() => {
  if(filterteamsRoles){
    const data = {
      "roleId":filterteamsRoles.value
    }
    fetchdynamicUserListData(data);
  }
},[filterteamsRoles])

  useMemo(() => {
    getColumnsList();
    fetchUserRolesHandle()
}, []);

const columns = [
  {
    name: "company_name",
    label: "Company Name",
    options: {
      setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '500px',
              maxWidth: '500px',
              overflow: 'auto',
              color: '#243d6a',
            }
          }),
      display:  true,
      filter: true,
      setCellHeaderProps: headerProps,
  }
  },
  {
    name: "user_id",
    label: "Name",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        value ? value.first_name +" "+ value.last_name: "user not found"
      ),
      display: true ,
      filter: true,
      setCellHeaderProps: headerProps
    }
  },
  {
    name: "_id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: false,
      setCellHeaderProps: headerProps,
    }
  },
 
  {
    name: "task_title",
    label: "Title",
    options: {
      setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '500px',
              maxWidth: '500px',
              overflow: 'auto',
              color: '#243d6a',
            }
          }),
      display:  true,
      filter: true,
      setCellHeaderProps: headerProps,
  }
  },
  {
    name: "task_description",
    label: "Description",
    options: {
      setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '500px',
              maxWidth: '500px',
              overflow: 'auto',
              color: '#243d6a',
            }
          }),
      display:  true,
      filter: true,
      setCellHeaderProps: headerProps,
  }
  },
  {
      name: "start_date_time",
      label: "Task Date",
      options: {
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
  },
  {
    name: "start_date_time",
    label: "Start Time",
    options: {
      display: true ,
      customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
      ),
      setCellHeaderProps: headerProps,
    }
  },
  {
      name: "end_date_time",
      label: "End Time",
      options: {
        display: true ,
        customBodyRender: (value, tableMeta, updateValue) => (
            value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
  
  ];

  
  const LoaderText = () => {
    return   !techieClocktimeLoader ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
       
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 210).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 210).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    onViewColumnsChange: async (changedColumn, action) => {

      if (action === "add") {
        columnsList.push(changedColumn);
      }

      if (action === "remove") {

        var index = columnsList.indexOf(changedColumn);
        if (index !== -1) {
            columnsList.splice(index, 1);
            columnsList(columnsList);
        }
    }

  
    },
    download: false,
    // onRowClick: handleRowClick,
  };
  useEffect(() => {
    const newValue = { label: 'Techie', value: '61ee321f408493b1aede07be' }
    setFilterteamsRoles(newValue)
  },[])

  const handleAccountManagerChange = (value) => {
    // console.log("region wise data filters account manager change")
    setFilterAccountManager(value);
  }

  const handleTeamsChange = (value) =>{
    console.log("team value are", value)
    setFilterteamsRoles(value)
  }

  const removeFilterAccountManagerBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    const filterManager = filterAccountManager.filter(item => item.value !== inputValue);
    setFilterAccountManager(filterManager);
  }
  const removeFilterUserRolesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    // const filterManager = filterteamsRoles.filter(item => item.value !== inputValue);
    const filterManager = filterteamsRoles.value !== inputValue
    setFilterteamsRoles(filterManager);
  }

  return (
    <>
    
    { techieClocktimeLoader ? 
      <div className="parent-div">
            <div className="loader"></div>
          </div>

          :

          <>
          <div ref={containerRef}>  
         
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >
      {/* <form onSubmit={setEfficiencyHandleSubmit(getTechieMeetingsChartData)}> */}
        <div className="container-fluid py-2 px-2">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter mt-2">
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => setShowChart(false)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '6%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
               
                {filterteamsRoles && filterteamsRoles?.label ==="Techie" &&
                <div className="col-md-4 col-lg-4" style={{ width: '20%' }}>
                <Select
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Account Name"
                  styles={colourStyles}
                  onChange={handleSetCompanyName}
                  onKeyDown={getAccountsList}
                ></Select>
                </div>
                }
                <div className="col-md-2 col-lg-2" style={{ width: '15%' }}>
                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    options={userRolesListData}
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    placeholder="Teams"
                    name="filterTeams"
                    defaultValue="+91"
                    onChange={handleTeamsChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '15%' }}>
                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    options={accountManagersList}
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    placeholder="Users"
                    name="filterMonth"
                    defaultValue="+91"
                    onChange={handleAccountManagerChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>
              
                <div className="col-md-2 col-lg-2" style={{ width: '15%' }}>
                  <div >
                    <TextField variant="outlined" type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="From Date" name="start_date" className="form-control" value={techieMeetingData.start_date} id="preliminary-review-start"
                        onChange={e => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '15%' }}>
                  <div>
                    <TextField variant="outlined" type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="To Date" name="end_date" className="form-control" value={techieMeetingData.end_date} id="preliminary-review-start"
                        onChange={e => onInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
    
        </div>
        {/* </form> */}
        <div className="row">
          <div className="col-md-12 ml-2">
            
          {
              filterAccountManager?.length > 0 ?
                filterAccountManager.map((filter) => (
                  <Badge onClick={removeFilterAccountManagerBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
             {Object.keys(filterteamsRoles).length > 0  ?
                  <Badge onClick={removeFilterUserRolesBadge}
                    pill data={filterteamsRoles.value} value={filterteamsRoles.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterteamsRoles.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            
                : ""
            }
            </div>
            </div>
        {showChart ?
          <PlotlyComponent
            id="TechieClocktimeChartComponent"
            data={TechieClocktimeData}
            layout={layout}
            config={config}
          />
          : <React.Fragment>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                title={"Clock Time List"}
                data={TechieClocktimeTableData}
                // dataRequstor={props.dataRequstor}
                columns={columns}
                options={options}
               
                />

              </MuiThemeProvider>
            {/* <TechieMettingTable gettechielist={tableData} /> */}
          </React.Fragment>}

      </FullScreen>
      </div>
      </>
        }
    </>
  )
})


const mapStateToProps = (state) => ({

    user : state.auth.user,
    accountManagersList : state.dashboard.dynamicUserListData,
    userRolesList: state.dashboard.userRolesList,
    columnsList:state.Techiemettinglist.columnsList,
    techieClocktimeLoader:state.dashboard.techieClocktimeLoader,
    TechieClocktimeData:state.dashboard.TechieClocktimeData,
    TechieClocktimeTableData:state.dashboard.TechieClocktimeTableData
});

const mapDispatchToProps = (dispatch) => ({
    fetchdynamicUserListData: (data) => dispatch(fetchDynamicUsersList(data)),
    getColumnsList:(data) => dispatch(getColumnsList()),
    TechieClockTimeChartData:(data) => dispatch(fetchTechieClockTimeChart(data)),
    fetchUserRolesHandle:() => dispatch(fetchUserRoles())
});

// export default RegionChart;
export default connect(mapStateToProps, mapDispatchToProps)(TechieClockTimeHrsChart);
