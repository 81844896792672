import React, { useState, useEffect,useMemo} from 'react';
import Navbar from '../home/Navbar';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import moment from 'moment';
import { Button } from 'react-bootstrap';
import TechieMettingModal from "../../components/pageModals/TechieMettingModal";
import TechieMettingTable from '../../components/commonColumns/TechieMettingTable';
import {TechieMettingActions} from "../../store/TechieMetting"

import { connect, useSelector,useDispatch } from 'react-redux';
import {gettechieMeetinglistHandle,} from "../../store/TechieMetting"

const TechieMetting = React.memo (({gettechielist,meetinglistdata,showTechieMetting,GettechieMeetinglist,showTechie_OpenModal}) => {
  const {user} = useSelector(state => state.auth)
  const [activity, setActivity] = useState({
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment().endOf('month').format('YYYY-MM-DD')
})
  const { register: targetRegister, handleSubmit: setTargetHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: targetErrors } } = useForm();
  const permissions = user.permissions;
  
  const fechData = async () => {
    const updatedData = {
      // user_id : user?.user?._id,
      from_date: activity.from_date.toString(),
      to_date: activity.to_date.toString()
  }
    GettechieMeetinglist(updatedData)
  }
  useEffect(() => {
    fechData();
  }, [])
  const onInputChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value }, []);
}
  return (
    <>
      <Navbar value="techiemetting" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
          {permissions.includes("canCreateTechieMeetings") ?
            <>                                                          
              <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => showTechie_OpenModal()}  >Create Meeting</Button>
              {showTechieMetting ? <TechieMettingModal /> : ""}
            </>
            : false}
        </div>
        <form onSubmit={setTargetHandleSubmit(fechData)}>
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={activity.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="To Date" name="to_date" className="form-control" value={activity.to_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        <div className="demo_table">
          <TechieMettingTable />
        </div>
      </div>
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  gettechielist : state.Techiemettinglist.gettechielist,
showTechieMetting : state.Techiemettinglist.ShowTechieMettingModal

});

const mapDispatchToProps = (dispatch) => ({
  
  GettechieMeetinglist:(data) => dispatch(gettechieMeetinglistHandle(data)),
  showTechie_OpenModal:(data) => dispatch(TechieMettingActions.showTechie_OpenModal(data)),
 
});


export default connect(mapStateToProps,mapDispatchToProps) (TechieMetting)


