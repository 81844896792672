import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useDispatch, useSelector } from 'react-redux';
import {updatedhrisdata,HrisActions,relievehrisdata} from "../../store/Hris";

const RelieveEmployeeModal = React.memo (({rawdataupdate,relievehrisdataHandle,openshowModal,closemodal,userId}) => {
  
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const { register: rejectRegister, handleSubmit: relieveHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const relieveSubmit = async (data) => {
    setDisable(true);
    console.log("inside user is", userId)
    var input = {user_id : userId, status:"block"}
    relievehrisdataHandle(input);
  }

 
  return (
    <>
      <Modal
       size="sm"
       show={() => openshowModal()}
       onHide={() => closemodal()}
        // dialogClassName="modal-30w"
        dialogClassName="modal-width"
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">
            <small>Relieve Employee</small>
          </Modal.Title>
          <a onClick={closemodal} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={relieveHandleSubmit(relieveSubmit)}>
            {/* <div className="row headerBand">
              <label>Relieve Employee</label>
            </div>
            <br /> */}
            <div class="row">
              <div class="col-md-12">
               <h5>Are you sure, You want to relieve employee?</h5>
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">yes</button>
                <button onClick={closemodal} type="button" style={{ background: 'red', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons">No</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  )

});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    userId :state.Hrislist.userId,
    Rowid : state.Rawdatalist.rowId, 
    showRelieveModal  :state.Hrislist.showRelievemodal,
});
const mapDispatchToProps = (dispatch) => ({

    openshowModal:(data) => dispatch(HrisActions.showrelieveOpenmodal(data)),
    closemodal:(data) => dispatch(HrisActions.showrelieveClosemodal(data)),
    relievehrisdataHandle:(data) => dispatch(relievehrisdata(data))
});
export default connect(mapStateToProps,mapDispatchToProps) (RelieveEmployeeModal)