import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Navbar from '../home/Navbar';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import PhoneCodes from '../../components/json/phoneCodes';

import { useSelector } from 'react-redux';

export default function OpportunityCreate() {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const permissions = user.permissions;
    const [drfExpiryDate, setDrfExpiryDate] = useState(null);
    const [RegionsList, setRegionsList] = useState([]);
    const [SalesConfidenceStage, setSelectedSalesConfidenceStage] = useState(false);
    const [BusinessTypes, setBusinessTypes] = useState([]);
    const [salesActivitiesList, setSalesActivitiesList] = useState([]);
    const [op_po_value, setpovalue] = useState(0);
    const [op_percentage_split_tokaizenat, setpercentagesplittokaizenat] = useState(0);
    const [poDate, setPoDate] = useState(null);
    const changeDrfExpiryDate = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD');
        setDrfExpiryDate(newDate);
    }
    const [closureMonth, setClosureMonth] = useState(false);
    const [ansysAccountManagerList, setAnsysAccountManagerList] = useState([]);
    const [timeline, setTimeline] = useState(false);
    const [timelineYear, setTimelineYear] = useState();
    const [closingQuarter, setClosingQuarter] = useState(false);
    var today = new Date();
    var day = today.getDate() + 3;
    var months = today.getMonth() + 1; //January is 0!
    var year = today.getFullYear();

    var threedays = moment().add(3, 'days');

    var twodays = moment().add(2, 'days');



    var nextThirdDay = `${year}-${months}-${day}`;
    var initialTimeLine = moment().subtract(1, 'years');
    var finalTimeLine = moment(initialTimeLine).add(24, 'M');
    var getDaysBetweenDates = function (startDate, endDate) {
        var now = startDate.clone(), dates = [];

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('MMM-YYYY'));
            now.add(1, 'M');
        }
        return dates;
    };

    var timeLineDateList = getDaysBetweenDates(initialTimeLine, finalTimeLine);
    const [drfStatusList, setDrfStatusList] = useState([]);

    const getDrfStatusList = async () => {

        const res = await axios.get("/drf-status/search");
        setDrfStatusList(res.data.drfStatusList);

    }

    const getUsersList = async () => {
        const res = await axios.get("/user/reports/users-list");

        setUsersList(res.data.usersList);
    }

    const [usersList, setUsersList] = useState([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError } = useForm();
    const [industry, setIndustry] = useState({ value: "", value: "" });
    const [existingIndustry, setExistingIndustry] = useState(false);
    const [industriesList, setIndustriesList] = useState([]);
    const [annualRevenueRangeList, setAnnualRevenueRangeList] = useState([]);
    const [productsList, setProductsList] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [leadsShow, setLeadsShow] = useState(false);
    var [addAnotherPerson, setAddAnotherPerson] = useState(false);
    const [meetingId, setMeetingId] = useState(false);
    const [companiesList, setCompaniesList] = useState();
    const [contactsList, setContactsList] = useState();
    const [phoneCode, setSelectedPhoneCode] = useState({ label: "India(+91)", value: "+91" });
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [contactName, setContactName] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [designation, setDesignation] = useState();
    const [email, setEmail] = useState();
    const [department, setDepartment] = useState();
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState();
    const [leadSource, setLeadSource] = useState();
    const [existingCompany, setExistingCompany] = useState(false);
    const [LeadExistStages, setLeadExistStages] = useState([]);
    const [LeadNotExistStages, setLeadNotExistStages] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(false);

    const handlePoDateChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD');


        setPoDate(newDate);
    }

    const [showPoDate, setShowPoDate] = useState(false);
    const [salesStages, setSalesStages] = useState([]);

    const [SalesStage, setSelectedSalesStage] = useState(false);

    const activity_status = useRef();
    const remarks = useRef();
    const task_name = useRef();
    const task_description = useRef();
    const action_date = useRef();
    const reminder_date = useRef();
    const initial_meeting_info = useRef();
    const new_meeting_date_time = useRef();
    const contact_name = useRef();
    const street = useRef();
    const company_website = useRef();
    const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));
    const [reminderDate, setReminderDate] = React.useState(moment(twodays).format('LLLL'));
    const [newMeetingDateTime, setNewMeetingDateTime] = React.useState(moment(twodays).format('LLLL'));
    const [accountManagersList, setAccountManagersList] = useState([]);
    const [open, setOpen] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    var today = new Date();
    var day = today.getDate() + 3;
    var month = today.getMonth() + 1; //January is 0!
    var year = today.getFullYear();

    var threedays = moment().add(3, 'days');

    var twodays = moment().add(2, 'days');




    var nextThirdDay = `${year}-${month}-${day}`;

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };
    const getBusinessTypesList = async () => {
        const res = await axios.get("/business-types/search");
        setBusinessTypes(res.data.businessTypesList);
    }

    useEffect(() => {
        getUsersList();
        getLeadStages();
        getLeadStagesOpportunities();
        getIndustriesList();
        getAnnualRevenueRangeList();
        getProductsList();
        getAccontManagersList();
        getAnsysAccountManagersList();
        getDrfStatusList();
        getProductsList();
        getRegionsList();
        getBusinessTypesList();

    }, []);

    const handleClose = (event, reason) => {
        setAnchorEl(false);


        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const getAnnualRevenueRangeList = async () => {


        const res = await axios.get("/annual-revenue-range/search");
        setAnnualRevenueRangeList(res.data.annualRevenueRange);


        setExistingIndustry(false);
    }

    const getProductsList = async () => {


        const res = await axios.get("/products/search");
        setProductsList(res.data.productsList);
    }

    const getAccontManagersList = async () => {

        const res = await axios.get("/user/list");
        if (res.data.status === "success") {
            setAccountManagersList(res.data.usersList);
        }

    }

    const getLeadStages = async () => {
        const res = await axios.get("/leads/stages/leads");


        setLeadExistStages(res.data.forExistingLeads);
        setLeadNotExistStages(res.data.forNotExistingLeads);

    }

    const handleSetIndustry = (value) => {

        if (value.__isNew__ === true) {
            setExistingIndustry(false);

        } else {
            setExistingIndustry(true);
        }
        setIndustry({ value: value.label, label: value.label });
        activity.industry = value.label;
    }
    const getRegionsList = async () => {
        const res = await axios.get("/region/search");
        setRegionsList(res.data.regionList);
    }
    const getAnsysAccountManagersList = async () => {
        const res = await axios.get("/ansys-account-managers/search");
        setAnsysAccountManagerList(res.data.AnsysAccountManagers);
    }
    const handleSetCompanyName = (value) => {
        setSelectedCompany(true);
        setCompanyName(value.value);
        if (value.__isNew__ === true) {
            activity.company_name = value.value;
            setExistingCompany(false);

        } else {
            setAccountId(value.data_id);
            setExistingCompany(true);
            activity.company_name = value.value;
            activity.industry = value.industry;
            activity.company_website = value.company_website;
            activity.annual_revenue = value.annual_revenue;
            activity.product = value.product;
            activity.street = value.company_address ? value.company_address.street : "";
            activity.postal_code = value.company_address ? Number(value.company_address.postal_code) : "";
            activity.state = value.company_address ? value.company_address.state : "";
            activity.city = value.company_address ? value.company_address.city : "";
            activity.country = value.company_address ? value.company_address.country : "";
            activity.industry = value.industry
            if (value.company_address) {
                setPostalCode(Number(value.company_address.postal_code));
                setCountry(value.company_address.country);
                setState(value.company_address.state);
                setCity(value.company_address.city);
            }

            setIndustry({ value: value.industry, label: value.industry });
            getPersonsList(value.data_id);

        }

    }
    const getLeadStagesOpportunities = async () => {
        const res = await axios.get("/leads/stages/opportunities");

        setSalesStages(res.data.salesStagesList);
        setSalesActivitiesList(res.data.salesActivitiesList);
    }

    const getCompaniesList = async (search) => {

        if (selectedCompany) {
            setCompanyName("");
            setAccountId(null);
            setExistingCompany(true);
            activity.company_name = null;
            activity.industry = null;
            activity.company_website = null;
            activity.annual_revenue = null;
            activity.product = null;
            activity.street = "";

            activity.postal_code = "";
            activity.state = "";
            activity.city = "";
            activity.country = "";

            setPostalCode(false);
            setCountry(false);
            setState(false);
            setCity(false);

            setIndustry(false);
        }


        setCompaniesList([]);
        if (search.target.value) {
            const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
            setCompaniesList(res.data.AccountsList);

            setExistingCompany(false);

        }

    }

    const handleSetContactName = (value) => {

        setContactName(value.label);
        setContactNumber(value.contact_number);
        setDesignation(value.designation);
        setEmail(value.email);
        setDepartment(value.department);
        setSelectedPhoneCode({ label: value.phone_code || "India(+91)", value: value.phone_code || "India(+91)" });



        if (value.__isNew__ !== true) {
            activity.contact_name = value.label ? value.label : "";
            activity.contact_number = value.contact_number ? value.contact_number : "";
            activity.designation = value.designation;
            activity.email = value.email;
            activity.department = value.department;
            activity.phone_code = value.phone_code ? value.phone_code : "+91";
        }


        activity.contact_person = value.label;

    }

    const getCityStateCountry = async (event) => {

        setPostalCode(event.target.value);

        if ((event.target.value).length === 6) {
            const res = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + event.target.value + "&sensor=true&key=AIzaSyCNUkjhrG73QZen2b3fML9O7cyVQeq8Kd4")
            if (res.status === 200) {
                var data = await res.json();
                if (data.status === "OK" && data.results[0]) {

                    var data = data.results[0].address_components
                    var length = data.length;
                    setCity(data[length - 3].long_name);
                    setState(data[length - 2].long_name);
                    setCountry(data[length - 1].long_name);

                    activity.city = data[length - 3].long_name;
                    activity.state = data[length - 2].long_name;
                    activity.country = data[length - 1].long_name;
                    activity.postal_code = event.target.value;
                } else {
                    setCity("");
                    setState("");
                    setCountry("");

                    activity.city = "";
                    activity.state = "";
                    activity.country = "";

                }

            }
        }
        activity.postal_code = event.target.value;

    };

    const handleSetPhoneCodes = (value) => {

        setSelectedPhoneCode({ label: value.label, value: value.value });
        activity.phone_code = value.value;
    }

    const onNewOptionClick = (option) => {

    }

    const handleSetLeadSource = (value) => {
        setLeadSource(value.value);
    }

    const handleMeetingDateTimeChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        activity.new_meeting_date_time = newDate;
    }


    const getIndustriesList = async () => {

        const res = await axios.get("/industries/search");
        setIndustriesList(res.data.industriesList);

        setExistingIndustry(false);
    }

    const handleReminderDateChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

        setReminderDate(newDate);
        activity.reminder_date = newDate;
    };


    const handleActionDateChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        setActionDate(newDate);
        activity.action_date = newDate;
    }

    const onInputChange = (e) => {
        if (e.target.name === "country") setCountry(e.target.value);
        if (e.target.name === "state") setState(e.target.value);
        if (e.target.name === "city") setCity(e.target.value);
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        if (e.target.name === "op_po_value") {
            setpovalue(e.target.value);
        }
        if (e.target.name === "op_percentage_split_tokaizenat") {
            setpercentagesplittokaizenat(e.target.value);
        }


        var sales_stage_id = e.currentTarget.dataset.id

        if (sales_stage_id !== undefined) {
            activity.sales_stage = sales_stage_id;
        } else {
            activity.sales_stage = "";
        }

        var confidence_level = e.currentTarget.dataset.id


        if (confidence_level !== undefined) {
            setSelectedSalesStage(e.target.value);
            setSelectedSalesConfidenceStage(confidence_level);

            activity.op_sales_confidence = confidence_level;
        }
    };


    const [activity, setActivity] = useState({
        company_name: "",
        contact_person: "",
        industry: "",
        phone_code: "+91",
        contact_number: "",
        designation: "",
        email: "",
        department: "",
        company_website: "",
        street: "",
        postal_code: "",
        state: "",
        city: "",
        country: "",
        follow_up_task: "",
        follow_up_task_detail: "",
        follow_up_date: threedays,
        annual_revenue: "",
        lead_id: "",
        sales_stage: "",
        remarks: "",
        product: "",
    });

    const getPersonsList = async (data) => {

        setContactsList([]);
        if (data) {
            const res = await axios.get("/contacts/search/" + data);
            setContactsList(res.data.contacts);


        }

    }

    // Add Another Person 
    const addAnotherPersonFunc = (e) => {

        e.preventDefault();
        Object.keys(activity).map(function (key) {

            if (activity[key] === undefined || activity[key] === "") {

                setActivityError(key, {
                    type: "manual",
                    message: key.toUpperCase() + " cannot be empty",
                });
            }

            if (activity.contact_number.length !== 10) {

                setActivityError("contact_number", {
                    type: "manual",
                    message: " Contact Number cannot be more than 10 digits",
                });
                e.stopPropagation();
            }
        });

        addAnotherPerson = true
        addActivityHandle(e);

    }

    const timelineChange = (e) => {
        setTimeline(e.target.value);
        var timeline_split = e.target.value.split('-');
        setClosureMonth(timeline_split[0]);
        setTimelineYear(timeline_split[1]);
        var monthNumber = moment().month(timeline_split[0]).format("M");

        if (monthNumber <= 3) {
            setClosingQuarter("Q1");
        } else if (monthNumber <= 6) {
            setClosingQuarter("Q2");
        } else if (monthNumber <= 9) {
            setClosingQuarter("Q3");
        } else if (monthNumber <= 12) {
            setClosingQuarter("Q4");
        }
    }

    const addActivityHandle = async (e) => {


        setDisable(true);

        setSnackBarMessage("Uploading data...")
        setOpen(true);

        setTimeout(() => { }, 500);
        var actionDates = activity.action_date ? activity.action_date : threedays
        var reminderDates = activity.reminder_date ? activity.reminder_date : twodays
        const activityData = {
            company_name: activity.company_name,
            contact_name: activity.contact_person,
            industry: activity.industry,
            phone_code: activity.phone_code ? activity.phone_code : "+91",
            contact_number: activity.contact_number.toString(),
            designation: activity.designation,
            email: activity.email,
            department: activity.department,
            company_website: activity.company_website,
            street: activity.street,
            postal_code: activity.postal_code.toString(),
            state: activity.state,
            city: activity.city,
            country: activity.country,
            lead_stage_id: activity_status.current.value,
            task_name: activity.task_name,
            task_description: activity.task_description,
            action_date: moment(actionDates).format('YYYY-MM-DD HH:mm:ss'),
            reminder_date: moment(reminderDates).format('YYYY-MM-DD HH:mm:ss'),
            user_id: activity.user_id,
            annual_revenue: activity.annual_revenue,
            remarks: activity.remarks,
            product: activity.product ? activity.product : "",
            isales_assign_to: activity.assigning_account_manager,
            isales_meeting_date_time: moment(newMeetingDateTime).format('YYYY-MM-DD HH:mm:ss'),
            isales_meeting_info: activity.initial_meeting_info,

            business_type: activity.op_business_type,

            budget: activity.op_budget,

            po_value: op_po_value,
            percentage_split_tokaizenat: op_percentage_split_tokaizenat,
            revenue_to_kaizenat: ((op_percentage_split_tokaizenat / 100) * op_po_value).toFixed(0),
            principal_in_rupees: (op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)).toFixed(0),
            principal_in_dollars: ((op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)) / 73.60).toFixed(0),

            timeline: timeline,
            closureMonth: closureMonth,
            timelineYear: timelineYear,
            closingQuarter: closingQuarter,
            timeline_quarter: closingQuarter,
            timeline_month: closureMonth,
            timeline_year: timelineYear,

            drf_expiry_date: drfExpiryDate,

            country: country,
            state: state,
            city: city

        }
        if (meetingId) {
            activityData.meeting_id = meetingId
        }

        try {
            const res = await axios.post("opportunities/new/create", activityData);



            alert("successfully added");
            window.location.reload();

        } catch (err) {
            alert(err)



        }
    }

    return (
        <>
            <Navbar value="opportunity" className="activity_list_navbar" />


            <Modal
                show={true}
                onHide={() => setLeadsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add Lead
                    </Modal.Title>
                    <a onClick={false} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={1} onSubmit={handleSubmit(addActivityHandle)} >

                        <div class="row">
                            <div class="col-md-8 col-md-offset-2">

                                <CreatableSelect
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={companiesList}
                                    placeholder="Search Company Name"
                                    styles={colourStyles}
                                    onChange={handleSetCompanyName}
                                    onKeyDown={getCompaniesList}
                                    onNewOptionClick={onNewOptionClick}
                                >
                                </CreatableSelect>
                                <br />
                                <br />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-6">

                                <CreatableSelect
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={contactsList}
                                    placeholder="Search Contact Person"
                                    inputRef={contact_name}
                                    name="contact_name"
                                    onChange={handleSetContactName}
                                    styles={colourStyles}
                                    autoComplete="none"
                                    required
                                >
                                </CreatableSelect>

                            </div>
                            <div class="col-md-6">
                                <TextField variant="outlined"
                                    {...register("industry", {
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    value={activity.industry}
                                    select
                                    helperText={errors.industry ? errors.industry.message : ''}
                                    error={Boolean(errors.industry)}
                                    onChange={e => onInputChange(e)}
                                    required
                                    type="text" className="form-control" inputRef={industry} name="industry" label="Industry"
                                >
                                    {industriesList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>

                            </div>
                        </div>
                        <br />
                        <div className='col-md-6'>
                            <div class="col-md-6">
                                <TextField variant="outlined"
                                    {...register("user_id", {
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select
                                    helperText={errors.user_id ? errors.user_id.message : ''}
                                    error={Boolean(errors.user_id)}
                                    onChange={e => onInputChange(e)}
                                    required
                                    type="text" className="form-control" name="user_id" label="Select Account Manager"
                                >
                                    {usersList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>

                            </div>
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-md-3">
                                <Select
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={PhoneCodes}
                                    placeholder="Search code"
                                    name="phone_code"
                                    defaultValue="+91"
                                    value={phoneCode}
                                    onChange={handleSetPhoneCodes}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </div>
                            <div className="col-md-3">

                                <TextField variant="outlined" autoComplete="none"
                                    {...register("contact_number", {
                                        minLength: {
                                            value: 10,
                                            message: "Contact Number must be at least 10 characters"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Contact Number cannot exceed 10 digits"
                                        }
                                    })}
                                    InputProps={{ inputProps: { minLength: 10, maxLength: 10 } }}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.contact_number}
                                    helperText={errors.contact_number ? errors.contact_number.message : ''}
                                    error={Boolean(errors.contact_number)}
                                    type="number" className="form-control" id="contact_number" name="contact_number" label="Contact Number" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("designation", {
                                        minLength: {
                                            value: 3,
                                            message: "Designation must be at least 3 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.designation}
                                    helperText={errors.designation ? errors.designation.message : ''}
                                    error={Boolean(errors.designation)}
                                    type="text" className="form-control" id="designation" name="designation" label="Designation" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("email", {
                                        minLength: {
                                            value: 6,
                                            message: "Email must be at least 6 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.email}
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={Boolean(errors.email)}
                                    className="form-control" type="email" id="email" name="email" label="Email Id" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("department", {
                                        minLength: {
                                            value: 3,
                                            message: "Department must be at least 3 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.department}
                                    helperText={errors.department ? errors.department.message : ''}
                                    error={Boolean(errors.department)}
                                    className="form-control" id="department" type="text" name="department" label="Department" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField multiline rows={2} autoComplete="none"
                                    {...register("street", {
                                        minLength: {
                                            value: 3,
                                            message: "Street must be at least 6 characters"
                                        }
                                    })}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    value={activity.street}
                                    onChange={e => onInputChange(e)}
                                    helperText={errors.street ? errors.street.message : ''}
                                    error={Boolean(errors.street)}
                                    name="street" className="form-control" inputRef={street} label="Street" variant="outlined" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("company_website", {
                                        minLength: {
                                            value: 6,
                                            message: "Company Website must be at least 6 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => onInputChange(e)}
                                    value={activity.company_website}
                                    helperText={errors.company_website ? errors.company_website.message : ''}
                                    error={Boolean(errors.company_website)}
                                    type="text" className="form-control" inputRef={company_website} name="company_website" label="Company Website" />
                                <br />

                                <div style={{ marginTop: "15px" }}>
                                    <TextField variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                        }}
                                        style={{
                                            borderLeftWidth: 3,
                                            borderLeftColor: 'red',
                                            borderLeftStyle: 'solid'
                                        }}

                                        {...register("annual_revenue", {
                                            minLength: {
                                                value: 3,
                                                message: "Annual Revenue must be at least 3 characters"
                                            }
                                        })}
                                        select
                                        required
                                        value={activity.annual_revenue}
                                        helperText={errors.annual_revenue ? errors.annual_revenue.message : ''}
                                        error={Boolean(errors.annual_revenue)}

                                        type="number" className="form-control" name="annual_revenue" label="Annual Revenue" onChange={e => onInputChange(e)} >
                                        {}

                                        {
                                            annualRevenueRangeList.map((option, annual_revenue) => (
                                                <MenuItem key={option.value} selected={annual_revenue === option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }

                                    </TextField>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div class="row">
                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"

                                    {...register("postal_code", {
                                        minLength: {
                                            value: 6,
                                            message: "Postal Code must be at least 6 characters"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Postal Code cannot exceed 6 digits"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={postalCode}
                                    helperText={errors.postal_code ? errors.postal_code.message : ''}
                                    error={Boolean(errors.postal_code)}
                                    onChange={getCityStateCountry}
                                    type="number" label="Postal Code"
                                    className="form-control" name="postal_code" />
                            </div>


                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("state", {

                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    helperText={errors.state ? errors.state.message : ''}
                                    value={state}
                                    onChange={e => onInputChange(e)}
                                    error={Boolean(errors.state)}
                                    type="text" className="form-control" name="state" label="State" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined"
                                    {...register("product", {
                                        minLength: {
                                            value: 3,
                                            message: "Product must be at least 3 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select
                                    required
                                    value={activity.product}
                                    helperText={errors.product ? errors.product.message : ''}
                                    error={Boolean(errors.product)}

                                    type="number" className="form-control" name="product" label="Product" onChange={e => onInputChange(e)} >
                                    {}

                                    {
                                        productsList.map((option, product) => (
                                            <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }

                                </TextField>
                            </div>

                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("city", {
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    value={city}
                                    onChange={e => onInputChange(e)}
                                    helperText={errors.city ? errors.city.message : ''}
                                    error={Boolean(errors.city)}
                                    type="text" className="form-control" name="city" label="City" />
                            </div>

                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("country", {
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    value={country}
                                    helperText={errors.country ? errors.country.message : ''}
                                    error={Boolean(errors.country)}
                                    onChange={e => onInputChange(e)}
                                    type="text" name="country" label="Country" className="form-control" />
                            </div>

                        </div>
                        <br />
                        <div className="meeting_border shadow withText" style={{ border: '1px solid #c4c4c4', padding: '1rem', background: '#fff' }}>
                            <label className='labels'>Meeting Details (<span style={{ fontSize: '10px' }}>All fields mandatory to add new meeting</span>)</label>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        inputRef={remarks}
                                        helperText={errors.remarks ? errors.remarks.message : ''}
                                        error={Boolean(errors.remarks)}
                                        onChange={e => onInputChange(e)}
                                        label="Meeting Discussion"
                                        value={activity.remarks}
                                        required
                                        style={{
                                            borderLeftWidth: 3,
                                            borderLeftColor: 'red',
                                            borderLeftStyle: 'solid'
                                        }}
                                        name="remarks" className="form-control" />
                                </div>
                                <div className="col-md-6">
                                    <TextField autoComplete="none"
                                        inputRef={activity_status}
                                        className="form-control"
                                        id="standard-select-currency"
                                        select
                                        style={{
                                            borderLeftWidth: 3,
                                            borderLeftColor: 'red',
                                            borderLeftStyle: 'solid'
                                        }}
                                        required
                                        helperText={errors.activity_status ? errors.activity_status.message : ''}
                                        error={Boolean(errors.activity_status)}
                                        onChange={e => onInputChange(e)}
                                        label="Select Lead Stage"
                                        variant="outlined"
                                        value={activity.activity_status}
                                        name="activity_status"

                                        {...register("activity_status", {
                                        })}

                                    >

                                        {existingCompany ?

                                            LeadExistStages.map((option) => (
                                                <MenuItem key={option.value} data-id={option.sales_stage} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                            :
                                            LeadNotExistStages.map((option) => (
                                                <MenuItem key={option.value} data-id={option.sales_stage} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }

                                    </TextField>

                                    {}
                                </div>
                            </div>
                        </div>
                        <br />

                        <div class="row">

                            <div className="col-md-4">
                                <TextField variant="outlined"
                                    {...register("timeline", {
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    select
                                    type="text" label="Timeline"
                                    onChange={timelineChange}
                                    className="form-control" value={timeline} >
                                    {
                                        timeLineDateList.map((option, timeline) => (
                                            <MenuItem key={option} value={option} selected={(timeline == option) ? true : ''}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className='col-md-4'>
                                <TextField variant="outlined"
                                    {...register("op_drf_status", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    select
                                    type="text"
                                    label="DRF Status"
                                    name="op_drf_status"
                                    required
                                    className="form-control"
                                    onChange={e => onInputChange(e)}

                                >
                                    {
                                        drfStatusList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>

                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className="col-md-4">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker

                                        {...register("drf_expiry_date", {
                                            minLength: {
                                                value: 1,
                                                message: "Drf Status must be at least 3 characters"
                                            }
                                        })}

                                        className="form-control"
                                        label="Drf Expiry Date"
                                        inputVariant="outlined"
                                        value={drfExpiryDate}
                                        onChange={changeDrfExpiryDate}
                                        required
                                        showTodayButton
                                        name="op_drf_expiry_date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                    />

                                </MuiPickersUtilsProvider>

                            </div>

                            <div className="col-md-4">

                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    {...register("closing_quarter", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    variant="outlined"
                                    label="Closure Quarter"
                                    className="form-control"
                                    name="op_closing_quarter"
                                    disabled
                                    value={closingQuarter}
                                >
                                </TextField>
                            </div>

                            <div className='col-md-4'>
                                <TextField variant="outlined"

                                    {...register("op_region", {
                                        minLength: {
                                            value: 3,
                                            message: "Region must be at least 3 characters"
                                        }
                                    })}
                                    required
                                    select
                                    helperText={errors.op_region ? errors.op_region.message : ''}
                                    error={Boolean(errors.op_region)}
                                    label="Region"
                                    type="text" className="form-control" onChange={e => onInputChange(e)} id="op_region" name="op_region"  >
                                    {
                                        RegionsList.map((option, op_region) => (
                                            <MenuItem key={option.value} selected={op_region === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className="col-md-6">
                                <TextField variant="outlined"
                                    {...register("op_business_type", {
                                        minLength: {
                                            value: 3,
                                            message: "Business Type must be at least 3 characters"
                                        }
                                    })}
                                    select
                                    helperText={errors.op_business_type ? errors.op_business_type.message : ''}
                                    error={Boolean(errors.op_business_type)}
                                    label="Business Type"
                                    type="text" className="form-control" onChange={e => onInputChange(e)} name="op_business_type" >

                                    {
                                        BusinessTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>

                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className='col-md-6' >
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                    }}

                                    helperText={errors.op_budget ? errors.op_budget.message : ''}
                                    error={Boolean(errors.op_budget)}
                                    variant="outlined"
                                    type="number" name="op_budget" label="Budget*"
                                    className="form-control" onChange={e => onInputChange(e)} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">

                                <TextField variant="outlined" type="text" label="Sales Activity *"
                                    name="op_sales_activity" className="form-control"
                                    select
                                    onChange={e => onInputChange(e)} >
                                    {
                                        salesActivitiesList.map((option, op_sales_activity) => (
                                            <MenuItem key={option.value} selected={op_sales_activity === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>


                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-md-4">
                                <TextField variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                    }}
                                    {...register("po_value", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    type="text" label="PO Value" name="op_po_value" className="form-control" onChange={e => onInputChange(e)} />
                            </div>
                            <div className="col-md-4">
                                <TextField variant="outlined"
                                    {...register("op_percentage_split_tokaizenat", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><b>%</b></InputAdornment>
                                    }}

                                    type="number" label="% To Kaizenat" name="op_percentage_split_tokaizenat" className="form-control" onChange={e => onInputChange(e)} />
                            </div>
                            <div className="col-md-4">
                                <TextField variant="outlined"

                                    {...register("op_revenue_to_kaizenat", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}

                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                    }}

                                    type="text" label="To Kaizenat( ₹ )" name="op_revenue_to_kaizenat" className="form-control" value={Math.floor((op_percentage_split_tokaizenat / 100) * op_po_value).toLocaleString('en-IN')} onChange={e => onInputChange(e)} />
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div className="col-md-4 ">
                                <TextField variant="outlined"
                                    {...register("op_principal_rupees", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                    }}
                                    type="text" label="To OEM( ₹ )" name="op_principal_rupees" className="form-control" value={Math.floor(op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)).toLocaleString('en-IN')} onChange={e => onInputChange(e)} />
                            </div>
                            <div className="col-md-4">
                                <TextField variant="outlined"


                                    {...register("op_principal_dollars", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start"><b>$</b></InputAdornment>
                                    }}
                                    type="text" name="op_principal_dollars" label="To OEM( $ )" value={Math.floor((op_po_value - ((op_percentage_split_tokaizenat / 100) * op_po_value)) / 73.6).toLocaleString('en-IN')} className="form-control" readOnly={true} />
                            </div>
                            {showPoDate ?
                                <div className="col-md-4">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker

                                            {...register("po_date", {
                                                minLength: {
                                                    value: 1,
                                                    message: "Drf Status must be at least 3 characters"
                                                }
                                            })}
                                            className="form-control"
                                            label="Select PO Date"
                                            inputVariant="outlined"
                                            value={poDate}
                                            onChange={handlePoDateChange}
                                            showTodayButton
                                            disablePast
                                            {...showPoDate ? "required" : ""}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </div>
                                : ""}
                            <div className="col-md-4">
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    {...register("closing_quarter", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    label="Closure Quarter"
                                    className="form-control"
                                    name="op_closing_quarter"
                                    disabled
                                    value={closingQuarter}
                                >
                                </TextField>
                            </div>
                        </div>

                        <br />
                        <div className='row'>
                            <div className="col-md-6">
                                <TextField variant="outlined"
                                    {...register("op_ansys_account_manager", {
                                        minLength: {
                                            value: 3,
                                            message: "Ansys Account Manager must be at least 3 characters"
                                        }
                                    })}
                                    select
                                    helperText={errors.op_ansys_account_manager ? errors.op_ansys_account_manager.message : ''}
                                    error={Boolean(errors.op_ansys_account_manager)}
                                    label="Ansys Account Manager"
                                    type="text" className="form-control" onChange={e => onInputChange(e)} name="op_ansys_account_manager"
                                >
                                    {
                                        ansysAccountManagerList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>

                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                        </div>
                        <br />
                        <div className='row'>
                            <div className="col-md-4">

                                <TextField variant="outlined" type="text" label="Sales Stage"
                                    name="op_sales_stage" className="form-control"
                                    select
                                    {...register("op_sales_stage", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    onChange={e => onInputChange(e)} >
                                    {
                                        salesStages.map((option) => (
                                            <MenuItem key={option.value} data-id={option.confidence_level} data-label={option.label} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>
                            <div className="col-md-4">
                                <TextField variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("op_sales_confidence", {
                                        minLength: {
                                            value: 1,
                                            message: "Drf Status must be at least 3 characters"
                                        }
                                    })}
                                    disabled
                                    isDisabled={true}
                                    type="text" label="Sales Confidence"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    name="op_sales_confidence" className="form-control" value={SalesConfidenceStage} readOnly={true} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div >
                                <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit"  >Submit</button>&nbsp;&nbsp;   &nbsp;&nbsp;
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    )
}

