import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import Select from 'react-select';
import { useLocation } from "react-router-dom";
import AddNewContact from "./AddNewContact";
import { useSelector,useDispatch } from 'react-redux';
import {ContactActions} from "../../store/Contact.js"

const EmployeeUpdateModal = (props) => {
  console.log("props values are",props.company_name)
  const search = useLocation().search;
  const acid = new URLSearchParams(search).get("acid");
  console.log("props values are//",acid)
  const dispatch =useDispatch();
  const AddcontactModal = useSelector((state) => state.Contactlist.AddcontactModal);
  // const openModal =useSelector((state))
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  // ContactActions.AddContactopenModal
  const [contactsList, setContactsList] = useState([]);
  const [state, setState] = useState({});
  const [masterContactsList, setMasterContactsList] = useState([]);
  const [dataObject, setDataObject] = useState([]);
  const getContacts = async () => {
    const res = await axios.get("/demos/economic-buyer-list/" + props.accountId);
    setContactsList(res.data.economicBuyerList);
  }

  const getTotalContactsList = async () => {
    const res = await axios.get("/contacts/contacts-list/" + props.accountId);
    setMasterContactsList(res.data.contactsList);
  }

  const membersChange = (e, value) => {

    dataObject[value] = e

  }

  const handleCheckBox = (e, value) => {
    if (e.target.checked === true) {
      dataObject["checked"] = value
    } else {
      dataObject["checked"] = null
    }
  }

  const updateContacts = async () => {

    var array = {};
    var checked = null;
    Object.keys(dataObject).map(key => {

      if (key !== "checked") {
        var item = dataObject[key]
        var array1 = []
        if (item.length > 0) {
          item.map(item1 => {
            array1.push(item1.value)
          })
          array[key] = array1
        } else {
          array[key] = []
        }
      } else {
        checked = dataObject["checked"]
      }

    })

    var inputData = {
      data: array,
      account_id: props.accountId
    }
    if (checked) {
      inputData.checked = checked
    }
    const res = await axios.post("/contacts/update-organization-chart/update", inputData);
    if (res.data.status === "success") {
      props.refreshData();
      props.onHide()
    }

  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#708090",
        color: "#fff"
      };
    }
  };

  const TreeNodeUpdate = (props) => {

    dataObject[props.data._id] = [];

    var data = contactsList.filter(item => item.value !== props.data._id)

    var datas = props.data.childs.map(item => {
      var item1 = item.split(":")
      return { label: item1[0], value: item1[1] }
    })

    if (datas.length > 0) {
      dataObject[props.data._id] = datas;
    }

    return (
      <div className="row">
        <div className="col-md-2">
          <label>Is Root </label>
          <br />
          <input type="radio" defaultChecked={props.data.is_root ? true : false} name="is_root" onChange={e => handleCheckBox(e, props.data._id)} />
        </div>
        <div className="col-md-3">
          <label for="manager"> Manager </label><br />
          <span>{props.data.contact_name}</span>
        </div>
        <div className="col-md-3" style={{ background: '#000;', marginBottom: '30px' }}>
          <label for="members">Select Members</label>
          <Select
            style={{ innerHeight: '50px', overflowY: 'scroll' }}
            defaultValue={datas}
            isMulti={true}
            options={data}
            closeMenuOnSelect={false}
            name="metric_options"
            onChange={(e) => membersChange(e, props.data._id)}
            styles={colourStyles}

          />
        </div>
        <br /><br />
      </div>
    )

  }

  useEffect(() => {
    getContacts();
    getTotalContactsList();
  }, [])
  return (
    <>
      < Modal
        show={props.show}
        dialogClassName="modal-90w"
        arialabelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Update Chart
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row col-md-3'>
              <button className="btn btn-primary" onClick={() => dispatch(ContactActions.AddContactopenModal())}>Add New Contact</button>
              {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={props.company_name} otherAccountId={acid} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}
              {/* {showAddContactModal ? <AddNewContact companyName={"test"} disableRequired={false} accountId={acid} refreshContactslist={() => getContacts()} show={showAddContactModal} onHide={() => setShowAddContactModal(false)} /> : ""} */}
            </div>
            <br />
            {
              masterContactsList.map(item => {
                return (
                  <TreeNodeUpdate data={item} />
                )
              })
            }
            <updateDiv />
            <button onClick={() => updateContacts()} className="btn btn-primary">Update All</button>
          </>
        </Modal.Body>
      </Modal >
    </>
  );
}


export default EmployeeUpdateModal;