import { createSlice } from "@reduxjs/toolkit";
import  Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    casestudymodal:false,
    showshowLeaveModal:false,

    getcasestudies:[],
    createcaselists:[]
  
}

const Casestudyslice = createSlice({
    name: "Casestudylist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          casestudymodal_openModal: (state, action) => {
            state.casestudymodal = true;
          },
          casestudymodal_closeModal: (state, action) => {
            state.casestudymodal = false; 
          },
          LeaveModalopenModal: (state, action) => {
            state.showshowLeaveModal = true; 
          },
          LeaveModalcloseModal: (state, action) => {
            state.showshowLeaveModal = false;
          },

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },


          Viewcontact_openModal : (state, action) => {
            state.showModal_Viewcontact = true; 
          },
          Viewcontact_CloseModal: (state, action) => {
            state.showModal_Viewcontact = false; 
          },

  ViewaccountupdatedSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = action.payload;
    toast.success("Account updated") 
   
    state.demo_showModal = false;
    
  },

  ViewaccountupdatedFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = [];
    toast.error("Error in Account updated") 
  
    state.demo_showModal = true;
  },

  getcasestudiesSuccess(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.getcasestudies = action.payload;
    // toast.success("Account updated") 
   
    // state.demo_showModal = false;
    
  },
  getcasestudiesFailure(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.getcasestudies = [];
    toast.error("Error in case studies") 
   
    // state.demo_showModal = false;
    
  },

  createcasestudiesSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.createcaselists = action.payload;
    toast.success("Successfully created") 
   
     state.casestudymodal = false;
    
  },
  createcasestudiesFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.createcaselists =[];
    toast.error("Error in created") 
   
     state.casestudymodal = true;
    
  },



    }  
});
export const CasestudyActions = Casestudyslice.actions;



export const Createcasestudies = (formData) => {


  return async (dispatch) => {
    dispatch(CasestudyActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/user/case-studies/create", formData).then((res) => {
        if (res.data.status === "success") {
          dispatch(CasestudyActions.createcasestudiesSuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(CasestudyActions.createcasestudiesFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CasestudyActions.createcasestudiesFailure("Network Error"));
    }
  };
};


export const getcasestudiesdata = (updatedData) => {
    
  
    return async (dispatch) => {
      dispatch(CasestudyActions.loaderStart());
      try {
        await Api.get("/user/case-studies/list").then((res) => {
          if (res.data.status === "success") {
            dispatch(CasestudyActions.getcasestudiesSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(CasestudyActions.getcasestudiesFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CasestudyActions.getcasestudiesFailure("Network Error"));
      }
    };
  };
  


  export default Casestudyslice.reducer;