
import React, { useState, useEffect } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import BenchmarkTable from "../../components/commonColumns/BenchmarkTable";
import { useMemo } from 'react';
import { connect } from 'react-redux';
import {getbenchmarklist} from "../../store/Benchmark"


export const BenchmarkList = React.memo(({user,getbenchmarklistdata,}) => {
    // console.log("benchmark--data-are-inside",benchmarklist)
    // console.log("Assigness",Assignesslist)
  
    // const fechData = async (filters) => {

    //     console.log("filters", filters);

    //     var postData = {
    //         'type': 'all'
    //     }
        // if(filters?.startDate){
        //     postData.startDate = filters.startDate;
        // }
        // if(filters?.endDate){
        //     postData.endDate = filters.endDate;
        // }

        const fechData = async () => {
            const type = {
               'type': 'all'
            }
            getbenchmarklistdata(type)
           }
         
           useMemo(() => {
               fechData()
           },[])
  
           
        return (
            <>
                <Navbar value="benchmark" className="activity_list_navbar" />
                <div className="main_demo_section d-flex flex-column">
                    <div className="demo_table">
                        <BenchmarkTable  filters={(data)=> fechData(data)} />
                    </div>
                </div>
            </>
        )


    })

const mapStateToProps = (state) => ({

    user : state.auth.user,
    benchmarklist:state.Benchmarklist.benchmarklist,

})

const mapDispatchToProps = (dispatch) => ({
    getbenchmarklistdata :(data) => dispatch(getbenchmarklist(data))
  });

    
  export default connect(mapStateToProps,mapDispatchToProps) (BenchmarkList)