import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";


const BenchmarkModal = (props) => {

  console.log("benchmark modal ", props)

  const [contactShow, setContactShow] = useState(false);
  var [deleteClicked, setDeleteClicked] = useState("notClicked");
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [accountId, setAccountId] = useState(false);
  const [productList, setProductList] = useState([]);
  const [requsterName, setRequsterName] = useState([]);
  const [economicBuyerList, setEconomicBuyerList] = useState([]);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [disable, setDisable] = React.useState(false);
  const[multiProductsData,setMultiProductsData] =useState([])
  const [benchmarkData, setBenchmarkData] = useState({
    account_name: "",
    requestor_name: "",
    subject: "",
    project_name: "",
    input_given: "",
    boundary_conditions: "",
    scope_of_work: "",
    success_criteria: "",
    POC_timeline: "",
    ansys_product: "",
    POC_execution_and_deliverables: "",
    initial_discussion: "",
    products_list:[]
  });

  const ProductList = async () => {
    const res = await axios.get("/products/search")
    if (res.data.status === "success") {
      setProductList(res.data.productsList);
    }
  }
  useEffect(() => {
    ProductList();

  }, [])

  useEffect(() => {
    const newData= props.multiProducts.map((data) =>{
     return data.value ? {name:data.label, product_id:data?.value} : {name:data?.name, product_id:data?.product_id}
    })
    setMultiProductsData(newData)
     },[props.multiProducts])

  const { register: benchmarkRegister, handleSubmit: benchmarkHandleSubmit, setError: benchmarkSetError, reset: reset, formState: { errors: benchmarkErrors } } = useForm();
  const {user} = useSelector(state => state.auth)

  const userData = user.user;
  const permissions = user.permissions;

  const addNewBenchmarkHandler = async (e) => {
    setDisable(true);
    setSnackBarMessage("Uploading Data ...!");
    setOpen(true);
    setTimeout(() => { }, 500);


    const updatedData = {
      account_id: props.allTechieActionsDetails ? props.allTechieActionsDetails.account_id : null,
      requestor_id: benchmarkData.requestor_name,
      lead_id: props.allTechieActionsDetails?.lead_id || null,
      opportunity_id: props.allTechieActionsDetails?._id || null,
      subject: benchmarkData.subject,
      benchmark_name: benchmarkData.project_name,
      inputs_given: benchmarkData.input_given,
      boundary_conditions: benchmarkData.boundary_conditions,
      scope_of_work: benchmarkData.scope_of_work,
      success_criteria: benchmarkData.success_criteria,
      poc_timeline: benchmarkData.POC_timeline,
      ansys_product: props.allTechieActionsDetails ? props.allTechieActionsDetails.product : null,
      poc_execution_deliverables: benchmarkData.POC_execution_and_deliverables,
      initial_discussion: benchmarkData.initial_discussion,
      // products : props.allTechieActionsDetails ? props.allTechieActionsDetails?.products : null,
      products:  benchmarkData.products_list ? [benchmarkData.products_list]  : [],
    }

    try {
      const res = await axios.post("/benchmarks/create", updatedData);

      if (res.data.status === "success") {

        if(!props.allTechieActionsDetails.lead_id){
          props.refreshBenchmarkTable();
        }

        setTimeout(() => {
          setOpen(true);
          setSnackBarMessage("Benchmark Successfully Added...!");
        }, 300);

        // alert("Benchmark Successfully Added...!");
        toast.success("Benchmark Successfully Added...!") 
        props.onHide();
      } else {
        setDisable(false);
        setSnackBarMessage(res.data.msg);
        alert(res.data.msg);
      }
      setContactShow(false);
      handleClick();

      reset({
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      })

      setDisable(true);
    } catch (err) {
      setDisable(false);
      alert(err.response.data.msg);
      if (err.response.status === 400) {
        benchmarkSetError("ac_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        setSnackBarMessage(err.response.data.msg)
        setTimeout(() => {
          setOpen(true);
        }, 500);

      }

    }
  }
  const handleClick = () => {
    setOpen(true);
  };
  const onInputChange = (e) => {
    setBenchmarkData({ ...benchmarkData, [e.target.name]: e.target.value }, []);
  };

  const getEconomicBuyers = () => {
    setRequsterName([{ label: "nagesh v", value: 'nagesh v' }, { label: "mahalingam", value: 'mahalingam' }])
  }

  const getEconomicBuyersList = async (accountId) => {

    const res = await axios.get("/demos/economic-buyer-list/" + accountId);
    setEconomicBuyerList(res.data.economicBuyerList);

  }
  useEffect(() => {
    getEconomicBuyersList(props.allTechieActionsDetails.account_id);
    getEconomicBuyers();
  }, [])

  const getAccountsList = async (search) => {

    setCompaniesList([]);
    const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
    setCompaniesList(res.data.AccountsList);

  }

  const handleSetCompanyName = (value) => {

    setSelecteAccount(value.value)
    if (value.__isNew__ === true) {


    } else {
      setAccountId(value.data_id);
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };

  var initialTimeLine = moment();
  var finalTimeLine = moment(initialTimeLine).add(12, 'M');

  var getDaysBetweenDates = function (startDate, endDate) {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('MMM-YYYY'));
      now.add(1, 'M');
    }
    return dates;
  };
  var timeLineDateList = getDaysBetweenDates(initialTimeLine, finalTimeLine);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => setContactShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Benchmark Request"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form >
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...benchmarkRegister("requestor_name", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={benchmarkData.requestor_name}
                  select
                  helperText={benchmarkErrors.requestor_name ? benchmarkErrors.requestor_name.message : ''}
                  error={Boolean(benchmarkErrors.requestor_name)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="requestor_name" label="Requster Name"
                >
                  {economicBuyerList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  type="text" label="POC Timeline"
                  onChange={e => onInputChange(e)}
                  name="POC_timeline"
                  className="form-control" value={benchmarkData.POC_timeline} >
                  {
                    timeLineDateList.map((option, timeline) => (
                      <MenuItem key={option} value={option} selected={timeline === option}>
                        {option}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...benchmarkRegister("subject", {
                    required: {
                      value: true,
                      message: "Subject is required"
                    },
                    minLength: {
                      value: 10,
                      message: "subject must be at least 10 characters"
                    }

                  })}
                  required
                  helperText={benchmarkErrors.subject ? benchmarkErrors.subject.message : ''}
                  error={Boolean(benchmarkErrors.subject)}
                  type="text" className="form-control" value={benchmarkData.subject} name="subject" label="Subject" onChange={e => onInputChange(e)} />
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...benchmarkRegister("project_name", {
                    required: {
                      value: true,
                      message: "Benchmark Name is required"
                    },
                    minLength: {
                      value: 3,
                      message: "Benchmark name must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={benchmarkErrors.project_name ? benchmarkErrors.project_name.message : ''}
                  error={Boolean(benchmarkErrors.project_name)}
                  type="text" label="Benchmark Name" name="project_name" className="form-control" value={benchmarkData.project_name} onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
            </div>
            <div className="row">
            </div>
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...benchmarkRegister("initial_discussion", {
                    required: {
                      value: true,
                      message: "Remarks is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Remarks must be at least 5 characters"
                    }
                  })}
                  helperText={benchmarkErrors.initial_discussion ? benchmarkErrors.initial_discussion.message : ''}
                  error={Boolean(benchmarkErrors.initial_discussion)}

                  type="text" label="Remarks" name="initial_discussion" className="form-control"
                  value={benchmarkData.initial_discussion} onChange={e => onInputChange(e)} >

                </TextField>
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...benchmarkRegister("products_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={benchmarkData.products_list}
                  select
                  helperText={benchmarkErrors.products_list ? benchmarkErrors.products_list.message : ''}
                  error={Boolean(benchmarkErrors.products_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="products_list" label="Select Product"
                >
                  {multiProductsData?.map((option) => (
                    <MenuItem key={option.product_id} value={option.product_id}>
                      {option.name}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
            </div>

            <div className="row">
              <div>

                <a disabled={disable} onClick={() => addNewBenchmarkHandler()} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</a>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )

}
export default BenchmarkModal;