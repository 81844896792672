
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';

import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css"; 
// import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react'; 
 
import {getMeetinglistdata, meetingColumnUpdateddata, getColumnsList, fetchMeetingsList} from "../../store/Meeting"
import { useMemo } from 'react';
import axios from "axios"
import moment from 'moment';
import "./ag-grid.css";



// const filterParams = { values: countries() };

const sortAndFilter = (allOfTheData, sortModel, filterModel) => {
  return sortData(sortModel, filterData(filterModel, allOfTheData));
};

const sortData = (sortModel, data) => {
  const sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  // do an in memory sort of the data, across all the fields
  const resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (let k = 0; k < sortModel.length; k++) {
      const sortColModel = sortModel[k];
      const valueA = a[sortColModel.colId];
      const valueB = b[sortColModel.colId];
      // this filter didn't find a difference, move onto the next one
      if (valueA == valueB) {
        continue;
      }
      const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    // no filters found a difference
    return 0;
  });
  return resultOfSort;
};

const filterData = (filterModel, data) => {
  const filterPresent = filterModel && Object.keys(filterModel).length > 0;
  if (!filterPresent) {
    return data;
  }
  const resultOfFilter = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (filterModel.age) {
      const age = item.age;
      const allowedAge = parseInt(filterModel.age.filter);
      // EQUALS = 1;
      // LESS_THAN = 2;
      // GREATER_THAN = 3;
      if (filterModel.age.type == 'equals') {
        if (age !== allowedAge) {
          continue;
        }
      } else if (filterModel.age.type == 'lessThan') {
        if (age >= allowedAge) {
          continue;
        }
      } else {
        if (age <= allowedAge) {
          continue;
        }
      }
    }
    if (filterModel.year) {
      if (filterModel.year.values.indexOf(item.year.toString()) < 0) {
        // year didn't match, so skip this record
        continue;
      }
    }
    if (filterModel.country) {
      if (filterModel.country.values.indexOf(item.country) < 0) {
        continue;
      }
    }
    resultOfFilter.push(item);
  }
  return resultOfFilter;
};



const AgGrid = ({getMeetinglist, meetinglistdata, user, meetingColumnUpdated, columnsList, getColumnsListData, columnObjects, fetchMeetingsData, meetingsListNew}) => {

  const [agGridApi, setAgGridApi] = useState(null);

  

    moment.tz.setDefault('Asia/Calcutta');
    const [activity, setActivity] = useState({
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD')
    })

    const columnsUpdate = (e) => {
      const col = e.columnApi.getColumnState().map(item => {
        return { ...item, field: item.colId };
      });

      var input = {
        user_id: user?.user?._id,
        table_name: "meetings",
        column_objects: col
      }
      meetingColumnUpdated(input)
    }

      useMemo(() => {
        const updatedData = {
            user_id : user?.user?._id,
            from_date: activity.from_date.toString(),
            to_date: activity.to_date.toString()
        }
        getMeetinglist(updatedData);
        getColumnsListData();
      }, []);

      const [colDefs, setColDefs] = useState([
        { 
            field: "bronze",
            filter: true,
        },
        { 
            field: "silver",
            filter: true,
        },
        { 
            field: "gold",
            filter: true,
        },
        { 
            field: "sport",
            filter: true,
        },
        { 
            field: "athlete",
            filter: true,
        },
        { 
            field: "year",
            filter: true,
        },
        { 
            field: "country",
            filter: true,
        },
        { 
            field: "age",
            filter: true,
        }
      ]);
      const defaultColDef = useMemo(() => {
        return {
          editable: true,
          filter: true,
          enablePivot: true,
          enableValue: true,
          floatingFilter: true,
        };
      }, []);

      const getRowId = useCallback(function (params) {
        return params.data.id;
      }, []);

      const onColumnOrderChanged = (event) => {
        
        const newColumnOrder = event.columnApi.getColumnState().map((col) => col.colId);
        localStorage.setItem('columnOrder', JSON.stringify(newColumnOrder));
        // setColumnOrder(newColumnOrder);
        

      };

      // const fetchData = async (pageSize, pageNumber) => {
      //   
      //   try {
      //     const response = await axios.post('/meetings/page/meetings-pagination', {
      //       params: {
      //         pageSize: pageSize,
      //         pageNumber: pageNumber,
      //       },
      //     });
    
      //     return response.data;
      //   } catch (error) {
      //     console.error('Error fetching data:', error);
      //     throw error;
      //   }
      // };

      const fetchPageData = useCallback((pageNumber, pageSize) => {

        setTimeout(() => {
          const startIndex = (pageNumber - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const pageData = meetinglistdata
          // setRowData(pageData);
        }, 500);
      }, []);
    
      const onGridReady = useCallback((params) => {

        setAgGridApi(params.api);

        // fetchMeetingsData({pageSize: params.api.paginationGetPageSize(), pageNumber: params.api.paginationGetCurrentPage()})
        // return;

        fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
          .then((resp) => resp.json())
          .then((data) => {
            data.forEach(function (d, index) {
              d.id = 'R' + (index + 1);
            });
            const dataSource = {
              rowCount: undefined,
              getRows: (params) => {

                
                // At this point in your code, you would call the server
                // To make the demo look real, wait for 500ms before returning
                setTimeout(function () {
                  // fetchMeetingsData({startRow: params.startRow, endRow: params.endRow})
                  // take a slice of the total rows
                  const dataAfterSortingAndFiltering = sortAndFilter(
                    data,
                    params.sortModel,
                    params.filterModel
                  );
                  const rowsThisPage = dataAfterSortingAndFiltering.slice(
                    params.startRow,
                    params.endRow
                  );
                  // if on or after the last page, work out the last row.
                  let lastRow = -1;
                  if (dataAfterSortingAndFiltering.length <= params.endRow) {
                    lastRow = dataAfterSortingAndFiltering.length;
                  }
                  // call the success callback
                  params.successCallback(rowsThisPage, lastRow);
                }, 500);
              },
            };
            params.api.setGridOption('datasource', dataSource);
          });
      }, []);

      // const onGridReady = (params) => {
      //   setAgGridApi(params.api);
      //   // if (columnOrder.length > 0) {
      //   //   agGridApi.current.setColumnState(columnOrder.map((colId) => ({ colId })));
      //   // }
      //   console.log("params ", params.api)
      //   fetchMeetingsData({pageSize: params.api.paginationGetPageSize(), pageNumber: params.api.paginationGetCurrentPage()})
      // };

      const onCellValueChanged = (event) => {
        columnsUpdate(event)
      }

      const onSortChanged = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnResized = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnVisible = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnPivotChanged = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnRowGroupChanged = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnValueChanged = useCallback((e) => {
        columnsUpdate(e)
      }, []);
    
      const onColumnMoved = useCallback((e) => {
        const newColumnOrder = e.columnApi.getColumnState().map((col) => col.colId);

        if(!e.column?.moving){
          // columnsUpdate(e)
          const col = e.columnApi.getColumnState().map(item => {
            return { ...item, field: item.colId };
          });
    
          var input = {
            user_id: user?.user?._id,
            table_name: "meetings",
            column_objects: col
          }
          meetingColumnUpdated(input)
        }
        
      }, []);

      const onColumnPinned = useCallback((e) => {
        columnsUpdate(e)
      }, []);

      const onPaginationChanged = () => {

        const dataSource = {
          getRows: (params) => {
            // Fetch data based on the scroll position
            const startRow = params.startRow;
            const endRow = params.endRow;
            // fetchDataInRange(startRow, endRow, params.successCallback, params.failCallback);
          },
        };
    
        // gridOptions.api?.setDatasource(dataSource);

        if (agGridApi) {
          const pageSize = agGridApi.paginationGetPageSize();
          const pageNumber = agGridApi.paginationGetCurrentPage();

          // fetchData(params.api.paginationGetPageSize(), params.api.paginationGetCurrentPage())
          // fetchMeetingsData({pageSize: agGridApi.paginationGetPageSize(), pageNumber: agGridApi.paginationGetCurrentPage()+1})
        
        }
      };

      const onPageSizeChanged = (newPageSize) => {

        agGridApi.paginationSetPageSize(newPageSize);
        // fetchPageData(1, newPageSize);
      };

      const onPageChanged = () => {

        const currentPage = agGridApi.paginationGetCurrentPage();
        const pageSize = agGridApi.paginationGetPageSize();
        fetchPageData(currentPage, pageSize);
      };


        // const dataSource = {
  //   getRows: (params) => {
  //     // Fetch data based on the scroll position
  //     const startRow = params.startRow;
  //     const endRow = params.endRow;
  //     // fetchDataInRange(startRow, endRow, params.successCallback, params.failCallback);
  //   },
  // };


    return (
        <div className="ag-theme-quartz-dark" style={{ height: '500px', width: '100%' }}>
            <div style={{ marginBottom: '10px' }}>
              {/* <label>
                Page Size:
                <select onChange={(e) => onPageSizeChanged(Number(e.target.value))}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </label> */}
            </div>

            <AgGridReact 
                // rowModelType= 'serverSide'
                // serverSideInitialRowCount={5500}
                // serverSideFilterOnServer={true}
                rowCount={10}
                rowBuffer={0}
                rowModelType={'infinite'}
                cacheBlockSize={10}
                rowsThisPage={10}
                rowsThisBlock={10}
                cacheOverflowSize={1}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={10}
                maxBlocksInCache={2}
                pagination={true} 
                paginationAutoPageSize={false}
                getRowId={getRowId}
                paginationPageSizeSelector={[10, 20, 50, 100, 500]}
                paginationGetRowCount={500}
                enableRangeSelection={true}
                onPageSizeChanged={onPageSizeChanged}
                // onPaginationChanged={onPaginationChanged}
                // rowData={meetingsListNew} 
                pivotPanelShow='always'
                // columnDefs={columnObjects}
                // paginationAutoPageSize={true}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                sideBar={true}
                suppressMenuHide={true}
                onCellValueChanged={onCellValueChanged}
                onColumnOrderChanged={onColumnOrderChanged}
                // onGridReady={(params) => {
                //     // Save ag-Grid API reference for later use
                //     agGridApi.current = params.api;
                // }}
                onGridReady={onGridReady}
                onSortChanged={onSortChanged}
                onColumnResized={onColumnResized}
                onColumnVisible={onColumnVisible}
                onColumnPivotChanged={onColumnPivotChanged}
                onColumnRowGroupChanged={onColumnRowGroupChanged}
                onColumnValueChanged={onColumnValueChanged}
                onColumnMoved={onColumnMoved}
                onColumnPinned={onColumnPinned}
            />
        </div>
    )
}


const mapStateToProps = (state) => ({
    meetinglistdata : state.meetinglist.meetinglistdata,
    user : state.auth.user,
    columnsList : state.meetinglist.columnsList,
    columnObjects : state.meetinglist.columnObjects,
    meetingsListNew : state.meetinglist.meetingsListNew
});

const mapDispatchToProps = (dispatch) => ({
    getMeetinglist :(data) => dispatch(getMeetinglistdata(data)),
    meetingColumnUpdated : (data) => dispatch(meetingColumnUpdateddata(data)),
    getColumnsListData : () => dispatch(getColumnsList()),
    fetchMeetingsData : (data) => dispatch(fetchMeetingsList(data))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AgGrid);