import React from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import moment from 'moment-timezone';

const HistoryTicketTable = (props) => {
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        display: false,
        sort: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "opened_on",
      label: "UpDated",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
      }
    },
    {
      name: "user_name",
      label: "User",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "summary",
      label: "Title",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: headerProps,
        customBodyRender: (value) => {
          const cleanedDescription = value.replace(/<\/?[^>]+(>|$)/g, '').replace(/;/g, '').replace(/&nbsp/g, '');
          return <div>{cleanedDescription}</div>;
        }
      }
    },
  ];


  const options = {
    textLabels: {
      body: {
        noMatch: props.data.length > 0 ? "Loading..." : "No Records Found..!"
      }
    },
    filter: false,
    filterType: false,
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 180).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 180).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Ticket History List"}
          data={props.data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </>
  )
}
export default HistoryTicketTable;