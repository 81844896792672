import axios from "axios";
const token = localStorage.getItem("user");
// const authToken = token && JSON.parse(token)?.token;
const authToken = token ? JSON.parse(token).token : null;

const Api = axios.create({
// http://localhost:3000/
  //Live Api 
  
  // baseURL: "https://crm.kaizenat.com/api",

  //Development Api
  baseURL: "https://crm.kaizenat.com/api",

  //Beta API

  // baseURL: "https://crm.beta.kaizenat.com/api",

  // baseURL: "https://crm.beta.kaizenat.com/api",

  // baseURL: "http://localhost:8001/api", 

  // baseURL: "https://crm.beta.kaizenat.com/api",
  // baseURL: "http://localhost:8001/api", 


  // baseURL: "https://crm.beta.kaizenat.com/api",
  // baseURL: "http://localhost:8001/api", 

  // baseURL: "http://localhost:8001/api", 


  // baseURL: "http://localhost:8001/api", 

  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: "application/json",
    Authorization: authToken ? `Bearer ${authToken}` : null,
  },
});


Api.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  const authToken = token ? JSON.parse(token).token : null;
  if (authToken) {
    config.headers.Authorization = authToken
  }
  return config;
});

export default Api;