import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from 'react-redux';
import {Ticketassignlist,TicketActions} from "../../store/Ticketlist"

const AssignTicketModal = React.memo(({user,Ticketassignlist,assignupdatedata,showAssignModal,TicketListId,openshowModal,closemodal}) => {
 
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [techieList, setTechieList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [TicketData, setTicketData] = useState({
    techie_names: '',
    product_list: '',
    comment: ''
  })
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const getTechieList = async () => {
    const res = await axios.get("/user/techies-list");
    setTechieList(res.data.techiesList)
  }
  const getProductsList = async () => {
    const res = await axios.get("/products/search");
    setProductsList(res.data.productsList);
  }
  useEffect(() => {
    getTechieList();
    getProductsList();
  }, [])

  const assignSubmit = async (data) => {
    setDisable(true);

    var updatedData = {   
      'ticket_id':TicketListId,
      'techie_id': TicketData.techie_names,
      'remarks': TicketData.comment,
    }
    assignupdatedata(updatedData)
    // try {
    //   const res = await axios.post("/tickets/assign-ticket", updatedData);
    //   setTimeout(() => {
    //     setOpen(true);
    //     setsnackBarMessage("Ticket Successfully Assigned");
    //     props.onHide();
    //   }, 2000);
    //   setsnackBarMessage("Ticket Successfully Assigned");
    //   setRejectShow(false);
    //   handleClick();
    //   props.refreshAssingTicket();

    //   setDisable(true);
    // } catch (err) {
    //   setDisable(false);

    // }

  }

  const onInputChange = (e) => {
    setTicketData({ ...TicketData, [e.target.name]: e.target.value }, []);
  };

  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {(userData.user_role_id.role_name === "user") ? "Assign Ticket" : "Assign Ticket"}
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Assign Ticket</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...rejectRegister("techie_names", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={TicketData.techie_names}
                  select
                  helperText={rejectErrors.techie_names ? rejectErrors.techie_names.message : ''}
                  error={Boolean(rejectErrors.techie_names)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="techie_names" label="Techie Name"
                >
                  {techieList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
              <div className="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...rejectRegister("comment", {
                    required: {
                      value: true,
                      message: "comment is required"
                    },
                    minLength: {
                      value: 5,
                      message: "comment must be at least 5 characters"
                    }

                  })}
                  helperText={rejectErrors.comment ? rejectErrors.comment.message : ''}
                  error={Boolean(rejectErrors.comment)}
                  type="text" className="form-control" value={TicketData.comment} name="comment" label="Remarks" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  showAssignModal: state.Ticketlist.showAssignModal,
  TicketListId: state.Ticketlist.TicketListId,

});
const mapDispatchToProps = (dispatch) => ({

  assignupdatedata:(data) => dispatch(Ticketassignlist(data)),
  openshowModal:(data) => dispatch(TicketActions.Assign_openModal(data)),
  closemodal:(data) => dispatch(TicketActions.Assign_closeModal(data)),

});



export default connect(mapStateToProps,mapDispatchToProps) (AssignTicketModal)

