import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { toPng } from 'html-to-image'
import {singleLayout, multipleLayout, regionalLayout,  SelectYear, AccountMangers, QuartersFilter, config} from './chartConfig'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const PlotlyComponent = createPlotlyComponent(Plotly);

const AnsysSalesTarget = () => {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const ref = useRef(null);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
    const [regionsList, setRegionsList] = useState([]);
    const quartersList = [{ value: "all", label: "Annual" }, { value: 1, label: 'Q1' }, { value: 2, label: "Q2" }, { value: 3, label: 'Q3' }, { value: 4, label: 'Q4' }]
    const getSalesTargetData = async () => {
        const res = await axios.post("/user/reports/ansys-achieved-target", filter)
        if (res.data.status === "success") {
            setData(res.data.chartData);
        }
    }

    const getRegionalTargetsData = async () => {
        const res = await axios.post("/user/reports/ansys-regional-achieved-target", filter)
        if (res.data.status === "success") {
            setData(res.data.chartData);
        }
    }

    const getSalesFilteredData = async () => {
        const res = await axios.post("/user/reports/ansys-achieved-target-filter", filter)
        if (res.data.status === "success") {
            setData(res.data.chartData);
        }
    }

    const getRegions = async () => {
        const resdata = await axios.get('user/reports/regions-list');
        if (resdata.data.status === "success") {
            var dataa = [];
            dataa.push({ label: "All", value: "all" })
            resdata.data.regionsList.map(item => {
                dataa.push(item)
            })
            setRegionsList(dataa);
        } else {
        }
    }

    useEffect(() => {

        if(filter.user_id && filter.user_id == "all"){
            getSalesFilteredData();
        }else{
            getSalesTargetData();
        }

    }, [filter]);

    useEffect(() => {
        
        multipleLayout.title = "Sales Target vs Achieved"
        singleLayout.title = "Sales Target vs Achieved"
        getRegions();
    }, []);


    const regionalTargets = (e) => {
        if (e.target.checked == true) {
            filter.ansysRegionalChart = true
            getRegionalTargetsData();
        }else{
            filter.ansysRegionalChart = false
            kaizenatTargets()
        }
    }

    const kaizenatTargets = () => {

        if (ref.current.checked){
            filter.ansysTarget = true
            filter.disableQuarterFilter = true
            filter.disableYearFilter = true
            filter.disableAccountManagerFilter = true
            getSalesTargetData();
        }else{
            filter.ansysTarget = false
            filter.disableQuarterFilter = false 
            filter.disableYearFilter = false
            filter.disableAccountManagerFilter = false

            if(filter.user_id && filter.user_id !== "all"){
                getSalesTargetData();
            }else{
                filter.user_id = "all"
                getSalesFilteredData();
            }
        }
    }

    const years = [{ value: 2021, label: 2021 }, { value: 2022, label: 2022 }, { value: 2023, label: 2023 }, { value: 2024, label: 2024 }]
 

    const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'Ansys-Sales-Target-chart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:")
});

}

 
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};

  return(

        <>
        <div ref={containerRef}> 
            <div className="row">
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <SelectYear years={years}  disableYearFilter={filter.disableYearFilter} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <QuartersFilter quartersList={quartersList} disableQuarterFilter={filter.disableQuarterFilter} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <AccountMangers accountManagers={regionsList} disableAccountManagerFilter={filter.disableAccountManagerFilter}  updateFilter={ (value) => setFilter({ ...filter, user_id: value }) } />
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <input ref={ref} type="checkbox" id="kaizenat_target" defaultChecked={true} onClick={(e) => kaizenatTargets(e)} />
                    <label for="kaizenat_target">Ansys Targets</label>
                </div>
                
                <div className="col-md-2 col-lg-2" >
                    <input type="checkbox" id="regional_target" defaultChecked={false} onClick={(e) => regionalTargets(e)} />
                    <label for="regional_target">Regional Targets</label>
                </div>

                {
                    (filter.ansysRegionalChart === true) ?
                        <PlotlyComponent
                        id="AnsysSalesChartComponents"
                            data={data}
                            layout={ regionalLayout }
                            useResizeHandler
                            className="w-full h-full"
                            config={config}
                        />
                        
                    : 
                    
                    
                        <PlotlyComponent
                        id="AnsysSalesChartComponents"
                            data={data}
                            layout={ (filter.ansysTarget === undefined || filter.ansysTarget === true || filter.ansysRegionalChart === undefined || filter.ansysRegionalChart === true || filter.user_id !== "all" ) ? multipleLayout  : singleLayout}
                            useResizeHandler
                            className="w-full h-full"
                            config={config}
                        />
                    
                }
            </div>
            </div>
        </>
    )
}

export default AnsysSalesTarget;