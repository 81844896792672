import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment-timezone';
import AssignTrainingModal from '../pageModals/AssignTrainingModal';
import ViewAccount from "../../components/pageModals/ViewAccount";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import AddTechActivityModal from "../pageModals/AddTechActivityModal";
import {ContactColumnUpdateddata,CommonsliceActions} from "../../store/Common"
import {start_Training,completed_Training,delete_Demo_Request,gettraininglist,gettrainingColumnListHandle,TrainingActions,getColumnsList}  from "../../store/Training";

import { connect, useDispatch, useSelector } from 'react-redux';


const TrainingTable = React.memo (({showModal_assign,showModal_activity,show_demoModal,traininglist,gettraininglists,ContactUpdateddata,isFetching,TrainingListId,openmodal,addActivityRowid,activityopenmodal,AccountrowMetaData,AccountrowId,trainingRowMetaData,trainingRowId
,viewaccountopenmodal,startTrainingdata,completed_trainingdata,deleteDemorequestdata,columnsList,getColumnsList,updateColumnsListData}) => {
const Trainingdata =  traininglist
  const {user} = useSelector(state => state.auth)
  const userData = user?.user;
  const permissions = user?.permissions;
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const [tableData, setTableData] = useState([]);
  const handleRowClick = (rowData, rowMeta) => {
    trainingRowMetaData(rowMeta);
    trainingRowId(Trainingdata[rowMeta.dataIndex]._id)
  };

  const filterDemoType = async (type) => {

    setLeadTypeActive(type);
    var data = [];
    if (type === "all") {
      data = Trainingdata;
    } else {
      data = Trainingdata?.filter(function (item) {
        if (item.approval_status === type) {
          return item;
        }
      });
    }
    setTableData(data);
  }

  useEffect(() => {
    filterDemoType("pending")
    setLeadTypeActive("pending");
  }, [Trainingdata]);


  useEffect(() => {
    
    getColumnsList(userData?._id);
  }, []);


  const updateActivity =  async (event, data, meta)=> {
    event.stopPropagation();
    addActivityRowid(meta)
    activityopenmodal()
}


  const onCellClickHandler = (meta) => {
    if (meta.colIndex === 1) {
         viewaccountopenmodal()
      AccountrowMetaData(meta);
      AccountrowId(Trainingdata[meta.dataIndex].account_id?._id)
    }
  }

  const setAsCompleted = (e) => {
    alert(e.target.id);
    document.getElementById(e.target.id).disabled = true;
    document.getElementById(e.target.id).value = "Completed";
  }

  const startTraining = async (id, element) => {
    var updatedData = {
      'status': "started",
      'training_id': id
    }
    // var typeData = {
    //   'type':"all"
    // }
    startTrainingdata(updatedData);

  }

  const completedTraining = async (id, element) => {
    var updatedData = {
      'status': "completed",
      'training_id': id
    }
    // var typeData = {
    //   'type':"all"
    // }
    completed_trainingdata(updatedData)

  }

  const assignTechnical = async (event, data, meta) => {

    event.stopPropagation();
    // setActivityShow(false);
   
    // setShowAssignModal(true);
    // setTrainingListId(meta);
    
    openmodal()
    TrainingListId({id: meta[0], products:meta[4]})
  }

  const deleteDemoRequest =  async (event, data, meta) => {
    event.stopPropagation();
      var updatedData = {
        'training_id': meta,
      }
      deleteDemorequestdata(updatedData)
  }

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "account_id",
      label: "Account Name",
      options: {
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.company_name : "user not found"
        ),
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "user_id",
      label: "Requested by",
      options: {
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value?.first_name + " " + value?.last_name : "user not found"
        ),
        filter: true,
        sort: true,
        display: true
      }
    },
    {
      name: "assigned_to",
      label: "Techie Name",
      options: {
        setCellHeaderProps: headerProps,
        customBodyRender: (value) => (
          value ? value?.first_name + ' ' + value?.last_name : "Not assigned  yet"
        ),
        display: true,
        filter: true,
        display: columnsList?.includes('assigned_to') ? true : false,
      }
    },
    {
      name: "products",
      label: "Product",
      options: {
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          // value ? value.name : "application not found"
          value ? value.map(item => item?.name).join(', ') : "product not found"
        ),
        filter: true,
        sort: true,
        display: columnsList?.includes('products') ? true : false,
      }
    },
    {
      name: "training_type",
      label: "Training Type",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: headerProps,
        display: columnsList?.includes('training_type') ? true : false,
      }
    },
    {
      name: "approval_status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellHeaderProps: headerProps,
        display: columnsList?.includes('approval_status') ? true : false,
        onRowClick: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          var status = tableMeta.rowData[7]
          if (permissions.includes('canAssignTrainings')) {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { assignTechnical(e, value, tableMeta.rowData) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">{value === "pending" ? "Assign" : "Re-Assign"}</button>
                </a>
              </div>
            );
          }
          else {
            return <>
              {
                permissions?.includes('deleteOwnTrainings') && value === "pending" ? 
                  <button onClick={(e) => deleteDemoRequest(e, value, tableMeta.rowData[0])} className='btn-sm btn-group-sm btn btn-table-commone btn-danger'>Delete</button>
                  : <span style={{ textTransform: 'capitalize' }}>{value}</span>
              }
            </>
          }
        }

      }
    },
    {
      name: "status",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var approval_status = tableMeta.rowData[6]
          if (value === "pending" && approval_status === "approved") {
            return (
              <div style={{ display: "flex" }}>
                {permissions?.includes("updateOwnTrainingsTimings") || permissions?.includes("updateAnyTrainingsTimings") ?
                  <a onClick={startTraining.bind(value, tableMeta.rowData[0])}>
                    <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">
                      Start
                    </button>

                  </a>
                  : false}

              </div>
            );
          } else if (value === "started") {
            return (
              <div>
                {permissions?.includes("updateOwnTrainingsTimings") || permissions?.includes("updateAnyTrainingsTimings") ?
                  <a onClick={completedTraining.bind(value, tableMeta.rowData[0])}>
                    <button style={{ background: '#cd485c' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">
                      Complete
                    </button>
                  </a>
                  : false}
              </div>

            )
          }
          else if (value === "completed") {
            return "Completed"
          } else {
            return "Approval Pending"
          }
        }

      }
    },
    {
      name: "status",
      label: "Add Activity",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[6]
          if (value === "started" && status !== "completed") {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { updateActivity(e, value, tableMeta.rowData[0]) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">Add Activity</button>
                </a>
              </div>
            );
          }
          else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
        }

      }
    },
    {
      name: "course_type",
      label: "Course Type",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: headerProps,
        display: columnsList?.includes('course_type') ? true : false,
      }
    },
    {
      name: "number_of_attendees",
      label: "No of Attendees",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: headerProps,
        display: columnsList?.includes('number_of_attendees') ? true : false,
      }
    },
    {
      name: "date_time",
      label: "Training Start Date & Time",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('date_time') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
      }
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('start_date') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
      }
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('end_date') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
      }
    },
    {
      name: "session_type",
      label: "Session Type",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: headerProps,
        display: columnsList?.includes('session_type') ? true : false,
      }
    },
    {
      name: "initial_discussion",
      label: "Initial Comments",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        display: columnsList?.includes('initial_discussion') ? true : false,
      }
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        display: columnsList?.includes('remarks') ? true : false,
      }
    },
  ];


  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }



  const options = {
    textLabels: {
      body: {
         noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);
        // var index = columnsList.indexOf(changedColumn);
        // if (index !== -1) {
        //     columnsList.splice(index, 1);
        //     columnsList(columnsList);
        // }
    }

      const updatedData = {
        user_id: userData._id,
        table_name: "trainings",
        columns_list: columns,
      }

      ContactUpdateddata(updatedData)
      updateColumnsListData(columns)
      
    },
    download: false,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
    onRowClick: handleRowClick,
  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<div style={{ display: "flex", flexDirection: "row" }} >
          <b>Trainings List</b>&nbsp;&nbsp;&nbsp;
          <span onClick={() => filterDemoType("all")} className={leadTypeActive === "all" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> All </span>&nbsp;&nbsp;
          <span onClick={() => filterDemoType("pending")} className={leadTypeActive === "pending" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Pending </span>&nbsp;&nbsp;
        </div>}
          data={tableData}
          dataRequstor={traininglist?._id}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    
      {showModal_assign ? <AssignTrainingModal /> : ""}
      {showModal_activity ? <AddTechActivityModal /> : ""}
      {show_demoModal ? <ViewAccount /> : ""}

    </>
  )

});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  showModal_assign: state.Traininglist.showModal_assign,
  showModal_activity:  state.Commonlist.showModal_activity,
  show_demoModal : state.Commonlist.demo_showModal,
  traininglist:state.Traininglist.traininglist,
  isFetching : state.Traininglist.isFetching,
  columnsList:state.Traininglist.columnsList

});

const mapDispatchToProps = (dispatch) => ({

      ContactUpdateddata: (data) => dispatch(ContactColumnUpdateddata(data)),
      TrainingListId:(data) => dispatch(TrainingActions.setTrainingListId(data)),
      openmodal:(data) => dispatch(TrainingActions.assign_openModal(data)),
      addActivityRowid:(data) => dispatch(CommonsliceActions.setaddActivityRowId(data)),
      activityopenmodal:(data) => dispatch(CommonsliceActions.activity_openModal(data)),
      AccountrowMetaData:(data) => dispatch(CommonsliceActions.setrowMetaData(data)),
      AccountrowId:(data) => dispatch(CommonsliceActions.setRowId(data)),
      trainingRowMetaData:(data) => dispatch(TrainingActions.TrainingsetrowMetaData(data)),
      trainingRowId:(data) => dispatch(TrainingActions.TrainingsetRowId(data)),
      viewaccountopenmodal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
      startTrainingdata: (data) => dispatch(start_Training(data)),
      completed_trainingdata: (data) => dispatch(completed_Training(data)),
      deleteDemorequestdata: (data) => dispatch(delete_Demo_Request(data)),
      getColumnsList: (data) => dispatch(getColumnsList(data)),
      updateColumnsListData: (data) => dispatch(TrainingActions.updateColumnsList(data))
      // gettraininglistdata: (data) => dispatch(gettraininglist(data)),
   
});
export default connect(mapStateToProps, mapDispatchToProps)(TrainingTable);