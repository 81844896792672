import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
const WonOpportunitiesPoModal = (props) => {
  const { user } = useSelector((state) => state.auth);

  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [selectedPOFile, setSelectedPOFile] = useState([]);
  const [selectedQuatationFile, setselectedQuatationFile] = useState([]);
  const {
    register: rejectRegister,
    handleSubmit: poHandleSubmit,
    setError: rejectSetError,
    formState: { errors: rejectErrors },
  } = useForm();

  // console.log("po files are here", selectedPOFile)
  const poSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    if (!selectedPOFile || selectedPOFile?.length === 0) {
      toast.error("Please select the PO file.");
      setDisable(false);
      return;
    }

    if (!selectedQuatationFile || selectedQuatationFile?.length === 0) {
      toast.error("Please select the Quotation file.");
      setDisable(false);
      return;
    }
    const formData = new FormData();
    formData.append("po_file", selectedPOFile);
    formData.append("quotation_file", selectedQuatationFile);
    formData.append("opportunity_id", props.opportunitiesId);
  
    try {
   
      const res = await axios.post("/opportunities/upload-po-quatation", formData);
      setTimeout(() => {
        toast.success("Po and Quotation files uploaded successfully!")
        props.poquationStatus(true)
        props.onHide();
      }, 2000);
      // toast.success("Po and Quotation files uploaded successfully!")
      // setsnackBarMessage("Po and Quotation files uploaded successfully!");
      setRejectShow(false);

      props.refreshPageData();
      setDisable(true);
    } catch (err) {
      setDisable(false);
    }
  };
 
  const changeHandler = (event) => {
    var poFiles = event.target.files;
    console.log("files ", poFiles)
    if (poFiles?.length > 0) {
      var filer = poFiles[0];
      setSelectedPOFile(filer);
    }
  };
  const quotationChangeHandler = (event) => {
    var files = event.target.files;
    console.log("files quotation", files)
    if (files?.length > 0) {
      var filep = files[0];
      setselectedQuatationFile(filep);
    }
  };
  useEffect(() =>{
    props?.poquationStatus(false)
  },[])

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => setRejectShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"PO Quotation Modal"}
          </Modal.Title>
          <a
            onClick={props.onCancelled}
            className="btn btn-sm btn-white btn-danger bottomBarButtons"
          >
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={poSubmit} encType="multipart/form-data">
            <div className="row headerBand">
              <label>PO and Quotation</label>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 mt-4">
                {/* <label htmlFor="file" className="custom-file-input2"> */}
                <label for="fileInput1" class="file-label">Choose PO file</label>
                
                  <input
                    type="file"
                    id="file"
                    name="po_file"
                    accept="*/*"
                    // style={{ display: "none" }}
                    onChange={changeHandler}
                    
                  />
                  {selectedPOFile && (
                    <p>File Path: {selectedPOFile?.name}</p>
                  )}
                {/* </label> */}
              </div>
              <div className="col-md-6 mt-4">
                {/* <label htmlFor="file2" className="custom-file-input3"> */}
                <label for="fileInput1" class="file-label">Choose Quotation file</label>
                  <input
                    type="file"
                    id="file2"
                    name="quotation_file"
                    accept="*/*"
                    // style={{ display: "none" }}
                    onChange={quotationChangeHandler}
                    
                  />
                  {selectedQuatationFile && (
                    <p>File Path: {selectedQuatationFile?.name}</p>
                  )}
                {/* </label> */}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button
                  // disabled={disable}
                  type="submit"
                  style={{
                    background: "#212060",
                    color: "#FFFFFF",
                    float: "left",
                  }}
                  className="btn btn-sm  bottomBarButtons upadte_common_btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

   
    </>
  );
};
export default WonOpportunitiesPoModal;
