import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const HardwareAssetsDetails = ({ formData, onInputchange,nextpage ,submits,dateinputchange,expirydateactivity}) => {
   
    const [TypeList, SetTypeList] = useState([]);
    const [Warrantytype, SetWarrantytype] = useState([]);
    const [Purchaseintype, SetPurchaseintype] = useState([]);
    const [Statustype, setStatusType] = useState([]);
   
    const getTypelist = () => {
        SetTypeList([{ label: "Laptop", value: "Laptop" }, { label: "Desktop", value: "Desktop" }, { label: "Mobile", value: "Mobile" }, { label: "Mouse", value: "Mouse" },
        { label: "Tab", value: "Tab" }, { label: "Ext Hdd", value: "Ext Hdd" }, { label: "Dongle", value: "Dongle" }, { label: "Sim Card", value: "Sim Card" }, { label: "Pendrive", value: "Pendrive" }])
    }
    const getWarrantyType = () => {
        SetWarrantytype([{ label: "No Warranty", value: "No Warranty" }, { label: "6 Months", value: "6 Months" }, { label: "1 Year", value: "1 Year" }, { label: "2 Year", value: "2 Year" }, { label: "3 Year", value: "3 Year" } ,{ label: "5 Year", value: "5 Year" }])
    }

    const getPurchaseintype = () => {
        SetPurchaseintype([{ label: "Amazon", value: "Amazon" }, { label: "Flipkart", value: "Flipkart" }, { label: "Offline", value: "Offline" }])

    }
    const getStatusType = () => {
        setStatusType([{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }, { label: "Damaged", value: "Damaged" }])
    }
    useEffect(() => {
        getTypelist();
        getWarrantyType();
        getPurchaseintype();
        getStatusType();

    }, []);
    const submitformdata = (e) => {
        e.preventDefault();
        if(formData.type ==="Pendrive" || formData.type==="Dongle" || formData.type ==="Sim Card" || formData.type ==="Mouse"){
            submits() ;

        } else {
            nextpage();
        }

        
    }
   
    return (
        <>
          <form onSubmit={submitformdata}>
                <div className='row'>
                        <div className='col-md-6'>
                        <TextField variant="outlined"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            value={formData.type}
                            onChange={e => onInputchange(e)}
                            size="small"
                            type="text" className="form-control" name="type" label="Type"
                        >
                            {TypeList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }

                        </TextField>
                    </div>
                    <div className='col-md-6'>

                        <TextField
                            variant="outlined"
                            name="brand"
                            label="Brand"
                            value={formData.brand}
                            size="small"
                            className="form-control"
                            onChange={e => onInputchange(e)}
                        />

                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            variant="outlined"
                           name="model"
                            label="Model"
                            value={formData.model}
                            onChange={e => onInputchange(e)}
                            
                            size="small"
                            className="form-control"
                        />
                   </div>
                    <div className='col-md-6'>
                        <TextField variant="outlined"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={formData.warrantytype}
                            onChange={e => onInputchange(e)}
                            select
                            size="small"
                            // 
                            type="text" className="form-control" name="warrantytype" label="Warranty type"
                        >
                            {Warrantytype.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                    </div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            variant="outlined"
                            name="serialnumber"
                            label="Serial Number"
                            value={formData.serialnumber}
                            size="small"
                            // 
                            className="form-control"
                            onChange={e => onInputchange(e)}
                                />
                    </div>
                    <div className="col-md-6">
                        <TextField variant="outlined" type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // 

                            onChange={e => onInputchange(e)}
                          label="Purchase on Date" name="purchaseDate" className="form-control" id="preliminary-review-purchase_Date"
                            value={formData.purchaseDate} size="small"

                        />
                    </div>

                </div>
                <br />
                <div className='row'>
                    <div className="col-md-6">
                    <TextField
                            variant="outlined"
                            // 
                            name="pointofname"
                            label="Point Of Contact Name"
                            value={formData.pointofname}
                            onChange={e => onInputchange(e)}
                            size="small"
                            className="form-control"
                            />
                       
                    </div>
                    <div className="col-md-6">
                        <TextField variant="outlined"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            value={formData.purchasein}
                            size="small"
                            onChange={e => onInputchange(e)}
                            // 
                            type="text" className="form-control" name="purchasein" label="Purchase in"
                        >
                            {Purchaseintype.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }
                        </TextField>

                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-md-6'>
                    <TextField
                            variant="outlined"
                            // 
                            name="pointofcontact"
                            label="Point Of Contact"
                            value={formData.pointofcontact}
                            onChange={e => onInputchange(e)}
                            size="small"
                            className="form-control"
                            />

                    </div>
                    <div className='col-md-6'>
                    <TextField
                        variant="outlined"
                        name="cost"
                        label="Cost"
                        size="small"
                        // 
                        value={formData.cost}
                        onChange={e => onInputchange(e)}
                         className="form-control"
                         />


                    </div>
                    </div>
                    
                <br/>
                <div className='row'>
                <div className='col-md-6'>
                <TextField variant="outlined"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            
                            value={formData.statustype}
                            onChange={e => onInputchange(e)}
                            size="small"
                            type="text" className="form-control" name="statustype" label="Status Type"
                        >
                            {Statustype.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                    </div>

                    <div className='col-md-6'>
                    {formData.warrantytype === "No Warranty" ?"" :
                        <TextField variant="outlined" type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            
                            label="Expiry Date" name="expiry_date" className="form-control" id="preliminary-review-expiry_date"
                            value={expirydateactivity.expiry_date} size="small"  onChange={e =>  dateinputchange(e)}

                        />
                        
                    }

                    </div>
                </div>
                <br/>
                <div className='row'>
                <div className='col-md-6'>
                    <div className='assetsbuttons'>
                    {(formData.type ==="Pendrive" || formData.type==="Dongle" || formData.type ==="Sim Card" || formData.type ==="Mouse" ) ?
                       <button id='submit' style = {{'margin-left':'700px'}}  className="btn btn-danger">Submit</button>:<button  className="nextbutton">Next</button>}
                    </div>
                </div> 
                  </div>
                </form>
            </>
    );
}

export default HardwareAssetsDetails;