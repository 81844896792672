import React,{useEffect, useState} from 'react';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { useForm } from "react-hook-form";
import statesList from '../../components/json/statesList';
import TextField from '@material-ui/core/TextField';
import RawDatabaseFormat from "../../pages/RawDatabase/RawDatabaseFormat.xlsx" ;
import { connect, useDispatch } from 'react-redux'
import {createrawdata,RawdataActions,createSingleRawData} from "../../store/Rawdatabase"
import {getIndustriesList,getDistrictsList} from "../../store/leads";
const RawDataBaseModal = React.memo (({rawdatacreate,user,openshowModal,closemodal,createSingleRawData,getIndustriesData,industriesList,getDistrictsData,districtsData}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [rawdatabasemodalshow, setrawdatabasemodalshow] = useState(false);
    const [updated, setUpdated] = useState(false);
    const { register: RawDatabaseRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: RawdatabaseErrors } } = useForm();
   ;
    const [Rawdatafilename, SetRawdatafilename] = useState();
    const [toggleState, settoggleTab] = useState(1);
    const [Rawdata, setRawdata] = useState();
    const [disable, setDisable] = React.useState(false);
    // const[rawdataacctivity,Setrawdataactivity] = useState({
    //     remarks:""
    // })
    const[singleRawData,setSingleRawData] =useState({
      company_name:"",
      contact_person_name:"",
      mobile:"",
      phone:"",
      email:"",
      department:"",
      designation:"",
      industry:"",
      website:"",
      address:"",
      city:"",
      state:"",
      postal_code:"",
      country:"",
      no_of_employees:"",
      job_level:"",
      meddic_role:"",
      source:"",
      remarks:"",
  
    })
    console.log("after passing state is", singleRawData.state.value)
    const toggleTab = (index) => {
      settoggleTab(index);
    };
    // const onInputchange = (e) => {
    //     Setrawdataactivity({...rawdataacctivity , [e.target.name]: e.target.value },[])

    // }
    const changeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
          let RawDatafile = event.target.files[0];
          let RawDatafilename = event.target.files[0].name;
          setRawdata(RawDatafile);
          SetRawdatafilename(RawDatafilename);
        }
      };
      
      const fetchdata = async () => {
        
        setDisable(true);

        const rawdata = new FormData();
        rawdata.append("file", Rawdata);
        rawdata.append("remark",singleRawData.remarks)
        
     
        rawdatacreate(rawdata)
    }
const createSingleRawDataHandle = () => {
const data = {
  company_name:singleRawData.company_name,
  contact_person_name:singleRawData.contact_person_name,
  mobile:singleRawData.mobile,
  phone:singleRawData.phone,
  email:singleRawData.email,
  department:singleRawData.department,
  designation:singleRawData.designation,
  industry:singleRawData.industry,
  website:singleRawData.website,
  address:singleRawData.address,
  city:singleRawData.city,
  state:singleRawData.state,
  postal_code:singleRawData.postal_code,
  country:singleRawData.country,
  no_of_employees:singleRawData.no_of_employees,
  job_level:singleRawData.job_level,
  meddic_role:singleRawData.meddic_role,
  source:singleRawData.source,
  remark:singleRawData.remarks
}
if(singleRawData.state.hasOwnProperty("label")){
  data.state = singleRawData.state?.value
}

if(singleRawData.city.hasOwnProperty("label")){
  data.city = singleRawData.city?.value
}
// console.log("single field data are", data)
createSingleRawData(data)
}
const onInputChange = (e) => {
  setSingleRawData({...singleRawData,[e.target.name]:e.target.value}, [])
}
  useEffect(() => {
    getIndustriesData();
  },[])

  const handleStateChange = (value) => {
    singleRawData.state = value
    console.log("valu inside state is",value)
    getDistrictsData(value.value)
    
}
const handleDistrictChange = (value) => {
    singleRawData.city = value
    setUpdated(!updated)
}

  return (
    <>
    <Modal
    show={() => openshowModal()}
    onHide={() => closemodal()}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
    <Modal.Header>
      <Modal.Title>
     <small> Raw Database  </small>
      </Modal.Title>
      <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
    </Modal.Header>
    <Modal.Body>
    <div className="container">
              <div className="lock_tabs" style={{ whiteSpace: "nowrap", maxWidth: '1000px', overflowX: 'auto', marginTop: "1rem" }}>

                <a className={toggleState === 1 ? "tab active_tab" : "tab"} onClick={() => toggleTab(1)} >
                  Upload RawData File
                </a>

                <a className={toggleState === 2 ? "tab active_tab" : "tab"} onClick={() => toggleTab(2)} >
                  Create Single RawData
                </a>
              </div>

     <div className="tab_content">
         <div className={toggleState === 1 ? "content active_content" : "content"} >
             <div className='form'>
              {/* <form onSubmit = {contactHandleSubmit(fetchdata)}> */}
              <form>
                <div className='row'>
                    <div className='col-md-7'> 
                          <input type="file" className='rowdatafiles' id="file" accept= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet (.XLSX)"  onChange={changeHandler} required/>  
                          <small><p>(Note: Download the database file format from this <a href={RawDatabaseFormat} download="RawDatabaseFormat">link</a>.)</p></small> 
                        {Rawdatafilename && (
                          <p>
                            File Name :
                            <span className="excelfilename">
                              {Rawdatafilename}
                            </span>
                          </p>
                        )}
                        </div>
                    </div>
                    <br/>

                    <div className="row">
                      <div className='col-md-6'>

                     
                <TextField variant="outlined"
                  {...RawDatabaseRegister("remarks", {
                    minLength: {
                      value: 3,
                      message: "remarks min length must be at least 3 characters"
                    },
                    maxlength: {
                      value: 13,
                      message: "remarks max length must be at least 13 characters"
                    }
                  })}
                 
                  helperText={RawdatabaseErrors.remarks ? RawdatabaseErrors.remarks.message : ''}
                  error={Boolean(RawdatabaseErrors.remarks)}
                  label="Remark" 
                   name="remarks"
                   required
                   className="form-control" 
                   value={singleRawData.remarks}
                    onChange={e => onInputChange(e)} 
                   />
                    </div>
              </div>
                <br/>
               <div className='row'>
                <div className='payslipbutton'  >
                 <button type="submit" onClick={fetchdata} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                 </div>
                 </div>
              </form>
        </div>
          </div>
          <div className={toggleState === 2 ? "content active_content" : "content"} >
          <div className='form'>
              <form onSubmit = {contactHandleSubmit(createSingleRawDataHandle)}>
                <div className="row mt-4">
                 <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("company_name", {
                            required: { 
                                value: true, 
                                message: "Company Name is required" 
                            },
                            
                        })}
                        name="company_name"
                        className="form-control" 
                        label="Company Name"
                        onChange={e => onInputChange(e)}
                        required
                        helperText={RawdatabaseErrors.company_name ? RawdatabaseErrors.company_name.message : ''}
                        error={Boolean(RawdatabaseErrors.company_name)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div className="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("email", {
                            required: { 
                                value: true, 
                                message: "email Name is required" 
                            },
                            
                        })}
                        name="email"
                        className="form-control" 
                        label="Email"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.email ? RawdatabaseErrors.email.message : ''}
                        error={Boolean(RawdatabaseErrors.email)} 
                     />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("contact_person_name", {
                            required: { 
                                value: true, 
                                message: "Contact Name is required" 
                            },
                            
                        })}
                        name="contact_person_name"
                        className="form-control" 
                        label="Contact person"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.contact_person_name ? RawdatabaseErrors.contact_person_name.message : ''}
                        error={Boolean(RawdatabaseErrors.contact_person_name)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("mobile", {
                            required: { 
                                value: true, 
                                message: "Mobile number is required" 
                            },
                            
                        })}
                        name="mobile"
                        className="form-control" 
                        label="Mobile"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.mobile ? RawdatabaseErrors.mobile.message : ''}
                        error={Boolean(RawdatabaseErrors.mobile)} 
                    />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("phone", {
                            required: { 
                                value: true, 
                                message: "phone number is required" 
                            },
                            
                        })}
                        name="phone"
                        className="form-control" 
                        label="Phone"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.phone ? RawdatabaseErrors.phone.message : ''}
                        error={Boolean(RawdatabaseErrors.phone)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("department", {
                            required: { 
                                value: true, 
                                message: "department is required" 
                            },
                            
                        })}
                        name="department"
                        className="form-control" 
                        label="Department"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.department ? RawdatabaseErrors.department.message : ''}
                        error={Boolean(RawdatabaseErrors.department)} 
                    />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("designation", {
                            required: { 
                                value: true, 
                                message: "designation is required" 
                            },
                            
                        })}
                        name="designation"
                        className="form-control" 
                        label="Designation"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.designation ? RawdatabaseErrors.designation.message : ''}
                        error={Boolean(RawdatabaseErrors.designation)} 
                    />
                  </div>
                  </div>

                  <div className="row mt-4">
                  <div class="col-md-6">
                  <TextField variant="outlined"
                                    {...RawDatabaseRegister("industry", {
                                       
                                       
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    value={singleRawData.industry}
                                    select
                                    required
                                    helperText={RawdatabaseErrors.industry ? RawdatabaseErrors.industry.message : ''}
                                    error={Boolean(RawdatabaseErrors.industry)}
                                    onChange={e => onInputChange(e)}
                                   
                                    type="text" className="form-control" name="industry" label="Industry"
                                >
                                    {industriesList?.map((option) => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("website", {
                            required: { 
                                value: true, 
                                message: "website is required" 
                            },
                            
                        })}
                        name="website"
                        required
                        className="form-control" 
                        label="Website"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.website ? RawdatabaseErrors.website.message : ''}
                        error={Boolean(RawdatabaseErrors.website)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("address", {
                            required: { 
                                value: true, 
                                message: "address is required" 
                            },
                            
                        })}
                        name="address"
                        className="form-control" 
                        label="Address"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.address ? RawdatabaseErrors.address.message : ''}
                        error={Boolean(RawdatabaseErrors.address)} 
                    />
                  </div>
                  <div class="col-md-6">
                        <Select
                            closeMenuOnSelect={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable="true"
                            options={statesList}
                            placeholder="Search State"
                            required
                            name="state"
                            value = {singleRawData?.state}
                            // defaultValue={statesList[0]}
                            // value={statesList[0]}
                            
                            // onChange={handleSetPhoneCodes}
                            onChange={handleStateChange}
                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // required
                        />
                  
              
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                      <Select
                          closeMenuOnSelect={true}
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable="true"
                          options={districtsData}
                          placeholder="Search City"
                          value={singleRawData?.city}
                          required
                          name="city"
                          // defaultValue={}
                          // value={statesList[0]}
                          
                          // onChange={handleSetPhoneCodes}
                          onChange={handleDistrictChange}
                          
                          InputLabelProps={{
                              shrink: true,
                          }}
                          // required
                      />
                   
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("postal_code", {
                            required: { 
                                value: true, 
                                message: "postal code is required" 
                            },
                            minLength: {
                              value: 6,
                              message: "Postal Code must be at least 6 characters"
                          },
                          maxLength: {
                              value: 6,
                              message: "Postal Code cannot exceed 6 digits"
                          }
                        })}
                        name="postal_code"
                        className="form-control" 
                        required
                        label="Postal Code"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.postal_code ? RawdatabaseErrors.postal_code.message : ''}
                        error={Boolean(RawdatabaseErrors.postal_code)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("country", {
                            required: { 
                                value: true, 
                                message: "country is required" 
                            },
                            
                        })}
                        name="country"
                        className="form-control" 
                        required
                        label="country"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.country ? RawdatabaseErrors.country.message : ''}
                        error={Boolean(RawdatabaseErrors.country)} 
                    />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("no_of_employees", {
                            required: { 
                                value: true, 
                                message: "no of employees is required" 
                            },
                            
                        })}
                        name="no_of_employees"
                        className="form-control" 
                        label="No Of Employees"
                        required
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.no_of_employees ? RawdatabaseErrors.no_of_employees.message : ''}
                        error={Boolean(RawdatabaseErrors.no_of_employees)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("job_level", {
                            required: { 
                                value: true, 
                                message: "job level is required" 
                            },
                            
                        })}
                        name="job_level"
                        className="form-control"
                        required 
                        label="Job Level"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.job_level ? RawdatabaseErrors.job_level.message : ''}
                        error={Boolean(RawdatabaseErrors.job_level)} 
                    />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("meddic_role", {
                            required: { 
                                value: true, 
                                message: "meddic role is required" 
                            },
                            
                        })}
                        name="meddic_role"
                        className="form-control" 
                        required
                        label="MEDDIC role"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.meddic_role ? RawdatabaseErrors.meddic_role.message : ''}
                        error={Boolean(RawdatabaseErrors.meddic_role)} 
                    />
                  </div>
                  </div>
                  <div className="row mt-4">
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("source", {
                            required: { 
                                value: true, 
                                message: "source is required" 
                            },
                            
                        })}
                        name="source"
                        className="form-control" 
                        required
                        label="Source"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.source ? RawdatabaseErrors.source.message : ''}
                        error={Boolean(RawdatabaseErrors.source)} 
                    />
                  </div>
                  <div class="col-md-6">
                    <TextField variant="outlined"
                        {...RawDatabaseRegister("remarks", {
                            required: { 
                                value: true, 
                                message: "remarks is required" 
                            },
                            
                        })}
                        name="remarks"
                        className="form-control" 
                        required
                        label="Remark"
                        onChange={e => onInputChange(e)}
                        helperText={RawdatabaseErrors.remarks ? RawdatabaseErrors.remarks.message : ''}
                        error={Boolean(RawdatabaseErrors.remarks)} 
                    />
                  </div>
                  </div>
                  <div className="row mx-auto mt-4">
                    <div className="col-12 mx-auto">
                      <button type='submit' className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>
                    </div>
                   
                  </div>
                </form>
          </div>
          </div>
      </div>
     </div>
       
    </Modal.Body>
   </Modal>

</>
  );

});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    industriesList : state.leads.industriesList,
    districtsData: state.leads.districtsData,
});

const mapDispatchToProps = (dispatch) => ({

  rawdatacreate :(data) => dispatch(createrawdata(data)),
  openshowModal:(data) => dispatch(RawdataActions.openModal(data)),
  closemodal:(data) => dispatch(RawdataActions.closeModal(data)),
  createSingleRawData:(data) => dispatch(createSingleRawData(data)),
  getIndustriesData : () => dispatch(getIndustriesList()),
  getDistrictsData : (data) => dispatch(getDistrictsList(data)),

});

export default connect(mapStateToProps,mapDispatchToProps) (RawDataBaseModal)
