import React, { useState, useEffect, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import axios from "axios";
import './chart.css';
import Select from 'react-select';
import { components } from "react-select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Badge from 'react-bootstrap/Badge'
import { AiOutlineBarChart } from 'react-icons/ai';
import { AiOutlineDatabase } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import OpportunitiesTable from "../../components/commonColumns/OpportunitiesTable";
import {useDispatch, useSelector, connect}  from "react-redux";
import {getManagers, getYears, getSalesConfidenceStages, getBusinessTypes, getSalesStages } from "../../store/dashboard";
import { opportunitiesActions } from "../../store/opportunities";
import { toast } from 'react-toastify';
import moment from "moment";
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const RegionChart = React.memo(({ months,accountManagersList,yearsList, quartersList, salesConfidenceList, salesStagesList, businessTypesList, fetchAccountManagersData, getYearsData, getSalesConfidenceStagesData, getBusinessTypesData, getSalesStagesData,setOpportunitiesDataTabel }) => {
  // var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  console.log("salesconfidencelist",salesConfidenceList)
  const [data, setData] = useState([]);
  // const [months, setMonths] = useState([]);
  // const [years, setYears] = useState([]);
  // const [salesConfidenceStages, setSalesConfidenceStages] = useState([]);
  // const [salesStages, setSalesStages] = useState([]);
  // const [accountManagers, setAccountManagers] = useState([]);
  // const [quarters, setQuarters] = useState([]);
  // const [businessTypes, setBusinessTypes] = useState([]);
  const [filterMonth, setFilterMonth] = useState([]);
  const [filterYear, setFilterYear] = useState([]);
  const [filterAccountManager, setFilterAccountManager] = useState([]);
  const [filterSalesStage, setFilterSalesStage] = useState([]);
  const [filterSalesConfidenceStages, setFilterSalesConfidenceStages] = useState([{label: 'Won', value: 'Won'}]);
  const [filterQuarter, setFilterQuarter] = useState([]);
  const [filterBusinessType, setFilterBusinessType] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const handle = useFullScreenHandle();
  const [tableHeight, setTableHeight] = useState("310px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);

  // const dispatch = useDispatch();
  // const {  } = useSelector(state => state.dashboard);

  console.log("regionwise revenue data")

  
  var deYear = moment().format('YY');
  var deYearName = moment().format('YYYY')
  var defaultYear = {value: parseInt(deYear), label:deYearName}

  const currentYear = moment().year();
  const getForecastChartData = async () => {

    var year = filterYear?.map(item => {
      return item.value.toString();
    });

    var account_manager = filterAccountManager?.map(item => {

      return item.value;
    });

    var sales_stages = filterSalesStage?.map(item => {
      return item.value;
    });

    var sales_confidence_stages = filterSalesConfidenceStages?.map(item => {
      return item.value;
    });

    var months = filterMonth?.map(item => {
      return item.value;
    });

    var quarters = filterQuarter?.map(item => {
      return item.value;
    });

    var business_types = filterBusinessType?.map(item => {
      return item.value;
    });

    var fil ={
      months: months,
      quarters: quarters,
      years: year? year:currentYear,
      account_manager: account_manager,
      sales_stages: sales_stages,
      sales_confidence_stages: sales_confidence_stages,
      business_type: business_types
    }
    
    // dispatch(getRegionWiseRevenueChart(fil))
    const res = await axios.post('/user/reports/region-chart', fil);
    // await Api.post("", data).then((res) => {

    var colorsArray = ["#72C","#880","#080","#448"];

    (res.data.data).map((dataset, index) => {
      var yValues = dataset.y.map(item => {
        return item / 10000000
      });

      dataset.marker = {
        color: colorsArray[index],
        width: 2.5,
        opacity: 0.7,
      };

      dataset.y = yValues;
     

      dataset.width = dataset.x.map(data => {
        return 0.5
      });

    });

    setData(res.data.data);
    setTableData(res.data.tableData);
    // setOpportunitiesDataTabel(res.data.tableData)
    if (res.data.tableData < 1) {
      setDefaultTableText("No Records Found");
    }
  }

  const reportChange = ((state, handle) => {
    var regionsChart = document.getElementById("regionChartComponent");
    if (regionsChart) {
      regionsChart = document.getElementById("regionChartComponent").getElementsByClassName("user-select-none")[0]
      regionsChart.id = "regionsChart";
    }
    if (state) {
      if (regionsChart) {
        document.getElementById("regionsChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }

      setTableHeight((window.innerHeight - 160).toString() + 'px');
      // setRowsPerPage(15);
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (regionsChart) {
        document.getElementById("regionsChart").style.height = "450px";
      }

      setTableHeight('333px');
      // setRowsPerPage(10);
      setColumnsDisplay(true);
      // setFilterDisplay(true);
    }


  });

  const removeFilterMonthBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterMonth.filter(item => item.value !== inputValue);
    setFilterMonth(filterMonths);
  }

  const removeFilterYearBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log('inputValue', inputValue);

    const filterMonths = filterYear.filter(item => item.value.toString() !== inputValue.toString());
    console.log("filterMonths", filterMonths);
    setFilterYear(filterMonths);
  }

  const removeFilterQuarterBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterQuarter.filter(item => item.value !== inputValue);
    setFilterQuarter(filterMonths);
  }

  const removeFilterAccountManagerBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterAccountManager.filter(item => item.value !== inputValue);
    setFilterAccountManager(filterMonths);
  }

  const removeFilterSalesStagesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterSalesStage.filter(item => item.value !== inputValue);
    setFilterSalesStage(filterMonths);
  }

  const removeFilterBusinessTypesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterBusinessType.filter(item => item.value !== inputValue);
    setFilterBusinessType(filterMonths);
  }

  const removeFilterSalesConfidenceBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterSalesConfidenceStages.filter(item => item.value !== inputValue);
    setFilterSalesConfidenceStages(filterMonths);
  }


  const chartButtonClicked = async () => {
    console.log("region wise data filters chart button change")
    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }


  const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'RegionWisechart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:",error)
});

}

  useMemo(() => {
    console.log("region wise data filters change")
    getForecastChartData();
  }, [filterMonth, filterYear, filterAccountManager, filterSalesConfidenceStages, filterSalesStage, filterQuarter, filterBusinessType]);
  
  useMemo(() => {
    getYearsData()
    getSalesConfidenceStagesData()
    getBusinessTypesData()
    getSalesStagesData()
    fetchAccountManagersData()
  }, []);



  const layout = {
    paper_bgcolor: 'transparent ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    yaxis: {
    },
    margin: {

    },
    showlegend: true,
    legend: { "orientation": "h" },
    title: "Region Wise Revenue",
  }
 
  
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};

  const handleYearChange = (value) => {
    console.log("region wise data filters year change", value)
    // When an event triggers, set the filterYear state with the new value.
    setFilterYear(value);
  };

  const handleMonthChange = (value) => {
    console.log("region wise data filters month change")
    setFilterMonth(value);
  }

  const handleQuarterChange = (value) => {
    console.log("region wise data filters quarter change")
    setFilterQuarter(value);
  }

  const handleAccountManagerChange = (value) => {
    console.log("region wise data filters account manager change")
    setFilterAccountManager(value);
  }

  const handleSalesConfidenceChange = (value) => {
    console.log("region wise data filters confidence change")
    setFilterSalesConfidenceStages(value);
  }

  const handleBusinessTypeChange = (value) => {
    console.log("region wise data filters business type change")
    setFilterBusinessType(value);
  }

  const handleSalesStagesChange = (value) => {
    console.log("region wise data filters sales stages change")
    setFilterSalesStage(value)
  }

  return (
    <>
    <div ref={containerRef}>
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >

      

        <div className="container-fluid py-2 px-2">
          <div className="row">

            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter">
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => setShowChart(false)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '6%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                  <div >
                    <Select
                      style={{
                        position: 'relative',
                        width: '200px',
                        height: '50px',
                        overflowX: 'hidden',
                        overflowY: 'hidden'
                      }}
                      isMulti
                      value={filterYear}
                      options={yearsList}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      classNamePrefix="select"
                      isSearchable="true"
                      allowSelectAll={true}
                      placeholder="Years"
                      name="filterYear"
                      components={{
                        Option
                      }}
                      onChange={handleYearChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2 col-lg-1" style={{ width: '11%' }}>
                  <div >
                    <Select
                      style={{
                        position: 'relative',
                        width: '200px',
                        height: '50px',
                        overflowX: 'hidden',
                        overflowY: 'hidden'
                      }}
                      isMulti
                      value={filterMonth}
                      options={months}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={false}
                      classNamePrefix="select"
                      isSearchable="true"
                      allowSelectAll={true}

                      placeholder="Month"
                      name="filterMonth"
                      components={{
                        Option
                      }}
                      onChange={handleMonthChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-1 col-lg-2" style={{ width: '11%' }}>
                  <Select
                    classNamePrefix="select"
                    options={quartersList}
                    isMulti
                    value={filterQuarter} 
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    allowSelectAll={false}
                    components={{
                      Option
                    }}
                    placeholder="Quarter"
                    name="filterQuarter"
                    defaultValue="+91"
                    onChange={handleQuarterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

                <div className="col-md-2 col-lg-1" style={{ width: '11%' }}>
                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    value={filterAccountManager}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    options={accountManagersList}
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    placeholder="Users"
                    name="filterMonth"
                    defaultValue="+91"
                    onChange={handleAccountManagerChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

                <div className="col-md-2 col-lg-1" style={{ width: '13%' }}>

              

                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    value={filterSalesConfidenceStages}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    options={salesConfidenceList}
                    placeholder="Confidence"
                    name="filterSalesConfidenceStage"
                   
                    onChange={handleSalesConfidenceChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

                <div className="col-md-2 col-lg-1" style={{ width: '14%' }}>
                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    value={filterBusinessType}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    options={businessTypesList}
                    placeholder="Buss. Types"
                    name="filterSalesStage"
                    defaultValue="+91"
                    onChange={handleBusinessTypeChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                  <Select
                    className="select_value"
                    classNamePrefix="select"
                    isMulti
                    value={filterSalesStage}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    controlShouldRenderValue={false}
                    isSearchable="true"
                    allowSelectAll={true}
                    components={{
                      Option
                    }}
                    options={salesStagesList}
                    placeholder="Stages"
                    name="filterSalesStage"
                    defaultValue="+91"
                    onChange={handleSalesStagesChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {
              filterMonth?.length > 0 ?
                filterMonth.map((filter) => (
                  <Badge onClick={removeFilterMonthBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              
              filterYear?.length > 0 ?
                filterYear.map((filter) => (
                  <Badge onClick={removeFilterYearBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                :
                
                <Badge 
                pill data={currentYear} value={currentYear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentYear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }

            {
              filterQuarter?.length > 0 ?
                filterQuarter.map((filter) => (
                  <Badge onClick={removeFilterQuarterBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterAccountManager?.length > 0 ?
                filterAccountManager.map((filter) => (
                  <Badge onClick={removeFilterAccountManagerBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterSalesStage?.length > 0 ?
                filterSalesStage.map((filter) => (
                  <Badge onClick={removeFilterSalesStagesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterBusinessType?.length > 0 ?
                filterBusinessType.map((filter) => (
                  <Badge onClick={removeFilterBusinessTypesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterSalesConfidenceStages?.length > 0 ?
                filterSalesConfidenceStages.map((filter) => (
                  <Badge 
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;</span></Badge>
                ))
                : 
               ""
            }








          </div>
        </div>

        {showChart ?
          <PlotlyComponent
            id="regionChartComponent"
            data={data}
            layout={layout}
            config={config}
          />
          : <React.Fragment>
            {/* regionChartTableData={tableData} */}
            {<OpportunitiesTable regionChartTableData={tableData}  keyData={"regionChart"} tableHeight={tableHeight} /> }
          </React.Fragment>}

      </FullScreen>
      </div>
    </>
  )
})


const mapStateToProps = (state) => ({
  accountManagersList: state.dashboard.accountManagersList,
  months: state.dashboard.months,
  regionWiseRevenueData : state.dashboard.regionWiseRevenueData,
  regionWiseRevenueTableData : state.dashboard.regionWiseRevenueTableData,
  yearsList : state.dashboard.yearsList,
  quartersList: state.dashboard.quartersList,
  salesConfidenceList : state.dashboard.salesConfidenceList,
  businessTypesList : state.dashboard.businessTypesList,
  salesStagesList : state.dashboard.salesStagesList
});

const mapDispatchToProps = (dispatch) => ({
  setOpportunitiesDataTabel : (data) => dispatch(opportunitiesActions.opportunitiesListSuccess(data)),
  fetchAccountManagersData: () => dispatch(getManagers()),
  getYearsData : () => dispatch(getYears),
  getSalesConfidenceStagesData : () => dispatch(getSalesConfidenceStages),
  getBusinessTypesData : () => dispatch(getBusinessTypes),
  getSalesStagesData : () => dispatch(getSalesStages)
});

// export default RegionChart;
export default connect(mapStateToProps, mapDispatchToProps)(RegionChart);













































































