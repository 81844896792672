import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect, useSelector } from 'react-redux';
import {RawdataLeadscreatedata,RawdataActions} from "../../store/Rawdatabase"
import Select from 'react-select';

  const RawdetailsModal = React.memo (({leadscreate,openshowModal,closeshowmodal,Rowid}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
    const [contactShow, setContactShow] = useState(false);
    const { register: AddnewleadRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors:AddnewleadErrors}, reset: reset } = useForm();
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [updated, setUpdated] = useState(false);
  const [industriesList, setIndustriesList] = useState([]);
  const [annualRevenueRangeList, setAnnualRevenueRangeList] = useState([]);
  const [productsList, setProductsList] = useState([]);

    const [addnewlead, setAddnewlead] = useState({
        company_name: "",
        contact_name: "",
        lead_type:"",
        contact_number: "",
        mobile:"",
        email: "",
        designation: "",
        department: "",
        industry: "",
        annual_revenue:"",
        postal_code: "",
        product:"",
        street: "",
        company_website:"",
        postal_code:"",
        state:"",
        city:"",
        country:""
      });
 const { company_name, contact_name, contact_number, email, designation, department, industry,postal_code,mobile } = addnewlead;
   console.log("new lead details are", addnewlead)

 const getAnnualRevenueRangeList = async () => {

  const res = await axios.get("/annual-revenue-range/search");
  setAnnualRevenueRangeList(res.data.annualRevenueRange);
}

const getProductsList = async () => {

  const res = await axios.get("/products/search");
  setProductsList(res.data.productsList);
}
const getIndustriesList = async () => {
        
  const res = await axios.get("/industries/search");
  setIndustriesList(res.data.industriesList);
}


useEffect(() => {
  getIndustriesList();
  getAnnualRevenueRangeList();
  getProductsList();

}, []);

 const getDetailsHandle = async (id) => {
        const res = await axios.get("/user/rawdata/details/" + id);
        const AddnewLeadData = res.data.rawdataDetail;    
        setAddnewlead({
          company_name: AddnewLeadData.company_name,
          contact_name: AddnewLeadData.contact_person_name,
          contact_number: AddnewLeadData.phone,
          mobile:AddnewLeadData.mobile,
          email: AddnewLeadData.email,
          designation: AddnewLeadData.designation,
          department: AddnewLeadData.department,
          // industry: AddnewLeadData.industry,
          industry: "",
          postal_code: AddnewLeadData.postal_code,
          street:AddnewLeadData.address,
          company_website:AddnewLeadData.website,
          postal_code:AddnewLeadData.postal_code,
          state:AddnewLeadData.state,
          city:AddnewLeadData.city,
          country:AddnewLeadData.country,
          source: AddnewLeadData.source,

        });
    
      };
      const onInputChange = (e) => {
        setAddnewlead({ ...addnewlead, [e.target.name]: e.target.value }, []);
      };

      useEffect(() => {

        getDetailsHandle(Rowid);
      }, [Rowid])
      
  
      const addActivityHandle = async (e) => {

        setDisable(true);
        const activityData = {
            company_name: addnewlead.company_name,
            contact_name: addnewlead.contact_name,
            industry: addnewlead.industry,
            phone_code: "+91",
            contact_number: addnewlead.contact_number?.toString(),
            designation: addnewlead.designation,
            email: addnewlead.email,
            department: addnewlead.department,
            company_website: addnewlead.company_website,
            street: addnewlead.street,
            postal_code: addnewlead.postal_code?.toString(),
            state: addnewlead.state,
            city: addnewlead.city,
            country: addnewlead.country,
            user_id: userData._id,
            annual_revenue: addnewlead.annual_revenue,
            product: addnewlead.product ? addnewlead.product?.value : "",  
            lead_type : addnewlead.lead_type,
            lead_source : addnewlead.source,
        }
        console.log("activityData", activityData)
        leadscreate(activityData)

        reset({
          keepErrors: false,
          keepDirty: true,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
      })

       
    }



  return (
    <div>
       <Modal
       show={() => openshowModal()}
       onHide={() => closeshowmodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New Lead
          </Modal.Title>
          <a onClick={() => closeshowmodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={contactHandleSubmit(addActivityHandle)}>
            <div className="row headerBand">
              <label>Organization Details</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("company_name", {
                  })}
                  style={{
                    borderLeftWidth: 3,
                    borderLeftColor: 'red',
                    borderLeftStyle: 'solid'
                }}

                  required
                  helperText={AddnewleadErrors.company_name ? AddnewleadErrors.company_name.message : ''}
                  error={Boolean(AddnewleadErrors.company_name)}
                  type="text" className="form-control" value={company_name} name="company_name" label="Organization Name" onChange={e => onInputChange(e)} />
              </div>
           
            </div>
            <br />
            <div className='row'>
            <div className='col-md-6'>
                                <TextField variant="outlined"
                                    {...AddnewleadRegister("lead_type", {
                                        minLength: {
                                            value: 3,
                                            message: "Lead Type must be at least 3 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select
                                    required
                                    value={addnewlead.lead_type}
                                    helperText={AddnewleadErrors.lead_type ? AddnewleadErrors.lead_type.message : ''}
                                    error={Boolean(AddnewleadErrors.lead_type)}

                                    type="text" className="form-control" name="lead_type" label="Lead Type" onChange={e => onInputChange(e)} >
                                    {
                                        ["Prospect", "Suspect"].map(item => (
                                            <MenuItem key={item} selected={"Prospect" === item} value={item}>{item}</MenuItem>
                                        ))
                                    }

                                </TextField>
                            </div>
            <div className="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("contact_name", {
                    minLength: {
                      value: 3,
                      message: "Contact Name must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.contact_name ? AddnewleadErrors.contact_name.message : ''}
                  error={Boolean(AddnewleadErrors.contact_name)}
                  type="text" className="form-control" value={contact_name} name="contact_name" label="Contact Name" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("email", {
                    minLength: {
                      value: 3,
                      message: "Email must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.email ? AddnewleadErrors.email.message : ''}
                  error={Boolean(AddnewleadErrors.email)}
                  label="Email" name="email" className="form-control" value={email} onChange={e => onInputChange(e)} />
              </div>

              <div class="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("contact_number", {
                    minLength: {
                      value: 6,
                      message: "Contact Number must be at least 6 characters"
                    },
                    maxLength: {
                      value: 13,
                      message: "Contact Number cannot exceed 13 digits"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.contact_number ? AddnewleadErrors.contact_number.message : ''}
                  error={Boolean(AddnewleadErrors.contact_number)}
                  type="number" className="form-control" value={contact_number} name="contact_number" label="Contact Number" onChange={e => onInputChange(e)} />
              </div>


            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("designation", {
                    minLength: {
                      value: 3,
                      message: "Designation must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.designation ? AddnewleadErrors.designation.message : ''}
                  error={Boolean(AddnewleadErrors.designation)}
                  type="text" label="Designation" name="designation" className="form-control" value={designation} onChange={e => onInputChange(e)} />
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("department", {
                    minLength: {
                      value: 3,
                      message: "Department must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.department ? AddnewleadErrors.department.message : ''}
                  error={Boolean(AddnewleadErrors.department)}
                  type="text" label="Department" name="department" className="form-control" value={department} onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
   
            <div className='row'>
              <div className="col-md-3">
              <TextField variant="outlined"
                  {...AddnewleadRegister("postal_code", {
                  })}
                  required
                  helperText={AddnewleadErrors.postal_code ? AddnewleadErrors.postal_code.message : ''}
                  error={Boolean(AddnewleadErrors.postal_code)}
                  type="text" label="phoneCode" name="co_postal_code" className="form-control" value={"+91"} onChange={e => onInputChange(e)} />

              </div>
              <div className="col-md-3">
              <TextField variant="outlined"
                  {...AddnewleadRegister("mobile", {
                  })}
                  required
                  helperText={AddnewleadErrors.mobile ? AddnewleadErrors.mobile.message : ''}
                  error={Boolean(AddnewleadErrors.mobile)}
                  type="text" label="Mobile number" name="mobile" className="form-control" value={mobile} onChange={e => onInputChange(e)} />


              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...AddnewleadRegister("industry", {
                    minLength: {
                      value: 3,
                      message: "Industry must be at least 3 characters"
                    }
                  })}
                  style={{
                    borderLeftWidth: 3,
                    borderLeftColor: 'red',
                    borderLeftStyle: 'solid'
                }}
                  onChange={e => onInputChange(e)}
                  select
                  // required
                  value={industry}
                  error={Boolean(AddnewleadErrors.industry)}
                  helperText={AddnewleadErrors.industry ? AddnewleadErrors.industry.message : ''}
                  // required
                  type="text"
                  className="form-control"
                   label="Industry"
                   name="industry" 
                   required
                  >

                  {
                    industriesList.map((option,industry) => (
                      <MenuItem key={option.value} selected={industry === option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
                 {/* <TextField variant="outlined"
                  {...AddnewleadRegister("industry", {
                    minLength: {
                      value: 1,
                      message: "Industry must be at least 1 characters"
                    }
                  })}
                  required
                  helperText={AddnewleadErrors.industry ? AddnewleadErrors.industry.message : ''}
                  error={Boolean(AddnewleadErrors.industry)}
                  select
                  type="text" label="Industry" name="industry" className="form-control"
                  value={addnewlead.industry} onChange={e => onInputChange(e)} >

                  {
                    industriesList.map((option, industry) => (
                      <MenuItem key={option.label} selected={industry === option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField> */}
              </div>

            </div>
            <br/>
            <div className='row'>
            <div className="col-md-6">
                                <TextField multiline rows={2} autoComplete="none"
                                    {...AddnewleadRegister("street", {
                                       
                                        minLength: {
                                            value: 3,
                                            message: "Street must be at least 6 characters"
                                        }
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    onChange={e => onInputChange(e)}
                                    value={addnewlead.street}
                                    name="street" 
                                    className="form-control"  label="Street" variant="outlined" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...AddnewleadRegister("company_website", {
                                       
                                        minLength: {
                                            value: 6,
                                            message: "Organization Website must be at least 6 characters"
                                        }
                                    })}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={addnewlead.company_website}
                                    type="text" className="form-control" 
                                     name="company_website"
                                     onChange={e => onInputChange(e)}
                                      label="Ogranization Website" />
                                <br />

                                <div style={{ marginTop: "15px" }}>
                                    <TextField variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                        }}
                                       

                                        {...AddnewleadRegister("annual_revenue", {
                                            
                                            minLength: {
                                                value: 3,
                                                message: "Annual Revenue must be at least 3 characters"
                                            }
                                        })}
                                        select
                                        required
                                        value={addnewlead.annual_revenue}
                                        helperText={AddnewleadErrors.annual_revenue ? AddnewleadErrors.annual_revenue.message : ''}
                                        error={Boolean(AddnewleadErrors.annual_revenue)}

                                        type="number" className="form-control"
                                         name="annual_revenue"
                                          label="Annual Revenue" onChange={e => onInputChange(e)} >
                                      
                                        {
                                            annualRevenueRangeList.map((option, annual_revenue) => (
                                                <MenuItem key={option.value} selected={annual_revenue === option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }

                                    </TextField>
                                </div>
                            </div>
            </div>
            <br/>
            <div className='row'>
            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"

                                    {...AddnewleadRegister("postal_code", {
                                        
                                        minLength: {
                                            value: 6,
                                            message: "Postal Code must be at least 6 characters"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Postal Code cannot exceed 6 digits"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                   
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                     value={addnewlead.postal_code}
                                    type="number" label="Postal Code"
                                    name="postal_code" 
                                    onChange={e => onInputChange(e)}
                                    className="form-control" 
                                   />
                            </div>
                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...AddnewleadRegister("state", {
                                      
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                     value={addnewlead.state}
                                    type="text" className="form-control"
                                      name="state"
                                      onChange={e => onInputChange(e)}
                                      label="State" />
                            </div>
                            <div className="col-md-6">
                            <Select

                              placeholder="Select Product"
                              name="colors"
                              options={productsList}
                              className="basic-multi-select"
                              onChange={(value) => setAddnewlead({...addnewlead, product: value })}
                              classNamePrefix="select">

                            </Select>

                                {/* <TextField variant="outlined"
                                    {...AddnewleadRegister("product", {
                                        minLength: {
                                            value: 3,
                                            message: "Product must be at least 3 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    
                                    select
                                    required
                                    defaultValue={addnewlead.product}
                                    onChange={e => onInputChange(e)}
                                    helperText={AddnewleadErrors.product ? AddnewleadErrors.product.message : ''}
                                    error={Boolean(AddnewleadErrors.product)}
                                    type="number" className="form-control"
                                     name="product"
                                      label="Product"  >
                                   

                                    {
                                        productsList.map((option, product) => (
                                            <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }

                                </TextField> */}
                            </div>
                        </div>
                        <br/>
                            <div className='row'>
                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...AddnewleadRegister("city", {
                                       
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={addnewlead.city}
                                    type="text" className="form-control" 
                                      name="city"
                                      onChange={e => onInputChange(e)}
                                     label="City" />
                            </div>

                            <div className="col-md-3">
                                <TextField variant="outlined" autoComplete="none"
                                    {...AddnewleadRegister("country", {
                                    
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={addnewlead.country}
                                    type="text"
                                     name="country" 
                                     onChange={e => onInputChange(e)}
                                     label="Country" className="form-control" />
                            </div>
                            </div>
            <br />
            <div className="row">
              <div>
              <button type='submit'  className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit"  >Submit</button>&nbsp;&nbsp;
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );


});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    Rowid : state.Rawdatalist.rowId, 
});
const mapDispatchToProps = (dispatch) => ({
  leadscreate:(data) => dispatch(RawdataLeadscreatedata(data)),
  openshowModal:(data) => dispatch(RawdataActions.showRawDatadetailsModal_openmodal(data)),
  closeshowmodal:(data) => dispatch(RawdataActions.showRawDatadetailsModal_closemodal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (RawdetailsModal)


