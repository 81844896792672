import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../home/Navbar';
import "./payslip.css";
import axios from "axios"
import { AuthContext } from '../../context/AuthContext';
import { Button } from 'react-bootstrap';
import PayslipModal from '../../components/pageModals/PayslipModal';
import PayslipTable from '../../components/commonColumns/PayslipTable';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker, DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { useForm } from "react-hook-form";
import { connect, useSelector,useDispatch } from 'react-redux';
import {getpayslip,PayslipActions} from "../../store/Payslip"
import { useMemo } from 'react';
const Payslip = React.memo(({paysliplist,getpaysliplist}) => {
  // console.log("payslip",paysliplist)
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
const payslipmodal = useSelector((state) => state.Paysliplist.showModal)
    const permissions = user.permissions;
    
  const { register: targetRegister, handleSubmit: setTargetHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: targetErrors } } = useForm();
 
  const [fromselectedDate, setFromSelectedDate] = useState( moment(new Date()).format('YYYY-MM-01'));
  const [ToselectedDate, setToselectedDate] = useState( moment(new Date()).format('YYYY-MM-01'));
  const handlefromDateChange = (Month) => {
    var monthandyear = moment(Month).format('YYYY-MM-01')
    setFromSelectedDate(monthandyear);
  };
  const handletoDatechange = (Months) => {
    var todates = moment(Months).format('YYYY-MM-01')
    setToselectedDate(todates)
  }
 
  const fetchData = async () => {
    
    const updatedData = {
      from_date: fromselectedDate,
      to_date: ToselectedDate
    }

    getpaysliplist(updatedData)
  }
 
  useMemo(() => {
    fetchData();
   
  }, []);

  return (
    <>
      <Navbar value="payslip" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
        {permissions.includes("createPayslip") ? 
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(PayslipActions.openModal())}>Create payslip</Button>
            {payslipmodal ? <PayslipModal refreshPayslipData={() => fetchData()} show={payslipmodal} onHide={() => dispatch(PayslipActions.closeModal())} /> : ""}
          </>
          :false}
        </div>
        <br />
        <form onSubmit={setTargetHandleSubmit(fetchData)}>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-6'>
                <div className='selectingdates'>

               
                <div className='row'>
                  <div className='col-md-5'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     
                      <DatePicker
                        inputVariant="outlined"
                        views={["month", "year"]}
                        label="From Month&Year"
                        value={fromselectedDate}
                     
                        onChange={handlefromDateChange}
                      
                      />

                    </MuiPickersUtilsProvider>


                  </div>
                  <div className='col-md-4'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <DatePicker
                        inputVariant="outlined"
                        views={["month", "year"]}
                        label="To Month&Year"
                        value={ToselectedDate}
                        onChange={handletoDatechange}
                        className="form-control"
                      />

                    </MuiPickersUtilsProvider>



                  </div>
              
                  <div className='col-md-2'>
                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="hardware_table">
          <PayslipTable />
        </div>
      </div>
    </>
  )
})
const mapStateToProps = (state) => ({
  user : state.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
  getpaysliplist : (data) => dispatch(getpayslip(data))

});

export default connect(mapStateToProps,mapDispatchToProps) (Payslip)