import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useDispatch, useSelector } from 'react-redux';
// import {rejectsubmitlist,LeaverequestActions} from "../../store/Leaverequest"
// import {updaterawdataStatus} from "../../"
// import updaterawdataStatus
import {RawdataActions,updaterawdataStatus} from "../../store/Rawdatabase"

  const InactiveRawDataModal = React.memo (({rejectsubmitdata,Rowid,inactiveOpenModal,closemodal}) => {
    console.log("id is//", Rowid)
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { register: rejectRegister, handleSubmit: rejectHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const rejectSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
        'reject_reason': data.reject_reason,
        'status': "inactive",
        'rawdata_id': Rowid,
      }
      console.log("in valid rawData  base", updatedData)
      rejectsubmitdata(updatedData)

  }


 
  return (
    <>

      <Modal
      show={() => inactiveOpenModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {/* {"Reject Leave Request"} */}
            <small>InValid RawData</small>
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={rejectHandleSubmit(rejectSubmit)}>
            <div className="row headerBand">
              <label>Reason</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  required
                  {...rejectRegister("reject_reason", {
                    minLength: {
                      value: 3,
                      message: "Reject Reason must be at least 3 characters"
                    }

                  })}
                  multiline rows={4}
                  helperText={rejectErrors.reject_reason ? rejectErrors.reject_reason.message : ''}
                  error={Boolean(rejectErrors.reject_reason)}
                  type="text" className="form-control"
                  name="reject_reason"
                  label="Reasons" />
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  isFetching : state.LeaveRequestlists.isFetching, 
  Rowid : state.Rawdatalist.rowId, 

});

const mapDispatchToProps = (dispatch) => ({
  rejectsubmitdata: (data) => dispatch(updaterawdataStatus(data)),
  inactiveOpenModal:() =>dispatch(RawdataActions.inactiveModalOpen()),
  closemodal:(data) => dispatch(RawdataActions.inactiveModalClose(data)),


});
export default connect(mapStateToProps,mapDispatchToProps)(InactiveRawDataModal);


