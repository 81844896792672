import React, { useEffect, useState,useMemo } from 'react';
import Navbar from '../home/Navbar';
import IndustryMetricTable from '../../components/commonColumns/IndustryMetricTable';
import axios from "axios";
import { useSelector,useDispatch, connect } from 'react-redux';
import {getindustrymetricdata,CasestudyActions} from "../../store/Industrymetric"

  const IndustryMetricList = React.memo (({getindustrylists,Getindustrymetricdata}) => {

    const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [metricData, setMetricData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
 
  const fetchIndustryMetric = async () => {
    Getindustrymetricdata()
  }

  useMemo(() => {
    fetchIndustryMetric();
  }, [])
  
  return (
    <> 
      <Navbar reFreshNewLeadTable={() => fetchIndustryMetric()} value="industrymetric" className="activity_list_navbar" />
      <IndustryMetricTable   />
    </>
  );
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  getindustrylists:state.Industrylist.getindustrylists,
});

const mapDispatchToProps = (dispatch) => ({
  Getindustrymetricdata :(data) => dispatch(getindustrymetricdata(data)),
  
});

export default connect(mapStateToProps,mapDispatchToProps) (IndustryMetricList)


