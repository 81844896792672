import React, { useState, useEffect,useMemo } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import TicketTable from "../../components/commonColumns/TicketTable";
import { useDispatch, useSelector,connect } from 'react-redux';
import {getTicketListHandle} from "../../store/Ticketlist"

const TicketsList = React.memo(({getticketlists,}) => {
  // console.log("ticketlist",getticketlists)
  const dispatch = useDispatch();
  const fechData = async () => {
    dispatch(getTicketListHandle());

  }
  useMemo(() => {
    fechData();
  }, []);

  return (
    <>
      <Navbar value="tickets" className="activity_list_navbar" />
      <TicketTable/>
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
});
export default connect(mapStateToProps) (TicketsList)

