import React, { useState, useEffect } from "react";
import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {singleLayout, ProductsFilter, productTargetLayout, multipleLayout, SelectYear, MonthlyFilter, AccountMangers, QuartersFilter, config, UsersListFilter} from './chartConfig'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import {getRegions} from "../../store/targets";

const PlotlyComponent = createPlotlyComponent(Plotly);

const ProductTargets = (props) => {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
    const [accountManagers, setAccountManagers] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const quartersList = [{ value: "all", label: "Annual" }, { value: 1, label: 'Q1' }, { value: 2, label: "Q2" }, { value: 3, label: 'Q3' }, { value: 4, label: 'Q4' }]
    
    const [techiesList, setTechiesList] = useState([]);

    const {regionsList}  = useSelector(state => state.targets);
    const [regionsData, setRegionsData] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const dispatch = useDispatch()

    const {newproductsList} =  useSelector(state => state.dashboard)

    const getSalesTargetData = async () => {
        console.log("filte ", filter)
        // if(filter.techie){
            getSalesFilteredData()
        // }else{
        //     const res = await axios.post("/user/techie-charts/product-target", filter)
        //     if (res.data.status === "success") {
        //         setData(res.data.chartData);
        //     }
        // }
        
    }

    const getSalesFilteredData = async () => {
        console.log("filters ", filter)
        // var techie = filter.techie.map(item => item.value)
        const res = await axios.post("/user/techie-charts/product-target-filter", {
            techie : filter.techie?.value,
            year: filter.year?.value,
            quarter: filter?.quarter,
            region: filter?.region?.label,
            // category: filter?.category?.value
        })
        if (res.data.status === "success") {
            setData(res.data.chartData);
        }
    }

    const getProductsFamilyList = async () => {
        const res = await axios.get("/products/search");
        setProductsList(res.data.productsList);
    }
    

    const getManagers = async () => {
        const resdata = await axios.get('user/reports/users-list');
        if (resdata.data.status === "success") {
            var dataa = [];
            dataa.push({ label: "All", value: "all" })
            resdata.data.usersList.map(item => {
                dataa.push(item)
            })
            setAccountManagers(dataa);
        } else {
        }
    }

    const getTechieUsersList = async() => {
        const res = await axios.get("/user/techies-list");
        console.log("res ", res.data.techiesList);
        setTechiesList(res.data.techiesList);
    }
    
    useEffect(() => {
        getSalesTargetData();
    }, [filter]);

    useEffect(() => {
        getSalesTargetData();
    }, [])

    useEffect(() => {
        
        multipleLayout.title = "Sales Target vs Achieved"
        singleLayout.title = "Sales Target vs Achieved"
        getManagers();
        getProductsFamilyList();
        getTechieUsersList();
    }, []);
    const years = [{ value: 2021, label: 2021 }, { value: 2022, label: 2022 }, { value: 2023, label: 2023 }, { value: 2024, label: 2024 }]
   
    
const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
    modeBarButtonsToAdd: [
      {
        name: 'Download',
        icon: Plotly.Icons.camera, // Download icon
        click: function(gd) {
          // Handle click on custom lock icon
          // alert('Custom Lock Icon Clicked!');
          toast.success("Downloaded Successfully!")
          // Download the chart as PNG
          downloadChartAsPng(gd);
        },
        title: 'Download As Png'
      },
  
    ],
  };
  
  function downloadChartAsPng(gd) {
    const chartContainer = document.getElementById(gd.id);
    const width = 1200;
    const height = 800;
  
    // Create a data URL of the chart as a PNG image
    Plotly.toImage(chartContainer, { format: 'png', width: width, height: height })
      .then(function(url) {
        // Trigger download using the created data URL
        const a = document.createElement('a');
        a.href = url;
        a.download = 'product Targets Chart.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  useEffect(() => {
    dispatch(getRegions())
}, [])
   
useEffect(() => {
        
    if(regionsList?.length>0){
        var regions = [] 
        var reg = []
        regionsList.forEach(item => {
            console.log('item ', item)
            var item1 = item.label?.split(" - ")
            if(!reg.includes(item1[0])){
                reg.push(item1[0])
                regions.push({label: item1[0], value: item.value})
            }
            // console.log("item1 ", item1, regions)
        })
        setRegionsData(regions)
    }
}, [regionsList])


useEffect(() => {
    console.log("newproductsList", newproductsList)
    if(newproductsList?.length>0){
        var categories = []
        newproductsList.forEach(item => {
            categories.push({label: item.name, value: item.value})
        })
        setProductCategories(categories)
    }
    
}, [newproductsList])
   
    return (
        <>
            <div className="row">
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <SelectYear years={years}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                </div>
                {/* <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <QuartersFilter quartersList={quartersList} disableQuarterFilter={false} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
                </div> */}
                <div className="col-md-2 col-lg-1" style={{ width: '14%' }}>
                  <div>
                    {(userData?.user_role_id?.role_name !== "techie") ?
                    <UsersListFilter values={filter.techie}  selectSingle ={true} placeholder={"Select Techie"} productFamilyList={techiesList} isDisabled={filter.disableAccountManagerFilter}  updateFilter={ (value) => setFilter({ ...filter, techie: value}) } />
                    //   <ProductsFilter values={filter.products} selectSingle={true}  disableFilter={false} updateFilter={(value) => setFilter({ ...filter, techie: value }) }  productsList ={techiesList} />
                      : ""}
                  </div>
                </div>
                <div className="col-md-2 col-lg-1" style={{ width: '14%' }}>
                  <div>
                    <UsersListFilter values={filter.region}  selectSingle ={true} placeholder={"Select Region"} productFamilyList={regionsData} isDisabled={false}  updateFilter={ (value) => setFilter({ ...filter, region: value}) } />
                  </div>
                </div>
                {/* <div className="col-md-2 col-lg-1" style={{ width: '14%' }}>
                  <div>
                    <UsersListFilter values={filter.category}  selectSingle ={true} placeholder={"Select Category"} productFamilyList={productCategories} isDisabled={false}  updateFilter={ (value) => setFilter({ ...filter, category: value}) } />
                  </div>
                </div> */}
                <PlotlyComponent
                id="productTargetLayoutComponents"
                    data={data}
                    layout={productTargetLayout}
                    useResizeHandler
                    className="w-full h-full"
                    config={config}
                />
            </div>
        </>
    )
}

export default ProductTargets;
