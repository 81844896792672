import React, { useState, useEffect, useMemo } from 'react';
import BattleCardsTable from '../../components/commonColumns/BattleCardsTable';
import Navbar from "../home/Navbar";
import axios from "axios"
import "./battlecards.css";
import { connect } from 'react-redux';
import {getbattlecards} from "../../store/Battlecard"

const BattleCards = React.memo(({user,getbattlecards,battlecardslist}) => {
  // console.log("battlecardslist",battlecardslist)
  const userData = user.user;
  const fetchData = async () => {
    const userid = {
     user_id:userData?._id
    }
    getbattlecards(userid)
   }
  useMemo(() => {
   
    fetchData()
  },[])

  return (
    <div>
      <Navbar value="battlecards" className="activity_list_navbar" />
      <BattleCardsTable/>
    </div>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
battlecardslist:state.Battlecardlist.battlecardslist
});
const mapDispatchToProps = (dispatch) => ({
  getbattlecards : (data) => dispatch(getbattlecards(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(BattleCards)