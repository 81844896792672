import React, { useState, useEffect } from "react";
import './employeeChart.css';
import axios from "axios";
import { Tree, TreeNode } from 'react-organizational-chart';
import { useLocation } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import { BiMailSend } from "react-icons/bi";
import EmployeeUpdateModal from "./EmployeeUpdateModal"
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const EmployeeChart = (props) => {

  const search = useLocation().search;
  const acid = new URLSearchParams(search).get("acid");
  const company_name = new URLSearchParams(search).get("company_name");
  
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [state, setState] = useState({
    contacts_list: null,
    data: null,
    root: null,
    showEmployeeUpdateModal: false,
  })
  const getContacts = async (props) => {
    const res = await axios.get("/demos/economic-buyer-list/" + acid);
    if (res.data.status === "success") {
      setState(prev => ({ contacts_list: res.data.economicBuyerList }))
    }
  }

  const managerChange = (val) => {
    state.manager = val;
  }

  const membersChange = (val) => {
    state.members = val
  }

  const handleCheckBox = (e) => {
    state.is_root = e.target.checked
  }

  const updateContacts = async () => {
    if (state.manager && state.members) {
      var inputData = {
        manager: state.manager,
        members: state.members,
        is_root: state.is_root || false
      }
      const res = await axios.post("/contacts/update-organization-chart/update", inputData);
      getChartData();
    }
  }

  const getChartData = async () => {
    setOpen(true);
    const res = await axios.post("/contacts/organization-chart-data", { account_id: acid });
    if (res.data.status === "success") {
      setState(prev => ({ data: res.data.contacts, root: res.data.root }))
      setOpen(false);
    } else {
      setOpen(false);
    }
  }

  useEffect(() => {
    getContacts();
    getChartData();
  }, [])

  const Datass = (props) => {
    return (
      props.data.map(item => {
        return (

          <TreeNode label={
            <div className="card shadow card_main">
              &nbsp;&nbsp;
              {item.name} - {item.designation}

              <div className="card-footer" style={{ backgroundColor: item.color }}>
                {item.phone && <a href={`tel:${item.phone}`}><BiPhoneCall /></a>}
                &nbsp;&nbsp;&nbsp;
                {item.email && <a href={`mailto:${item.email}`}><BiMailSend /></a>}
              </div>
            </div>
          }>
            {item.children.length > 0 ? <Datass data={item.children} /> : null}

          </TreeNode>
        )
      })
    )
  }

  return (
    <>
      <div className="upadte_modal">
        <button className="employee_btn btn btn-primary" onClick={() => setState({ ...state, showEmployeeUpdateModal: true })}>Update</button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12 col-12 mx-auto">
            <div className="row">
              <div className="col-md-6" >
                {
                  state.root?.length > 0 ?
                    <Tree label={<div>{state.root}</div>}>
                      {
                        state.data && state.data.length > 0 && <Datass data={state.data} />
                      }
                    </Tree>
                    : <h1>Update Chart details</h1>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      {state.showEmployeeUpdateModal ? <EmployeeUpdateModal company_name={company_name} refreshData={() => getChartData()} show={state.showEmployeeUpdateModal} accountId={acid} onHide={() => setState({ ...state, showEmployeeUpdateModal: false })} /> : ""}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={"Updating Organization Chart"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default EmployeeChart;