import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import {  connect } from "react-redux";
import {fetchEmployeeLeaveStatus} from "../../store/dashboard";
import DateFnsUtils from '@date-io/date-fns';
const EmployeeLeaveStatus = React.memo(({EmployeeleaveLoader,employeeLeaveCount, fetchEmployeeLeaveStatusData}) => {
  // const [employeeStatus, setEmployeeStatus] = useState([]);
  // const [totalWorkFromHomeCount, setTotalWorkFromHomeCount] = useState(0);
  // const [totalLeaveCount, setTotalLeaveCount] = useState(0);
  // const [totalInOfficeCount, setTotalInOfficeCount] = useState(0);
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));

  // const dispatch = useDispatch();
  // const { employeeLeaveCount } = useSelector(state => state.dashboard);



  // const fetchEmployeeLeaveStatus = async () => {
  //   try {
  //     const response = await axios.get(`user/employee-leave-status?date=${filterDate}`);
  //     setEmployeeStatus(response.data.employees);
  //     setTotalWorkFromHomeCount(response.data.totalWorkFromHomeCount);
  //     setTotalLeaveCount(response.data.totalLeaveCount);
  //     setTotalInOfficeCount(response.data.totalInOfficeCount);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
/*
  const generateExcelFile = () => {
    const workbook = XLSX.utils.book_new();
    const employeeData = employeeStatus.map((employee) => ({
      Name: employee.name,
      Status: employee.status,
    }));
    const employeeWorksheet = XLSX.utils.json_to_sheet(employeeData);
    XLSX.utils.book_append_sheet(workbook, employeeWorksheet, 'Employee Data');
    const totalCounts = [
      {
        'Total Work From Home': totalWorkFromHomeCount,
        'Total Leave Count': totalLeaveCount,
        'Total In Office': totalInOfficeCount,
      },
    ];
    const totalCountsWorksheet = XLSX.utils.json_to_sheet(totalCounts);
    XLSX.utils.book_append_sheet(workbook, totalCountsWorksheet, 'Total Counts');
    writeFile(workbook, 'employee_leave_status.xlsx');
  };
  */
  
  

  useMemo(() => {
    fetchEmployeeLeaveStatusData(filterDate);
  }, [filterDate]);

  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  return (
<>
{EmployeeleaveLoader ?  
  <div className="parent-div">
  <div className="loader"></div>
</div>
:
<>
    <div className="container">
      <div className="filter-section">
        <br />
        <div className="row">
          <div className="col-lg-1"><label>Select Date: </label></div>
          <div className="col-lg-3">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className="form-control"
                label="Date"
                inputVariant="outlined"
                value={filterDate}
                labelFunc={formatWeekSelectLabel}
                onChange={(date)  => setFilterDate(moment(date).format('YYYY-MM-DD'))}
                showTodayButton
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </MuiPickersUtilsProvider>
          </div>
        </div>
        

        {/* <input
          type="date"
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
          style={{
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            fontSize: "14px",
          }}
        /> */}
        <br />
      </div>
{/*       <div className="download-button">
        <Button
        variant="contained"
        color="primary"
        onClick={generateExcelFile}
        style={{ float: "right" }}
        >
            Download as Excel
        </Button>
    </div> */}
      <div className="employee-status">
        <h2>Employee Leave Status</h2>
        <br />
        <div className="total-counts">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Total Work From Home</TableCell>
                <TableCell>Total Leave Count</TableCell>
                <TableCell>Total In Office</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{employeeLeaveCount?.totalWorkFromHomeCount}</TableCell>
                <TableCell>{employeeLeaveCount?.totalLeaveCount}</TableCell>
                <TableCell>{employeeLeaveCount?.totalInOfficeCount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
      </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeLeaveCount?.employees?.map((employee, index) => (
                <TableRow key={index}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
      </div>


    </div>
    </>
  }
    </>
  );
});

// export default ;
const mapStateToProps = (state) => ({
  employeeLeaveCount: state.dashboard.employeeLeaveCount,
  EmployeeleaveLoader:state.dashboard.EmployeeleaveLoader
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployeeLeaveStatusData: (data) => dispatch(fetchEmployeeLeaveStatus(data)),
});

// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLeaveStatus);