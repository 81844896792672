import React,{useState,useEffect} from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {SelectYear, QuartersFilter} from './chartConfig';
import {useSelector,useDispatch} from "react-redux";
import { getYears } from "../../store/dashboard";
import { updateViewRowDetails } from "../../store/opportunities";
import Badge from 'react-bootstrap/Badge'
const OpportunitiesViewChart = React.memo((props) => {
  const dispatch = useDispatch()
  const yearsList = useSelector((state) => state.dashboard.yearsList)
  const FilterYearFromOpp = useSelector((state) => state.opportunities.opportunitiesYearFilter)
  // const FilterYear = useSelector((state) => state.opportunities)
  const quartersList = [{ value: "all", label: "Annual" }, { value: 'Q1', label: 'Q1' }, { value: 'Q2', label: "Q2" }, { value: 'Q3', label: 'Q3' }, { value: 'Q4', label: 'Q4' }]
  console.log("PreSalesReportNew",FilterYearFromOpp)
  const [filter, setFilter] = useState({year:{label:FilterYearFromOpp,value:FilterYearFromOpp}});
  console.log("filters are opportunitiesYearFilter",filter)

    const PlotlyComponent = createPlotlyComponent(Plotly);

    const layout = {
        paper_bgcolor: '#fff ',
        font: {
          size: 12,
          color: '#6E8898'
        },
        margin: {
    
        },
        title: "All Activities",
        useResizeHandler: true,
        height:350,
        style: { width: "100%", height: "100%" },
        showlegend: true,
        showgrid:false,
        grid:false,
        xaxis:{
          showgrid:false,
          showline:false
        },
        yaxis:{
          showgrid:false
        },
        legend: { "orientation": "h" },
      }
    
      const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian']
      }

useEffect(() => {
dispatch(getYears())
},[])

useEffect(() => {
  if(filter){
    let newQuarter;
    if (filter.quarter === "Q1") {
      newQuarter = 1;
    } else if (filter.quarter === "Q2") {
      newQuarter = 2;
    } else if (filter.quarter === "Q3") {
      newQuarter = 3;
    } else if (filter.quarter === "Q4") {
      newQuarter = 4;
    } else {
      newQuarter = null;
    }
    const filetrData = {
      year:Number(filter?.year?.value),
      quarter:newQuarter,
      rowId:props?.rowId
    }
    dispatch(updateViewRowDetails(filetrData))
  }

},[filter])
const removeYearvalueBadge = (e) => {
const input = e.target.getAttribute("data")

// const filteredYears = filter.year.value !==input;
var filteredYears = filter.year.value.toString() === input.toString() ? "" : filter.year;
console.log("input is here filteredYears//",filteredYears)
setFilter({...filter,year:filteredYears})
}
const quarterValueHandle = (e) =>{
  const input = e.target.getAttribute("data")

  // const filteredYears = filter.year.value !==input;
  var filteredquarter = filter.quarter.toString() === input.toString() ? "" : filter.quarter;
  console.log("input is here filteredquarter//",filteredquarter)
  setFilter({...filter,quarter:filteredquarter})
}
    return (
<>
<div className="filters_and_table">
<div className="row mt-3">
<div className="col-md-2 col-lg-3">
          <SelectYear value={filter.year} years={yearsList}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
    </div>

    <div className="col-md-2 col-lg-3">
        <QuartersFilter values={filter.quarter} quartersList={quartersList}  disableQuarterFilter={false} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
    </div>
</div>
<div className="row mt-2">
    <div className="col-md-12">

    {
        filter.year && 
      <Badge onClick={removeYearvalueBadge}
            pill data={filter.year.value} value={filter.year.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.year.value}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
      }
       {
        filter.quarter && 
      <Badge onClick={quarterValueHandle}
            pill data={filter.quarter} value={filter.quarter} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.quarter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
      }
      </div>
      </div>
</div>
<PlotlyComponent
            id="postSalesChatComponents"
            data={props.data}
            layout={layout}
            className="w-full h-full"
            config={config}
        />
</>
       

    )

});

export default OpportunitiesViewChart;