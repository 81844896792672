import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OpportunitiesTable from "../../components/commonColumns/OpportunitiesTable"
import ContactTable from "../../components/commonColumns/ContactTable";
import AddNewContact from "../pageModals/AddNewContact";
import { RiOrganizationChart } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { useSelector ,useDispatch, connect } from 'react-redux';
import { ContactActions } from '../../store/Contact';
import {Viewaccountupdated,getaccountdetailsHandle,getannualrevenue,CommonsliceActions} from "../../store/Common"
export const ViewAccount = React.memo(({getaccountdata,openmodal,accountdetails,annualrevenue,accountupdatedata,openshowModal,closemodal,RowIdss,getannualrevenuelists,getannualrevenuedata, demo_showModal}) => {
  
  const dispatch = useDispatch();
  const [accountShow, setAccountShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [annualRevenue, setAnnualRevenue] = useState();
  const [contactsList, setContactsList] = useState([]);
  const [openOpportunitiesList, setOpenOpportunitiesList] = useState([]);
  const [closedOpportunitiesList, setClosedOpportunitiesList] = useState([]);
  const [lostOpportunitiesList, setLostOpportunitiesList] = useState([]);
  var [deleteClicked, setDeleteClicked] = useState("notClicked");
  // console.log("tabel data in techie", openOpportunitiesList)
  // console.log("tabel data in techie", closedOpportunitiesList)
  // console.log("tabel data in techie", lostOpportunitiesList)
  const [defaultTableText, setDefaultTableText] = useState('Loading...');
  const [annualRevenueRangeList, setAnnualRevenueRangeList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [rotateChevron1, setRotateChevron1] = useState(false);
  const [rotateChevron2, setRotateChevron2] = useState(false);
  const [rotateChevron3, setRotateChevron3] = useState(false);
  var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const { register: accountRegister, handleSubmit: accountHandleSubmit, setError: accountSetError, formState: { errors: accountErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const [account, setAccount] = useState({
    ac_company_name: "",
    ac_company_website: "",
    ac_annual_revenue: "",
    ac_contact_number: "",
    ac_street: "",
    ac_city: "",
    ac_state: "",
    ac_postal_code: "",
    ac_country: ""
  });
  const { ac_company_name, ac_company_website, ac_annual_revenue, ac_contact_number, ac_street,
    ac_city, ac_state, ac_postal_code, ac_country } = account;
  const [toggleState, settoggleTab] = useState(1);

  const toggleTab = (index) => {
    settoggleTab(index);
  };
  const AddcontactModal = useSelector((state) => state.Contactlist.AddcontactModal);

  var [account_id, setAccountId] = useState({
    account_id: ""
  });

    const UpdateAccountHandle = async (e) => {
    setDisable(true);
    setTimeout(() => { }, 500);
    const updatedData = {
          account_id: account_id.account_id,
          company_name: account.ac_company_name,
          company_website: account.ac_company_website,
          street: account.ac_street,
          city: account.ac_city,
          state: account.ac_state,
          postal_code: account.ac_postal_code,
          country: account.ac_country,
          annual_revenue: account.ac_annual_revenue,
          user_id: userData ? userData._id : null
        }
        accountupdatedata(updatedData)
}
  const getDetailsHandle = async (id) => {
  //   try {

  //     // const res = await axios.get("/accounts/details/" + id);
  //     // const accountData = res.data.accountDetail;
  //     dispatch(getaccountdetailsHandle(id)); 
  //      const res = getaccountdata
  //      const accountData = res?.accountDetail ? res?.accountDetail : []
  //      console.log("jsfjs",accountData)
   
       
     

  //     setAccount({
  //       ac_company_name: accountData.company_name,
  //       ac_company_website: accountData.company_website,
  //       ac_annual_revenue: accountData.annual_revenue,
  //       ac_contact_number: accountData.contact_number,
  //       ac_street: (accountData.company_address ? accountData.company_address.street : null),
  //       ac_city: (accountData.company_address ? accountData.company_address.city : null),
  //       ac_state: (accountData.company_address ? accountData.company_address.state : null),
  //       ac_postal_code: (accountData.company_address ? accountData.company_address.postal_code : null),
  //       ac_country: (accountData.company_address ? accountData.company_address.country : null)
  //     });
  //     setContactsList(getaccountdata.contactsList);
  //     setOpenOpportunitiesList(getaccountdata.openOpportunitiesList);
  //     setClosedOpportunitiesList(getaccountdata.closedOpportunitiesList);
  //     setLostOpportunitiesList(getaccountdata.lostOpportunitiesList);
  //     setAnnualRevenue(accountData.annual_revenue);
  //     setAccountId({ account_id: id });


  //     if (deleteClicked === "notClicked") {
  //       setAccountShow(true);
  //     }

  //     if (getaccountdata.contactsList ||getaccountdata.openOpportunitiesList.length < 1 ||getaccountdata.closedOpportunitiesList.length < 1) {
  //       setDefaultTableText("No Records Found");
  //     }


  //   } catch (err) {
  //     setSnackBarMessage("Error in fetching records...");
  //     setDefaultTableText('Error in fetching records');


  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 1000);

  //   }

  // };
    try {

      const res = await axios.get("/accounts/details/" + id);
      const accountData = res.data.accountDetail;
      console.log("accountdata",accountData)
      // accountdetails(id)
      //  const rest = getaccountdata
      //  const accountDatat = rest?.accountDetail ? rest?.accountDetail : []
      setAccount({
        ac_company_name: accountData.company_name,
        ac_company_website: accountData.company_website,
        ac_annual_revenue: accountData.annual_revenue,
        ac_contact_number: accountData.contact_number,
        ac_street: (accountData.company_address ? accountData.company_address.street : null),
        ac_city: (accountData.company_address ? accountData.company_address.city : null),
        ac_state: (accountData.company_address ? accountData.company_address.state : null),
        ac_postal_code: (accountData.company_address ? accountData.company_address.postal_code : null),
        ac_country: (accountData.company_address ? accountData.company_address.country : null)
      });
      setContactsList(res.data.contactsList);
      setOpenOpportunitiesList(res.data.openOpportunitiesList);
      setClosedOpportunitiesList(res.data.closedOpportunitiesList);
      setLostOpportunitiesList(res.data.lostOpportunitiesList);
      setAnnualRevenue(accountData.annual_revenue);
      setAccountId({ account_id: id });

      if (deleteClicked === "notClicked") {
        setAccountShow(true);
      }

      if (res.data.contactsList || res.data.openOpportunitiesList.length < 1 || res.data.closedOpportunitiesList.length < 1) {
        setDefaultTableText("No Records Found");
      }
    } catch (err) {
     
      setDefaultTableText('Error in fetching records');
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };
  useEffect(() => {
    getDetailsHandle(RowIdss);
  }, [RowIdss])

  const onInputChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value }, []);
  };

  const getAnnualRevenueRangeList = async () => {
    dispatch(getannualrevenue()); 
    let res = annualrevenue
    let annualrevenueList = res?.annualRevenueRange ?  res?.annualRevenueRange :[]
    setAnnualRevenueRangeList(annualrevenueList)
    // const res = await axios.get("/annual-revenue-range/search");
    // setAnnualRevenueRangeList(res.data.annualRevenueRange);
  }
  useEffect(() => {
    getAnnualRevenueRangeList();
    getannualrevenuedata()
  }, []);

  const handleRotate1 = () => setRotateChevron1(!rotateChevron1);
  const handleRotate2 = () => setRotateChevron2(!rotateChevron2);
  const handleRotate3 = () => setRotateChevron3(!rotateChevron3);
  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header >
  
       <h5> {(userData && userData.user_role_id && userData.user_role_id.role_name === "user") ? "Update Account" : "View Account"} </h5>

          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={accountHandleSubmit(UpdateAccountHandle)}>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
        
              <div className="row headerBand">
                <label>Organization Details
                </label>
              </div>
              
              {ac_company_name &&
                <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                  <Link target="_blank" to={{
                    pathname: "/org-chart",
                    search: "?acid=" + account_id.account_id + "&company_name=" + ac_company_name,
                    hash: "",
                    state: {}
                  }}> <RiOrganizationChart /> Org Chart</Link>
                </div>
              }
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...accountRegister("ac_company_name", {
                    minLength: {  
                      value: 3,
                      message: "Organization Name must be at least 3 characters"
                    }
                  })}
                  required
                  inputProps={
                    { readOnly: true, }
                  }

                  helperText={accountErrors.ac_company_name ? accountErrors.ac_company_name.message : ''}
                  error={Boolean(accountErrors.ac_company_name)}
                  type="text" className="form-control" value={ac_company_name} name="ac_company_name" label="Organization Name" onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...accountRegister("ac_company_website", {
                    minLength: {
                      value: 3,
                      message: "Organization Website must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={accountErrors.ac_company_website ? accountErrors.ac_company_website.message : ''}
                  error={Boolean(accountErrors.ac_company_website)}
                  type="text" className="form-control" value={ac_company_website} name="ac_company_website" label="Organization Website" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">

              <div class="col-md-6">
                <TextField variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                  }}
                  select
                  required
                  value={ac_annual_revenue}
                  helperText={accountErrors.ac_annual_revenue ? accountErrors.ac_annual_revenue.message : ''}
                  error={Boolean(accountErrors.ac_annual_revenue)}

                  type="number" className="form-control" name="ac_annual_revenue" label="Annual Revenue" onChange={e => onInputChange(e)} >

                  {
                    getannualrevenuelists.map((option, ac_annual_revenue) => (
                      <MenuItem key={option.value} selected={ac_annual_revenue === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>

              </div>
            </div><br />
            <div class="row">
              <div className="col-md-6">
                <TextField variant="outlined" multiline rows={3}
                  {...accountRegister("ac_street", {
                    minLength: {
                      value: 3,
                      message: "Street must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={accountErrors.ac_street ? accountErrors.ac_street.message : ''}
                  error={Boolean(accountErrors.ac_street)}
                  label="Street" name="ac_street" onChange={e => onInputChange(e)} className="form-control" value={ac_street} />
              </div>

              <div class="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <TextField variant="outlined"
                      {...accountRegister("ac_city", {
                        minLength: {
                          value: 3,
                          message: "City must be at least 3 characters"
                        }
                      })}
                      required
                      helperText={accountErrors.ac_city ? accountErrors.ac_city.message : ''}
                      error={Boolean(accountErrors.ac_city)}
                      type="text" label="City" name="ac_city" onChange={e => onInputChange(e)} className="form-control" value={ac_city} />
                  </div>
                  <div className="col-md-6">
                    <TextField variant="outlined"
                      {...accountRegister("ac_state", {
                        minLength: {
                          value: 3,
                          message: "State must be at least 3 characters"
                        }
                      })}
                      required
                      helperText={accountErrors.ac_state ? accountErrors.ac_state.message : ''}
                      error={Boolean(accountErrors.ac_state)}
                      type="text" label="State" name="ac_state" onChange={e => onInputChange(e)} className="form-control" value={ac_state} />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <TextField variant="outlined"
                      {...accountRegister("ac_postal_code", {
                        minLength: {
                          value: 3,
                          message: "Postal Code must be at least 3 characters"
                        }
                      })}
                      required
                      helperText={accountErrors.ac_postal_code ? accountErrors.ac_postal_code.message : ''}
                      error={Boolean(accountErrors.ac_postal_code)}
                      type="number" label="Postal Code" name="ac_postal_code" onChange={e => onInputChange(e)} className="form-control" value={ac_postal_code} />
                  </div>
                  <div className="col-md-6">
                    <TextField variant="outlined"
                      {...accountRegister("ac_country", {
                        minLength: {
                          value: 3,
                          message: "Country must be at least 3 characters"
                        }
                      })}
                      required
                      helperText={accountErrors.ac_country ? accountErrors.ac_country.message : ''}
                      error={Boolean(accountErrors.ac_country)}
                      type="text" label="Country" name="ac_country" onChange={e => onInputChange(e)} className="form-control" value={ac_country} />
                  </div>
                </div>

              </div>

            </div>
            <br />
            <div className="row" style={{ float: 'right' }}>
              <div className=" col-md-3 mx-auto">
                <button disabled={disable} className="btn btn-sm  bottomBarButtons upadte_common_btn">Update</button>
              </div>
            </div>
            <br />
            <br />
            <div className="container container_tabs">
              <div className="lock_tabs" style={{ whiteSpace: "nowrap", maxWidth: '1000px', overflowX: 'auto', marginTop: "1rem" }}>
                <a
                  className={toggleState === 1 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(1)}
                >
                  Contacts
                </a>
                <a
                  className={toggleState === 2 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(2)}
                >
                  Open Opportunities
                </a>

                <a
                  className={toggleState === 3 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(3)}
                >
                  Won Opportunities
                </a>

                <a
                  className={toggleState === 4 ? "tab active_tab" : "tab"}
                  onClick={() => toggleTab(4)}
                >
                  Lost Opportunities
                </a>

              </div>
              <div className="tab_content">
                <div
                  className={toggleState === 1 ? "content active_content" : "content"}
                >
                  <div className="col-12 mx-auto d-flex flex-row justify-content-center align-items-center">
                    <>

                      {/* <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => openmodal()} >Add new Contact</Button>
                      {AddcontactModal ? (
                        <AddNewContact/>
                      ) : null} */}

                      <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => openmodal()} >Add new Contact</Button>
                      {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={ac_company_name} otherAccountId={RowIdss}  onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}
                     {/* {showAddContactModal ? <AddNewContact show={showAddContactModal} companyName={ac_company_name} accountId={RowIdss} closemodal={() => dispatch(ContactActions.AddContactcloseModal())} /> : ""} */}

                    </>
                  </div>
                  <br />
                  <div className="row">
                    <ContactTable keyData="viewAccountTableContacts" viewAccountContactdata={contactsList} tableHeight="300px" />
                  </div>


                </div>
                <div
                  className={toggleState === 2 ? "content active_content" : "content"}
                >
                  <div className="row">
                    <OpportunitiesTable keyData="viewAccountOpportunitiesKey" viewAccountOPPData={openOpportunitiesList} oppportunityType="Open" tableHeight="300px" />
                  </div>

                </div>
                <div className={toggleState === 3 ? "content active_content" : "content"} >
                  <div className="row">
                    <OpportunitiesTable keyData="viewAccountOpportunitiesKey" viewAccountOPPData={closedOpportunitiesList} oppportunityType="Won" tableHeight="300px" />
                  </div>
                </div>

                <div className={toggleState === 4 ? "content active_content" : "content"}>
                  <div className="row">
                    <OpportunitiesTable keyData="viewAccountOpportunitiesKey" viewAccountOPPData={lostOpportunitiesList} oppportunityType="Lost" tableHeight="300px" />
                  </div>
                </div>

              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
     
    </>
  )

});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    getaccountdata :state.Commonlist.getaccountdetails,
    RowIdss : state.Commonlist.rowId,
    getannualrevenuelists:state.Commonlist.getannualrevenuelists,
    demo_showModal : state.Commonlist.demo_showModal
  });
  


  const mapDispatchToProps = (dispatch) => ({
    accountdetails :(data) => dispatch(getaccountdetailsHandle(data)),
    accountupdatedata :(data) => dispatch(Viewaccountupdated(data)),
    openshowModal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
    closemodal:(data) => dispatch(CommonsliceActions.demo_closeModal(data)),
    getannualrevenuedata:() => dispatch(getannualrevenue()),
    openmodal :(data) => dispatch(ContactActions.AddContactopenModal(data)),
  });
  
  export default connect(mapStateToProps,mapDispatchToProps) (ViewAccount)
