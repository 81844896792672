
import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
import BenchmarkModal from "../components/pageModals/BenchmarkModal";
var userObj = JSON.parse(localStorage.getItem("user")) || null;

const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showModal_assign: false,
    showModal_activity:false,
    showModal_Viewcontact:false,
    benchmarklist: [],
    benchmarktiming:[],
    benchmarkdeleteDemoRequest:[],
    benchmarkassignlist:[],
    addtechactivity:[],
    columnsList:[],
    techieList:[],
    productList:[]
}

const BenchmarkSlice = createSlice({
    name: "Benchmarklist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },
         Viewcontact_openModal : (state, action) => {
          state.showModal_Viewcontact = true; 
        },
        Viewcontact_CloseModal: (state, action) => {
          state.showModal_Viewcontact = false; 
        },

        setbenchmarkListId(state, action) {
          state.benchmarkListId = action.payload;
        },
        setbenchmarkrowMetaData(state, action) {
          state.benchmarkrowMetadata = action.payload;
        },

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          benchmarklistSuccess(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarklist = action.payload;
            // toast.success("Completed") 
          },
          benchmarklistFailure(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarklist = [];
            toast.error("Error In benchmark list") 
          },
          benchmarkstartBenchmarkSuccess(state,action)  {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.startBenchmark = [];
            toast.success("success  start Benchmark ") 
          },
          benchmarkstartBenchmarkFailure(state,action)  {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.startBenchmark = [];
            toast.error("Error in startBenchmark") 
          },
          benchmarktimingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarktiming = action.payload;
            toast.success("benchmark  data") 
          },
          benchmarktimingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarktiming = action.payload;
            toast.error("benchmark  data") 
          },
          benchmarkdeleteDemoRequestSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarkdeleteDemoRequest = action.payload;
            toast.success("benchmark delete demo request") 
          },
          benchmarkdeleteDemoRequestFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarkdeleteDemoRequest = action.payload;
            toast.error("benchmark delete demo request") 
          },
       
          benchmarksassignSuccess (state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarkassignlist = action.payload;
            toast.success("benchmark assign") 
            state.showModal_assign = false
          },
          benchmarksassignFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.benchmarkassignlist = [];
            toast.error(" Error in benchmark assign") 
            state.showModal_assign = true
          },

          addTechActivitySuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.addtechactivity = action.payload;;
            toast.success("Succesfully Added tech activity ") 
            state.showModal_activity = false;
          },
          addTechActivityFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.addtechactivity = [];
            toast.error("Error in tech activity") 
            state.showModal_activity = false;
          },
      
          benchmarkColumnsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.columnsList = action.payload
        },
        benchmarkColumnsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.columnsList = []
        },
        techieListSuccess(state, action){
          state.isFetch = false
          state.error = false
          state.errorPayload = false
          state.techieList = action.payload
      },
      techieListFailure(state, action){
        state.isFetch = false
        state.error = false
        state.errorPayload = false
        state.techieList = []
    },
    productListSuccess(state, action){
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.productList = action.payload
  },
  productListFailure(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.productList = []
},
updateColumnsList(state, action){
  state.columnsList = action.payload
}

    }  
});
export const BenchmarkActions = BenchmarkSlice.actions;


//BENCHMARK LIST START HERE

export const getbenchmarklist = (type) => {
  return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart());
    try {
      await Api.post("/benchmarks/list", type).then((res) => {
        if (res.data.status === "success") {

          dispatch(BenchmarkActions.benchmarklistSuccess(res?.data?.benchmarksList));

        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.benchmarklistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.benchmarklistFailure("Network Error"));
    }
  };
};

export const poststartBenchmark = (type) => {
  return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/benchmarks/update-timings", type).then((res) => {
        if (res.data.status === "success") {
          dispatch(BenchmarkActions.benchmarkstartBenchmarkSuccess(res.data));
          dispatch(getbenchmarklist({type:"all"}))
        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.benchmarkstartBenchmarkFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.benchmarkstartBenchmarkFailure("Network Error"));
    }
  };
};

export const benchmarksupdatetimings = (type) => {
  return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/benchmarks/update-timings", type).then((res) => {
        if (res.data.status === "success") {
          dispatch(BenchmarkActions.benchmarktimingSuccess(res.data));
          dispatch(getbenchmarklist({type:"all"}))
        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.benchmarktimingFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.benchmarktimingFailure("Network Error"));
    }
  };
};




export const benchmarksdeleteDemoRequest = (type) => {

  return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/benchmarks/remove", type).then((res) => {
        if (res.data.status === "success") {
          dispatch(BenchmarkActions.benchmarkdeleteDemoRequestSuccess(res.data));
          dispatch(getbenchmarklist({type:"all"}))
        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.benchmarkdeleteDemoRequestFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.benchmarkdeleteDemoRequestFailure("Network Error"));
    }
  };
};

export const benchmarksassign= (updatedData) => {

  return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/benchmarks/update", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(BenchmarkActions.benchmarksassignSuccess(res?.data));
          dispatch(getbenchmarklist({type:"all"}))
        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.benchmarksassignFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.benchmarksassignFailure("Network Error"));
    }
  };
};



export const add_tech_activity = (type) => {
   return async (dispatch) => {
    dispatch(BenchmarkActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/demos/add-tech-activity", type).then((res) => {
        if (res.data.status === "success") {
          dispatch(BenchmarkActions.addTechActivitySuccess(res.data));
          dispatch(getbenchmarklist({type:"all"}))
        }
        if (res.data.status === "failure") {
          dispatch(BenchmarkActions.addTechActivityFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(BenchmarkActions.addTechActivityFailure("Network Error"));
    }
  };
}; 


export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(BenchmarkActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/benchmarks/"+ userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(BenchmarkActions.benchmarkColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(BenchmarkActions.benchmarkColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(BenchmarkActions.benchmarkColumnsListFailure("Network Error"));
      }
  }
}
export const getTechieList = () => {
  return async (dispatch) => {
      dispatch(BenchmarkActions.loaderStart())
      try {
         
          await Api.get("/user/techies-list").then((res) => {
                  if (res.data.status === "success") {
                      dispatch(BenchmarkActions.techieListSuccess(res?.data?.techiesList))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(BenchmarkActions.techieListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(BenchmarkActions.techieListFailure("Network Error"));
      }
  }
}
export const getProductsList = () => {
  return async (dispatch) => {
      dispatch(BenchmarkActions.loaderStart())
      try {
         
          await Api.get("/products/search").then((res) => {
                  if (res.data.status === "success") {
                      dispatch(BenchmarkActions.productListSuccess(res?.data?.productsList))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(BenchmarkActions.productListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(BenchmarkActions.productListFailure("Network Error"));
      }
  }
}

  export default BenchmarkSlice.reducer;