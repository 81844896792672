import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState} from 'draft-js';
import MenuItem from "@material-ui/core/MenuItem";
import { useForm } from "react-hook-form";
import moment from 'moment';
import EmailCampaignContacts from './EmailCampaignContacts';
import SendTestMail from './SendTestMail';
import { connect, useSelector } from 'react-redux';
import{getdigitalmarketingdata,EmailcampaignActions} from "../../store/EmailCampaign";

    const EmailCampaignModal = React.memo (({props,emailCampaign,openmodal,closemodal}) => {
  
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();
    const [companiesList, setCompaniesList] = useState();
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [selectContacts, setSelectContacts] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const event_type = useRef();
    const content = useRef();
    const send_at = useRef();
    const [subject, setSubject] = useState("")
    
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [emailCampaignData, setEmailCampaignData] = useState({});
    const html = `<p> 
    <br /><br /><br /><br /><br /><br /><br /><br />
    </p>`;
    const contentBlock = htmlToDraft(props?.data ? props.data?.description : html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const html1 = `<p> 
    <br /><br />
    </p>`;
    const SubjectcontentBlock = htmlToDraft(props?.data? props.data?.description : html1);
    const SubjectcontentState = ContentState.createFromBlockArray(SubjectcontentBlock.contentBlocks);
    const subjectEditorStateTe = EditorState.createWithContent(SubjectcontentState);
    const [subjectState, setSubjectState] = useState(subjectEditorStateTe)
    const mailBody = useRef();
    const [sendTestMail, setSendTestMail] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [templateId, setTemplateId] = useState(null);

    useEffect(() => {
        getEventsList()
    }, [])

    const getEventsList = async () => {
        const res = await axios.get('/meetings/isales/webinar/events-list')
        setEventsList(res.data.data)
    }

    const getCompaniesList = async (search) => {
        setCompaniesList([]);
        const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
        setCompaniesList(res.data.AccountsList);
    }

    const handleSetCompanyName = (value) => {

        setCompanyName(value.value);
        if (value.__isNew__ === true) {
            
        } else {
            setAccountId(value.data_id);
        }
    }

    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY LT")}`
    };

    const UpdateSelectContacts = () => {
        setSelectContacts(true)
    }

    const updateEmailContacts = (data) => {
        setSelectedContacts(data)
        
    }

    const addNewEvent = async(e) => {

        e.preventDefault();
        setOpen(true)

        if(!emailCampaignData.event_type){
            setSnackBarMessage("Event Type is required")
            return;
        }

        if (!templateId || templateId.length < 1) {
            setSnackBarMessage("Template Id is required!");
            return;
          }
          

        if(selectedContacts.length < 1){
            setSnackBarMessage("Please select Contacts")
            return;
        }
        
        
        emailCampaignData.contacts = selectedContacts
        emailCampaignData.template_id = templateId.replace(" ","")
        // console.log("email campaign Data ", emailCampaignData)
        // emailCampaign(emailCampaignData)
        var res = await axios.post('/digital-marketing/create', emailCampaignData)
        if(res.data.status === "success"){
            setSnackBarMessage("Mail Sent Successfully");
            setTimeout(() => {
                 setOpen(true);

                //  props.onHide()
                // props.refreshTable()
              }, 2000);   
        }else{
            setSnackBarMessage(res.data.msg);
            setOpen(true);
        }
    }

    const onEditorStateChange = (data) => {
        
        setEditorState(data)
    }

    const onSubjectEditorStateChange = (data) => {
        
        setSubjectState(data)
    }

    const onTemplateIdChange = (e) => {
        
        setTemplateId(e.target.value)
    }

    

    const onInputChange = (e) => {
        setEmailCampaignData({ ...emailCampaignData, [e.target.name]: e.target.value }, []);
    };

    return (
        <>
            < Modal
                show={() => openmodal}
                onHide={() => closemodal()}
                backdrop={false}
                disableBackdrop={true}
                className={modalHide}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Email Campaign
                    </Modal.Title>
                    <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2} onSubmit={contactshandleSubmit(addNewEvent)}>
                            <div class="row">
                                <div class="col-md-6 col-md-offset-2 ">

                                <TextField variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    select
                                    onChange={e => onInputChange(e)}
                                    required
                                    type="text" className="form-control" name="event_type" label="Event Title"
                                    >
                                    {eventsList?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                        ))
                                    }
                                </TextField>

                                </div>
                            </div>
                            <br />
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <TextField 
                                    multiline 
                                    variant="outlined"
                                    required
                                    type="text" label="Template Id" name="template_id" className="form-control"
                                    value={templateId} 
                                    onChange={onTemplateIdChange} 
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-md-6 row">
                                <div class="col-md-5">
                                    <a onClick={UpdateSelectContacts}   className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Select Contacts</a>
                                </div>
                                <div class="col-md-7">
                                    <span style={{fontSize:'16px'}}>{selectedContacts.length} Contacts Selected</span>
                                </div>
                                
                            </div>
                            </div>
                            <br />
                        <br />
                        <div className="row">
                            <div>
                                <a onClick={()=>setSendTestMail(true)} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Test Mail</a>&nbsp;&nbsp;
                                <button onClick={addNewEvent} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
            

            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            {sendTestMail ? <SendTestMail emailCampaignData={emailCampaignData} template_id={templateId} show={sendTestMail} onHide={() => setSendTestMail(false)}   /> : null }

            { selectContacts ? <EmailCampaignContacts show={selectContacts} onHide={() => setSelectContacts(false)} emailContacts={(data) => updateEmailContacts(data) }  /> : null }
            
            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />

        </>
    );

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    getdigitalmarketinglists : state.Emailcampaignlist.getdigitalmarketinglists,
});


const mapDispatchToProps = (dispatch) => ({

    openmodal:(data) => dispatch(EmailcampaignActions.Email_campaign_openModal(data)),
    closemodal:(data) => dispatch(EmailcampaignActions.Email_campaign_closeModal(data))

});
export default connect(mapStateToProps,mapDispatchToProps) (EmailCampaignModal)
