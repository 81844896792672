import React, { useState } from 'react';
import Navbar from '../home/Navbar';
import TrainingTable from "../../components/commonColumns/TrainingTable";
import axios from 'axios';
import { useEffect } from 'react';
import {gettraininglist} from "../../store/Training"
import { useMemo } from 'react';
import { connect } from 'react-redux';


export const TrainingList = React.memo(({gettraininglistdata,traininglist,user,gettraininglists}) => {
    const fechData = async () => {
        var postData = {
            'type': 'all'

        }
        gettraininglistdata(postData)
    }

    useMemo(() => {
        fechData();
    },[])


        return (
        <> 
            <Navbar value="training" className="activity_list_navbar" />
            <div className="main_demo_section d-flex flex-column">
                <div className="demo_table">
                <TrainingTable/>
                </div>
            </div>
        </>
    )

})


const mapStateToProps = (state) => ({
    traininglist:state.Traininglist.traininglist,
    gettraininglists:state.Traininglist.gettraininglists,
    user : state.auth.user,

  });

  const mapDispatchToProps = (dispatch) => ({

    gettraininglistdata: (data) => dispatch(gettraininglist(data)),
    
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TrainingList);