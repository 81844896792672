import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, size) => ({
  userSelect: "none",
  padding: '3px',
  margin: '0px 3px 0px 0px',
  height: '28px',
  width: '70px',
  textAlign: 'center',
  background: isDragging ? "lightgreen" : "lightblue",
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  display: "flex",
  padding: grid,
  overflow: "auto"
});

class DragAndDrop extends Component {
  constructor(props) {
    super(props);

    if (props.data) {
      var datas = props.data.split(",")
      this.exportdata = datas;
      var array = ['Tech', 'IT', 'Purchase', 'Finance', 'Director'];
      var list1 = [];
      var list2 = [];
      var list3 = array.map(item => {
        if (!datas.includes(item)) {
          list1.push({ id: item, content: item })
        } else {
          list2.push({ id: item, content: item })
        }
      })
      this.state = {
        items: { "list-1": list1, "list-2": list2 },
        sizeA: 50,
        sizeB: 50
      };

    } else {

      this.state = {
        items: {
          "list-1": [
            {
              id: 'IT',
              content: 'IT'
            },
            {
              id: 'Purchase',
              content: 'Purchase'
            },
            {
              id: 'Finance',
              content: 'Finance'
            },
            {
              id: 'Director',
              content: 'Director'
            }
          ], "list-2": [{
            id: 'Tech',
            content: 'Tech'
          }]
        },
        sizeA: 50,
        sizeB: 50
      };
      this.exportdata = [];
    }

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === result.destination.droppableId) {
      const items = reorder(
        this.state.items[result.source.droppableId],
        result.source.index,
        result.destination.index
      );

      this.setState({
        items: {
          ...this.state.items,
          [result.source.droppableId]: items
        }
      });
    } else {
      const sourceClone = [...this.state.items[result.source.droppableId]];
      const destClone = [...this.state.items[result.destination.droppableId]];

      const [removed] = sourceClone.splice(result.source.index, 1);

      destClone.splice(result.destination.index, 0, removed);

      if (result.source.droppableId === "list-2") {
        this.props.DragCompleted(sourceClone)
      }
      if (result.destination.droppableId === "list-2") {
        this.props.DragCompleted(destClone)
      }

      this.setState({
        items: {
          [result.source.droppableId]: sourceClone,
          [result.destination.droppableId]: destClone
        }
      });
    }
  }
  render() {
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="list-1" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                 <span>Drag From Here : &nbsp;</span>
                {this.state.items["list-1"].map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (

                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          this.state.sizeA
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div>

          </div>
          <Droppable droppableId="list-2" direction="horizontal">
            {(provided, snapshot) => (
              
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <span>Drop Here : &nbsp;</span>
                {this.state.items["list-2"].map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          this.state.sizeB
                        )}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
export default DragAndDrop;
