import React, { useState, useEffect} from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import { Button } from 'react-bootstrap';
import TestimonialModal from '../../components/pageModals/TestimonialModal';
import TestimonialTable from '../../components/commonColumns/TestimonialTable';
import { useSelector,useDispatch, connect } from 'react-redux';
import {gettestimonialsdata,TestimonialsActions} from "../../store/Testimonials"

const Testimonial = React.memo (({gettestimoniallist,showtestimonialModal}) => {

  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const permissions = user.permissions;
  const [data, setData] = useState([]);
  // const [showLeaveModal, setShowLeaveModal] = useState(false);
  const fechData = async () => {
    dispatch(gettestimonialsdata());
  }
  useEffect(() => {
    fechData();
  }, [])
  return (
    <>
      <Navbar value="testimonial" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
          {permissions.includes("canCreateTestimonials") ?
            <>
              <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(TestimonialsActions.testimonialsopenModal())} >Testimonial</Button>
              {showtestimonialModal ? <TestimonialModal refreshTestimonialData={() => fechData()} show={showtestimonialModal} onHide={() => dispatch(TestimonialsActions.testimonialscloseModal())} /> : ""}
            </>
            : false}
        </div>
        <div className="demo_table">
          <TestimonialTable/>
        </div>
      </div>
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  gettestimoniallist:state.Testimoniallist.gettestimoniallist,
  showtestimonialModal : state.Testimoniallist.showtestimonialModal,


});


export default connect(mapStateToProps) (Testimonial)
;
