import React, {useEffect, useState, useMemo} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import {getLeadsAnalysis} from "../../store/dashboard";
import {connect}  from "react-redux";

const CardElements = React.memo(({ leadsAnalysis, getLeadsAnalysisData,cardElementsLoader }) => {

    

    var month_start = moment().startOf('month').format('YYYY-MM-DD')
    var month_end = moment().endOf('month').format('YYYY-MM-DD')

    const [filter, setFilter] = useState({start_date:month_start, end_date : month_end});
    useMemo(() => {
        getLeadsAnalysisData(filter);
    }, [filter])

    return (

        
        <>
        

        {cardElementsLoader ? 

            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
        <>
        <div className='card_layout' style={{ marginLeft:'15px', marginTop:'10px'}}>
            <Card >
                <CardContent>
                <div className='row' style={{display:'flex', flexDirection:'row'}}>
                    <div className='col-md-5'><small >Start Date</small></div>
                        <div className='col-md-7'>
                            <input  id="start_date" placeholder="Start Date" name="start_date" value={filter.start_date} onChange={(e) => setFilter({ ...filter, start_date: e.target.value })} type="date" className="form-control" />
                        </div>
                </div>&nbsp;
                <div className='row' style={{display:'flex', flexDirection:'row'}}>
                    <div className='col-md-5'>End Date</div>
                        <div className='col-md-7'>
                            <input placeholder="End Date" name="end_date" value={filter.end_date} onChange={(e) => setFilter({ ...filter, end_date: e.target.value })} type="date" className="form-control" />
                        </div>
                   
                </div>
                </CardContent>
            </Card>
        </div>
        <div className='card-elements'>
        <Card >
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                {leadsAnalysis?.total_leads}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Leads
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card >
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                {leadsAnalysis?.total_prospect}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Prospects
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card >
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                {leadsAnalysis?.total_suspect}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Suspect
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card >
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                {leadsAnalysis?.total_dropped}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Dropped
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.total_converted_to_opportunity}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Opportunities({parseInt(leadsAnalysis?.convertion_rate)+"%"})
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.leadMeetings}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Sales Meetings
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.drf_approvals}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    DRF Approvals
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.closed_tickets}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Tickets
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.demos}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Demos
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.benchmarks}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Benchmarks
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                    {leadsAnalysis?.trainings}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Trainings
                </Typography>
            </CardContent>
        </Card>
    </div>
    <div className='card-elements'>
        <Card >
            <CardContent>
                <Typography gutterBottom variant="h3" component="div">
                {leadsAnalysis?.total_won_opportunity}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                 Won
                </Typography>
            </CardContent>
        </Card>
    </div>
      
       
            </>
        
        }
        </>  
    );

});

// export default CardElements;
const mapStateToProps = (state) => ({
    leadsAnalysis: state.dashboard.leadsAnalysis,
    cardElementsLoader : state.dashboard.cardElementsLoader
});

const mapDispatchToProps = (dispatch) => ({
    getLeadsAnalysisData: (filters) => dispatch(getLeadsAnalysis(filters)),
});


// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(CardElements);