import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { components } from "react-select";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import './chart.css';
import { useSelector, useDispatch, connect } from 'react-redux';
import {getManagers, getInstantReportData } from "../../store/dashboard";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const InstaReport = React.memo(({ instaReportLoader,accountManagersList,instantReport,getInstantReportData, fetchAccountManagersData }) => {
    // const {user} = useSelector(state => state.auth)
    const dispatch = useDispatch();
    // const userData = user.user;
    // const [res, setRes] = useState([]);
    const [filterAccountManager, setFilterAccountManager] = useState([]);
    // const [accountManagers, setAccountManagers] = useState([]);
    const [filter, setFilter] = useState([]);
    const fromDate = moment().startOf('week').format('YYYY-MM-DD');
    const toDate = moment().endOf('week').format('YYYY-MM-DD');
    const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY")}`
      };


    const getData = async () => {

        if(!filter.start_date && !filter.end_date){
            filter.start_date = moment().startOf('week').format('YYYY-MM-DD');
            filter.end_date = moment().endOf('week').format('YYYY-MM-DD');
        }        
        
        var filters ={
            "start_date":filter.start_date,
            "end_date":filter.end_date,
            
        }
        if(Object.keys(filterAccountManager).length>0){
            filters.ac_manager  = filterAccountManager
        }
        getInstantReportData(filters)

        //     var res = await axios.post('user/reports/insta-report', filters);
        //     setRes(res.data.data);
            
        // }else {
        //     const filterss ={
        //         "start_date":filter.start_date,
        //         "end_date":filter.end_date,
             
                
        //     }
        //     var res = await axios.post('user/reports/insta-report', filterss);
        //     setRes(res.data.data);
            
        // }
     
    }

    // useEffect(() => {
    //     dispatch(getManagers());
    // }, [])

    useMemo(() => {
        fetchAccountManagersData();
      }, [fetchAccountManagersData]);

    useMemo(() => {
        getData()
    }, [filter, filterAccountManager])

    return (
        <>
        {instaReportLoader ? 
        
        
            <div className="parent-div">
            <div className="loader"></div>
          </div>
         : <>

        
    
            <div className="container-fluid py-3 px-2">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="row row_gutter">
                            <div className="col-md-2 col-lg-2" >
                                <Select
                                    className="select_value"
                                    classNamePrefix="select"
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={true}
                                    isSearchable="true"
                                    value={filterAccountManager}
                                    options={accountManagersList}
                                    allowSelectAll={false}
                                    components={{
                                        Option
                                    }}
                                    placeholder="Users"
                                    name="filterMonth"
                                    defaultValue="+91"
                                    onChange={value => setFilterAccountManager(value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </div>

                            <div className="col-md-2 col-lg-2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        className="form-control"
                                        label="From Date"
                                        inputVariant="outlined"
                                        value={filter.start_date ? filter.start_date : fromDate}
                                        labelFunc={formatWeekSelectLabel}
                                        onChange={(date)  => setFilter({...filter, start_date: moment(date).format('YYYY-MM-DD')})}
                                        showTodayButton
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="col-md-2 col-lg-2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        className="form-control"
                                        label="To Date"
                                        inputVariant="outlined"
                                        value={filter.end_date ? filter.end_date : toDate}
                                        labelFunc={formatWeekSelectLabel}
                                        onChange={(date)  => setFilter({...filter, end_date: moment(date).format('YYYY-MM-DD')})}
                                        showTodayButton
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <table>
                                <tr>
                                    <th style={{ width: '200px' }}>S. No.</th>
                                    <th style={{ width: '35%' }}>Activities</th>
                                    <th style={{ width: '30%' }}>Frequency</th>
                                    <th style={{ width: '30%' }}>Report</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Discovery calls</td>
                                    <td>4 Per week</td>
                                    <td >
                                        <div className="tooltip1">{instantReport?.disc_cals !==0 ? instantReport?.disc_cals : "No Data"}
                                        {instantReport?.disc_cals !==0 ? <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.disc_cals_data }}></span>  : false}
                                             
                                        </div>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Technical Demo/Technical Presentation</td>
                                    <td>2 Per week</td>
                                    <td >
                                        <div className="tooltip1">{instantReport?.demos !==0 ? instantReport?.demos : "No Data" }
                                        {instantReport?.demos !==0 ?    <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.demos }}></span>   : false}

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Metric for different industries</td>
                                    <td>1 Per week</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.metrics !==0 ? instantReport?.metrics : "No Data" }
                                        {instantReport?.metrics !==0 ?  <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.metric_data }}></span>  : false}
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Services</td>
                                    <td>2 Per month</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.services !==0 ? instantReport?.services : "No Data"}
                                        {instantReport?.services !==0 ? <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.services_data }}></span> : false}
                                            
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Emerging Physics like SPEOS, Lumerical</td>
                                    <td>1 lead per month</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.emerg_prod !==0 ? instantReport?.emerg_prod : "No Data"}
                                        {instantReport?.emerg_prod !==0 ?  <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.emerg_prod_data }}></span> : false}
                                           
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Marketing Activities</td>
                                    <td>1 per quarter</td>
                                    <td>No Data</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>DRF Approved</td>
                                    <td>1 / 5 per week</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.drf_approv !==0 ? instantReport?.drf_approv : "No Data"}
                                        {instantReport?.drf_approv !==0 ? <span className="tooltiptext1" dangerouslySetInnerHTML={{ __html: instantReport?.drf_approv_data }}></span>  : false}
                                              
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Closures for this month</td>
                                    <td>1 per month</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.closures !==0 ? instantReport?.closures : "No Data"}
                                        {instantReport?.closures !==0 ? <span className="tooltiptext1"  dangerouslySetInnerHTML={{ __html: instantReport?.closures_data }}></span> : false}
                                            
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Meetings for this month</td>
                                    <td>20 per month</td>
                                    <td>
                                        <div className="tooltip1">{instantReport?.meetings_this_month !==0 ? instantReport?.meetings_this_month : "No Data"}
                                        {instantReport?.meetings_this_month !==0 ? <span className="tooltiptext1"  dangerouslySetInnerHTML={{ __html: instantReport?.meetings_this_month }}></span> : false}
                                           
                                        </div>
                                    </td>

                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            </>  
        }
        </>
    )

});


const mapStateToProps = (state) => ({
    accountManagersList: state.dashboard.accountManagersList,
    instantReport : state.dashboard.instantReport,
    instaReportLoader:state.dashboard.instaReportLoader
});

const mapDispatchToProps = (dispatch) => ({
    fetchAccountManagersData: () => dispatch(getManagers()),
    getInstantReportData : (data) => dispatch(getInstantReportData(data))
});

// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(InstaReport);