import React, { useEffect, useState } from 'react';
import Navbar from '../home/Navbar'

import TextField from '@material-ui/core/TextField';
import { Button, Modal } from 'react-bootstrap'
import axios from "axios";
import AddRoiCalc from "../../components/pageModals/AddRoiCalc";

import './list.css';
import RoiSheetTable from "../../components/commonColumns/RoiSheetTable";

const RoiList = () => {

  const [data, setData] = useState([])
  const [detail, setDetails] = useState({})
  const [showRoiForm, setShowRoiForm] = useState(false);

  const getData = async() => {
    var res = await axios.get('/user/roi-sheet/list')
    setData(res.data.data)
    //console.log("data data", res.data.data)
  }

  const getId = async(id) => {
    //console.log("getId", id);
    var res = await axios.post('/user/roi-sheet/details', {_id: id})
    setDetails(res.data.data[0])
    //console.log("res ", res.data.data[0]);
  }

  useEffect(async() => {
    getData()
  }, [])

    return (
        <>
        <Navbar value="roilist" className="activity_list_navbar" />
        <div className="container-fluid roi_list_main">
        <div className="main_demo_section d-flex flex-column" id="roicalc">
          {showRoiForm ? <AddRoiCalc refreshTargetData={() => getData()} detail={detail} /> : ""}
          <br />
          
          <div className="demo_table">
            <button className="btn btn-sm btn-primary" onClick={() => setShowRoiForm(!showRoiForm)}>{showRoiForm ? "Close ROI form" : "Add New ROI"}</button>
            <RoiSheetTable data={data} getId={getId} showRoiForm={()=> setShowRoiForm(true)} />
            {/* <SetTargetTable data={data} refreshTargetData={() => fechData()} /> */}
          </div>
        </div>
        </div>
       
      </>
    )
}

export default RoiList;