import React, { useEffect, useState, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddResource from '../pageModals/AddResource';
import AddMetricModal from '../pageModals/AddIndustryMetric';
import { EditorState, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditIcon from '@mui/icons-material/Edit';
import '../../pages/home/home.css';
import { useSelector } from 'react-redux';

const ResourcesTable = (props) => {
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [defaultTableText, setDefaultTableText] = useState(props.progress)
  const [showResourceModal, setShowResourceModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [showMetricModal, setShowMetricModal] = useState(false);
  const html = `<p>  Description...<br /><br /></p>`;
  var contentBlock = htmlToDraft(html);
  var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  var editorStateTe = EditorState.createWithContent(contentState)
  const [editorState, setEditorState] = useState(editorStateTe)
  const mailBody = useRef();

  useEffect(() => {
    setsnackBarMessage("Fetching Records...!");
    setOpen(true);
  }, []);

  const onEditorStateChange = (data) => {
    
    setEditorState(data)
}

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setDefaultTableText(props.progress)
  }, [props.progress])


  useEffect(() => {
  }, [props.data])


  const editResource = (index) => {
    setSelectedResource(props.data[index])
    setShowResourceModal(true)
    
  }

  useEffect(() => {
    if(selectedResource){
      
      contentBlock = htmlToDraft(selectedResource?.description);
      contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorStateTe = EditorState.createWithContent(contentState);
      setEditorState(editorStateTe)
    }

  }, [selectedResource])


  const addNewResource =() => {
    setSelectedResource(null)
    setShowResourceModal(true)
  }

  const addNewMetric = () => {
    setShowMetricModal(true)
  }

  return (
    <>

      <div className='container-fluid'>
          <div className='row'>
              <h3>Resources <button onClick={() => addNewResource()} class="btn btn-sm btn-primary">Add New Resource</button>
              </h3>
          </div>
          <div className='row'>
              <div className='col-md-3 '> 
                  {
                    props.data.map((item, index) => {
                      return  <>
                        <div className="resource-card resourceLink shadow">
                            <a style={{color:'#333',fontWeight:"bold" }}   onClick={() => setSelectedResource(item)}><u>{item.title}</u></a><span  style={{color:'#333'}} className="editIcon" onClick={() => editResource(index)}><EditIcon fontSize="small" /></span><br />
                        </div>
                      </>
                    })
                  }
                  
              </div>
              <div className='col-md-9' style={{borderLeft: '3px solid grey', height:'900px'}}>
                      <h3>{selectedResource && selectedResource.title}</h3>
                      <div className="form-group col-md-12 editor" style={{marginTop: '30px'}} >
                          <div>                          
                          <Editor
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              />
                          </div>
                        </div>
              </div>
          </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {showMetricModal ? <AddMetricModal  show={showMetricModal} refreshTableData={() => props.refreshTableData()} onHide={() => setShowMetricModal(false)} /> : ""}
      {showResourceModal ? <AddResource data={selectedResource} show={showResourceModal} refreshTableData={() => props.refreshTableData()} onHide={() => setShowResourceModal(false)} /> : ""}
    </>
  )
}

export default ResourcesTable;