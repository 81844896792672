import React, { useState, useEffect } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import { Button } from 'react-bootstrap';
import CaseStudyModal from '../../components/pageModals/CaseStudyModal';
import CaseStudyTable from '../../components/commonColumns/CaseStudyTable';
import { useSelector,useDispatch, connect } from 'react-redux';
import {getcasestudiesdata,CasestudyActions} from "../../store/Casestudy"
import { useMemo } from 'react';

const CaseStudy = React.memo (({getcasestudies,casestudymodals,casestudy_openModal,casestudy_closeModal}) => {
  // console.log("casestudy",getcasestudies)
  const dispatch = useDispatch();
  const showLeaveModal = useSelector((state) => state.Casestudylist.showshowLeaveModal);
  const {user} = useSelector(state => state.auth)
  const permissions = user.permissions;

  // const [showLeaveModal, setShowLeaveModal] = useState(false);

  const [baseUrl, setBaseUrl] = useState([]);
 
  const fechData = async () => {
  
    dispatch(getcasestudiesdata());
  }

  useMemo(() => {
    fechData();
  }, [])

  return (
    <>
      <Navbar value="casestudy" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
          {permissions.includes("canCreateCaseStudies") ?
            <>                                                    
              <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => casestudy_openModal()}  >Case Study</Button>
              {casestudymodals ? <CaseStudyModal /> : ""}
            </>
            : false}
        </div>
        <div className="demo_table">
          <CaseStudyTable/>
        </div>
      </div>
    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  casestudymodals:state.Casestudylist.casestudymodal,
  getcasestudies:state.Casestudylist.getcasestudies,
});
const mapDispatchToProps = (dispatch) => ({
  casestudy_openModal:(data) => dispatch(CasestudyActions.casestudymodal_openModal(data)),
  casestudy_closeModal:(data) => dispatch(CasestudyActions.casestudymodal_closeModal(data)),
});


export default connect(mapStateToProps,mapDispatchToProps) (CaseStudy)

