
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showModal_assign: false,
    showModal_activity:false,
    showModal_Viewcontact:false,
    traininglist: [],
    start_traininglist:[],
    completedTrainingSuccesslist:[],
    delete_Demo_RequestSuccesslist:[],
    gettraininglists:[],
    assignedtraining:[],
    trainingListProducts : [],
    trainingListid : null,
    columnsList:[],
}

const TrainingSlice = createSlice({
    name: "Traininglist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },

          assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },
         Viewcontact_openModal : (state, action) => {
          state.showModal_Viewcontact = true; 
        },
        Viewcontact_CloseModal: (state, action) => {
          state.showModal_Viewcontact = false; 
        },
        settrainingRowId(state, action) {
          state.trainingrowId = action.payload;
        },
        setrowMetaData(state, action) {
          state.trainingrowMetadata = action.payload;
        },

        setTrainingListId(state, action) {
          state.trainingListid = action.payload.id;
          state.trainingListProducts = action.payload.products;
        },
       

        TrainingsetRowId(state, action) {
          state.rowId = action.payload;
        },
        TrainingsetrowMetaData(state, action) {
          state.rowMetadata = action.payload;
        },
        



          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          traininglistSuccess(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.traininglist = action.payload;
            // toast.success("Training list data") 
          },
          traininglistFailure(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.traininglist = [];
            toast.error("Error in training list data") 
          },
      
          start_TrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.start_traininglist = action.payload;
            toast.success("start training list") 
          },
          start_TrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.start_traininglist = [];
            toast.error("Error in start training list") 
          },
          completedTrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.completedTrainingSuccesslist = action.payload;
            toast.success(" completedTrainingSuccess list") 
          },
          completedTrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.completedTrainingSuccesslist = [];
            toast.error(" Error in completedTrainingSuccess list") 
          },
          delete_Demo_RequestSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.delete_Demo_RequestSuccesslist = action.payload;
            toast.success("completedTrainingSuccess list") 
          },
          delete_Demo_RequestFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.delete_Demo_RequestSuccesslist = [];
            toast.error(" Error in delete_Demo_RequestSuccesslist") 
          },
          gettrainingColumnsuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.gettraininglists = action.payload;
            // toast.success("Training list data") 
          },
          gettrainingColumnFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.gettraininglists = [];
            // toast.success("Training list data") 
          },
          // asiign training update
          AssigntrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedtraining = action.payload;
            toast.success("Successfully Assigned") 
            state.showModal_assign = false
          },
          AssigntrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedtraining = [];
            toast.error("Error in assign") 
            state.showModal_assign = true
          },
          trainingColumnsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.columnsList = action.payload
        },
        trainingColumnsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.columnsList = []
        },
        updateColumnsList(state, action){
          state.columnsList = action.payload
        }
        

    }  
});
export const TrainingActions = TrainingSlice.actions;

//GET TRAINING LIST START HERE

export const gettraininglist = (postData) => {
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart());
  
      try {
        await Api.post("/trainings/list", postData).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.traininglistSuccess(res?.data?.trainingsList));
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.traininglistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.traininglistFailure("Network Error"));
      }
    };
  };  
  
  export const start_Training = (data) => {
    
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/update-timings", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.start_TrainingSuccess(res.data));
                  dispatch(gettraininglist({type:"all"}))
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.start_TrainingFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.start_TrainingFailure("Network Error"));
      }
    };
  };   
  
  export const completed_Training = (data) => {
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/update-timings", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.completedTrainingSuccess(res.data));
                  dispatch(gettraininglist({type:"all"}))
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.completedTrainingFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.completedTrainingFailure("Network Error"));
      }
    };
  };   
  
  
  export const delete_Demo_Request = (data) => {
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/remove", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.delete_Demo_RequestSuccess(res.data));
            dispatch(gettraininglist({type:'all'}))
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.delete_Demo_RequestFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.delete_Demo_RequestFailure("Network Error"));
      }
    };
  }; 
  
  
  
  
  export const TrainingColumnUpdateddata = (updatedData) => {
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingLoader"));
      try {
        await Api.post("columns-hide-show/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.ContactcolumndataSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.ContactcolumndataFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.ContactcolumndataFailure("Network Error"));
      }
    };
  };
  
   //training column list
  export const gettrainingColumnListHandle = (userid) => {
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingLoader"));
      try {
     
        await Api.get(`/columns-hide-show/list/trainings/${userid}`).then((res) => {
          if (res.data.status === "success") {
            dispatch(TrainingActions.gettrainingColumnsuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.gettrainingColumnFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.gettrainingColumnFailure("Network Error"));
      }
    };
  };
  

  // assign training update
  export const TrainingAssignUpdateddata = (updatedData) => {
  
    return async (dispatch) => {
      dispatch(TrainingActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/trainings/update", updatedData).then((res) => {
          if (res.data.status === "success") {

            dispatch(TrainingActions.AssigntrainingSuccess(res?.data));
                  dispatch(gettraininglist({type:"all"}))
          }
          if (res.data.status === "failure") {
            dispatch(TrainingActions.AssigntrainingFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TrainingActions.AssigntrainingFailure("Network Error"));
      }
    };
  };

  export const getColumnsList = (data) => {
    return async (dispatch) => {
        dispatch(TrainingActions.loaderStart())
        try {
            await Api.get("/columns-hide-show/list/trainings/"+data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(TrainingActions.trainingColumnsListSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(TrainingActions.trainingColumnsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(TrainingActions.trainingColumnsListFailure("Network Error"));
        }
    }
  }

  export default TrainingSlice.reducer;