import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { connect, useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import {CreatedAddcontact,ContactActions,} from "../../store/Contact";
import {getEconomicBuyersList} from "../../store/leads";
import{CommonsliceActions} from "../../store/Common"


  const AddNewContact = React.memo ((props) => {
    // const {openmodal,closemodal,companyName,companyNamecall,contactaccountId,disableRequired,setCompanyName,setAccountId,contactCompanyName,accountId,AccountrowId} = props
    const {openmodal,closemodal,companyName,companyNamecall,contactaccountId,disableRequired,setCompanyName,setAccountId,contactCompanyName,accountId,AccountrowId,otherCompanyName,otherAccountId,keyData,getsetEconomicBuyersList, AddcontactModal, error} = props
// console.log("contactaccountid",accountId)
// console.log("organizationrowid",otherCompanyName)
// console.log("organizationrowid",otherAccountId)
  
const [newCompanyName ,setNewCompanyName] =useState("")
const [newAccountId ,setNewAccountId] =useState(null)
const [showHideSearchField ,setShowHideSearchField] =useState(true)
const[newContactAccountId,setNewContactAccountId] =useState("")
console.log("account id is", newContactAccountId)
useEffect(() => {
if(keyData ==="CommonKeyData"){
  setNewCompanyName(otherCompanyName)
  setNewAccountId(otherAccountId)
  setShowHideSearchField(false)
}else{
  setNewCompanyName(companyName)
  setNewAccountId(accountId)
  setShowHideSearchField(true)
}
},[keyData,otherCompanyName,otherAccountId,companyName,accountId])


// console.log("organizationrowid new",newCompanyName)
// console.log("organizationrowid new",newAccountId)

  const dispatch = useDispatch();
  const [isRequired, setRequired] = useState([disableRequired]);
  const { user } = useSelector((state) => state.auth);
  const userData = user.user;
  const {
    register: contactsRegister,
    handleSubmit: contactshandleSubmit,
    setError: setError,
    formState: { errors: contactsErrors },
    reset: contactsReset,
  } = useForm();
  const [companiesList, setCompaniesList] = useState();
  // const [companyName, setCompanyName] = useState(false);
  // const [accountId, setAccountId] = useState(false);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [ContactsShow, setContactsShow] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const co_company_name = useRef();
  const co_contact_name = useRef();
  const co_email = useRef();
  const co_contact_number = useRef();
  const co_designation = useRef();
  const co_department = useRef();

  const addContactsHandle = async (e) => {
    // setDisable(true);
  
    setTimeout(() => {}, 500);

    const contactsData = {
      user_id: userData._id,
      company_name: newCompanyName ? newCompanyName : contactCompanyName,
      contact_name: co_contact_name.current.value,
      email: co_email.current.value,
      contact_number: co_contact_number.current.value,
      designation: co_designation.current.value,
      department: co_department.current.value,
    };
    if(newAccountId){
      contactsData.account_id = newAccountId
    }
    if(newContactAccountId){
      contactsData.account_id =newContactAccountId
    }

    // console.log("contact details are", contactsData)
    dispatch(CreatedAddcontact(contactsData))
    // if(newAccountId){
      // getsetEconomicBuyersList(newAccountId)
      // dispatch(getEconomicBuyersList(newAccountId))
    // }
    
  }

  useEffect(() => {
    if(!error){
      contactsReset({
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      });
      // setDisable(true);
    }
  }, [error])

  const getCompaniesList = async (search) => {
    setCompaniesList([]);
    const res = await axios.get(
      "/accounts/search/" + userData._id + "/" + search.target.value
    );
    setCompaniesList(res.data.AccountsList);
  };

  const handleSetCompanyName = (value) => {
    // setCompanyName(value.value);
    setCompanyName(value.value)
    if (value.__isNew__ === true) {
    } else {
      // setAccountId(value.data_id);
      setAccountId(value.data_id);
      setNewContactAccountId(value.data_id);
    }
  };



  return (
    <>
      <Modal
        show={AddcontactModal}
        // onHide={() => closemodal()}
        backdrop={false}
        disableBackdrop={true}
        className={modalHide}
        dialogClassName="modal-90w"
        arialabelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Add Contact
          </Modal.Title>
          <a
            onClick={() => closemodal()}
            className="btn btn-sm btn-white btn-danger bottomBarButtons"
          >
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <form key={2} >
            {(showHideSearchField)? (
              <div class="row">
                <div class="col-md-8 col-md-offset-2 ">
                  <CreatableSelect
                    closeMenuOnSelect={true}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable="true"
                    options={companiesList}
                    placeholder="Search Organization Name*"
                    onChange={handleSetCompanyName}
                    onKeyDown={getCompaniesList}
                  ></CreatableSelect>
                  <br />
                  <br />
                </div>
              </div>
            ) : (
              false
            )}

            <div class="row">
              <div class="col-md-6">
                <TextField
                  variant="outlined"
                  inputRef={co_contact_name}
                  {...contactsRegister("co_contact_name", {
                    required: {
                      value: true,
                      message: "Contact Name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Contact Name must be at least 3 characters",
                    },
                  })}
                  helperText={
                    contactsErrors.co_contact_name
                      ? contactsErrors.co_contact_name.message
                      : ""
                  }
                  error={Boolean(contactsErrors.co_contact_name)}
                  label="Contact Name*"
                  type="text"
                  className="form-control"
                  name="co_contact_name"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  inputRef={co_email}
                  {...(isRequired !== false && {
                    ...contactsRegister("co_email", {
                      required: {
                        value: { isRequired },
                        message: "Email is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Email must be at least 3 characters",
                      },
                    }),
                  })}
                  helperText={
                    contactsErrors.co_email
                      ? contactsErrors.co_email.message
                      : ""
                  }
                  error={Boolean(contactsErrors.co_email)}
                  label="Email*"
                  type="email"
                  className="form-control"
                  name="co_email"
                />
              </div>
              <div class="col-md-6">
                <TextField
                  variant="outlined"
                  inputRef={co_contact_number}
                  {...(isRequired !== false && {
                    ...contactsRegister("co_contact_number", {
                      required: {
                        value: true,
                        message: "Contact Number is required",
                      },
                      minLength: {
                        value: 6,
                        message: "Contact Number must be at least 6 characters",
                      },
                      maxLength: {
                        value: 13,
                        message: "Contact Number cannot exceed 13 digits",
                      },
                    }),
                  })}
                  helperText={
                    contactsErrors.co_contact_number
                      ? contactsErrors.co_contact_number.message
                      : ""
                  }
                  error={Boolean(contactsErrors.co_contact_number)}
                  label="Contact Number*"
                  type="number"
                  className="form-control"
                  name="co_contact_number"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  inputRef={co_designation}
                  {...(isRequired !== false && {
                    ...contactsRegister("co_designation", {
                      required: {
                        value: true,
                        message: "Designation is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Designation must be at least 3 characters",
                      },
                    }),
                  })}
                  helperText={
                    contactsErrors.co_designation
                      ? contactsErrors.co_designation.message
                      : ""
                  }
                  error={Boolean(contactsErrors.co_designation)}
                  label="Designation*"
                  name="co_designation"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  inputRef={co_department}
                  {...(isRequired !== false && {
                    ...contactsRegister("co_department", {
                      required: {
                        value: true,
                        message: "Department is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Department must be at least 3 characters",
                      },
                    }),
                  })}
                  helperText={
                    contactsErrors.co_department
                      ? contactsErrors.co_department.message
                      : ""
                  }
                  error={Boolean(contactsErrors.co_department)}
                  label="Department*"
                  type="text"
                  className="form-control"
                  name="co_department"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div>
                <button
                  onClick={contactshandleSubmit(addContactsHandle)}
                  disabled={disable}
                  className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn"
                >
                  Submit
                </button>
                &nbsp;&nbsp;
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      
    </>
  );
});
                
const mapStateToProps = (state) => ({
  user : state.auth.user,
  getindustrylists:state.Industrylist.getindustrylists,
  companyName:state.Contactlist.companyName, 
  accountId:state.Contactlist.accountId,
  AccountrowId:state.Commonlist.rowId, 
  companyNamecall :  state.Commonlist.contactcompanyName,
  disableRequired:state.Contactlist.disableRequired,
  contactCompanyName:state.Contactlist.contactcompanyName,
  contactaccountId:state.Contactlist.contactaccountId,
  AddcontactModal : state.Contactlist.AddcontactModal,
  error : state.Contactlist.error
});

const mapDispatchToProps = (dispatch) => ({
  openmodal :(data) => dispatch(ContactActions.AddContactopenModal(data)),
  closemodal:(data) => dispatch(ContactActions.AddContactcloseModal(data)),
  setCompanyName:(data) => dispatch(ContactActions.setcompanyName(data)),
  setAccountId:(data) => dispatch(ContactActions.setAccountId(data)),
  getsetEconomicBuyersList:(data) => dispatch(getEconomicBuyersList(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (AddNewContact)

