
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    paysliplist:[],
  createdpaysliplist:[],
  Deletedpaysliplist:[],
  columnsList:[]
}

const PayslipSlice = createSlice({
    name: "Paysliplist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          payslipSuccess(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.paysliplist = action.payload;
            // toast.success("payslip list data") 
    
          },
          payslipFailure (state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.paysliplist = [];
            toast.error("Error in payslip list data") 
            
          },
          
          createpayslipSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createdpaysliplist = action.payload;
            toast.success("Successfully created Payslip") 
            state.showModal = false
    
          },
          createpayslipFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createdpaysliplist = [];
            toast.error("Error in payslip ") 
            state.showModal = true
    
    
          },
          DeletepayslipSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.Deletedpaysliplist = action.payload;
            toast.success(" Deleted Payslip") 
            // state.showModal = false
    
          },
          DeletepayslipFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.Deletedpaysliplist = [];
            toast.error("Error in delete") 
            // state.showModal = false
    
          },
          tasksListUpdateSuccess(state, action){
            state.meetingLoader = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        tasksListUpdateFailure(state, action){
            state.meetingLoader = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        columnsListSuccess(state, action){
          state.isFetch = false
          state.error = true
          state.columnsList = action.payload
          state.msg = ""
      },
      columnsListFailure(state, action){
          state.isFetch = false
          state.error = true
          state.columnsList = []
          state.msg = ""
      },
    
          updateColumns(state, action){
            state.columnsList = action.payload
        }

    }  
});
export const PayslipActions = PayslipSlice.actions;

 //PAYSLIP DATA START HERE


 export const updateColumns = (data) => {
  return async (dispatch) => {
      dispatch(PayslipActions.loaderStart())
      try {
          await Api.post("/columns-hide-show/create", data).then((res) => {
                  if (res.data.status === "success") {
                    toast.success("Columns Updated ")
                      dispatch(PayslipActions.tasksListUpdateSuccess("Columns Updated"))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(PayslipActions.tasksListUpdateFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error")
          dispatch(PayslipActions.tasksListUpdateFailure("Network Error"));
      }
  }
}


export const getColumnsList  = () => {
  return async(dispatch) => {
      dispatch(PayslipActions.loaderStart("meetingloader"))

      try {
        var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/payslip/"+ userData?.user?._id).then((res) => {
              if(res.data.status === "success") {
                dispatch(PayslipActions.columnsListSuccess(res.data.columnsList?.columns_list));
                // toast.success(`${activityData.leave_type} Successfully Added...!`)
                  // dispatch(LeaverequestActions.createleaveSuccess(res.data));
                  // dispatch(getleavelist())
              }
              if(res.data.status ==="failure") {
               
                  dispatch(PayslipActions.columnsListFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(PayslipActions.columnsListFailure("Network Error"));
      }
  }
  }

 export const getpayslip = () => {
    return async(dispatch) => {
        dispatch(PayslipActions.loaderStart())
        try {
            await Api.post("/user/payslip/getlist").then((res) => {
                if(res.data.status === "success") {
                    dispatch(PayslipActions.payslipSuccess(res.data));
                }
                if(res.data.status ==="failure") {
                    dispatch(PayslipActions.payslipFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(PayslipActions.payslipFailure("Network Error"));
        }
    }
  }

  
  export const createpayslipdata = (data) => {

    return async(dispatch) => {
        dispatch(PayslipActions.loaderStart("meetingloader"))
        try {
            await Api.post("/user/payslip/create",data).then((res) => {
                if(res.data.status === "success") {
                    dispatch(PayslipActions.createpayslipSuccess(res.data));
                }
                if(res.data.status ==="failure") {
                    dispatch(PayslipActions.createpayslipFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(PayslipActions.createpayslipFailure("Network Error"));
        }
    }
  }

 //delete
 export const Deletepayslipdata = (data) => {

  return async(dispatch) => {
      dispatch(PayslipActions.loaderStart("meetingloader"))
      try {
          await Api.post("/user/payslip/delete",data).then((res) => {
              if(res.data.status === "success") {
                  dispatch(PayslipActions.DeletepayslipSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(PayslipActions.DeletepayslipFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(PayslipActions.DeletepayslipFailure("Network Error"));
      }
  }
}

   



  

  export default PayslipSlice.reducer;