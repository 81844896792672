import React, {useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from 'react-bootstrap';
import Navbar from '../home/Navbar';

import { getRegions, getTechieUsersList, updateTargetsValues, getRegionTargetDetails, getAllSelectedProducts, updateProductTargetsValues, getProductTargetDetails, getAllUsersByRegion } from "../../store/targets";

const ProductTargets = () => {

    const dispatch = useDispatch();
    const {regionsList, techieUsersList, targetDetails,isFetching, msg, allProductsListTechie, productTargetDetails, usersListByRegion}  = useSelector(state => state.targets);
    const [accountManager, setAccountManager] = useState();
    const [techie, setTechie] = useState(); 
    const [category, setCategory] = useState(null);
    const [region, setRegion] = useState(null)
    
    const [year, setYear] = useState(new Date().getFullYear());
    const [updated, setUpdated] = useState(false);
    const [regionsData, setRegionsData] = useState([]);


    console.log("allProductsListTechie", usersListByRegion);

    // useEffect(()=> {
        const [regionTargets, setRegionTargets] = useState(     
          usersListByRegion.reduce((acc, region) => {
            acc[region.value] = {
                q1New : 0,
                q1Renewal : 0,
                q2New: 0,
                q2Renewal : 0,
                q3New: 0,
                q3Renewal: 0,
                q4New: 0,
                q4Renewal: 0,
                totalNew : 0,
                totalRenewal : 0,
                Q1:{new: 0, renewal: 0},
                Q2:{new: 0, renewal: 0},
                Q3:{new: 0, renewal: 0},
                Q4:{new: 0, renewal: 0}
            };
            return acc;
          }, {})
        );
        
        useEffect(() => {
          // console.log("productTargetDetails, usersListByRegion", productTargetDetails, usersListByRegion)
          if(usersListByRegion?.length>0){
            var obj = usersListByRegion.reduce((acc, region) => {
              console.log("region ", region.value)
                acc[region.value] = {
                    q1New : 0,
                    q1Renewal : 0,
                    q2New: 0,
                    q2Renewal : 0,
                    q3New: 0,
                    q3Renewal: 0,
                    q4New: 0,
                    q4Renewal: 0,
                    totalNew : 0,
                    totalRenewal : 0,
                    target:{
                      new: 0,
                      renewal: 0
                    },
                    Q1:{new: 0, renewal: 0},
                    Q2:{new: 0, renewal: 0},
                    Q3:{new: 0, renewal: 0},
                    Q4:{new: 0, renewal: 0}
                };
                return acc;
            }, {});

            // obj["Q1"] = {new:0, renewal:0}
            // obj["Q2"] = {new:0, renewal:0}
            // obj["Q3"] = {new:0, renewal:0}
            // obj["Q4"] = {new:0, renewal:0}
            // obj["target"] = {new:0, renewal:0}
            if(productTargetDetails){
                console.log("productTargetDetails", productTargetDetails)
                Object.keys(productTargetDetails).forEach(element => {
                  var techie = productTargetDetails[element]?.techie
                  console.log("techie ", techie)
                  // obj[element.techie] = element.techie
                  // console.log("element ", productTargetDetails[element])
                  //   obj[element] = productTargetDetails[element]
                  obj[techie] = productTargetDetails[element]?.target
                })
            }
            setRegionTargets(obj)
          }
        }, [productTargetDetails, usersListByRegion])
       
    // }, [regionsList])

      const handleInputChange = (regionValue, field, value) => {
        
        // Split the field into quarter and status
        const quarter = field.substring(0, 2).toUpperCase();
        const status = field.substring(2, 15).toLowerCase();

        // console.log("quarter ", quarter, status)

        setRegionTargets((prevState) => {
          var state = Object.assign({}, prevState)
          var updatedRegionData = {
            ...prevState[regionValue],
            [field]: value,
          };

          let updatedRegion = {...updatedRegionData}

          // console.log("first updatedREgion", updatedRegion)
          // console.log("regionValue, field, value ", regionValue, field, value)

          // Perform operations and update other variables in updatedRegion
          var totalNewVal = parseInt(updatedRegion.q1New || 0) + parseInt(updatedRegion.q2New || 0) + parseInt(updatedRegion.q3New || 0) + parseInt(updatedRegion.q4New || 0);
          var totalRenewalVal = parseInt(updatedRegion.q1Renewal || 0) + parseInt(updatedRegion.q2Renewal || 0) + parseInt(updatedRegion.q3Renewal || 0) + parseInt(updatedRegion.q4Renewal || 0);
          
          // updatedRegion.totalNew = totalNewVal
          // updatedRegion.totalRenewal = totalRenewalVal

          // console.log("updatedRegion before", updatedRegion)

          var targetData = {...updatedRegion['target']}

          // updatedRegion['target'].new = totalNewVal
          // updatedRegion['target'].renewal = totalRenewalVal

          targetData.new = totalNewVal
          targetData.renewal = totalRenewalVal

          updatedRegion.target = targetData


          var sum  = 0
          for(const item in state){
            if (!["Q1", "Q2", "Q3", "Q4", "target"].includes(item)){
              if(state[item]?.hasOwnProperty('totalNew') && state[item][field] && item !== regionValue){
                var v = parseInt(state[item][field])
                if(v>0){
                  sum += v
                }
              }
            }
          }

          console.log("regionTargets[quarter]", quarter, updatedRegion[quarter])
          if(updatedRegion[quarter]){
            var obj = Object.assign({}, updatedRegion[quarter])
          }else{
            var obj = {new: 0, renewal: 0}
          }
          console.log("bojcxcv ", obj)
          sum += parseInt(updatedRegion[field])

          obj[status] = sum
          console.log("after updateing ", obj)

          updatedRegion[quarter] = obj

          // console.log("updated regions ss ", updatedRegion)

          return {
            ...prevState,
            [regionValue]: updatedRegion,
            // [quarter] : obj
          };
        });
      };

      // regionTargets[region.value]?.q1New + regionTargets[region.value]?.q2New + regionTargets[region.value]?.q3New + regionTargets[region.value]?.q4New
      const updateTargets = () => {
        if(!accountManager){
            alert("Please select Account Manager")
            return;
        }else if(!accountManager && !year){
          alert("Please select Account Manager and Year")
          return;
        }
        else if(!year){
          alert("Please select Year")
          return;
        }
        const data = {
            targets: regionTargets,
            manager : accountManager,
            year : year,
            region : region
        }
        dispatch(updateProductTargetsValues(data))
        console.log("regionTargetssdfdsf", regionTargets);
      }
      // techiesList
      useEffect(() => {
        if(accountManager && region && year && !techie){
            dispatch(getAllUsersByRegion(accountManager, region, year))
        }
        else if(accountManager && region && category){
            dispatch(getAllSelectedProducts(techie, accountManager, year, region));
            dispatch(getProductTargetDetails(techie, accountManager, year, region, category));
        }
        else if(accountManager){
            dispatch(getAllSelectedProducts(techie, accountManager, year, region));
            dispatch(getProductTargetDetails(techie, accountManager, year, region, category));
        }
      }, [year, accountManager, techie, region, category]);

      const changeYear = (e) => {
        setYear(e.target.value)
        dispatch(getProductTargetDetails(techie, accountManager, e.target.value, region, category));
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        // setOpen(false);
      };


    useEffect(() => {
        dispatch(getRegions())
        dispatch(getTechieUsersList())
    }, [])


    const onAccountManagerChange = (e) => {
      if(e.target.name === "manager"){
        setAccountManager(e.target.value)
        dispatch(getProductTargetDetails(techie, e.target.value, year, region, category));
      }
      if(e.target.name === "user"){
        setTechie(e.target.value)
      }
      if(e.target.name === "region"){
        setRegion(e.target.value)
        dispatch(getProductTargetDetails(techie, accountManager, year, e.target.value, category));
        dispatch(getAllUsersByRegion(accountManager, e.target.value, year))
      }
      if(e.target.name === "category"){
        setCategory(e.target.value)
      }
      
    }

    function calculateFormattedValue(regionTargets, regionValue) {
      const q1New = parseInt(regionTargets) || 0;
      const q1Renewal = parseInt(regionValue) || 0;
    
      const totalValue = (q1New + q1Renewal) / 10000000;
      const formattedValue = totalValue.toFixed(3) + ' Cr';
    
      return totalValue > 0 ? formattedValue : null;
    }

    useEffect(() => {
        
      if(regionsList?.length>0){
          var regions = [] 
          var reg = []
          regionsList.forEach(item => {
              console.log('item ', item)
              var item1 = item.label?.split(" - ")
              if(!reg.includes(item1[0])){
                  reg.push(item1[0])
                  regions.push({label: item1[0], value: item.value})
              }
              // console.log("item1 ", item1, regions)
          })
          setRegionsData(regions)
      }
  }, [regionsList])


  useEffect(() => {
    console.log("regionTargets", regionTargets)
  }, [regionTargets])

    // 
    return (

        <>
        <Navbar value="productTargets" className="activity_list_navbar" />
        {/* <Navbar value="setTargets" className="activity_list_navbar" /> */}
        <div className="main_demo_section d-flex flex-column">
          <div className="request_teche my-3">
            {/* <>
              <a href="/set-target-tree">
                <Button className="btn-sm navbarAddBtn btn_add_common">Set Target</Button>
              </a>
            </> */}
          </div>
          {/* <form onSubmit={setTargetHandleSubmit(fechData)}> */}
            <div className="container-fluid">
              <div className="row">
                <h4>Product Targets<Button href="/assign-products" className="btn-sm navbarAddBtn btn_add_common">Assign Products</Button><br /></h4><br />
                <br />
                <div class="col-lg-12 col-md-8 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-3 col-3 col-sm-3">
                      <TextField variant="outlined"
                        // {...targetRegister("year", {
                        // })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={targetData.year}
                        select
                        // helperText={targetErrors.year ? targetErrors.year.message : ''}
                        // error={Boolean(targetErrors.year)}
                        onChange={onAccountManagerChange}
                        required
                        type="text" className="form-control" name="manager" label="Select Manager"
                      >

                        {techieUsersList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                        }
                      </TextField>

                    
                    </div>

                    <div className="col-md-3 col-3 col-sm-3">
                      <TextField variant="outlined"
                        // {...targetRegister("year", {
                        // })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={targetData.year}
                        select
                        // helperText={targetErrors.year ? targetErrors.year.message : ''}
                        // error={Boolean(targetErrors.year)}
                        onChange={onAccountManagerChange}
                        required
                        type="text" className="form-control" name="region" label="Select Region"
                      >

                        {regionsData.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                        }
                      </TextField>

                    
                    </div>

                    {/* <div className="col-md-2 col-sm-2 col-2">

                      <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                    </div> */}

                    {/* <div className="col-md-3 col-3 col-sm-3">
                      <TextField variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        select
                        onChange={onAccountManagerChange}
                        required
                        type="text" className="form-control" name="user" label="Select Techie"
                      >
                        {usersListByRegion.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                        }
                      </TextField>
                    </div> */}


                    <div className="col-md-3 col-3 col-sm-3">
                      <TextField variant="outlined"
                        // {...targetRegister("year", {
                        // })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={targetData.year}
                        defaultValue={new Date().getFullYear()}
                        select
                        // helperText={targetErrors.year ? targetErrors.year.message : ''}
                        // error={Boolean(targetErrors.year)}
                        onChange={changeYear}
                        required
                        type="text" className="form-control" name="year" label="Select Year"
                      >
                        {["2023", "2024", "2025"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))
                        }
                      </TextField>
                    </div>




                   

                  </div>
                </div>

                <div class="col-lg-5 col-md-8 col-sm-12 col-12">
                  <div className="row">
                    
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div className="container-fluid">
              <div className="row">
                <br />
                <div>
                <table>
                    <thead>
                        <tr>
                        <th>Category</th>
                        <th colSpan={3}>Quarter 1</th>
                        <th colSpan={3}>Quarter 2</th>
                        <th colSpan={3}>Quarter 3</th>
                        <th colSpan={3}>Quarter 4</th>
                        <th colSpan={4}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td></td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        </tr>
                        <tr>
                        <td>Total</td>
                        {["Q1", "Q2", "Q3", "Q4", "target"].map((region) => (
                            <>
                            
                            <td>
                                <input
                                    type="number"
                                    style={{width:'90px'}}
                                    value={regionTargets[region]?.new}
                                    disabled
                                    onChange={(e) =>
                                    handleInputChange(region, "new", e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    style={{width:'90px'}}
                                    value={regionTargets[region]?.renewal}
                                    disabled
                                    onChange={(e) =>
                                    handleInputChange(region, "renewal", e.target.value)
                                    }
                                />
                            </td>

                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region]?.new, regionTargets[region]?.renewel)}</td>
                            </>
                        ))}
                        </tr>
                        {usersListByRegion.map((region) => (
                        <tr key={region.value}>
                            <td>{region.label}</td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q1New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q1New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q1Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q1Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q1New, regionTargets[region.value]?.q1Renewal)}</td>
                            <td>
                            <input
                                style={{width:'90px'}}
                                type="number"
                                value={regionTargets[region.value]?.q2New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q2New", e.target.value)
                                }
                            />
                            </td>
                            <td >
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q2Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q2Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q2New, regionTargets[region.value]?.q2Renewal)}</td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q3New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q3New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q3Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q3Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q3New, regionTargets[region.value]?.q3Renewal)}</td>
                            <td>  
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q4New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q4New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q4Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q4Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q4New, regionTargets[region.value]?.q4Renewal)}</td>
                            <td>
                            <input
                                type="number"
                                disabled={true}
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.totalNew}
                                onChange={(e) =>
                                handleInputChange(region.value, "totalNew", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                disabled={true}
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.totalRenewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "totalRenewal", e.target.value)
                                }
                            />
                            </td>
                            {calculateFormattedValue(regionTargets[region.value]?.totalNew, regionTargets[region.value]?.totalRenewal)}
                            {/* <td style={{ width: '70px' }}>{((parseInt() + parseInt())/10000000).toFixed(1)+' Cr'}</td> */}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>

            <div className="container">
                <br />
                <div className="row col-md-10">
                    <button className="btn btn-sm btn-primary" onClick={() => updateTargets()}>Submit</button>
                </div>
            </div>
  
            {/* <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isFetching}
                autoHideDuration={2000}
                onClose={handleClose}
                message={msg}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            /> */}
          {/* </form> */}
          <br />
        </div>
      </>
    )

}

export default ProductTargets;