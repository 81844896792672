import { createSlice } from "@reduxjs/toolkit";
import Api  from './axios';
import { toast } from 'react-toastify';
import districtsList from "../components/json/districtsList";
var userObj = JSON.parse(localStorage.getItem("user")) || null

const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,
    leadsList: [],
    leadsListCount: 0,
    page: 0,
    rowsPerPage: 20,
    columnsList: [],
    msg: "",
    subPartnersList: [],
    showUpdateLeadModal: false,
    drfStatusList : [],
    accountManagersList: [],
    showAddNewLeadModal : false,
    companiesList : [],
    districtsData: [],
    viewRowMeta : null,
    viewRowId: null,
    viewAccountId : null,
    leadDetails : [],
    viewLeadDetails : {},
    viewMeetings : [],
    viewTasksList : [],
    economicBuyerList : [],
    industryMetricList : [],
    leadlistLoader : false,
    // showViewLeadModal : false,
    companyContactsList : [],
    tempLeadsList : [],
    tempColumnsList: [],
    leadAlreadyExist: false
}

const leadsSlice = createSlice({
    name: 'leads',
    initialState: intialAuthState,
    reducers: {
        loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
        },
        loaderStop(state,action){
            state.isFetching = false;
            state.msg = "Fetching...";
        },

        leadsListSuccess(state, action) {
            state.leadlistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.leadsList = action.payload;
            // toast.success("Organization list data") 
          },
          leadslistFailure(state, action) {
            state.leadlistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.leadsList = [];
            toast.error("Error in lead list") 
          },
        
        // leadsListSuccess(state, action) { 
        //     state.isFetching = false
        //     state.error = false
        //     state.errorPayload = false 
        //     state.leadsList = action.payload.leads
        //     state.leadsListCount = action.payload.totalCount
        // },
        // leadslistFailure(state, action){
        //     state.isFetching = false
        //     state.error = true
        //     state.errorPayload = action.payload
        //     state.leadsList = []
        //     state.leadsListCount = 0
        // },
        leadsListColumnsSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.columnsList = action.payload
        },
        leadslistColumnsFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.columnsList = []
        },
        leadsColumnUpdateSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        leadsColumnsUpdateFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        subPartnersListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.subPartnersList = action.payload
        },
        subPartnersListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.subPartnersList = []
        },
        cancelLeadApprovalSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        cancelLeadApprovalFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        leadApprovalSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        leadApprovalFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        updateMeddicCatValSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        updateMeddicCatValFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        updateKey(state, action){
            state[action.payload.key] = action.payload.value;
        },
        drfStatusListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.drfStatusList = action.payload
        },
        drfStatusListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.drfStatusList = []
        },
        leadStagesListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.existingLeadStages = action.payload?.forExistingLeads
            state.notExistingLeadStages = action.payload?.forNotExistingLeads
        },
        leadStagesListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.existingLeadStages = []
            state.notExistingLeadStages = []
        },
        industriesListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.industriesList = action.payload
        },
        industriesListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.industriesList = []
        },
        annualRevenueRangeListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.annualRevenueRangeList = action.payload
        },
        annualRevenueRangeListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.annualRevenueRangeList = []
        },
        accountManagersListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.accountManagersList = action.payload
        },
        accountManagersListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.accountManagersList = []
        },
        updateShowAddNewModal(state, action){
            state.showAddNewLeadModal = action.payload
        },
        addNewLeadSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        addNewLeadFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        companiesListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.companiesList = action.payload
        },
        companiesListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.companiesList = []
        },
        contactsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.companyContactsList = action.payload
        },
        contactsListFailure(state, action){
            state.isFetch = false
            state.error = action.payload
            state.errorPayload = false
            state.companyContactsList = []
        },
        updateDistrictsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.districtsData = action.payload
        },
        updateViewRowDetailsData(state, action){
            state.viewRowMeta = action.payload.meta
            state.viewRowId = action.payload.rowId  
            state.viewAccountId = action.payload.accountId
        },
        leadDetailsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.viewLeadDetails = action.payload?.leadDetail
            state.viewMeetings = action.payload?.meetings
            state.viewTasksList = action.payload?.tasksList
        },
        leadDetailsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.leadDetails = {}
        },
        economicBuyerListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.economicBuyerList = action.payload
        },
        economicBuyerListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.economicBuyerList = []
        },
        industryMetricListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.industryMetricList = action.payload
        },
        industryMetricListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.industryMetricList = []
        },
        updateCloseViewLeadModal(state, action){
            state.showUpdateLeadModal = action.payload
        },
        isalesMeetingFixSucess : (state,action) => {
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.showUpdateLeadModal = false
            toast.success(action.payload)
        },
        addnewLeadTaskModalSuccess: (state,action) => {
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.showUpdateLeadModal = false
            toast.success(action.payload)
        },
        viewLeadmodal_openModal: (state, action) => {
            state.showUpdateLeadModal = true; 
        },
        viewLeadmodal_closeModal: (state, action) => {
            state.showUpdateLeadModal = false; 
        },
        updateColumnsList(state, action){
            state.columnsList = action.payload
        },
        updateTempColumnsList(state, action){
            state.tempColumnsList = action.payload;
        },
        tempLeadsListSuccess(state, action) {
            state.leadlistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.tempLeadsList = action.payload;
            // toast.success("Organization list data") 
        },
        tempLeadslistFailure(state, action) {
            state.leadlistLoader = false;
            state.error = true;
            state.errorPayload = null;
            state.tempLeadsList = [];
            // toast.error("Error in temp lead list") 
        },
        tempLeadsListColumnsSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.tempColumnsList = action.payload
        },
        tempLeadsColumnsFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.tempColumnsList = []
        },
        updateShowDialogWindow(state, action){
            state.leadAlreadyExist = action.payload
        }
    }
})

export const leadsActions = leadsSlice.actions;


export const getLeadsList =  (page=0, rowsPerPage=20) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart("leadlistLoader"))
        try {
            await Api.get("/leads/" + "pagination/" + page + "/" + rowsPerPage).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.leadsListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadslistFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.leadslistFailure("Network Error"));
        }
    }
  };
  
export const getColumnsListData = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/columns-hide-show/list/leads/" + data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.leadsListColumnsSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadslistColumnsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.leadslistColumnsFailure("Network Error"));
        }
    }
}

export const updateColumnsList = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("columns-hide-show/create", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Columns Updated!")
                        dispatch(leadsActions.leadsColumnUpdateSuccess("Successfully Updated!"))
                        if(data.table_name === "templeads"){
                            dispatch(leadsActions.updateTempColumnsList(data.columns_list))
                        }else{
                            dispatch(leadsActions.updateColumnsList(data.columns_list))
                        }
                        
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadsColumnsUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.leadsColumnsUpdateFailure("Network Error"));
        }
    }
}

export const subPartnersList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/sub-partners/list").then((res) => {
                    if (res.data.status === "success") {
                        
                        dispatch(leadsActions.subPartnersListSuccess(res.data.subPartnersList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.subPartnersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.subPartnersListFailure("Network Error"));
        }
    }
}

export const cancelLeadApproval = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("/leads/cancel/approval", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Lead approval cancelled")
                        dispatch(leadsActions.cancelLeadApprovalSuccess("Lead approval cancelled"))
                        dispatch(getLeadsList());
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.cancelLeadApprovalFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.cancelLeadApprovalFailure("Network Error"));
        }
    }
}

export const approveLeads = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("/leads/approve", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Lead approved successfully!")
                        dispatch(leadsActions.leadApprovalSuccess("Lead approved successfully!"))
                        dispatch(getLeadsList());
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadApprovalFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.leadApprovalFailure("Network Error"));
        }
    }
}

export const updateMeddicValue = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("/leads/update-meddic", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("MEDDIC updated successfully!")
                        dispatch(leadsActions.updateMeddicCatValSuccess("Lead approved successfully!"))
                        dispatch(getLeadsList());
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.updateMeddicCatValFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.updateMeddicCatValFailure("Network Error"));
        }
    }
}

export const getDrfStatusList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/drf-status/search").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.drfStatusListSuccess(res.data.drfStatusList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.drfStatusListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.drfStatusListFailure("Network Error"));
        }
    }
}

export const updateKey =  (key, value) => {
    return async(dispatch) => {
        dispatch(leadsActions.updateKey({key:key, value:value}));
    }
}

export const getLeadStages = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart(""))
        try {
            await Api.get("/leads/stages/leads").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.leadStagesListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(leadsActions.leadStagesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(leadsActions.leadStagesListFailure("Network Error"));
        }
    }
}

export const getIndustriesList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart(""))
        try {
            await Api.get("/industries/search").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.industriesListSuccess(res.data.industriesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(leadsActions.industriesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(leadsActions.industriesListFailure("Network Error"));
        }
    }
}

export const getAnnualRevenueRangeList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart(""))
        try {
            await Api.get("/annual-revenue-range/search").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.annualRevenueRangeListSuccess(res.data.annualRevenueRange))
                    }
                    if (res.data.status === "failure") {
                        dispatch(leadsActions.annualRevenueRangeListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(leadsActions.annualRevenueRangeListFailure("Network Error"));
        }
    }
}

export const getAccontManagersList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart(""))
        try {
            await Api.get("/user/account-managers-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.accountManagersListSuccess(res.data.usersList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(leadsActions.accountManagersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(leadsActions.accountManagersListFailure("Network Error"));
        }
    }
}

export const addNewLeadData = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("leads/create", data).then((res) => {
                    // if(res.data.status === "account_exist"){
                    //     // console.log("account exist ")
                    //     // alert("already exist")

                    //         const result = window.confirm(`Account already exist Do you want to create new account
                    //         ? or Proceed with existing account. <br />
                    //         Click yes to create new account or Cancel to continue with same account`);

                    //         if (result) {
                    //             // fsdfs
                    //             data.create_new_account = true
                    //             console.log("data lead ", data)
                    //             dispatch(addNewLeadData(data))
                    //         } else {
                    //             data.create_new_account = false
                    //             console.log("data lead ", data)
                    //             dispatch(addNewLeadData(data))
                                
                    //         }
                        
                    // }
                    // else 
                    if(res.data?.already_exist === true){
                        dispatch(leadsActions.updateShowDialogWindow(true))
                        dispatch(leadsActions.updateShowAddNewModal(false))
                    }else if (res.data.status === "success") {
                        toast.success("Lead added successfully!")
                        dispatch(leadsActions.addNewLeadSuccess(res.data))
                        dispatch(leadsActions.updateShowAddNewModal(false))
                    }
                    else if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.addNewLeadFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            const networkErrorMsg = err.response ? err.response.data.msg : "Network Error";
            dispatch(leadsActions.addNewLeadFailure())
            toast.error(networkErrorMsg)
            // toast.error("Network Error")
            // dispatch(leadsActions.addNewLeadFailure("Network Error"))
        }
    }
}

export const getCompaniesList = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/accounts/search/" + data?.user_id + "/" + data?.value).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.companiesListSuccess(res.data.AccountsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.companiesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.companiesListFailure("Network Error"))
        }
    }
}

export const getPersonsList = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/contacts/search/"+data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.contactsListSuccess(res.data.contacts))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.contactsListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.contactsListFailure("Network Error"))
        }
    }
}

export const getDistrictsList = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            dispatch(leadsActions.updateDistrictsListSuccess(districtsList[data]))
        }catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.updateDistrictsListFailure("Network Error"))
        }
    }
}

export const getEconomicBuyersList = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/demos/economic-buyer-list/" + data).then((res) => {
                    if (res.data.status === "success") {
                        var da = res.data.economicBuyerList
                        // da.push({ label: "Add New Contact", value: "" })
                        dispatch(leadsActions.economicBuyerListSuccess(da))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.economicBuyerListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.economicBuyerListFailure("Network Error"))
        }
    }
}

export const updateViewRowDetails = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        if(data.accountId){
            dispatch(getEconomicBuyersList(data.accountId))
            dispatch(getPersonsList(data.accountId))
        }else{
            dispatch(getEconomicBuyersList(data.companyName))
            dispatch(getPersonsList(data.companyName))
        }
        dispatch(leadsActions.updateViewRowDetailsData(data))
        try {
            await Api.get("/leads/unique/details/"+data.rowId).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.leadDetailsListSuccess(res.data))
                        
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadDetailsListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.leadDetailsListFailure("Network Error"))
        }
    }
}

export const getIndustryMetricsList = () => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/user/resources/industry-metrics/list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.industryMetricListSuccess(res.data.metricsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.industryMetricListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            dispatch(leadsActions.industryMetricListFailure("Network Error"))
        }
    }
}

export const meddicUpdate = (type, url, data) => {

    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post(url, data).then((res) => {
                    if (res.data.status === "success") {
                        if(type === "save"){
                            toast.success("MEDDIC Updated")
                        }else{
                            toast.success("Submitted for approval!")
                        }
                        dispatch(leadsActions.updateCloseViewLeadModal(false))
                        dispatch(getLeadsList())
                        // leadsActions.showUpdateLeadModal(false)
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            toast.error("Network Error")
        }
    }
}

export const addNewMeeting = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("leads/add-new-meeting", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Added Meetings")
                        leadsActions.updateCloseViewLeadModal(false)
                        dispatch(getLeadsList())
                        // dispatch(leadsActions.industryMetricListSuccess(res.data.metricsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        // dispatch(leadsActions.industryMetricListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            // dispatch(leadsActions.industryMetricListFailure("Network Error"))
        }
    }
}

export const addNewLeadTask = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("leads/add-lead-task", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.addnewLeadTaskModalSuccess(res.data.msg))
                        dispatch(getLeadsList())
                        // dispatch(leadsActions.industryMetricListSuccess(res.data.metricsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        // dispatch(leadsActions.industryMetricListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            // dispatch(leadsActions.industryMetricListFailure("Network Error"))
        }
    }
}

export const isalesFixNewMeetingDetails = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("leads/isales-fix-new-meeting", data).then((res) => {
                    if (res.data.status === "success") {
                        // toast.success('Assigned Lead Successfully!.')
                        dispatch(leadsActions.isalesMeetingFixSucess(res.data.msg))
                        dispatch(getLeadsList())
                        // dispatch(leadsActions.industryMetricListSuccess(res.data.metricsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        // dispatch(leadsActions.industryMetricListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            // dispatch(leadsActions.industryMetricListFailure("Network Error"))
        }
    }
}

export const updateOrganizationDetailsUpdated = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("leads/update-organization-details", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success('Organization details updated Successfully!.')
                        dispatch(leadsActions.updateCloseViewLeadModal(false))
                        dispatch(getLeadsList())
                        // dispatch(leadsActions.industryMetricListSuccess(res.data.metricsList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        // dispatch(leadsActions.industryMetricListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error(err)
            // dispatch(leadsActions.industryMetricListFailure("Network Error"))
        }
    }
}

export const getTempLeadsList =  () => {
    return async (dispatch) => {
        // dispatch(leadsActions.loaderStart("templeadlistLoader"))
        try {
            await Api.post("/leads/temp-leads").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.tempLeadsListSuccess(res.data))
                     
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.tempLeadslistFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error(err)
            dispatch(leadsActions.tempLeadslistFailure(err));
        }
    }
  };

export const approveTempLeads = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.post("/leads/approve-temp-lead", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success(res.data.msg)
                        dispatch(leadsActions.leadApprovalSuccess(res.data.msg))
                        dispatch(getTempLeadsList());
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.leadApprovalFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(leadsActions.leadApprovalFailure("Network Error"));
        }
    }
}

export const getTempColumnsListData = (data) => {
    return async (dispatch) => {
        dispatch(leadsActions.loaderStart())
        try {
            await Api.get("/columns-hide-show/list/templeads/" + data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(leadsActions.tempLeadsListColumnsSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(leadsActions.tempLeadsColumnsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error(err)
            dispatch(leadsActions.tempLeadsColumnsFailure(err));
        }
    }
}

export default leadsSlice.reducer;