import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from 'react-redux';
import {HardwareAssign,getuserslistHardware,HardwareActions} from "../../store/Hardware"

const  HardwareAssignModal = React.memo(({user,hardwarelist,assignhardware,getuserslistHandle,getuser,metaid,openshowModal,closemodal}) => {

  const dispatch = useDispatch();
const userList =  getuser?.usersList
console.log("userlist",userList)
// const[userList,setUserList]= useState([getuser.usersList])
const { register: hardwareAssignRegister, handleSubmit: hardwarehandleSubmit, setError: setError, formState: { errors: hardwareErrors }, reset: contactsReset } = useForm();

  const[assignmodal,setAssigmodal] = useState({
    hardwareId:"",
    user_id:"",
  })

 
 const onInputchange = (e) =>{ 
  setAssigmodal({...assignmodal,[e.target.name] :e.target.value},[])
  }
  const assignsubmit = async() => {  

    const  assignmodaldata = {
      hardwareId :metaid,
      user_id  :assignmodal.user_id,
        }
        assignhardware(assignmodaldata)
   
  
  }
 

useEffect(() => {
  getuserslistHandle();
}, []);

  return (
    <>
      <Modal
        dialogClassName="modals"
        aria-labelledby="example-custom-modal-styling-title"
        show={() => openshowModal()}
        onHide={() => closemodal()}
        backdrop={false}
        disableBackdrop={true}
      >
        <Modal.Header>
          <Modal.Title>
          <h5>   Assign Modal </h5>
          </Modal.Title>
       <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        
          <Modal.Body>
          <form onSubmit={hardwarehandleSubmit(assignsubmit)}>
              <div className="row">
              <div className="col-md-6">
              <TextField variant="outlined"
                  {...hardwareAssignRegister("user_id", {
                      required: {
                          value: true,
                          message: "user id is required"
                      },
                  })}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  value={assignmodal.user_id}
                  select
                  helperText={hardwareErrors.user_id ? hardwareErrors.user_id.message : ''}
                  error={Boolean(hardwareErrors.user_id)}
                  onChange={e => onInputchange(e)}
                  type="text" className="form-control" name="user_id" label="Assign to">
                  {userList?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                  ))}
              </TextField>


              </div>
              </div>

            <div className="row">
              <div>
              <button className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn" type="submit"> Submit</button>
              </div>
            </div>
            </form>
          </Modal.Body>
      </Modal>
  
    </>
  );

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  getuser :state.Hardwarelist.getuserlistsHardware,
  metaid : state.Hardwarelist.assignmetaid
  
});

const mapDispatchToProps = (dispatch) => ({

  assignhardware : (data) => dispatch(HardwareAssign(data)),
  getuserslistHandle:() =>  dispatch(getuserslistHardware()),
  openshowModal:(data) => dispatch(HardwareActions.assign_openModal(data)),
  closemodal:(data) => dispatch(HardwareActions.assign_closeModal(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(HardwareAssignModal)