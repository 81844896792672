import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import { EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const SendTestMail = (props) => {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm(); 
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const description = useRef();
    const html = `<p>  Description...
    <br /><br />
    </p>`;
    const contentBlock = htmlToDraft(props.data ? props.data.description : html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();
    const [email, setEmail] = useState()
 
     useEffect(() => {
         //console.log("props data ", props)
     }, [props.data])
     
     const onEditorStateChange = (data) => {
         //console.log("editor data", data)
         setEditorState(data)
     }
 
     const onInputChange = (e) => {
         setEmail(e.target.value)
     }
     const sendTestMailSubmit = async (e) => {
         setDisable(true);
         setSnackBarMessage("Uploading Data ...!");
         setOpen(true);
 
         setTimeout(() => { }, 500);
         const inputData = {
             template_id : props.template_id,
             event_type : props.emailCampaignData?.event_type,
             email : email
         };
         try {
             var res;
                 res = await axios.post("/digital-marketing/test-mail", inputData);
 
             if (res.data.status === "success") {
                 setTimeout(() => {
                     setOpen(true);
                     setSnackBarMessage("Test Mail Successfully Sent...!");
                  }, 1000);
               
                  toast.success("Test Mail Successfully Sent...!") 
                 setTimeout(() => {
                    props.onHide();
                 }, 5000)
                 setContactsShow(false);
 
                 contactsReset({
                     keepErrors: false,
                     keepDirty: true,
                     keepIsSubmitted: false,
                     keepTouched: false,
                     keepIsValid: false,
                     keepSubmitCount: false,
                 })
                 setDisable(true);
             } else {
                 setTimeout(() => {
                     setOpen(true);
                     setSnackBarMessage(res.data.msg);
                     toast.success(res.data.msg) 
                     props.onHide();
                 }, 2000);
                 setContactsShow(true);
                 setDisable(false);
             }
         } catch (err) {
             setDisable(false);
             alert(err);
         }
 
     }
 
     const handleClose = (event, reason) => {
         setAnchorEl(false);
 
         if (reason === 'clickaway') {
             return;
         }
         setOpen(false);
     };
 
     return (
         <>
             < Modal
                 show={props.show}
                 onHide={() => setContactsShow(false)}
                 backdrop={false}
                 disableBackdrop={true}
                 className={modalHide}
                 dialogClassName="modal-90w"
                 arialabelledby="example-custom-modal-styling-title">
                 <Modal.Header >
                     <Modal.Title id="example-custom-modal-styling-title">
                         Test Email Campaign
                     </Modal.Title>
                     <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                 </Modal.Header>
                 <Modal.Body>
                     <form key={2} onSubmit={contactshandleSubmit(sendTestMailSubmit)}>
 
                         <div class="row">
                             <div class="col-md-10">
                                 <TextField variant="outlined"
                                     value ={email}
                                     onChange={e => onInputChange(e)}
                                     helperText={contactsErrors.email ? contactsErrors.email.message : ''}
                                     error={Boolean(contactsErrors.email)}
                                     label="Email"
                                     required
                                     type="email" className="form-control" name="email" />
                             </div>
                         </div>
                         <br />
                         <div className="row">
                             <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                         </div>
                     </form>
                 </Modal.Body>
             </Modal >
             <Snackbar
                 severity="success"
 
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
                 open={open}
                 autoHideDuration={2000}
                 onClose={handleClose}
                 message={snackBarMessage}
                 action={
                     <React.Fragment>
                         <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                         </ButtonMaterialUi>
                         <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                             <CloseIcon fontSize="small" />
                         </IconButton>
                     </React.Fragment>
                 }
             />
         </>
     );



}


export default SendTestMail;