import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import AssignOpportunitiesUserModal from '../pageModals/AssingOpportunitiesUserModal';
import AddLicenseModal from '../pageModals/AddLicenseModal';
import ViewOpportunities from '../../components/pageModals/ViewOpportunities';
import { getMuiTheme } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import CustomFooter from './CustomFooter';
import { useSelector, connect } from 'react-redux';
import WonOpportunitiesPoModal from '../pageModals/WonOpportunitiesPoModal';

import {getOpportunitiesList,getTechieOpportunitiesList,opportunitiesActions, getColumnsList, updateColumns, updateViewRowDetails, getTechiesListByManager} from "../../store/opportunities";

const OpportunitiesTable = React.memo((props) => {
  const {
    updateViewRowData,
    opportunitiesList,
    techieOpportunitiesList,
    columnsList,
    getColumnsData,
    getOpportunitiesData,
    getTechieOpportunitiesListHand,
    updateColumnsData,
    isFetching,
    opportuntieslistLoader,
    oppportunityType,
    setRowId,
    regionChartTableData,
    salesStageChartTableData,
    ForecastSalesChartTableData,
    viewAccountOPPData,
    StateWiseRevenueData,
    keyData,
    updateOpportunityModalData,
    createNewOpportunityData,
    opportunitiefilteredYears,
    getTechiesListByManagerData
  } = props;  

  const [tableData, setTableData] = useState([]);
  const [filteredDataOrg, setFilteredData] = useState([]);
  const [opportunityTypeActive, setOpportunityTypeActive] = useState("Open");
  const [showOpportunitiesModal, setShowOpportunitiesModal] = useState(false);
  const [showAssigniSales, setShowAssigniSales] = useState(false);
  const {user} = useSelector(state => state.auth)
  const [filteredYear, setFilteredYear] = useState(moment().format('YYYY'));
  const userData = user.user;
  const permissions = user.permissions;
  // const [footerCallback, setFooterCallback] = useState(() => () => null);
  const [filtersChange, setFiltersChange] = useState()
  // const [previousData, setPreviousData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
const [opportunitesId, setOpportunitesId] =useState(null)
// const [showReassignTechie, setReassignTechie] = useState(false)
  var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

  const [showWonPoModal, setShowWonPoModal] = useState(false);

  const onCellClickHandler = (rowMeta) => {

    
    if (rowMeta.colIndex == [28]) {
      
      return;
    }

    createNewOpportunityData(false);
    setShowOpportunitiesModal(true);
    // setRowMetaData(rowMeta);
    setRowId(tableData[rowMeta.dataIndex]._id)
    // const data = {
    //   year:
    // }
    updateViewRowData({meta: rowMeta, rowId:tableData[rowMeta.dataIndex]._id, accountId:tableData[rowMeta.dataIndex].account_id })
  };

  useEffect(() => {
    // getOpportunitiesData();
    // getDrfStatusData();
    // getAnsysAccountManagersData();
    // getRegionsData();
    // getBusinessTypesData();
     getColumnsData();
    // getProductsData();
    getTechiesListByManagerData({managerId : userData._id});
  }, [])

  const filterYear = (e) => {
    var data = [];
    if (opportunityTypeActive === "Open") {

      if (e.target.value !== "all") {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name != "Won" && item.sales_stage?.name != "Lost" && item.timeline_year === e.target.value) {
            return item;
          }
        });
      } else {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name != "Won" && item.sales_stage?.name != "Lost") {
            return item;
          }
        });
      }
    }
    else {
      if (e.target.value !== "all") {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name === opportunityTypeActive && item.timeline_year === e.target.value) {
            return item;
          }
        });
      } else {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name === opportunityTypeActive) {
            return item;
          }
        });
      }

    }
    opportunitiefilteredYears(e.target.value)
    setFilteredYear(e.target.value);

    setTableData(data);
    setFilteredData(data);
  }
  
  useEffect(() => {
    var listArray = []
    //("keyData ", keyData)
    if(keyData === "regionChart"){
      listArray = regionChartTableData
    }else if(keyData === "salesStageChart"){
      listArray = salesStageChartTableData
    }else if(keyData === "forecastSalesChart"){
      listArray = ForecastSalesChartTableData
    }else if(keyData === "viewAccountOpportunitiesKey"){
      listArray = viewAccountOPPData
    }else if(keyData === "statewiseRenenue"){
      listArray = StateWiseRevenueData
    }else{
      setShowOpportunitiesModal(false)
      listArray = opportunitiesList?.length> 0 ? opportunitiesList : techieOpportunitiesList
    }
    setAllTableData(listArray)

    // var datas = (listArray)  =>{
    var datas = listArray?.filter(function (item) {
          if (item.sales_stage?.name === opportunityTypeActive && item.timeline_year === filteredYear) {
            return item;
          }
          else if (item.sales_stage?.name != ("Lost") && item.sales_stage?.name != ("Won") && item.timeline_year === filteredYear) {
            return item;
          }
      });
    // }
    // var results = datas(listArray) 
    // //("resultslength ", results.length, opportunityTypeActive);
    // if (datas?.length === 0  && opportunityTypeActive === "Open") {
    //   setOpportunityTypeActive("Won")
    // }

    setTableData(datas);
    setFilteredData(datas);

  }, [keyData,opportunitiesList,techieOpportunitiesList, ForecastSalesChartTableData, regionChartTableData, salesStageChartTableData,viewAccountOPPData,StateWiseRevenueData]);

  const filterLeadType = async (type) => {

    var noFiltersSelected = filtersChange?.some(columnFilters => columnFilters.length > 0);
    // //("no filters selected ", noFiltersSelected, filtersChange)
    // opportunitiesList
    //("noFiltersSelected", noFiltersSelected)
    setOpportunityTypeActive(type);
    var data = [];
    if (type === "Open") {
      if (filteredYear !== "all") {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name != "Won" && item.sales_stage?.name != "Lost" && item.timeline_year === filteredYear) {
            return item;
          }
        });
      } else {
        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name != "Won" && item.sales_stage?.name != "Lost") {
            return item;
          }
        });
      }

    } 
    else {
      if (filteredYear !== "all") {

        data = allTableData.filter(function (item) {
          
          if (item.sales_stage?.name === type && item.timeline_year === filteredYear) {
            return item;
          }
        });
      } else {

        data = allTableData.filter(function (item) {
          if (item.sales_stage?.name === type) {
            return item;
          }
        });
      }

    }
    if(noFiltersSelected){
      var params  = {
        "filters": [],
        "data": data,
        "columnName": null
      }
      applyFilters(params)
    }else{
      setTableData(data);
    }
    
    setFilteredData(data);
    //("data sssdf", data)
    
  }


  const fetchDrfExpiredList = () => {
    let todayDate = moment().format('YYYY-MM-DD')
    let drfExpiredData = tableData.filter(function (item) {
      
      if (item.drf_expiry_date && ((moment(item.drf_expiry_date).format("YYYY-MM-DD") < todayDate) && item.sales_confidence !== "Won" && item.sales_stage !== "Won")) {
        return item;
      }
    });
    setOpportunityTypeActive("drfExpiredList")
    setTableData(drfExpiredData);
    setFilteredData(drfExpiredData);
  }
  
  const headerProps = () => ({
    style: {
      whiteSpace: "nowrap",
      background: "#e6e6e6",
      fontWeight: 'bold'
    }
  })
const AddlicenseButtonHandle = (e,value,id) =>{

  e.stopPropagation();
  setShowAddLicenseModal(true)
  setOpportunitesId(id)
}

const uploadOldLicense = (e, value, oppId) => {
  // setOpen

  e.preventDefault();

  setShowWonPoModal(oppId)
}


  const columns = [
    {
      name: "_id",
      label: "id",
      options: {
        filter: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "user_id",
      label: "Account Manager",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value ? (value.first_name + ' '+ value.last_name) : "user not found")
        },
        display: columnsList?.includes('user_id') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Organization Name",
      options: {
        filter: true,
        display: true,
        setCellProps: () => ({
        }),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "drf_status",
      label: "DRF Status",
      options: {
        filter: true,
        display: columnsList?.includes('drf_status') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "drf_expiry_date",
      label: "DRF Expiry Date",
      options: {
        filter: false,
        display: columnsList?.includes('drf_expiry_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "products",
      label: "Products",
      options: {
        filter: true,
        display: columnsList?.includes('products') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //("value", value)
          // console.log("prod", tableMeta)
            return value?.map(product => product?.name).join(', ');
        },
        setCellHeaderProps: headerProps,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '500px',
              maxWidth: '500px',
              overflow: 'auto',
              color: '#243d6a',
            }
          }),
      }
    },
    {
      name: "product",
      label: "Previous Product",
      options: {
        filter: true,
        display: columnsList?.includes('product') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    // {
    //   name: "product",
    //   label: "Product",
    //   options: {
    //     filter: true,
    //     display: columnsList?.includes('product') ? true : false,
    //     // customBodyRender: (value, tableMeta, updateValue) => (
    //     //   value ? value.name : ""
    //     // ),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowData = tableMeta.rowData;
    //       //("row Data", rowData);
    //       if (rowData && rowData.products && rowData.products.length > 0) {
    //         // If 'products' array is not empty, map and display product names
    //         return rowData.products.map(product => product.name).join(', ');
    //       } else if (rowData && rowData.product && rowData.product.name) {
    //         // If 'products' array is empty, display the name from the 'product' object
    //         return rowData.product.name;
    //       } else {
    //         // Handle the case where 'rowData' or its properties are missing
    //         return "";
    //       }
    //     },
    //     setCellHeaderProps: headerProps,
    //   }
    // },
    {
      name: "license_start_date",
      label: "License Start Date",
      options: {
        // setCellHeaderProps: headerProps,
        filter: true,
        sort: false,
        empty: true,
        filterOptions: {
          renderValue: val => {
            return val?.[0]?.toUpperCase() + val?.slice(1);
          }
        },
        // display: columnsList?.includes('technical_approval') ? true : false,
        display:  opportunityTypeActive === "Won" ? true : false,
        onRowClick: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // if (permissions.includes("approveLeads") && (value === "pending" || value === null)) {
            //     
           
            if (tableMeta?.rowData[17]?.name === "Won" && permissions?.includes("canAddLicenseDate") && !value && tableMeta?.rowData[27]) {
            return (
              <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                <a style={{ textAlign: 'center' }} onClick={(e) => { AddlicenseButtonHandle(e, value, tableMeta.rowData[0]) }}>
                  <span style={{ cursor: 'pointer', textAlign: 'center' }} className='btn-sm btn-group-sm btn btn-table-commone btn-success'> Add License Start Date</span>
                </a>
              </div>
            );
          } else {
            return <span style={{ textTransform: 'capitalize' }}>{value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : "License not updated"}</span>;
          }
        }
      }
    },
    {
      name: "month",
      label: "Closure Month",
      options: {
        filter: true,
        display: columnsList?.includes('month') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "closure_qtr",
      label: "Closure Quarter",
      options: {
        filter: true,
        display: columnsList?.includes('closure_qtr') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    // {
    //   name: "po_value",
    //   label: "PO Value",
    //   options: {
    //     setCellHeaderProps: headerProps,
    //     filter: false,
    //     align: "right",
    //     display: columnsList?.includes('po_value') ? true : false,
    //     customBodyRender: (value, meta) => {
    //       return (
    //         <div style={{textAlign:"right"}}>
    //           <span>  {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>
    //         </div>
     
    //       )
    //     }
    //   }
    // },

    {
      name: "po_value",
      label: "PO Value",
      options: {
        filter: true,
       
        display: columnsList?.includes('po_value') ? true : false,
      customBodyRender: (value, tableMeta, updateValue) => {
             return (
            <div style={{textAlign:"right"}}>
              <span>  {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>
            </div>
     
          )
        },
        setCellHeaderProps: headerProps,
      }
    },

    {
      name: "percentage_split_tokaizenat",
      label: "% To Kaizenat",
      options: {
        filter: false,
        display: columnsList?.includes('percentage_split_tokaizenat') ? true : false,
        customBodyRender: (value, meta) => {
          return (<div style={{textAlign:"right"}}><span>  {value ? value + "%" : ""} </span></div>)
        },
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "revenue_to_kaizenat",
      label: "To Kaizenat( ₹ )",
      options: {
        filter: false,
        display: columnsList?.includes('revenue_to_kaizenat') ? true : false,
        customBodyRender: (value, meta) => {
          return (
            <div style={{textAlign:"right"}}>
          <span> {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>
          </div>
          )
        },
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "principal_in_rupees",
      label: "To OEM( ₹ )",
      options: {
        filter: false,
        display: columnsList?.includes('principal_in_rupees') ? true : false,
        // "₹" +
        customBodyRender: (value, meta) => {
          return (  <div style={{textAlign:"right"}}><span> {value ? "₹" + value.toLocaleString("en-IN") : ""}</span></div>)
        },
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "principal_in_dollars",
      label: "To OEM( $ )",
      options: {
        setCellHeaderProps: headerProps,
        filter: false,
        display: columnsList?.includes('principal_in_dollars') ? true : false,
        customBodyRender: (value, meta) => {
          return (  <div style={{textAlign:"right"}}><span>  {value ? "$" + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(value) : ""}</span></div>)
        }
      }
    },
    {
      name: "closure",
      label: "Closure Date",
      options: {
        filter: false,
        display: columnsList?.includes('closure') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        display: columnsList?.includes('department') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "sales_stage",
      label: "Sales Stage",
      options: {
        filter: true,
        display: columnsList?.includes('sales_stage') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "sales_activity",
      label: "Sales Activity",
      options: {
        filter: true,
        display: columnsList?.includes('sales_activity') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "sales_confidence",
      label: "Sales Confidence",
      options: {
        filter: true,
        display: columnsList?.includes('sales_confidence') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "business_type",
      label: "Business Type",
      options: {
        filter: true,
        display: columnsList?.includes('business_type') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value?.name : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "opportunity_name",
      label: "Opportunity Name",
      options: {
        filter: false,
        display: columnsList?.includes('opportunity_name') ? true : false,
        setCellHeaderProps: headerProps,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '500px',
              maxWidth: '500px',
              overflow: 'auto',
              color: '#243d6a',
            }
          }),
      }
    },
    {
      name: "tasks",
      label: "Tasks",
      options: {
        filter: false,
        sort: true,
        display: columnsList?.includes('tasks') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value ? value.first_name : "")
        },
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "created_at",
      label: "Created at ",
      options: {
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "updated_at",
      label: "Updated at ",
      options: {
        display: columnsList?.includes('updated_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
        filter: false,
      }
    },
    {
      name: "tasks",
      label: "Last Added Task",
      options: {
        filter: false,
        display: columnsList?.includes('tasks') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value?.task_name
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "timeline",
      label: "Timeline",
      options: {
        filter: false,
        display: columnsList?.includes('timeline') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "license_file_path",
      label: "License File Path",
      options: {
        filter: false,
        display: columnsList?.includes('license_file_path') ? true : false,
        sort: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "license_file_path",
      label: "Upload License",
      options: {
        setCellHeaderProps: headerProps,
        filter: false,
        display: columnsList?.includes('principal_in_dollars') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {

          // const uploadOldLicense = (e) => {
          //   // Prevent the default action (opening the link) and stop the event propagation
          //   e.preventDefault();
          //   e.stopPropagation();
          //   console.log("upload license click")
          //   // Call the uploadOldLicense function
          //   uploadOldLicense(tableMeta.rowData[0]);
          // };
          // 
            if (tableMeta?.rowData[17]?.name === "Won" && permissions?.includes("canUploadLicense") && !value) {
              return (
                <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                  <a style={{ textAlign: 'center' }} onClick={(e) => { uploadOldLicense(e, value, tableMeta.rowData[0]) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center' }} className='btn-sm btn-group-sm btn btn-table-commone btn-success'> Upload License</span>
                  </a>
                </div>
              );
            } else {
              return <span style={{ textTransform: 'capitalize' }}>{value ? "License uploaded": "License not updated"}</span>;
            }

              // <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
              //   <a style={{ textAlign: 'center' }} onClick={(e) => { uploadOldLicense(e, tableMeta.rowData[0]) }}>
              //     <span style={{ cursor: 'pointer', textAlign: 'center' }} className='btn-sm btn-group-sm btn btn-table-commone btn-success'>Upload License</span>
              //   </a>
              // </div>
            
        }
      }
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value?.name
        ),
        setCellHeaderProps: headerProps,
      }
    }

  ];
  const LoaderText = () => {
    return   opportuntieslistLoader ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
//  filteredArray[index].some(filtered => (item.user_id?.first_name + ' ' + item.user_id?.last_name) === filtered)
 const applyFilters  = ({filters = [], data=[], columnName=null}) => {
  // console.log("datas datass ", data, filters, columnName)
  var filteredArray = filters?.length>0 ? filters : filtersChange
  // var filteredArray = list.filter(subarray => subarray.length > 0);
  // var data = data?.length > 0 ? data : tableData
  // var data = data?.length > 0 ? data : filteredDataOrg
  // var data = filteredDataOrg
  //("filteredArray", filteredArray)
  // console.log("filters ", filters, data, columnName)
  // console.log("opportunityTypeActive ", opportunityTypeActive, filteredYear)
  var datas = allTableData?.filter(function (item) {
    if (item.sales_stage?.name === opportunityTypeActive && item.timeline_year === filteredYear) {
      return item;
    }
    else if (item.sales_stage?.name != ("Lost") && item.sales_stage?.name != ("Won") && item.timeline_year === filteredYear) {
      return item;
    }
  });

  var data = data?.length > 0 ? data : datas

  // console.log("log data ", data)
  // console.log("filteredArray ", filters, columnName)
  var filteredData = []
  filteredArray.map((item, index) => {
      var changedColumn = columnName ? columnName : index
      // console.log("changed Column ", changedColumn)
      // const isIndexPresent = filteredArray.indexOf(index) !== -1;
      // if(!isIndexPresent){
      //   return null;
      // }
      // const columnIndex = columns.findIndex(col => col.name === columnName);
      

      if(filteredArray[index]?.length < 1){
        return null;
      }
      // console.log("filteredArray[index] ", filteredArray[index])
      const columnIndex = columns[index]
      var changedColumn = columnIndex.name

      // console.log("changedColumn", changedColumn, index)

      if(changedColumn === 0 || changedColumn === "user_id"){
          filteredData = data?.filter(item => 
            filteredArray[index]?.includes((item.user_id?.first_name + ' ' + item.user_id?.last_name))
          );
      }
      if(changedColumn === 1 || changedColumn === "company_name"){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.company_name)
        );
      }
      if(changedColumn === "drf_status" || changedColumn ===  2){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.drf_status?.name)
        );
      }
      if(changedColumn === "products" || changedColumn === 4){
        var name = ''
        filteredData = data.filter(item => 
          name += item?.products?.map(i => i.name),
          filteredArray[index]?.includes(name)
        );
      }
      if(changedColumn === "product" || changedColumn === 5){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.product?.name)
        );
      }
      if(changedColumn === "month" || changedColumn === 6){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.month)
        );
      }
      if(changedColumn === "closure_qtr" || changedColumn === 7){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.closure_qtr)
        );
      }
      if(changedColumn === "department" || changedColumn === 7){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.department)
        );
      }
      if(changedColumn === "sales_stage" || changedColumn === 15){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.sales_stage?.name)
        );
      }
      if(changedColumn === "sales_activity" || changedColumn === 16){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.sales_activity?.name)
        );
      }
      if(changedColumn === "sales_confidence" || changedColumn === 17){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.sales_confidence)
        );
      }
      if(changedColumn === "business_type" || changedColumn === 18){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.business_type?.name)
        );
      }
      if(changedColumn === "region" || changedColumn === 29){
        filteredData = data.filter(item => 
          filteredArray[index]?.includes(item?.region?.name)
        );
      }
    })

    setTableData(filteredData)
    setUpdated(!updated);
    setUpdated(!updated);
 }
 
 const handleFilterChange = (changedColumn, filterList) => {
  // console.log("changedColumn ",changedColumn,  filterList)
  setFiltersChange(filterList);
  const noFiltersSelected = filterList.every(columnFilters => columnFilters.length === 0);
    if (noFiltersSelected) {
      setTableData(filteredDataOrg)
    } else {
      var params  = {
        "filters": filterList,
        "data": filteredDataOrg,
        "columnName" : changedColumn
      }
      applyFilters(params)
    }
  };
  
  const customFooterVal = (
    count,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage
  ) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
    return (
      <CustomFooter
        totalPoValue = {sumPoValue(startIndex, endIndex, "po_value")}
        totalToKaizenat = {sumPoValue(startIndex, endIndex, "revenue_to_kaizenat")}
        totalPrincipal = {sumPoValue(startIndex, endIndex, "principal_in_rupees")}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage? rowsPerPage : 15}
        // onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
        onChangeRowsPerPage= {event => {
          changeRowsPerPage(event.target.value);
        }}
        onChangePage= {(event, newPage) => {
          changePage(newPage);
        }}
        // onChangePage={(_, page) => changePage(page)}
        // rowsPerPageOptions={[20,50,70,100,200,500,1000,2000]}
      />
    );
  }

  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPageOptions:[10, 25, 50, 100],
    rowsPerPage: window.innerHeight > 720 ? 50 : 50,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: permissions?.includes("canAssignOpportunities")? true : "none",
    customToolbarSelect: (selectedRows, displayData) => {

      var lead_ids_data = selectedRows.data.map(item1 => {
        return displayData[item1.index]?.data[0];
      })
      return (
        <div className="assing_to_isales">
        {permissions?.includes("canAssignOpportunities") ?
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAssigniSales(true)} >Assign To</Button>
            {showAssigniSales ? <AssignOpportunitiesUserModal leadIds={lead_ids_data} refreshAssignuser={() => userData.user_role_id?.role_name ==="techie" ? getTechieOpportunitiesListHand() : getOpportunitiesData() } show={showAssigniSales} onHide={() => setShowAssigniSales(false)} /> : ""}
          </>
       : false}
       
      </div>
      )
    },
    download: ((keyData === "regionChart") && (userData._id === "6138b9e3341ae5b3f3e74f01")) ? true : false,
    print: false,
    // onRowClick: handleRowClick,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
    onFilterChange: handleFilterChange,
    setRowProps: (row, dataIndex, rowIndex) => {

      if (row[13] !== "Won" && row[13] !== "Lost") {
        if (row[20]) {
          if (row[20]['postponed_date']) {
            const action_date = moment(row[20]['postponed_date']).format('YYYYMMDD')
            const today_date = moment().format('YYYYMMDD')
            if (parseInt(action_date) >= parseInt(today_date)) {
              var difference = 0;
            } else {
              var difference = -1;
            }
          } else {
            const action_date = moment(row[20]['action_date']).format('YYYYMMDD')
            const today_date = moment().format('YYYYMMDD')
            if (parseInt(action_date) >= parseInt(today_date)) {
              var difference = 0;
            } else {
              var difference = -1;
            }

          }

          if (row[21]) {

            var timeline_split = row[21].split("-")
            var month = moment().format('M');
            var year = moment().format('YYYY');
            var timelineMonth = moment().month(timeline_split[0]).format("M");

            if (parseInt(timeline_split[1]) < parseInt(year)) {

              return {
                style: { backgroundColor: "#ffe8e8",position:"relative" ,zIndex:"999" },
              };
            } else if (parseInt(timeline_split[1]) === parseInt(year) && parseInt(timelineMonth) < parseInt(month)) {

              return {
                style: { backgroundColor: "#ffe8e8" },
              };
            } else {

              return {
                style: { backgroundColor: difference >= 0 ? "" : "#ffe8e8" },
              };
            }
          } else {
            return {
              style: { backgroundColor: difference >= 0 ? "" : "#ffe8e8" },
            };
          }
        } else {

          if (row[21]) {

            var timeline_split = row[21].split("-")
            var month = moment().format('M');
            var year = moment().format('YYYY');
            var timelineMonth = moment().month(timeline_split[0]).format("M");

            if (parseInt(timeline_split[1]) < parseInt(year)) {
              return {
                style: { backgroundColor: "#ffe8e8" },
              };
            } else if (parseInt(timeline_split[1]) === parseInt(year) && parseInt(timelineMonth) < parseInt(month)) {
              return {
                style: { backgroundColor: "#ffe8e8" },
              };
            }
          }
          return {
            style: { backgroundColor: "#ebebeb" }
          }

        }
      }

    },
    onViewColumnsChange: async (changedColumn, action) => {
      //("changed columns", changedColumn, action)
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {

        // var index = columns.indexOf(changedColumn);
        // if (index !== -1) {
        //   columns.splice(index, 1);
        //   // setColumnsList(columns);
        // }
        // const index = columns.indexOf(changedColumn);
        var columns = columnsList.filter(item => item !== changedColumn);

        // setColumnsList(columns);

        // if (index !== -1) {
        //   // const newColumns = [...columns]; // Create a copy of the original columns array
        //   columns.splice(index, 1); // Remove the element at the specified index
        //   // setColumns(newColumns); // Update the state with the new array
        // }
      }
      const updatedData = {
        user_id: userData._id,
        table_name: "opportunities",
        columns_list: columns,
      }

      updateColumnsData(updatedData)
      // try {
      //   const res = await axios.post("", updatedData);
      //   setSnackBarMessage("Successfully Updated");
      //   handleClick();
      // } catch (err) {
      //   setSnackBarMessage(err.response.data.msg)
      // }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {
        //("item", item)

        const temp = [
          item.data[0] ? "NA" : "NA",
          item.data[1] ? item.data[1].first_name : "NA",
          item.data[2] ? item.data[2] : "NA",
          item.data[3] ? item.data[3].name : "NA",
          item.data[4] ? item.data[4].name : "NA",
          item.data[6] ? item.data[6].name : "NA",
          "NA",
          item.data[7] ? item.data[7] : "NA",
          item.data[8] ? item.data[8] : "NA",
          item.data[9] ? item.data[9] : "NA",
          item.data[10] ? item.data[10] : "NA",
          item.data[11] ? item.data[11] : "NA",
          item.data[12] ? item?.data[12] : "NA",
          item.data[13] ? item?.data[13] : "NA",
          item.data[14] ? item?.data[14] : "NA",
          item.data[15] ? item.data[15] : "NA",
          item.data[16] ? item.data[16] : "NA",
          item.data[17] ? item.data[17].name : "NA",
          item.data[18] ? item.data[18].name : "NA",
          item.data[19] ? item.data[19] : "NA",
          item.data[20] ? item.data[20].name : "NA",
          item.data[21] ? item?.data[21] : "NA",
          item.data[22] ? item?.data[22] : "NA",
          item.data[23] ? item?.data[23] : "NA",
          item.data[24] ? item?.data[24] : "NA",
          item.data[25] ? item?.data[25] : "NA",
          item.data[26] ? item?.data[26] : "NA",

        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val

    },
    customFooter: customFooterVal
    
  };

  const sumPoValue = (startIndex, endIndex, column) => {
    
    if(column == "po_value"){
      return tableData
      ?.slice(startIndex, endIndex)
      ?.map(a => a.po_value)
      ?.reduce((total, salary) => (total += salary), 0);
    }

    if(column == "revenue_to_kaizenat"){
      return tableData
      ?.slice(startIndex, endIndex)
      ?.map(a => a.revenue_to_kaizenat)
      ?.reduce((total, revenue) => (total += revenue), 0);
    }

    if(column == "principal_in_rupees"){
      return tableData
      ?.slice(startIndex, endIndex)
      ?.map(a => a.principal_in_rupees)
      ?.reduce((total, revenue) => (total += revenue), 0);
    }

  };

  const opportunitiesNew = () => {
    setShowOpportunitiesModal(false);
  }

  const createNewOpportunity = () => {
    setShowOpportunitiesModal(true)
    updateOpportunityModalData(true)
    createNewOpportunityData(true)
  }


  return (
    <>
      <React.Fragment>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: 'flex', flexDirection: "row" }}><div style={{ display: "flex", flexDirection: "row" }} >
              <b>Opportunities List</b>&nbsp;&nbsp;&nbsp;
              {
                opportunityTypeActive &&
                <>
                  <span onClick={() => filterLeadType("Open")} className={opportunityTypeActive === "Open" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Open </span>&nbsp;&nbsp;
                  <span onClick={() => filterLeadType("Won")} className={opportunityTypeActive === "Won" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Won </span>&nbsp;&nbsp;
                  <span onClick={() => filterLeadType("Lost")} className={opportunityTypeActive === "Lost" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Lost </span>&nbsp;&nbsp;
                </>
              }
            </div>
              {
                opportunityTypeActive &&
                <>
                  <div>
                    <select style={{ borderRadius: '5px' }} value={filteredYear} onChange={filterYear}>
                      <option value="all">All</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                  </div>
                </>
              }
              {
                (permissions.includes('createOwnOpportunities')) ? <>&nbsp;&nbsp;<span onClick={() => createNewOpportunity()} className={"badges badge-pill cursor-pointer badge-primary"}> Create New Opportunity </span>&nbsp;&nbsp;</> : null 
              }
               <span onClick={fetchDrfExpiredList} className={opportunityTypeActive === "drfExpiredList" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> DRF Expired List </span>&nbsp;&nbsp;
            </div>
            }
            // keyData ==="regionChart" || keyData ==="salesStageChart" || keyData ==="forecastSalesChart" ? regionChartTableData || salesStageChartTableData || ForecastSalesChartTableData: 
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </React.Fragment>
      {showOpportunitiesModal &&  <ViewOpportunities show={showOpportunitiesModal} />}
      {showAddLicenseModal ? <AddLicenseModal show={showAddLicenseModal} opportunity_id={opportunitesId} onHide={() => setShowAddLicenseModal(false)} refreshPageData={() => getOpportunitiesData()} /> : ""}
      <WonOpportunitiesPoModal show={showWonPoModal ? true: false} onCancelled={() => setShowWonPoModal(false)} onPoModalCancelled={() => setShowWonPoModal(false)} refreshPageData = {() => getOpportunitiesData()} poquationStatus={(data) => console.log(data)} onHide={() => setShowWonPoModal(false)} opportunitiesId={showWonPoModal}  /> 
    </>
  )
});
// 
const mapStateToProps = (state) => ({
  opportunitiesList : state.opportunities.opportunitiesList,
  techieOpportunitiesList:state.opportunities.techieOpportunitiesList,
  // ansysAccountManagers : state.opportunities.ansysAccountManagers,
  // drfStatusList : state.leads.drfStatusList,
  // productsList : state.dashboard.productsList,
  // regionsList : state.dashboard.regionsList,
  // businessTypesList : state.dashboard.businessTypesData,
  // leadSalesStagesList : state.dashboard.leadSalesStagesList,
  // salesActivitiesList : state.dashboard.salesActivitiesList,
  columnsList : state.opportunities.columnsList,
  isFetching : state.opportunities.isFetching,
  opportuntieslistLoader:state.opportunities.opportuntieslistLoader,
  rowid : state.opportunities.rowId,
});

const mapDispatchToProps = (dispatch) => ({
  getOpportunitiesData : () => dispatch(getOpportunitiesList()),
  getTechieOpportunitiesListHand : () => dispatch(getTechieOpportunitiesList()),
  // getAnsysAccountManagersData : () => dispatch(getAnsysAccountManagersList()),
  // getDrfStatusData : () => dispatch(getDrfStatusList()),
  // getProductsData : () => dispatch(getProductsList()),
  // getRegionsData : () => dispatch(getRegionsList()),
  // getBusinessTypesData : () => dispatch(getBusinessTypesList()),
  // getLeadStagesData : () => dispatch(getLeadStagesList()),
  setRowId:(data) => dispatch(opportunitiesActions.setRowId(data)),
  updateColumnsData : (data) => dispatch(updateColumns(data)),
  getColumnsData : () => dispatch(getColumnsList()),
  updateViewRowData : (data) => dispatch(updateViewRowDetails(data)),
  updateOpportunityModalData : (data) => dispatch(opportunitiesActions.updateOpportunityModal(data)),
  createNewOpportunityData : (data) => dispatch(opportunitiesActions.createNewOpportunity(data)),
  opportunitiefilteredYears  : (data) => dispatch(opportunitiesActions.OpportunitiesFilteredYear(data)),
  getTechiesListByManagerData : (data) => dispatch(getTechiesListByManager(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesTable);