import React, { useState, useEffect, useMemo } from "react";
import Tooltip from '@mui/material/Tooltip';
import { FiClock } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import moment from 'moment';
import PostponeIsalesMeeting from "../pageModals/PostponeIsalesMeeting";

import {connect}  from "react-redux";
import {getIsalesMeeting, updateCompletedMeeting} from "../../store/dashboard";

const UpdateIsalesMeetings = React.memo(({ updateIsalesmeetingLoader,isalesMeetings, updateCompletedMeetingData, getIsalesMeetingData }) => {

    // const [snackBarMessage, setsnackBarMessage] = useState(false);
    // const [data, setData] = useState([]);
    const [followUpId, setFollowUpId] = useState(null);
    const [showPostPoneModal, setShowPostPoneModal] = useState(false);

    console.log("Update Isales Meetings Rendering")

    const completedMeeting = async (event, task_id) => {
        event.stopPropagation();
        var updatedData = {
            'status': "completed",
            'lead_id': task_id,
        }
        updateCompletedMeetingData(updatedData);
    }

    const setFollowUpIdFn = (followupId) => {
        setFollowUpId(followupId);
        setShowPostPoneModal(true);
    }

    useEffect(() => {
        getIsalesMeetingData();
    }, [])

    return (
        
        <>
        {updateIsalesmeetingLoader ? 

            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
       
         <>
<div className="container-fluid py-2 px-2">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <button  className="btn_leads_task">Have You Attended These meetings?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div className="row" id="style-10" style={{ minHeight: '440px', maxHeight: '440px', padding: '15px', overflowY: 'auto' }}>
                            {
                                (isalesMeetings) &&
                                isalesMeetings.map((task, index) => {
                                        return (
                                            <div className="col-md-3" >
                                                <span className="task_title_main">{index + 1}. {task?.company_name} -  {(task?.company_name).replace(/\b(\w)/g, s => s.toUpperCase())}
                                                </span>
                                                <div className="icons_postponed_completed" style={{ display: 'flex', flexDirection: 'row' }}>
                                                </div>
                                                <span className="common_fontfamily" style={{ fontSize: '14px', }}>

                                                    <Tooltip title="Mark as completed" arrow >
                                                        <a className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }} onClick={(e) => completedMeeting(e, task._id)}>
                                                            <AiOutlineCheckCircle size={20} color="white" className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    <Tooltip title="Postpone task" arrow >
                                                        <a className="btn btn-primary btn-sm" style={{ cursor: 'pointer' }} onClick={() => setFollowUpIdFn(task._id)}>
                                                            <FiClock data-for='toolTip1' color="white" size={19} className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    </span>
                                                <span className="   ">Initial Discussion - {task.isales_meeting_info}</span>
                                                <span className="task_description">Isales Meeting Date Time -  {moment(task.isales_meeting_date_time).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}</span>
                                                <span>Assigned By - {task.assigned_by?.first_name + ' ' + task.assigned_by?.last_name}</span>
                                                <br />
                                                <hr />
                                            </div>

                                        )
                                    })
                                    
                            }

                        

                        </div>

                        {showPostPoneModal ? <PostponeIsalesMeeting show={showPostPoneModal}  refreshTable={() => getIsalesMeetingData()} lead_id={followUpId} onHide={() => setShowPostPoneModal(false)} /> : ""}

                    </>

                   }
                    </>
    )
});
// export default UpdateIsalesMeetings;
const mapStateToProps = (state) => ({
    isalesMeetings: state.dashboard.isalesMeetings,
    updateIsalesmeetingLoader : state.dashboard.updateIsalesmeetingLoader
});

const mapDispatchToProps = (dispatch) => ({
    updateCompletedMeetingData: (data) => dispatch(updateCompletedMeeting(data)),
    getIsalesMeetingData: () => dispatch(getIsalesMeeting())
});


// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(UpdateIsalesMeetings);