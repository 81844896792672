
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;

const intialAuthState = {
  isFetching: false,
  error: false,
  errorPayload: false,
  user: userObj,
  msg: "",
  leadsAnalysis: null,
  open: false,
  showModal: false,
  showupdatemodal: false,
  showRelievemodal: false,
  rowId: [],
  rowMetadata: [],
  hrislist: [],
  createhris: [],
  updatehris: [],
  regionList: [],
  columnsList: [],
  userRoles: []
}

const HrisSlice = createSlice({
  name: "Hrislist",
  initialState: intialAuthState,
  reducers: {
    Open: (state, action) => {
      state.open = true;
    },
    close: (state, action) => {
      state.open = false;
    },

    openModal: (state, action) => {
      state.showModal = true;
    },
    closeModal: (state, action) => {
      state.showModal = false;
    },
    showupdateOpenmodal: (state, action) => {
      state.showupdatemodal = true;
    },
    showupdateClosemodal: (state, action) => {
      state.showupdatemodal = false;
    },
    showrelieveOpenmodal: (state, action) => {
      state.showRelievemodal = true;
    },
    showrelieveClosemodal: (state, action) => {
      state.showRelievemodal = false;
    },
    setRowId(state, action) {
      state.rowId = action.payload;
    },
    userIdData(state, action) {
      state.userId = action.payload;
    },
    setrowMetaData(state, action) {
      state.rowMetadata = action.payload;
    },

    loaderStart(state, action) {
      state.isFetching = true;
      state.msg = "Fetching...";
      state[action.payload] = true;
    },
    loaderStop(state, action) {
      state.isFetching = false;
      state.msg = "Fetching...";
    },

    hrislistSuccess(state, action) {
      state.isFetching = false;
      state.error = false;
      state.errorPayload = null;
      state.hrislist = action.payload;
      // toast.success("hris data list") 
    },
    hrislistFailure(state, action) {
      state.isFetching = false;
      state.error = false;
      state.errorPayload = null;
      state.hrislist = [];
      toast.error("Error in hris data list")


    },
    createhrisSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.createhris = action.payload;
      toast.success("Successfully Created")
      state.showModal = false;

    },
    createhrisFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.createhris = action.payload;
      toast.error(action.payload)
      state.showModal = true;

    },

    updatedHrisSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.updatehris = action.payload;
      toast.success("Succesfully Updated")
      state.showupdatemodal = false

    },

    updatedHrisFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.updatehris = action.payload;
      toast.error("Not Updated Error")
      state.showupdatemodal = true

    },

    relieveHrisSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      toast.success("User Relieved Successfully ...!")
      state.showRelievemodal = false

    },

    relieveHrisFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      toast.error("Not Updated Error")
      state.showRelievemodal = true
    },

    getRegionlistSuccess(state, action) {
      state.meetingLoader = false;
      state.error = false
      state.errorPayload = false
      state.regionList = action.payload.regionsList;

    },
    getRegionlistFailure(state, action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = action.payload
      state.regionList = [];

    },
    tasksListUpdateSuccess(state, action) {
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.msg = action.payload
    },
    tasksListUpdateFailure(state, action) {
      state.isFetch = false
      state.error = true
      state.errorPayload = action.payload
      state.msg = ""
    },
    hrisColumnsListSuccess(state, action) {
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.columnsList = action.payload
    },
    hrisColumnsListFailure(state, action) {
      state.isFetch = false
      state.error = true
      state.errorPayload = action.payload
      state.columnsList = []
    },

    updateColumns(state, action) {
      state.columnsList = action.payload
    },
    hrisUserRolesListSuccess(state, action){
      state.userRoles = action.payload
      state.error = false
      state.errorPayload = false
      state.isFetch = false
    },
    hrisUserRolesFailure(state, action){
      state.userRoles = []
      state.error = true
      state.errorPayload = action.payload
      state.isFetch = false
    }
  }
});
export const HrisActions = HrisSlice.actions;



export const gethris = (userids) => {

  return async (dispatch) => {
    dispatch(HrisActions.loaderStart("meeting loader"))

    try {
      await Api.get("/user/users-list-HRIS", userids).then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.hrislistSuccess(res?.data?.usersList));
        }
        if (res.data.status === "failure") {
          dispatch(HrisActions.hrislistFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      dispatch(HrisActions.hrislistFailure("Network Error"));

    }
  }
}



export const createHrislist = (formdata) => {
  return async (dispatch) => {
    dispatch(HrisActions.loaderStart("meetingloader"))
    try {
      await Api.post("/user/user-HRIS/add", formdata).then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.createhrisSuccess(res.data));
          dispatch(gethris())
        }
        if (res.data.status === "failure") {
          dispatch(HrisActions.createhrisFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      dispatch(HrisActions.createhrisFailure("Network Error"));
    }
  }
}


export const updatedhrisdata = (activityUpdateformdata) => {

  return async (dispatch) => {
    dispatch(HrisActions.loaderStart("meetingloader"))

    try {
      await Api.post("/user/user-HRIS/update", activityUpdateformdata).then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.updatedHrisSuccess(res.data));
          dispatch(gethris())
        }
        if (res.data.status === "failure") {
          dispatch(HrisActions.updatedHrisFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      dispatch(HrisActions.updatedRawdataFailure("Network Error"));
    }
  }

}

export const relievehrisdata = (userData) => {

  return async (dispatch) => {
    dispatch(HrisActions.loaderStart("meetingloader"))

    try {
      await Api.post("/user/profile/block-user", userData).then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.relieveHrisSuccess(res.data));
          dispatch(gethris())
        }
        if (res.data.status === "failure") {
          dispatch(HrisActions.relieveHrisFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      dispatch(HrisActions.relieveHrisFailure("Network Error"));
    }
  }

}

export const fetchRegionlist = () => {

  return async (dispatch) => {
    dispatch(HrisActions.loaderStart("meetingloader"))
    try {
      await Api.get("/user/reports/regional-branches").then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.getRegionlistSuccess(res.data));
          // dispatch(gethris())
        }
        if (res.data.status === "failure") {
          dispatch(HrisActions.getRegionlistFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      dispatch(HrisActions.getRegionlistFailure("Network Error"));
    }
  }
}



export const updateColumns = (data) => {
  return async (dispatch) => {
    dispatch(HrisActions.loaderStart())
    try {
      await Api.post("/columns-hide-show/create", data).then((res) => {
        if (res.data.status === "success") {
          toast.success("Columns Updated ")
          dispatch(HrisActions.tasksListUpdateSuccess("Columns Updated"))
        }
        if (res.data.status === "failure") {
          toast.error(res.data.msg)
          dispatch(HrisActions.tasksListUpdateFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      toast.error("Network Error")
      dispatch(HrisActions.tasksListUpdateFailure("Network Error"));
    }
  }
}


export const getColumnsList = () => {
  return async (dispatch) => {
    dispatch(HrisActions.loaderStart())
    try {
      var userData = JSON.parse(localStorage.getItem("user"))
      await Api.get("/columns-hide-show/list/hris/" + userData?.user?._id).then((res) => {
        if (res.data.status === "success") {
          dispatch(HrisActions.hrisColumnsListSuccess(res.data.columnsList?.columns_list))
        }
        if (res.data.status === "failure") {
          toast.error(res.data.msg)
          dispatch(HrisActions.hrisColumnsListFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      toast.error("Network Error!")
      dispatch(HrisActions.hrisColumnsListFailure("Network Error"));
    }
  }
}

export const getUserRoles = () => {
  return async (dispatch) => {
    dispatch(HrisActions.loaderStart())
    try {
      var userData = JSON.parse(localStorage.getItem("user"))
      await Api.get("/user/user-HRIS/user-roles").then((res) => {
        if (res.data.status === "success") {
          var userRoles = []
          res.data.userRoles?.forEach((role) => {
            if(role.label === "user"){
              userRoles.push({value: role.value, label: "Sales Team"})
            }
            else if(role.label === "sales_manager"){
              userRoles.push({value: role.value, label: "Sales Manager"})
            }
            else if(role.label === "technical_manager"){
              userRoles.push({value: role.value, label: "Technical Manager"})
            }
            else if(role.label === "HR"){
              userRoles.push({value: role.value, label: "HR Team"})
            }
            else if(role.label === "techie"){
              userRoles.push({value: role.value, label: "Technical Team"})
            }
            else if(role.label === "pdteam"){
              userRoles.push({value: role.value, label: "PD Team"})
            }
            else if(role.label === "admin"){
              userRoles.push({value: role.value, label: "Admin Team"})
            }
            else if(role.label === "marketing_team"){
              userRoles.push({value: role.value, label: "Marketing Team"})
            }
            else{
              userRoles.push({value: role.value, label: role.label})
            }
          });
          dispatch(HrisActions.hrisUserRolesListSuccess(userRoles))
        }
        if (res.data.status === "failure") {
          toast.error(res.data.msg)
          dispatch(HrisActions.hrisUserRolesFailure(res.data.msg))
        }
      })
    }
    catch (err) {
      toast.error("Network Error!")
      dispatch(HrisActions.hrisUserRolesFailure("Network Error"));
    }
  }
}

export default HrisSlice.reducer;