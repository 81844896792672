import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
const TrainingModal = (props) => {

  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [accountId, setAccountId] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sessionTypeList, setSessionTypeList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [trainingTypeList, setTrainingTypeList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const[multiProductsData,setMultiProductsData] =useState([])
  const [demoData, setDemoData] = useState({
    number_of_attendees: "",
    account_id: "",
    application: "",
    training_type: "",
    initial_discussion: "",
    session_type: "",
    date_time: "",
    course_type: "",
    products_list:[]
  });

  const { register: trainingRegister, handleSubmit: trainingHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: trainingErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  var today = new Date();
  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');

  const new_date_time = useRef();

  const closure_date = useRef();
  const ProductList = async () => {
    const res = await axios.get("/products/search")
    setProductList(res.data.productsList)
  }
  const getSessionTypeList = () => {
    setSessionTypeList([{ label: "Online", value: 'Online' }, { label: "Offline", value: 'Offline' }])
  }
  const getCourseTypeList = () => {
    setCourseTypeList([{ label: "Basic", value: 'Basic' }, { label: "Advanced", value: 'Advanced' }])
  }
  const getTrainingTypeList = () => {
    setTrainingTypeList([{ label: "Post Sales", value: 'Post Sales' }, { label: "Pre Sales", value: 'Pre Sales' }])
  }
  useEffect(() => {
    const newData= props.multiProducts.map((data) =>{
     return data.value ? {name:data.label, product_id:data?.value} : {name:data?.name, product_id:data?.product_id}
    })
    setMultiProductsData(newData)
     },[props.multiProducts])
  useEffect(() => {
    ProductList();
    getSessionTypeList();
    getCourseTypeList();
    getTrainingTypeList();
  }, [])

  const handleClick = () => {
    setOpen(true);
  };
  const onInputChange = (e) => {
    setDemoData({ ...demoData, [e.target.name]: e.target.value }, []);
  };

  const addNewTrainingHandle = async (e) => {
    setDisable(true);
    setSnackBarMessage("Uploading Data ...!");
    setOpen(true);
    setTimeout(() => { }, 500);
    const updatedData = {
      number_of_attendees: demoData.number_of_attendees,
      opportunity_id: props.allTechieActionsDetails ? props.allTechieActionsDetails._id : null,
      account_id: props.allTechieActionsDetails ? props.allTechieActionsDetails.account_id : null,
      application: props.allTechieActionsDetails ? props.allTechieActionsDetails.product : null,
      // products:  props.allTechieActionsDetails ? props.allTechieActionsDetails?.products  : [],
      products:  demoData.products_list ? [demoData.products_list]  : [],
      training_type: demoData.training_type,
      comments: demoData.initial_discussion,
      session_type: demoData.session_type,
      date_time: moment(newDateTime).format('YYYY-MM-DD HH:mm:ss'),
      course_type: demoData.course_type
    }
    try {
      const res = await axios.post("/trainings/create", updatedData);
      if (res.data.status === "success") {

        if(!props.allTechieActionsDetails.lead_id){
          props.refreshTrainingTable()
        }
        
        setTimeout(() => {
          setOpen(true);
          setSnackBarMessage("Training Successfully Added...!");
        }, 300);

        // alert("Training Successfully Added...!");
        toast.success("Training Successfully Added...!") 
        props.onHide();
        handleClick();

      } else {
        setDisable(false);
        alert(res.data.msg);
        setSnackBarMessage(res.data.msg);
        handleClick();
      }
      reset({
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      })

      setDisable(true);
    } catch (err) {
      setDisable(false);
      alert(err.response.data.msg);
      if (err.response.status === 400) {
        trainingSetError("at_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        setSnackBarMessage(err.response.data.msg)
        setTimeout(() => {
          setOpen(true);
        }, 500);

      }

    }
  }



  const getAccountsList = async (search) => {

    setCompaniesList([]);
    const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
    setCompaniesList(res.data.AccountsList);
  }

  const handleSetCompanyName = (value) => {

    setSelecteAccount(value.value)
    if (value.__isNew__ === true) {
    } else {
      setAccountId(value.data_id);
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };
  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    demoData.new_date_time = newDate;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Training Request"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form >
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...trainingRegister("number_of_attendees", {
                    required: {
                      value: true,
                      message: "Training Name is required"
                    },
                    minLength: {
                      value: 1,
                      message: "Training Name must be at least 1 characters"
                    }
                  })}
                  helperText={trainingErrors.number_of_attendees ? trainingErrors.number_of_attendees.message : ''}
                  error={Boolean(trainingErrors.number_of_attendees)}
                  label="Number of Attendees" name="number_of_attendees" type="number" className="form-control" value={demoData.number_of_attendees} onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    className="form-control"
                    label="Training Start Date & Time"
                    inputVariant="outlined"
                    value={newDateTime}
                    onChange={handleMeetingDateTimeChange}
                    showTodayButton
                    disablePast
                    inputRef={new_date_time}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...trainingRegister("training_type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.training_type}
                  select
                  helperText={trainingErrors.training_type ? trainingErrors.training_type.message : ''}
                  error={Boolean(trainingErrors.training_type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="training_type" label="Training Type"
                >
                  {trainingTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...trainingRegister("session_type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.session_type}
                  select
                  helperText={trainingErrors.session_type ? trainingErrors.session_type.message : ''}
                  error={Boolean(trainingErrors.session_type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="session_type" label="Session Type"
                >
                  {sessionTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>

            </div>
            <br />
            <div className="row">

              <div class="col-md-6">
                <TextField variant="outlined"
                  {...trainingRegister("course_type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.course_type}
                  select
                  helperText={trainingErrors.course_type ? trainingErrors.course_type.message : ''}
                  error={Boolean(trainingErrors.course_type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="course_type" label="Course Type"
                >
                  {courseTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...trainingRegister("products_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.products_list}
                  select
                  helperText={trainingErrors.products_list ? trainingErrors.products_list.message : ''}
                  error={Boolean(trainingErrors.products_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="products_list" label="Select Product"
                >
                  {multiProductsData?.map((option) => (
                    <MenuItem key={option.product_id} value={option.product_id}>
                      {option.name}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              </div>
              <div className='row mt-4'>
              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...trainingRegister("initial_discussion", {
                    required: {
                      value: true,
                      message: "Comments is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Comments must be at least 5 characters"
                    }

                  })}
                  helperText={trainingErrors.initial_discussion ? trainingErrors.initial_discussion.message : ''}
                  error={Boolean(trainingErrors.initial_discussion)}
                  type="text" className="form-control" value={demoData.initial_discussion} name="initial_discussion" label="Comments" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div>
                <a onClick={() => addNewTrainingHandle()} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</a>

              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

    </>
  )

}
export default TrainingModal;