import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import HardwareUpdateModal from "../pageModals/HardwareUpdateModal"
import moment from 'moment-timezone';
import HardwareAssignModal from '../pageModals/HardwareAssignModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import {unAssignedUserlist,HardwareActions,updateColumns,getColumnsList} from "../../store/Hardware"
import {ContactColumnUpdateddata} from "../../store/Common"


const  HardwareTable = React.memo(({hardwarelist,hardwareupdatecolumns,updateColumns,Assignmodal,updatehardware,unAssigned,hardware,isFetching,metid,setRowMetaData,setRowId,openmodal,getColumnsListData,columnsList}) => {

  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const data = hardwarelist

  const permissions = user.permissions;
  const [open, setOpen] = React.useState(false);
  // const [columnsList, setColumnsList] = useState([]);
  
  const handleRowClick = (rowData, rowMeta) => {
    
    dispatch(HardwareActions.update_openModal())
          setRowMetaData(rowData);
          setRowId(data[rowMeta.dataIndex]._id) 

              };
    
  useEffect(() => {
    getColumnsListData()
  }, [hardwarelist?.list]);

  const assignHandle = async (event, data, meta) => {
    event.stopPropagation();
    openmodal()
    metid(meta)
  }
  const unAssignedUser = async (event, data, meta) => {
    event.stopPropagation();
    const datas ={
      "hardwareId":meta
    }
    unAssigned(datas)

    }

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: true,
        
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    
    {
        name: "type",
        label: "Type",
        options: {
          // display: columnsList?.includes('type') ? true : false,
          display: true,
          filter: true,
          setCellHeaderProps: headerProps,
        }
      },
    {
      name: "brand",
      label: "Brand",
      options: {
        // display: columnsList?.includes('brand') ? true : false,
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
     {
      name: "cost",
      label: "Cost",
      options: {
        setCellHeaderProps: headerProps,
        filter: true,
        display: columnsList?.includes('cost') ? true : false,

        customBodyRender: (value, meta) => {
          return (<span>  {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>)
        }
      }
    },
    {
        name: "model",
        label: "Model",
         options: {
          display: columnsList?.includes('model') ? true : false,
       
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        display: columnsList?.includes('serial_number') ? true : false,
    
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "purchased_on",
      label: "Purchased On",
      options: {
        filter: true,
        display: columnsList?.includes('purchased_on') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
        name: "warranty_exp_date",
        label: "Warranty Exp Date",
        options: {
            filter: true,
            
            display: columnsList?.includes('warranty_exp_date') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
              value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "warranty_type",
        label: "Warranty Type",
        options: {
          display: columnsList?.includes('warranty_type') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
            filter: true,
            setCellHeaderProps: headerProps,
          }
      },
    {
      name: "purchased_in",
      label: "Purchased In",
      options: {
        filter: true,
      
        display: columnsList?.includes('purchased_in') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "point_of_name",
      label: "Point of Name",
      options: {
        filter: true,
       
        display: columnsList?.includes('point_of_name') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
        name: "point_of_contact",
        label: "Point of Contact",
        options: {
          filter: true,
         
          display: columnsList?.includes('point_of_contact') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
          setCellHeaderProps: headerProps,
        }
      },

      {
        name:"assigned_status",
        label:"Actions",
        options : {
            filter:true,
            
            display: columnsList?.includes('assigned_status') ? true : false,
            setCellHeaderProps:headerProps,
            customBodyRender : (value,tableMeta,updateValue) => {

                var status = tableMeta.rowData[11]
                
                if (value === "Pending" && status !== "Completed") {
                    return (
                      <div style={{ display: "flex" }}>
                            <>
                                <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success " onClick={(e) => { assignHandle(e, value,tableMeta.rowData[0]) }}>
                                    <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>AssignTo</span>
                                </a>
                            </>
        
                      </div>
                    );
                  }
                  else {

                    return (
                        <div style={{ display: "flex" }}>  
                              <>
                                  <a style={{ textAlign: 'center'}} className="btn-sm btn-group-sm btn btn-table-commone btn-danger " onClick={(e) => unAssignedUser(e, value, tableMeta.rowData[0])}>
                                      <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>UnAssign</span>
                                  </a>
                              </>
          
                        </div>
                      );
                  }
            }
        }

      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          
          display: columnsList?.includes('status') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      {
        name: "physical_address",
        label: "Physical Address",
        options: {
          filter: true,
         
          display: columnsList?.includes('physical_address') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      },  
      {
        name: "hostname",
        label: "Host Name",
        options: {
          filter: true,
         
          display: columnsList?.includes('hostname') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      {
        name: "ram",
        label: "RAM",
        options: {
          filter: true,
         
          display: columnsList?.includes('ram') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      
      {
        name: "processor",
        label: "Processor",
        options: {
          filter: true,
         
          display: columnsList?.includes('processor') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      
      {
        name: "hard_disk_type",
        label: "Hard Disk Type",
        options: {
          filter: true,
         
         
          display: columnsList?.includes('hard_disk_type') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      {
        name: "hard_disk",
        label: "Hard Disk",
        options: {
          filter: true,
          
          display: columnsList?.includes('hard_disk') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      }, 
      {
        name: "graphics_card",
        label: "Graphics Card",
        options: {
          filter: true,
         
          display: columnsList?.includes('graphics_card') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? value : ""
          ),
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "assigned_to",
        label: "Assigned To",
        options: {
          customBodyRender: (value) => (
            value ? value.first_name + " " + value.last_name : "NA"
          ),
          display: columnsList?.includes('assigned_to') ? true : false,

          filter: true,
          setCellHeaderProps: headerProps,
        }
      }
    
  ];
  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }


  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 190).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 170).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },

      onViewColumnsChange: async (changedColumn, action) => {
        var columns = Object.assign([], columnsList)
        
        if (action === "add") {
          columns.push(changedColumn);
        }
        if (action === "remove") {
          columns = columns.filter(item => item !== changedColumn);
       }
      const updatedData = {
        user_id: userData ? userData._id : null,
        table_name: "hardware",
        columns_list: columns,
      }
      hardwareupdatecolumns(updatedData)
      updateColumns(columns)
      },


     


   
    download: false,
    onRowClick: handleRowClick,
  };


  return (
    
   <>
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Hardware Assets list"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </React.Fragment>

            {Assignmodal ? <HardwareAssignModal    /> :""}
            {updatehardware ? <HardwareUpdateModal   /> : ""}
    </>
  )
});
const mapStateToProps = (state) => ({
  user : state.auth.user,
  hardwarelist:state.Hardwarelist.hardwarelist,
  Assignmodal:state.Hardwarelist.showModal_assign,
  updatehardware:state.Hardwarelist.updatedHardwaremodal,
  isFetching : state.Hardwarelist.isFetching,
  columnsList : state.Hardwarelist.columnsList,

});
const mapDispatchToProps = (dispatch) => ({
    unAssigned : (data) => dispatch(unAssignedUserlist(data)),
    hardwareupdatecolumns : (data) => dispatch(updateColumns(data)),
    metid:(data) => dispatch(HardwareActions.setAssignmetaid(data)),
    setRowMetaData:(data) => dispatch(HardwareActions.setrowMetaData(data)),
    setRowId:(data) => dispatch(HardwareActions.setRowId(data)),
    openmodal:(data) =>dispatch(HardwareActions.assign_openModal()),
    getColumnsListData : () => dispatch(getColumnsList()),
    updateColumns: (data) => dispatch(HardwareActions.updateColumns(data))
   
   
})

export default connect(mapStateToProps,mapDispatchToProps)(HardwareTable)