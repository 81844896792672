import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import { useForm } from "react-hook-form";
import Badge from 'react-bootstrap/Badge';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { components } from "react-select";
import {updateWebinardata,getmanagerslist,getInvolvedPersonslist} from "../../store/Webinar"
import { useDispatch, useSelector } from 'react-redux';

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
const UpdateWebinarModal = (props) => {
  console.log("updatedprops", props)
   const managerlists = useSelector(state => state.Webinarslist.getmanagerlists);
   const  InvolvedPersonslist = useSelector(state => state.Webinarslist.getInvolvedPersonslists);
  const dispatch = useDispatch();
  var today = new Date();
  var twodays = moment().add(2, 'days');
  const end_time = useRef();
  const start_time = useRef();
  const task_date = useRef();
  const [contactShow, setContactShow] = useState(false);
  const[typeList,setTypeList]=useState([]);
  const [open, setOpen] = React.useState(false);
  const [filterAccountManager, setFilterAccountManager] = useState(props.metaData[11]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [filterPersonsInvloved, setFilterPersonsInvloved] = useState(props.metaData[12]);
  const [personsInvloved, setPersonsInvloved] = useState([]);
  //console.log("filteraccount", filterAccountManager)
  const [disable, setDisable] = React.useState(false);
  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  const [updateWebinarData, setUpdateWebinarData] = useState({
    up_webinar_title:  props.metaData[2],
    up_webinar_description:  props.metaData[9],
    type:props.metaData[3],
    up_webinar_agenda:  props.metaData[10],
    up_webinar_date : props.metaData[6],
    up_webinar_start_time: moment(props.metaData[6]).format('YYYY-MM-DD')+"T"+moment(props.metaData[7], 'HH:mm A').format('HH:mm'),
    up_webinar_end_time:  moment(props.metaData[6]).format('YYYY-MM-DD')+"T"+moment(props.metaData[8], 'HH:mm A').format('HH:mm'),
    speakers:  props.metaData[4],
    personsInvloved:  props.metaData[5],
  });
  //console.log("meta data here", props.metaData)
  const { register: updatewebinarRegister, handleSubmit: updateWebinarHandleSubmit, setError: updateWebinarSetError, formState: { errors: updateWebinarErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const { up_webinar_title, up_webinar_description,up_webinar_date ,up_webinar_agenda} = updateWebinarData;
  const getTypeList = () => {
    setTypeList([{ label: "Seminar", value: 'Seminar' }, { label: "Webinar", value: 'Webinar' }])
  }
  
  useEffect(() => {
    getTypeList();
  }, [])
  
  const onInputChange = (e) => {
    setUpdateWebinarData({ ...updateWebinarData, [e.target.name]: e.target.value }, []);
  };
  
  const getManagers = async () => {
    // dispatch(getmanagerslist());
    // const res = managerlists?.techiesList
    // setAccountManagers(res)
    const res = await axios.get("/user/techies-list");
    setAccountManagers(res.data.techiesList);
  }

  const getPersonsList = async () => {
    // dispatch(getInvolvedPersonslist());
    
    // const res = InvolvedPersonslist?.list
    // console.log("managersadsadslist",res)
    // setPersonsInvloved(res)
    const res = await axios.get("/user/team-members-list");
    setPersonsInvloved(res.data.list);
  }
  const UpdateWebinarHandle = async (e) => {
    setDisable(true);
    
    var account_manager = filterAccountManager.map(item => {
        return item.value? item.value : item;
      });

      var persons_involveds = filterPersonsInvloved.map(item => {
        return item.value? item.value : item;
      });
    const updatedData = {
        webinar_id: props.metaData[0],
        title: updateWebinarData.up_webinar_title,
        description: updateWebinarData.up_webinar_description,
        type:updateWebinarData.type,
        agenda: updateWebinarData.up_webinar_agenda,
        start_date: moment(updateWebinarData.up_webinar_date).format('YYYY-MM-DD') + " " + moment(updateWebinarData.up_webinar_start_time).format("HH:mm:ss"),
        end_date: moment(updateWebinarData.up_webinar_date).format('YYYY-MM-DD') + " " + moment(updateWebinarData.up_webinar_end_time).format("HH:mm:ss"),
        speakers:account_manager,
        persons_involved:persons_involveds
    }
    dispatch(updateWebinardata(updatedData));
   
  }

  useEffect(() => {
  }, [props.rowId, props.metaData])

  useEffect(() => {
    getManagers();
    getPersonsList();
  }, [])



  const removeFilterUserListBadge = (value) => {
    //console.log("value is", value)
    var inputValue = value.target.getAttribute('data');
    var inputValue2 = value.target.getAttribute('value');
    const filterAccountManagers = filterAccountManager.filter(item =>  item !== inputValue);
    setFilterAccountManager(filterAccountManagers);
  }

  const removeFilterUserListBadge1 = (value) => {
    //console.log("value is", value)
    var inputValue = value.target.getAttribute('data');
    var inputValue2 = value.target.getAttribute('value');
    const filterAccountManagers1 = filterPersonsInvloved.filter(item =>  item !== inputValue);
    setFilterPersonsInvloved(filterAccountManagers1);
  }
  const FilterUser = (props) => {
    return <span>{JSON.stringify(props)}</span>
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => setContactShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
          {"Update Webinar"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateWebinarHandleSubmit(UpdateWebinarHandle)}>
            <div className="row headerBand">
              <label>Update Webinar</label>
            </div>
            <br />
            <div className='row'>
            <div className='col-md-6'>
            <TextField variant="outlined"
                  {...updatewebinarRegister("type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={updateWebinarData.type}
                  select
                  helperText={updateWebinarErrors.type ? updateWebinarErrors.type.message : ''}
                  error={Boolean(updateWebinarErrors.type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="type" label="Type"
                >
                  {typeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
         </div>
            </div>
            <br />
            <div class="row">
            <div className="col-md-6">
                <TextField variant="outlined"
                  {...updatewebinarRegister("up_webinar_title", {
                    minLength: {
                      value: 3,
                      message: "Task Title must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={updateWebinarErrors.up_webinar_title ? updateWebinarErrors.up_webinar_title.message : ''}
                  error={Boolean(updateWebinarErrors.up_webinar_title)}
                  type="text" className="form-control" value={up_webinar_title} name="up_webinar_title" label="Task Title" onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className="form-control"
                        label="Task Date"
                        inputVariant="outlined"
                        value={updateWebinarData.up_webinar_date}
                        onChange={(date) => setUpdateWebinarData({...updateWebinarData, up_webinar_date: moment(date).format('YYYY-MM-DD') })}
                        showTodayButton
                        labelFunc={formatWeekSelectLabel}
                        minDate={ permissions.includes('EnableBackDate') ? true :  today.setDate(today.getDate() - 1) }
                        inputRef={task_date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                 </MuiPickersUtilsProvider>
             
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        className="form-control"
                        label="Start Time"
                        inputVariant="outlined"
                        value={updateWebinarData.up_webinar_start_time}
                        onChange={(date) => setUpdateWebinarData({...updateWebinarData, up_webinar_start_time: date })}
                        showTodayButton
                        inputRef={start_time}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        className="form-control"
                        label="End time"
                        inputVariant="outlined"
                        value={updateWebinarData.up_webinar_end_time}
                        onChange={(time) => setUpdateWebinarData({...updateWebinarData, up_webinar_end_time: time })}
                        showTodayButton
                        inputRef={end_time}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
             </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
         
    
            <div className="row">
            <div class="col-md-6">
              <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterAccountManager}
                        options={accountManagers}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}
                        placeholder="Speakers"
                        name="accountManagers"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterAccountManager(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                 
    
              </div> 
            <div className='col-md-6'>
            <div>
                      {
              filterAccountManager.length > 0 ?
                filterAccountManager.map((filter) => {
                    var user = accountManagers.find(item => item.value == filter)
                    return <>
                    <Badge onClick={removeFilterUserListBadge}
                    pill data={user?user.value:filter.value} value={user?user.label:filter.label} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{user?user.label:filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                </>
                }
                ): ""}
                      </div>
    </div>
            </div>
            <br/>
            <div className="row">
              <div class="col-md-6">
                <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterPersonsInvloved}
                        options={personsInvloved}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}
                        placeholder="Persons Involved"
                        name="personsInvloved"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterPersonsInvloved(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
  
              </div>
              <div class="col-md-6">
              <div>
                      {
              filterPersonsInvloved.length > 0 ?
                filterPersonsInvloved.map((filter) => {
                    var user = personsInvloved.find(item => item.value == filter)
                    return <>
                    <Badge onClick={removeFilterUserListBadge1}
                    pill data={user?user.value:filter.value} value={user?user.label:filter.label} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{user?user.label:filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                </>
                }
                ): ""}
                      </div>
              </div>
       </div>
<br/>
            <div className='row'>
                <div className='col-md-6'>
                <TextField variant="outlined"
                 multiline row={2}
                  {...updatewebinarRegister("up_webinar_description", {
                  })}
                  helperText={updateWebinarErrors.up_webinar_description ? updateWebinarErrors.up_webinar_description.message : ''}
                  error={Boolean(updateWebinarErrors.up_webinar_description)}
                  type="text" className="form-control" value={up_webinar_description} name="up_webinar_description" label="Description" onChange={e => onInputChange(e)} />
                </div>
                <div className='col-md-6'>
                <TextField variant="outlined"
                 multiline row={2}
                  {...updatewebinarRegister("up_webinar_agenda", {
                  })}
                  helperText={updateWebinarErrors.up_webinar_agenda ? updateWebinarErrors.up_webinar_agenda.message : ''}
                  error={Boolean(updateWebinarErrors.up_webinar_agenda)}
                  type="text" className="form-control" value={up_webinar_agenda} name="up_webinar_agenda" label="Agenda" onChange={e => onInputChange(e)} />
                </div>
                
            </div>
            <br />
            <div className="row">
              <div>
          
               <button type="submit" disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Update</button>
          
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    
    </>
  )

}
export default UpdateWebinarModal;