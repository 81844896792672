import React, { useState, useEffect } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import LeaveRequestTable from '../../components/commonColumns/LeaveRequestTable';
import { connect, useSelector } from 'react-redux';
import {getleavelist,LeaverequestActions} from "../../store/Leaverequest"
import { useMemo } from 'react';
import LeaveModal from '../../components/pageModals/LeaveModal';
import { Button } from 'react-bootstrap';

const Leave = React.memo (( {leavelist,getleavelist,user,setType,showleavemodal,openshowModal,Type}) => {
  const userData = user.user;
  const permissions = user.permissions;
  const fechData = async () => {
    const userid = {
     user_id:userData?._id
    }
    getleavelist(userid)
   }
   const setViewModal = (type) => {
    setType(type)
    // setType(type)
    openshowModal()
    // setShowLeaveModal(true)
  }
   useMemo(() => {
    fechData()
   },[])
 
   
    return (
    <>
      <Navbar value="leave" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
      <div className="request_teche my-3" >
      {permissions.includes("canCreateLeaveRequest") ?
        <>
          <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setViewModal('leave')} >Leave Request</Button>
          {showleavemodal ? <LeaveModal /> : ""}
        </>
        : false}

      {permissions.includes("canCreateLeaveRequest") ?
        <>
          <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setViewModal('wfh')} >Work from home (WFH)</Button>
          {showleavemodal ? <LeaveModal/> : ""}
        </>
        : false}
    </div>
        <div className="demo_table">
        <LeaveRequestTable/>
        
        </div>
      </div>
    </>
  )

});
const mapStateToProps = (state) => ({
  leavelist : state.LeaveRequestlists.leavelist,
  user : state.auth.user,
  showleavemodal:state.LeaveRequestlists.showleavemodal,
  Type:state.LeaveRequestlists.Type
});

const mapDispatchToProps = (dispatch) => ({

  getleavelist: (data) => dispatch(getleavelist(data)),
  setType:(data) => dispatch(LeaverequestActions.setType(data)),
  openshowModal:(data) => dispatch(LeaverequestActions.showleaveOpenmodal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Leave);

