import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AddNewContact from "../pageModals/AddNewContact";
import moment from 'moment';
import { useSelector,useDispatch, connect } from 'react-redux';
import {techmeetingcreate,TechieMettingActions} from "../../store/TechieMetting"
import {ContactActions } from "../../store/Contact"
import {CommonsliceActions} from "../../store/Common"
// import getEconomicBuyersList
import { getEconomicBuyersList } from '../../store/leads';
const TechieMettingModal = React.memo (({techiecreate,newselectedAccount, newaccountId,AddcontactModal,openshowModal,closemodal,ContactopenModal,economicBuyerList,getsetEconomicBuyersList}) => {
  const dispatch = useDispatch();
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  console.log("company name is",selectedAccount)
  const [accountId, setAccountId] = useState(false);
  const [productList, setProductList] = useState([]);
  const [mettingTypeList, setMettingTypeList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [contactsList, setContactsList] = useState([]);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const meetingType = [{ label: "Post Sales", value: 'Post Sales' }, { label: "Pre Sales", value: 'Pre Sales' }]
  const [techieData, setTechieData] = useState({
    account_id: selectedAccount,
    contact_id: "",
    meeting_type: "",
    meeting_note: ""
  });
  // console.log("busyer list is", economicBuyerList)
  const { register: trainingRegister, handleSubmit: trainingHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: trainingErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  var today = new Date();
  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');
  const new_date_time = useRef();
  const closure_date = useRef();
  const ProductList = async () => {
    const res = await axios.get("/products/search")
    setProductList(res.data.productsList)
  }
  const getMettingTypeList = () => {
    setMettingTypeList([{ label: "Online", value: 'Online' }, { label: "Offline", value: 'Offline' }])
  }

  useEffect(() => {
    ProductList();
    getMettingTypeList();
  }, [])


  const onInputChange = (e) => {
    setTechieData({ ...techieData, [e.target.name]: e.target.value }, []);
  };

  const addNewTechieMeeting = async (e) => {
    setDisable(true);
    
    setTimeout(() => { }, 500);
    const updatedData = {
      account_id: accountId,
      contact_id: techieData.contact_id,
      meeting_type: techieData.meeting_type,
      meeting_note: techieData.meeting_note,
      type: techieData.type

    }
    techiecreate(updatedData)
      

    reset({
      keepErrors: false,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    })
    // try {
    //   const res = await axios.post("/meetings/techie-meeting/create", updatedData);
    //   if (res.data.status === "success") {
    //     setTimeout(() => {
    //       setOpen(true);
    //       setSnackBarMessage("Meeting Successfully Added...!");
    //       props.onHide();
    //     }, 2000);
    //     setSnackBarMessage("Meeting Successfully Added...!");
    //     handleClick();
    //   } else {
    //     setSnackBarMessage("Error in adding Meeting...!");
    //   }
    //   props.refreshMettingData();


    //   reset({
    //     keepErrors: false,
    //     keepDirty: true,
    //     keepIsSubmitted: false,
    //     keepTouched: false,
    //     keepIsValid: false,
    //     keepSubmitCount: false,
    //   })



    //   setDisable(true);
    // } catch (err) {
    //   setDisable(false);
    //   if (err.response.status === 400) {
    //     trainingSetError("at_" + err.response.data.key, {
    //       type: "manual",
    //       message: err.response.data.message,
    //     });
    //   }
    //   if (err.response.status === 403) {
    //     setSnackBarMessage(err.response.data.msg)
    //     setTimeout(() => {
    //       setOpen(true);
    //     }, 500);

    //   }

    // }
  }

  const getAccountsList = async (search) => {
    setCompaniesList([]);
    const res = await axios.get("/accounts/search-company/" + search.target.value);
    setCompaniesList(res.data.AccountsList);
  }

  const handleSetCompanyName = (value) => {

    setSelecteAccount(value.label)
    newselectedAccount(value.value)
    if (value.__isNew__ === true) {
    } else {
      setAccountId(value.value);
      newaccountId(value.value);
      // getContactList(value.value);
      getsetEconomicBuyersList(value.value);
    }
  }


  // const getContactList = async (data) => {

  //   setCompaniesList([]);
  //   const res = await axios.get("/demos/economic-buyer-list/" + data);
  //   setContactsList(res.data.economicBuyerList);

  // }
  useEffect(() => {
    setCompaniesList(techieData.account_id);
  }, [])

  const handleSetContactName = (value) => {


    if (value.__isNew__ !== true) {
      techieData.contact_id = value.value;

    }

    if (value.__isNew__ === true) {

      ContactopenModal()
      // setShowAddContactModal(true);

//       setShowAddContactModal(true);
      // dispatch(ContactActions.AddContactopenModal());

    }

  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };
  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    techieData.new_date_time = newDate;
  }


  // const refreshContactsList = async () => {
  //   getContactList(accountId);
  // }

  const returnValue = async (contactId) => {
    techieData.contact_id = contactId;
  }
  return (
    <>
      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Meeting Request"}
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={trainingHandleSubmit(addNewTechieMeeting)}>
            <div class="row">
              <div class="col-md-6">
                <Select
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Account Name"
                  styles={colourStyles}
                  onChange={handleSetCompanyName}
                  onKeyDown={getAccountsList}
                ></Select>
              </div>
              <div className="col-md-6">
                <CreatableSelect
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={economicBuyerList}
                  placeholder="Search Contact Person"
                  name="contact_name"
                  onChange={handleSetContactName}
                  styles={colourStyles}
                  autoComplete="none"
                  required
                  formatCreateLabel={() => `Add New Contact`}
                  isValidNewOption={() => true}
                >
                </CreatableSelect>
              </div>
            </div>
            <br />
            
            <div className="row">

              <div class="col-md-6">

                <TextField variant="outlined"
                  {...trainingRegister("meeting_type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={techieData.meeting_type}
                  select
                  helperText={trainingErrors.meeting_type ? trainingErrors.meeting_type.message : ''}
                  error={Boolean(trainingErrors.meeting_type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="meeting_type" label="Metting Type"
                >
                  {mettingTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>

              <div class="col-md-6">

                <TextField variant="outlined"
                  {...trainingRegister("type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={techieData.type}
                  select
                  helperText={trainingErrors.type ? trainingErrors.type.message : ''}
                  error={Boolean(trainingErrors.type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="type" label="Type"
                >
                  {meetingType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>

            </div>
            <br />
            <div className="row">

              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...trainingRegister("meeting_note", {
                    required: {
                      value: true,
                      message: "Remarks is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Remarks must be at least 5 characters"
                    }

                  })}
                  helperText={trainingErrors.meeting_note ? trainingErrors.meeting_note.message : ''}
                  error={Boolean(trainingErrors.meeting_note)}
                  required
                  type="text" className="form-control" value={techieData.meeting_note} name="meeting_note" label="Meeting Note" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* {AddcontactModal ? (
        <AddNewContact/>
      ) : null} */}
       {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={selectedAccount} otherAccountId={accountId} returnValue={(contactId) => returnValue(contactId)} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}

    </>
  )


});
const mapStateToProps = (state) => ({

    user : state.auth.user,
   AddcontactModal : state.Contactlist.AddcontactModal,
   economicBuyerList:state.leads.economicBuyerList
   
});
const mapDispatchToProps = (dispatch) => ({
   techiecreate:(data) => dispatch(techmeetingcreate(data)),
   openshowModal:(data) => dispatch(TechieMettingActions.showTechie_OpenModal(data)),
   closemodal:(data) => dispatch(TechieMettingActions.showTechie_CloseModal(data)),
   ContactopenModal:() => dispatch(ContactActions.AddContactopenModal()),
   newaccountId:(data) => dispatch(CommonsliceActions.setRowId(data)),
   newselectedAccount:(data) => dispatch(CommonsliceActions.setcompanyName(data)),
   getsetEconomicBuyersList:(data) => dispatch(getEconomicBuyersList(data))
});
export default connect(mapStateToProps,mapDispatchToProps) (TechieMettingModal)
