import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import Select from 'react-select';
import { components } from "react-select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Badge from 'react-bootstrap/Badge'
import { AiOutlineBarChart } from 'react-icons/ai';
import { AiOutlineDatabase } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import OpportunitiesTable from "../../components/commonColumns/OpportunitiesTable";
import {getYears, getManagers, getSalesStages, getBusinessTypes, getSalesConfidenceStages, getRegions, getStatesList, fetchForecastSalesStagesData} from "../../store/dashboard";
import { useDispatch, useSelector, connect } from "react-redux";
import { opportunitiesActions } from "../../store/opportunities";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'

const PlotlyComponent = createPlotlyComponent(Plotly);
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};



const ForecastSalesStagesReport = React.memo(({getStatesListData,getRegionsData,getSalesConfidenceStagesData,getBusinessTypesData,getSalesStagesData,getManagersData,getYearsData, fetchForecastSalesStagesDataList, regionsList, forecastSalesStagesData, forecastSalesStagesTableData, months, quartersList, accountManagersList, salesStagesList, businessTypesList, salesConfidenceList, statesList,getForecastSalesStagesLoader,setOpportunitiesDataTabel}) => {
  
  var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const handle = useFullScreenHandle();
  // const [months, setMonths] = useState([]);
  // const [years, setYears] = useState([]);
  // const [salesStages, setSalesStages] = useState([]);
  // const [accountManagers, setAccountManagers] = useState([]);
  // const [quarters, setQuarters] = useState([]);
  // const [businessTypes, setBusinessTypes] = useState([]);
  // const [regionsList, setRegionsList] = useState([]);
  // const [statesList, setStatesList] = useState([]);
  const[tableData,setTableData] =useState([])
  const [filterMonth, setFilterMonth] = useState([]);
  const [filterYear, setFilterYear] = useState([]);
  const [filterAccountManager, setFilterAccountManager] = useState([]);
  const [filterSalesStage, setFilterSalesStage] = useState([]);
  const [filterSalesConfidenceStage, setFilterSalesConfidenceStage] = useState([]);
  const [filterQuarter, setFilterQuarter] = useState([]);
  const [filterBusinessType, setFilterBusinessType] = useState([]);
  const [filterRegion, setFilterRegion] = useState([]);
  const [filterState, setFilterState] = useState([]);
  // const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const [showChart, setShowChart] = useState(true);
  const [tableHeight, setTableHeight] = useState("333px");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  // const [salesConfidenceStages, setSalesConfidenceStages] = useState([]);

  // const dispatch = useDispatch();
  // const {   } = useSelector(state => state.dashboard);


  const reportChange = ((state, handle) => {
    var forecastSalesChart = document.getElementById("forecastSalesStagesComponent");
    if (forecastSalesChart) {
      var forecastSalesChart = document.getElementById("forecastSalesStagesComponent").getElementsByClassName("user-select-none")[0];;
      forecastSalesChart.id = "forecastSalesStagesChart";
    }

    if (state) {
      if (forecastSalesChart) {
        document.getElementById("forecastSalesStagesChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }

      setTableHeight((window.innerHeight - 160).toString() + 'px');
      setRowsPerPage(15);
      setColumnsDisplay(false);
      setFilterDisplay(false);
    } else {
      if (forecastSalesChart) {
        document.getElementById("forecastSalesStagesChart").style.height = "450px";
      }

      setTableHeight('333px');
      setRowsPerPage(10);
      setColumnsDisplay(true);
      setFilterDisplay(true);
    }


  });

  const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'forecast Sales Stages Report Chart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:")
});

}
  
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};

function downloadChartAsPng(gd) {
  const chartContainer = document.getElementById(gd.id);
  const width = 1200;
  const height = 800;

  // Create a data URL of the chart as a PNG image
  Plotly.toImage(chartContainer, { format: 'png', width: width, height: height })
    .then(function(url) {
      // Trigger download using the created data URL
      const a = document.createElement('a');
      a.href = url;
      a.download = 'forecast Sales Stages Report Chart.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
}

  const getForecastChartData = async () => {
    var account_manager = filterAccountManager.map(item => {
      return item.value;
    });

    var sales_confidence_stages = filterSalesConfidenceStage.map(item => {
      return item.value;
    });

    var sales_stages = filterSalesStage.map(item => {
      return item.value;
    });

    var months = filterMonth.map(item => {
      return item.value;
    });

    var quarters = filterQuarter.map(item => {
      return item.value;
    });

    var business_types = filterBusinessType.map(item => {
      return item.value;
    });


    var region = filterRegion.map(item => {
      return item.value;
    });

    var state = filterState.map(item => {
      return item.value;
    })

    // const res = await axios.post('/user/reports/forecast-sales-stages', );
    var filterObj = {
      months: months,
      quarters: quarters,
      account_manager: account_manager,
      sales_stages: sales_stages,
      sales_confidence_stages: sales_confidence_stages,
      user_id: userData._id,
      business_type: business_types,
      region : region,
      state : state
    }

  
  /// only for Loader purpose///
      const DemoData={
        months: months,
      quarters: quarters,
      account_manager: account_manager,
      sales_stages: sales_stages,
      sales_confidence_stages: sales_confidence_stages,
      // user_id: userData._id,
      business_type: business_types,
      region : region,
      state : state
      }
  /// only for Loader purpose///

  
    fetchForecastSalesStagesDataList([filterObj,DemoData])
  }


  const removeFilterMonthBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterMonth.filter(item => item.value !== inputValue);
    setFilterMonth(filterMonths);
  }

  const removeFilterQuarterBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterQuarter.filter(item => item.value !== inputValue);
    setFilterQuarter(filterMonths);
  }

  const removeFilterAccountManagerBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterAccountManager.filter(item => item.value !== inputValue);
    setFilterAccountManager(filterMonths);
  }

  const removeFilterSalesStagesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterSalesStage.filter(item => item.value !== inputValue);
    setFilterSalesStage(filterMonths);
  }


  const removeFilterSalesConfidenceStagesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterSalesConfidenceStage.filter(item => item.value !== inputValue);
    setFilterSalesConfidenceStage(filterMonths);
  }

  const removeFilterBusinessTypesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterBusinessType.filter(item => item.value !== inputValue);
    setFilterBusinessType(filterMonths);
  }

  const removeFilterRegionTypesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterRegion.filter(item => item.value !== inputValue);
    setFilterRegion(filterMonths);
  }
  const removeFilterStatesTypesBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterMonths = filterState.filter(item => item.value !== inputValue);
    setFilterState(filterMonths);
  }

  //   const filterRegions = filterRegion.filter(item => item.value !== inputValue);
  //   setFilterRegion(filterRegions);
  // }
  
  // const removeFilterstateTypesBadge = (value) => {
  //   var inputValue = value.target.getAttribute('value');

  //   const filterStates = filterState.filter(item => item.value !== inputValue);
  //   setFilterState(filterStates);
  // }
  // const getManagers = async () => {
  //   const resdata = await axios.get('user/account-managers-list');
  //   if (resdata.data.status === "success") {
  //     setAccountManagers(resdata.data.usersList);
  //   } else {
  //   }
  // }

  // const getMonths = () => {
  //   setMonths([{ label: 'January', value: 'Jan' }, { label: 'February', value: 'Feb' }, { label: 'March', value: 'Mar' }, { label: 'April', value: 'Apr' }, { label: 'May', value: 'May' }, { label: 'June', value: 'Jun' }, { label: 'July', value: 'Jul' }, { label: 'August', value: 'Aug' }, { label: 'September', value: 'Sep' }, { label: 'October', value: 'Oct' }, { label: 'November', value: 'Nov' }, { label: 'December', value: 'Dec' }])
  // }

  // const getQuarters = () => {
  //   setQuarters([{ label: "Q1", value: 'Q1' }, { label: "Q2", value: 'Q2' }, { label: "Q3", value: 'Q3' }, { label: "Q4", value: 'Q4' }])
  // }

  // const getYears = async () => {
  //   const resdata = await axios.get('user/reports/years-list');
  //   if (resdata.data.status === "success") {

  //     setYears(resdata.data.yearsList);
  //   } else {
  //   }
  // }

  // const getSalesConfidenceStages = async () => {
  //   const resdata = await axios.get('user/reports/sales-confidence-stages-list');
  //   if (resdata.data.status === "success") {

  //     setSalesConfidenceStages(resdata.data.salesConfidenceStagesList);
  //   } else {
  //   }
  // }

    // const getRegionsList = async () => {
    //   const resdata = await axios.get('user/reports/regions-list');
    //   if (resdata.data.status === "success") {
    //     setRegionsList(resdata.data.regionsList);
    //   } else {
    //   }
    // }


  // const getBusinessTypes = async () => {
  //   const resdata = await axios.get('user/reports/business-types-list');
  //   if (resdata.data.status === "success") {
  //     setBusinessTypes(resdata.data.businessTypes);
  //   } else {
  //   }
  // }

  // const getStatesList = async() => {
  //   const resdata = await axios.get('user/reports/states-list');
  //   if (resdata.data.status === "success") {
  //     setStatesList(resdata.data.statesList);
  //   } else {
  //   }
  // }

  // const getSalesStages = async () => {
  //   const resdata = await axios.get('user/reports/sales-stages-list');
  //   if (resdata.data.status === "success") {

  //     setSalesStages(resdata.data.salesStagesList);
  //   } else {
  //   }
  // }
  // const [data, setData] = useState([]);

  // useEffect(() => {

  //   if(forecastSalesStagesData.length > 0){
  //     console.log("forecast sales  stages ", forecastSalesStagesData)
  //     var items = []
  //     setData(items);
  //   }
  //   console.log("forecast", forecastSalesStagesData)
  // }, [forecastSalesStagesData]) 

  const chartButtonClicked = async () => {

    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }



  useMemo(() => {
    getForecastChartData();
  }, [filterMonth, filterYear, filterAccountManager, filterSalesStage, filterSalesConfidenceStage, filterQuarter, filterBusinessType, filterState, filterRegion]);

  useMemo(() => {
    getForecastChartData();
    getManagersData();
    getYearsData();
    getSalesStagesData();
    getBusinessTypesData();
    getSalesConfidenceStagesData();
    getRegionsData();
    getStatesListData();
  }, [])

  const layout = {
    paper_bgcolor: 'transparent ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    yaxis: {
      tickformat: ' Cr',
      ticksuffix: ' Cr'
    },
    title: "Revenue Forecast",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }
 
  const options = {
    textLabels: {
      body: {
        noMatch: "Loading.."
      }
    },
    filter: filterDisplay,
    viewColumns: columnsDisplay,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: rowsPerPage,
    tableBodyHeight: tableHeight,
    tableBodyMaxHeight: tableHeight,
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: false,
    download: false,
    print: false
  };

useEffect(() => {
  // setOpportunitiesDataTabel(forecastSalesStagesTableData)
  setTableData(forecastSalesStagesTableData)
},[forecastSalesStagesTableData])
  const columns = [

    {
      name: "user_id",
      label: "Account Manager",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.first_name : "user not found"
        ),
        display: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },
    {
      name: "company_name",
      label: "Company Name",
      options: {
        filter: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            left: "0",
            background: "white",
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            left: 0,
            background: "#e6e6e6",
            zIndex: 101
          }
        })
      }
    },
    {
      name: "po_value",
      label: "Po Value",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, meta) => {
          return (<span>  {value ? "₹" + nf.format(value) : ""}</span>)
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },
    {
      name: "revenue_to_kaizenat",
      label: "Revenue to Kaizenat",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, meta) => {
          return (<span>  {value ? "₹" + nf.format(value) : ""}</span>)
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },
    {
      name: "principal_in_dollars",
      label: "Principal(dollars)",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, meta) => {
          return (<span>  {value ? "₹" + nf.format(value) : ""}</span>)
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },
    {
      name: "principal_in_rupees",
      label: "Principal(rupees)",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, meta) => {
          return (<span>  {value ? "₹" + nf.format(value) : ""}</span>)
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },
    {
      name: "opportunity_name",
      label: "Opportunity Name",
      options: {
        filter: true,
        display: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
            fontWeight: 'bold'
          }
        })
      }
    },

  ];

  return (
    <>
    {getForecastSalesStagesLoader ?
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
  <>
  <div ref={containerRef}>
    
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >

        <div className="all_feilds_css py-2">
          <div className="box11">
            <button onClick={chartButtonClicked}
              className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
          </div>
          <div className="box11">
            <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => setShowChart(false)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
          </div>
          <div className="box11">
            <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
              <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
            </button>
          </div>
          <div className="box1">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterMonth}
          options={months}
          placeholder="Month"
          name="filterMonth"
          defaultValue="+91"
          onChange={value => setFilterMonth(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
          </div>
          <div className="box1">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterQuarter}
          options={quartersList}
          placeholder="Quarter"
          name="filterQuarter"
          defaultValue="+91"
          onChange={value => setFilterQuarter(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
          </div>
          {(userData?.user_role_id?.role_name !== "user") ?
            <div className="box1">

            <Select
            className="select_value"
            classNamePrefix="select"
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            isSearchable="true"
            allowSelectAll={true}
            components={{
              Option
            }}
            value={filterAccountManager}
            options={accountManagersList}
            placeholder="Ac. Managers"
            name="filterAccountManager"
            onChange={value => setFilterAccountManager(value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />

            </div>
            : " "}
          <div className="box1">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterSalesStage}
          options={salesStagesList}
          placeholder="Stages"
          name="filterSalesStage"
          defaultValue="+91"
          onChange={value => setFilterSalesStage(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />

          </div>
          <div className="box1">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterSalesConfidenceStage}
          options={salesConfidenceList}
          placeholder="Confidence"
              name="filterSalesConfidenceStage"
              defaultValue="+91"
              onChange={value => setFilterSalesConfidenceStage(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
          </div>
          <div className="box2">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterBusinessType}
          options={businessTypesList}
          placeholder="Business Types"
          name="filterBusinessType"
          defaultValue="+91"
          onChange={value => setFilterBusinessType(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />

          </div>
          <div className="box2">

          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterRegion}
          options={regionsList}
          placeholder="Region"
          name="filterRegion"
          onChange={value => setFilterRegion(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />

          </div>
          
          <div className="box2">
          <Select
          className="select_value"
          classNamePrefix="select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          isSearchable="true"
          allowSelectAll={true}
          components={{
            Option
          }}
          value={filterState}
          options={statesList}
          placeholder="States"
          name="filterState"
          onChange={value => setFilterState(value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
          </div>

        </div>

        <div className="row">
          <div className="col-md-12">
            {
              filterMonth.length > 0 ?
                filterMonth.map((filter) => (
                  <Badge onClick={removeFilterMonthBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterQuarter.length > 0 ?
                filterQuarter.map((filter) => (
                  <Badge onClick={removeFilterQuarterBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterAccountManager.length > 0 ?
                filterAccountManager.map((filter) => (
                  <Badge onClick={removeFilterAccountManagerBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterSalesStage.length > 0 ?
                filterSalesStage.map((filter) => (
                  <Badge onClick={removeFilterSalesStagesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterSalesConfidenceStage.length > 0 ?
                filterSalesConfidenceStage.map((filter) => (
                  <Badge onClick={removeFilterSalesConfidenceStagesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterBusinessType.length > 0 ?
                filterBusinessType.map((filter) => (
                  <Badge onClick={removeFilterBusinessTypesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
            {
              filterRegion.length > 0 ?
              filterRegion.map((filter) => (
                  <Badge onClick={removeFilterRegionTypesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
            { 
              filterState.length > 0 ?
              filterState.map((filter) => (
                  <Badge onClick={removeFilterStatesTypesBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

          </div>
        </div>

        {showChart ?
          <PlotlyComponent
     
            data={JSON.parse(JSON.stringify(forecastSalesStagesData))}
            layout={layout}
            useResizeHandler
            className="w-full h-full"
            config={config}
            id="forecastSalesStagesComponent"
          />
          : <React.Fragment>
            <OpportunitiesTable ForecastSalesChartTableData={tableData} keyData={"forecastSalesChart"} tableHeight={tableHeight} />
          </React.Fragment>}
      </FullScreen>
      </div>
      </>
    }
    </>
  )
});

// export default ;.

const mapStateToProps = (state) => ({
  regionsList : state.dashboard.regionsList,
  forecastSalesStagesData : state.dashboard.forecastSalesStagesData,
  forecastSalesStagesTableData : state.dashboard.forecastSalesStagesTableData,
  months : state.dashboard.months,
  quartersList : state.dashboard.quartersList,
  accountManagersList : state.dashboard.accountManagersList,
  salesStagesList : state.dashboard.salesStagesList,
  businessTypesList : state.dashboard.businessTypesList,
  salesConfidenceList : state.dashboard.salesConfidenceList,
  statesList : state.dashboard.statesList,
  getForecastSalesStagesLoader:state.dashboard.getForecastSalesStagesLoader
});

const mapDispatchToProps = (dispatch) => ({
  setOpportunitiesDataTabel : (data) => dispatch(opportunitiesActions.opportunitiesListSuccess(data)),
  getManagersData: () => dispatch(getManagers()),
  getYearsData : () => dispatch(getYears()),
  getSalesStagesData : () => dispatch(getSalesStages()),
  getBusinessTypesData : () => dispatch(getBusinessTypes()),
  getSalesConfidenceStagesData : () => dispatch(getSalesConfidenceStages()),
  getRegionsData : () => dispatch(getRegions()),
  getStatesListData : () => dispatch(getStatesList()),
  fetchForecastSalesStagesDataList : (data) => dispatch(fetchForecastSalesStagesData(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastSalesStagesReport);
