import React, { useEffect, useState } from 'react';
import Navbar from '../home/Navbar';

import LeadsTable from '../../components/commonColumns/LeadsTable';
import axios from "axios";

import './list.css';

export default function LeadList() {

  // const [data, setData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  // const [progress, setProgress] = useState("Loading...");

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  // const [totalCount, setTotalCount] = useState(0)
  const [searchText, setSearchText] = useState("")
  // const handleChangePage = (newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = numberOfRows => {
  //   setRowsPerPage(numberOfRows);
  //   setPage(0);
  // };

  // const handleSearchText = searchText => {
  //   setSearchText(searchText)
  //   setPage(0)
  // }


  // const fetchData = async (searchParamLeadId = null) => {
  //     try {
  //       const res = await axios.get("/leads/" + "pagination/" + page + "/" + rowsPerPage)
  //       if (res.data.status === "success") {
  //         setProgress("No Records Found.")
  //       } else {
  //         setProgress("No Records Found.")
  //       }
  //       setData(res.data.leads);
  //       setTotalCount(res.data.totalCount)
  //     } catch (err) {
  //       setProgress("Error in Retrieving Data.")
  //     }
  // };

  useEffect(() => {
    let searchParamLeadId = queryParams.get('lead_id') ? queryParams.get('lead_id') : null;
    // fetchData(searchParamLeadId);
    //console.log("this is ", searchParamLeadId)
  }, [page, rowsPerPage, searchText]);
  
  return (
    <>
    {/* reFreshNewLeadTable={() => fetchData()}  */}
      <Navbar value="leads" className="activity_list_navbar" />
      <LeadsTable 
      // data={data} page={page} totalCount={totalCount}
      //  rowsPerPage = {rowsPerPage} handleChangePage={(page) => handleChangePage(page)}
      //  handleSearchText = {(searchText) =>handleSearchText(searchText) }
      //  handleChangeRowsPerPage = {(numberOfRows) => handleChangeRowsPerPage(numberOfRows)} reFreshLeadTable={() => fetchData()} progress={progress} 
      />
    </>
  );
}
