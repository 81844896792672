import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import {updaterawdata,RawdataActions} from "../../store/Rawdatabase"
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from '../commonColumns/commonComponents';
import moment from 'moment-timezone';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const RawdataUpdateModal = React.memo (({rawdataupdate,openmodal,closemodal,Rowid}) => {
  console.log("newRowData ID IS", Rowid)
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
   const[accounttype,Setaccounttype] = useState([])
   const[meddictype,Setmeddictype] = useState([])
   const[jobleveltype,Setjobleveltype] = useState([])
   const [industriesList, setIndustriesList] = useState([]);
  const { register: RawdataupdateRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: rawdataupdateErrors } } = useForm();
  const [rawupdateshow, setrawupdateshow] = useState(false);
  const [Rawdataupdate, SetRawdataupdate] = useState({
    company_name: "",
    account_type:"",
    industry:"",
     website:"",
     address: "",
     city: "",
     state: "",
     postal_code: "",
     country: "",
    contact_person_name: "",
    mobile: "",
    phone: "",
   source:"",
    designation: "",
    department: "",
    job_level:"",
    meddic_role:"",
    email: "",
    no_of_employees:"",
    industry: "",
    statustype: "",
 
    
  
  });
  console.log("new lead details are", Rawdataupdate)
  const [tasksList, setTasks] = useState([]);
  const getIndustriesList = async () => {
    const res = await axios.get("/industries/search");
    setIndustriesList(res.data.industriesList);
  }

  useEffect(() => {
    getIndustriesList();
  }, []);
  
  const getRawdataDetails = async (id) => {
    const res = await axios.get("/user/rawdata/details/" + id);
    const RawDataupdate = res.data.rawdataDetail;
    setTasks(res.data.tasksList);
    console.log('res.data comapny name', RawDataupdate.company_name);
    SetRawdataupdate({
      company_name: RawDataupdate.company_name,
      account_type:RawDataupdate.account_type,
      industry:RawDataupdate.industry,
      website: RawDataupdate.website,
      address: RawDataupdate.address,
      city: RawDataupdate.city,
      state: RawDataupdate.state,
      postal_code: RawDataupdate.postal_code,
      country: RawDataupdate.country,
      contact_person_name: RawDataupdate.contact_person_name,
      mobile: RawDataupdate.mobile,
      phone: RawDataupdate.phone,
      designation: RawDataupdate.designation,
      department: RawDataupdate.department,
      job_level:RawDataupdate.job_level,
      meddic_role:RawDataupdate.meddic_role,
      email: RawDataupdate.email,
      no_of_employees:RawDataupdate.no_of_employees,
      industry: RawDataupdate.industry,
      source:RawDataupdate.source
    });
  };
  useEffect(() => {
    getRawdataDetails(Rowid);
  }, [Rowid]);
  const onInputchange = (e) => {
    SetRawdataupdate({ ...Rawdataupdate, [e.target.name]: e.target.value }, []);
  };
const rawDataSubmitHandle = async () => {
    const activityUpdateformdata = {
      rawdata_id:Rowid,
      company_name: Rawdataupdate.company_name,
      account_type:Rawdataupdate.account_type,
      industry: Rawdataupdate.industry,
      website: Rawdataupdate.website,
      address: Rawdataupdate.address,
      city: Rawdataupdate.city,
      state: Rawdataupdate.state,  
      postal_code: Rawdataupdate.postal_code,
      country: Rawdataupdate.country,
      contact_person_name: Rawdataupdate.contact_person_name,
      mobile: Rawdataupdate.mobile,
      phone: Rawdataupdate.phone,
      designation: Rawdataupdate.designation,
      department: Rawdataupdate.department,
      job_level:Rawdataupdate.job_level,
      meddic_role:Rawdataupdate.meddic_role,
      email: Rawdataupdate.email,
      no_of_employees:Rawdataupdate.no_of_employees,
      source:Rawdataupdate.source
    };
    rawdataupdate(activityUpdateformdata)

   
  }

  


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
 const accountType = async () => {
  const res = await axios.get("/account-type/search ")
try {
  if(res.data.status ==="success"){
     Setaccounttype(res.data.accountList)
  }

} catch (error) {
  alert("accounttype notfound")
}

}
useEffect(() => {
  accountType();
}, []);

const meddicctype = async () => {
const res = await axios.get("/meddic-role/search ")
try {
if(res.data.status ==="success"){
   Setmeddictype(res.data.meddicRolesList)
}

} catch (error) {
alert("meddic role notfound")
}

}
useEffect(() => {
  meddicctype();
}, []);

const joblevel = async () => {
const res = await axios.get("/job-level/search ")
try {
if(res.data.status ==="success"){
   Setjobleveltype(res.data.jobLevelList)
}

} catch (error) {
alert("joblevel notfound")
}

}
useEffect(() => {
joblevel();
}, []);


const Taskcolumns = [
  {
    name: "_id",
    label: "id",
    options: {
      filter: false,
      display: false,
      setCellHeaderProps: headerProps,
    }
  },
  {
    name: "company_name",
    label: "Company Name",
    options: {
      filter: true,
      display: true,
      setCellHeaderProps: headerProps,
    }
  },
  {
    name: "call_type",
    label: "Call Type",
    options: {
      filter: true,
      setCellHeaderProps: headerProps,
    }
  },
  {
    name: "call_objective",
    label: "Call Objective",
    options: {
      filter: true,
      setCellHeaderProps: headerProps,
    }
  },
  {
    name: "remarks",
    label: "Remarks",
    options: {
      filter: true,
      setCellHeaderProps: headerProps,
    }
  },
  {
    name: "meeting_date_time",
    label: "Call Time",
    options: {
      filter: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        value ? <div>{value?.replace("T", " ").replace("Z", "")}</div> : ""
      ),
      // ).format('D MMM  YYYY HH:mm:ss')
      setCellHeaderProps: headerProps,
    }
  }
];

const tasksOptions = {
  textLabels: {
    body: {
      noMatch:  "No Records Found" 
    }
  },
  filter: false,
  filterType: false,
  responsive: "simple",
  tableBodyHeight: "300px",
  tableBodyMaxHeight: "300px",
  fixedHeader: true,
  fixedSelectColumn: false,
  selectableRows: "none",
  download: false,
  print: false,
  onDownload: (buildHead, buildBody, columns, data) => {
    let rowDatas = data.map(item => {

      const temp = [
        item.data[0] ? item.data[0] : "NA",
        item.data[1] ? item.data[1].replace("T", " ").replace("Z", " ") : "NA",
        item.data[2] ? item.data[2].replace("T", " ").replace("Z", " ") : "NA",
        item.data[3] ? item.data[3] : "NA",
      ]
      return { data: temp }
    })

    let val = `${buildHead(columns)}${buildBody(rowDatas)}`
    return val

  }
};

  return (
    <>
      <Modal
        dialogClassName="modals"
        aria-labelledby="example-custom-modal-styling-title"
        show={() => openmodal()}
        onHide={() => closemodal()}
        backdrop={false}
        disableBackdrop={true}
      >
        <Modal.Header>
          <Modal.Title>Raw Database Update</Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">
            Cancel
          </a>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={contactHandleSubmit(rawDataSubmitHandle)}>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="company_name"
                    label="Company Name"
                    required
                    value={Rawdataupdate.company_name}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                    }}
                                    select
                                  
                                    value={Rawdataupdate.account_type}
                                    onChange={(e) => onInputchange(e)}
                                    required
                                    size="small"
                                    type="text" className="form-control" name= "account_type"  label="Account type
                                    "
                                >
                                    {accounttype.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>    
                </div>
              </div>
              <br />
              <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...RawdataupdateRegister("industry", {
                    minLength: {
                      value: 1,
                      message: "Industry must be at least 1 characters"
                    }
                  })}
                  required
                  helperText={rawdataupdateErrors.industry ? rawdataupdateErrors.industry.message : ''}
                  error={Boolean(rawdataupdateErrors.industry)}
                  select
                  type="text" label="Industry" name="industry" className="form-control"
                  value={Rawdataupdate.industry} onChange={e => onInputchange(e)} >

                  {
                    industriesList.map((option, industry) => (
                      <MenuItem key={option.label} selected={industry === option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="website"
                    label="website"
                    required
                    value={Rawdataupdate.website}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="address"
                    label="Address"
                    value={Rawdataupdate.address}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="city"
                    label="City"
                    required
                    value={Rawdataupdate.city}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="state"
                    label="State"
                    required
                    value={Rawdataupdate.state}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="postal_code"
                    label="Postal code"
                    required 
                    value={Rawdataupdate.postal_code}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="country"
                    label="country"
                    required
                    value={Rawdataupdate.country}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="contact_person_name"
                    label="Contact Name"
                    value={Rawdataupdate.contact_person_name}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="mobile"
                    label="Mobile"
                    value={Rawdataupdate.mobile}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="phone"
                    label="Phone"
                    value={Rawdataupdate.phone}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="designation"
                    label="Designation"
                    value={Rawdataupdate.designation}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="department"
                    label="Department"
                    value={Rawdataupdate.department}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                    }}
                                    select
                                  
                                    value={Rawdataupdate.job_level}
                                    onChange={(e) => onInputchange(e)}
                
                                    size="small"
                                    type="text" className="form-control" name= "job_level"  label="Job Level"
                                    
                                >
                                    {jobleveltype.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>    

                </div>
                <div className="col-md-6">

                <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                    }}
                                    select
                                  
                                    value={Rawdataupdate.meddic_role}
                                    onChange={(e) => onInputchange(e)}
                                    size="small"
                                    type="text" className="form-control" name= "meddic_role"  label="Meddic Role"
                                    
                                >
                                    {meddictype.map((option) => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>    
                
                </div>
              </div>
                  <br />

                  <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={Rawdataupdate.email}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="no_of_employees"
                    label="No of Employees"
                    value={Rawdataupdate.no_of_employees}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="source"
                    label="Source"
                    value={Rawdataupdate.source}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                </div>
                  <br />
                 
              <div className="row">
                <div>
                  <button className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn" type="submit">
                      Update
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="row">
          <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={"Tasks List"}
                data={tasksList}
                columns={Taskcolumns}
                options={tasksOptions}
              />
            </MuiThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
  
    </>
  );

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    Rowid : state.Rawdatalist.rowId, 
});
const mapDispatchToProps = (dispatch) => ({

  rawdataupdate:(data) => dispatch(updaterawdata(data)),
  closemodal:(data) => dispatch(RawdataActions.showUpdateRawdataModal_closemodal(data)),
  openmodal:(data) => dispatch(RawdataActions.showUpdateRawdataModal_openmodal(data))

});
export default connect(mapStateToProps,mapDispatchToProps) (RawdataUpdateModal)


