import React, { useEffect, useState, useCallback } from 'react';
import { getMuiTheme } from "../../components/commonColumns/commonComponents";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { meetingColumns, options } from './commonProps';
import RenderDateFilter from "./RenderDateFilter";
import { getEventReports } from "../../store/EventTracker";
import { connect } from 'react-redux';

const EventMeetings = (props) => {

    const { filters, setFilters, isFetching, getMeetings, meetings } = props

    const fetchData = useCallback(() => {
        let input = {
            event_type: "meetings",
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        getMeetings(input)
    })

    useEffect(() => {
        fetchData()
    }, [filters.startDate, filters.endDate])

    return (
        <>
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                            <b>Meetings List</b>&nbsp;&nbsp;&nbsp;
                            <RenderDateFilter filters={filters} setFilters={setFilters} />
                        </div>
                        }
                        data={meetings}
                        columns={meetingColumns}
                        options={options(isFetching)}
                    />
                </MuiThemeProvider>
            </React.Fragment>
        </>
    )
}

const mapStateToProps = (state) => ({
    isFetching: state.EventTracker.isFetching,
    meetings: state.EventTracker.meetings
})

const mapDispatchToProps = (dispatch) => ({
    getMeetings: (params) => dispatch(getEventReports(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventMeetings);