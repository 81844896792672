import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { connect, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {TechieMettingActions,getColumnsList} from "../../store/TechieMetting"
import {ContactColumnUpdateddata} from "../../store/Common"


const TechieMettingTable = React.memo (({gettechielist,meetinglistdata,user,isFetching,TechieRowMetaData,TechierowId,contactupdate,getColumnsList,columnsList, updateLeaveColumnsData}) => {

  const techieMeetingData = gettechielist
  const userData = user.user;
  
  const handleRowClick = (rowData, rowMeta) => {
    TechieRowMetaData(rowMeta)
    TechierowId(techieMeetingData[rowMeta.dataIndex]._id)
  };

  useEffect(() => {
    getColumnsList();
  }, []);

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "techie_id",
      label: "Techie Name",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('techie_id') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "contact_person",
      label: "Contact Person",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('contact_person') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "meeting_type",
      label: "Meeting Type",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('meeting_type') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('type') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "meeting_note",
      label: "Meeting Note",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('meeting_note') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "updated_at",
      label: "Meeting Date",
      options: {
        filter: true,
        display: columnsList?.includes('updated_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? <div>{moment(value).tz("Asia/Kolkata").format('D MMM  YYYY')}</div> : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },

  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
       
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 210).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 210).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      
      if (action === "add") {
        columns?.push(changedColumn);
      }

      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);
        // var index = columnsList?.indexOf(changedColumn);
        // if (index !== -1) {
        //     columnsList.splice(index, 1);
        //     columnsList(columnsList);
        // }
    }

      const updatedData = {
        user_id: userData._id,
        table_name: "techiemeetings",
        columns_list: columns,
      }
      contactupdate(updatedData)
      updateLeaveColumnsData(columns)
    },
    download: false,
    onRowClick: handleRowClick,
  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Techie Meeting List"}
          data={techieMeetingData}
          // dataRequstor={props.dataRequstor}
          columns={columns}
          options={options}
        />

      </MuiThemeProvider>
     
    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  gettechielist : state.Techiemettinglist.gettechielist,
  isFetching : state.Techiemettinglist.isFetching,
  columnsList:state.Techiemettinglist.columnsList
});
  
const mapDispatchToProps = (dispatch) => ({
  TechieRowMetaData:(data) => dispatch(TechieMettingActions.setTechierowMetaData(data)),
  TechierowId:(data) => dispatch(TechieMettingActions.setTechieRowId(data)),
  contactupdate:(data) => dispatch(ContactColumnUpdateddata(data)),
  getColumnsList:() => dispatch(getColumnsList()),
  updateLeaveColumnsData: (data) => dispatch(TechieMettingActions.updateColumnsList(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (TechieMettingTable)

