import { createSlice } from "@reduxjs/toolkit";
import  Api  from "./axios";
import { toast } from "react-toastify";
import {getPersonsList} from "./leads";
var userObj = JSON.parse(localStorage.getItem("user")) || null;

const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    AddcontactModal:false,
    contactlistdata: [],
    createContact:[],
    viewupdateContact:[],
    Contactcolumnupdated:[],
    rowId:"",
    getcolumn:[],
    rowMetadata:[],
    getviewcontactlists:[],
    columnsList:[],
    industriesList:[],
    companyName:[],
    // contactaccountId:[],
    contactcompanyName:[],
    contactaccountId:[]
}

const Contactslice = createSlice({
    name: "Contactlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          }, 
          AddContactopenModal: (state, action) => {
            state.AddcontactModal = true;
            state.companyName = null
            state.accountId = null 
          },
          AddContactcloseModal: (state, action) => {
            state.AddcontactModal = false; 
          },
          setcompanyName(state, action) {
            state.contactcompanyName = action.payload;
          },
          setAccountId(state, action) {
            state.contactaccountId = action.payload;
          },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },
         
   //CONTACT LIST REDUCERS START HERE
   contactlistsuccess(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.contactlistdata = action.payload;
    // toast.success("contact list data") 
    // toast.success(action.payload.status) 
  },
  contactlistFailure(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.contactlistdata = [];
     toast.error(" Error in contact list")
  },
//Add contact (navbar)
  createContactSuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.createContact = action.payload;
    toast.success("Successfully Added") 
    state.AddcontactModal = false;

  },
  createContactFailure(state,action) {
    state.meetingLoader = false;
    state.error = true;
    state.errorPayload = action.payload;
    state.createContact = [];
    toast.error(action.payload) 
    // state.AddcontactModal = false;
  },


//view contact update (on click table)
  ViewcontactUpdatedSuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.viewupdateContact = action.payload;
    toast.success("Successfully updated") 
    // getcontactlistdata()
    state.showModal = false;
  
    

  },

  viewcontactUpdatedFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.viewupdateContact = [];
     toast.error("Error Not Updated") 
     state.showModal = true;
 

  },

  //get column (table)
  getcontactColumnsuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getcolumn = action.payload;
    // toast.success("column updated ") 

  },
  getcontactColumnFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getcolumn = action.payload;
    // toast.success("column updated ") 

  },


  ContactcolumndataSuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.Contactcolumnupdated = action.payload;
    // toast.success("column updated ") 

  },
  ContactcolumndataFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.Contactcolumnupdated = action.payload;
    // toast.success("column failed ")  `
  },

  // get view contact data
  getViewcontactdatasuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getviewcontactlists = action.payload;
    // toast.success("column updated ") 
  },
  getViewcontactdataFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getviewcontactlists = [];
    // toast.success("column updated ") 
  },

  contactColumnsListSuccess(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.columnsList = action.payload
},
contactColumnsListFailure(state, action){
    state.isFetch = false
    state.error = true
    state.errorPayload = action.payload
    state.columnsList = []
},
industriesListSuccess(state, action){
  state.isFetch = false
  state.error = false
  state.errorPayload = false
  state.industriesList = action.payload
},
industriesListFailure(state, action){
  state.isFetch = false
  state.error = false
  state.errorPayload = false
  state.industriesList = []
},
updateColumnsList(state, action){
  state.columnsList = action.payload
}
    }  
});
export const ContactActions = Contactslice.actions;

//GET CONTACT LIST START HERE
export const getcontactlistdata = (updatedData) => {
    return async (dispatch) => {
      dispatch(ContactActions.loaderStart());
      try {
        await Api.get(`/contacts/${updatedData?.user_id}`).then((res) => {
          if (res.data.status === "success") {
            dispatch(ContactActions.contactlistsuccess(res?.data?.contacts));
          }
          if (res.data.status === "failure") {
            dispatch(ContactActions.contactlistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(ContactActions.contactlistFailure("Network Error"));
      }
    };
  };

  export const CreatedAddcontact = (updatedData) => {
    return async (dispatch) => {
      dispatch(ContactActions.loaderStart("meetingLoader"));
      try {
        await Api.post("contacts/create", updatedData).then((res) => {

          if (res.data.status === "success") {
            dispatch(ContactActions.createContactSuccess(res?.data));
            dispatch(getPersonsList(updatedData.account_id))
            dispatch(getcontactlistdata(updatedData))
          }
          if (res.data.status === "failure") {
            dispatch(ContactActions.createContactFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(ContactActions.createContactFailure("Network Error"));
      }
    };
  };
  
  export const ViewcontactUpdate = (updatedData) => {
    return async (dispatch) => {
      dispatch(ContactActions.loaderStart("meetingLoader"));
      try {
        await Api.post("contacts/update",updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(ContactActions.ViewcontactUpdatedSuccess(res.data));
            dispatch(getcontactlistdata(updatedData)); 
          }
          if (res.data.status === "failure") {
            dispatch(ContactActions.viewcontactUpdatedFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(ContactActions.viewcontactUpdatedFailure("Network Error"));
      }
    };
  };
   
export const getContactColumnListHandle = (userid) => {
  return async (dispatch) => {
    dispatch(ContactActions.loaderStart("meetingLoader"));
    try {
      await Api.get(`/columns-hide-show/list/contacts/${userid}`).then((res) => {
        if (res.data.status === "success") {
          dispatch(ContactActions.getcontactColumnsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(ContactActions.getcontactColumnFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(ContactActions.getcontactColumnFailure("Network Error"));
    }
  };
};
 
export const getViewContactdata = (id) => {
  return async (dispatch) => {
    dispatch(ContactActions.loaderStart("meetingLoader"));
    try {
      await Api.get(`/contacts/details/${id}`).then((res) => {
        if (res.data.status === "success") {
          dispatch(ContactActions.getViewcontactdatasuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(ContactActions.getViewcontactdataFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(ContactActions.getViewcontactdataFailure("Network Error"));
    }
  };
};
   
export const getIndustriesListdata = (id) => {
  return async (dispatch) => {
    dispatch(ContactActions.loaderStart("meetingLoader"));
    try {
      await Api.get("/industries/search").then((res) => {
        if (res.data.status === "success") {
          dispatch(ContactActions.getIndustriesListsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(ContactActions.getIndustriesListFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(ContactActions.getIndustriesListFailure("Network Error"));
    }
  };
};

export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(ContactActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/contacts/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(ContactActions.contactColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(ContactActions.contactColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(ContactActions.contactColumnsListFailure("Network Error"));
      }
  }
}
export const getIndustriesList = () => {
  return async (dispatch) => {
      dispatch(ContactActions.loaderStart())
      try {
          await Api.get("/industries/search").then((res) => {
                  if (res.data.status === "success") {
                      dispatch(ContactActions.industriesListSuccess(res?.data?.industriesList))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(ContactActions.industriesListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(ContactActions.industriesListFailure("Network Error"));
      }
  }
}
  export default Contactslice.reducer;