import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSelector,useDispatch, connect } from 'react-redux';
import {Createcasestudies,CasestudyActions} from "../../store/Casestudy"
const CaseStudy = React.memo (({getcasestudies,createcase,openshowModal,closemodal}) => {

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [accountId, setAccountId] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [contactsList, setContactsList] = useState([]);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSelected, setIsSelected] = useState(false);

  const [techieData, setTechieData] = useState({
    account_id: selectedAccount,
    description: ""
  });

  const { register: trainingRegister, handleSubmit: trainingHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: trainingErrors } } = useForm();
  const {user} = useSelector(state => state.auth)

  var twodays = moment().add(2, 'days');
{/*  
  const changeHandler = (event) => {
    setSelectedFile(event.target.files);
    setIsSelected(true);
  };
*/}

const changeHandler = (event) => {
  const files = event.target.files;
  setIsSelected(true);
  if (files.length > 0) {
    const file = files[0];
    console.log(file); // Check the structure of the first file object
    setSelectedFile(file);
  }
};

  
  const onInputChange = (e) => {
    setTechieData({ ...techieData, [e.target.name]: e.target.value }, []);
  };

  const addNewTechieMeeting = async (e) => {
    setDisable(true);
    setTimeout(() => { }, 500);
    const formData = new FormData();
    formData.append('account_id', accountId);
    formData.append('description', techieData.description);

    if (selectedFile.length > 0) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append('files', selectedFile[i]);
      }
    }
    createcase(formData)
  

    reset({
      keepErrors: false,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    })

    // try {
    //   const res = await axios.post("/user/case-studies/create", formData);
    //   if (res.data.status === "success") {
    //     setTimeout(() => {
    //       setOpen(true);
    //       setSnackBarMessage("Meeting Successfully Added...!");
    //       props.onHide();
    //     }, 2000);

    //     setSnackBarMessage("Meeting Successfully Added...!");
    //     handleClick();


    //   } else {
    //     setSnackBarMessage("Error in adding Meeting...!");
    //   }
    //   props.refreshCaseStudyData();


    //   reset({
    //     keepErrors: false,
    //     keepDirty: true,
    //     keepIsSubmitted: false,
    //     keepTouched: false,
    //     keepIsValid: false,
    //     keepSubmitCount: false,
    //   })

    //   setDisable(true);
    // } catch (err) {
    //   setDisable(false);
    //   if (err.response.status === 400) {
    //     trainingSetError("at_" + err.response.data.key, {
    //       type: "manual",
    //       message: err.response.data.message,
    //     });
    //   }
    //   if (err.response.status === 403) {
    //     setSnackBarMessage(err.response.data.msg)
    //     setTimeout(() => {
    //       setOpen(true);
    //     }, 500);

    //   }

    // }

  }


  const getAccountsList = async (search) => {
    setCompaniesList([]);
    const res = await axios.get("/accounts/search-company/" + search.target.value);
    setCompaniesList(res.data.AccountsList);
  }
  const handleSetCompanyName = (value) => {
    setSelecteAccount(value.value)

    if (value.__isNew__ === true) {

    } else {
      setAccountId(value.value);
    }
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };
  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    techieData.new_date_time = newDate;
  }


  return (
    <>
      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Case Study"}
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={trainingHandleSubmit(addNewTechieMeeting)}>
            <div class="row">
              <div class="col-md-6">
                <Select
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Account Name"
                  styles={colourStyles}
                  onChange={handleSetCompanyName}
                  onKeyDown={getAccountsList}
                ></Select>
              </div>

            </div>
            <br />

            <div className="row">

              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...trainingRegister("description", {
                    required: {
                      value: true,
                      message: "Description is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Description must be at least 5 characters"
                    }

                  })}
                  helperText={trainingErrors.description ? trainingErrors.description.message : ''}
                  error={Boolean(trainingErrors.description)}
                  type="text" className="form-control" value={techieData.description} name="description" label="Description" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />

          {/*  <div className="row">
              <div className="col-md-12">
                  <input type="file" className='custom-file-input' id="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="file" multiple onChange={changeHandler} />
              </div>
                </div>   */}

                <div className="col-md-6">
                <br />
                <div>
                <label htmlFor="file" className="custom-file-input">
               
             
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  multiple
                  style={{ display: 'none' }}
                  onChange={changeHandler}
                />
                {selectedFile && <p>Selected File: {selectedFile?.name}</p>}
                </label>
                </div>
            </div>

            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>

              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


    </>
  )




});

const mapStateToProps = (state) => ({
  user : state.auth.user,
 
});

const mapDispatchToProps = (dispatch) => ({
  createcase :(data) => dispatch(Createcasestudies(data)),
  openshowModal:(data) => dispatch(CasestudyActions.casestudymodal_openModal(data)),
  closemodal:(data) => dispatch(CasestudyActions.casestudymodal_closeModal(data)),
});


export default connect(mapStateToProps,mapDispatchToProps) (CaseStudy)
