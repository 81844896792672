import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import {assignRawdata,RawdataActions} from "../../store/Rawdatabase"
import { connect, useDispatch, useSelector } from 'react-redux';

  const  AssignRawdatauserModal = React.memo(({rawdatalist,openshowmodal,closeshowmodal,leadid}) => {
  // const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [ContactsShow, setHardwareShow] = useState(false);
const[userList,setUserList]= useState([])

const [rejectShow, setRejectShow] = useState(false);
const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
const [assigniRawData, setAssigniRawData] = useState({
  assign_list: '',
})


  const onInputChange = (e) => {
    setAssigniRawData({ ...assigniRawData, [e.target.name]: e.target.value }, []);
  };


  const assignSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
      "assignee_id": assigniRawData.assign_list,
      'rawdatas_id': leadid
    }

    rawdatalist(updatedData)

  }

  
const getUsers = async() => {
  try {
    const res = await axios.get('/user/isales-sales-users');
    if(res.data.status ==="success"){
      setUserList(res.data.list)
    }
  } catch (error) {
    alert(error)
  }
}
useEffect(() => {
  getUsers();
}, []);

  return (
    <>
      <Modal
        dialogClassName="modals"
        aria-labelledby="example-custom-modal-styling-title"
        show={() => openshowmodal()}
        onHide={() => closeshowmodal()}
        backdrop={false}
        disableBackdrop={true}
      >
        <Modal.Header>
          <Modal.Title>Assign To</Modal.Title>
          <a onClick={() => closeshowmodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        
          <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
          <div className="row headerBand">
              <label>Select Account Manager</label>
            </div>
            <br/>
              <div className="row">
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...rejectRegister("assign_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={assigniRawData.assign_list}
                  select
                  helperText={rejectErrors.assign_list ? rejectErrors.assign_list.message : ''}
                  error={Boolean(rejectErrors.assign_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="assign_list" label="Select"
                >
                  {userList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
              </div>

            <div className="row">
              <div>
              <button className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn" type="submit"> Submit</button>
              </div>
            </div>
            </form>
          </Modal.Body>
      </Modal>
     
    </>
  );



});


const mapStateToProps = (state) => ({
  user : state.auth.user,
  leadid : state.Rawdatalist.leadidData, 
});


const mapDispatchToProps = (dispatch) => ({
    rawdatalist : (data) => dispatch(assignRawdata(data)),
    openshowmodal: (data) => dispatch(RawdataActions.AssignRawdataModel_openmodal(data)),
    closeshowmodal: (data) => dispatch(RawdataActions.AssignRawdataModel_closemodal(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(AssignRawdatauserModal)
