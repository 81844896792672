import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import { useSelector, useDispatch, connect } from 'react-redux';
// import {updatetimesheet} from "../../store/Timesheet"
import {updateDailyTask, updateViewModal} from "../../store/timesheet";

const UpdateTimesheetModal = React.memo(({showUpdateTimeSheetModal, updateViewModalData, viewModalData, updateDailyTaskData}) => {

  // console.log("modalData", viewModalData)
  // useEffect(() => {
  //   console.log("viewModalData ", viewModalData)
  // }, [viewModalData])
  // console.log("props", props)



//const UpdateTimesheetModal = (props) => {
//  console.log("props", props)
  const dispatch = useDispatch();

  var today = new Date();
  // var twodays = moment().add(2, 'days');
  const end_time = useRef();
  const start_time = useRef();
  const task_date = useRef();
  const [contactShow, setContactShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [teamMembersList,setTeamMembersList]= useState([]);

  const [statusList, setStatusList] = useState([]);
  const [disable, setDisable] = React.useState(false);

  var start_time_new = moment(viewModalData.metaData?.start_date_time.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT')
  var end_time_new = moment(viewModalData.metaData?.end_date_time.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('LT')
  
  // console.log("starttime", start_time_new)
  // console.log("endtime", end_time_new)
  const [updateTimesheetData, setUpdateTimesheetData] = useState({
    up_task_title:  viewModalData.metaData?.task_title,
    up_task_description:  viewModalData.metaData?.task_description,
    up_task_date : viewModalData.metaData?.start_date_time,
    up_task_start_time: moment(viewModalData.metaData?.start_date_time).format('YYYY-MM-DD')+"T"+moment(start_time_new, 'HH:mm A').format('HH:mm'),
    up_task_end_time:  moment(viewModalData.metaData?.end_date_time).format('YYYY-MM-DD')+"T"+moment(end_time_new, 'HH:mm A').format('HH:mm'),
    up_status:  viewModalData.metaData?.task_status,
    up_assignedto: viewModalData.metaData?.user_id?.first_name
  });
  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  const { register: timesheetRegister, handleSubmit: timesheetHandleSubmit, setError: timesheetSetError, formState: { errors: timesheetErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const userDataId= userData.user_role_id._id
  const permissions = user.permissions;
  const { up_task_title, up_task_description,up_task_date,up_assignedto } = updateTimesheetData;
    //console.log("memebre list name", up_assignedto)
  const onInputChange = (e) =>  {
    setUpdateTimesheetData({ ...updateTimesheetData, [e.target.name]: e.target.value }, []);
  };
  const getAssignedMembersList = async () => {
    const res = await axios.get("/user/team-members-list");
    setTeamMembersList(res.data.list);
}

  const getTimesheetStatus = () => {
    setStatusList([{ label: "Open", value: 'Open' }, { label: "Closed", value: 'Closed' }])
  }
  

  const UpdateTimesheetHandle = async (e) => {
    setDisable(true);
    
    const updatedData = {
        task_id: viewModalData.rowId,
        task_status: updateTimesheetData.up_status,
        task_title: updateTimesheetData.up_task_title,
        task_description: updateTimesheetData.up_task_description,
        start_date_time: moment(updateTimesheetData.up_task_date).format('YYYY-MM-DD') + " " + moment(updateTimesheetData.up_task_start_time).format("HH:mm:ss"),
        end_date_time: moment(updateTimesheetData.up_task_date).format('YYYY-MM-DD') + " " + moment(updateTimesheetData.up_task_end_time).format("HH:mm:ss"),
        assigned_by: userDataId,
        assigned_to: updateTimesheetData.up_assignedto,
    }

    updateDailyTaskData(updatedData)
    // try {
    //   const res = await axios.post("task/update", updatedData);
    //   setTimeout(() => {
    //     setOpen(true);
    //     setSnackBarMessage(" Successfully Updated");
    //     props.onHide();
    //   }, 2000);
    //   setSnackBarMessage(" Successfully Updated");
    //   setContactShow(false);
    //   handleClick();
    //     props.refreshTable();

    //   setDisable(true);
    // } catch (err) {
    //   setDisable(false);
    //   setOpen(true);
    //   setSnackBarMessage(err.response.data.msg);
    //   if (err.response.status === 400) {
    //     timesheetSetError("ac_" + err.response.data.key, {
    //       type: "manual",
    //       message: err.response.data.message,
    //     });
    //   }
    //   if (err.response.status === 403) {
    //     setSnackBarMessage(err.response.data.msg)
    //     setTimeout(() => {
    //       setOpen(true);
    //     }, 500);

    //   }

    // }
  }
  // const handleClick = () => {
  //   setOpen(true);
  // };

    //dispatch(updatetimesheet(updatedData));

    // try {
    //   const res = await axios.post("task/update", updatedData);
    //   setTimeout(() => {
    //     setOpen(true);
    //     setSnackBarMessage(" Successfully Updated");
    //     props.onHide();
    //   }, 2000);
    //   setSnackBarMessage(" Successfully Updated");
    //   setContactShow(false);
    //   handleClick();
    //     props.refreshTable();

    //   setDisable(true);
    // } catch (err) {
    //   setDisable(false);
    //   setOpen(true);
    //   setSnackBarMessage(err.response.data.msg);
    //   if (err.response.status === 400) {
    //     timesheetSetError("ac_" + err.response.data.key, {
    //       type: "manual",
    //       message: err.response.data.message,
    //     });
    //   }
    //   if (err.response.status === 403) {
    //     setSnackBarMessage(err.response.data.msg)
    //     setTimeout(() => {
    //       setOpen(true);
    //     }, 500);

    //   }

    // }
  // }
 

  // useEffect(() => {
  // }, [props.rowId, props.metaData])


  useEffect(() => {
    getTimesheetStatus();
    getAssignedMembersList();
  }, [])

  return (
    <>
      <Modal
        show={showUpdateTimeSheetModal}
        onHide={() => setContactShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
          {"Update Clock Time"}
          </Modal.Title>
          <a onClick={() => updateViewModalData(false)} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={timesheetHandleSubmit(UpdateTimesheetHandle)}>
            <div className="row headerBand">
              <label>Update Task</label>
            </div>
            <br />
            <div class="row">
            <div className="col-md-6">
                <TextField variant="outlined"
                  {...timesheetRegister("up_task_title", {
                    minLength: {
                      value: 3,
                      message: "Task Title must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={timesheetErrors.up_task_title ? timesheetErrors.up_task_title.message : ''}
                  error={Boolean(timesheetErrors.up_task_title)}
                  type="text" className="form-control" value={up_task_title} name="up_task_title" label="Task Title" onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className="form-control"
                        label="Task Date"
                        inputVariant="outlined"
                        value={updateTimesheetData.up_task_date}
                        onChange={(date) => setUpdateTimesheetData({...updateTimesheetData, up_task_date: moment(date).format('YYYY-MM-DD') })}
                        showTodayButton
                        labelFunc={formatWeekSelectLabel}
                        minDate={ permissions.includes('EnableTimeSheetBackDate') ? true :  today.setDate(today.getDate() - 6) }
                        inputRef={task_date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                 </MuiPickersUtilsProvider>
             
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        className="form-control"
                        label="Start Time"
                        inputVariant="outlined"
                        value={updateTimesheetData.up_task_start_time}
                        onChange={(date) => setUpdateTimesheetData({...updateTimesheetData, up_task_start_time: date })}
                        showTodayButton
                        inputRef={start_time}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                        className="form-control"
                        label="End time"
                        inputVariant="outlined"
                        value={updateTimesheetData.up_task_end_time}
                        onChange={(time) => setUpdateTimesheetData({...updateTimesheetData, up_task_end_time: time })}
                        showTodayButton
                        inputRef={end_time}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
             </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
         
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...timesheetRegister("up_status", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={updateTimesheetData.up_status}
                  select
                  helperText={timesheetErrors.up_status ? timesheetErrors.up_status.message : ''}
                  error={Boolean(timesheetErrors.up_status)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="up_status" label="Status"
                >
                  {statusList.map((option,new_up_status) => (
                      <MenuItem key={option.value} selected={new_up_status === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                  ))
                  }
                </TextField>
              </div>
         
              {permissions.includes('AssignTeamMemberTask')  && 
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...timesheetRegister("up_assignedto", {
                  })}
                  required
                  helperText={timesheetErrors.up_assignedto ? timesheetErrors.up_assignedto.message : ''}
                  error={Boolean(timesheetErrors.up_assignedto)}
                  select
                  type="text" label="Assign to" name="up_assignedto" className="form-control"
                  value={up_assignedto} onChange={e => onInputChange(e)} >

                  {
                  up_assignedto && teamMembersList.map((option) => (
                      <MenuItem key={option.value} selected={ up_assignedto.toString() == option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
              } 
            
            </div>
            <br/>
            <br/>
            <div className='row'>
                <div className='col-md-6'>
                <TextField variant="outlined"
                 multiline row={2}
                  {...timesheetRegister("up_task_description", {
                  })}
                  helperText={timesheetErrors.up_task_description ? timesheetErrors.up_task_description.message : ''}
                  error={Boolean(timesheetErrors.up_task_description)}
                  type="text" className="form-control" value={up_task_description} name="up_task_description" label="Description" onChange={e => onInputChange(e)} />
                </div>
                
            </div>
            <br />
            <div className="row">
              <div>
          
               <button type="submit" disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Update</button>
          
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
     
    </>
  )

})
// export default UpdateTimesheetModal;

// 


const mapStateToProps = (state) => ({
  showUpdateTimeSheetModal : state.timesheet.showUpdateTimeSheetModal,
  viewModalData : state.timesheet.viewModalData,
});

const mapDispatchToProps = (dispatch) => ({
  updateViewModalData : (data) => dispatch(updateViewModal(data)),
  updateDailyTaskData : (data) => dispatch(updateDailyTask(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTimesheetModal);

