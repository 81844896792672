import React, { useState, useEffect,  } from "react";
import axios from "axios";
import { Tree, TreeNode } from 'react-organizational-chart';
import { useLocation } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from "react-redux";

import Navbar from '../home/Navbar';
import {getRegions} from "../../store/targets";
import { useSelector } from "react-redux";

const SetTargetTree = (props) => {

  const search = useLocation().search;
  const acid = new URLSearchParams(search).get("acid");
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const {regionsList} = useSelector(state => state.targets);
  console.log("regionsList", regionsList);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  useEffect(() => {
    dispatch(getRegions());
  }, [])

  const [state, setState] = useState({
    contacts_list: null,
    data: null,
    root: null,
    showEmployeeUpdateModal: false,
    targetsData: {}
  })

  const getContacts = async (props) => {
    const res = await axios.get("/demos/economic-buyer-list/" + acid);
    if (res.data.status === "success") {

      setState(prev => ({ contacts_list: res.data.economicBuyerList }))
    }
  }

  const getChartData = async () => {
    setOpen(true);
    var year = document.getElementById("year").value
    var targetType = document.getElementById("targetType").value
    var data = {
      year : year
    }

    if(targetType === "kaizenatTarget"){
      var res = await axios.post("/user/reports/sales-target-tree", data)
    }else if(targetType === "ansysTarget"){
      var  res = await axios.post("/user/reports/ansys-target-tree", data)
    }else if(targetType === "productsTarget"){
      var res = await axios.post("/user/reports/product-target-tree", data)
    }
    
    if (res.data.status === "success") {
      setState(prev => ({ data: res.data.contacts, root: res.data.root, targetsData: {} }))
      setOpen(false);
    } else {
      setOpen(false);
    }
  }

  useEffect(() => {
    getContacts();
    getChartData();
  }, [])

  const updateSalesTargets = async () => {

    var year = document.getElementById("year").value
    var targetType = document.getElementById("targetType").value

    var res;
    if(targetType == "ansysTarget"){
       res = await axios.post("common/targets/update-ansys-target", { data: state.targetsData, year: year });
    }else if(targetType == "kaizenatTarget"){
       res = await axios.post("common/targets/update", { data: state.targetsData, year: year });
    }else if(targetType == "productsTarget"){
      res = await axios.post("common/targets/update-product-target", { data: state.targetsData, year: year });
    }

    setOpen(true);
    
    if (res.data.status === "success") {
      getChartData();
    } else {
      setOpen(false);
    }

  }


  const onInputChange = (e) => {

    var split = e.target.getAttribute("data-id").split("-")
    var user_id = split[0]

    var business_type = e.target.getAttribute("data-type")
    var type = document.getElementById(split[0]).value

    var object = state.targetsData[split[0]]

    if (type === "target") {

      var value = e.target.value / 4

      var item = state.targetsData[user_id]
      if (business_type === "renewel") {
        item["Q1"] = { "renewel": value, "new": item.Q1.new }
        item["Q2"] = { "renewel": value, "new": item.Q2.new }
        item["Q3"] = { "renewel": value, "new": item.Q3.new }
        item["Q4"] = { "renewel": value, "new": item.Q4.new }
        item["target"] = { "renewel": parseFloat(e.target.value), "new": item.target.new }
      } else {
        item["Q1"] = { "renewel": item.Q1.renewel, "new": value }
        item["Q2"] = { "renewel": item.Q2.renewel, "new": value }
        item["Q3"] = { "renewel": item.Q3.renewel, "new": value }
        item["Q4"] = { "renewel": item.Q4.renewel, "new": value }
        item["target"] = { "renewel": item.target.renewel, "new": parseFloat(e.target.value) }
      }

      state.targetsData[user_id] = item

      document.getElementById(user_id + "-totalTarget").textContent = "Total Target " + `${(item.target.new + item.target.renewel).toLocaleString("en-IN")}`
      document.getElementById(user_id + "-valueToText").textContent = numDifferentiation(item.target.new + item.target.renewel)


    } else {

      state.targetsData[split[0]][type][business_type] = parseFloat(e.target.value)
      var object = state.targetsData[split[0]]
      state.targetsData[split[0]]["target"]['renewel'] = parseFloat(object["Q1"]['renewel']) + parseFloat(object["Q2"]['renewel']) + parseFloat(object["Q3"]['renewel']) + parseFloat(object["Q4"]['renewel'])
      state.targetsData[split[0]]["target"]['new'] = parseFloat(object["Q1"]['new']) + parseFloat(object["Q2"]['new']) + parseFloat(object["Q3"]['new']) + parseFloat(object["Q4"]['new'])

      document.getElementById(user_id + "-totalTarget").textContent = "Total Target " + `${state.targetsData[split[0]]["target"]["new"] + state.targetsData[split[0]]["target"]["renewel"]}`
      document.getElementById(user_id + "-valueToText").textContent = numDifferentiation(state.targetsData[split[0]]["target"]["new"] + state.targetsData[split[0]]["target"]["renewel"])
    }

  }

  const onselectionchange = (e, user_id) => {

    var type = e.target.value
    var doc = document.querySelector('input[data-id="' + user_id + "-renewel" + '"]')
    doc.value = state.targetsData[user_id][type]['renewel']

    var doc1 = document.querySelector('input[data-id="' + user_id + "-new" + '"]')
    doc1.value = state.targetsData[user_id][type]['new']

    document.getElementById(user_id + "-totalTarget").textContent = "Total Target " + `${(state.targetsData[user_id][type]['renewel'] + state.targetsData[user_id][type]['new']).toLocaleString("en-IN")}`
    document.getElementById(user_id + "-valueToText").textContent = numDifferentiation(state.targetsData[user_id][type]['renewel'] + state.targetsData[user_id][type]['new'])
  }

  const objects = { "renewel": 0, "new": 0 }

  function numDifferentiation(value) {
    var val = Math.abs(value)
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(3) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(3) + ' Lac';
    } else if (val >= 10000) {
      val = (val / 1000).toFixed(3) + ' Thousands';
    }
    return val;
  }

  const Datass = (props) => {

    return (
      props.data.map((item, key) => {

        state.targetsData[item._id] = {
          'Q1': item.target?.Q1 || objects,
          'Q2': item.target?.Q2 || objects,
          'Q3': item.target?.Q3 || objects,
          'Q4': item.target?.Q4 || objects,
          'target': item.target?.target || objects
        }

        var image = props.data[key].image

        return (

          <TreeNode style={{minWidth:"800px !important", maxWidth:"800px !important"}} label={
            <div className="card shadow card_main">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  style={{ borderRadius: '50%', height: '4rem', width: '4rem' }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://kaizenat.com/kaizendynaLogo.png";
                  }}
                  class="card-img-top" src={image || "https://kaizenat.com/kaizendynaLogo.png"} alt="Card image cap"></img>
              </div>
              &nbsp;&nbsp;
              <b>{item.name}</b>

              <select name="type" id={item._id} onChange={e => onselectionchange(e, item._id)}>
                <option value="target">Annual</option>
                <option value="Q1">Q1</option>
                <option value="Q2">Q2</option>
                <option value="Q3">Q3</option>
                <option value="Q4">Q4</option>
              </select>

              <div className="card-footer" style={{ backgroundColor: item.color }}>

                <br />
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <label style={{ color: 'black' }}><b>Renewal</b></label>
                  <input placeholder={item.target ? item.target.target.renewel.toLocaleString("en-IN") : ""} style={{ width: '110px' }} data-type="renewel" data-id={item._id + "-renewel"} onChange={e => onInputChange(e)} type="number" />

                  <label style={{ color: 'black' }}><b>New</b></label>
                  <input placeholder={item.target ? item.target.target.new.toLocaleString("en-IN") : ""} style={{ width: '110px' }} data-type="new" data-id={item._id + "-new"} onChange={e => onInputChange(e)} type="number" />

                  <label style={{ fontWeight: 'bold', color: "#000" }} id={item._id + "-totalTarget"}>{(item.target?.target.new + item.target?.target.renewel).toLocaleString("en-IN")}</label>
                  <label style={{ fontWeight: 'bold', color: "#000" }} id={item._id + "-valueToText"}>{numDifferentiation(item.target?.target.new + item.target?.target.renewel)}</label>
                </div>

              </div>
            </div>
          }>
            {item.children.length > 0 ? <Datass data={item.children} /> : null}

          </TreeNode>

        )
      })
    )
  }

  return (
    <div style={{zoom: '90%'}}>
      <Navbar value="setTargets" className="activity_list_navbar" />
      <div className="container-fluid" style={{maxWidth:"800px !important"}}>
        <div className="row" >
          <div className="col-md-10 col-lg-10 col-sm-12 col-12" >
            <div className="row">
              <div className="col-md-3">
                <select name="targetType" id="targetType" onChange={getChartData}>
                  <option value="kaizenatTarget" selected>Kaizenat Target</option>
                  <option value="ansysTarget">Ansys Target</option>
                  <option value="productsTarget">Products Target</option>
                </select>
              </div>
              <div className="col-md-3">
                <select name="year" id="year" onChange={getChartData}>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023" selected>2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>
            </div>

            

            <div className="row">
              <div className="col-md-10"  style={{minWidth:"800px !important", maxWidth:"800px !important"}}>
                <br />
                {
                  state.root?.length > 0 ?
                    <Tree style={{minWidth:"800px !important", maxWidth:"800px !important"}} label={<div>{state.root}</div>}>
                      {
                        state.data && state.data.length > 0 && <Datass data={state.data} />
                      }
                    </Tree>
                    : <h1>Update Chart details</h1>
                }
                <button className="btn btn-primary" onClick={updateSalesTargets}>Update Values</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />


      <div className="container">
        <div className="row">
            
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={"Updating Organization Chart"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default SetTargetTree;