import React, { useState} from "react";
import Navbar from "../home/Navbar";
import TimesheetList from "../../components/commonColumns/TimesheetList";
import { useForm } from "react-hook-form";
import { connect, useSelector } from 'react-redux';
// import {gettimesheetlist,gettaskupdate,getAssignees} from "../../store/Timesheet"
// user,Assignesslist,timesheetlist,getColumnlist
const TimeSheet = React.memo (({}) => {
  // console.log("timesheet_data",timesheetlist)
  // console.log("Assignesslist_data",Assignesslist)  
  // console.log("columnlistlist_data",getColumnlist)

   
    // const permissions = user.permissions;

  // const { register: timesheetRegister, handleSubmit: timesheetHandleSubmit, setError: timesheetSetError, reset: reset, formState: { errors: timesheetErrors } } = useForm();

  return (
    <>
      <Navbar value="timesheet" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >

        </div>


        <div>
        </div>


      <div className="demo_table">
        <TimesheetList />
        {/* // data={timesheetlist?.tasks_list} Assignesslistdata= {Assignesslist?.list} getColumnlist={getColumnlist}   */}
    
      </div>
    </div>
    </>
  );
});

   const mapStateToProps = (state) => ({

    // timesheetlist:state.Timesheetlist.timesheetlist,
    //  Assignesslist : state.Timesheetlist.Assignesslist,
    //  getColumnlist:state.Timesheetlist.getColumnlist,
    // user : state.auth.user,
  
  });

  const mapDispatchToProps = (dispatch) => ({
  
    // gettimesheetlist: (data) => dispatch(gettimesheetlist(data)),
    //  gettaskupdate: (data) => dispatch(gettaskupdate(data)),
    //  getAssigneeslist : (data) => dispatch(getAssignees(data))
  });
  export default connect(mapStateToProps, mapDispatchToProps)(TimeSheet);
