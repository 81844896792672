import React, { useState, useEffect, useMemo } from "react";
import Navbar from "../home/Navbar";
import "./rowdata.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import RawDataTable from "../../components/commonColumns/RawDataTable";
import RawDataBaseModal from "../../components/pageModals/RawDataBaseModal";
import { Button } from 'react-bootstrap';
import RawDatabaseFormat from "./RawDatabaseFormat.xlsx"
import { connect, useSelector, useDispatch } from "react-redux";
import { getrawdata, RawdataActions, fetchRawdataPagination } from "../../store/Rawdatabase"
const RowData = React.memo(({ rawdatalist, user, getrawdatalist, showModal, openshowModal }) => {
  const dispatch = useDispatch();
  const userData = user.user;
  const [Rawdatafilename, SetRawdatafilename] = useState();
  const [Rawdata, setRawdata] = useState();

  const {
    register: targetRegister,
    handleSubmit: SetRawdata,
    setError: trainingSetError,
    reset: reset,
    formState: { errors: targetErrors },
  } = useForm();

  const changeHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      let RawDatafile = event.target.files[0];
      let RawDatafilename = event.target.files[0].name;
      setRawdata(RawDatafile);
      SetRawdatafilename(RawDatafilename);
    }
  };

  const fetchData = async () => {
    const userid = {
      user_id: userData?._id,
      page: 1,
      perPage: 15
    }
    getrawdatalist(userid)
  }

  useMemo(() => {
    fetchData()
  }, [])

  return (
    <>
      <Navbar value="rowdata" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3">
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => openshowModal()}>Add</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;<Button href={RawDatabaseFormat} download="RawDatabaseFormat" >Download Template File</Button>

            {showModal ? <RawDataBaseModal /> : ""}
          </>
        </div>

        <div className="hardware_table">
          <RawDataTable />
        </div>
      </div>

    </>
  );

});


const mapStateToProps = (state) => ({
  user: state.auth.user,
  showModal: state.Rawdatalist.showModal
});

const mapDispatchToProps = (dispatch) => ({
  getrawdatalist: (data) => dispatch(fetchRawdataPagination(data)),
  openshowModal: (data) => dispatch(RawdataActions.openModal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RowData)