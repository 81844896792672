import React ,{useState,useEffect}from 'react';
import Modal from 'react-bootstrap/Modal';
import HardwareAssetsDetails from "./HardwareAssetsDetails"
import axios from 'axios';
import HardwareConfigAssets from './HardwareConfigAssets';
import moment from 'moment';
import {createhardwarelist} from "../../store/Hardware"
import { useDispatch, useSelector } from 'react-redux';


const HardwareModal = (props) => {
  const dispatch = useDispatch();
  const[page,setpage] = useState(0); 
  const formtitles = [<div className='assetlabels'> Assets Details </div>, <div className='updatelabels2'> Configuration Assets  </div>];
  const[formData,Setformdata] = useState({
    brand :"",
    type  :"",
    model : "",
    warrantytype:"",
    cost : "",
    serialnumber :"",
    purchaseDate : "",
    purchasein :"",
    pointofname:"",
    pointofcontact :"",
    statustype: "",
    physicalAddress : "",
    hostname : "",
    ram : "",
    processor : "",
    harddisk : "",
    harddisktype:"",
    graphicscard: "",

  })
  const onInputchange = (e) =>{ 
    Setformdata({...formData,[e.target.name] :e.target.value},[])
  }
  const [expirydateactivity,setExpirydateactivity] = useState({expiry_date:""})
 
  const dateinputchange = (e) => {
    setExpirydateactivity({...expirydateactivity,[e.target.name] : e.target.value} ,[])
      }
  const submit = async() => {  
    const  activityformdata = {
      brand :formData.brand,
      type  :formData.type,
      model : formData.model,
      cost : formData.cost,
      serial_number :formData.serialnumber,
      purchased_on : formData.purchaseDate,
      warranty_type:formData.warrantytype,
       warranty_exp_date :expirydateactivity.expiry_date ==="Invalid date" ? null : expirydateactivity.expiry_date,
      purchased_in :formData.purchasein,
      point_of_name:formData.pointofname,
      point_of_contact :formData.pointofcontact,
      status: formData.statustype,
      physical_address : formData.physicalAddress,
      hostname: formData.hostname,
      ram : formData.ram,
      processor: formData.processor,
      hard_disk : formData.harddisk,
      hard_disk_type:formData.harddisktype,
      graphics_card: formData.graphicscard,

    }

    dispatch(createhardwarelist(activityformdata));
    props.onHide();
  }
  const nextpage = () =>{
      setpage((currpage) => currpage +1)
  }

  const previouspage = () => {
    setpage((currpage) => currpage -1)
  }
  

  useEffect(() => {

    function addOneYear(date1) {
      const year = parseInt(formData?.warrantytype.replace("Year", ""))
      if(year===6){
        date1.setMonth(date1.getMonth() + 6);
        return date1;
      }else{
        
        date1.setFullYear(date1.getFullYear() + year);
        return date1;
      }
   
    }
    if(formData.purchaseDate && formData.warrantytype && formData.warrantytype !=="NO Warranty"){
      const date1 = new Date(`${formData?.purchaseDate}`); 
      const newDate1 = addOneYear(date1);
      setExpirydateactivity({expiry_date:moment(newDate1).format("YYYY-MM-DD")})
    
    }

  }, [formData.purchaseDate,formData.warrantytype])


  return (
    <>
  <Modal

        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
     <h6>    Asset Management </h6>
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
        <div>                         
      <div className='Titlebutton'>{formtitles[page]}</div>
      <br/>

            {page===0 ? <HardwareAssetsDetails formData={formData}  nextpage={nextpage}   onInputchange ={onInputchange} dateinputchange={dateinputchange} expirydateactivity={expirydateactivity} submits={submit}/> :
            <HardwareConfigAssets formData={formData}  onInputchange = {onInputchange} submit={submit} previouspage={previouspage}/> 
            }

     </div>
    </Modal.Body>
     
 </Modal>
  </>
  );
}

export default HardwareModal;


