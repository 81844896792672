
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showModal_assign: false,
    showModal_activity:false,
    showModal_Viewcontact:false,

    traininglist: [],
    start_traininglist:[],
    completedTrainingSuccesslist:[],
    delete_Demo_RequestSuccesslist:[],
    gettraininglists:[],
    assignedtraining:[],
    webinarlists:[],
    createwebinarlists:[],
    updatewebinarlists:[],
    techielists:[],
    InvolvedPersonslists:[],
    columnsList:[]
}

const WebinarSlice = createSlice({
    name: "Webinarslist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },

          assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },
         Viewcontact_openModal : (state, action) => {
          state.showModal_Viewcontact = true; 
        },
        Viewcontact_CloseModal: (state, action) => {
          state.showModal_Viewcontact = false; 
        },



          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          traininglistSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.traininglist = action.payload;
            // toast.success("Training list data") 
          },
          traininglistFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.traininglist = [];
            toast.error("Error in training list data") 
          },
      
          start_TrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.start_traininglist = action.payload;
            toast.success("start training list") 
          },
          start_TrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.start_traininglist = [];
            toast.error("Error in start training list") 
          },
          completedTrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.completedTrainingSuccesslist = action.payload;
            toast.success(" completedTrainingSuccess list") 
          },
          completedTrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.completedTrainingSuccesslist = [];
            toast.error(" Error in completedTrainingSuccess list") 
          },
          delete_Demo_RequestSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.delete_Demo_RequestSuccesslist = action.payload;
            toast.success("completedTrainingSuccess list") 
          },
          delete_Demo_RequestFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.delete_Demo_RequestSuccesslist = [];
            toast.error(" Error in delete_Demo_RequestSuccesslist") 
          },
          gettrainingColumnsuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.gettraininglists = action.payload;
            // toast.success("Training list data") 
          },
          gettrainingColumnFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.gettraininglists = [];
            // toast.success("Training list data") 
          },
          // asiign training update
          AssigntrainingSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedtraining = action.payload;
            toast.success("Successfully Assigned") 
            state.showModal_assign = false
          },
          AssigntrainingFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedtraining = [];
            toast.error("Error in assign") 
            state.showModal_assign = true
          },

          webinarlistSuccess(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.webinarlists = action.payload;
            // toast.success("Successfully Assigned") 
            // state.showModal_assign = false
          },
          webinarlistFailure(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.webinarlists = [];
            // toast.success("Successfully Assigned") 
            // state.showModal_assign = false
          },
          webinarcreateSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createwebinarlists = action.payload;
            toast.success("Successfully Added") 
            // state.showModal_assign = false
          },
          webinarcreateFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createwebinarlists = [];
            toast.error("Error in webinar create") 
            // state.showModal_assign = false
          },
          updatewebinarcreateSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updatewebinarlists = action.payload;
            toast.success("Successfully updated") 
            // state.showModal_assign = false
          },
          updatewebinarcreateFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updatewebinarlists = [];
            toast.error("error in update") 
            // state.showModal_assign = false
          },

          techieslistSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.techielists = action.payload;
            // toast.success("Successfully Assigned") 
            // state.showModal_assign = false
          },
          techieslistFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.techielists = [];
            toast.error("Error in techies list") 
            // state.showModal_assign = false
          },
          InvolvedPersonslistSuccess(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.InvolvedPersonslists = action.payload;
            // toast.success("Successfully Assigned") 
            // state.showModal_assign = false
          },
          InvolvedPersonslistFailure(state, action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.InvolvedPersonslists =[];
            toast.error("Error in persons list") 
            // state.showModal_assign = false
          },
          
      tasksListUpdateSuccess(state, action){
        state.isFetch = false
        state.error = false
        state.errorPayload = false
        state.msg = action.payload
    },
    tasksListUpdateFailure(state, action){
        state.isFetch = false
        state.error = true
        state.errorPayload = action.payload
        state.msg = ""
    },
    opportunutiesColumnsListSuccess(state, action){
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.columnsList = action.payload
  },
  opportunutiesColumnsListFailure(state, action){
      state.isFetch = false
      state.error = true
      state.errorPayload = action.payload
      state.columnsList = []
  },
          updateColumns(state, action){
            state.columnsList = action.payload
          }

      

    }  
});
export const WebinarActions = WebinarSlice.actions;

//GET Wbinar LIST START HERE

export const webinarlist = (updatedData) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/meetings/isales/webinar/list", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.webinarlistSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.webinarlistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.webinarlistFailure("Network Error"));
      }
    };
  };  
  
  export const start_Training = (data) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/list", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.start_TrainingSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.start_TrainingFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.start_TrainingFailure("Network Error"));
      }
    };
  };   
  
  export const completed_Training = (data) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/update-timings", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.completedTrainingSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.completedTrainingFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.completedTrainingFailure("Network Error"));
      }
    };
  };   
  
  
  export const delete_Demo_Request = (data) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingloader"));
  
      try {
        await Api.post("/trainings/remove", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.delete_Demo_RequestSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.delete_Demo_RequestFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.delete_Demo_RequestFailure("Network Error"));
      }
    };
  }; 
  
  
  
  
  export const TrainingColumnUpdateddata = (updatedData) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingLoader"));
      try {
        await Api.post("columns-hide-show/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.ContactcolumndataSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.ContactcolumndataFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.ContactcolumndataFailure("Network Error"));
      }
    };
  };

  export const createdata = (updatedData,filtersDates) => {
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/meetings/isales/webinar/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.webinarcreateSuccess(res.data));
            dispatch(webinarlist(filtersDates))
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.webinarcreateFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.webinarcreateFailure("Network Error"));
      }
    };
  };

  
  export const gettechieslist = (updatedData) => {

  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingLoader"));
      try {
        await Api.get("/user/techies-list").then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.techieslistSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.techieslistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.techieslistFailure("Network Error"));
      }
    };
  };

  export const getInvolvedPersons = (updatedData) => {

  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingLoader"));
      try {
        await Api.get("/user/team-members-list").then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.InvolvedPersonslistSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.InvolvedPersonslistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.InvolvedPersonslistFailure("Network Error"));
      }
    };
  };

 
  export const updateWebinardata = (updatedData) => {
    
  
    return async (dispatch) => {
      dispatch(WebinarActions.loaderStart("meetingLoader"));
      try {
        await Api.post( "/meetings/isales/webinar/update", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(WebinarActions.updatewebinarcreateSuccess(res.data));
            dispatch(webinarlist())
          }
          if (res.data.status === "failure") {
            dispatch(WebinarActions.updatewebinarcreateFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(WebinarActions.updatewebinarcreateFailure("Network Error"));
      }
    };
  };

  
  

export const updateColumns = (data) => {
  return async (dispatch) => {
      dispatch(WebinarActions.loaderStart())
      try {
          await Api.post("/columns-hide-show/create", data).then((res) => {
                  if (res.data.status === "success") {
                    toast.success("Columns Updated ")
                      dispatch(WebinarActions.tasksListUpdateSuccess("Columns Updated"))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(WebinarActions.tasksListUpdateFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error")
          dispatch(WebinarActions.tasksListUpdateFailure("Network Error"));
      }
  }
}

  

export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(WebinarActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/webinar/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(WebinarActions.opportunutiesColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(WebinarActions.opportunutiesColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(WebinarActions.opportunutiesColumnsListFailure("Network Error"));
      }
  }
}

  

  export default WebinarSlice.reducer;