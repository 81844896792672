import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';


const AddResource = (props) => {
    const [isRequired, setRequired] = useState(props.disableRequired);
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();
    const [companiesList, setCompaniesList] = useState();
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const description = useRef();

    const html = `<p>  Description...
    <br /><br />
    </p>`;
    const contentBlock = htmlToDraft(props.data ? props.data.description : html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    const [title, setTitle] = useState(props.data?.title)

    useEffect(() => {
        
    }, [props.data])
    
    const onEditorStateChange = (data) => {
        
        setEditorState(data)
    }

    const onInputChange = (e) => {
        setTitle(e.target.value)
    }
    const addContactsHandle = async (e) => {

        if(mailBody.current.value.length < 30){
            alert("Mail body must atleast 30 characters");
        }

        setDisable(true);
        setSnackBarMessage("Uploading Data ...!");
        setOpen(true);

        setTimeout(() => { }, 500);

        const contactsData = {
            title : title,
            description : mailBody.current.value
        };

        if(props.data){
            contactsData.resource_id = props.data?._id
        }

        try {

            var res;
            if(props.data){
                res = await axios.post("/user/resources/update", contactsData);
            }else{
                res = await axios.post("/user/resources/create", contactsData);
            }

            if (res.data.status === "success") {
                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage("Resource Successfully Added...!");
                    props.onHide();

                }, 2000);

                setSnackBarMessage("Resource Successfully Added...!");
                setContactsShow(false);

                contactsReset({
                    keepErrors: false,
                    keepDirty: true,
                    keepIsSubmitted: false,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                })
                setDisable(true);
            } else {

                setTimeout(() => {
                    setOpen(true);
                    setSnackBarMessage(res.data.msg);
                }, 2000);

                setContactsShow(true);
                setDisable(false);
            }

            if (props.refreshTableData()) {
                props.refreshTableData();
            }

            if (props.returnValue) {
                props.returnValue(res.data.savedContacts);
            }

        } catch (err) {
            setDisable(false);

            alert(err);

        }

    }

    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            < Modal
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                className={modalHide}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add Resource
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2} onSubmit={contactshandleSubmit(addContactsHandle)}>
                        <div class="row">
                            <div class="col-md-10">
                                <TextField variant="outlined"
                                 multiline rows={2}
                                    value ={title}
                                    onChange={e => onInputChange(e)}
                                    helperText={contactsErrors.title ? contactsErrors.title.message : ''}
                                    error={Boolean(contactsErrors.title)}
                                    label="Title"
                                    type="text" className="form-control" name="title" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <br />
                                <div className="form-group col-md-12 editor" style={{marginTop: '30px'}} >
                                    <label className="font-weight-bold"> Description <span className="required"> * </span> </label>
                                    <div style={{minHeight:'350px',border:'1px grey solid'}}>
                                    
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange = {onEditorStateChange}
                                        />
                                    </div>
                                    <textarea
                                     style={{display:'none'}}
                                        disabled
                                        ref={mailBody}
                                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                                </div>

                        </div>
                        <br />
                        <div className="row col-md-3 common_center_div">
                            <button disabled={disable} className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn ">Submit</button>&nbsp;&nbsp;
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}

export default AddResource;

