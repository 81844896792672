import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import {assignDemolist,getTechieListdata,getproductlistdata,DemoActions} from "../../store/Demo"
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

export const AssignDemoModal = React.memo(({user,demorowproducts, techielist,productlist,assigndemo,DemoRowid,openshowModal,closemodal}) => {

  const dispatch = useDispatch();
  const userData = user?.user;
  const [rejectShow, setRejectShow] = useState(false);
  const [techieList, setTechieList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [demoData, setDemoData] = useState({
    techie_names: '',
    product_list: '',
    comment: ''
  })

  useEffect(() => {
    var filterNames = demorowproducts.split(", ")
    var filtered = productsList.filter((option) => filterNames.includes(option.label))
    setDemoData({product_list: filtered})
  }, [productsList])
  
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const getTechieList = async () => {
  //   dispatch(getTechieListdata())
  //   const res = techielist?.techiesList ? techielist?.techiesList : []
  // setTechieList(res)
    const res = await axios.get("/user/techies-list");
    setTechieList(res.data.techiesList)
  }
  const getProductsList = async () => {
    // dispatch(getproductlistdata())
    // const res = productlist?.productsList ? productlist?.productsList : []
    // setProductsList(res)
    const res = await axios.get("/products/search");
    setProductsList(res.data.productsList);
  }
  useEffect(() => {
    getTechieList();
    getProductsList();
  }, [])

  const assignSubmit = async (data) => {
    setDisable(true);
    var selectedProducts = demoData?.product_list?.map((option) => option.value);
    var updatedData = {
      'approval_status': "approved",
      'demo_id': DemoRowid,
      'assigned_to': demoData.techie_names,
      'application': selectedProducts[0],
      'remarks': demoData.comment,
      'products': selectedProducts,
    }
    assigndemo(updatedData)
  
  }

  const onInputChange = (e) => {
    setDemoData({ ...demoData, [e.target.name]: e.target.value }, []);
  };
 
 
  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
         <h5>   {(userData.user_role_id.role_name === "user") ? "Assign Demo" : "Assign Demo"} </h5>
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Assign Demo</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...rejectRegister("techie_names", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.techie_names}
                  select
                  helperText={rejectErrors.techie_names ? rejectErrors.techie_names.message : ''}
                  error={Boolean(rejectErrors.techie_names)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="techie_names" label="Techie Name"
                >
                  {techieList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
              <div class="col-md-6">
              <Select
                      // defaultValue={}
                      isMulti
                      name="product_list"
                      label="Product"
                      placeholder="Select Products"
                      options={productsList}
                      className="basic-multi-select"
                      onChange={value => onInputChange({target: {name: "product_list", value:value}})}
                      value={demoData.product_list}
                      classNamePrefix="select">

                    </Select>
                {/* <TextField variant="outlined"
                  {...rejectRegister("product_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.product_list}
                  select
                  helperText={rejectErrors.product_list ? rejectErrors.product_list.message : ''}
                  error={Boolean(rejectErrors.product_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="product_list" label="Product List"
                >
                  {productsList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField> */}

              </div>

            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <TextField variant="outlined"
                  multiline row={2}
                  {...rejectRegister("comment", {
                    required: {
                      value: true,
                      message: "comment is required"
                    },
                    minLength: {
                      value: 5,
                      message: "comment must be at least 5 characters"
                    },
                    maxLength :{
                      value: 25,
                      message: "comment must be at least 25 characters"
                    }

                  })}
                  helperText={rejectErrors.comment ? rejectErrors.comment.message : ''}
                  error={Boolean(rejectErrors.comment)}
                  type="text" className="form-control" value={demoData.comment} name="comment" label="Remarks" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div >
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF',  }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )



});

const mapStateToProps = (state) => ({
    user : state.auth.user,
     techielist : state.Demolist.gettechielists,
     productlist :  state.Demolist.getproductlists,
     DemoRowid : state.Demolist.demorowId, 
     demorowproducts : state.Demolist.demorowproducts,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    assigndemo :(data) => dispatch(assignDemolist(data)),
    openshowModal:(data) => dispatch(DemoActions.assign_openModal(data)),
    closemodal:(data) => dispatch(DemoActions.assign_closeModal(data)),
   
  });
  
  export default connect(mapStateToProps,mapDispatchToProps) (AssignDemoModal)
