import React, { useState, useEffect, useCallback } from 'react';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { getMuiTheme } from "../../components/commonColumns/commonComponents";
import MUIDataTable from "mui-datatables";
import { TechDayColumns, options, LoaderText } from './commonProps';
import AddEventReportsModal from "./AddEventReportsModal";
import RenderDateFilter from "./RenderDateFilter";
import { useSelector, connect } from 'react-redux';
import { getEventReports } from "../../store/EventTracker";

const TechDay = (props) => {

    const [addLicenseSeeding, setAddLicenseSeeding] = useState(false)
    const { filters, setFilters, techDay, isFetching, getTechEvents } = props

    const { user } = useSelector(state => state.auth)
    const permissions = user.permissions;

    const handleAddClick = async () => {
        fetchData()
        setAddLicenseSeeding(!addLicenseSeeding)
    }

    const fetchData = () => {
        let input = {
            event_type: "Tech Day",
            start_date: filters.startDate,
            end_date: filters.endDate
        }
        getTechEvents(input)
    }

    useEffect(() => {
        fetchData()
    }, [filters])

    return (
        <>
            <React.Fragment>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }} >
                            <b>Tech Day List</b>&nbsp;&nbsp;&nbsp;
                            {permissions.includes("canCreateEventReport") && <span onClick={() => handleAddClick()} className={"badges badge-pill cursor-pointer badge-primary"}> Add </span>}&nbsp;&nbsp;
                            <RenderDateFilter filters={filters} setFilters={setFilters} />
                        </div>
                        }
                        data={techDay}
                        columns={TechDayColumns}
                        options={options(isFetching)}
                    />

                </MuiThemeProvider>
            </React.Fragment>
            {addLicenseSeeding && <AddEventReportsModal show={addLicenseSeeding} hide={handleAddClick} from="Tech Day" />}
        </>
    )
}


const mapStateToProps = (state) => ({
    isFetching: state.EventTracker.isFetching,
    techDay: state.EventTracker.techDay
})

const mapDispatchToProps = (dispatch) => ({
    getTechEvents: (params) => dispatch(getEventReports(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(TechDay);