import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showtestimonialModal:false,
    gettestimoniallist:[],
    createtestilists:[],
    columnsList:[]
  
}

const Testimonialslice = createSlice({
    name: "Testimoniallist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
         testimonialsopenModal: (state, action) => {
            state.showtestimonialModal = true; // Correct the property name to "showModal"
          },
          testimonialscloseModal: (state, action) => {
            state.showtestimonialModal = false; // Correct the property name to "showModal"
          },

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },


          Viewcontact_openModal : (state, action) => {
            state.showModal_Viewcontact = true; 
          },
          Viewcontact_CloseModal: (state, action) => {
            state.showModal_Viewcontact = false; 
          },

  ViewaccountupdatedSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = action.payload;
    toast.success("Account updated") 
   
    state.demo_showModal = false;
    
  },

  ViewaccountupdatedFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = [];
    toast.error("Error in Account updated") 
  
    state.demo_showModal = true;
  },

  gettestimonialSuccess(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.gettestimoniallist = action.payload;
    // toast.success("Account updated") 
   
    // state.demo_showModal = false;
    
  },
  gettestimonialFailure(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.gettestimoniallist = [];
    toast.error("Error in Testimonials") 
   
    // state.demo_showModal = false;
    
  },

  testimonialcreateSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.createtestilists = action.payload;
    toast.success("Successfully created") 
   
     state.showtestimonialModal = false;
    
  },
  testimonialcreateFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.createtestilists =[];
    toast.error("Error in created") 
   
     state.showtestimonialModal = true;
    
  },
  testimonialColumnsListSuccess(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.columnsList = action.payload
},
testimonialColumnsListFailure(state, action){
    state.isFetch = false
    state.error = true
    state.errorPayload = action.payload
    state.columnsList = []
},



    }  
});
export const TestimonialsActions = Testimonialslice.actions;


export const gettestimonialsdata = (updatedData) => {
    return async (dispatch) => {
      dispatch(TestimonialsActions.loaderStart());
      try {
        await Api.get("/user/testimonials/list").then((res) => {
          if (res.data.status === "success") {
            dispatch(TestimonialsActions.gettestimonialSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(TestimonialsActions.gettestimonialFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TestimonialsActions.gettestimonialFailure("Network Error"));
      }
    };
  };
  

export const Createtestimonials = (formData) => {
  return async (dispatch) => {
    dispatch(TestimonialsActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/user/testimonials/create", formData).then((res) => {
        if (res.data.status === "success") {
          dispatch(TestimonialsActions.testimonialcreateSuccess(res?.data?.testimonials));
          dispatch(gettestimonialsdata())
        }
        if (res.data.status === "failure") {
          dispatch(TestimonialsActions.testimonialcreateFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(TestimonialsActions.testimonialcreateFailure("Network Error"));
    }
  };
};


  export const getColumnsList = () => {
    return async (dispatch) => {
        dispatch(TestimonialsActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/testimonials/" + userData?.user?._id).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(TestimonialsActions.testimonialColumnsListSuccess(res?.data?.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(TestimonialsActions.testimonialColumnsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(TestimonialsActions.testimonialColumnsListFailure("Network Error"));
        }
    }
  }


  export default Testimonialslice.reducer;