import axios from "axios";
import { useRef, useState, useEffect } from "react";
import "./register.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";

export default function Register() {
  const SITE_KEY = "6LeOziAfAAAAAB61YnpeI250NJCFJwPvj-IQ8jNV";
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [token, setToken] = useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const { register, handleSubmit, formState: { errors: errors }, reset: reset } = useForm();

  const first_name = useRef();
  const last_name = useRef();
  const email = useRef();
  const phone = useRef();
  const job_title = useRef();
  const company = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const history = useHistory();

  const [registrationError, setRegistrationError] = useState(false);

  const handleClick = async (e) => {
    const user = {
      first_name: first_name.current.value,
      last_name: last_name.current.value,
      email: email.current.value,
      phone: phone.current.value,
      job_title: job_title.current.value,
      company: "Kaizenat Technologies Pvt. Ltd.",
      password: password.current.value,
    };

    if (passwordAgain.current.value !== password.current.value) {
      passwordAgain.current.setCustomValidity("Passwords dint match!");
    } else {

      try {
        var response = await axios.post("/user/register", user);
        //console.log("response", response);
        setRegistrationError(response.data.msg);

        setTimeout(() => {
          history.push("/login");
        }, 3000);


      } catch (err) {
        setRegistrationError(err.response);
      }


    }
  };

  return (
    <div className="login">
      <div className="loginWrapper">
        <div className="loginRight">
          <form className="registerBox" onSubmit={handleSubmit(handleClick)}>
            <div className="text-center">
              <h2>User Registration</h2>
              <br />
              <span style={{ fontSize: '17px', fontWeight: 'bold', color: '#212161' }}>{registrationError}</span>
              <br /><br />
            </div>
            <TextField variant="outlined"
              label="First Name"

              inputRef={first_name}
              {...register("first_name", {
                required: {
                  value: true,
                  message: "First Name is required"
                },
                minLength: {
                  value: 3,
                  message: "First name must be at least 3 characters"
                }
              })}

              helperText={errors.first_name ? errors.first_name.message : ''}
              error={Boolean(errors.first_name)}

              className="loginInput"
            />

            <TextField variant="outlined"
              label="Last Name"

              inputRef={last_name}
              {...register("last_name", {
                required: {
                  value: true,
                  message: "Last Name is required"
                },
                minLength: {
                  value: 1,
                  message: "Last name must be at least 1 characters"
                }
              })}

              helperText={errors.last_name ? errors.last_name.message : ''}
              error={Boolean(errors.last_name)}

              className="loginInput"

            />
            <TextField variant="outlined"
              label="Email"

              inputRef={email}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email is required"
                },
                minLength: {
                  value: 3,
                  message: "Email must be at least 1 characters"
                }
              })}

              helperText={errors.email ? errors.email.message : ''}
              error={Boolean(errors.email)}

              className="loginInput"
              type="text"

            />

            <TextField variant="outlined"
              label="Phone"

              inputRef={phone}
              {...register("phone", {
                required: {
                  value: true,
                  message: "Phone is required"
                },
                minLength: {
                  value: 3,
                  message: "Phone must be at least 1 characters"
                }
              })}

              helperText={errors.phone ? errors.phone.message : ''}
              error={Boolean(errors.phone)}

              className="loginInput"
              type="text"
            />
            <TextField variant="outlined"
              label="Job Title"

              inputRef={job_title}
              {...register("job_title", {
                required: {
                  value: true,
                  message: "Job Title is required"
                },
                minLength: {
                  value: 3,
                  message: "Job Title must be at least 1 characters"
                }
              })}

              helperText={errors.job_title ? errors.job_title.message : ''}
              error={Boolean(errors.job_title)}


              className="loginInput"
              type="text"
            />
            <TextField variant="outlined"
              label="Password"
              inputRef={password}
              {...register("password", {
                required: {
                  value: true,
                  message: "Password is required"
                },
                minLength: {
                  value: 3,
                  message: "Password must be at least 6 characters"
                }
              })}

              helperText={errors.password ? errors.password.message : ''}
              error={Boolean(errors.password)}
              className="loginInput"
              type="password"
            />
            <TextField variant="outlined"
              label="Confirm Password"

              inputRef={passwordAgain}
              {...register("passwordAgain", {
                required: {
                  value: true,
                  message: "Confirm Password is required"
                },
                minLength: {
                  value: 3,
                  message: "Confirm Password must be at least 6 characters"
                }
              })}

              helperText={errors.passwordAgain ? errors.passwordAgain.message : ''}
              error={Boolean(errors.passwordAgain)}

              className="loginInput"
              type="password"
            />

            <Link to="/login">
              <a className="loginLink">Login</a>
            </Link>

            <button className="loginButton" type="submit">
              Register
            </button>
          </form>
        </div>
      </div>
    </div>

  );

}

