import React, { useState, useEffect, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {singleLayout, multipleLayout, SelectYear, AccountMangersSingle, QuartersFilter, config,UsersListFilter} from './chartConfig'
import { getYears, getManagers, achievedTargetFilter, getSalesTargetData} from "../../store/dashboard";
import { useDispatch, useSelector, connect } from "react-redux";
import Badge from 'react-bootstrap/Badge'
import { toast } from 'react-toastify';
import { MultilineChart } from "@material-ui/icons";
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const SalesTarget = React.memo(({achievedTargetFilterData, getSalesTargetDataList, getManagersData, getYearsData, achievedTarget, accountManagersList, yearsList,SalestargetLoader,QuartervsQuarterLoader }) => {

    const [filter, setFilter] = useState({kaizenatTarget: true, disableAccountManagerFilter:true});
    console.log("filter",filter)
    // const [accountManagers, setAccountManagers] = useState(null);

    const quartersList = [{ value: "all", label: "Annual" }, { value: 1, label: 'Q1' }, { value: 2, label: "Q2" }, { value: 3, label: 'Q3' }, { value: 4, label: 'Q4' }]



    const containerRef = useRef(null); 
    const downloadContent = () => {
    const container = containerRef.current
    
    toPng(container)
    .then(function(dataUrl) {
      const link = document.createElement('a')
      link.download = 'sales-Target-chart.png';
      link.href = dataUrl;
      link.click();
    })
    .catch(function(error) {
      console.log("error:",error)
    });
    
    }
    
     
    const config = {
      responsive: true,
      displaylogo: false,
      showTips: true,
      pan2d: true,
      modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
      modeBarButtonsToAdd: [
        {
          name: 'Download',
          icon: Plotly.Icons.camera, // Download icon
          click: function() {
            // Handle click on custom lock icon
            // alert('Custom Lock Icon Clicked!');
            toast.success("Downloaded Successfully!")
            // Download the chart as PNG
            downloadContent();
          },
          title: 'Download As Png'
        },
    
      ],
    };
    
    useEffect(() => {
      console.log("out side if ", filter)
        var newFilter = {}
        if(filter.kaizenatTarget === false && filter?.year && filter.quarter === "all"){  
                
                filter.user_id = "all"

                var filters1 = Object.assign({}, filter)
                filters1.user_id = "all"
                filters1.year = filter.year?.value
                achievedTargetFilterData(filters1)
                console.log("inside fist dsta ", filter)
        }
        else if(filter.user_id && filter.user_id !== "all"){
          console.log("inside if ", filter)
            newFilter = {
                "user_id":filter?.user_id?.value,
                "kaizenatTarget" : false
            }
            if(filter.year){
              newFilter.year = filter.year?.value
            }
            getSalesTargetDataList([newFilter])
            // achievedTargetFilterData(newFilter)
            console.log('new filter ', newFilter)
        }else{
            newFilter = {
              "kaizenatTarget": true,
              "user_id": filter?.user_id?.value,
            }
            if(filter.year){
              newFilter.year = filter.year?.value
            }
            getSalesTargetDataList([newFilter])
            console.log('filter years ', newFilter)
        }
    }, [filter]);

    useMemo(() => {
        
        multipleLayout.title = "Sales Target vs Achieved"
        singleLayout.title = "Sales Target vs Achieved"
        getManagersData();
        getYearsData()
    }, []);

    const kaizenatTargets = (e) => {
        
        if (e.target.checked === true) {
            filter.kaizenatTarget = true
            filter.disableQuarterFilter = true
            filter.disableYearFilter = true
            filter.disableAccountManagerFilter = true
            filter.user_id = filter?.user_id?.value
            getSalesTargetDataList(filter)


        }else{
            filter.kaizenatTarget = false
            filter.disableQuarterFilter = false 
            filter.disableYearFilter = false
            filter.disableAccountManagerFilter = false

            if(filter?.year){
              filter.year = filter.year?.value
            }

            if(filter.user_id && filter.user_id !== "all"){
                filter.user_id = filter?.user_id?.value
                getSalesTargetDataList(filter);
            }else{
                filter.user_id = "all"
                achievedTargetFilterData(filter)
            }

            console.log("filter ", filter)
        }
        setFilter(filter)
    }

  
    const removeYearProductListBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadge1",inputValue)
        // var Yearfilter = filter.year?.filter(item => item.value.toString() !== inputValue.toString())
        delete filter.year
        setFilter({...filter})
      }

      const removeFilterUserlistBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadge2", inputValue);
        var filteuser_id = filter.user_id.value === inputValue ? "" : filter.user_id.value;
        setFilter({ ...filter, user_id: filteuser_id });
      }

    return (
        <>
        {  SalestargetLoader  ?
        
            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
        <>

        <div ref={containerRef}>  
        <div className="row">
        
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                <SelectYear isMulti={false} closeMenuOnSelect={true} value={filter.year} years={yearsList}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                
                  {/*  <SelectYear years={yearsList}  disableYearFilter={filter.disableYearFilter} updateFilter={ (value) => setFilter({ ...filter, year: value }) } /> */} 
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <QuartersFilter quartersList={quartersList} disableQuarterFilter={filter.disableQuarterFilter} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                 <UsersListFilter values={filter.userlist}  selectSingle ={true} placeholder={"User List"} productFamilyList={accountManagersList} isDisabled={filter.disableAccountManagerFilter}  updateFilter={ (value) => setFilter({ ...filter, user_id: value}) } />
                    {/* <AccountMangersSingle multiple = {false} accountManagers={accountManagersList} disableAccountManagerFilter={filter.disableAccountManagerFilter}  updateFilter={ (value) => setFilter({ ...filter, user_id: value }) } /> */}
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '11%' }}>
                    <input defaultChecked={filter.kaizenatTarget} type="checkbox" id="kaizenat_target" value={filter.kaizenatTarget} onChange={kaizenatTargets} />
                    <label for="kaizenat_target">Kaizenat Targets</label>
                </div>
                <div className="row">
        <div className="col-md-12">
        {
          filter?.year?
          <Badge   onClick={removeYearProductListBadge}
          pill data={filter?.year?.value} value={filter?.year?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter?.year?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          : null 
        }
        {/* {
            filter?.year?.map((filter) => (
              <Badge   onClick={removeYearProductListBadge}
                pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            ))
          } */}
          {
            (filter.user_id !=="all" && filter.user_id ) && 
          <Badge onClick={removeFilterUserlistBadge}
              pill data={filter.user_id?.value} value={filter.user_id?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.user_id?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
        }
          

        </div>
        </div>

                {achievedTarget && achievedTarget.length>0 ?
                <PlotlyComponent
                id="achievedTargetComponents"
                    data={JSON.parse(JSON.stringify(achievedTarget))}
                    // (filter.kaizenatTarget === undefined || filter.kaizenatTarget === true || filter.user_id !== "all" ) ? 
                    layout={ (filter.kaizenatTarget === true || filter.user_id !== "all")? multipleLayout : singleLayout  }
                    // (filter.user_id != "all") ? singleLayout : 
                    useResizeHandler
                    className="w-full h-full"
                    config={config}
                /> : null }
            </div>
            </div>
            </>
        }
          
        </>
    )
});


const mapStateToProps = (state) => ({
    achievedTarget : state.dashboard.achievedTarget,
    accountManagersList : state.dashboard.accountManagersList,
    yearsList : state.dashboard.yearsList,
    SalestargetLoader:state.dashboard.SalestargetLoader,
    QuartervsQuarterLoader:state.dashboard.QuartervsQuarterLoader
    // SalestargetLoader:state.dashboard.SalestargetLoader
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getManagersData: () => dispatch(getManagers()),
    getYearsData : () => dispatch(getYears()),
    getSalesTargetDataList : (data) => dispatch(getSalesTargetData(data)),
    achievedTargetFilterData : (data) => dispatch(achievedTargetFilter(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(SalesTarget);