import { useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./forgotPassword.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const email = useRef();
  const { user, isFetching, error, errorPayload, loginStatus } = useSelector(state => state.auth);
  const history = useHistory();
  const [msg, setMsg] = useState("");

  const handleClick = async(e) => {
    e.preventDefault();
    var response  = await axios.post("/user/forgot/password", {email: email.current.value}).then(function (res) {
      // console.log("res data error", res)
      if (res.data.status === "success") {
        history.push({              
          pathname: '/reset-password',
          data: email.current.value,
          state: { email: email.current.value }
        });
      } else {
        //console.log("res", res);
        //console.log("error ", error)
        toast.error("User Does Not Exist") 
        setMsg("Failed " + error);
        // alert(" Failed to Sent OTP in the mail. " + res.data);
      }
    });;
  }

  return (

    <div className="login" >
      <div className="loginWrapper">
        <div className="loginRight">
          <form className="forgotBox" onSubmit={handleClick}>
            <div className="text-center">
              <h2>Forgot Password</h2>
            </div>
            <input placeholder="Email" type="email" required className="loginInput" ref={email} />
            <Link to="/login">
              <a className="forgotPassword">Login</a>
            </Link>
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (

                <CircularProgress />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>

  );
}

// export default Login;