
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";

import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showBattleCardModal:false,
    createbattlecards:[],
    battlecardslist:[],


    benchmarklist: [],
    benchmarktiming:[],
    benchmarkdeleteDemoRequest:[],
    benchmarkassignlist:[],
    addtechactivity:[],
}

const BattlecardSlice = createSlice({
    name: "Battlecardlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          assign_openModal: (state, action) => {
            state.showModal_assign = true; 
          },
          assign_closeModal: (state, action) => {
            state.showModal_assign = false; 
          },

         battlecard_openModal: (state, action) => {
            state.showBattleCardModal = true; 
          },
          battlecard_closeModal: (state, action) => {
            state.showBattleCardModal = false; 
          },
         Viewcontact_openModal : (state, action) => {
          state.showModal_Viewcontact = true; 
        },
        Viewcontact_CloseModal: (state, action) => {
          state.showModal_Viewcontact = false; 
        },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          createbattlecardSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createbattlecards = action.payload;
            toast.success("Created Battle card") 
            state.showBattleCardModal = false;
    
          },
          createbattlecardFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createbattlecards = action.payload;
            toast.error("Error in Battle card ") 
            state.showBattleCardModal = true;
    
          },
          battlecardsSuccess(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.battlecardslist = action.payload;
            // toast.success(" get Battle card data list") 
    
          },
          
          battlecardsFailure (state,action) {
            state.isFetching = false
            state.error = false;
            state.errorPayload = null;
            state.battlecardslist = [];
            toast.error("Error in battle card list") 
          },
      

    }  
});
export const BattlecardActions = BattlecardSlice.actions;


//BENCHMARK LIST START HERE

export const createbattlecard = (formdata) => {
  return async(dispatch) => {
      dispatch(BattlecardActions.loaderStart("meetingloader"))
      try {
          await Api.post("/user/battlecard/create",formdata).then((res) => {
              if(res.data.status === "success") {
                  dispatch(BattlecardActions.createbattlecardSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(BattlecardActions.createbattlecardFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(BattlecardActions.createbattlecardFailure("Network Error"));
      }
  }
}


export const getbattlecards = (formdata) => {

  return async(dispatch) => {
      dispatch(BattlecardActions.loaderStart())

      try {
          await Api.get("/user/battlecard/list",formdata).then((res) => {
              if(res.data.status === "success"){
                  dispatch(BattlecardActions.battlecardsSuccess(res.data));
                  }
                  if(res.data.status ==="failure") {
                      dispatch(BattlecardActions.battlecardsFailure(res.data.msg))
                  }
          })
      }
      catch (err) {
        dispatch(BattlecardActions.battlecardsFailure("Network Error"));

      }
  }
} 



  

  export default BattlecardSlice.reducer;