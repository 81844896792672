import React, { useState, useEffect } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import { Button } from 'react-bootstrap';
import SetTargetTable from '../../components/commonColumns/SetTargetTable';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import moment from 'moment';
import AddTargets from './AddTargets';

import { Chart, Bars, Transform } from 'rumble-charts';

const SetTarget = () => {
  const [data, setData] = useState([]);
  const [yearList, setYearList] = useState([]);
  const { register: targetRegister, handleSubmit: setTargetHandleSubmit, formState: { errors: targetErrors } } = useForm();
  const [targetData, setTargetData] = useState({
    year: moment().format('YYYY')
  });


  const fechData = async () => {

    const updatedData = {
      year: targetData.year || moment().format('YYYY'),
    }
    try {
      const res = await axios.post("/common/targets/list", updatedData)
      if (res.data.status === "success") {
        setData(res.data.list);
      }
    } catch (err) {
    }

  }
  const getYearList = () => {
    setYearList([{ label: "2020", value: '2020' }, { label: "2021", value: '2021' }, { label: "2022", value: '2022' }, { label: "2023", value: '2023' }, { label: "2024", value: '2024' }])
  }


  useEffect(() => {
    fechData();
    getYearList();
  }, [])

  const onInputChange = (e) => {
    setTargetData({ ...targetData, [e.target.name]: e.target.value }, []);

  };

  return (
    <>
      <Navbar value="setTargets" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        {/* <div className="request_teche my-3"> */}
          <>
            {/* <a href="/set-target-tree">
              <Button className="btn-sm navbarAddBtn btn_add_common">Set Target</Button>
              
            </a> */}
            {/* <a href="/assign-segments">
              <Button className="btn-sm navbarAddBtn btn_add_common">Assign Segments</Button>
            </a>
             */}
          </>
        {/* </div> */}
        {/* <form onSubmit={setTargetHandleSubmit(fechData)}>
          <div className="container-fluid">
            <div className="row">
              <br />
              <div class="col-lg-5 col-md-8 col-sm-12 col-12">
                <div className="row">
                  <div className="col-md-10 col-10 col-sm-10">
                    <TextField variant="outlined"
                      {...targetRegister("year", {
                      })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={targetData.year}
                      select
                      helperText={targetErrors.year ? targetErrors.year.message : ''}
                      error={Boolean(targetErrors.year)}
                      onChange={e => onInputChange(e)}
                      required
                      type="text" className="form-control" name="year" label="Year"
                    >
                      {yearList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                      }
                    </TextField>
                  </div>
                  <div className="col-md-2 col-sm-2 col-2">

                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form> */}
        {/* <br /> */}
        <div className="demo_table">
          <AddTargets></AddTargets>
          {/* <SetTargetTable data={data} refreshTargetData={() => fechData()} /> */}
        </div>
      </div>
    </>
  )
};

export default SetTarget;