import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { connect } from 'react-redux';
import{EmailcampaignActions} from "../../store/EmailCampaign"

const ViewEmailContent = React.memo (({user,getdigitalmarketinglists,openmodal,closemodal}) => {

    const html = getdigitalmarketinglists;
    const contentBlock = htmlToDraft(getdigitalmarketinglists ? getdigitalmarketinglists : html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    return (
        <>
          <Modal
            show={() => openmodal()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title">
    
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
              </Modal.Title>
              <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                            <div className="col-md-12">

                                    <label id="mail_subject">Mail Body</label>
                                    <Editor
                                        id="mail_subject"
                                        placeholder='Enter Mail Content...'
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                    />

                                <textarea
                                     style={{display:'none'}}
                                        disabled
                                        placeholder='Enter Mail Body Content...'
                                        ref={mailBody}
                                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                            </div>
                        </div>
            </Modal.Body>
          </Modal>

        </>
      )
    });

const mapStateToProps = (state) => ({
  user : state.auth.user,
  getdigitalmarketinglists : state.Emailcampaignlist.getdigitalmarketinglists,
});

const mapDispatchToProps = (dispatch) => ({
  openmodal :(data) => dispatch(EmailcampaignActions.show_viewContentModal_openmodal(data)),
  closemodal:(data) => dispatch(EmailcampaignActions.show_viewContentModal_closemodal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (ViewEmailContent)