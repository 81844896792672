// import { useContext, useRef } from 'react';
// import { ResetPasswords } from '../../../apiCalls'
// import { AuthContext } from '../../../context/AuthContext';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import "./resetPassword.css";
// import { useHistory } from "react-router";
// import { useLocation } from "react-router-dom";
// import { Link } from 'react-router-dom';

// import { useSelector } from 'react-redux';


// export default function ResetPassword() {
//   const email = useRef();
//   const forgot_otp = useRef();
//   const password = useRef();
//   const confirm_password = useRef();

//   const { user, isFetching, error, dispatch } = useSelector(state => state.auth);


//   const history = useHistory();
//   const location = useLocation();

//   const handleClick = (e) => {
//     e.preventDefault();


//     if (password.current.value !== confirm_password.current.value) {
//       alert("Password and Confirm passwords do not match");
//       return;
//     }

//     var response = ResetPasswords({
//       email: email.current.value,
//       forgot_otp: forgot_otp.current.value,
//       password: password.current.value,
//     }, dispatch).then(function (res) {

//       if (res.status === 200) {
//         alert("We have Reset your password, please login with new password.")
//         history.push("/login");
//       } else {
//         alert("error : " + res.response.data);
//       }
//     });
//   }
//   return (

//     <div className="login" >
//       <div className="loginWrapper">
//         <div className="loginRight">
//           <span style={{ color: 'black', textAlign: 'center' }}>Note: Do not refresh the page.</span>
//           <form className="ResetBox" onSubmit={handleClick}>

//             <div className="text-center">
//               <h2>Reset Password</h2>
//             </div>
//             <div className='text-center'>
//               <span style={{ color: '#18c96e' }}>Please enter OTP sent to {location.data}</span>
//             </div>

//             <input placeholder="Email" type="email" required className="loginInput" value={location.data} ref={email} disabled />

//             <input type="number" placeholder="OTP" className="loginInput" required ref={forgot_otp} />

//             <input type="password" placeholder="New Password" name="password" ref={password} className="loginInput" required />

//             <input type="password" placeholder="Confirm New Password" name="confirm_password" ref={confirm_password} className="loginInput" required />

//             <Link to="/login">
//               <a className="forgotPassword">Login</a>
//             </Link>
//             <button className="loginButton" type="submit" disabled={isFetching}>
//               {isFetching ? (

//                 <CircularProgress />
//               ) : (
//                 "Reset Password"
//               )}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>

//   );
// }




import { useContext, useEffect, useRef, useState } from 'react';
import { ResetPasswords } from '../../../apiCalls'
import { AuthContext } from '../../../context/AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./resetPassword.css";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import axios from 'axios';


export default function ResetPassword() {
  const email = useRef();
  const forgot_otp = useRef();
  const password = useRef();
  const confirm_password = useRef();

  const { user, isFetching, error, dispatch } = useSelector(state => state.auth);


  const history = useHistory();
  const location = useLocation();

  // const getemail = location.data
  // console.log("gettingemail",getemail)
  const handleClick = (e) => {
    e.preventDefault();


    if (password.current.value !== confirm_password.current.value) {
      alert("Password and Confirm passwords do not match");
      return;
    }

    var response = ResetPasswords({
      email: email.current.value,
      forgot_otp: forgot_otp.current.value,
      password: password.current.value,
    }, dispatch).then(function (res) {

      if (res.status === 200) {
        alert("We have Reset your password, please login with new password.")
        history.push("/login");
      } else {
        alert("error : " + res.response.data);
      }
    });
  }


  const [time, setTime] = useState(90);
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    startTimer();
  }, []);
  
  const startTimer = () => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          setShowResend(true); // Set showResend to true after timer reaches 0
          return 0;
        } else return time - 1;
      });
    }, 1000);
  };

  // const handleResend = () => {
  //   // Handle resend logic here
  //   console.log('Resend OTP');
  //   // Reset timer
  //   setTime(10);
  //   startTimer()
  //   setShowResend(false); // Reset showResend to false
  // };

  
const handleResend = async () => {
  try {
    // Handle resend logic here
    console.log('Resend OTP');

    // Call API to resend OTP
    const response = await axios.post("/user/forgot/password", { email: email.current.value });

    // Check response status
    if (response.data.status === "success") {
      // Reset timer
      setTime(90);
      startTimer()
      setShowResend(false); // Reset showResend to false
      console.log('OTP Resent Successfully');
    } else {
      console.log('Failed to resend OTP');
      // Handle error or display message accordingly
    }
  } catch (error) {
    console.error('Error while resending OTP:', error);
    // Handle error or display message accordingly
  }
};

  return (

    <div className="login" >
      <div className="loginWrapper">
        <div className="loginRight">
          <span style={{ color: 'black', textAlign: 'center' }}>Note: Do not refresh the page.</span>
          <form className="ResetBox" onSubmit={handleClick}>

            <div className="text-center">
              <h2>Reset Password</h2>
            </div>
            <div className='text-center'>
              <span style={{ color: '#18c96e' }}>Please enter OTP sent to {location.data}</span>
            </div>

            <input placeholder="Email" type="email" required className="loginInput" value={location.data} ref={email} disabled />

            {showResend  ? <input type="number" placeholder="Click the Resend Otp" className="loginInput" required ref={forgot_otp} readOnly /> :
            <input type="number" placeholder="OTP" className="loginInput" required ref={forgot_otp}  /> }

            <div className='otpTimerbox'>
      
      {showResend  ?
        <p style={{color:"red"}} onClick={handleResend}>Resend OTP</p>
      
      :<p>
      <span style={{ color: 'green' }}>Please enter OTP : </span>
      <span style={{ color: 'red' }}>
        {`${Math.floor(time / 60)}`.padStart(2, '0')}:{`${time % 60}`.padStart(2, '0')}
      </span>
    </p> }

    </div>

            <input type="password" placeholder="New Password" name="password" ref={password} className="loginInput" required />

            <input type="password" placeholder="Confirm New Password" name="confirm_password" ref={confirm_password} className="loginInput" required />

            <Link to="/login">
              <a className="forgotPassword">Login</a>
            </Link>
            <button className="loginButton" type="submit" disabled={isFetching}>
              {isFetching ? (

                <CircularProgress />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>

  );
}

