import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CreatableSelect from 'react-select/creatable';
import AddNewContact from "../pageModals/AddNewContact";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { connect, useSelector,useDispatch } from 'react-redux';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {createmeetingdata,CallrecordsActions} from "../../store/Callrecords";
import {ContactActions} from "../../store/Contact.js";
import {CommonsliceActions} from "../../store/Common.js";
import { getEconomicBuyersList, getPersonsList } from '../../store/leads.js';
const CreateCallRecordModal = ({createmeeting,openmodal,closemodal,ContactopenModal,newaccountId,newselectedAccount,getEconomicBuyersListHandle,companyContactsList,companiesListDatahere, getPersonsListData}) => {
  const dispatch = useDispatch();
    var today = new Date();
    const action_date = useRef();
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();
  console.log("contact person lists are", companyContactsList)
  console.log("contact person lists are//", companiesListDatahere)
  const [selectedAccount, setSelecteAccount] = useState("");
  const [searchCompanyName, setSearchCompantName] = useState("");
  const [leadId, setLeadId] = useState("");
  const [accountId, setAccountId] = useState(false);
  console.log("accountid is", accountId)
  const [productList, setProductList] = useState([]);
  const [callingTypeList, setCallingTypeList] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [contactsList, setContactsList] = useState([]);
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [meetingDate, setMeetingDate] = useState(today);

const formatWeekSelectLabel = (date, invalidLabel) => {
  return `${moment(date).format("MMM Do YYYY LT")}`
};
  const [callRecordData, setCallRecordData] = useState({

    account_id: selectedAccount,
    contact_id: "",
    call_type: "",
    call_objective:"",
    remarks: "",
    calling_date_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
  });

  const { register: callingRegister, handleSubmit: callingHandleSubmit, setError: callingSetError, reset: reset, formState: { errors: callingErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  var today = new Date();
  var twodays = moment().add(2, 'days');
  const ProductList = async () => {
    const res = await axios.get("/products/search")
    setProductList(res.data.productsList)
  }
  const getCallingTypeList = () => {
    setCallingTypeList([{ label: "Initial", value: 'Initial' }, { label: "Follow-up", value: 'Follow-up' }])
  }

  useEffect(() => {
    ProductList();
    getCallingTypeList();
  }, [])
  const AddcontactModal = useSelector((state) => state.Contactlist.AddcontactModal);
  const handleClick = () => {
    setOpen(true);
  };
  const onInputChange = (e) => {
    setCallRecordData({ ...callRecordData, [e.target.name]: e.target.value }, []);
  };

  const addNewTechieMeeting = async (e) => {
    setDisable(true);
  
    setTimeout(() => { }, 500);

    const updatedData = {
      account_id: accountId,
      lead_id:leadId,
      company_name:searchCompanyName,
      contact_id: callRecordData.contact_id,
      meeting_date_time:callRecordData.calling_date_time+"Z",
      call_type: callRecordData.call_type,
      call_objective:callRecordData.call_objective,
      remarks: callRecordData.remarks,
    }

    createmeeting(updatedData)

   
  }

  const getAccountsList = async (search) => {
// console.log("valueacoountlist",search)
    setCompaniesList([]);

    const res = await axios.get("/accounts/search/" + userData._id + "/" + search.target.value);
    setCompaniesList(res.data?.AccountsList);
    

  }

  const handleSetCompanyName = (value) => {
    console.log("companydata",value)

    getPersonsListData(value.account_id)
    setSelecteAccount(value.account_id)
    newselectedAccount(value.account_id)
    setSearchCompantName(value.value)

    if(!value.lead_id){
        setOpen(true);
        alert("There is no lead for this company, please add new lead before adding DCR..")
     dispatch(CallrecordsActions.callrecordmodal_closeModal())
        
    }else{
        setLeadId(value.lead_id)
    }


    if (value.__isNew__ === true) {

    } else {
      setAccountId(value.account_id);
      newaccountId(value.account_id);
      // getContactList(value.account_id);
    }
  }


  // const getContactList = async (data) => {
  //   getEconomicBuyersListHandle(data)
  //   // setCompaniesList([]);
  //   // const res = await axios.get("/demos/economic-buyer-list/" + data);
  //   // setContactsList(res.data.economicBuyerList);
  // }
  useEffect(() => {
    setCompaniesList(callRecordData?.account_id);
    // getEconomicBuyersList
  }, [])


// const getContactName =(accountId) => {
  // getEconomicBuyersListHandle(accountId)
  // alert("indeside")
// }


// useEffect(() => {
//   if(accountId){
//     getContactName(accountId)
//   }
// },[accountId])

  const handleSetContactName = (value) => {
    // dispatch(ContactActions.AddContactopenModal(value));
    // ContactopenModal()
    if (value.__isNew__ !== true) {
      callRecordData.contact_id = value.value;
    }

    if (value.__isNew__ === true) {
      // setShowAddContactModal(true);
      ContactopenModal()
    }
  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };

  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    callRecordData.new_date_time = newDate;
  }




  const returnValue = async (contactId) => {

    callRecordData.contact_id = contactId;
  }

  const handleMeetingDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    setMeetingDate(newDate);
  }

  return (
    <>
      <Modal
        show={() => openmodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Create Call Record"}
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={callingHandleSubmit(addNewTechieMeeting)}>
            <div class="row">
              <div class="col-md-6">
                <Select
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Account Name*"
                  styles={colourStyles}
                  onChange={handleSetCompanyName}
                  onKeyDown={getAccountsList}
                ></Select>
              </div>
              <div className="col-md-6">
                <CreatableSelect
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companyContactsList}
                  placeholder="Search Contact Person*"
                  name="contact_name"
                  onChange={handleSetContactName}
                  styles={colourStyles}
                  autoComplete="none"
                  required
                  formatCreateLabel={() => `Add New Contact`}
                  isValidNewOption={() => true}
                  // onKeyDown={getContactName}
                >
                </CreatableSelect>
              </div>
            </div>
            <br />
            
            <div className="row">

              <div class="col-md-6">

                <TextField variant="outlined"
                  {...callingRegister("call_type", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={callRecordData.call_type}
                  select
                  helperText={callingErrors.call_type ? callingErrors.call_type.message : ''}
                  error={Boolean(callingErrors.call_type)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="call_type" label="Call Type"
                >
                  {callingTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              <div className="col-md-6">

                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            className="form-control"
                                            label="Calling Date Time*"
                                            inputVariant="outlined"
                                            value={callRecordData.calling_date_time}
                                            onChange={(date) => setCallRecordData({...callRecordData, calling_date_time: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
                                            showTodayButton
                                            labelFunc={formatWeekSelectLabel}
                                            inputRef={action_date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                       </MuiPickersUtilsProvider>
                </div>
            </div>
            <br />
            <div className="row">
            <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...callingRegister("call_objective", {
                    required: {
                      value: true,
                      message: "call_objective is required"
                    },
                    minLength: {
                      value: 5,
                      message: "call_objective must be at least 5 characters"
                    }

                  })}
                  helperText={callingErrors.call_objective ? callingErrors.call_objective.message : ''}
                  error={Boolean(callingErrors.call_objective)}
                  type="text" className="form-control" value={callRecordData.call_objective} name="call_objective" label="Call Objective*" onChange={e => onInputChange(e)} />
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...callingRegister("remarks", {
                    required: {
                      value: true,
                      message: "Remarks is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Remarks must be at least 5 characters"
                    }

                  })}
                  helperText={callingErrors.remarks ? callingErrors.remarks.message : ''}
                  error={Boolean(callingErrors.remarks)}
                  type="text" className="form-control" value={callRecordData.remarks} name="remarks" label="Remarks*" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>

              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* {AddcontactModal ? (
        <AddNewContact/>
      ) : null} */}
      
     {/*  {showAddContactModal ? <AddNewContact show={showAddContactModal} companyName={selectedAccount} accountId={accountId} refreshContactslist={() => refreshContactsList()} returnValue={(contactId) => returnValue(contactId)} onHide={() => setShowAddContactModal(false)} /> : ""} */}
     {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={selectedAccount} otherAccountId={accountId} returnValue={(contactId) => returnValue(contactId)} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}

    </>
  )
};

const mapStateToProps = (state) => ({
    user : state.auth.user,
    companyContactsList:state.leads.companyContactsList,
    companiesListDatahere:state.leads.companiesList
});

const mapDispatchToProps = (dispatch) => ({
  createmeeting :(data) => dispatch(createmeetingdata(data)),
  openmodal: (data) => dispatch(CallrecordsActions.callrecordmodal_openModal()),
  closemodal:(data)=> dispatch(CallrecordsActions.callrecordmodal_closeModal()),
  newaccountId:(data) => dispatch(CommonsliceActions.setRowId(data)),
    newselectedAccount:(data) => dispatch(CommonsliceActions.setcompanyName(data)),
  ContactopenModal:() => dispatch(ContactActions.AddContactopenModal()),
  getEconomicBuyersListHandle:(data) => dispatch(getEconomicBuyersList(data)),
  getPersonsListData : (data) => dispatch(getPersonsList(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (CreateCallRecordModal);

