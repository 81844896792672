import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { connect, useSelector } from 'react-redux';
import {createcallrecordsdata,CallrecordsActions} from "../../store/Callrecords"

  const CallActionModal = React.memo (({callactionupdate,leadID,callRecordId,openmodal,closemodal,Calltype}) => {
    // console.log("caltype2",Calltype)
    // console.log("callRecordId",callRecordId)
    // console.log("leadID",leadID)
    var today = new Date();
    var threedays = moment().add(3, 'days');
  
    var twodays = moment().add(2, 'days');
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [reminderDate, setReminderDate] = React.useState(moment(twodays).format('LLLL'));
  const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");

  const action_date = useRef();
  const reminder_date = useRef();

  const [techieData, setTechieData] = useState({
    action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
    reminder_date: moment(reminderDate).format('YYYY-MM-DD HH:mm:ss'),
  });

  const { register: callingRegister, handleSubmit: callActionHandleSubmit, setError: callSetError, reset: reset, formState: { errors: trainingErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  var twodays = moment().add(2, 'days');

  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY LT")}`
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onInputChange = (e) => {
    setTechieData({ ...techieData, [e.target.name]: e.target.value }, []);
  };

  const addNewActionReminderTime = async (e) => {

    setDisable(true);
    setSnackBarMessage("Uploading Data ...!");
    setOpen(true);

    setTimeout(() => { }, 500);

    var updateData = {
        call_record_id : callRecordId,
        lead_id: leadID,
        action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss')+"Z",
        reminder_date: moment(reminderDate).format('YYYY-MM-DD HH:mm:ss')+"Z",
      }

      const calltypeData = {
        Calltype:Calltype
      }
      callactionupdate([updateData,calltypeData])
  }


 
  const handleReminderDateChange = (date) => {

    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    var action = moment(actionDate)
    let remind = moment(newDate)
    let diff = remind.diff(action, 'minutes');
    if(diff > 0){
      let remidDate = moment(actionDate).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
      setReminderDate(remidDate);
      alert("Reminder date should be earlier than action date")
      return;
    }else{
      setReminderDate(newDate);
    }

  };

  const handleActionDateChange = (date) => {

    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    var remidDate = moment(date).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
    setActionDate(newDate);
    setReminderDate(remidDate)

  }

  return (
    <>
      <Modal
        show={openmodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Call Action"}
          </Modal.Title>
          <a onClick={closemodal} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={callActionHandleSubmit(addNewActionReminderTime)}>
          <div className="row">
                          <div className="col-md-5">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                className="form-control"
                                label="Action Date"
                                inputVariant="outlined"
                                value={actionDate}
                                onChange={handleActionDateChange}
                                labelFunc={formatWeekSelectLabel}
                                showTodayButton
                                inputRef={action_date}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          <div className="col-md-5">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                className="form-control"
                                label="Reminder Date"
                                inputVariant="outlined"
                                value={reminderDate}
                                labelFunc={formatWeekSelectLabel}
                                onChange={handleReminderDateChange}
                                showTodayButton
                                inputRef={reminder_date}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </MuiPickersUtilsProvider>

                          </div>

                        </div>
            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>

              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    leadID:state.Callrecordlist.RowLeadId,
    callRecordId:state.Callrecordlist.CallRecordId,
    Calltype:state.Callrecordlist.Calltype
});

const mapDispatchToProps = (dispatch) => ({
  callactionupdate :(data) => dispatch(createcallrecordsdata(data)),
  openmodal : () => dispatch(CallrecordsActions.callactionmodal_openModal()),
  closemodal : () => dispatch(CallrecordsActions.callactionmodal_closeModal()), 
});

export default connect(mapStateToProps,mapDispatchToProps) (CallActionModal)
