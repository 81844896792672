import React, { useEffect, useState } from 'react';
import Navbar from '../home/Navbar';
import ResourcesTable from '../../components/commonColumns/ResourcesTable';
import axios from "axios";
import './list.css';
export default function ResourcesList() {

  const [data, setData] = useState([]);
  const [metricData, setMetricData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const [progress, setProgress] = useState("Loading...");
  const [page, setPage] = useState("resources");

  const fetchData = async () => {
    
    try {
      const res = await axios.get("/user/resources/list");
      if (res.data.status === "success") {
        setProgress("No Records Found.")
      } else {
        setProgress("No Records Found.")
      }
      setData(res.data.resourcesList);
    } catch (err) {
      setProgress("Error in Retrieving Data.")
    }
  };

  const fetchIndustryMetric = async () => {
    try {
      const res = await axios.get("/user/resources/industry-metrics/list");
      if (res.data.status === "success") {
        setProgress("No Records Found.")
      } else {
        setProgress("No Records Found.")
      }
      setMetricData(res.data.metricsList);
    }catch(err) {
      setProgress("Error in Retrieving Data.")
    }
  }

  useEffect(() => {
    let searchParamLeadId = queryParams.get('lead_id') ? queryParams.get('lead_id') : null;
    fetchData(searchParamLeadId);
    fetchIndustryMetric();
  }, []);



  return (
    <>
      <Navbar reFreshNewLeadTable={() => fetchData()} value="resources" className="activity_list_navbar" />
      <ResourcesTable data={data} refreshTableData={() => fetchData()} progress={progress} /> 
    </>
  );
}

