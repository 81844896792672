import { createSlice } from "@reduxjs/toolkit";
import Api  from './axios';

var userObj = JSON.parse(localStorage.getItem("user")) || null


const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,
    loginStatus:userObj ? true : false, 

    loginErrormsg:false, 
    username:userObj?.username, 
    logoutSuccess:false, 
    logoutFailure:false, 
    
    loginError:false, 
    registerError:false, 
    registerStatus: false, 
    userDetails : {}, 
    data: [], 
    loaderStatus:[],
    page_name:"register", 
    form_fields:{full_name:"",email:"",mobile:"",password:"",cpassword:""},login:{email:"",password:""}, 
    logout:{}, 
    passcode: { otp_key: "" }, 
    OtpError: false, 
    OtpStatus: false, 
    otpMesg:[], 
    alreadyRegister:false,
    forgotPassword:{email:""},
    forgetPass:{email:""},
    forgotPasswordMsg:false,
    resetPassword:{password:"",cpassword:""},
    contactSuccess:false, 
    contactFailure:false, 
    contactStatus:false, 
    contactError:false, 
    contactErrormsg:false,
    contact_field:{name:"",email:"",mobile:"",query:""}
}

const authSlice = createSlice({
    name: 'auth',
    initialState: intialAuthState,
    reducers: {
        loginButtonLoaderStart(state, action) {
            state.isFetching = true
        },
        loginButtonLoaderStop(state,action){
            state.isFetching = false
        },
        loginSuccess(state, action) { 
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.user = action.payload
            state.loginStatus = true
            
        },
        loginFailure(state, action){
            state.isFetching = false
            state.errorPayload = action.payload
            state.error = true
            state.loginStatus = false
        }
    }
})

export const authActions = authSlice.actions;

export const LoginButtonLoaderStart = () => {
    return async(dispatch) => {
        dispatch(authActions.loginButtonLoaderStart())
    }
}

export const LoginButtonLoaderStop = () => {
    return async(dispatch) => {
        dispatch(authActions.loginButtonLoaderStop())
    }
}

export const loginCall =  (userCredential) => {

    return async (dispatch) => {
        try {
            await Api.post("/user/login", userCredential).then((res) => {
                    if (res.data.status === "success") {
                        
                        localStorage.setItem("user", JSON.stringify(res.data))
                        
                        // window.location.reload();
                        dispatch(authActions.loginSuccess(res.data))
                        
                    }
              
                    if (res.data.status === "failure") {
                        dispatch(authActions.loginFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(authActions.loginFailure("Network Error"));
        }
    }
  
  };
  


  export default authSlice.reducer;