import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
// import axios from "axios";
import { useForm } from "react-hook-form";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { useSelector, useDispatch, connect } from 'react-redux';
import {  getTeamMembersList, fetchEfficiencyChart } from "../../store/dashboard";


const EfficiencyChart = React.memo(({teamMembersList, efficiencyChart, getTeamMembersListData, fetchEfficiencyChartData,EfficiencychartLoader }) => {

  // const [data, setData] = useState([]);
  // const [teamMembersList, setTeamMembersList] = useState([]);
  const [timesheetData, setTimesheetData] = useState({
    assignees: "",
    from_date: moment().startOf('month').format('YYYY-MM-DD'),
    to_date: moment(new Date()).format('YYYY-MM-DD')
  });
  
  const [ multicolor, setMulticolor]= useState("red")
  // const [progress, setProgress] = useState("Loading...");
var calendarMonth = moment(timesheetData.from_date);
  var check = moment(new Date());
var month = check.format('MMMM')
var Cmonth = calendarMonth.format('MMMM')

var monthN = check.format('MM')
var CmonthN = calendarMonth.format('MM')



  const {
    register: timesheetRegister,
    setError: timesheetSetError,
    reset: reset,
    handleSubmit: setEfficiencyHandleSubmit,
    formState: { errors: timesheetErrors },
  } = useForm();


  const {user} = useSelector(state => state.auth)
  const permissions = user.permissions;
  const EfficiencyPercentage = Math.round(efficiencyChart?.efficiency);
  const fetchData = async () => {
    const updatedData = {
      start_date: timesheetData.from_date,
      end_date: timesheetData.to_date,
      user_id: timesheetData.assignees,
    };

    /// Demo data for filters //
const DemoData = {
  user_id: timesheetData.assignees,
}

    fetchEfficiencyChartData([updatedData,DemoData]);
    
    // try {
    //   const res = await axios.post(
    //     "",
    //     updatedData
    //   );
    //   if (res.data.status === "success") {
    //     setProgress("No Records Found.");
    //   } else {
    //     setProgress("No Records Found.");
    //   }
    //   setData(res.data);
      
    // } catch (err) {
    //   setProgress("Error in Retrieving Data.");
    // }
  }

  // const getAssignedMembersList = async () => {
  //   const res = await axios.get("");
  //   setTeamMembersList(res.data.list);
  // };

 const setMulticolorHandle = (per) => {
    
    if(per <= 30){
        setMulticolor("red")
    }else if(per >30 && per <=50){
        setMulticolor("#FF9933")
    }else if(per >50 && per <=80){
        setMulticolor("#3e98c7")
    }else{
        setMulticolor("green") 
    }

 }
  useMemo(() => {
    fetchData();
    getTeamMembersListData();
  }, []);

  useMemo(() => {
    setMulticolorHandle(EfficiencyPercentage);
  },[EfficiencyPercentage])

  const onInputChange = (e) => {
    setTimesheetData({ ...timesheetData, [e.target.name]: e.target.value }, []);
  };

  return (
    <>
    {EfficiencychartLoader  ?
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
  <>
 
      <form onSubmit={setEfficiencyHandleSubmit(fetchData)}>
        <div className="container-fluid">
          <div className="row efficiency_filters">
            <div className="col-md-3 col-lg-3">
              {permissions.includes("viewAnyTeamMembersChart") || permissions.includes("viewTeamMembersChart")  ?
                <div className="">
                  <TextField
                    variant="outlined"
                    // {...timesheetRegister("assignees", {})}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={timesheetData.assignees}
                    select
                    helperText={ timesheetErrors.assignees ? timesheetErrors.assignees.message : ""}
                    error={Boolean(timesheetErrors.assignees)}
                    onChange={(e) => onInputChange(e)}
                    type="text"
                    className="form-control"
                    name="assignees"
                    label="Assignees"
                  >
                    {teamMembersList?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
            : null}
            </div>
            <div className="col-md-3 col-lg-3">
            <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={timesheetData.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
              />
            </div>

            <div className="col-md-3 col-lg-3">
            <TextField variant="outlined" type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                label="To Date" name="to_date" className="form-control" value={timesheetData.to_date} id="preliminary-review-start"
                onChange={e => onInputChange(e)}
             />
            </div>
            <div className="col-md-3 col-lg-3">
              <button className="btn btn-sm btn-primary upadte_common_btn" id="submit"> Submit </button>
            </div>
          </div>
        </div>
      </form>
      <div className="total_hrs_main">
      
      <h2>  {CmonthN === monthN ?  `${month} month efficiency` : ""} </h2>
      </div>
      <div className="circular_chart">
        <CircularProgressbar
        value={EfficiencyPercentage} 
        text={`${EfficiencyPercentage}%`} 
        styles={{
            path: {
              transform: "rotate(180deg)",
              transformOrigin: "center center",
              strokeLinecap: "butt",
              stroke: multicolor,
             
            },
            trail: {
              strokeWidth: 8
            },
            text: {
              fontSize: 15,
              fontWeight: 500,
              animation: "fadein 2s",
              fill:multicolor,
            },
            stroke:"red"
        
          }}
        /> 
      </div>
      <div className="total_hrs_main">
        <h6>Hours Spent{efficiencyChart?.total_task_hours}</h6>
        <h2>
          {efficiencyChart?.total_task_hours_text} / {efficiencyChart?.total_hours}Hrs
        </h2>
      </div>
      </>
    
    }
    </>
  );
});
// export default ;


const mapStateToProps = (state) => ({
  teamMembersList : state.dashboard.teamMembersList,
  efficiencyChart : state.dashboard.efficiencyChart ,
  EfficiencychartLoader:state.dashboard.EfficiencychartLoader
});

const mapDispatchToProps = (dispatch) => ({
  getTeamMembersListData : () => dispatch(getTeamMembersList()),
  fetchEfficiencyChartData : (data) => dispatch(fetchEfficiencyChart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EfficiencyChart);