import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { connect, useDispatch } from 'react-redux'
import{createbattlecard,BattlecardActions} from "../../store/Battlecard"


const BattleCardModal = React.memo(({openshowModal,closeshowmodal,createbattlecarddata}) => {
  const dispatch = useDispatch();
  
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState();
    
    const { payslipregister, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();  
      const [battlecardActivity, setBattlecardActivity] = useState({
       title:"",
  }) 
  const onInputChange = (e) => {
    setBattlecardActivity({ ...battlecardActivity, [e.target.name]: e.target.value }, []);
      
  }
  const changeHandler = (event) => {
      if (event.target.files && event.target.files[0]) {
          let pdfFile = event.target.files[0];
          let pdfFileName = event.target.files[0].name
          setSelectedFile(pdfFile);
          setSelectedFileName(pdfFileName);
        }
  
   
    };
 
    const battlecardhandle = async () => {
         const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("title", battlecardActivity.title);
      
      createbattlecarddata(formData)

  }

  
    return (
      <>
       
      <Modal
      show={() => openshowModal()}
      onHide={() => closeshowmodal()}
      backdrop={false}
      disableBackdrop={true}
    >
          <Modal.Header>
            <Modal.Title>Battle Card  </Modal.Title>
            <a onClick={() => closeshowmodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
          </Modal.Header>
          <Modal.Body>
              <div className='form'>
                  <form onSubmit={handleSubmit(battlecardhandle)}>
                    <div className='row'>
                      <div className='col-md-6' >

                      
                    <TextField
                            variant="outlined"
                            name="title"
                            label="Title"
                             value={battlecardActivity.title}
                            size="small"
                              required
                            className="form-control"
                            onChange={e => onInputChange(e)}
                        />
                        </div>

                    </div>
                    <br/>
                      <br/>
                      <div className='row'>
                      <div className='col-md-7'>
                       
                         <input type="file" className='battlecard-file-input' id="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="pdffile" onChange={changeHandler} />   
                                  <br/> {selectedFileName?selectedFileName:""}
                              </div>
  
                      </div>
                      <br/>
                     <div className='row'>
                      <div className='payslipbutton'  >
                      <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                       </div>
                       </div>
                    </form>
              </div>
          </Modal.Body>
                </Modal>
             
      </>
    );

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
battlecardslist:state.Battlecardlist.battlecardslist
});
const mapDispatchToProps = (dispatch) => ({
  openshowModal: (data) => dispatch(BattlecardActions.battlecard_openModal(data)),
  closeshowmodal: (data) => dispatch(BattlecardActions.battlecard_closeModal(data)),
  createbattlecarddata: (data) => dispatch(createbattlecard(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(BattleCardModal)