import { createSlice } from "@reduxjs/toolkit";
import Api from './axios';
import { toast } from 'react-toastify';
// import { yearsToMonths } from "date-fns/esm";
var userObj = JSON.parse(localStorage.getItem("user")) || null


const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,

    regionsList : [],
    accountManagersList: [],
    msg: "",
    targetDetails: null,
    segmentDetails: null,
    techieUsersList: [],
    ProductsCategoriesList: [],
    selectedProductsList : [],
    selectedCategoriesList : [], 
    selectedSubCategoriesList : [],
    allProductsListTechie: [],
    usersListByRegion: [] 
}

const targetsSlice = createSlice({
    name: 'targets',
    initialState: intialAuthState,
    reducers: {
        loaderStart(state, action) {
            state.isFetching = true
            state.msg = "Fetching..."
        },
        loaderStop(state,action){
            state.isFetching = false
            state.msg = "Fetching..."
        },
        regionsListSuccess(state, action) {
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.regionsList = action.payload
        },
        regionsListFailure(state, action){
            state.isFetching = false
            state.errorPayload = action.payload
            state.error = true
            state.regionsList = []
        },
        accountManagersListSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.accountManagersList = action.payload
        },
        accountManagersListFailure(state, action){
            state.isFetching = false
            state.errorPayload = action.payload
            state.error = true
            state.accountManagersList = []
        },
        updateTargetsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        updateTargetsFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        targetDetailsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.targetDetails = action.payload ? action.payload : []
        },
        targetDetailsFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.targetDetails = null
        },
        assignSegmentSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false;
            state.msg = action.payload
        },
        assignSegmentFailure(state, action) {
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload
            state.msg = null
        },
        segmentDetailsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false;
            state.segmentDetails = action.payload
        },
        segmentDetailsFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload
            state.segmentDetails = null
        },
        techieUsersListSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.techieUsersList = action.payload
        },
        techieUsersListFailure(state, action){
            state.isFetching = false
            state.errorPayload = action.payload
            state.error = true
            state.techieUsersList = []
        },
        productCategoriesListSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.ProductsCategoriesList = action.payload
        },
        productCategoriesListFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.ProductsCategoriesList = []
        },
        productTechieDetailsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.selectedProductsList = action.payload?.products 
            state.selectedCategoriesList = action.payload?.categories 
            state.selectedSubCategoriesList = action.payload?.subCategories 
        },
        allProductListByTechieDetailsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.allProductsListTechie = action.payload 
        },
        allProductListByTechieDetailsFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.allProductsListTechie = []
        },
        updateProductTargetsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            // state.allProductsListTechie = action.payload 
        },
        updateProductTargetsFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
        },
        productTargetDetailsSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.productTargetDetails = action.payload
        },
        productTargetDetailsFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.productTargetDetails = []
        },
        usersByRegionSuccess(state, action){
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.usersListByRegion = action.payload
        }
    }
});

export const targetsActions = targetsSlice.actions;

export const LoaderStart = () => {
    return async(dispatch) => {
        dispatch(targetsActions.loaderStart())
    }
}

export const LoaderStop = () => {
    return async(dispatch) => {
        dispatch(targetsActions.loaderStop())
    }
}

// export const loginCall =  (userCredential) => {

//     return async (dispatch) => {
//         try {
//             await Api.post("/user/login", userCredential).then((res) => {
//                     //console.log("res", res.data)
//                     if (res.data.status == "success") {
//                         localStorage.setItem("user", JSON.stringify(res.data))
//                         dispatch(targetsActions.loginSuccess(res.data))
//                     }
              
//                     if (res.data.status == "failure") {
//                         dispatch(targetsActions.loginFailure(res.data.msg))
//                     }
//                 })
//         }
//         catch (err) {
//             dispatch(targetsActions.loginFailure("Network Error"));
//         }
//     }
  
//   };

export const getRegionTargetDetails = (year, acManager) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            if(!year){
                year = new Date().getFullYear()
            }
            await Api.post("/common/targets/region-target-detail", {year:year.toString(), accountManager:acManager}).then((res) => {
                    
                    if (res.data.status === "success") {
                        dispatch(targetsActions.targetDetailsSuccess(res.data.targetDetails))
                    }

                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.targetDetailsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.updateTargetsFailure("Network Error"));
        }
    }
}


export const updateTargetsValues = (input) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/create-region-target", input).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Targets updated successfully!")
                        dispatch(targetsActions.updateTargetsSuccess(res.data.msg))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.updateTargetsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.updateTargetsFailure("Network Error"));
        }
    }
}
// /
export const getAccountManagers = () => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.get("/user/sales-managers-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.accountManagersListSuccess(res.data.usersList))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.accountManagersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.accountManagersListFailure("Network Error"));
        }
    }
}
  
  export const getRegions = () => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.get("/user/reports/regions-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.regionsListSuccess(res.data.regionsList))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.regionsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.regionsListFailure("Network Error"));
        }
    }
  }

  export const assignSegment = (userId, accountManagerId, segment_id, year) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/assign-segment", {sales_executives: userId, account_manager: accountManagerId, segment_id: segment_id, year: year}).then((res) => {
                    if (res.data.status === "success") {
                        // toast.success("Assigned segment successful!")
                        toast.success("Successfully Updated!")
                        dispatch(targetsActions.assignSegmentSuccess(res.data.msg))
                        dispatch(getAssignedSegementDetails(userId, accountManagerId, year))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res?.data?.msg)
                        dispatch(targetsActions.assignSegmentFailure(res?.data?.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.assignSegmentFailure("Network Error"));
        }
    }
  }

  export const getAssignedSegementDetails =  (userId, accountManagerId, year) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/assign-segment-detail", {userId:userId, accountManagerId:accountManagerId, year: year}).then((res) => {
                    
                    if (res.data.status === "success") {
                        dispatch(targetsActions.segmentDetailsSuccess(res.data.segmentDetails))
                    }

                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.segmentDetailsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.segmentDetailsFailure("Network Error"));
        }
    }
  };

  export const selectAllSegments = (regionsList, userId, accountManagerId) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/assign-segment", {sales_executives: userId, account_manager: accountManagerId, segment_id: regionsList}).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.assignSegmentSuccess(res.data.msg))
                        dispatch(getAssignedSegementDetails(userId, accountManagerId))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.assignSegmentFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.assignSegmentFailure("Network Error"));
        }
    }
  }


export const getTechieUsersList = () => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.get("/user/techies-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.techieUsersListSuccess(res.data.techiesList))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.techieUsersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.techieUsersListFailure("Network Error"));
        }
    }
}

export const getProductsList = () => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.get("/products/categories").then((res) => {
                
                    if (res.data.status === "success") {
                        dispatch(targetsActions.productCategoriesListSuccess(res.data.ProductsCategoriesList))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.productCategoriesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.productCategoriesListFailure("Network Error"));
        }
    }   
}

export const assignProductsToTechie = (userId, manager, data, year, region, checked) => {
    
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/assign-product-techie", {user: userId, manager: manager, data: data, year: year, region: region, checked: checked}).then((res) => {
                    if (res.data.status === "success") {
                        // toast.success("Assigned segment successful!")
                        toast.success(res.data.msg)
                        // dispatch(targetsActions.assignSegmentSuccess(res.data.msg))
                        dispatch(getAssignedProductTechieDetail(userId, manager, year, region))
                    }
              
                    if (res.data.status === "failure") {
                        toast.error(res?.data?.msg)
                        // dispatch(targetsActions.assignSegmentFailure(res?.data?.msg))
                    }
                })
        }
        catch (err) {
            
            toast.error("Network Error")
            // dispatch(targetsActions.assignSegmentFailure("Network Error"));
        }
    }
}
// 

export const getAssignedProductTechieDetail =  (user, manager, year, region=null) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/assign-product-techie-detail", {user:user, manager:manager, year: year, region:region}).then((res) => {
                    
                    if (res.data.status === "success") {
                        dispatch(targetsActions.productTechieDetailsSuccess(res.data.productTechieDetails))
                    }

                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.productTechieDetailsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.productTechieDetailsFailure("Network Error"));
        }
    }
  };

  export const getAllSelectedProducts = (user, manager, year, region) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/products/list", {user:user, manager:manager, year: year, region:region}).then((res) => {
                    
                    if (res.data.status === "success") {
                        dispatch(targetsActions.allProductListByTechieDetailsSuccess(res.data.categoriesList))
                    }

                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.allProductListByTechieDetailsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.allProductListByTechieDetailsFailure("Network Error"));
        }
    }
  }

  export const updateProductTargetsValues = (input) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            await Api.post("/common/targets/create-product-region-target", input).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Targets updated successfully!")
                        dispatch(targetsActions.updateProductTargetsSuccess(res.data.msg))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.updateProductTargetsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.updateProductTargetsFailure("Network Error"));
        }
    }
  }

  export const getProductTargetDetails = (user, manager, year, region=null, category=null) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        try {
            if(!year){
                year = new Date().getFullYear()
            }
            await Api.post("/common/targets/product-target-detail", {year:year.toString(), manager:manager, techie: user, region:region, category:category}).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.productTargetDetailsSuccess(res.data.productTargetDetails))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.productTargetDetailsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.productTargetDetailsFailure("Network Error"));
        }
    }
  }

  export const getAllUsersByRegion = (manager, region, year) => {
    return async (dispatch) => {
        dispatch(targetsActions.loaderStart())
        dispatch(targetsActions.usersByRegionSuccess([]))
        try {
            if(!year){
                year = new Date().getFullYear()
            }
            await Api.post("/common/targets/product-target-users-list", {year:year.toString(), manager:manager, region:region}).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(targetsActions.usersByRegionSuccess(res.data.techiesList))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(targetsActions.usersByRegionFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(targetsActions.usersByRegionFailure("Network Error"));
        }
    }
  }
  export default targetsSlice.reducer;