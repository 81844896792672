import React, { useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from 'react-toastify';

const formatWeekSelectLabel = (date, invalidLabel) => {
  return `${moment(date).format("MMM Do YYYY")}`
};
const AddLicenseModal = (props) => {
  const { user } = useSelector((state) => state.auth);
  var today = new Date();
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
const [linceseDates,setLinceseDates] =useState({
  start_date:moment(new Date()).format('YYYY-MM-DD'),
  end_date:moment(new Date()).format('YYYY-MM-DD')
})
console.log("dates are here", linceseDates.start_date)
  const {
    register: rejectRegister,
    handleSubmit: poHandleSubmit,
    setError: rejectSetError,
    formState: { errors: licenceErrors },
  } = useForm();

  const poSubmit = async (data) => {
    
    if(!linceseDates?.start_date && !linceseDates?.end_date){
      toast.error("Start and End Dates are required!")
      return;
    }
    
    const startDate = moment(linceseDates.start_date);
    const endDate = moment(linceseDates.end_date);
    const differenceInDays = endDate.diff(startDate, 'days');

    if(differenceInDays<0){
      toast.error("License end date must be greater than the start date")
      return;
    }

    setDisable(true);
    var updatedData = {
      license_start_date: linceseDates.start_date,
      license_end_date: linceseDates.end_date,
      opportunity_id:props.opportunity_id
    };
   
    try {
      const res = await axios.post("/opportunities/amc/renewel", updatedData);
      setTimeout(() => {
        setOpen(true);
        setsnackBarMessage("License dates updated!");
        props.onHide();
      }, 2000);
      props?.refreshPageData()
      setsnackBarMessage("License dates updated!");
      setRejectShow(false);
      handleClick();
      props?.refreshRejectModal();
      
      setDisable(true);
    } catch (err) {
      setDisable(false);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => setRejectShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Add License"}
          </Modal.Title>
          <a
            onClick={props.onHide}
            className="btn btn-sm btn-white btn-danger bottomBarButtons"
          >
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={poHandleSubmit(poSubmit)}>
            <div className="row headerBand">
              <label>Add License Dates</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className="form-control"
                        label="License Start Date*"
                        inputVariant="outlined"
                        value={linceseDates.start_date}
                        onChange={(date) => setLinceseDates({...linceseDates, start_date: moment(date).format('YYYY-MM-DD') })}
                        showTodayButton
                        labelFunc={formatWeekSelectLabel}
                        // inputRef={start_date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
              </div>
              <div class="col-md-6">
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className="form-control"
                            label="License End Date*"
                            inputVariant="outlined"
                            value={linceseDates.end_date}
                            onChange={(date) => setLinceseDates({...linceseDates, end_date: moment(date).format('YYYY-MM-DD') })}
                            showTodayButton
                            labelFunc={formatWeekSelectLabel}
                            // inputRef={start_date}
                            // minDate={today.setDate(today.getDate() - 0) }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                       </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button
                  disabled={disable}
                  type="submit"
                  style={{
                    background: "#212060",
                    color: "#FFFFFF",
                    float: "left",
                  }}
                  className="btn btn-sm  bottomBarButtons upadte_common_btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};
export default AddLicenseModal;
