import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';

const AssignOpportunitiesUserModal = (props) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [isalesUser, setIsalesUser]= useState([]);
  const [disable, setDisable] = useState(false);
  const [assigniSalesData, setAssigniSalesData] = useState({
    sales_list: '',
  })
  
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const iSalesUserList = async () => {
    const res = await axios.get('/user/ac-managers-isales-list');
    setIsalesUser(res.data.list)
  }
  useEffect(() => {
    iSalesUserList();
  }, [])

  const assignSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
      'assignee_id': assigniSalesData.sales_list,
      'opportunities_id': props.leadIds
    }
    try {
      const res = await axios.post("/opportunities/assign/opportunities",updatedData);

      setTimeout(() => {
        setOpen(true);
      
        setsnackBarMessage(" Successfully Assigned");
        props.onHide();
      }, 2000);
     

      setsnackBarMessage("Successfully Assigned");
      props.refreshAssignuser();
      setRejectShow(false);
      handleClick();
      setDisable(true);
    } catch (err) {
      setDisable(false);

    }

  }
  const onInputChange = (e) => {
    setAssigniSalesData({ ...assigniSalesData, [e.target.name]: e.target.value }, []);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>

      <Modal
        show={props.show}
        onHide={() => setRejectShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Assign To"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Select Account Manager</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...rejectRegister("sales_list", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={assigniSalesData.sales_list}
                  select
                  helperText={rejectErrors.sales_list ? rejectErrors.sales_list.message : ''}
                  error={Boolean(rejectErrors.sales_list)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="sales_list" label="Select"
                >
                  {isalesUser.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
     

            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )

}
export default AssignOpportunitiesUserModal;