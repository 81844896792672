import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import { connect, useDispatch } from 'react-redux';
import {updatedhrisdata,HrisActions, getUserRoles} from "../../store/Hris"

 
const HrisUpdateModel = React.memo (({updatedhrisdata,Rowid,openshowModal,closemodal,regionList,  userRoles, getUserRolesData}) => {
  const [Relationtype, Setrelationtype] = useState([]);
  const[gendarList,setGendarList] =useState([]); 
  const [managerslist, setManagerslist] = useState([]);
  const {
    register: RawdataupdateRegister,
    handleSubmit: contactHandleSubmit,
    setError: contactSetError,
    formState: { errors: rawdataupdateErrors },
  } = useForm();
  const {
    payslipregister,
    handleSubmit,
    formState: { errors: errors },
    setError: setActivityError,
    reset: reset,
  } = useForm();
  const [disable, setDisable] = React.useState(false);

  const [Hrisupdate, SetHrisupdate] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    company: "",
    phone: "",
    region:"",
    employee_id: "",
    date_of_join: "",

    manager_id: "",
    previous_company: "",
    no_of_yrs_of_experience: "",
    gender: "",
    DOB: "",
    blood_group: "",
    highest_education_qualification: "",
    current_address: "",
    permanent_address: "",
    personal_contact_number: "",
    personal_email_id: "",
    aadhar_number: "",
    PAN_number: "",
    emergency_contact_number: "",
    person_name: "",
    relation_with_employee: "",
    remarks: "",
    leave_balance: "",
    name_on_pass_book: "",
    salary_account_number: "",
    IFSC_code: "",
    branch_name: "",
    pf_uan_number:"",
    insurance_id:"",
    user_role_id: ""
  });


  const getrelationTypelist = () => {
    Setrelationtype([
      { label: "Spouse", value: "spouse" },
      { label: "Parents", value: "parents" },
      { label: "Brother", value: "brother" },
      { label: "Sister", value: "sister" },
    ]);
  };

  const getgendarlist = () => {
    setGendarList([
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" }
    ]);
  };
  const getUsers = async () => {
    try {
      const res = await axios.get("/user/list");
      if (res.data.status === "success") {
        setManagerslist(res.data.usersList);
      }
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    getrelationTypelist();
    getgendarlist();
    getUsers();
    getUserRolesData()
  }, []);

  const gethrisdataDetails = async (id) => {
    const userID = {
      "user_id": id,
    };

    const res = await axios.post("/user/user-profile-details", userID);
    const Hrisupdate = res.data.user;
    console.log("hris ", Hrisupdate)
    SetHrisupdate({
      user_id: Hrisupdate.user_id,
      first_name: Hrisupdate.first_name,
      last_name: Hrisupdate.last_name,
      email: Hrisupdate.email,
      job_title: Hrisupdate.job_title,
      company: Hrisupdate.company,
      phone: Hrisupdate.phone,
      region:Hrisupdate.region,
      employee_id: Hrisupdate.employee_id,
      date_of_join: moment(Hrisupdate.date_of_join).format("YYYY-MM-DD"),
      manager_id: Hrisupdate?.manager_id?._id,
      previous_company: Hrisupdate.previous_company,
      no_of_yrs_of_experience: Hrisupdate.no_of_yrs_of_experience,
      gender: Hrisupdate.gender,
      DOB: moment(Hrisupdate.DOB).format("YYYY-MM-DD"),
      blood_group: Hrisupdate.blood_group,
      highest_education_qualification:
        Hrisupdate.highest_education_qualification,
      current_address: Hrisupdate.current_address,
      permanent_address: Hrisupdate.permanent_address,
      personal_contact_number: Hrisupdate.personal_contact_number,
      personal_email_id: Hrisupdate.personal_email_id,
      aadhar_number: Hrisupdate.aadhar_number,
      PAN_number: Hrisupdate.PAN_number,
      emergency_contact_number: Hrisupdate.emergency_contact_number,
      person_name: Hrisupdate.person_name,
      relation_with_employee: Hrisupdate.relation_with_employee,
      remarks: Hrisupdate.remarks,
      leave_balance: Hrisupdate.leave_balance,
      name_on_pass_book: Hrisupdate.name_on_pass_book,
      salary_account_number: Hrisupdate.salary_account_number,
      IFSC_code: Hrisupdate.IFSC_code,
      branch_name: Hrisupdate.branch_name,
      pf_uan_number:Hrisupdate.pf_uan_number,
      insurance_id:Hrisupdate.insurance_id,
      user_role_id : Hrisupdate?.user_role_id?._id
    });
  };

  useEffect(() => {
    gethrisdataDetails(Rowid);
  }, [Rowid]);

  const HrisDataSubmitHandle = async () => {
    console.log("Hrisupdate", Hrisupdate)
    const activityUpdateformdata = {
      user_id:Rowid,
      first_name: Hrisupdate.first_name,
      last_name: Hrisupdate.last_name,
      email: Hrisupdate.email,
      job_title: Hrisupdate.job_title,
      company: Hrisupdate.company,
      phone: Hrisupdate.phone,
      region:Hrisupdate.region,
      employee_id: Hrisupdate.employee_id,
      date_of_join: Hrisupdate.date_of_join,
      manager_id: Hrisupdate.manager_id,
      previous_company: Hrisupdate.previous_company,
      no_of_yrs_of_experience: Hrisupdate.no_of_yrs_of_experience,
      gender: Hrisupdate.gender,
      DOB: Hrisupdate.DOB,
      blood_group: Hrisupdate.blood_group,
      highest_education_qualification:
        Hrisupdate.highest_education_qualification,
      current_address: Hrisupdate.current_address,
      permanent_address: Hrisupdate.permanent_address,
      personal_contact_number: Hrisupdate.personal_contact_number,
      personal_email_id: Hrisupdate.personal_email_id,
      aadhar_number: Hrisupdate.aadhar_number,
      PAN_number: Hrisupdate.PAN_number,
      emergency_contact_number: Hrisupdate.emergency_contact_number,
      person_name: Hrisupdate.person_name,
      relation_with_employee: Hrisupdate.relation_with_employee,
      remarks: Hrisupdate.remarks,
      leave_balance: Hrisupdate.leave_balance,
      name_on_pass_book: Hrisupdate.name_on_pass_book,
      salary_account_number: Hrisupdate.salary_account_number,
      IFSC_code: Hrisupdate.IFSC_code,
      branch_name: Hrisupdate.branch_name,
      pf_uan_number:Hrisupdate.pf_uan_number,
      insurance_id:Hrisupdate.insurance_id,
      user_role_id : Hrisupdate.user_role_id
    };
    updatedhrisdata(activityUpdateformdata)

  }

  const onInputchange = (e) => {
    SetHrisupdate({ ...Hrisupdate, [e.target.name]: e.target.value }, []);
  };

  return (
    <>
      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
      >
        <Modal.Header>
          <Modal.Title>HRIS Update</Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">
            Cancel
          </a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={contactHandleSubmit(HrisDataSubmitHandle)}>
            <div className="headerBand">
              <label>Professional Details </label>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="first_name"
                  label="First Name"
                  value={Hrisupdate.first_name}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="last_name"
                  label="Last Name"
                  value={Hrisupdate.last_name}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="employee_id"
                  label="Employee Id"
                  value={Hrisupdate.employee_id}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="email"
                  label="Official Email"
                  type="email"
                  value={Hrisupdate.email}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="job_title"
                  label="Desigination"
                  value={Hrisupdate.job_title}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={Hrisupdate.date_of_join}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  type="date"
                  className="form-control"
                  name="date_of_join"
                  label="Date Of Join"
                ></TextField>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  name="manager_id"
                  label="Reporting Manager"
                  value={Hrisupdate.manager_id}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  type="text"
                  className="form-control"
                >
                  {managerslist.map((option, manager_id) => (
                    <MenuItem
                      key={option.value}
                      selected={manager_id.toString() === option.value.toString()}
                      value={option.value}
                    >
                      {option.label}
                    
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="previous_company"
                  label="Most Recent Previous Company"
                  value={Hrisupdate.previous_company}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={Hrisupdate.no_of_yrs_of_experience}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  type="text"
                  className="form-control"
                  name="no_of_yrs_of_experience"
                  label="No.Of.Years.Of.Experience"
                ></TextField>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="phone"
                  label="Phone"
                  type="number"
                  value={Hrisupdate.phone}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-md-6">
              <TextField
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              name="region"
              label="Region"
              value={Hrisupdate.region}
              onChange={(e) => onInputchange(e)}
              // required
              size="small"
              type="text"
              className="form-control"
            >
              {regionList.map((option, manager_id) => (
                <MenuItem
                  key={option.value}
                  selected={manager_id.toString() === option.value.toString()}
                  value={option.value}
                >
                  {option.label}
                
                </MenuItem>
              ))}
            </TextField>

              </div>

              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  name="user_role_id"
                  label="Joining Team"
                  value={Hrisupdate.user_role_id}
                  onChange={(e) => onInputchange(e)}
                  // required
                  size="small"
                  type="text"
                  className="form-control"
                >
                  {userRoles?.map((option, user_role_id) => (
                    <MenuItem
                      key={option.value}
                      selected={user_role_id?.toString() === option.value.toString()}
                      value={option.value}
                    >
                      {option.label}

                    </MenuItem>
                  ))}
                </TextField>

              </div>

              </div>
  

            <br />

            <div className="headerBand">
              <label>Personal Details </label>
            </div>
            <br />

            <div className="row">
              <div className="col-md-6">
              <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  name="gender"
                  label="Gender"
                  value={Hrisupdate.gender}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  type="text"
                  className="form-control"
                >
                  {gendarList.map((option, gender) => (
                    <MenuItem
                      key={option.value}
                      selected={gender === option.value}
                      value={option.value}
                    >
                      {option.label}
                    
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={Hrisupdate.DOB}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  type="date"
                  className="form-control"
                  name="DOB"
                  label="D.O.B"
                ></TextField>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="blood_group"
                  label="Blood Group"
                  value={Hrisupdate.blood_group}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="highest_education_qualification"
                  label="Highest Education Qualification"
                  value={Hrisupdate.highest_education_qualification}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  name="current_address"
                  label="Current Address"
                  value={Hrisupdate.current_address}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  required
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  name="permanent_address"
                  label="Permanent Address"
                  value={Hrisupdate.permanent_address}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  required
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  name="personal_contact_number"
                  label="Personal Contact Number"
                  type="number"
                  value={Hrisupdate.personal_contact_number}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  name="personal_email_id"
                  label="Personal Email Id"
                  value={Hrisupdate.personal_email_id}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  name="aadhar_number"
                  label="Aadhar Number"
                  type="number"
                  value={Hrisupdate.aadhar_number}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="PAN_number"
                  label="Pan Number"
                  size="small"
                  required
                  value={Hrisupdate.PAN_number}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="emergency_contact_number"
                  label="Emergency Contact Number"
                  size="small"
                  type="number"
                  required
                  value={Hrisupdate.emergency_contact_number}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>

              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="person_name"
                  label="Person Name"
                  size="small"
                  required
                  value={Hrisupdate.person_name}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  name="relation_with_employee"
                  label="Relation With Employee"
                  value={Hrisupdate.relation_with_employee}
                  onChange={(e) => onInputchange(e)}
                  required
                  size="small"
                  type="text"
                  className="form-control"
                >
                  {Relationtype.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="remarks"
                  label="Remarks"
                  size="small"
                  required
                  value={Hrisupdate.remarks}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="leave_balance"
                  label="Leave Balance"
                  size="small"
                  // required
                  value={Hrisupdate.leave_balance}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>
            <br />

            <div className="headerBand">
              <label>Bank Details </label>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  name="name_on_pass_book"
                  label="Name on passBook"
                  value={Hrisupdate.name_on_pass_book}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  className="form-control"
                />
              </div>
              <br />
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="salary_account_number"
                  label="Salary Account Number"
                  type="number"
                  size="small"
                  required
                  value={Hrisupdate.salary_account_number}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  required
                  name="IFSC_code"
                  label="Ifsc Code"
                  value={Hrisupdate.IFSC_code}
                  onChange={(e) => onInputchange(e)}
                  size="small"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  variant="outlined"
                  name="branch_name"
                  label="Branch Name"
                  size="small"
                  required
                  value={Hrisupdate.branch_name}
                  onChange={(e) => onInputchange(e)}
                  className="form-control"
                />
              </div>
            </div>

            <br />
            <br />
              <div className="headerBand">
                <label>Other Details </label>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    // required
                    name="pf_uan_number"
                    label="PF UAN Number"
                    value={Hrisupdate.pf_uan_number}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="insurance_id"
                    label="Insurance Id"
                    size="small"
                    // required
                    value={Hrisupdate.insurance_id}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
            <div className="row">
              <div>
                <button
                  disabled={disable}
                  className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn"
                  id="submit"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );

});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    Rowid : state.Hrislist.rowId, 
    rowMetaData:state.Hrislist.rowMetadata, 
    regionList:state.Hrislist.regionList,
    userRoles: state.Hrislist.userRoles
});

const mapDispatchToProps = (dispatch) => ({
  updatedhrisdata :(data) => dispatch(updatedhrisdata(data)),
  openshowModal:(data) => dispatch(HrisActions.showupdateOpenmodal(data)),
  closemodal:(data) => dispatch(HrisActions.showupdateClosemodal(data)),
  getUserRolesData: (data) => dispatch(getUserRoles())
 
});

export default connect(mapStateToProps,mapDispatchToProps) (HrisUpdateModel)

