import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import axios from "axios"
import { useEffect, useState, Children } from "react";

export default function CustomizedSteppers(props) {

  const [steps, setSteps] = useState([])
  const [activeSteps, setActiveSteps] = useState([]);

  const isStepFailed = (step) => {
    return step < 1;
  };

  var postData = {
    'opportunities_id': props.opportunitiesId
  }

  const getStagesData = async () => {
    const res = await axios.post("/leads/chart/unique-lead-timeline", postData)
    var active = 0;
    var data = res.data.chartDatas.map((item, i) => {
      if(item.daysDiff >0 && i != 10){
        active = i
      }
      setActiveSteps(active);
    })
    ////console.log('active', active)
    setSteps(res.data.chartDatas); 
  }

  useEffect(() => {
    getStagesData();
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((label, index) => {
          const labelProps= {error : label.daysDiff < 1}
          return (
            <Step active={true} key={label.lead_stage} style={{color: activeSteps >= index ? '#50C878' : '#dc3545'}}>
              <StepLabel style={{color: activeSteps >= index ? '#50C878' : '#dc3545'}} icon={<span>{label.daysDiff +"D"}</span>} {...labelProps}><span style={{color: activeSteps >= index ? '#50C878' : '#dc3545'}}>{label.lead_stage}</span></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}


