import React, { useState, useMemo } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {CategoriesList, config,AccountMangers,UsersListFilter } from './chartConfig'
import {getManagers, getLineChartSalesData} from "../../store/dashboard";
import { connect } from "react-redux";
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineBarChart } from 'react-icons/ai';
import { AiOutlineDatabase } from 'react-icons/ai';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import OpportunitiesTable from "../commonColumns/OpportunitiesTable";
import opportunities from "../../store/opportunities";
import { toast } from 'react-toastify';
import Badge from 'react-bootstrap/Badge'
// import opportunitiesActions
// import { opportunitiesActions } from "../../store/opportunities";
const PlotlyComponent = createPlotlyComponent(Plotly);

const LineChartSales = React.memo(({accountManagersList,LineChartsalesLoader, lineChartSalesStages, getManagersData, getLineSalesChartsDataList}) => {
// console.log("line chart data are", lineChartSalesStages)
    // const [FiltersApplied, setFiltersApplied] = useState(false);
    const [filter, setFilter] = useState({});
    console.log("filter",filter)
    const [clickData, setClickData] = useState(null);
    const [showChart, setShowChart] = useState(true);
    const handle = useFullScreenHandle();
    const [tableHeight, setTableHeight] = useState("310px");
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [filterDisplay, setFilterDisplay] = useState(true);
    const [columnsDisplay, setColumnsDisplay] = useState(true);

    const handleFilterUpdate = (value) => {
        console.log("user is here", value)
        if (Array.isArray(value) && value.length > 0) {
            setFilter({ ...filter, user_id: value });
          
        } else {
            setFilter({ ...filter, user_id: undefined });
            // setFiltersApplied(false);
        }
    };
    
    const reportChange = ((state, handle) => {
        var lineChartSalesStagesChart = document.getElementById("lineChartSalesStagesChartComponent");
        if (lineChartSalesStagesChart) {
          lineChartSalesStagesChart = document.getElementById("lineChartSalesStagesChartComponent").getElementsByClassName("user-select-none")[0]
          lineChartSalesStagesChart.id = "lineChartSalesStagesChart";
        }
        if (state) {
          if (lineChartSalesStagesChart) {
            document.getElementById("lineChartSalesStagesChart").style.height = (window.innerHeight - 60).toString() + 'px';
          }
    
          setTableHeight((window.innerHeight - 160).toString() + 'px');
          // setRowsPerPage(15);
          setColumnsDisplay(false);
          // setFilterDisplay(false);
        } else {
          if (lineChartSalesStagesChart) {
            document.getElementById("lineChartSalesStagesChart").style.height = "450px";
          }
    
          setTableHeight('333px');
          // setRowsPerPage(10);
          setColumnsDisplay(true);
          // setFilterDisplay(true);
        }
    
    
      });

      const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
        modeBarButtonsToAdd: [
          {
            name: 'Download',
            icon: Plotly.Icons.camera, // Download icon
            click: function(gd) {
              // Handle click on custom lock icon
              // alert('Custom Lock Icon Clicked!');
              toast.success("Downloaded Successfully!")
              // Download the chart as PNG
              downloadChartAsPng(gd);
            },
            title: 'Download As Png'
          },
      
        ],
      };
      
      function downloadChartAsPng(gd) {
        const chartContainer = document.getElementById(gd.id);
        const width = 1800;
        const height = 800;
      
        // Create a data URL of the chart as a PNG image
        Plotly.toImage(chartContainer, { format: 'png', width: width, height: height })
          .then(function(url) {
            // Trigger download using the created data URL
            const a = document.createElement('a');
            a.href = url;
            a.download = 'line Chart Sales Stages Chart.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      }

    const chartButtonClicked = async () => {
        setShowChart(true);
      }
 
    const categoriesList = [{value: "Metric", label: "Metric"}, {value: "ODRF", label:"ODRF"}, {value: "DRF", label:"DRF"}, {value: "Meetings", label:"Meetings"}, {value: "Demos", label: "Demos"},{value: "Quotation", label: "Quotation"},{value: "PO", label: "PO"}]


  //   const getLineSalesChartsData = async () => {
      

  // var fil = {
   
  //     user_id :  filter.userlist ? [filter.userlist?.value] : null
  //   }
  //   getLineSalesChartsDataList(fil)
  // }


  useMemo(() => {
    getManagersData()
 
  }, []);

useMemo(() => {
  const updatedFilter = { ...filter };

  if (!updatedFilter.type || Object.keys(updatedFilter.type).length === 0) {
    updatedFilter.type = "PO";
  }
  // getLineSalesChartsData()
 
  var newUpdatedFilterAre = {
    type:updatedFilter?.type,
    user_id:updatedFilter?.userlist ? updatedFilter?.userlist.value : ""
  }
  // console.log("updated filter are", newUpdatedFilterAre)
  getLineSalesChartsDataList(newUpdatedFilterAre);
}, [filter]);



  // useMemo(() => {
  //   setOpportunitiesDataTabel(lineChartSalesStages?.tableDatas)
  // },[lineChartSalesStages])
  const updatedFilter = { ...filter };

  if (!updatedFilter.type || Object.keys(updatedFilter.type).length === 0) {
    updatedFilter.type = "PO";
  }

  const lineChartSaleslayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: `${updatedFilter?.type} Sales Chart`,
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },
  
}


const removeFilterUserlistBadge = (value) => {
  var inputValue = value.target.getAttribute('value');
  console.log("yearbadge2", inputValue);
  var filteuserlist = filter.userlist.value === inputValue ? "" : filter.userlist.value;
  setFilter({ ...filter, userlist: filteuserlist });
}

  return (
    <>
    {LineChartsalesLoader ? 
        <div className="parent-div">
        <div className="loader"></div>
      </div>
     :
     <>
   <FullScreen enabled={true} handle={handle} onChange={reportChange} > 
        <div className="container-fluid py-2 px-2">
            <div className="row" style={{ margin: '0px', padding: '0px' }}>

            {/* <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '6%' }}>
                  <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => setShowChart(false)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
                </div>
                <div className="col-md-2 col-lg-2" style={{ width: '6%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div> */}

                <div className="col-md-3 col-lg-3">
                    <CategoriesList
                        defaultValue={{ label: "PO", value: "PO" }}
                        placeholder={"Categories List"}
                        categoriesList={categoriesList}
                        disableCategoryFilter={false}
                        updateFilter={(value) => setFilter({ ...filter, type: value })}
                    
                    />
                </div>
                <div className="col-md-3 col-lg-3">
                <UsersListFilter values={filter.userlist}  selectSingle ={true} placeholder={"User List"} productFamilyList={accountManagersList} disableCategoryFilter={false}  updateFilter={ (value) => setFilter({ ...filter, userlist: value }) } />
                
                {/*    
                 <AccountMangers
                placeholder={"Users List"}
                accountManagers={accountManagersList}
                disableAccountManagerFilter={filter.disableAccountManagerFilter || false}
                updateFilter={handleFilterUpdate}
                defaultValue={[]}
            />
            */}

        </div>
        <div className="row">
        <div className="col-md-12">
        {
          filter.userlist && 
          <Badge onClick={removeFilterUserlistBadge}
              pill data={filter.userlist?.value} value={filter.userlist?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.userlist?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
        }
        </div>
        </div>

                 {showChart ?

                  <PlotlyComponent
                  id="lineChartSalesStagesChatComponents"
                        data={lineChartSalesStages}
                        layout={lineChartSaleslayout}
                        useResizeHandler
                        className="w-full h-full"
                        config={config}
                       
                    />
                          : null }
                 {/* {showChart ?
                        <PlotlyComponent
                        data={lineChartSalesStages}
                        layout={lineChartSaleslayout}
                        useResizeHandler
                        className="w-full h-full"
                        config={config}
                       
                    />
                    : <React.Fragment>
                          <OpportunitiesTable  oppportunityType="open" tableHeight={tableHeight} />
                    
                    </React.Fragment>} */}
            </div>
        </div>
         </FullScreen> 
        </>
    
    }
    </>
)

});

const mapStateToProps = (state) => ({
    accountManagersList : state.dashboard.accountManagersList,
    lineChartSalesStages : state.dashboard.lineChartSalesStages,
    LineChartsalesLoader:state.dashboard.LineChartsalesLoader
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getManagersData: () => dispatch(getManagers()),
    getLineSalesChartsDataList : (data) => dispatch(getLineChartSalesData(data)),
    // setOpportunitiesDataTabel : (data) => dispatch(opportunitiesActions.opportunitiesListSuccess(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LineChartSales);
