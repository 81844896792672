import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Navbar from '../home/Navbar';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Users from '../../components/commonColumns/Users'
import { useDispatch,useSelector } from "react-redux";

import { getRegions, getAccountManagers, updateTargetsValues, assignSegment, getAssignedSegementDetails, selectAllSegments } from "../../store/targets";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ButtonMaterialUi from '@material-ui/core/Button';

export default function AssignSegments() {

    const dispatch = useDispatch();
    const {regionsList, accountManagersList, segmentDetails, msg, errorPayload,isFetching}  = useSelector(state => state.targets);
    const [data, setData] = useState([]);
    // const [usersList, setUsersList] = useState([]);
    // const [projectModules, setProjectModules] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [userId, setUserId] = useState();
    const [accountManagerId, setAccountManagerId] = useState();
    const [selectAll, setSelectAll] = useState(false);
    const yearRef = useRef(null);
    const [year, setYear] = useState(null);

    useEffect(() => {
        console.log("segmentDetails", segmentDetails)
    }, [segmentDetails])

    // const fetchUsers = async () => {
    //     try {
    //         const res = await axios.get("/user/reports//all-users-list");

    //         setUsersList(res.data.usersList);
    //     } catch (err) {

    //     }
    // }


    // const fetchProjectModules = async () => {
    //     try {
    //         const res = await axios.get("/project-modules/list");

    //         setProjectModules(res.data.projectModulesList);

    //     } catch (err) {

    //     }
    // }

    // const fetchSubModules = async (projectModuleId, userId) => {
    //     try {
    //         const res = await axios.post("/user-modules-permissions/permissions/list", { module_id: projectModuleId, user_id: userId });

    //         setPermissions(res.data.permissonList);
    //     } catch (err) {

    //     }
    // }


    const updatePermission = async (segment_id) => {

        // var status = "";
        // if (permission_status) {
        //     status = "delete";
        // } else {
        //     status = "update";
        // }

        if(!userId){
            return alert("Please select sales representatives");
        }
        if(!accountManagerId){
            return alert("Please select accounts manager");
        }
        var yearValue = year ? year : new Date().getFullYear()

        try {

            dispatch(assignSegment(userId, accountManagerId, segment_id, yearValue));

        } catch (err) {

        }
    }


    useEffect(() => {
        dispatch(getAccountManagers());
        dispatch(getRegions());
    }, []);

    // useEffect(() => {
    //     if(msg){
    //         alert(msg)
    //     }else if(errorPayload){
    //         alert(errorPayload)
    //     }
        
    // }, [msg, errorPayload])

    // useEffect(() => {
    //     if(accountManager){
    //         dispatch(getRegionTargetDetails(year, accountManager));
    //     }
    //   }, [year, accountManager])

    const onInputChange = (e) => {
        console.log("yearRef", yearRef.value);
        console.log("year ", year)
        var yearValue = year ? year : new Date().getFullYear()
        if (e.target.name === "user_id") {
            dispatch(getAssignedSegementDetails(e.target.value, accountManagerId, yearValue));
            setUserId(e.target.value);
            
        }
        if (e.target.name === "account_manager_id") {
            dispatch(getAssignedSegementDetails(userId, e.target.value, yearValue));
            setAccountManagerId(e.target.value);
        }
        
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        // setOpen(false);
      };

    // Function to handle "Select All" checkbox change
    const handleSelectAll = (e) => {

        if(!userId){
            return alert("Please select sales representatives");
        }
        if(!accountManagerId){
            return alert("Please select accounts manager");
        }

        setSelectAll(e.target.checked);
        var selectedList = regionsList.map(item => item.value);
        console.log("selectedList", selectedList)
        dispatch(selectAllSegments(selectedList, userId, accountManagerId))
        // if (e.target.checked) {
        // // Select all regions
        // setSelectedRegions(regionsList.map(region => region.value));
        // } else {
        // // Deselect all regions
        // setSelectedRegions([]);
        // }
    };
    
    const changeYear = (e) => {
        setYear(e.target.value)
        dispatch(getAssignedSegementDetails(userId, accountManagerId, e.target.value));
      }


    return (
        <>
            <Navbar value="assignSegments" className="activity_list_navbar" />
            
            <div class="container-fluid">
                <Tab.Container id="left-tabs-example" defaultActiveKey="assignSegments">
                    <Row>
                        <Col sm={10}>
                            <br />
                            <Tab.Content>
                                <Tab.Pane eventKey="assignSegments">

                                    <React.Fragment>
                                        <div className='row'>

                                        <div className='col-md-3'>
                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    name="account_manager_id"
                                                    label="Account Manager"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        accountManagersList.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>
                                            
                                            <div className='col-md-3'>

                                                <TextField variant="outlined"
                                                    required
                                                    select
                                                    label="Users"
                                                    name="user_id"
                                                    type="text" className="form-control" onChange={e => onInputChange(e)}  >
                                                    {
                                                        accountManagersList.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>

                                                                {option.label}
                                                            </MenuItem>
                                                        ))
                                                    }

                                                </TextField>
                                            </div>

                                        <div className='col-md-3'>
                                            <TextField variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                defaultValue={new Date().getFullYear()}
                                                select
                                                ref={yearRef}
                                                onChange={changeYear}
                                                required
                                                className="form-control" name="year" label="Select Year"
                                            >
                                                {["2023", "2024", "2025"].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>

                                            
                                        </div>

                                        <br />
                                        <input
                                            type="checkbox"
                                            label="Select All"
                                            onChange={handleSelectAll}
                                            checked={selectAll}
                                        />&nbsp;&nbsp;Select All
                                        <br /><br />
                                        <div className='row'>
                                        
                                            {
                                                regionsList.map(region => (

                                                    <>
                                                            <div className='col-md-1'>
                                                                <input type="checkbox"
                                                                    checked={segmentDetails?.includes(region.value) ? true : false}
                                                                    label={region.label}
                                                                    onChange={() => updatePermission(region.value)}
                                                                />

                                                            </div>
                                                            <div className='col-md-3'>
                                                                <span>{region.label}</span>
                                                            </div>
                                                    </>

                                                )
                                                )
                                            }
                                        </div>


                                        

                                    </React.Fragment>
                                </Tab.Pane>
                                <Tab.Pane eventKey="users">
                                    <React.Fragment>
                                        <Users />
                                    </React.Fragment>
                                </Tab.Pane>

                                <Tab.Pane eventKey="">
                                    <React.Fragment>

                                    </React.Fragment>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isFetching}
                autoHideDuration={2000}
                onClose={handleClose}
                message={msg}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    )

}