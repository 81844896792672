import React, { useEffect, useState } from 'react'
import Navbar from '../home/Navbar';
import axios from 'axios';
import LeadsAssignedTable from '../../components/commonColumns/LeadAssignedTable';
const AssignedList = () => {
    const[assignedLeadData, setAssignedLeadData] = useState([]);
//console.log("lead list data", assignedLeadData)
    const feachData = async() => {
        try {
            const res = await axios.get('/leads/assigned/leads/list')
            if(res.data.status==="success"){
                setAssignedLeadData(res.data.leadslist)
            }
        } catch (error) {
            //console.log(error)
        }

    }
    useEffect(() => {
     feachData()
    },[])
  return (
    <>
    <Navbar value="assignedLead" className="activity_list_navbar" />
    <LeadsAssignedTable data = {assignedLeadData} />
    </>
  )
}
export default AssignedList;