import React, { useState, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const AgGrid1 = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowData, setRowData] = useState([]);

  const fetchData = useCallback(() => {
    
    if (gridApi) {
      const startRow = currentPage * pageSize;
      const endRow = (currentPage + 1) * pageSize;
      const datasource = {
        getRows: ({ startRow, endRow, sortModel, filterModel, successCallback }) => {
          // Simulate fetching data from API
          const allData = [
            { id: 1, name: 'John', age: 30 },
            { id: 2, name: 'Jane', age: 25 },
            { id: 3, name: 'Doe', age: 35 },
            // Add more data as needed
          ];
  
          // Apply sorting if necessary
          const sortedData = sortData(allData, sortModel);
  
          // Apply filtering if necessary
          const filteredData = filterData(sortedData, filterModel);
  
          // Apply pagination
          const slicedData = filteredData.slice(startRow, endRow);
  
          successCallback(slicedData, filteredData.length);
        }
      };
  
      datasource.getRows({
        startRow,
        endRow: endRow - 1,
        sortModel: [], // Access sort model directly from grid api
        filterModel: gridApi.getFilterModel(),
        successCallback: (rowData, totalRowCount) => {
          setRowData(rowData);
          setTotalRows(totalRowCount);
        }
      });
    }
  }, [gridApi, currentPage, pageSize]);
  
  useEffect(() => {
    fetchData();
  }, []);

  const sortData = (data, sortModel) => {
    // Implement custom sorting logic here based on sortModel
    // For simplicity, let's assume sorting by 'name' field in ascending order
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  };

  const filterData = (data, filterModel) => {
    // Implement custom filtering logic here based on filterModel
    // For simplicity, let's assume no filtering for now
    return data;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPaginationChanged = useCallback(() => {
    if (gridApi) {
      const currentPage = gridApi.paginationGetCurrentPage();
      setCurrentPage(currentPage);
      fetchData();
    }
  }, [gridApi, currentPage, fetchData]);

  const columnDefs = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Age', field: 'age' }
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onPaginationChanged={onPaginationChanged}
        pagination={true}
        domLayout='autoHeight'
        paginationPageSize={pageSize}
        paginationAutoPageSize={false} // Ensure this is set to false
        suppressPaginationPanel={true} // Hide built-in pagination panel
        loadingOverlayComponent="LoadingOverlay" // Optional: Show loading overlay
        frameworkComponents={{
          LoadingOverlay: () => <div className="ag-overlay-loading-center">Loading...</div>
        }}
      />
    </div>
  );
};

export default AgGrid1;
