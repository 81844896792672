import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../home/Navbar';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MaterialUiButton from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap'
import OpportunitiesTable from "../../components/commonColumns/OpportunitiesTable"

import { connect, useSelector,useDispatch } from 'react-redux';
import {getOpportunitiesList,getTechieOpportunitiesList} from "../../store/opportunities";
// export default function OpportunitiesList() {
  const OpportunitiesList = React.memo (({getOpportunitiesData,getTechieOpportunitiesListHand}) => {
  moment.tz.setDefault("Asia/Kolkata");
  const [data, setData] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const { register: contactRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: contactErrors } } = useForm();
//console.log("opp tabel main data", data)
  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');
  const fetchData = useRef()
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  var [deleteClicked, setDeleteClicked] = useState("notClicked");
  const [progress, setProgress] = useState("Loading...");

  const queryParams = new URLSearchParams(window.location.search);

  const [opportunity, setOpportunity] = useState({
    op_company_name: "",
    op_drf_status: "",
    op_drf_expiry_date: "",
    op_product: "",
    op_principal_rupees: "",
    op_principal_dollars: "",
    op_month: "",
    op_revenue_to_kaizenat: "",
    op_sales_stage: "",
    op_sales_activity: "",
    op_closure: "",
    op_closure_qtr: "",
    op_expiry_date: "",
    op_sales_confidence: "",
    op_action_date: "",
    op_region: "",
    op_business_type: " ",
    op_ansys_account_manager: "",
    op_percentage_split_tokaizenat: "",
    op_po_value: "",
    op_timeline: "",
    op_meeting_summary: "",
    op_account_id: "",
    op_po_date: ""
  });

  var [accountId, setAccountId] = useState("");
  const { op_company_name } = opportunity;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  // fetchData.current = async () => {
  //   var opportunity_id = queryParams.get('opportunity_id')
  //   try {

  //     var res;
  //     if(opportunity_id){
  //       res = await axios.get("/opportunities/view-individual/"+opportunity_id);
  //     }else{
  //       res = await axios.get("/opportunities/" + userData._id);
  //     }

  //     if (res.data.status === "success") {
  //       setProgress("No Records Found.")
  //     } else {
  //       setProgress("No Records Found.")
  //     }
  //     setData(res.data.opportunities);
  //     setOpen(false);

  //   } catch (err) {
  //     setProgress("Error in Retrieving Data.")
  //     setSnackBarMessage("Error in fetching records...");
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 1000);

  //   }

  // };

  // useEffect(() => {
  //   handleClick();
  //   setSnackBarMessage("Fetching Records...!");
  //   // fetchData.current();
  // }, []);


  const co_company_name = useRef();
  const co_contact_name = useRef();
  const co_email = useRef();
  const co_contact_number = useRef();
  const co_designation = useRef();
  const co_department = useRef();

  const addContactsHandle = async (e) => {
    setSnackBarMessage("Uploading Data ...!");
    setOpen(true);
    setTimeout(() => { }, 500);

    const contactsData = {
      user_id: userData._id,
      company_name: op_company_name,
      contact_name: co_contact_name.current.value,
      email: co_email.current.value,
      contact_number: co_contact_number.current.value,
      designation: co_designation.current.value,
      department: co_department.current.value,
      account_id: accountId
    };
    try {
      const res = await axios.post("contacts/create", contactsData);
      opportunity.op_account_id = res.data.savedContacts
      setOpen(true);
      if (res.data.status === "success") {
        setSnackBarMessage("Contact Successfully Added...!");
      } else {
        setSnackBarMessage("Error in adding contact...!");
      }
      setContactShow(false)

    } catch (err) {

      if (err.response.status === 400) {
      }


    }


  }

  useEffect(() => {
    
    if(userData?.user_role_id?.role_name ==="techie"){
      getTechieOpportunitiesListHand();
    }else{
      getOpportunitiesData();
    }

   
  },[])

  return (
    <>
      <Navbar value="opportunity" className="activity_list_navbar" />

      <OpportunitiesTable  />
       {/* data={data} rfreshOpportunitiesTable={() => fetchData.current()} progress={progress} */}

      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>

            <MaterialUiButton color="secondary" size="small" onClick={handleClose}>

            </MaterialUiButton>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>

          </React.Fragment>
        }
      /> */}
      <Modal
        show={contactShow}
        onHide={() => setContactShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >

        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Add New Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={contactHandleSubmit(addContactsHandle)}>
            <div className="row headerBand">
              <label>Company Details</label>
            </div>
            <br />
            <div class="row">

              <div className="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_contact_name", {
                    minLength: {
                      value: 3,
                      message: "Contact Name must be at least 3 characters"
                    }
                  })}
                  inputRef={co_contact_name}
                  required
                  helperText={contactErrors.co_contact_name ? contactErrors.co_contact_name.message : ''}
                  error={Boolean(contactErrors.co_contact_name)}
                  type="text" className="form-control" name="co_contact_name" label="Contact Name" />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_email", {
                    minLength: {
                      value: 3,
                      message: "Email must be at least 3 characters"
                    }
                  })}
                  inputRef={co_email}
                  required
                  helperText={contactErrors.co_email ? contactErrors.co_email.message : ''}
                  error={Boolean(contactErrors.co_email)}
                  label="Email" name="co_email" className="form-control" />
              </div>

              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_contact_number", {
                    minLength: {
                      value: 10,
                      message: "Contact Number must be at least 10 characters"
                    },
                    maxLength: {
                      value: 10,
                      message: "Contact Number cannot exceed 10 digits"
                    }
                  })}
                  inputRef={co_contact_number}
                  required
                  helperText={contactErrors.co_contact_number ? contactErrors.co_contact_number.message : ''}
                  error={Boolean(contactErrors.co_contact_number)}
                  type="number" className="form-control" name="co_contact_number" label="Contact Number" />
              </div>

            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_designation", {
                    minLength: {
                      value: 3,
                      message: "Designation must be at least 3 characters"
                    }
                  })}
                  inputRef={co_designation}
                  required
                  helperText={contactErrors.co_designation ? contactErrors.co_designation.message : ''}
                  error={Boolean(contactErrors.co_designation)}
                  type="text" label="Designation" name="co_designation" className="form-control" />
              </div>
              <div class="col-md-6">
                <TextField variant="outlined"
                  {...contactRegister("co_department", {
                    minLength: {
                      value: 3,
                      message: "Department must be at least 3 characters"
                    }
                  })}
                  inputRef={co_department}
                  required
                  helperText={contactErrors.co_department ? contactErrors.co_department.message : ''}
                  error={Boolean(contactErrors.co_department)}
                  type="text" label="Department" name="co_department" className="form-control" />
              </div>

            </div>
            <br />
            <div className="row">
              <div>
                {(permissions.includes('updateOwnContacts') || permissions.includes("updateAnyContacts")) ? <button className="btn btn-sm btn-primary  bottomBarButtons">Submit</button> : false}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </ >
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getOpportunitiesData : () => dispatch(getOpportunitiesList()),
  getTechieOpportunitiesListHand : () => dispatch(getTechieOpportunitiesList())
});


export default connect(mapStateToProps,mapDispatchToProps) (OpportunitiesList)

