import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../home/Navbar';
import EmailCampaignTable from '../../components/commonColumns/EmailCampaignTable';
import { connect } from 'react-redux';

const EmailCampaign = React.memo (() => {
  return (
    <>
    <Navbar value="emailcampaign" className="activity_list_navbar" />
    <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
        </div>
        <div className="hardware_table">
          <EmailCampaignTable/>
        </div>
      </div>
    </>
  )

});

export default connect() (EmailCampaign)