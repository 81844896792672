import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';

const RejectReason = (props) => {
  
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const { register: rejectRegister, handleSubmit: rejectHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const rejectSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
      'status': "rejected",
      'lead_id': props.rejectLeadId,
      'type': props.rejectTypeLead,
      'reject_reason': data.reject_reason
    }
    try {
      const res = await axios.post("/leads/reject", updatedData);
      setTimeout(() => {
        setOpen(true);
        setsnackBarMessage("Lead Approval Rejected");
        props.onHide();
      }, 2000);

      setsnackBarMessage("Lead Approval Rejected");
      setRejectShow(false);
      handleClick();
      props.refreshRejectModal();
      setDisable(true);
    } catch (err) {
      setDisable(false);

    }

  }
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>

      <Modal
        show={props.show}
        onHide={() => setRejectShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {(userData.user_role_id.role_name === "user") ? "Reject Lead" : "Reject Lead"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={rejectHandleSubmit(rejectSubmit)}>
            <div className="row headerBand">
              <label>Reject Reason</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  required
                  {...rejectRegister("reject_reason", {
                    minLength: {
                      value: 3,
                      message: "Reject Reason must be at least 3 characters"
                    }

                  })}
                  multiline rows={4}
                  helperText={rejectErrors.reject_reason ? rejectErrors.reject_reason.message : ''}
                  error={Boolean(rejectErrors.reject_reason)}
                  type="text" className="form-control"
                  name="reject_reason"
                  label="Reject Reasons" />
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  )

}
export default RejectReason;