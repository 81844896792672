import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";

import { useForm } from "react-hook-form";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector,useDispatch, connect } from 'react-redux';
import {Addindustrydata,IndustrysActions} from "../../store/Industrymetric"


const AddIndustryMetric = React.memo ((props,{openshowModal,closeModal, data}) => {

//const AddIndustryMetric = React.memo (({openshowModal,closeModal,data}) => {


    const dispatch = useDispatch();
    // const [isRequired, setRequired] = useState(props.disableRequired);
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();
    const [companiesList, setCompaniesList] = useState();
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [industriesList, setIndustriesList] = useState([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const [disable, setDisable] = React.useState(false);
    const description = useRef();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState();
    // const html = `<p>  Description...
    // <br /><br />
    // </p>`;
    // const contentBlock = htmlToDraft(data ? data?.description : html);
    // const contentState = ContentState?.createFromBlockArray(contentBlock?.contentBlocks);
    // const editorStateTe = EditorState?.createWithContent(contentState);
    // const [editorState, setEditorState] = useState(editorStateTe)
    const html = `<p>  Description...
    <br /><br />
    </p>`;

let contentState;

if (data && data.description) {
  const contentBlock = htmlToDraft(data.description);
  contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
} else {
  // If data or data.description is undefined, create an empty ContentState
  contentState = ContentState.createFromText('');
}

const editorStateTe = EditorState.createWithContent(contentState);
const [editorState, setEditorState] = useState(editorStateTe);

    const [productsList, setProductsList] = useState([]);
    const mailBody = useRef();

    const [activity, setActivity] = useState({
        metric_name:"",
        product:"",
        industry:""
    })

    const [title, setTitle] = useState(data?.title)

    useEffect(() => {
        getIndustriesList();
        getProductsList();
    }, [])

   

    const onEditorStateChange = (data) => {
        
        setEditorState(data)
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }

    const changeHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            let pdfFile = event.target.files[0];
            let pdfFileName = event.target.files[0].name
            setSelectedFile(pdfFile);
            setSelectedFileName(pdfFileName);
          }
      };

    const addContactsHandle = async (e) => {

        setDisable(true);

        setTimeout(() => { }, 500);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("metric_name", activity.metric_name);
        formData.append("industry", activity.industry);
        formData.append("product", activity.product);
        dispatch(Addindustrydata(formData));

        contactsReset({
                        keepErrors: false,
                         keepDirty: true,
                         keepIsSubmitted: false,
                         keepTouched: false,
                         keepIsValid: false,
                         keepSubmitCount: false,
                     })


        // try {
        //     var res;
        //     if (props.data) {
        //         res = await axios.post("/user/resources/upload-industry-metric", formData);
        //     } else {
        //         res = await axios.post("/user/resources/upload-industry-metric", formData);
        //     }

        //     if (res.data.status === "success") {
        //         setTimeout(() => {
        //             setOpen(true);
        //             setSnackBarMessage("Metric Successfully Added...!");
        //             props.onHide();

        //         }, 2000);

        //         setSnackBarMessage("Metric Successfully Added...!");
        //         setContactsShow(false);

        //         contactsReset({
        //             keepErrors: false,
        //             keepDirty: true,
        //             keepIsSubmitted: false,
        //             keepTouched: false,
        //             keepIsValid: false,
        //             keepSubmitCount: false,
        //         })
        //         setDisable(true);
        //     } else {

        //         setTimeout(() => {
        //             setOpen(true);
        //             setSnackBarMessage(res.data.msg);
        //         }, 2000);

        //         setContactsShow(true);
        //         setDisable(false);
        //     }

        //     if (props.refreshTableData()) {
        //         props.refreshTableData();
        //     }

        //     if (props.returnValue) {
        //         props.returnValue(res.data.savedContacts);
        //     }
        // } catch (err) {
        //     setDisable(false);

        //     alert(err);

        // }

    }

    const getProductsList = async () => {
        const res = await axios.get("/products/search");
        setProductsList(res.data.productsList);
    }

    const getIndustriesList = async () => {
        const res = await axios.get("/industries/search");
        setIndustriesList(res.data.industriesList);
    }


    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const modalClose = (data)=> {
        closeModal(data)
        console.log(("modalClose ", data))
    }

    console.log("modalClose", modalClose)

    return (
        <>
       

        <Modal
        show={() => openshowModal()}
        onHide={() => closeModal()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                       <h6> Add New Metric for Industry</h6>
                    </Modal.Title>
                    <a onClick={() => closeModal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2} onSubmit={contactshandleSubmit(addContactsHandle)}>


                        <div class="row">
                            <div class="col-md-12">
                                <TextField variant="outlined"
                                    

                                    value={activity.metric_name}
                                    onChange={e => onInputChange(e)}
                                    helperText={contactsErrors.metric_name ? contactsErrors.metric_name.message : ''}
                                    error={Boolean(contactsErrors.metric_name)}
                                    label="Metric Name"
                                    type="text" className="form-control" name="metric_name" />
                            </div>
                        </div>
                        <br />
                        <div className="row">

                            <div class="col-md-6">
                                <TextField variant="outlined"

                                    select
                                    required
                                    helperText={contactsErrors.industry ? contactsErrors.industry.message : ''}
                                    error={Boolean(contactsErrors.da_industry)}
                                    value={activity.industry}
                                    type="text" className="form-control" name="industry" onChange={e => onInputChange(e)} label="Industry " >

                                    {
                                        industriesList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className="col-md-6">
                                <TextField variant="outlined"

                                    select
                                    required
                                    value={activity.product}
                                    helperText={contactsErrors.product ? contactsErrors.product.message : ''}
                                    error={Boolean(contactsErrors.product)}

                                    type="text" className="form-control" name="product" label="Product" onChange={e => onInputChange(e)} >

                                    {
                                        productsList.map((option, product) => (
                                            <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }

                                </TextField>
                            </div>

                        </div><br />
                        <div class="row">
                            <div className='col-md-12'>
                            <input type="file" className='form-control' id="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="pdffile" onChange={changeHandler} />   
                                <br/> {selectedFileName?selectedFileName:""}
                            </div>
                        </div>
                        <br />
                        <div className="row col-md-12">
                                <button style={{marginLeft:'10px;'}} disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
         
        </>
    );


    

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  data:state.Commonlist.data,
   
});

const mapDispatchToProps = (dispatch) => ({

  openshowModal:(data) => dispatch(IndustrysActions.MetricopenModal(data)),
  closeModal:(data) => dispatch(IndustrysActions.MetriccloseModal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(AddIndustryMetric)

