import React, { useEffect, useState } from "react";
import Navbar from "../home/Navbar";
import axios from "axios";
import { Button } from 'react-bootstrap';
import CreateWebinarModal from "../../components/pageModals/CreateWebinarModal";
import WebinarTable from "../../components/commonColumns/WebinarTable";
import moment from 'moment';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import { connect, useSelector,useDispatch } from 'react-redux';
import {webinarlist,WebinarActions} from "../../store/Webinar"
import { useMemo } from "react";

const Webinars = React.memo (({getwebinarlistdata,getmanagerlists,getInvolvedPersonslists}) => {

  // console.log("managerlist",getmanagerlists)
  // console.log("involved",getInvolvedPersonslists)
  const dispatch = useDispatch();
  const createshowModal =useSelector((state) => state.Webinarslist.showModal)
    const {user} = useSelector(state => state.auth)
    const permissions = user.permissions;
    moment.tz.setDefault('Asia/Calcutta');
  
    const [activity, setActivity] = useState({
      from_date: moment().startOf('month').format('YYYY-MM-DD'),
      to_date: moment().endOf('month').format('YYYY-MM-DD')
    })

  const { register: targetRegister, handleSubmit: setTargetHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: targetErrors } } = useForm();
  const fechData = async () => {
    const updatedData = {
      from_date: activity.from_date.toString(),
      to_date: activity.to_date.toString()
  }
  getwebinarlistdata(updatedData)
              
  };
  useMemo(() => {
    fechData();
   
  }, []);

  const onInputChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value }, []);
}
  return (
    <>
      <Navbar value="webinars" className="activity_list_navbar" />
      <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
          {permissions.includes("viewTaskManagerPage") ? 
            <>
              <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(WebinarActions.openModal())} >Create Event</Button>
              {createshowModal ? <CreateWebinarModal refreshWebinarData={() => fechData()} show={createshowModal} onHide={() => dispatch(WebinarActions.closeModal())} managerlist={getmanagerlists?.techiesList} involvedpersons={getInvolvedPersonslists?.list} /> : ""}
            </>
            : false}
        </div>
        <br/>
        <form onSubmit={setTargetHandleSubmit(fechData)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={activity.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="To Date" name="to_date" className="form-control" value={activity.to_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
      <div className="demo_table">
        <WebinarTable/>
      </div>
    </div>
    </>
  );
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
getmanagerlists:state.Webinarslist.getmanagerlists,
getInvolvedPersonslists:state.Webinarslist.getInvolvedPersonslists

});

const mapDispatchToProps = (dispatch) => ({
  getwebinarlistdata :(data) => dispatch(webinarlist(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (Webinars)
