import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";

const defaultFooterStyles = {
  root: {
    "&:last-child": {
      padding: "0px 0px 0px 590px"
    }
  }
};

class CustomFooter extends React.Component {

  render() {
    const { classes } = this.props;
    
    return (
      <TableFooter>
        <TableRow>
          <TableCell>
            <React.Fragment>
                <div style={{display:'flex', flexDirection:'row'}}>
                    {this.props.totalPoValue && <h5 style={{marginLeft:'30px'}}>Total Po Value : {"₹" + (this.props.totalPoValue).toLocaleString("en-IN")}</h5>}
                    {this.props.totalToKaizenat && <h5 style={{marginLeft:'30px'}}>Total To Kaizenat : {"₹" + (this.props.totalToKaizenat).toLocaleString("en-IN")}</h5> }
                    {this.props.totalPrincipal && <h5 style={{marginLeft:'30px'}}>Total To Principal : {"₹" + (this.props.totalPrincipal).toLocaleString("en-IN")}</h5> }
                </div>
            </React.Fragment>            
          </TableCell>

          <TableCell>
            <TablePagination
              className={classes.root}
              count={this.props.count}
              page={this.props.page}
              rowsPerPage={this.props.rowsPerPage}
              onChangePage={this.props.onChangePage}
              onChangeRowsPerPage={this.props.onChangeRowsPerPage}
              rowsPerPageOptions={this.props.rowsPerPageOptions}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(
  CustomFooter
);
