import React, {useState, useEffect, useMemo } from 'react'
import Navbar from '../home/Navbar';
import axios from "axios";
import HrisDetailsTable from '../../components/commonColumns/HrisDetailsTable';
import { Button } from 'react-bootstrap';
import HrisaddModal from '../../components/pageModals/HrisaddModal';
import { connect } from 'react-redux';
import {gethris,HrisActions} from "../../store/Hris"
import { useDispatch, useSelector } from 'react-redux';

 const HumanResourceInformationSystem = React.memo(({hrislist,user,gethris,showModal,openshowModal}) => { 
  const dispatch = useDispatch();
  const userData = user.user;
  const fetchData = async () => {
    const userid = {
     user_id:userData?._id
    }
    gethris(userid)
   }
   useMemo(() => {
 
     fetchData()
   },[])
    return (
    <>
    <Navbar value="hris" className="activity_list_navbar"/>
    <div className="main_demo_section d-flex flex-column">
      <div className="request_teche my-3">

    <>
    <Button
      className="btn-sm navbarAddBtn btn_add_common"
      onClick={() => openshowModal()}>
      Add
    </Button>
    {showModal ? (
      <HrisaddModal
      refreshPayslipData={() => fetchData()}
 
      />
    ) : null}
  </>
      </div>
     
      <div className="hardware_table">
      <HrisDetailsTable />
      </div>

    </div>
    </>
  )
 });

 const mapStateToProps = (state) => ({
  hrislist : state.Hrislist.hrislist,
  user : state.auth.user,
  showModal:state.Hrislist.showModal
})
const mapDispatchToProps = (dispatch) => ({
  gethris : (data) => dispatch(gethris(data)),
  openshowModal:(data) => dispatch(HrisActions.openModal(data))
});

export default connect(mapStateToProps,mapDispatchToProps) (HumanResourceInformationSystem)