import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    demo_showModal:false,
    callrecordmodal:false,
    callactionmodal:false,
    viewLeadmodal:false,
    showModal_Viewcontact:false,
    callrecordlists:[],
    createmeetinglist:[],
    dailycallactionlist:[],
    CallRecordId:[],
    RowLeadId:[],
    RowMetaData:[],
    RowId:[],
    AccountId:[],
    Calltype:[],
    updateColumns: [],
    columnsList: []
}

const Callrecordslice = createSlice({
    name: "Callrecordlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          callrecordmodal_openModal: (state, action) => {
            state.callrecordmodal = true; 
          },
          callrecordmodal_closeModal: (state, action) => {
            state.callrecordmodal = false; 
          },
          callactionmodal_openModal: (state, action) => {
            state.callactionmodal = true; 
          },
          callactionmodal_closeModal: (state, action) => {
            state.callactionmodal = false; 
          },
          viewLeadmodal_openModal: (state, action) => {
            state.viewLeadmodal = true; 
          },
          viewLeadmodal_closeModal: (state, action) => {
            state.viewLeadmodal = false; 
          },


          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },
          setCallRecordId(state, action) {
            state.CallRecordId = action.payload;
          },
          setRowLeadId(state, action) {
            state.RowLeadId = action.payload;
          },

          setRowMetaData(state, action) {
            state.RowMetaData = action.payload;
          },
          setRowId(state, action) {
            state.RowId = action.payload;
          },
          setAccountId(state, action) {
            state.AccountId = action.payload;
          },
          setCalltype(state, action) {
            state.Calltype = action.payload;
          },

      // get column table
      callrecordslistSuccess(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.callrecordlists = action.payload;
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      callrecordslistFailure(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.callrecordlists = [];
        toast.error("Error in call record list") 
  
      },
      createmeetinglistSuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.createmeetinglist = action.payload;
        toast.success("Succesfully created") 
        state.callrecordmodal = false;
  
      },
      createmeetinglistFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.createmeetinglist = [];
        toast.error("Error in created") 
        state.callrecordmodal = true;
  
      },
      dailycallactionlistSuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.dailycallactionlist = action.payload;
        // toast.success("Succesfully created") 
        state.callactionmodal = false;
  
      },
      cdailycallactionlistFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.dailycallactionlist =[];
        toast.error("error in call action") 
        state.callactionmodal = true;
  
      },
      updateColumns(state, action){
        state.columnsList = action.payload
      },
      columnsListSuccess(state, action){
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.columnsList = action.payload;
      },
      columnsListFailure(state, action){
        state.meetingLoader = false;
        state.error = true;
        state.errorPayload = action.payload;
        state.columnsList = []
      }
    
    }  
});
export const CallrecordsActions = Callrecordslice.actions;


 ///common 
  
export const getmeetingColumnListHandle = (userid) => {
  return async (dispatch) => {
    dispatch(CallrecordsActions.loaderStart("meetingLoader"));
    try {
   
      await Api.get(`/columns-hide-show/list/meetings/${userid}`).then((res) => {
        if (res.data.status === "success") {
          dispatch(CallrecordsActions.getmeetingColumnsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(CallrecordsActions.getmeetingColumnFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CallrecordsActions.getmeetingColumnFailure("Network Error"));
    }
  };
};


///Add activity

export const getCallrecorddata = (type) => {
    return async (dispatch) => {
      dispatch(CallrecordsActions.loaderStart());
      try {
        await Api.post("/meetings/daily-call-records/list", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(CallrecordsActions.callrecordslistSuccess(res?.data?.list));
          }
          if (res.data.status === "failure") {
            dispatch(CallrecordsActions.callrecordslistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CallrecordsActions.callrecordslistFailure("Network Error"));
      }
    };
  }; 



  export const createmeetingdata = (type) => {
    return async (dispatch) => {
      dispatch(CallrecordsActions.loaderStart());
      try {
        await Api.post("/meetings/daily-call-records/create", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(CallrecordsActions.createmeetinglistSuccess(res.data));
            dispatch(getCallrecorddata())
          }
          if (res.data.status === "failure") {
            dispatch(CallrecordsActions.createmeetinglistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CallrecordsActions.createmeetinglistFailure("Network Error"));
      }
    };
  }; 

 

  export const createcallrecordsdata = (type) => {

    return async (dispatch) => {
      dispatch(CallrecordsActions.loaderStart());
      try {
        await Api.post("/meetings/daily-call-records/create_call_meeting", type[0]).then((res) => {
          if (res.data.status === "success") {
            dispatch(CallrecordsActions.dailycallactionlistSuccess(res.data)); 
            type[1].Calltype === 'Follow-up' ? toast.success("Follow up call scheduled successfully!") : toast.success("Initial  call scheduled successfully!")
          }
          if (res.data.status === "failure") {
            dispatch(CallrecordsActions.cdailycallactionlistFailure(res.data.msg));
            toast.error(res.data.msg) 
          }
        });
      } catch (err) {
        dispatch(CallrecordsActions.cdailycallactionlistFailure("Network Error"));
      }
    };
  }; 

  export const getColumnsList  = () => {
    return async(dispatch) => {
        dispatch(CallrecordsActions.loaderStart("meetingloader"))
  
        try {
          var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/callrecords/"+ userData?.user?._id).then((res) => {
                if(res.data.status === "success") {
                  dispatch(CallrecordsActions.columnsListSuccess(res.data.columnsList?.columns_list));
                  // toast.success(`${activityData.leave_type} Successfully Added...!`)
                    // dispatch(LeaverequestActions.createleaveSuccess(res.data));
                    // dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                 
                    dispatch(CallrecordsActions.columnsListFailure(res.data.msg))
                }
            })
        }
        catch (err) {  
          dispatch(CallrecordsActions.columnsListFailure("Network Error"));
        }
    }
    }


  export default Callrecordslice.reducer;