import React, { useEffect, useMemo, useState } from 'react';
import Navbar from '../home/Navbar';
import MettingTable from "../../components/commonColumns/MettingTable"
import moment from 'moment';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import { connect, useSelector } from 'react-redux';
import {getMeetinglistdata, fetchMeetingsList} from "../../store/Meeting"
 
const MeetingsList = React.memo (({getMeetinglist,user}) => {

    moment.tz.setDefault('Asia/Calcutta');
    const [activity, setActivity] = useState({
        from_date: moment().startOf('month').format('YYYY-MM-DD'),
        to_date: moment().endOf('month').format('YYYY-MM-DD')
    })
    const { register: targetRegister, handleSubmit: setTargetHandleSubmit, setError: trainingSetError, reset: reset, formState: { errors: targetErrors } } = useForm();
    
    const fetchData = async () => {

        const updatedData = {
            user_id : user?.user?._id,
            from_date: activity.from_date.toString(),
            to_date: activity.to_date.toString(),
            page: 1,
            perPage: 15
        }
        getMeetinglist(updatedData)
    }
    useMemo(() => {
        fetchData();
      }, []);
    
    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }
    return (
        <>
            <Navbar value="meetings" className="activity_list_navbar" />
            <br />
            <form onSubmit={setTargetHandleSubmit(fetchData)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="From Date" name="from_date" className="form-control" value={activity.from_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-5">
                                    <TextField variant="outlined" type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="To Date" name="to_date" className="form-control" value={activity.to_date} id="preliminary-review-start"
                                        onChange={e => onInputChange(e)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
          
            <br />
            <MettingTable/>
        </>
    )
});

const mapStateToProps = (state) => ({
    user : state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    getMeetinglist :(data) => dispatch(getMeetinglistdata(data))
    // getMeetinglistdata
});

export default connect(mapStateToProps,mapDispatchToProps) (MeetingsList)