import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { useForm } from "react-hook-form";
import RawDataAddNewContact from "./RawDataAddNewContact";
import { useSelector,useDispatch, connect } from "react-redux";
import {RawdataActions} from "../../store/Rawdatabase"

const RawdataExistingcustomerModal = React.memo (({contactmodal,openmodal,closemodal}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const [ContactsShow, setHardwareShow] = useState(false);
  const[contactpage,Setcontactpage] = useState(false)
const[userList,setUserList]= useState([])
const { register: hardwareAssignRegister, handleSubmit: hardwarehandleSubmit, setError: setError, formState: { errors: hardwareErrors }, reset: contactsReset } = useForm();
//console.log("user list is", userList)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
const getUsers = async() => {
  try {
    const res = await axios.get("/user/list")
    if(res.data.status ==="success"){
      setUserList(res.data.usersList)
    }
  } catch (error) {
    alert(error)
  }
}
useEffect(() => {
  getUsers();
}, []);

const ContactPageHandle = () => {
    // Setcontactpage(true)
 dispatch(RawdataActions.contactpage_open())
  
}

  return (
    <>
    <div className="row">
<div className="mb-3">
      <Modal 
        dialogClassName="webiste_modal"
        aria-labelledby="example-custom-modal-styling-title"
        show={() => openmodal}
        onHide={() => closemodal()}
        backdrop={false}
        disableBackdrop={true}
       className="rawdatacustomermodals"
      >
        <Modal.Header>
          <Modal.Title><h5>Existing Account</h5></Modal.Title>
          <a  onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        
          <Modal.Body>
         
              <div className="row">
                <div className="col-md-9">
                    <h6>Account already exists! You can add this contact in the existing account</h6>
                </div>
              </div>
           <br/>
            <div className="row">
              <div  className="col-md-12">
              <button className="btn btn-sm btn-primary bottomBarButtons upadte_common_btn"   onClick={ContactPageHandle} > Add</button>
              </div>
            </div>
          </Modal.Body>
      </Modal>
      </div>
      </div>
  
      {contactmodal ? <RawDataAddNewContact /> : ""}
    </>
  );


});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    contactmodal : state.Rawdatalist.contactmodal
});

const mapDispatchToProps = (dispatch) => ({

    openshowModal:(data) => dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal(data)),
    closemodal:(data) => dispatch(RawdataActions.showRawDataExistingCustomerModal_closemodal(data)),
});

export default connect(mapStateToProps,mapDispatchToProps) (RawdataExistingcustomerModal)
