import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { config, SelectYear, QuartersFilter, MonthlyFilter, QuartersFilterMultiSelect, MonthlyFilterMultiSelect, UsersMultiSelect } from './chartConfig'
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineDatabase } from 'react-icons/ai';
import { useSelector, connect } from 'react-redux';
import { getYears, fetchIsalesChartData, fetchEventTrackerChart, getManagers } from "../../store/dashboard";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image';
const PlotlyComponent = createPlotlyComponent(Plotly);

const EventTrackerChart = React.memo((props) => {

    const { isalesLoader, yearsList, getYearsData, eventTrackerChartData, geteventTrackerChartData, getManagersList, accountManagersList } = props
    const { user } = useSelector(state => state.auth)
    const userData = user.user;
    const permissions = user.permissions;
    const handle = useFullScreenHandle();
    const [filterMonth, setFilterMonth] = useState();
    const [filterYear, setFilterYear] = useState(moment().year());
    const [filterAccountManager, setFilterAccountManager] = useState([]);
    const [filterQuarter, setFilterQuarter] = useState();

    const [showChart, setShowChart] = useState(true);
    const [columnsDisplay, setColumnsDisplay] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    

    var monthsList = [{ label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }]
    const quartersList = [{ value: 1, label: 'Q1' }, { value: 2, label: "Q2" }, { value: 3, label: 'Q3' }, { value: 4, label: 'Q4' }]

    var defaultYear = { value: moment().year(), label: moment().year() }

    const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY")}`
    };

    const reportChange = ((state, handle) => {
        var isalesChart = document.getElementById("isalesChartComponent");
        if (isalesChart) {
            isalesChart = document.getElementById("isalesChartComponent").getElementsByClassName("user-select-none")[0]
            isalesChart.id = "isalesChart";
        }

        if (state) {
            if (isalesChart) {
                document.getElementById("isalesChart").style.height = (window.innerHeight - 60).toString() + 'px';
            }
            setColumnsDisplay(false);
        } else {
            if (isalesChart) {
                document.getElementById("isalesChart").style.height = "450px";
            }
            setColumnsDisplay(true);
        }
    });

    const containerRef = useRef(null);
    const downloadContent = () => {
        const container = containerRef.current

        toPng(container)
            .then(function (dataUrl) {
                const link = document.createElement('a')
                link.download = 'I-Sales-chart.png';
                link.href = dataUrl;
                link.click();
            })
            .catch(function (error) {
                console.log("error:", error)
            });

    }


    const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
        modeBarButtonsToAdd: [
            {
                name: 'Download',
                icon: Plotly.Icons.camera, // Download icon
                click: function () {
                    toast.success("Downloaded Successfully!")
                    // Download the chart as PNG
                    downloadContent();
                },
                title: 'Download As Png'
            },
        ],
    };

    const chartButtonClicked = async () => {

        setShowChart(true);
        if (!columnsDisplay) {
            handle.enter()
        }
    }

    const getForecastChartData = async () => {
        let data = {
            quarters: filterQuarter,
            months: filterMonth,
            account_manager: filterAccountManager,
            from_date: fromDate,
            to_date: toDate,
            user_id: userData._id,
            year: filterYear
        }
        geteventTrackerChartData(data)
    }

    useMemo(() => {
        getForecastChartData();
    }, [filterMonth, filterYear, filterAccountManager, filterQuarter, fromDate, toDate]);

    useMemo(() => {
        getManagersList()
        getYearsData();
    }, [])

    const layout = {
        paper_bgcolor: 'transparent ',
        font: {
            size: 12,
            color: '#6E8898'
        },
        margin: {
            l: 60,
            r: 60,
            b: 90,
            t: 60,
        },
        title: "Sales Event Tracker Report",
        useResizeHandler: true,
        style: { width: "100%", height: "100%" },
        showlegend: true,
        legend: { "orientation": "h" },
    }

    return (
        <>

            {isalesLoader ?
                <div className="parent-div">
                    <div className="loader"></div>
                </div>
                :
                <>
                    <div ref={containerRef}>
                        <FullScreen enabled={true} handle={handle} onChange={reportChange} >
                            <div className="container-fluid py-3 px-2">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                                        <div className="row row_gutter" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className="box11">
                                                    <div style={{display:"flex", flexDirection:"row"}}>
                                                        <button onClick={chartButtonClicked}
                                                            className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                                                        <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                                                            <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                                                    
                                                </div> */}

                                                <div className="col-md-1 col-lg-2" >
                                                    <div >
                                                        <SelectYear defaultYear={defaultYear} years={yearsList} disableYearFilter={false} updateFilter={value => setFilterYear(value.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 col-lg-2" >
                                                    <div>
                                                        <MonthlyFilterMultiSelect monthsList={monthsList} disableMonthFilter={false} updateFilter={(value) => setFilterMonth(value.map(item => item.value))} isMulti={true} />
                                                    </div>
                                                </div>

                                                <div className="col-md-1 col-lg-2">
                                                    <QuartersFilterMultiSelect quartersList={quartersList} disableQuarterFilter={false} updateFilter={(value) => setFilterQuarter(value.map(item => item.value))} isMulti={true} />
                                                </div>

                                                {permissions?.includes("viewAnyEventReportChart") && <div className="col-md-1 col-lg-2">
                                                    <UsersMultiSelect usersList={accountManagersList} disableUserSelectFilter={false} updateFilter={(value) => setFilterAccountManager(value.map(item => item.value))} isMulti={true} />
                                                </div>}

                                                <div className="col-md-2 col-lg-2">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            style={{ width: '50%' }}
                                                            className="form-control"
                                                            label="From Date"
                                                            inputVariant="outlined"
                                                            value={fromDate}
                                                            onChange={(date) => setFromDate(date)}
                                                            labelFunc={formatWeekSelectLabel}
                                                            showTodayButton
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            clearable
                                                            clearLabel="Remove Date"
                                                        />

                                                    </MuiPickersUtilsProvider>

                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            style={{ width: '50%' }}
                                                            className="form-control"
                                                            label="To Date"
                                                            inputVariant="outlined"
                                                            value={toDate}
                                                            onChange={(date) => setToDate(date)}
                                                            labelFunc={formatWeekSelectLabel}
                                                            showTodayButton
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            clearable
                                                            clearLabel="Remove Date"
                                                        />

                                                    </MuiPickersUtilsProvider>
                                                </div>

                                                <div className="col-md-2 col-lg-2">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                                <PlotlyComponent
                                    data={eventTrackerChartData}
                                    layout={layout}
                                    useResizeHandler
                                    className="w-full h-full"
                                    config={config}
                                    id="isalesChartComponent"
                                />
                            </div>
                        </FullScreen>
                    </div>
                </>
            }
        </>
    )
});

const mapStateToProps = (state) => ({
    isalesChartTableData: state.dashboard.isalesChartTableData,
    yearsList: state.dashboard.yearsList,
    isalesChart: state.dashboard.isalesChart,
    isalesLoader: state.dashboard.isalesLoader,
    eventTrackerChartData: state.dashboard.eventTrackerChartData,
    accountManagersList: state.dashboard.accountManagersList
});

const mapDispatchToProps = (dispatch) => ({
    getYearsData: () => dispatch(getYears()),
    geteventTrackerChartData: (data) => dispatch(fetchEventTrackerChart(data)),
    getManagersList: () => dispatch(getManagers())
});
export default connect(mapStateToProps, mapDispatchToProps)(EventTrackerChart);