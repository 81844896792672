import React, { useState ,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import axios from "axios";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from 'moment';
import PayslipTable from '../commonColumns/PayslipTable';
import {createpayslipdata} from "../../store/Payslip"
import { useDispatch } from 'react-redux'


function PayslipModal(props) {

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const[userlist,setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  
  const { payslipregister, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();
  

    const [payslipactivity, setpayslipActivity] = useState({
   pdffile:"",
     user_id:"",
})

const onInputChange = (e) => {
    setpayslipActivity({ ...payslipactivity, [e.target.name]: e.target.value }, []);
    
}

const [selectedDate, setSelectedDate] = useState( moment(new Date()).format('YYYY-MM-01'));



const handleDateChange = (Month) => {
  var monthandyear = moment(Month).format('YYYY-MM-01')
  setSelectedDate(monthandyear);
};


const changeHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
        let pdfFile = event.target.files[0];
        let pdfFileName = event.target.files[0].name
        setSelectedFile(pdfFile);
        setSelectedFileName(pdfFileName);
      }

 
  };
  const payslipHandle = async () => {
    
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("assigned_user_id", payslipactivity.user_id);
    formData.append("month_year", selectedDate);

    dispatch(createpayslipdata(formData));

   
  }


  const userids = async () => {
    const res = await axios.get("/user/list")
  try {
    if(res.data.status ==="success"){
        setUserList(res.data.usersList)
    }

  } catch (error) {
    alert("userlist notfound")
  }

  }
  useEffect(() => {
   userids();
  }, []);

  return (
    <>
     
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header>
          <Modal.Title>Payslip </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
            <div className='form'>
                <form onSubmit={handleSubmit(payslipHandle)}>
                    <div className='row'>
                        <div className='col-md-6'>
                        <TextField variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                    }}
                                    select
                                  
                                    value={payslipactivity.user_id}
                                    onChange={e => onInputChange(e)}
                                    required
                                    size="small"
                                    type="text" className="form-control" name= "user_id"  label="Employee
                                    "
                                >
                                    {userlist.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>
                        </div>
                        <div className='col-md-6'>
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
      
      <DatePicker
        inputVariant="outlined"
        views={["month", "year"]}
        label="Month and Year"
        value={selectedDate}
        onChange={handleDateChange}
        className="form-control"

      />
         
          </MuiPickersUtilsProvider>
               </div>
                    </div>
                    <br/>
                    <div className='row'>
                    <div className='col-md-7'>
                     
                       <input type="file" className='payslip-file-input' id="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="pdffile" onChange={changeHandler} />   
                                <br/> {selectedFileName?selectedFileName:""}
                            </div>

                    </div>
                    <br/>
                   <div className='row'>
                    <div className='payslipbutton'  >
                     <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                    
                     </div>
                     </div>
                  </form>
            </div>
        </Modal.Body>
              </Modal>
              {<PayslipTable filepdf = {selectedFileName}/>}


         
    </>
  );
}


export default PayslipModal

