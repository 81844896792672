import React,{useState,useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
function HardwareUpdateConfig({formupdateData, onInputchange,submit,previouspage}) {

    const [harddisktype,Setharddisktype] = useState([]);
    const[RAMtype,setRAMtype] = useState([])
    const[SSDtype,setSSDtype] = useState([])
    const[HDDtype,setHDDtype] = useState([])
    const[Hybridtype ,setHybridtype] = useState([])
    const[Graphicscardtype,setGraphicscardtype] = useState([])

    const getRamtype = () => {
      setRAMtype([{ label: "4 GB", value: "4 GB" }, { label: "8 GB", value: "8 GB" }, { label: "12 GB", value: "12 GB" }, { label: "16 GB", value: "16 GB" }, { label: "20 GB", value: "20 GB" },{ label: "32 GB", value: "32 GB" },{ label: "36 GB", value: "36 GB" },{ label: "40 GB", value: "40 GB" },{ label: "64 GB", value: "64 GB" }])
  }



  const getHarddisk = () => {
    Setharddisktype([{ label: "SSD", value: "SSD" }, { label: "HDD", value: "HDD" }, { label: "Hybrid", value: "Hybrid" }])
}
const getSSDtype = () => {
    setSSDtype([{label:"128 GB",value:"128 GB"},{label:"150 GB",value:"150 GB"},{label:"250 GB ",value:"250 GB"},{label:"256 GB ",value:"256 GB"},{label:"512 GB",value:"512 GB"},{label:"1 TB",value:"1 TB"}])
  
  }
  const getHDDtype = () => {
    setHDDtype([{label:"512 GB",value:"512 GB"},{label:"1 TB ",value:"1 TB"},{label:"2 TB",value:"2 TB"}])
  
  }
  const getHybridtype = () => {
    setHybridtype([{label:"512 GB + 128 SSD",value:"512 GB + 128 SSD"},{label:"1 TB + 128 SSD ",value:"1 TB + 128 SSD"},{label:"1 TB + 256 SSD",value:"1 TB + 256 SSD"}])
  
  }
  const getGraphicscardtype = () => {
    setGraphicscardtype([{label:"NA",value:"NA"},{label:"2 GB",value:"2 GB"},{label:"4 GB",value:"4 GB"},{label:"8 GB",value:"8 GB"},{label:"12 GB",value:"12 GB"},{label:"16 GB",value:"16 GB"}])
  }


  
    useEffect(() => {
        getHarddisk();
      getRamtype();
      getSSDtype();
      getHDDtype();
      getHybridtype();
      getGraphicscardtype();
    
     
    }, []);


      const submitupdateconfigformdata =(e) => {
      e.preventDefault();
      submit();
      
    }

  return (
   <>
   <form onSubmit={submitupdateconfigformdata}>
   <div className='row'>
   
<div className='col-md-6'>

<TextField
variant="outlined"
required
name="PhysicalAddress"
label="Physical Address"
size="small"
value={formupdateData.PhysicalAddress}
onChange={e => onInputchange(e)}
 className="form-control"
/>
</div>

<div className='col-md-6'>
    <TextField
    variant="outlined"
    required
    name="Hostname"
    label="Host name"
    size="small"
    value={formupdateData.Hostname}
    onChange={e => onInputchange(e)}
    className="form-control"
    />
</div>
    </div>

    <br/>

    <div className='row'>
    <div className='col-md-6'>
    <TextField variant="outlined" 
            InputLabelProps={{
                shrink: true,
            }}
            select
            required
            value={formupdateData.RAM}
            onChange={e => onInputchange(e)}
            size="small"
            type="text" className="form-control" name="RAM"
            label="RAM"
        >
            {RAMtype.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))
            }
        </TextField>
</div>
<div className='col-md-6'>
<TextField
variant="outlined"
name="Processor"
label="Processor"
required
value={formupdateData.Processor}
onChange={e => onInputchange(e)}
 size="small"
 className="form-control"  
  />
</div>
    </div>
    <br/>
    <div className='row'>
   <div className='col-md-6'>
   <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                           required
                           name="Harddisktype"
                           label="Hard disk Type"
                           value={formupdateData.Harddisktype}
                           onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {harddisktype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                           }
                       </TextField>
                       </div>

                  
                      <div className='col-md-6'>
                      <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                           required
                           name="Harddisk"
                           label="Hard disk"
                            value={formupdateData.Harddisk}
                           onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {formupdateData.Harddisktype ==="SSD" ? SSDtype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                          : formupdateData.Harddisktype ==="HDD"?  HDDtype.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )) :  formupdateData.Harddisktype ==="Hybrid"?  Hybridtype.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                              {option.label}
                          </MenuItem>
                      )) : "!please choose hardisk type..."}
                       </TextField> 

                   
    
                         </div>
                        </div>
                        <br/>
      <div  className='row'>
        <div  className='col-md-6' >
        <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                          //  required
                           name="Graphicscard"
                           label="Graphics card "
                           value={formupdateData.Graphicscard}
                          onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {Graphicscardtype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                           }
                       </TextField>


        </div>

      </div>
    <br/>
    <div className='previos_submit_div'>
    <button  className="previousbutton" onClick={previouspage}>Previous</button>
    <button  className="submitbutton">Submit</button>
    </div>
    </form>
   </>
  );
}

export default HardwareUpdateConfig;
