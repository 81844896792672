import React from 'react';
import Navbar from '../home/Navbar';
import DemoTable from "../../components/commonColumns/DemoTable";
import { connect } from 'react-redux';
import { useMemo } from 'react';
import {getdemolist} from "../../store/Demo"

export const DemoList = React.memo(({getdemolistdata}) => {
 
    const fechData = async () => {
     const type = {
        'type': 'all'
     }
     getdemolistdata(type)
    }
    useMemo(() => {
        fechData()
    },[])
  
    return (
        <>
            <Navbar value="demo" className="activity_list_navbar" />
            <div className="main_demo_section d-flex flex-column">
                <div className="demo_table">
                <DemoTable/>
                </div>
            </div>
        </>
    )
})
const mapStateToProps = (state) => ({
    user : state.auth.user,
  });
  const mapDispatchToProps = (dispatch) => ({
    getdemolistdata :(data) => dispatch(getdemolist(data))
  });
  
  export default connect(mapStateToProps,mapDispatchToProps) (DemoList)