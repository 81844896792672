import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import axios from "axios";
import { AuthContext } from '../../context/AuthContext';
import { Link, Redirect, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getFamilyProductList } from '../../store/Ticketlist';
// Snackbar notification
// import ButtonMaterialUi from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// creatable select
import CreatableSelect from 'react-select/creatable';
import {TicketActions} from "../../store/Ticketlist"
import AddNewClient from "./AddNewClient";

// React hook use form
import { useForm } from "react-hook-form";
import Select from 'react-select';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState } from 'draft-js';
import { useSelector,useDispatch, connect } from 'react-redux';
import {addnewTicketlist} from "../../store/Ticketlist"
import { toast } from 'react-toastify';

const AddNewTicket = React.memo (({newTicketlist,user,addNewClient,showTicketModal,TicketListId,openmodal,closemodal,familyList,Applicationlist,getFamilyProduct}) => {
    const dispatch = useDispatch();

    const [applicationList, setApplicationList] = useState([]);
    // const [familyList, setFamilyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [osList, setOsList] = useState([]);
    const [versionList, setVersionList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [companiesList, setCompaniesList] = useState();
    const [selectedAccount, setSelecteAccount] = useState("");

    const [selectedFile, setSelectedFile] = useState([]);
     const [isSelected, setIsSelected] = useState(false);
    const [isFilePicked, setIsFilePicked] = useState(false);

    // const html = `<p>Clear this text and copy paste your mail body here...😀
    // <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    // </p>`;
    const html = `<p>
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </p>`;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorStateTe = EditorState.createWithContent(contentState);
    const [editorState, setEditorState] = useState(editorStateTe)
    const mailBody = useRef();

    const [subArea, setSubArea] = useState("");
    const [Area, setArea] = useState("");
    const [clientsList, setClientsList] = useState([])

    const [activity, setActivity] = useState({
        account: '',
        // first_name:'',
        // last_name:'',
        family: '',
        product_id: '',
        area: '',
        subarea: '',
        product_version: '',
        platform: '',
        os: '',
        summary: '',
        description: '',
        files: ''
    })
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();
    //     const  getApplicationList = () => {
    //         setApplicationList([{ label: "Q1", value: 'Q1' },{ label: "Q2", value: 'Q2' }])
    //   }

    const getProductFamilyList = async () => {
        const res = await axios.get("/product-family/search");
        if (res.data.status === "success") {
            // setFamilyList(res.data.productFamilyList);
            console.log("res.", res.data.productFamilyList)
            console.log("family", familyList)
        }
    }

    const getClientsList = async() => {
        const res = await axios.post("/client/auth/list", {account_id: selectedAccount});
        if (res.data.status === "success") {
            setClientsList(res.data.clientsList);
            
        }
        // setClientsList
    }

    const getApplicationList = async (value) => {
        const res = await axios.get("/products/list/" + value);
        if (res.data.status === "success") {
            setApplicationList(res.data.productsList);
            
        }
    }

    const getPlatformList = async () => {
        const res = await axios.get("/platform-processor/search");
        if (res.data.status === "success") {
            setProductList(res.data.platformList);
            
        }
    }

    const getOperatingSystemsList = async () => {
        const res = await axios.get("/operating-systems/search");
        if (res.data.status === "success") {
            setOsList(res.data.operatingSystemsList);
            
        }
    }

  
    

    const getAreasList = async (value) => {
        const res = await axios.get("/product-areas/search/" + value);
        if (res.data.status === "success") {
            setAreaList(res.data.productAreasList);
            
        }
    }

    const getSubAreasList = async (value) => {
        const res = await axios.get("/product-areas/search/" + value);
        if (res.data.status === "success") {
            setSubAreaList(res.data.productSubAreasList);
            
        }
    }

    useEffect(() => {
        // getProductFamilyList();
        getPlatformList();
        getOperatingSystemsList();
        getFamilyProduct();
    }, [])

    useEffect(() => {
        if(selectedAccount !== ""){
            getClientsList();
        }
    }, [selectedAccount])

    const addRaiseTicketHandle = async (e) => {

        if(activity?.products?.length <1){
            toast.error("Please select product")
            return;
        }

        setTimeout(() => { }, 500);

        const formData = new FormData();
        formData.append('account_id', selectedAccount);
        formData.append('product_family_id', activity.family);
        formData.append('product_id', activity.product_id);
        formData.append('area_id', Area);
        formData.append('sub_area_id', subArea);
        formData.append('product_version', activity.product_version);
        formData.append('platform_id', activity.platform);
        formData.append('operating_system_id', activity.os);
        formData.append('summary', activity.summary);
        formData.append('description', activity.description);
        formData.append('client_id', activity.client_id);
        formData.append('mail_body', mailBody.current.value)

        if (selectedFile.length > 0) {
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append('files', selectedFile[i]);
            }
        }

        // var selectedprod  = activity?.products?.map((option) => option.value);
        // formData.append('products', selectedprod)
        // if(selectedprod?.length>0){
        //     formData.append('product', selectedprod[0])
        // }

        formData.mail_body = mailBody.current.value

        // console.log("form data response", formData)
        // return;
        newTicketlist(formData)
       
        
        reset({
            keepErrors: false,
            keepDirty: true,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
        })
        
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        
        // getAreasList(e.target.value);
        // getSubAreasList(e.target.value);
        // getProductVersionList(e.target.value)
    }

    const familyChangeInput = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        getApplicationList(e.target.value);
    }

    const handleChangeSubArea = (value) => {
        setSubArea(value.value);
        // setActivity({...activity, [e.target.name] : e.target.value}, []);
    }

    const handleChangeArea = (value) => {
        setArea(value.value);
    }

    const onInputChangeApplication = (e) => {
        console.log("product id is here",e.target.value)
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
        getAreasList(e.target.value);
        getSubAreasList(e.target.value);
        getProductVersionList(e.target.value)
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            

            return {
                ...styles,
                // background-color: transparent;
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };

    const getAccountsList = async (value) => {
        
        setCompaniesList([]);
        const res = await axios.get("/tickets/accounts/search/" + value.target.value);
        setCompaniesList(res.data.accountsList);

    }

    const handleSetCompanyName = (value) => {
        setSelecteAccount(value.value)
        
    }
    const getProductVersionList = async (value) => {
        const res = await axios.get("/product-versions/search/" + value);
        if (res.data.status === "success") {
            setVersionList(res.data.productVersionsList);
            
        }
    }



  {/*   const changeHandler = (event) => {
        setSelectedFile(event.target.files);
        // setSelectedFile({file : event.target.files});
        setIsSelected(true);
    };
*/}
    const changeHandler = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
          const file = files[0];
          console.log(file); // Check the structure of the first file object
          setSelectedFile(file);
        }
      };
    const onEditorStateChange = (data) => {
        
        setEditorState(data)
    }

    return (
        <>
        ticketListId={TicketListId}  onHide={() => closemodal()} 
            {/* Add Contacts Modal */}
                <Modal
                show={() => openmodal()}
                onHide={() => closemodal()}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Create New Ticket
                    </Modal.Title>
                    <a  onClick={() => closemodal()}className="btn btn-sm btn-outline-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                {/* onSubmit={handleSubmit(addActivityHandle)} */}
                <Modal.Body>
                    <div className="from">
                        <form key={1} onSubmit={handleSubmit(addRaiseTicketHandle)} >
                            <div className="row">
                                <div className="col-md-6">
                                    <Select
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={companiesList}
                                        placeholder="Account"
                                        styles={colourStyles}
                                        onChange={handleSetCompanyName}
                                        // onChange = {(value) => setSelecteAccount(value.value)}
                                        onKeyDown={getAccountsList}
                                    // onNewOptionClick={onNewOptionClick}
                                    ></Select>
                                </div>
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("family", {
                                            required: {
                                                value: true,
                                                message: "family is required"
                                            },

                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.family}
                                        select
                                        helperText={errors.family ? errors.family.message : ''}
                                        error={Boolean(errors.family)}
                                        onChange={e => familyChangeInput(e)}
                                        required
                                        type="text" className="form-control" name="family" label="Family"
                                    >
                                        {familyList?.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>

                                </div>
                            </div>
                            <br />

                            <div className="row">

                                <div className="col-md-6">
                                {/* <Select
                                    isMulti
                                    name="products"
                                    label="Product"
                                    placeholder="Select Products"
                                    options={applicationList}
                                    className="basic-multi-select"
                                    onChange={value => onInputChange({target: {name: "products", value:value}})}
                                    value={activity.products}
                                    classNamePrefix="select">

                                </Select> */}
                                    <TextField variant="outlined"
                                        {...register("product_id", {
                                            required: {
                                                value: true,
                                                message: "product id is required"
                                            },
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={activity.product_id}
                                        select
                                        helperText={errors.product_id ? errors.product_id.message : ''}
                                        error={Boolean(errors.product_id)}
                                        onChange={e => onInputChangeApplication(e)}
                                        required
                                        type="text" className="form-control" name="product_id" label="Application"
                                    >
                                        {applicationList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>


                                </div>
                                <div className="col-md-6">
                                    <CreatableSelect
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={areaList}
                                        name="subarea"
                                        placeholder="Search Area Name"
                                        // styles={colourStyles}
                                        onChange={handleChangeArea}
                                    // onNewOptionClick={onNewOptionClick}
                                    >
                                    </CreatableSelect>

                                </div>
                            </div>
                            <br />
                            <div className="row">

                                <div className="col-md-6">

                                    <CreatableSelect
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={subAreaList}
                                        name="subarea"
                                        placeholder="Search Sub Area Name"
                                        // styles={colourStyles}
                                        onChange={handleChangeSubArea}
                                    // onNewOptionClick={onNewOptionClick}
                                    >
                                    </CreatableSelect>

                                </div>
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("product_version", {
                                            // required: { 
                                            //     value: true, 
                                            //     message: "product version is required" 
                                            // },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.product_version}
                                        select
                                        helperText={errors.product_version ? errors.product_version.message : ''}
                                        error={Boolean(errors.product_version)}
                                        onChange={e => onInputChange(e)}
                                        // required
                                        type="text" className="form-control" name="product_version" label="Product Version">
                                        {versionList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                            </div>
                            <br />
                            <div className="row">

                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("platform", {
                                            required: {
                                                value: true,
                                                message: "platform is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.platform}
                                        select
                                        helperText={errors.platform ? errors.platform.message : ''}
                                        error={Boolean(errors.platform)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="platform" label="Platform"
                                    >
                                        {productList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("os", {
                                            required: {
                                                value: true,
                                                message: "OS version is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.os}
                                        select
                                        helperText={errors.os ? errors.os.message : ''}
                                        error={Boolean(errors.os)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="os" label="OS"
                                    >
                                        {osList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>


                                </div>
                            </div>
                            <br />

                            <div className="row">
                            <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("client_id", {
                                            required: {
                                                value: true,
                                                message: "Client is required"
                                            },
                                            // minLength: {
                                            //     value: 2,
                                            //     message: "Your name must be at least 2 characters"
                                            // }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        value={activity.client_id}
                                        select
                                        helperText={errors.client_id ? errors.client_id.message : ''}
                                        error={Boolean(errors.client_id)}
                                        onChange={e => onInputChange(e)}
                                        required
                                        type="text" className="form-control" name="client_id" label="Client">
                                        {clientsList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                            </div>
                            <div className="col-md-2">                                 
                                <Button className="btn btn-primary btn-sm" onClick={() => dispatch(TicketActions.AddNew_openModal())}>Add New User</Button>
                            </div>
                            </div>
                            <br />

                            <div className='row'>
                                <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        {...register("summary", {
                                            //     required: { 
                                            //         value: true, 
                                            //         message: "Designation is required" 
                                            //     },
                                            minLength: {
                                                value: 5,
                                                message: "summary must be at least 5 characters"
                                            }
                                        })}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        onChange={e => onInputChange(e)}
                                        value={activity.summary}
                                        helperText={errors.summary ? errors.summary.message : ''}
                                        error={Boolean(errors.summary)}
                                        // inputRef={designation}
                                        type="text" className="form-control" id="summary" name="summary" label="Title" />

                                </div>
                                {/* <div className="col-md-6">
                                    <TextField multiline rows={2}
                                        variant="outlined"
                                        {...register("description", {
                                            //     required: { 
                                            //         value: true, 
                                            //         message: "Designation is required" 
                                            //     },
                                            minLength: {
                                                value: 10,
                                                message: "description must be at least 10 characters"
                                            }
                                        })}
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        onChange={e => onInputChange(e)}
                                        value={activity.description}
                                        helperText={errors.description ? errors.description.message : ''}
                                        error={Boolean(errors.description)}
                                        // inputRef={designation}
                                        type="text" className="form-control" id="description" name="description" label="Description" />
                                </div> */}

                                <br />
                                <br />
                                <div className="form-group col-md-12 editor" style={{marginTop: '30px'}} >
                                    <label className="font-weight-bold"> Mail Body <span className="required"> * </span> </label>
                                    <div style={{minHeight:'350px',border:'1px grey solid'}}>
                                    
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        // onEditorStateChange={onEditorStateChange}
                                        onEditorStateChange = {onEditorStateChange}
                                        />
                                    </div>
                                    <textarea
                                     style={{display:'none'}}
                                    disabled
                                    ref={mailBody}
                                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                    />
                                    {/* <textarea style={{display:'none'}} disabled ref={(val) => userInfo.description = val} value={draftToHtml(convertToRaw(editorState.getCurrentContent())) } /> */}
                                </div>

                                <div className="col-md-6">
                                    <br />
                                    <div>
                                    <label htmlFor="file" className="custom-file-input">
                                   
                                 
                                    <input
                                      type="file"
                                      id="file"
                                      name="file"
                                      style={{ display: 'none' }}
                                      onChange={changeHandler}
                                    />
                                    {selectedFile && <p>Selected File: {selectedFile?.name}</p>}
                                    </label>
                                    </div>
                                </div>

                                

                                <div className="col-md-6">
                                    <br />
                                    <button className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit">Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>

                            {/* <br />
                        <div className="row">
                            <div >
                                <button className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit">Submit</button>&nbsp;&nbsp;
                            </div>
                        </div> */}
                        </form>
                    </div>
                </Modal.Body>
            </Modal >

            {addNewClient ? <AddNewClient account_id={selectedAccount}  refreshContactslist={() => getClientsList()} show={addNewClient} 
            onHide={() => dispatch(TicketActions.AddNew_closeModal())} /> : ""}
            {/* Snack Bar */}
           
        </>
    );

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    addNewClient : state.Ticketlist.addNewClientModal,
    TicketListId : state.Ticketlist.TicketListId,
    familyList:state.Ticketlist.familyList,
    Applicationlist:state.Ticketlist.ApplicationList
      
});


const mapDispatchToProps = (dispatch) => ({
    newTicketlist:(data) =>dispatch(addnewTicketlist(data)),
    openmodal:(data) =>dispatch(TicketActions.openModal(data)),
    closemodal:(data) =>dispatch(TicketActions.closeModal(data)),
    getFamilyProduct:() =>dispatch(getFamilyProductList()),
    // getApplicationList:() => dispatch(getApplicationList())

});
export default connect(mapStateToProps,mapDispatchToProps) (AddNewTicket)
