import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import {add_tech_activity,CommonsliceActions} from "../../store/Common"

export const AddTechActivityModal = React.memo(({user,addtechactivity,openshowModal,closemodal,activityrow}) => {
let type = "demo"
  const dispatch = useDispatch();
  const [demoData, setDemoData] = useState({
    comment: ''
  })
  var datetime = React.useState(moment().format('MMM Do YY'));
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const assignSubmit = async (data) => {
    var updatedData = {
      '_id': activityrow,
      'type': type,
      'remarks': demoData.comment
      
    }
    addtechactivity(updatedData)
    closemodal();
  
  }
  const onInputChange = (e) => {
    setDemoData({ ...demoData, [e.target.name]: e.target.value }, []);
  };
  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Add Activity
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Add Activity</label>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
              <h5>Activity Date - {datetime}</h5>
              <br />  
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <TextField variant="outlined"
                  multiline rows={4}
                  {...rejectRegister("comment", {
                    required: {
                      value: true,
                      message: "Tech activity is required"
                    },
                    minLength: {
                      value: 5,
                      message: "Tech activity must be at least 5 characters"
                    }

                  })}
                  helperText={rejectErrors.comment ? rejectErrors.comment.message : ''}
                  error={Boolean(rejectErrors.comment)}
                  type="text" className="form-control" value={demoData.comment} name="comment" label="Remarks" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button  type="submit" style={{ background: '#212060', color: '#FFFFFF',  }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Update</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    activityrow:state.Commonlist.addActivityRow,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    addtechactivity :(data) => dispatch(add_tech_activity(data)),
    openshowModal:(data) => dispatch(CommonsliceActions.activity_openModal(data)),
    closemodal:(data) => dispatch(CommonsliceActions.activity_closeModal(data)),
  });
  
  export default connect(mapStateToProps,mapDispatchToProps) (AddTechActivityModal)
