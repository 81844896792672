import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js-dist';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge'; 
import Select from 'react-select';
import {getProductsList,fetchUsersList, getYears} from "../../store/dashboard";
import { connect, useSelector } from "react-redux";
import { useMemo } from 'react';

const FunnelChart = React.memo(({fetchUsersListData, getYearsData, getProductsListData, productsList, months, quartersList, usersList, yearsList,FunnelchartLoader}) => {
    const [chartData, setChartData] = useState([]);
    // const [months, setMonths] = useState([]);
    // const [years, setYears] = useState([]);
    // const [accountManagers, setAccountManagers] = useState([]);
    // const [quarters, setQuarters] = useState([]);
    const [filterProduct, setFilterProduct] = useState([]);
    const [filterYear, setFilterYear] = useState([]);
    const [filterMonth, setFilterMonth] = useState([]);
    const [filterQuarter, setFilterQuarter] = useState([]);
    const [filterAccountManager, setFilterAccountManager] = useState([]);
    // const [productsList, setProductsList] = useState([]);


    // const dispatch = useDispatch();
    // const {  } = useSelector(state => state.dashboard);
  

      
      const getForecastChartData = async () => {
        try {
          const res = await axios.post('/user/reports/funnel-chart', {
            months: filterMonth.map(item => item.value),
            quarters: filterQuarter.map(item => item.value),
            years: filterYear.map(item => item.value),
            account_managers: filterAccountManager.map(item => item.value),
            products: filterProduct.map(item => item.value),
          });
      
          const data = res.data.data;
          const croresData = data.map(item => ({
            name: item.name,
            totalPoValue: item.totalPoValue / 10_000_000
          }));
      
          const sortedData = croresData.sort((a, b) => b.totalPoValue - a.totalPoValue);
          setChartData(sortedData);
        } catch (error) {
          console.error('Error fetching chart data:', error);
        }
      };
      
      const {user} = useSelector(state => state.auth)
      const permissions = user.permissions;
  
    const handleFilterYearChange = (selectedOptions) => {
        setFilterYear(selectedOptions);
      };
      
      const handleFilterMonthChange = (selectedOptions) => {
        setFilterMonth(selectedOptions);
      };
      
      const handleFilterQuarterChange = (selectedOptions) => {
        setFilterQuarter(selectedOptions);
      };
      
      const handleFilterAccountManagerChange = (selectedOptions) => {
        setFilterAccountManager(selectedOptions);
      };
      
      const handleFilterProductChange = (selectedOptions) => {
        setFilterProduct(selectedOptions);
      };
      

  // const getMonths = () => {
  //   setMonths([{ label: 'January', value: 'Jan' }, { label: 'February', value: 'Feb' }, { label: 'March', value: 'Mar' }, { label: 'April', value: 'Apr' }, { label: 'May', value: 'May' }, { label: 'June', value: 'Jun' }, { label: 'July', value: 'Jul' }, { label: 'August', value: 'Aug' }, { label: 'September', value: 'Sep' }, { label: 'October', value: 'Oct' }, { label: 'November', value: 'Nov' }, { label: 'December', value: 'Dec' }])
  // }
  // const getProductsList = async () => {
  //   const res = await axios.get("/products/search");
  //   setProductsList(res.data.productsList);
  // }
  // const getQuarters = () => {
  //   setQuarters([{ label: "Q1", value: 'Q1' }, { label: "Q2", value: 'Q2' }, { label: "Q3", value: 'Q3' }, { label: "Q4", value: 'Q4' }])
  // }

  // const getYears = async () => {
  //   const resdata = await axios.get('/user/reports/years-list');
  //   if (resdata.data.status === "success") {
  //     const availableYears = resdata.data.yearsList;
  //     if (filterYear.length === 0) {
  //       const currentYear = new Date().getFullYear();
  //       setFilterYear([{ label: currentYear.toString(), value: currentYear }]);
  //     }
  //     setYears(availableYears);
  //   } else {
  //   }
  // };
  
  // const getManagers = async () => {
  //   const resdata = await axios.get('user/reports/users-list');
  //   if (resdata.data.status === "success") {

  //     setAccountManagers(resdata.data.usersList);
  //   } else {
  //   }
  // }


  useMemo(() => {
    fetchUsersListData();
    // getMonths();
    getYearsData();
    // getQuarters();
    getProductsListData();
  }, []);

  useMemo(() => {
    getForecastChartData();
  }, [filterProduct, filterMonth, filterYear, filterAccountManager, filterQuarter]);

  const removeFilterBadge = (type, value) => {
    let updatedFilter = [];

    switch (type) {
      case 'year':
        updatedFilter = filterYear.filter((item) => item.value !== value);
        setFilterYear(updatedFilter);
        break;
      case 'month':
        updatedFilter = filterMonth.filter((item) => item.value !== value);
        setFilterMonth(updatedFilter);
        break;
      case 'quarter':
        updatedFilter = filterQuarter.filter((item) => item.value !== value);
        setFilterQuarter(updatedFilter);
        break;
      case 'account_manager':
        updatedFilter = filterAccountManager.filter((item) => item.value !== value);
        setFilterAccountManager(updatedFilter);
        break;
      case 'product':
        updatedFilter = filterProduct.filter((item) => item.value !== value);
        setFilterProduct(updatedFilter);
        break;
      default:
        break;
    }
  }
  useMemo(() => {
    if (chartData.length > 0) {
      const gd = document.getElementById('myDiv');
      const funnelData = [
        {
          type: 'funnel',
          y: chartData.map((item) => item.name),
          x: chartData.map((item) => item.totalPoValue),
          hoverinfo: 'y+text',
          textinfo: 'text',
          textposition: 'inside',
          text: chartData.map(item => `${item.totalPoValue.toFixed(3)} Cr`),
        },
      ];

      const layout = { margin: { l: 150 }, width: 600, height: 500 };

      Plotly.newPlot(gd, funnelData, layout);
    }
  }, [chartData]);

  return (
    <>

    {FunnelchartLoader ? 
       <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
    
    <> 
 
      <div className="container-fluid py-2 px-2">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 mx-auto">
            <div className="row row_gutter">
            <div className="col-md-1 col-lg-1" style={{ width: '20%' }}>
                <Select
                  isMulti
                  value={filterProduct}
                  options={productsList}
                  onChange={handleFilterProductChange}
                  placeholder="Product"
                />
              </div>

              <div className="col-md-1 col-lg-1" style={{ width: '20%' }}>
                <Select
                  isMulti
                  value={filterYear}
                  options={yearsList}
                  onChange={handleFilterYearChange}
                  placeholder="Year"
                />
              </div>
              <div className="col-md-1 col-lg-1" style={{ width: '20%' }}>
                <Select
                  isMulti
                  value={filterMonth}
                  options={months}
                  onChange={handleFilterMonthChange}
                  placeholder="Month"
                />
              </div>
              <div className="col-md-1 col-lg-1" style={{ width: '20%' }}>
                <Select
                  isMulti
                  value={filterQuarter}
                  options={quartersList}
                  onChange={handleFilterQuarterChange}
                  placeholder="Quarter"
                />
              </div>
              {permissions.includes('viewOwnFunnelChart') ?
              <div className="col-md-1 col-lg-1" style={{ width: '20%' }}>
                <Select
                  isMulti
                  value={filterAccountManager}
                  options={usersList}
                  onChange={handleFilterAccountManagerChange}
                  placeholder="User"
                />
              </div> 
              : null }
            </div>
            <div className="row">
            <div className="col-md-12" style={{ display: 'flex', flexWrap: 'wrap' }}>
  
  {filterYear.length > 0 ? (
    filterYear.map((filter) => (
      <Badge
        onClick={() => removeFilterBadge('year', filter.value)}
        pill
        key={filter.value}
        style={{
          cursor: 'pointer',
          background: 'grey',
          color: 'white',
          fontWeight: 'normal',
          border: '1px solid grey',
          marginRight: '10px',
        }}
      >
        {filter.label}
        <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>
          &nbsp;x
        </span>
      </Badge>
    ))
  ) : (
    ''
  )}
                {filterMonth.length > 0 ? (
                  filterMonth.map((filter) => (
                    <Badge
                      onClick={() => removeFilterBadge('month', filter.value)}
                      pill
                      key={filter.value}
                      style={{
                        cursor: 'pointer',
                        background: 'grey',
                        color: 'white',
                        fontWeight: 'normal',
                        border: '1px solid grey',
                        marginRight: '10px',
                      }}
                    >
                      {filter.label}
                      <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>
                        &nbsp;x
                      </span>
                    </Badge>
                  ))
                ) : (
                  ''
                )}

  
  {filterQuarter.length > 0 ? (
    filterQuarter.map((filter) => (
      <Badge
        onClick={() => removeFilterBadge('quarter', filter.value)}
        pill
        key={filter.value}
        style={{
          cursor: 'pointer',
          background: 'grey',
          color: 'white',
          fontWeight: 'normal',
          border: '1px solid grey',
          marginRight: '10px',
        }}
      >
        {filter.label}
        <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>
          &nbsp;x
        </span>
      </Badge>
    ))
  ) : (
    ''
  )}
  
  {filterAccountManager.length > 0 ? (
    filterAccountManager.map((filter) => (
      <Badge
        onClick={() => removeFilterBadge('account_manager', filter.value)}
        pill
        key={filter.value}
        style={{
          cursor: 'pointer',
          background: 'grey',
          color: 'white',
          fontWeight: 'normal',
          border: '1px solid grey',
          marginRight: '10px',
        }}
      >
        {filter.label}
        <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>
          &nbsp;x
        </span>
      </Badge>
    ))
  ) : (
    ''
  )}
  
  {filterProduct.length > 0 ? (
    filterProduct.map((filter) => (
      <Badge
        onClick={() => removeFilterBadge('product', filter.value)}
        pill
        key={filter.value}
        style={{
          cursor: 'pointer',
          background: 'grey',
          color: 'white',
          fontWeight: 'normal',
          border: '1px solid grey',
          marginRight: '10px',
        }}
      >
        {filter.label}
        <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>
          &nbsp;x
        </span>
      </Badge>
    ))
  ) : (
    ''
  )}
  </div>
            </div>
          </div>
        </div>
        <div>
          <div id="myDiv"></div>
        </div>
      </div>
      </>
    }
    </>
  );
});

// export default ;

const mapStateToProps = (state) => ({
  productsList : state.dashboard.productsList,
  months : state.dashboard.months,
  quartersList : state.dashboard.quartersList,
  usersList : state.dashboard.usersList,
  yearsList : state.dashboard.yearsList,
  FunnelchartLoader:state.dashboard.FunnelchartLoader
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersListData: (data) => dispatch(fetchUsersList(data)),
  getYearsData : () => dispatch(getYears()),
  getProductsListData : () => dispatch(getProductsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelChart);
