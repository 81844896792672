
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showupdatemodal : false,
    hrislist:[],
    createhris:[],
    updatehris:[],

    ProfileList:[],
}

const ProfileSlice = createSlice({
    name: "Profilelist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
      

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          profileUpdateSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createhris = action.payload;
            toast.success("Profile Updated Successfully")
            state.showModal = false;

          },
          createhrisFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createhris = [];
            toast.error("Error ! Not Created")
            state.showModal = true;
    
          },
    
        
          profilelistSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.ProfileList = action.payload

        },
        profilelistFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.ProfileList = []
        },
    }  
});
export const ProfileActions = ProfileSlice.actions;


export const getProfileData = (data) => {
    return async (dispatch) => {
        dispatch(ProfileActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get('/user/profile/details/' + userData?.user?._id).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(ProfileActions.profilelistSuccess(res.data.user))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(ProfileActions.profilelistFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(ProfileActions.profilelistFailure("Network Error"));
        }
    }
}

export const updateProfiledata = (updatedData) => {

    return async (dispatch) => {
      dispatch(ProfileActions.loaderStart("meetingloader"));
  
      // Add password check here
      if (updatedData.password !== updatedData.confirm_password) {
        dispatch(ProfileActions.profileUpdateFailure("Passwords didn't match"));
        return; // Exit early if passwords don't match
      }
  
      try {
        await Api.post('/user/profile/update', updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(ProfileActions.profileUpdateSuccess(res.data));
            dispatch(getProfileData());
          }
          if (res.data.status === "failure") {
            dispatch(ProfileActions.profileUpdateFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(ProfileActions.profileUpdateFailure("Network Error"));
      }
    };
  };

 export default ProfileSlice.reducer;