import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showAssignModal: false,
    addNewClientModal:false,
    meetinglistdata: [],
    getticketlists:[],
    ticketlists:[],
    addclientlists:[],
    ticketassignlists:[],
    familyList:[],
    applicationList:[],
    ApplicationList:[{ label: "Q1", value: 'Q1' },{ label: "Q2", value: 'Q2' }]

}

const TicketlistSlice = createSlice({
    name: "Ticketlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          Assign_openModal :  (state, action) => {
            state.showAssignModal = true; 
          },
          Assign_closeModal :(state, action) => {
            state.showAssignModal = false; 
          },

          AddNew_openModal :  (state, action) => {
            state.addNewClientModal = true; 
          },
          AddNew_closeModal :  (state, action) => {
            state.addNewClientModal = false; 
          },
          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },

          setTicketListId(state, action) {
            state.TicketListId = action.payload;
          },

         

          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

           //TICKET  LIST REDUCERS START HERE
    meetingListSuccess(state, action) {
      state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.meetinglistdata = action.payload;
        // toast.success("meeting list data") 
      },
      meetingListFailure(state, action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.meetinglistdata = [];
        toast.error("No data found ")
      },
      meetingcolumndataSuccess (state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.meetingcolumnupdated = action.payload;
        // toast.success("meeting column updated") 
  
      },
      meetingcolumndataFailure (state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.meetingcolumnupdated = [];
        toast.error("Error in column updated") 
  
      },
      getTicketlistsuccess(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.getticketlists = action.payload;
        // toast.success("meeting column updated") 
  
      },
      getTicketlistFailure(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.getticketlists = [];
        toast.error("Error in ticketlist") 
  
      },
      TicketlistSuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.ticketlists = action.payload;
        toast.success("Successfully Added") 
        state.showModal = false;
  
      },
      TicketlistFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.getticketlists = [];
         toast.error("Error in Addedd ticket") 
         state.showModal = true;
  
      },
      AddclientlistSuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addclientlists = action.payload;
        toast.success("Successfully Added") 
        state.addNewClientModal = false;
  
      },
      AddclientlistFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addclientlists = [];
        
        state.addNewClientModal = true;
        
      },
      TicketassignlistSuccess(state,action) {
        state.error = false;
        state.errorPayload = null;
        state.ticketassignlists = action.payload;
        toast.success("Successfully Added") 
        state.showAssignModal = false;
  
      },
      TicketassignlistFailure(state,action) {
     
        state.error = false;
        state.errorPayload = null;
        state.ticketassignlists = [];
        toast.success("Successfully Added") 
        state.showAssignModal = true;
  
      },
  
      familyproductlistSuccess(state, action){
        state.isFetch = false
        state.error = false
        state.errorPayload = false
        state.familyList = action.payload
    },
    familyproductlistFailure(state, action){
        state.isFetch = false
        state.error = true
        state.errorPayload = action.payload
        state.familyList = []
    },
    applicationlistSuccess(state, action){
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.applicationList = action.payload
  },
  applicationlistFailure(state, action){
      state.isFetch = false
      state.error = true
      state.errorPayload = action.payload
      state.applicationList = []
  },
    }  
});
export const TicketActions = TicketlistSlice.actions;
          // TICKET LIST ACTIONS START HERE
  
  export const meetingColumnUpdateddata = (updatedData) => {
  
    return async (dispatch) => {
      dispatch(TicketActions.loaderStart("meetingLoader"));
      try {
        await Api.post("columns-hide-show/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(TicketActions.meetingcolumndataSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(TicketActions.meetingcolumndataFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(TicketActions.meetingcolumndataFailure("Network Error"));
      }
    };
  };


  
export const getTicketListHandle = (userid) => {

  return async (dispatch) => {
    dispatch(TicketActions.loaderStart());
    try {
   
      await Api.get("/tickets/list").then((res) => {
        if (res.data.status === "success") {
          dispatch(TicketActions.getTicketlistsuccess(res?.data?.ticketsList));
        }
        if (res.data.status === "failure") {
          dispatch(TicketActions.getTicketlistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(TicketActions.getTicketlistFailure("Network Error"));
    }
  };
};

 
export const addnewTicketlist = (updatedData) => {

  return async (dispatch) => {
    dispatch(TicketActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/tickets/create", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(TicketActions.TicketlistSuccess(res.data));
          dispatch(getTicketListHandle("somtext"));
        }
        if (res.data.status === "failure") {
          dispatch(TicketActions.TicketlistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(TicketActions.TicketlistFailure("Network Error"));
    }
  };
};

export const addnewclientlist = (updatedData) => {

  return async (dispatch) => {
    dispatch(TicketActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/client/auth/register", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(TicketActions.AddclientlistSuccess(res.data));
        }
        if (res.data.status === "failure") {
          toast.error(res.data.msg) 
          dispatch(TicketActions.AddclientlistFailure(res.data.msg));
        }
      });
    } catch (err) {
      toast.error("Network Error") 
      dispatch(TicketActions.AddclientlistFailure("Network Error"));
    }
  };
};

export const Ticketassignlist = (updatedData) => {

  return async (dispatch) => {
    dispatch(TicketActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/tickets/assign-ticket", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(TicketActions.TicketassignlistSuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(TicketActions.TicketassignlistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(TicketActions.TicketassignlistFailure("Network Error"));
    }
  };
};




export const getFamilyProductList = () => {
  return async (dispatch) => {
      dispatch(TicketActions.loaderStart())
      try {
          // await Api.get("/platform-processor/search").then((res) => {
            await Api.get("/product-family/search").then((res) => {
                  if (res.data.status === "success") {
                      // dispatch(TicketActions.familyproductlistSuccess(res.data.metricsList))
                      dispatch(TicketActions.familyproductlistSuccess(res.data.productFamilyList))
                      
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(TicketActions.familyproductlistFailure(res.data.msg))
                  }
              })
      }catch (err) {
          toast.error("Network Error")
          dispatch(TicketActions.familyproductlistFailure("Network Error"))
      }
  }
};


export const getApplicationList = (value) => {
  return async (dispatch) => {
      dispatch(TicketActions.loaderStart())
      try {
          // await Api.get("/platform-processor/search").then((res) => {
            await Api.get("/products/list/" + value).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(TicketActions.applicationlistSuccess(res.data.productsList))
                      
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(TicketActions.applicationlistFailure(res.data.msg))
                  }
              })
      }catch (err) {
          toast.error("Network Error")
          dispatch(TicketActions.applicationlistFailure("Network Error"))
      }
  }
};


  export default TicketlistSlice.reducer;