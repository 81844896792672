
import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;

const intialEventState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    meetings: [],
    eventReports: [],
    demosnPresentations: [],
    drfList: [],
    techDay: [],
    lunchnLearn: [],
    licenseSeeding: [],
    lunchDinnerMeet: []
}

const EventReportSlice = createSlice({
    name: "EventTracker",
    initialState: intialEventState,
    reducers: {
        loaderStart: (state, action)=>{
            state.isFetching = true 
        },
        loaderStop: (state, action) => {
            state.isFetching = false
        },
        eventReportsListSuccess: (state, action) => {
            console.log("action.payload[action.payload.name]", action.payload[action.payload.name])
            console.log("res ", action.payload)
            state.isFetching = false
            state.error = false
            state.errorPayload= false
            state[action.payload.name]= action.payload[action.payload.name]
        },
        eventReportsListFailure: (state, action) => {
            state.isFetching = false 
            state.error = true 
            state.errorPayload = action.payload
            state.eventReports = []
        },
        eventCreatedSuccess: (state, action) => {
            state.isFetching = false 
            state.error = false
            state.errorPayload = false 
        },
        eventCreatedFailure: (state, action) => {
            state.isFetching = false 
            state.error = true
            state.errorPayload = action.payload 
        }
    }
});

export const EventReportsActions = EventReportSlice.actions;

export const getEventReports = (input) => {
    return async (dispatch) => {
        dispatch(EventReportsActions.loaderStart())
        try {
            await Api.post("/event-reports/list", input).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(EventReportsActions.eventReportsListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(EventReportsActions.eventReportsListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error(err?.message)
            dispatch(EventReportsActions.eventReportsListFailure(err));
        }
    }
  }

  export const createEventReport = (input) => {
    return async (dispatch) => {
        dispatch(EventReportsActions.loaderStart())
        try {
            await Api.post("/event-reports/create", input).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Successfully Added") 
                        dispatch(EventReportsActions.eventCreatedSuccess())
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(EventReportsActions.eventCreatedFailure())
                    }
            })
        }catch (err) {
            toast.error(err?.message)
            dispatch(EventReportsActions.eventCreatedFailure())
        }
    }
  }

export default EventReportSlice.reducer;