import React,{useState,useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const HardwareConfigAssets = ({formData ,Setformdata,onInputchange,submit ,previouspage}) => {
  const [harddisktype,Setharddisktype] = useState([]);
  const[RAMtype,setRAMtype] = useState([])
    
    const[SSDtype,setSSDtype] = useState([])
    const[HDDtype,setHDDtype] = useState([])
    const[Hybridtype ,setHybridtype] = useState([])
    const[Graphicscardtype,setGraphicscardtype] = useState([])
    
     
    const getHarddisk = () => {
      Setharddisktype([{ label: "SSD", value: "SSD" }, { label: "HDD", value: "HDD" }, { label: "Hybrid", value: "Hybrid" }])
  }
  const getRamtype = () => {
    setRAMtype([{ label: "4 GB", value: "4 GB" }, { label: "8 GB", value: "8 GB" }, { label: "12 GB", value: "12 GB" }, { label: "16 GB", value: "16 GB" }, { label: "20 GB", value: "20 GB" },{ label: "32 GB", value: "32 GB" },{ label: "36 GB", value: "36 GB" },{ label: "40 GB", value: "40 GB" },{ label: "64 GB", value: "64 GB" }])
}
const getSSDtype = () => {
  setSSDtype([{label:"128 GB",value:"128 GB"},{label:"150 GB",value:"150 GB"},{label:"250 GB ",value:"250 GB"},{label:"256 GB ",value:"256 GB"},{label:"512 GB",value:"512 GB"},{label:"1 TB",value:"1 TB"}])

}
const getHDDtype = () => {
  setHDDtype([{label:"512 GB",value:"512 GB"},{label:"1 TB ",value:"1 TB"},{label:"2 TB",value:"2 TB"}])

}
const getHybridtype = () => {
  setHybridtype([{label:"512 GB + 128 SSD",value:"512 GB + 128 SSD"},{label:"1 TB + 128 SSD ",value:"1 TB + 128 SSD"},{label:"1 TB + 256 SSD",value:"1 TB + 256 SSD"}])

}
const getGraphicscardtype = () => {
  setGraphicscardtype([{label:"NA",value:"NA"},{label:"2 GB",value:"2 GB"},{label:"4 GB",value:"4 GB"},{label:"8 GB",value:"8 GB"},{label:"12 GB",value:"12 GB"},{label:"16 GB",value:"16 GB"}])
}



  useEffect(() => {
    getHarddisk();
    getRamtype();
      getSSDtype();
      getHDDtype();
      getHybridtype();
      getGraphicscardtype();
   
  }, []);

    const submitformdata =(e) => {
      e.preventDefault();
      submit();
       
    } 

  return (
    <>
    <form onSubmit={submitformdata}>
       <div className='row'>
    <div className='col-md-6'>
<TextField
variant="outlined"

name="physicalAddress"
label="Physical Address"
size="small"
value={formData.physicalAddress}
onChange={e => onInputchange(e)}
  className="form-control"
/>
</div>

<div className='col-md-6'>
<TextField
variant="outlined"

name="hostname"
label="Host name "
size="small"
value={formData.hostname}
onChange={e => onInputchange(e)}
  className="form-control"
/>

</div>
 </div>
    <br/>
    <div className='row'>
    <div className='col-md-6'>
    <TextField variant="outlined"
                           
                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            
                            value={formData.ram}
                            onChange={e => onInputchange(e)}
                            size="small"
                            type="text" className="form-control" name="ram"
                            label="RAM"
                        >
                            {RAMtype.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                            }
                        </TextField>
                       </div>

                      <div className='col-md-6'>
                      <TextField
                     variant="outlined"
                     
                      name="processor"
                      label="Processor"
                      value={formData.processor}
                      onChange={e => onInputchange(e)}
                       size="small"
                      className="form-control"
                       />

                       </div>
                      </div>
                      <br/>
                     <div className='row'>
                     <div className='col-md-6'>
                     <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                           
                           name="harddisktype"
                           label="Hard disk Type"
                           value={formData.harddisktype}
                           onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {harddisktype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                           }
                       </TextField>
                       </div>

                  
                      <div className='col-md-6'>
                      <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                           
                           name="harddisk"
                           label="Hard disk"
                            value={formData.harddisk}
                           onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {formData.harddisktype ==="SSD" ? SSDtype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                          : formData.harddisktype ==="HDD"?  HDDtype.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )) :  formData.harddisktype ==="Hybrid"?  Hybridtype.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                              {option.label}
                          </MenuItem>
                      )) : "!please choose hardisk type..."}
                       </TextField> 

                      

                      </div>
                      </div>
                      <br/>
                      <div className='row'>
                        <div className='col-md-6'>

                        
                        <TextField variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           select
                          //  
                           name="graphicscard"
                           label="Graphics card "
                           value={formData.graphicscard}
                          onChange={e => onInputchange(e)}
                           size="small"
                           type="text" className="form-control" 
                           
                           >
                           {Graphicscardtype.map((option) => (
                               <MenuItem key={option.value} value={option.value}>
                                   {option.label}
                               </MenuItem>
                           ))
                           }
                       </TextField>
                       </div>
                         </div>

                       <br/>
                      <div>
                      <div className='previos_submit_div'>
                    <button  className="previousbutton" onClick={previouspage}>Previous</button>
                    <button  className="submitbutton">Submit</button>
                    </div>
                 
                    </div>
                   </form>

      
                    </>
  );
}

export default HardwareConfigAssets;