import React, { useEffect, useState } from 'react';
import Navbar from '../home/Navbar';
import axios from 'axios';
import AccountsTable from "../../components/commonColumns/AccountsTable"
import { connect, useSelector } from 'react-redux';
import {getorganizationlist} from "../../store/Organization"
import { useMemo } from 'react';

 const AccountsList = React.memo (({user,}) => {

  // console.log("organization--data-are-inside",organizationlistdata)
  const userData = user.user;


 return (
    <>
      <Navbar value="accounts" className="activity_list_navbar" />
      <AccountsTable  />
    </>
  );

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  organizationlistdata:state.Organizationlist.organizationlistdata,
  accountcolumn:state.Organizationlist.accountcolumn
});

const mapDispatchToProps = (dispatch) => ({
  // getorganizationlistdata :(data) => dispatch(getorganizationlist(data))

});


export default connect(mapStateToProps,mapDispatchToProps) (AccountsList)