import React, { useState, useEffect, useMemo} from "react";
import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { config, SelectYear, QuartersFilter, MonthlyFilter} from './chartConfig'
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { useSelector, useDispatch, connect } from 'react-redux';
import {  getYears, getActivitesChart } from "../../store/dashboard";
import { toast } from 'react-toastify';

const PlotlyComponent = createPlotlyComponent(Plotly);
const Activitychart = React.memo(({getYearsData, yearsList, salesActivitiesChart, getActivitesChartData,ActivitychartLoader}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const handle = useFullScreenHandle();
  // const [data, setData] = useState([]);
  // const [years, setYears] = useState([]);
  // const [salesStages, setSalesStages] = useState([]);
  // const [accountManagers, setAccountManagers] = useState([]);
  // const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const [filterMonth, setFilterMonth] = useState();
  const [filterYear, setFilterYear] = useState(null);
  // const [filterAccountManager, setFilterAccountManager] = useState([]);
  // const [filterSalesStage, setFilterSalesStage] = useState([]);
  const [filterQuarter, setFilterQuarter] = useState();
  // const [tableData, setTableData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  // const [tableHeight, setTableHeight] = useState("333px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  const [fromDate, setFromDate] = useState( moment().startOf('month').format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().endOf('month').format("YYYY-MM-DD"));

  
  const dispatch = useDispatch();
  // const {    } = useSelector(state => state.dashboard);


  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  const reportChange = ((state, handle) => {
    var forecastSalesChart = document.getElementById("activityChartComponent");
    if (forecastSalesChart) {
      forecastSalesChart = document.getElementById("activityChartComponent").getElementsByClassName("user-select-none")[0]
      forecastSalesChart.id = "activityChart";
    }
    if (state) {
      if (forecastSalesChart) {
        document.getElementById("activityChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }
      // setTableHeight((window.innerHeight - 170).toString() + 'px');
      // setRowsPerPage(15);
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (forecastSalesChart) {
        document.getElementById("activityChart").style.height = "450px";
      }
      // setTableHeight('333px');
      // setRowsPerPage(10);
      setColumnsDisplay(true);
      // setFilterDisplay(true);
    }

  });


  const chartButtonClicked = async () => {

    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }

  const getForecastChartData = async () => {
    // var account_manager = filterAccountManager.map(item => {
    //   return item.value;
    // });

    // var sales_stages = filterSalesStage.map(item => {
    //   return item.value;
    // });
    var data = {
      // account_manager: account_manager,
      // sales_stages: sales_stages,
      from_date: fromDate,
      to_date: toDate,
      user_id: userData._id
    }

    if(filterYear > 0){
      data.year = filterYear
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }
    if(filterQuarter){
      data.quarters = filterQuarter
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }
    if(filterMonth){
      data.months = filterMonth
      delete data.from_date
      delete data.to_date
      setFromDate(moment().format("YYYY-MM-DD"))
      setToDate(moment().format("YYYY-MM-DD"))
    }

    getActivitesChartData(data)
    // const res = await axios.post('', data);

    // setData(res.data.data);

    // setTableData(res.data.tableData);

    // if (res.data.tableData < 1) {
    //   setDefaultTableText("No Records Found");
    // }
  }

  // const getManagers = async () => {
  //   const resdata = await axios.get('user/reports/users-list');
  //   if (resdata.data.status === "success") {
  //     setAccountManagers(resdata.data.usersList);
  //   } else {
  //   }
  // }
  var  monthsList = [{ label: "All", value: false }, { label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }]
  const quartersList = [{ label: "All", value: false }, { label: "Q1", value: 1 }, { label: "Q2", value: 2 }, { label: "Q3", value: 3 }, { label: "Q4", value: 4 }]
  // const getYears = async () => {
  //   const resdata = await axios.get('user/reports/years-list');
  //   if (resdata.data.status === "success") {
  //     setYears(resdata.data.yearsList);
  //   } else {
  //   }
  // }

  // const getSalesConfidenceStages = async () => {
  //   const resdata = await axios.get('user/reports/sales-confidence-stages-list');
  //   if (resdata.data.status === "success") {
  //     setSalesStages(resdata.data.salesConfidenceStagesList);
  //   } else {
  //   }
  // }

  useMemo(() => {
    getForecastChartData();
  }, [filterMonth, filterYear, filterQuarter, fromDate, toDate]);

  useMemo(() => {

    getForecastChartData();
    // getManagers();
    getYearsData();
    // getSalesConfidenceStages(); 
  }, [])

  const layout = {
    paper_bgcolor: 'transparent ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    margin: {
      l: 60,
      r: 60,
      b: 90,
      t: 60,
    },
    title: "Daily Activities",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }
const startEndDateHandle = (date) => {
  setFromDate(moment(date).format('YYYY-MM-DD'));
  setFilterYear(null)
  setFilterMonth(null)
  setFilterQuarter(null)
}
const startEndDateHandle1 = (date) => {
  setToDate(moment(date).format('YYYY-MM-DD'));
  setFilterYear(null)
  setFilterMonth(null)
  setFilterQuarter(null)
}


const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function(gd) {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadChartAsPng(gd);
      },
      title: 'Download As Png'
    },

  ],
};

function downloadChartAsPng(gd) {
  const chartContainer = document.getElementById(gd.id);
  const width = 1200;
  const height = 800;

  // Create a data URL of the chart as a PNG image
  Plotly.toImage(chartContainer, { format: 'png', width: width, height: height })
    .then(function(url) {
      // Trigger download using the created data URL
      const a = document.createElement('a');
      a.href = url;
      a.download = 'activity Chart.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
}

  return (
    <>
    {ActivitychartLoader  ?
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
  <>
 
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >
        <div className="container-fluid py-3 px-2">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter">
                <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                <div className="col-md-2 col-lg-2" >
                  <div >
                    <SelectYear years={yearsList} disableYearFilter={false} updateFilter={ (value) => setFilterYear(value) } />
                  </div>
                </div>
                <div className="col-md-2 col-lg-2" >
                  <div>
                    <MonthlyFilter monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilterMonth(value) } />
                  </div>
                </div>

                <div className="col-md-2 col-lg-2">
                   <QuartersFilter quartersList={quartersList} disableQuarterFilter={false} updateFilter={ (value) => setFilterQuarter(value) }  />
                </div>

                <div className="col-md-2 col-lg-2" >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="From Date"
                      inputVariant="outlined"
                      value={fromDate}
                      onChange={(date) => startEndDateHandle(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-md-2 col-lg-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="To Date"
                      inputVariant="outlined"
                      value={toDate}
                      onChange={(date) => startEndDateHandle1(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </MuiPickersUtilsProvider>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
            <PlotlyComponent
              data={JSON.parse(JSON.stringify(salesActivitiesChart))}
              layout={layout}
              useResizeHandler
              className="w-full h-full"
              config={config}
              id="activityChartComponent"
            />
        </div>
      </FullScreen>
      </>
    
    }
    </>
  )
});

// export default ;
const mapStateToProps = (state) => ({
  salesActivitiesChart : state.dashboard.salesActivitiesChart,
  yearsList : state.dashboard.yearsList,
  ActivitychartLoader:state.dashboard.ActivitychartLoader
});

const mapDispatchToProps = (dispatch) => ({
  getYearsData : () => dispatch(getYears()),
  getActivitesChartData : (data) => dispatch(getActivitesChart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activitychart);