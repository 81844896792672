import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import MaterialUiButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import Navbar from '../../home/Navbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './profile.css';
import { connect, useSelector } from 'react-redux';

import {updateProfiledata,getProfileData} from "../../../store/Profile"
const Profile = React.memo(({showModal,UpdateProfile,ProfileList,ansysCertificate,getProfileData}) => { 
  console.log("getprofiledata",ProfileList)
// export default function Profile() {
  const { user } = useSelector((state) => state.auth);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors: errors },
    reset: reset,
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const [profilePic, setProfilePic] = useState();
  const [profilePicName, setProfilePicName] = useState('');
  const [profilePicImageUrl, setProfilePicImageUrl] = useState('');
  const [ansysCertifications, setAnsysCertifications] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const [profile, setProfile] = useState({
    ansys_certification: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    job_title: '',
    password: '',
    confirm_password: '',
    company: '',
  });
  const history = useHistory('');
  const registrationError = false;

  const onInputChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value }, []);
  };

  const profileUpdate = async (e) => {
    const updateData = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      phone: profile.phone,
      job_title: profile.job_title,
      password: profile.password,
      company: profile.company,
      user_id: user?.user?._id,
      profile_pic: profilePic,
      profile_pic_name: profilePicName,
    };
    UpdateProfile(updateData)
    // if (profile.password !== profile.confirm_password) {
    //   alert("Passwords didn't match");
    // } else {
    //   try {
    //     await axios.post('/user/profile/update', updateData, {
    //       headers: {
    //         'content-type': 'application/json',
    //       },
    //     });
    //     setSnackBarMessage('Profile Updated Successfully!');
    //     handleClick();
    //   } catch (err) {}
    // }
  };

  // const fetchUserDetails = async () => {
  //   const res = await axios.get('/user/profile/details/' + user?.user?._id);
  //   const profileDetails = res.data.user;
  //   setAnsysCertifications(profileDetails.ansys_certification);
  //   setProfile({
  //     ansys_certification: ansysCertifications,
  //     first_name: profileDetails.first_name,
  //     last_name: profileDetails.last_name,
  //     email: profileDetails.email,
  //     phone: profileDetails.phone,
  //     job_title: profileDetails.job_title,
  //     company: profileDetails.company,
  //   });
  //   setProfilePicImageUrl(profileDetails.profile_pic);
  // };

  useEffect(() => {
    
   setProfile({
      ansys_certification:ProfileList?.ansysCertifications,
      first_name: ProfileList?.first_name,
      last_name: ProfileList?.last_name,
      email: ProfileList?.email,
      phone: ProfileList?.phone,
      job_title: ProfileList?.job_title,
      company: ProfileList?.company,
    
    });
    setProfilePicImageUrl(ProfileList?.profile_pic);
  }, [ProfileList]);

  const imageHandler = async (e) => {
    setProfilePic(e.target.files[0]);
    setProfilePicName(e.target.files[0].name);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    formData.append('user_id', user?.user?._id);
    try {
      const res = await axios.post('/user/profile/image-upload', formData);
      setProfilePicImageUrl(res.data.profile_pic);
    } catch (ex) {}
  };

  useMemo(() => {
    getProfileData();
  }, [])


  return (
    <div>
      <Navbar value="profile" className="activity_list_navbar" />
      <div className="profile">
        <div className="loginWrapper">
          <div className="loginRight">
            <br />
            <form className="registerBox" onSubmit={handleSubmit(profileUpdate)}>
              <div className="text-center">{registrationError}</div>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = 'https://kaizenat.com/kaizendynaLogo.png';
                }}
                className="profileUserImg"
                src={profilePicImageUrl}
                alt=""
                type="file"
                onClick={() => {}}
              />
              <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
              <div className="label">
                <label className="image-upload" htmlFor="input">
                  Set Profile
                </label>
              </div>
              <br />
              <TextField
                variant="outlined"
                label="Ansys Certifications Status"
                name="ansys_certification"
                value={profile.ansys_certification}
                {...register('ansys_certification', {
                  minLength: {
                    value: 3,
                    message: 'First name must be at least 3 characters',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.ansys_certification ? errors.ansys_certification.message : ''}
                error={Boolean(errors.ansys_certification)}
                className="loginInput"
              />
              <br />
              <TextField
                variant="outlined"
                label="First Name"
                name="first_name"
                value={profile.first_name}
                {...register('first_name', {
                  minLength: {
                    value: 3,
                    message: 'First name must be at least 3 characters',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.first_name ? errors.first_name.message : ''}
                error={Boolean(errors.first_name)}
                className="loginInput"
              />
              <br />
              <TextField
                variant="outlined"
                label="Last Name"
                name="last_name"
                value={profile.last_name}
                {...register('last_name', {
                  minLength: {
                    value: 1,
                    message: 'Last name must be at least 1 character',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.last_name ? errors.last_name.message : ''}
                error={Boolean(errors.last_name)}
                className="loginInput"
              />
              <br />
              <TextField
                variant="outlined"
                label="Email"
                name="email"
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                isDisabled={true}
                disabled
                value={profile.email}
                {...register('email', {
                  minLength: {
                    value: 3,
                    message: 'Email must be at least 1 character',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.email ? errors.email.message : ''}
                error={Boolean(errors.email)}
                className="loginInput"
                type="text"
              />
              <br />
              <TextField
                variant="outlined"
                label="Phone"
                name="phone"
                InputProps={{
                  readOnly: true,
                }}
                disabled
                value={profile.phone}
                {...register('phone', {
                  minLength: {
                    value: 3,
                    message: 'Phone must be at least 1 character',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.phone ? errors.phone.message : ''}
                error={Boolean(errors.phone)}
                className="loginInput"
                type="text"
              />
              <br />
              <TextField
                variant="outlined"
                label="Job Title"
                name="job_title"
                value={profile.job_title}
                {...register('job_title', {
                  minLength: {
                    value: 3,
                    message: 'Job Title must be at least 1 character',
                  },
                })}
                onChange={(e) => onInputChange(e)}
                helperText={errors.job_title ? errors.job_title.message : ''}
                error={Boolean(errors.job_title)}
                className="loginInput"
                type="text"
              />
              <br />
              <TextField
        variant="outlined"
        label="Password"
        name="password"
        value={profile.password}
        {...register('password', {
          minLength: {
            value: 6,
            message: 'Password must be at least 6 characters',
          },
        })}
        onChange={(e) => onInputChange(e)}
        helperText={errors.password ? errors.password.message : ''}
        error={Boolean(errors.password)}
        className="loginInput"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <TextField
        variant="outlined"
        name="confirm_password"
        label="Confirm Password"
        value={profile.confirm_password}
        {...register('confirm_password', {
          minLength: {
            value: 6,
            message: 'Confirm Password must be at least 6 characters',
          },
        })}
        onChange={(e) => onInputChange(e)}
        helperText={errors.confirm_password ? errors.confirm_password.message : ''}
        error={Boolean(errors.confirm_password)}
        className="loginInput"
        type={showConfirmPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
              <br />
              <button
              className="loginButton" 
              id="submit"
            >
              Submit
            </button>
            
             
            </form>
          </div>
        </div>

      </div>
    </div>
  );

});
const mapStateToProps = (state) => ({
 showModal:state.Hrislist.showModal,
 ProfileList:state.Profilelist.ProfileList,
//  ansysCertificate:state.Profilelist.ansysCertificate
})
const mapDispatchToProps = (dispatch) => ({
  UpdateProfile : (data) => dispatch(updateProfiledata(data)),
  getProfileData : (data) => dispatch(getProfileData(data)),

});

export default connect(mapStateToProps,mapDispatchToProps) (Profile)
