import React, {useState,useMemo,useEffect } from 'react';
import Navbar from '../home/Navbar';
import ContactTable from "../../components/commonColumns/ContactTable"
import { connect, useSelector } from 'react-redux';
import {getcontactlistdata} from "../../store/Contact"

  const ContactsList = React.memo (( {getcontactlist,contactlistdata,getcolumn}) => {
    // console.log("getcolumncontact",getcolumn)
    //    console.log("contact data are inside", contactlistdata)
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const fetchData = async () => {

    const userid = {
      
        user_id:userData?._id
    }
  
    getcontactlist(userid)
};
useMemo(() => {
    fetchData();
   
  }, []);

    return (
    <> 
      <Navbar value="contacts" className="activity_list_navbar" />    

      <ContactTable />
     
      </>
  );
  })
  const mapStateToProps = (state) => ({
    user : state.auth.user,
    contactlistdata : state.Contactlist.contactlistdata,
    getcolumn:state.Contactlist.getcolumn,
    // getviewcontact:state.Contactlist.getviewcontactlists
  });

  const mapDispatchToProps = (dispatch) => ({
    getcontactlist: (data) => dispatch(getcontactlistdata(data)),
    
  });

  export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);