





import React, { useState, useEffect, useMemo, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import Badge from 'react-bootstrap/Badge'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import {monthlyLayout, ProductFamilyFilter, CategoriesList, ProductsFilter, multipleLayout, normalLayout, AccountMangers, config} from './chartConfig'
import {getProductsList, getProductFamilyList, fetchTechiesList, fetchMonthlyTechActivity} from "../../store/dashboard";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const MonthvsMonth = React.memo(({getProductsListData,fetchTechiesListData,getProductFamilyListData,  fetchMonthlyTechActivityData, techiesList, productsList, productFamilyList, monthlyTechActivity,MonthvsMonthLoader}) => {
    // console.log("techiesList1",techiesList)
    // console.log("techiesList2",productFamilyList)
    const [columnsDisplay, setColumnsDisplay] = useState(true);
    const [showChart, setShowChart] = useState(true);
    const handle = useFullScreenHandle()
    // const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
 console.log("filtermonth",filter.Categoriesvalue)

 var str = "Demo";
 var new_str = str.replace(/-/g, '');
//  console.log("ASDS",new_str);
    const chartButtonClicked = async () => {

        setShowChart(true);
        if (!columnsDisplay) {
          handle.enter()
        }
      }

    // useEffect(() => {
    //     console.log("products list ", productsList)
    // }, [productsList])

    const categoriesList = [{value: "Demos", label: "Demos"}, {value: "Trainings", label:"Trainings"}, {value: "Tickets", label:"Tickets"}, {value: "Benchmarks", label:"Benchmarks"}]

  
    const getMonthlyTechActivity = async () => {
        var products = [];
       

        if(filter.products && filter.products.length>0){
            products = filter.products.map(item => {
              return item.value
            })
            filter.products_list = products;
        }
      
   
          var filterObj = {
          
            products_list: products,
            Categories: filter.Categoriesvalue?filter.Categoriesvalue : new_str,
            Techielist:filter.techielist?.value,
            product_family : filter.product_family?.value
          }
      
          fetchMonthlyTechActivityData([filterObj,new_str])
    }


    const reportChange = ((state, handle) => {
        var MonthvsMonthChat = document.getElementById("MonthvsMonthChatComponents");
        if (MonthvsMonthChat) {
            MonthvsMonthChat = document.getElementById("MonthvsMonthChatComponents").getElementsByClassName("user-select-none")[0]
            MonthvsMonthChat.id = "MonthvsMonthChat";
        }
        if (state) {
          if (MonthvsMonthChat) {
            document.getElementById("MonthvsMonthChat").style.height = (window.innerHeight - 60).toString() + 'px';
          }
          // setTableHeight((window.innerHeight - 160).toString() + 'px');
          // setRowsPerPage(15);
          setColumnsDisplay(false);
          // setFilterDisplay(false);
        } else {
          if (MonthvsMonthChat) {
            document.getElementById("MonthvsMonthChat").style.height = "450px";
          }
    
          // setTableHeight('333px');
          // setRowsPerPage(10);
          setColumnsDisplay(true);
          // setFilterDisplay(true);
        }
    
      });
      
      const removeProductsvalueBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadgemonth", inputValue);
        var productfilter = filter.products.filter(item => item.value !== inputValue)
        setFilter({...filter, products: productfilter})
      }

      const removetypevalueBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadgemonth", inputValue);
        var updatedMonth = filter.Categoriesvalue === inputValue ? "" : filter.Categoriesvalue;
        setFilter({ ...filter, Categoriesvalue: updatedMonth });
      }

      const removetypevalueBadgeDemos = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadgemonth", inputValue);
        var updatedMonth = "Demos" === inputValue ? "" : "Demos";
        setFilter({ ...filter, Categoriesvalue: updatedMonth });
    }

   
      const removeFiltertechieTypeBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadge2", inputValue); 
    
        var techielist_nameupdatedQuarter = filter.techielist.value === inputValue ? "" : filter.techielist.value;
        setFilter({...filter, techielist: techielist_nameupdatedQuarter})
       
      }
      
      const removeFilterproduct_familyTypeBadge = (value) => {
        var inputValue = value.target.getAttribute('value');
        console.log("yearbadge2", inputValue); 
    
        var product_familyupdatedQuarter = filter.product_family.value === inputValue ? "" : filter.product_family.value;
        setFilter({...filter, product_family: product_familyupdatedQuarter})
       
      }


    // const getProductsList = async () => {
    //     const res = await axios.get("/products/search");
    //     setProductsList(res.data.productsList);
    // }

    // const getSalesFilteredData = async () => {
    //     const res = await axios.post("/user/reports/achieved-target-filter", filter)
    //     if (res.data.status === "success") {
    //         setData(res.data.chartData);
    //     }
    // }

    // const getTechieList = async () => {
    //     const res = await axios.get("/user/techies-list");
    //     setAccountManagers(res.data.techiesList)
    // }
    
    // const getProductFamilyList = async () => {
    //     const res  = await axios.get('/product-family/list')
    //     setProductFamilyList(res.data.productFamilyList)
    // }
    
    useMemo(() => {

        if(filter.user_id && filter.user_id == "all"){
            // getSalesFilteredData();
        }else{
            getMonthlyTechActivity();
        }

    }, [filter]);

    useEffect(() => {
        getProductsListData();
        fetchTechiesListData();
        getProductFamilyListData();
    }, []);

  
      
  const containerRef = useRef(null); 
  const downloadContent = () => {
  const container = containerRef.current
  
  toPng(container)
  .then(function(dataUrl) {
    const link = document.createElement('a')
    link.download = 'MonthVsMonthchart.png';
    link.href = dataUrl;
    link.click();
  })
  .catch(function(error) {
    console.log("error:",error)
  });
  
  }
  
   
  const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
    modeBarButtonsToAdd: [
      {
        name: 'Download',
        icon: Plotly.Icons.camera, // Download icon
        click: function() {
          // Handle click on custom lock icon
          // alert('Custom Lock Icon Clicked!');
          toast.success("Downloaded Successfully!")
          // Download the chart as PNG
          downloadContent();
        },
        title: 'Download As Png'
      },
  
    ],
  };

    return (
        <>
        {MonthvsMonthLoader ? 
            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
         <>
         <div ref={containerRef}> 
         <FullScreen enabled={true} handle={handle} onChange={reportChange} >
            <div className="container-fluid py-2 px-2">
                <div className="row" style={{margin:'0px', padding:'0px'}}>

                <div className="col-lg-12 col-md-12 col-12 mx-auto">
                <div className="row row_gutter">

                <div className="col-md-1 col-lg-1" style={{ width: '7%' }}>
                <button onClick={chartButtonClicked}
                  className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
              </div>
              <div className="col-md-1 col-lg-1">
                <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                  <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                </button>
              </div>

                <div className="col-md-2 col-lg-2" >
                <ProductsFilter values={filter.products}  disableFilter={false} updateFilter={(value) => setFilter({ ...filter, products: value }) }  productsList ={productsList} />
                </div>
                <div className="col-md-2 col-lg-2" > 
                    <AccountMangers  selectSingle ={true} values={filter.techielist} placeholder={"Techies List"} TechieList={techiesList} disableAccountManagerFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, techielist: value }) } />
                </div>
                <div className="col-md-2 col-lg-2" >
                    <CategoriesList  defaultValue={{ label: "Demos", value: "Demos" }}  placeholder={"Categories List"} categoriesList={categoriesList} disableCategoryFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, Categoriesvalue: value }) } />
                </div>
                <div className="col-md-2 col-lg-2" >
                    <ProductFamilyFilter values={filter.product_family}  selectSingle ={true} placeholder={"Product Family"} productFamilyList={productFamilyList} disableCategoryFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, product_family: value }) } />
                </div>
                
            </div>
            </div>
            </div>
            </div>


            <div className="row">
            <div className="col-md-12">

            {
                filter.products?.map((filter) => (
                  <Badge onClick={removeProductsvalueBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
            }
           
            
            {
              filter.Categoriesvalue ?

              filter.Categoriesvalue&& 
              <Badge onClick={removetypevalueBadge}
                  pill data={filter.Categoriesvalue}  value={filter.Categoriesvalue} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.Categoriesvalue}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            
                  :
                  <Badge onClick={removetypevalueBadgeDemos}
                  pill data={"Demos"}  value={"Demos"}  style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{"Demos"} <span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                }

      
              
            {
              filter.techielist && 
              <Badge onClick={removeFiltertechieTypeBadge}
              pill data={filter.techielist?.value} value={filter.techielist?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.techielist?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }
            
              {
                filter.product_family && 
                <Badge onClick={removeFilterproduct_familyTypeBadge}
                pill data={filter.product_family?.value} value={filter.product_family?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.product_family?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
              }
            
          </div>
          </div>

            <PlotlyComponent
            id="monthlyTechActivityChatComponents"
            data={monthlyTechActivity}
            layout={{ ...monthlyLayout, title: `Monthly overview chart (${filter.Categoriesvalue || 'Demo'})` }}
            useResizeHandler
            className="w-full h-full"
            config={config}
        />
        </FullScreen>
        </div>
           
            </>
        
        }
        </>
    )
});

// export default MonthvsMonth;

const mapStateToProps = (state) => ({
    techiesList : state.dashboard.techiesList,
    productsList : state.dashboard.productsList,
    productFamilyList : state.dashboard.productFamilyList,
    monthlyTechActivity : state.dashboard.monthlyTechActivity,
    MonthvsMonthLoader:state.dashboard.MonthvsMonthLoader
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetchMonthlyTechActivityData: (data) => dispatch(fetchMonthlyTechActivity(data)),
    fetchTechiesListData : () => dispatch(fetchTechiesList()),
    getProductsListData : () => dispatch(getProductsList()),
    getProductFamilyListData : () => dispatch(getProductFamilyList())
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(MonthvsMonth);