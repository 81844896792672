
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showleavemodal:false,
    Reject_showModal:false,
    leavelist:[],
    approveLeave:[],
    cancelleave:[],
    rejectsubmitlists:[],
    Type:[],
    createdleave:[],
    LeaveTypeList:[{ label: "Sick Leave ", value: 'Sick Leave' }, { label: "Casual Leave", value: 'Casual Leave' }, { label: "Optional Leave", value: 'Optional Leave' }],
    columnsList : [],
  }

const LeaverequestSlice = createSlice({
    name: "LeaveRequestlists",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          showleaveOpenmodal: (state, action) => {
            state.showleavemodal = true;
          },
          showleaveClosemodal: (state, action) => {
            state.showleavemodal = false; 
          },

          setRejectLeaveRejectId(state, action) {
            state.RejectLeaveRejectId = action.payload;
          },
          Reject_openModal: (state, action) => {
            state.Reject_showModal = true; 
          },
          Reject_closeModal: (state, action) => {
            state.Reject_showModal = false; 
          },
          setType(state, action) {
            state.Type = action.payload;
          },


          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          leavelistSuccess(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.leavelist = action.payload;
            // toast.success("leave list data") 
    
        },
        
        leavelistFailure(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.leavelist = [];
            toast.error("Error in leave list data") 
          },

          approvedSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.approveLeave = action.payload;
            toast.success("Successfully approved ") 
    
        },
        approvedFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.approveLeave =[];
            toast.error("Error in approve") 
    
        },
        cancelledSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.cancelleave = action.payload;
            toast.success("Successfully cancelled ") 
    
        },
        cancelledFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.cancelleave = [];
            toast.error("Error cancelled ") 
    
        },
        rejectsubmitlistSuccess(state,action) {
          state.meetingLoader = false;
          state.error = false;
          state.errorPayload = null;
          state.rejectsubmitlists = action.payload;
          toast.success("Successfully cancelled ") 
  
      },
      rejectsubmitlistFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.rejectsubmitlists = [];
        toast.error("Error in reject ") 

    },
    createleaveSuccess(state,action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.createdleave = action.payload;
      state.showleavemodal = false;

    },
    createleaveFailure(state,action) {
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.createdleave = [];
      toast.error("Leave Request failed")
      state.showleavemodal = true;

    },
    leaveColumnsListSuccess(state, action){
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = null;
      state.columnsList = action.payload;
    },
    leaveColumnsListFailure(state, action){
      state.meetingLoader = false;
      state.error = false;
      state.errorPayload = action.payload;
      state.columnsList = [];
      toast.error("Failed to get table columns");
    },
    updateLeaveColumns(state, action){
      state.columnsList = action.payload
    }
     
    }  
});
export const LeaverequestActions = LeaverequestSlice.actions;



///LEAVE REQUEST TABLE LIST START HERE 

export const getleavelist = (data) => {
    return async (dispatch) => {
      dispatch(LeaverequestActions.loaderStart());
  
      try {
        await Api.get("/user/hr-activities/leave-request/list").then((res) => {
          if (res.data.status === "success") {
            dispatch(LeaverequestActions.leavelistSuccess(res?.data?.leaveRequestsList));
          }
          if (res.data.status === "failure") {
            dispatch(LeaverequestActions.leavelistFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(LeaverequestActions.leavelistFailure("Network Error"));
      }
    };
  };


  export const approvedLeave = (updatedData) => {
    return async(dispatch) => {
        dispatch(LeaverequestActions.loaderStart("meetingloader"))

        try {
            await Api.post("/user/hr-activities/leave-request/update",updatedData).then((res) => {
                if(res.data.status === "success"){
                dispatch(LeaverequestActions.approvedSuccess(res.data));
                dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                    dispatch(LeaverequestActions.approvedFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(LeaverequestActions.approvedFailure("Network Error"));
        }
    }
 
}
export const getcancelLeave = (updatedData) => {
    return async(dispatch) => {
        dispatch(LeaverequestActions.loaderStart("meetingloader"))

        try {
            await Api.post("/user/hr-activities/leave-request/update",updatedData).then((res) => {
                if(res.data.status === "success"){
                dispatch(LeaverequestActions.cancelledSuccess(res.data));
                dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                    dispatch(LeaverequestActions.cancelledFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(LeaverequestActions.cancelledFailure("Network Error"));
        }
    }
 
}
//leave request reject 
export const rejectsubmitlist = (updatedData) => {
  return async(dispatch) => {
      dispatch(LeaverequestActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/hr-activities/leave-request/update",updatedData).then((res) => {
              if(res.data.status === "success"){
              dispatch(LeaverequestActions.rejectsubmitlistSuccess(res.data));
              // dispatch(getleavelist({user_id: userObj.user?._id}))
              dispatch(getleavelist())
              }
              if(res.data.status ==="failure") {
                  dispatch(LeaverequestActions.rejectsubmitlistFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(LeaverequestActions.rejectsubmitlistFailure("Network Error"));
      }
  }

}


export const createLeaveModal = (activityData) => {
  return async(dispatch) => {
      dispatch(LeaverequestActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/hr-activities/leave-request/create",activityData).then((res) => {
              if(res.data.status === "success") {
                toast.success(`${activityData.leave_type} Successfully Added...!`)
                  dispatch(LeaverequestActions.createleaveSuccess(res.data));
                  dispatch(getleavelist())
              }
              if(res.data.status ==="failure") {
               
                  dispatch(LeaverequestActions.createleaveFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(LeaverequestActions.createleaveFailure("Network Error"));
      }
  }
  }
  

  export const getLeaveListColumns  = () => {
    return async(dispatch) => {
        dispatch(LeaverequestActions.loaderStart("meetingloader"))
  
        try {
          var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/leaverequests/"+ userData?.user?._id).then((res) => {
                if(res.data.status === "success") {
                  dispatch(LeaverequestActions.leaveColumnsListSuccess(res.data.columnsList?.columns_list));
                  // toast.success(`${activityData.leave_type} Successfully Added...!`)
                    // dispatch(LeaverequestActions.createleaveSuccess(res.data));
                    // dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                 
                    dispatch(LeaverequestActions.leaveColumnsListFailure(res.data.msg))
                }
            })
        }
        catch (err) {  
          dispatch(LeaverequestActions.leaveColumnsListFailure("Network Error"));
        }
    }
    }

  // const res = await axios.get("" + userData._id);
  // setColumnsList(res.data.columnsList ? res.data.columnsList.columns_list : []);

  export default LeaverequestSlice.reducer;