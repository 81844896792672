import axios from "axios";  
export const loginCall = async (userCredential, dispatch) => {

  dispatch({ type: "LOGIN_START" });
  try {
    const res = await axios.post("/user/login", userCredential);
    dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
  } catch (err) {
    dispatch({ type: "LOGIN_FAILURE", payload: err.response.data.msg });
  }

};

export const ForgotPasswords = async (userCredential, dispatch) => {
  try {
    const res = await axios.post("/user/forgot/password", userCredential);
    return res;
  } catch (err) {
    return err;
    dispatch({ type: "FORGOT_PASSWORD_FAILURE", payload: err.response.data.msg })


  }
};

export const ResetPasswords = async (userCredential, dispatch) => {
  try {
    const res = await axios.post("/user/reset/password", userCredential);


    return res;


  } catch (err) {


    return err;


  }
};