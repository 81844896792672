import React, { useState, useEffect } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import {quarterlyLayout, ProductFamilyFilter,CategoriesList, ProductsFilter, multipleLayout, normalLayout, AccountMangers, config} from './chartConfig'
import {getProductsList, getProductFamilyList, fetchTechiesList, getAchievedTargetFilteredData, getSalesTargetChartData} from "../../store/dashboard";
import { connect } from "react-redux";
import { useMemo } from "react";
import { toast } from 'react-toastify';

const PlotlyComponent = createPlotlyComponent(Plotly);

const QuartervsQuarterChart = React.memo(({getSalesTargetChartDataList, getAchievedTargetFilteredDataList, getProductFamilyListData, getProductsListData, fetchTechiesListData, techiesList, productsList, productFamilyList, achievedTargetFilterData,QuartervsQuarterLoader}) => {

    const [filter, setFilter] = useState({});
    const categoriesList = [{value: "Demos", label: "Demos"}, {value: "Trainings", label:"Trainings"}, {value: "Tickets", label:"Tickets"}, {value: "Benchmarks", label:"Benchmarks"}]

    // const dispatch = useDispatch();
    // const {  } = useSelector(state => state.dashboard);

    const getSalesTargetData = async () => {
        if(filter.product_family){
            filter.product_family= filter.product_family?.value
            
        }

        var products = [];
        if(filter.products && filter.products.length>0){
            products = filter.products.map(item => {
              return item.value
            })
            filter.products_list = products;
        }

        getSalesTargetChartDataList(filter)
        delete filter["products_list"]
    }
    
    useMemo(() => {
        if(filter.user_id && filter.user_id == "all"){
            getAchievedTargetFilteredDataList(filter)
        }else{
            getSalesTargetData();
        }
    }, [filter]);
    
    useMemo(() => {
        getProductsList();
        fetchTechiesList();
        getProductFamilyList();
    }, []);

    const config = {
        responsive: true,
        displaylogo: false,
        showTips: true,
        pan2d: true,
        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
        modeBarButtonsToAdd: [
          {
            name: 'Download',
            icon: Plotly.Icons.camera, // Download icon
            click: function(gd) {
              // Handle click on custom lock icon
              // alert('Custom Lock Icon Clicked!');
              toast.success("Downloaded Successfully!")
              // Download the chart as PNG
              downloadChartAsPng(gd);
            },
            title: 'Download As Png'
          },
      
        ],
      };
      
      function downloadChartAsPng(gd) {
        const chartContainer = document.getElementById(gd.id);
        const width = 1200;
        const height = 800;
      
        // Create a data URL of the chart as a PNG image
        Plotly.toImage(chartContainer, { format: 'png', width: width, height: height })
          .then(function(url) {
            // Trigger download using the created data URL
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Quarter vs Quarter Chart.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      }
    return (
        <>
        {QuartervsQuarterLoader ? 
            <div className="parent-div">
            <div className="loader"></div>
          </div>
         :
         <> 
        
            <div className="container-fluid py-2 px-2">
                <div className="row" style={{margin:'0px', padding:'0px'}}>
    
                <div className="col-md-3 col-lg-3" >
                <ProductsFilter values={filter.products}  disableFilter={false} updateFilter={(value) => setFilter({ ...filter, products: value }) }  productsList ={productsList} />
                </div>
                <div className="col-md-3 col-lg-3">
                    <AccountMangers placeholder={"Techiesvasdsa List"} accountManagers={techiesList} disableAccountManagerFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, user_id: value }) } />
                </div>
                <div className="col-md-3 col-lg-3" >
                    <CategoriesList  placeholder={"Categories List"} categoriesList={categoriesList} disableCategoryFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, type: value }) } />
                </div>
                <div className="col-md-3 col-lg-3" >
                    <ProductFamilyFilter selectSingle ={true} placeholder={"Product Family"} productFamilyList={productFamilyList} disableCategoryFilter={filter.disableAccountManagerFilter || false}  updateFilter={ (value) => setFilter({ ...filter, product_family: value }) } />
                </div>
                <PlotlyComponent
                id="QuarterVsQuarterChartComponents"
                    data={achievedTargetFilterData}
                    layout={quarterlyLayout}
                    useResizeHandler
                    className="w-full h-full"
                    config={config}
                />
                </div>
            </div>
            </>
        
        }
        </>
    )
});

// export default ;
const mapStateToProps = (state) => ({
    techiesList : state.dashboard.techiesList,
    productsList : state.dashboard.productsList,
    productFamilyList : state.dashboard.productFamilyList,
    achievedTargetFilterData : state.dashboard.yearsList,
    QuartervsQuarterLoader:state.dashboard.QuartervsQuarterLoader,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getAchievedTargetFilteredDataList: (data) => dispatch(getAchievedTargetFilteredData(data)),
    fetchTechiesListData : () => dispatch(fetchTechiesList()),
    getProductsListData : () => dispatch(getProductsList()),
    getProductFamilyListData : () => dispatch(getProductFamilyList()),
    getSalesTargetChartDataList : (data) => dispatch(getSalesTargetChartData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(QuartervsQuarterChart);