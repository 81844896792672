import React, { useEffect } from 'react';
import Login from './pages/Auth/login/Login';
import Register from './pages/Auth/register/Register';
import ForgotPassword from './pages/Auth/forgotPassword/ForgotPassword';
import ResetPassword from './pages/Auth/resetPassword/ResetPassword';
import Home from './pages/home/Home';
import ContactsList from './pages/contacts/ContactsList';
import AccountsList from './pages/accounts/AccountsList';
import OpportunitiesList from './pages/opportunities/OpportunitiesList';
import LeadsList from './pages/leads/LeadsList';
import Profile from './pages/Auth/profile/Profile.jsx';
import axios from "axios";
import MeetingsList from './pages/meetings/MeetingsList';
import TicketsList from './pages/tickets/TicketsList';
import DemoList from './pages/demo/DemoList';
import BenchmarkList from "./pages/benchmark/BenchmarkList";
import TrainingList from "./pages/training/TrainingList";
import Queries from './pages/queries/Queries'; 
import Leave from './pages/leaveRequest/Leave';
import TechieMetting from "./pages/techieMetting/TechieMetting";
import CaseStudy from "./pages/casestudy/CaseStudy"
import Testimonial from "./pages/testimonial/Testimonial";
import SetTarget from "./pages/setTarget/SetTarget"
import Permissions from "./pages/Permissions/Permissions";
import OpportunityCreate from "./pages/opportunities/OpportunityCreate";
import Resources from "./pages/resources/ResourcesList";
import EmployeeeChart from './components/pageModals/EmployeeChart';
import SetTargetTree from './pages/setTarget/SetTargetTree';
import CallRecord from './pages/dcr/CallRecord';
import TimeSheet from './pages/timesheet/TimeSheet';
import Webinars from './pages/webinars/Webinars';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { toast } from 'react-toastify';
import { AuthContext } from './context/AuthContext';
import AssignedList from "../src/pages/assignedLeadList/AssignedList";
import Payslip from './pages/payslip/Payslip';
import Hardware from './pages/hardwareAssets/Hardware';
import IndustryMetricList from './pages/IndustryMetric/IndustryMetricList';
import RawDataBase from './pages/RawDatabase/RawDataBase';
import BattleCards from './pages/battlecards/BattleCards';
import EmailCampaign from './pages/digitalMarketing/EmailCampaign';
import HumanResourceInformationSystem from './pages/hsir/HumanResourceInformationSystem';
import { useSelector } from 'react-redux';

import RoiList from './pages/roi/RoiList';
import Crmcalendar from "./pages/calendar/Crmcalendar"

import AddTargets from "./pages/setTarget/AddTargets";
import AssignSegments from "./pages/setTarget/AssignSegments";
import AgGridTable from "./components/commonColumns/AgGridTable";

import AgGrid from "./components/commonColumns/AgGrid.js";

import ProductTargets from './pages/setTarget/ProductTargets.jsx';

// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignProducts from './pages/setTarget/AssignProducts.jsx';
import TempLeads from './components/commonColumns/TempLeads.js';
import AgGrid1 from './components/commonColumns/AgGrid1.js';
// import RawDataTableRowData from './components/commonColumns/RawDataTableRowData.js';
import RawDataTablePagination from './components/commonColumns/RawDataTablePagination.js';
import SalesEventTracker from "./pages/eventReports/SalesEventTracker.jsx";
// import {getRegions} from "./store/targets"
// import { useDispatch } from 'react-redux';
//  beta API 
// axios.defaults.baseURL = 'https://crm.beta.kaizenat.com/api'; // this is beta api.

//live API


   axios.defaults.baseURL = 'https://crm.kaizenat.com/api'; // this is live api database.

// development API
//  axios.defaults.baseURL = 'http://localhost:8001/api'


// axios.defaults.baseURL = 'https://crm.kaizenat.com/api'

// axios.defaults.baseURL = 'https://crm.kaizenat.com/api'

export default function App() {
  
  const {user} = useSelector(state => state.auth)

  const permissions = user ? user.permissions : [];

  axios.defaults.headers.common['Authorization'] = user ? user.token : null;

  const {isFetching}  = useSelector(state => state.dashboard);


  // useEffect(() => {
  //   if(isFetching){
  //     toast.info('Fetching...', {
  //       position: "top-right",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //       });
  //   }else{
  //     toast.dismiss();
  //   }
  // },[isFetching])

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRegions());
  // }, [])
  useEffect(() => {

    if (user) {
      var idleInterval = 0;
      document.addEventListener('mousemove', (e) => {
        idleInterval = 0;
      });
      window.addEventListener('keypress', e => {
        idleInterval = 0;
      });
      setInterval(() => {

        idleInterval += 1;


        if (idleInterval >= 10) {

          window.localStorage.clear();
          window.location.reload();
        }

      }, 60000);
    }

  }, []);
  
  return (
    <>
    <Router>
      <Switch>
        <Route exact path="/">
          {user ? <Home /> : <Login />}
        </Route>
        <Route path="/login">
          {user ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/register">
          {user ? <Redirect to="/" /> : <Register />}
        </Route>
        <Route path="/forgot-password">
          {user ? <Redirect to="/" /> : <ForgotPassword />}
        </Route>
        <Route path="/reset-password">
          {user ? <Redirect to="/" /> : <ResetPassword />}
        </Route>
        <Route path="/dashboard">
          {user ? <Home></Home> : <Login />}
        </Route>
        <Route path="/contacts-list">
          {(user && permissions.includes('viewContactsPage')) ? <ContactsList ></ContactsList> : <Login />}
        </Route>
        <Route path="/accounts-list">
          {(user && permissions.includes('viewAccountsPage')) ? <AccountsList ></AccountsList> : <Login />}
        </Route>
        <Route path="/leads-list">
          {(user && permissions.includes('viewLeadsPage')) ? <LeadsList ></LeadsList> : <Login />}
        </Route>
        <Route path="/meetings-list">
          {(user && permissions.includes('viewMeetingsPage')) ? <MeetingsList ></MeetingsList> : <Login />}
        </Route>
        <Route path="/opportunities-list">
          {(user && permissions.includes('viewOpportunitiesPage')) ? <OpportunitiesList ></OpportunitiesList> : <Login />}
        </Route>
        <Route path="/tickets-list">
          {(user && permissions.includes('viewTicketsListPage')) ? <TicketsList ></TicketsList> : <Login />}
        </Route>
        <Route path="/techie-demo">
          {(user && permissions.includes('viewDemoListPage')) ? <DemoList ></DemoList> : <Login />}
        </Route>

        <Route path="/techie-benchmark">
          {(user && permissions.includes('viewBenchmarksPage')) ? <BenchmarkList ></BenchmarkList> : <Login />}
        </Route>

        <Route path="/techie-training">
          {(user && permissions.includes('viewTrainingListPage')) ? <TrainingList ></TrainingList> : <Login />}
        </Route>

        <Route path="/queries-feedback">
          {user ? <Queries></Queries> : <Login />}
        </Route>

        <Route path="/leave-request">
          {(user && permissions.includes('viewLeaveRequestPage')) ? <Leave ></Leave> : <Login />}
        </Route>

        <Route path="/techie-metting">
          {(user && permissions.includes('viewTechieMeetingsPage')) ? <TechieMetting ></TechieMetting> : <Login />}
        </Route>

        <Route path="/case-study">
          {(user && permissions.includes('viewCaseStudiesPage')) ? <CaseStudy ></CaseStudy> : <Login />}
        </Route>

        <Route path="/testimonial">
          {(user && permissions.includes('viewTestimonialsPage')) ? <Testimonial ></Testimonial> : <Login />}
        </Route>

        <Route path="/set-target">
          {(user && permissions.includes('viewAnySalesAchievedChart')) ?
           <SetTarget ></SetTarget> : 
             <Login />} 
        </Route>

        <Route path="/profile">
          {user ? <Profile></Profile> : <Login />}
        </Route>
        
        <Route path="/permissions">
          {(user && permissions.includes('canAddPermissions')) ? 
           <Permissions></Permissions>
          : <Login />}    
        </Route>
        
        <Route path="/opportunity-create">
          {(user && permissions.includes('viewAnySalesAchievedChart')) ? <OpportunityCreate></OpportunityCreate> : <Login />}
        </Route>
        
        <Route path="/org-chart">
          {user ? <EmployeeeChart></EmployeeeChart> : <Login />}
        </Route>

        <Route path="/set-target-tree">
          {(user && permissions.includes('viewAnySalesAchievedChart')) ? <AddTargets></AddTargets> : <Login />}
        </Route>
        {/* <SetTargetTree></SetTargetTree> */}

        <Route path="/resources">
          {(user && permissions.includes('viewResourcesPage')) ? <Resources></Resources> : <Login />}
        </Route>

        <Route path="/call-records">
          {(user && permissions.includes('viewDCRListPage')) ? <CallRecord ></CallRecord> : <Login />}
        </Route>

        <Route path="/clock-time">
          {(user && permissions.includes('viewTaskManagerPage')) ? <TimeSheet ></TimeSheet> : <Login />}
        </Route>

        <Route path="/events">
          {(user && permissions.includes('viewWebinarsPage')) ? <Webinars ></Webinars> : <Login />}
        </Route>
        <Route path="/assigned-meetings">
         {(user && permissions.includes('viewDCRListPage')) ? <AssignedList></AssignedList>:<Login />}
        </Route>

        <Route path="/payslip">
         {(user && permissions.includes('ViewPayslipPage')) ?<Payslip></Payslip>:<Login />}
        </Route>

        <Route path="/hardware-assets">
         {(user && permissions.includes('ViewHardwareAssetPage')) ? <Hardware></Hardware>:<Login />}
        </Route>

        <Route path="/rawdata">
         {(user && permissions.includes('viewRawdataPage')) ? <RawDataBase></RawDataBase> :<Login />}
        </Route>

        <Route path="/battlecards">
          {(user && permissions.includes('viewRawdataPage')) ? <BattleCards></BattleCards> : <Login />}
        </Route>

        <Route path="/industry-metric">
         {(user && permissions.includes('viewIndustryMetricPage')) ? <IndustryMetricList></IndustryMetricList> :<Login />}
        </Route>

        <Route path="/email-campaign">
         {(user && permissions.includes('viewEmailCampaignPage')) ? <EmailCampaign></EmailCampaign> :<Login />}
        </Route>
        <Route path="/hris">
        {(user) ? <HumanResourceInformationSystem></HumanResourceInformationSystem> : <Login/>}
        </Route>
        
        <Route path="/roi-list">
          {(user && permissions.includes('viewRoiSheetPage'))  ? <RoiList></RoiList> : <Login />}
        </Route>

        <Route path="/calendar">
        {(user && permissions.includes('viewCalendarPage')) ? <Crmcalendar></Crmcalendar> : <Login/> }
        </Route>
        
        <Route path="/assign-segments">
          {(user && permissions.includes('canAddProductTargets'))  ?  
          <AssignSegments></AssignSegments>  
            : <Login />}  
        </Route>
        
        <Route path="/ag-grid-table">
          {(user && permissions.includes('canAddPermissions'))  ? 
           <AgGrid></AgGrid>  
            : <Login />}  
        </Route>

        <Route path="/product-targets-tree">
          {(user && permissions.includes('canAddPermissions'))  ?  <SetTargetTree></SetTargetTree>  : <Login />} 
        </Route>

        <Route path="/product-targets">
          {(user && permissions.includes('viewAnyProductTargetsChart'))  ?  
          <ProductTargets></ProductTargets>  
           : <Login />}   
        </Route>
        
        <Route path="/assign-products">
          {(user && permissions.includes('canAddProductTargets'))  ?  
          <AssignProducts></AssignProducts> 
             : <Login />}   
        </Route>

        <Route path="/temp-leads">
          {(user && permissions.includes('viewTempLeadsPage'))  ?  
          <TempLeads></TempLeads> 
             : <Login />}   
        </Route>

        <Route path="/ag-grids">
          <AgGrid1></AgGrid1>   
        </Route>

 
        {/* <Route path="/ag-grid-rowdata">
          <RawDataTableRowData></RawDataTableRowData>   
        </Route> */}

        <Route path="/ag-grid-pagination">
          <RawDataTablePagination></RawDataTablePagination>   
        </Route>

        <Route path="/event-tracker/list">
        {(user && permissions.includes('viewEventReportsPage'))  ?  
          <SalesEventTracker></SalesEventTracker>
           : <Login />}   
        </Route>
        
      </Switch>
      {/* <ToastContainer
position="bottom-left"
autoClose={3000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/> */}
    </Router>
    <div>
    <ToastContainer
position="top-right"
autoClose={1000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
  </div>
  </>
  );
}

