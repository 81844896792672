import React, { useState, useRef, useContext, useEffect } from 'react';
// Bootstrap button and modal
import { Button, Modal } from 'react-bootstrap'
import axios from 'axios';
// React hook use form
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
//Auth Context
import { AuthContext } from '../../context/AuthContext';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import  Switch  from '@mui/material/Switch';
import { connect, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import{CalendarActions,updateCalendarEvents} from "../../store/Calendar"
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AiOutlineDelete } from "react-icons/ai";
import {AiOutlinePlus} from "react-icons/ai"

const UpdateCalendarModal = React.memo(({user,updateCalendarEventsData,openshowModal,closemodal,rowMetadata}) => { 

  console.log("rowdata",rowMetadata)
  const [calendarShow, setCalendarShow] = useState(false);
 
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [disable, setDisable] = React.useState(false);
  const { register: contactRegister, handleSubmit: contactHandleSubmit, setError: contactSetError, formState: { errors: contactErrors } } = useForm();


  const [Calendarupdate, SetCalendarupdate] = useState({

  

    title: rowMetadata[6],
    Attendees:  rowMetadata[2],
    start: new Date(rowMetadata[3]),
    end: new Date(rowMetadata[4]),   
    description: rowMetadata[5],
    
  });

console.log("syd_update_obj",Calendarupdate)


const [switchState, setSwitchState] = useState(rowMetadata[7] === "unknown" ? false : true);

  const handleChange = () => {
    setSwitchState(!switchState);
  };


  const customTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            '&$checked': {
              color: '#006400', // Dark green color when on
            },
            '!&$checked': {
              color: '#006400', // Dark green color when on
            },
            '&$checked + $track': {
              backgroundColor: '#006400', // Dark green color for the track when on
            },
          },
          
          track: {
            backgroundColor: '#e57373', 
           // Red color for the track when off
          },
        },
      },
    },
  });


  const [attendees, setattendees] = useState([
    {
      emailName:"hello",
    emailAddress:"hello@gmail.com"
  }]);

  const attendeesHandlechange = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...attendees];
    onChangeVal[i][name] = value;
    setattendees(onChangeVal);
  };
  console.log("attendeesnewval",attendees)
  const handleAdd = () => {
    setattendees([...attendees,{emailName:"",emailAddress:""}])
  }

  const handleDelete = (i) => {
    const deleteVal = [...attendees]
    deleteVal.splice(i,1)
    setattendees(deleteVal)
  }

//   const UpdateContactHandle = async (e) => {
//     setDisable(true);
//     // e.preventDefault();


//     const { title, Attendees, start, end, description } = Calendarupdate;
//     console.log("calendar_after",Calendarupdate)


//     const updatedData = {
     
//             email:Attendees,
//             subject:description, ///description
//             context:title,  
         
           
//             startDateTime :start.toISOString(),
//             endDateTime: end.toISOString()
//             ,
//             attendees: [
//               {
//                 emailAddress: userData ? userData.email : '',
//                 name: userData ? userData.first_name : ''
//               }
//             ]
//           };
//           const combinedData = [rowMetadata[0],updatedData];
// updateCalendarEventsData(combinedData);
          
          
//   }


  const UpdateContactHandle = async (e) => {
    setDisable(true); 
    const { title, Attendees, start, end, description } = Calendarupdate;
    console.log("calendar_after",Calendarupdate)
    const combinedArray = {
      emailAddress: attendees.map(item => ({
        address: item.emailAddress,
        name: item.emailName
      }))
    };
    // console.log("combined_values", combinedArray);
    const eventid = rowMetadata[0]
    const obj = {
      eventId:rowMetadata[0],
      meetingType: switchState ? 'online' : 'offline',
      email:user? user.user.email: '',
      subject: title,
      content: description,
      startDateTime: moment(start).format('YYYY-MM-DDTHH:mm:ss'),
      endDateTime: moment(end).format('YYYY-MM-DDTHH:mm:ss'),
      attendees: combinedArray
    };
    // updateCalendarEventsData(eventid);
    updateCalendarEventsData(obj);
  }


  return (
   <>
    <Modal
    show={()=> openshowModal()}
    onHide={() => setCalendarShow(false)}
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
  >

    <Modal.Header>
      <Modal.Title className="custom_header_title_update">
       Calendar data List
      </Modal.Title>
      <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
    </Modal.Header>
    <Modal.Body>

    <div style={{display:"flex" }}>
    <ThemeProvider theme={customTheme}>
    <div style={{display:"flex" }}>
      <Switch
        checked={switchState}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Switch demo' }}
        defaultChecked
      />
      <div style={{fontSize:"13px" ,margin:"8px"}} >{switchState ? 'Online' : 'Offline'}</div>
    </div>
    </ThemeProvider>
       </div>
    <br/>

      <form onSubmit={contactHandleSubmit(UpdateContactHandle)}>
        {/* <input type="hidden"  name="opportunity_id" value={op_id} /> */}
     
        <div class="row">
        <div className="col-md-6">
        <TextField
          variant="outlined"
         
          name="title"
        label="Emailname"
          value={Calendarupdate.title}
          onChange={(e) => SetCalendarupdate({ ...Calendarupdate, title: e.target.value })}
          required
          size="small"
          className="form-control"
        />
      </div>

      { switchState ?  <div className='col-md-6 d-flex justify-content-center'>
      <Button variant="primary" onClick={handleAdd} size="sm">
        <AiOutlinePlus size={15}/> Add Attendees
      </Button>
    </div> : (
        <div className="col-md-6">
        <TextField variant="outlined"
         
        required
        size="larger"
        type="text"
        className="form-control" 
        name="description"  
        label="Description"
        value={Calendarupdate.description}
        onChange={(e) => SetCalendarupdate({ ...Calendarupdate,description : e.target.value })}
    >
       
    </TextField>
        </div>

       )}
       </div>
       <br/>
 
 
 
       {switchState ? (
         <div className="row">
           {attendees.map((Val,i) => 
             <div>
             <br/>
             <div className="row">  
             <div className='col-md-5'>
             <TextField variant="outlined"
                    
         required
         size="larger"
         type="text"
         className="form-control" 
         name="emailName"  
         label="Name"
         value={Val.emailName}
         onChange={(e) => attendeesHandlechange(e,i)}
       />
       </div>
       <div className='col-md-5'>
       <TextField variant="outlined"
                    
       required
       size="larger"
       type="text"
       className="form-control" 
       name="emailAddress"  
       label="Email"
       value={Val.emailAddress}
       onChange={(e) => attendeesHandlechange(e,i)}
       />
       
       </div>
       <div className='col-md-2'>
       <Button variant="outlined" className='btn btn-sm btn-danger' color="secondary" 
       onClick={() => handleDelete(i)}>
       <AiOutlineDelete />
       </Button>
       
       </div>
             </div>
             </div>
             
             )
           }
         </div>
         
       ) : null}
       <br />
       {switchState ?  <br /> : null }



  <div className="row">
         <div className="col-md-6">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DateTimePicker
           className="form-control"
           label="Start Date Time*"
           inputVariant="outlined"
           showTodayButton
           format="dd-MM-yyyy hh:mm a"
           value={Calendarupdate.start} // Set the value
           onChange={(date) => SetCalendarupdate({ ...Calendarupdate, start: date })} // Update the start value
           InputLabelProps={{
             shrink: true,
           }}
         />
       </MuiPickersUtilsProvider>
         
         </div>
         <div className="col-md-6">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DateTimePicker
           className="form-control"
           label="End Date Time*"
           inputVariant="outlined"
           showTodayButton
           format="dd-MM-yyyy hh:mm a"
           value={Calendarupdate.end} // Set the value
           onChange={(date) => SetCalendarupdate({ ...Calendarupdate, end: date })} // Update the end value
           InputLabelProps={{
             shrink: true,
           }}
         />
       </MuiPickersUtilsProvider>
         </div>
         </div>
         <br />

         <div className="row">
         { switchState ?  (
         <div className="col-md-6">
         <TextField variant="outlined"
         multiline row={2} 
         required
         size="larger"
         type="text"
         className="form-control" 
         name="description"  
         label="Description*"
         value={Calendarupdate.description}
         onChange={(e) => SetCalendarupdate({ ...Calendarupdate,description : e.target.value })}
     >
        
     </TextField>
         </div>
         ) : null 
         }
       </div>
       <br />

        <br />
        <div className="row">
          <div>
            <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Update</button> 
          </div>
        </div>
      </form>
    </Modal.Body>
  </Modal>
    </>
  )

});
const mapStateToProps = (state) => ({
 showModal:state.Hrislist.showModal,
 rowMetadata:state.Calendarlist.rowMetadata,
 updateCalendarEvent:state.updateCalendarEvent,
 user : state.auth.user,
})
const mapDispatchToProps = (dispatch) => ({
  updateCalendarEventsData:(data) => dispatch(updateCalendarEvents(data)),
 openshowModal:(data) => dispatch(CalendarActions.UpdateOpenModal(data)),
    closemodal:(data) => dispatch(CalendarActions.updateCloseModal(data)),
    
});

export default connect(mapStateToProps,mapDispatchToProps) (UpdateCalendarModal)