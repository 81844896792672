import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { Button, Modal } from 'react-bootstrap'
import axios from "axios";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import "./roitable.css";

// Snackbar notification
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import html2canvas from 'html2canvas';

const AddRoiCalc = (props) => {

    const {user} = useSelector(state => state.auth)

    
    
    // useEffect(() => {
    //     setDetails(props.detail)
    // }, [props.detail])

    const [companiesList, setCompaniesList] = useState();
    const [selectedAccount, setSelecteAccount] = useState("");
    const [opportunitiesList, setOpportunitiesList] = useState([]);

    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [inputValues, setInputValues] = useState({
        inputValue1: 0,
        inputValue2: 0,
        // Add more input fields as needed
    });

    const handleExportChart = (id) => {
        const chartElement = document.getElementById("roi-form");
      
        // Create a container element
        const containerElement = document.createElement('div');
        // containerElement.style.backgroundColor = '#1F2A40';
        containerElement.style.color = '#fff';
        containerElement.style.paddingTop = '20px';
        containerElement.style.textAlign = 'center';
        containerElement.style.position = 'relative';
      
        // Create the title element
        const titleElement = document.createElement('h2');
        titleElement.textContent = calculatedValues.company_name;
      
        // Append the title and chart to the container
        containerElement.appendChild(titleElement);
        containerElement.appendChild(chartElement.parentNode.replaceChild(containerElement, chartElement));
            
        html2canvas(containerElement).then((canvas) => {
          // Convert the canvas to a Blob object
          canvas.toBlob((blob) => {
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "Roi-sheet-crm" + '.png';
      
            // Programmatically click the anchor element to trigger the file download
            link.click();
      
            // Clean up the temporary URL object
            URL.revokeObjectURL(link.href);
      
            // Restore the original chart parent
            containerElement.parentNode.replaceChild(chartElement, containerElement);
          }, 'image/png');
        });
      };

    const getAccountsList = async (value) => {
        
        setCompaniesList([]);
        const res = await axios.get("/tickets/accounts/search/" + value.target.value);
        setCompaniesList(res.data.accountsList);
        
        // setExistingCompany(false);
        
        // console.log(activity);
    }

    
    const handleSetCompanyName = async(value) => {
        setSelecteAccount(value.value)
        // /account/opportunities
        var res = await axios.post('/opportunities/account/opportunities', {account_id : value.value})

        setOpportunitiesList([])
        setOpportunitiesList(res.data.opportunities)

        

        // setCompaniesList({ ...companiesList, [e.target.name]: e.target.value }, []);
    }

    const handleSetOpportunity = async (value) => {
        
        setCalculatedValues({
            ...calculatedValues,
          ["opportunity_id"]: value.opportunity_id,
          ["lead_id"] : value.lead_id,
          ["product"] : value.product,
          ["opportunity_name"] : value.value
        })
    }

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            

            return {
                ...styles,
                // background-color: transparent;
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };

    const updateToDatabase = async() => {

        // if(selectedAccount){
        //     alert('Please select account');
        //     return;
        // }

        if(!calculatedValues.opportunity_id){
            alert("Opportunity is required, Please select");
            return;
        }

        setSnackBarMessage("Uploading Data ...!");
        setOpen(true);

        

        

        

        var object = {
            inputData : calculatedValues,
            account_id : (selectedAccount || calculatedValues.account_id)
        }

        const res = await axios.post('/user/roi-sheet/update', object)
        if(res.data.status === "success"){
            setTimeout(() => {
                setOpen(true);
                setSnackBarMessage("Successfully saved to database...!");
                // props.onHide();

            }, 2000);

            

            setSnackBarMessage("Successfully saved to database...!");
            // setContactsShow(false);

            props.refreshTargetData()
        }else{
            setTimeout(() => {
                setOpen(true);
                setSnackBarMessage("Error in saving to database.");
                props.onHide();

            }, 2000);

            setSnackBarMessage("Error in saving to database.");
            // setContactsShow(false);
        }
        
        

    }

    const [calculatedValues, setCalculatedValues] = useState({})

    useEffect(() => {
        setCalculatedValues(props.detail)
    }, [props.detail])


    // const downloadExcel = () => {
    //     // Parse the HTML table
    //     // const table = ReactHTMLParser(tableHtml)[0];

    //     const tabel = document.getElementById("")
    
    //     // Convert the HTML table to a worksheet
    //     const ws = XLSX.utils.table_to_sheet(table);
    
    //     // Create a workbook with the worksheet
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    //     // Generate a blob containing the Excel data
    //     const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });
    
    //     // Create a download link and trigger the download
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'table.xlsx';
    //     a.click();
    //     URL.revokeObjectURL(url);
    // };

    

    useEffect(() => {
    // Perform your calculations whenever any input field changes
    
    var total_prototype_cost = inputValues.no_of_prototype * inputValues.cost_per_prototype
    var total_testing_cost = inputValues.testing_cost * (inputValues.no_of_prototype - 1)
    var prototype_cost_for_design_changes = inputValues.cost_to_new_design * (inputValues.no_of_prototype - 1)
    var cost_to_validate_design = total_prototype_cost + total_testing_cost + prototype_cost_for_design_changes
    var total_cost_to_validate_product_1styear = cost_to_validate_design * inputValues.no_of_products_per_year
    var total_cost_to_validate_product_2ndyear = total_cost_to_validate_product_1styear * 1.05
    var total_cost_to_validate_product_3rdyear = total_cost_to_validate_product_2ndyear * 1.05
    var total_cost_to_validate_product_cumulative =  parseInt(total_cost_to_validate_product_1styear) + parseFloat(total_cost_to_validate_product_2ndyear) + parseFloat(total_cost_to_validate_product_3rdyear)

    var perpetual_cost_mechanical_2ndyear =  inputValues.perpetual_cost_mechanical * (inputValues.mechanical_premium_type === "annual_lease" ? 1.05  :  0.2)
    var perpetual_cost_mechanical_3rdyear = perpetual_cost_mechanical_2ndyear * (inputValues.mechanical_premium_type === "annual_lease" ? 1.05 : 0.2)
    var perpetual_cost_mechanical_cumulative = parseInt(inputValues.perpetual_cost_mechanical) + parseInt(perpetual_cost_mechanical_2ndyear) + parseInt(perpetual_cost_mechanical_3rdyear)

    var annual_cost_of_manpower_2ndyear = inputValues.annual_cost_of_manpower * 1.1
    var annual_cost_of_manpower_3rdyear = annual_cost_of_manpower_2ndyear * 1.1
    var annual_cost_of_manpower_cumulative = parseInt(inputValues.annual_cost_of_manpower) + parseFloat(annual_cost_of_manpower_2ndyear) + parseFloat(annual_cost_of_manpower_3rdyear)

    var total_duration_for_physical_testing = parseInt(inputValues.duration_of_physical_testing) + parseInt(inputValues.duration_to_make_design_changes * (inputValues.no_of_prototype_product - 1)) + parseInt(inputValues.duration_build_new_prototype * (inputValues.no_of_prototype_product - 1))

    var total_duration_for_virtual_testing = parseInt(inputValues.duration_to_virtual_simulation_iterations) + parseInt(inputValues.duration_to_cad_design * (inputValues.iterations - 1)) + parseInt(inputValues.duration_to_virtual_simulation * (inputValues.iterations -1))

    var total_savings_intime_ansys_implementation = total_duration_for_physical_testing - total_duration_for_virtual_testing

    var total_savings_intime_ansys_implementation_all = total_savings_intime_ansys_implementation * inputValues.no_of_products_per_year

    var total_implementation_cost_cumulative = parseInt(perpetual_cost_mechanical_cumulative) + parseInt(annual_cost_of_manpower_cumulative) + parseInt(inputValues.hardware_cost)
    var total_direct_savings_in_money = total_cost_to_validate_product_cumulative - total_implementation_cost_cumulative
    
    var total_direct_savings_in_time = total_savings_intime_ansys_implementation_all * 3

    var direct_savings_to_customer_1styear = parseInt(calculatedValues.total_cost_to_validate_product_1styear - calculatedValues.total_implementation_cost_1styear)

    var direct_savings_to_customer_2ndyear = parseInt(calculatedValues.total_cost_to_validate_product_2ndyear - calculatedValues.total_implementation_cost_2ndyear)

    var direct_savings_to_customer_3rdyear = parseInt(calculatedValues.total_cost_to_validate_product_3rdyear - calculatedValues.total_implementation_cost_3rdyear)
    
    var direct_savings_to_customer_4thyear = parseInt(calculatedValues.total_cost_to_validate_product_cumulative) - parseInt(calculatedValues.total_implementation_cost_cumulative)
    
    var roi_first_year = Math.ceil(((calculatedValues.total_cost_to_validate_product_1styear - calculatedValues.total_implementation_cost_1styear)/calculatedValues.total_implementation_cost_1styear) * 100)
    var roi_second_year = Math.ceil(((calculatedValues.total_cost_to_validate_product_2ndyear - calculatedValues.total_implementation_cost_2ndyear)/calculatedValues.total_implementation_cost_2ndyear) * 100)
    var roi_third_year = Math.ceil(((calculatedValues.total_cost_to_validate_product_3rdyear - calculatedValues.total_implementation_cost_3rdyear)/calculatedValues.total_implementation_cost_3rdyear) * 100)
    var roi_fourth_year = Math.ceil(((calculatedValues.total_cost_to_validate_product_cumulative - calculatedValues.total_implementation_cost_cumulative)/calculatedValues.total_implementation_cost_cumulative) * 100)

    setCalculatedValues({
        ...calculatedValues,
        ["total_prototype_cost"]: total_prototype_cost,
        ["total_testing_cost"] : total_testing_cost,
        ["prototype_cost_for_design_changes"] : prototype_cost_for_design_changes,
        ["cost_to_validate_design"] : cost_to_validate_design,
        ["total_cost_to_validate_product_1styear"] : total_cost_to_validate_product_1styear,
        ["total_cost_to_validate_product_2ndyear"] : total_cost_to_validate_product_2ndyear,
        ["total_cost_to_validate_product_3rdyear"] : total_cost_to_validate_product_3rdyear,
        ['total_cost_to_validate_product_cumulative'] : total_cost_to_validate_product_cumulative,

        ["perpetual_cost_mechanical_2ndyear"] : perpetual_cost_mechanical_2ndyear,
        ["perpetual_cost_mechanical_3rdyear"] : perpetual_cost_mechanical_3rdyear,
        ["perpetual_cost_mechanical_cumulative"] : perpetual_cost_mechanical_cumulative,

        ["annual_cost_of_manpower_2ndyear"] : annual_cost_of_manpower_2ndyear,
        ["annual_cost_of_manpower_3rdyear"] : annual_cost_of_manpower_3rdyear,
        ["annual_cost_of_manpower_cumulative"] : annual_cost_of_manpower_cumulative,

        ["total_implementation_cost_1styear"] : parseInt(inputValues.perpetual_cost_mechanical) + parseInt(inputValues.annual_cost_of_manpower) + parseInt(inputValues.hardware_cost),
        ["total_implementation_cost_2ndyear"] : parseInt(perpetual_cost_mechanical_2ndyear + annual_cost_of_manpower_2ndyear),
        ["total_implementation_cost_3rdyear"] : parseInt(perpetual_cost_mechanical_3rdyear + annual_cost_of_manpower_3rdyear),
        ["total_implementation_cost_cumulative"] : total_implementation_cost_cumulative,

        ["total_duration_for_physical_testing"] : total_duration_for_physical_testing,
        ["total_duration_for_virtual_testing"] : total_duration_for_virtual_testing,

        ["total_savings_intime_ansys_implementation"] : total_savings_intime_ansys_implementation,

        ["total_savings_intime_ansys_implementation_all"] : total_savings_intime_ansys_implementation_all,

        ["total_direct_savings_in_money"] : total_direct_savings_in_money,

        ["total_direct_savings_in_time"] : total_direct_savings_in_time,

        ["direct_savings_to_customer_1styear"] : direct_savings_to_customer_1styear,

        ["direct_savings_to_customer_2ndyear"] : direct_savings_to_customer_2ndyear,

        ["direct_savings_to_customer_3rdyear"] : direct_savings_to_customer_3rdyear,

        ["direct_savings_to_customer_4thyear"] : direct_savings_to_customer_4thyear,

        ["roi_first_year"] : roi_first_year,
        ["roi_second_year"] : roi_second_year,
        ["roi_third_year"] : roi_third_year,
        ["roi_fourth_year"] : roi_fourth_year
    });
    
  }, [inputValues]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues({
          ...inputValues,
          [name]: value,
        });
        setCalculatedValues({
            ...calculatedValues,
          [name]: value,
        })
    };


    return (
        <>
        {/* <Navbar value="setTargets" className="activity_list_navbar" /> */}
        <div className="main_demo_section d-flex flex-column">
          <div className="request_teche my-3">
            {/* {permissions.includes("canCreateCaseStudies") ?  */}
            
            {/* : false} */}
          </div>
          {/* onSubmit={setTargetHandleSubmit(fechData)} */}
            {/* <span></span> */}
            <span  className='btn btn-sm btn-primary' style={{cursor:"pointer", width:'100px'}} onClick={() => handleExportChart("roi-sheet-kaizenforce", "ROI-sheet-kaizenforce")} >Download</span>
            {/* <button onClick={downloadExcel}>Download as Excel</button> */}
            <div className="container-fluid" id="roi-form">
                <div className='row'>

                    {!calculatedValues._id ? 
                    <div className="col-md-6 comapany_layout">
                        <div style={{width: '80%'}}>
                        <Select
                            closeMenuOnSelect={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable="true"
                            options={companiesList}
                            placeholder="Account"
                            styles={colourStyles}
                            onChange={handleSetCompanyName}
                            // onChange = {(value) => setSelecteAccount(value.value)}
                            onKeyDown={getAccountsList}
                        // onNewOptionClick={onNewOptionClick}
                        ></Select>
                        </div>
                        <br />
                        <div style={{width: '80%'}}>
                            <Select
                                closeMenuOnSelect={true}
                                className="basic-single"
                                classNamePrefix="select"
                                isSearchable="true"
                                options={opportunitiesList}
                                placeholder="Select Opportunity"
                                styles={colourStyles}
                                onChange={handleSetOpportunity}
                                // onChange = {(value) => setSelecteAccount(value.value)}
                                // onKeyDown={getAccountsList}
                            // onNewOptionClick={onNewOptionClick}
                            ></Select>

                        </div>
                        
                    </div>
                    : 
                        <>
                            <div style={{color:'#000'}} className='comapny_name_is'> Company Name : {calculatedValues.account}</div>
                            <div style={{color:'#000'}} className='comapny_name_is'> Opportunity Name : {calculatedValues.opportunity_name}</div>
                        </>
                    }

                </div>
                <br />
              <div className="row">
                <br />
                <table  id="roisheet" className='roi-table table-with-border total_direct_saving_table' style={{width:'40%', marginLeft:'2px'}}>
                {/* style={{borderCollapse: 'collapse'}} */}
                    <tr>
                        <td>ROI at the end of (3 Years)</td>
                        <td>
                        {/* total_direct_savings_in_money */}
                            <span className="text-align-right">₹{parseFloat(calculatedValues.direct_savings_to_customer_4thyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span>
                        </td>
                        <td>
                            <span className="text-align-right">{calculatedValues.roi_fourth_year}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Total Direct Savings in terms of Time(3 Years) in days </td>
                        <td><span className="text-align-right"> {calculatedValues.total_direct_savings_in_time}</span></td>
                    </tr>
                </table>
                <div  style={{marginBottom: '50px'}}></div>
                <br /><br />
            

                <table className='roi-table table-with-border ' style={{marginLeft:'2px', color:'#000'}}>
                    <tr></tr>
                    <tr style={{textAlign: 'center',background:"lightgray"}}>
                    <th colSpan="4" className="header-cell"> Product Validation Cost (Physical Testing)</th>
                    </tr>
                    <tr>
                        <th rowspan="20" className=' header-cell1234 rotate-text'> <span >ROI Calculation - Money</span></th>
                        <th className="header-cell" style={{textAlign:'center'}}>Description</th>
                        <th className="header-cell" style={{textAlign:'center'}}>Unit</th>
                        <th className="header-cell" style={{textAlign:'center'}}>Value</th>
                    </tr>
                    <tr className='common_row_bg_green'>
                        <td># of Prototype</td>
                        <td>Per Product</td>
                        <td style={{background:"yellow"}} ><input placeholder='Enter Value' type="number" className='number_input_layout' style={{background:"yellow"}} defaultValue={calculatedValues.no_of_prototype} name="no_of_prototype" onChange={handleInputChange} /></td>
                    </tr>
                    <tr className='common_row_bg_green'>
                        <td>Cost of building prototype</td>
                        <td>Per Prototype</td>
                        <td style={{background:"yellow"}}><input placeholder='Enter Value' style={{background:"yellow"}} type="number" className='number_input_layout' name="cost_per_prototype" defaultValue={calculatedValues.cost_per_prototype} onChange={handleInputChange} /></td>
                    </tr>
                    <tr className='common_row_bg_green'>
                        <td ><span className="text-align-right">Total Prototype cost</span></td>
                        <td></td>
                        {/* <td><span>{calculatedValues.total_prototype_cost}</span></td> */}
                        <td ><span className="text-align-right">₹{parseFloat(calculatedValues.total_prototype_cost).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr class="blank_row">
                      <td bgcolor="#FFFFFF" colspan="3">&nbsp;</td>
                    </tr>
                    <tr className='common_row_bg_blue'>
                        <td>Testing cost</td>
                        <td>Per Prototype</td>
                        <td style={{background:"yellow"}}><input type="number" placeholder='Enter Value' style={{background:"yellow"}} className='number_input_layout' name="testing_cost" defaultValue={calculatedValues.testing_cost} onChange={handleInputChange} /></td>
                    </tr>
                    <tr className='common_row_bg_blue'>
                        <td><span className="text-align-right">Total Testing cost</span></td>
                        <td></td>
                        <td><span className="text-align-right"> ₹{parseFloat(calculatedValues.total_testing_cost).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr className='common_row_bg_lightyellow'>
                        <td>Cost to incorporate  New Design Changes </td>
                        <td>Per Prototype</td>
                        <td style={{background:"yellow"}}><input type="number" placeholder='Enter Value' style={{background:"yellow"}} className='number_input_layout' name="cost_to_new_design" defaultValue={calculatedValues.cost_to_new_design} onChange={handleInputChange} /></td>
                    </tr>
                    <tr className='common_row_bg_lightyellow'>
                        <td><span className="text-align-right">Total Prototype cost for Design changes</span></td>
                        <td></td>
                        <td> <span className="text-align-right">₹{parseFloat(calculatedValues.prototype_cost_for_design_changes).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td>(1 physical testing cost is mandatory)</td>
                    </tr>
                    <tr>
                        <td>Total Cost spent to validate Product Design</td>
                        <td>Per Product</td>
                        <td><span  className="text-align-right">₹{parseFloat(calculatedValues.cost_to_validate_design).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr>
                        <td>#Products </td>
                        <td>Per Year</td>
                        <td style={{background:"yellow"}}><input style={{background:"yellow"}}  className="text-align-right number_input_layout" placeholder='Enter Value' type="number" name="no_of_products_per_year" defaultValue={calculatedValues.no_of_products_per_year} onChange={handleInputChange} /></td>
                        {/* <td><span>Rs. 8,47,000</span></td>
                        <td><span>Rs. 2,317,000</span></td> */}
                    </tr>
                    <tr>
                        <td  colSpan='2'><span className="text-align-right common_dark_red">Total Cost spent to validate All Products  </span></td>
                        <td><span className="text-align-right common_dark_red">₹{parseFloat(calculatedValues.total_cost_to_validate_product_1styear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span> </td>
                        <td><span className="text-align-right common_dark_red"> ₹{parseFloat(calculatedValues.total_cost_to_validate_product_2ndyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})} </span> </td>
                        <td><span className="text-align-right common_dark_red"> ₹{parseFloat(calculatedValues.total_cost_to_validate_product_3rdyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span>  </td>
                        <td><span className="text-align-right common_dark_red"> ₹{parseFloat(calculatedValues.total_cost_to_validate_product_cumulative).toLocaleString('en-IN', {maximumFractionDigits: 2,})} </span> </td>
                    </tr>
                    <tr style={{background:"lightgray"}}>
                        <td colSpan='2' style={{textAlign: 'center'}}>Ansys implementation Cost (Virtual Testing)</td>
                        <td>1st Year</td>
                        <td style={{width:'300px'}}>2nd Year</td>
                        <td>3rd Year</td>
                        <td>Cumulative</td>
                    </tr>
                    <tr>
                        <td>
                            <select name="mechanical_premium_type"  onChange={handleInputChange}>
                                <option value="perpetual" selected={calculatedValues.mechanical_premium_type === "perpetual"}>Perpetual Cost of Ansys Product</option>
                                <option value="annual_lease" selected={calculatedValues.mechanical_premium_type === "annual_lease"}>Annual Cost of Ansys Product</option>
                            </select>
                        </td>
                        <td><span className="text-align-right">1</span></td>
                        
                        <td style={{background:"yellow"}}><input type="number" placeholder='Enter Value' style={{background:"yellow"}} className="text-align-right number_input_layout" defaultValue={calculatedValues.perpetual_cost_mechanical}  name="perpetual_cost_mechanical" onChange={handleInputChange}  /></td>
                        <td style={{width:'300px'}}><span className="text-align-right">₹{parseFloat(calculatedValues.perpetual_cost_mechanical_2ndyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.perpetual_cost_mechanical_3rdyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.perpetual_cost_mechanical_cumulative).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr>
                        <td>Annual Cost of Man Power</td>
                        <td><span className="text-align-right">1</span></td>
                        <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout' type="number" defaultValue={calculatedValues.annual_cost_of_manpower} name="annual_cost_of_manpower" onChange={handleInputChange} /></td>
                        <td style={{width:'300px'}}><span className="text-align-right">₹{parseFloat(calculatedValues.annual_cost_of_manpower_2ndyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.annual_cost_of_manpower_3rdyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.annual_cost_of_manpower_cumulative).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    
                    <tr>
                        <td>Hardware Cost</td>
                        <td><span className="text-align-right">1</span></td>
                        <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout'  type="number" name="hardware_cost" defaultValue={calculatedValues.hardware_cost} onChange={handleInputChange} /></td>
                        <td style={{width:'300px'}}><span className="text-align-right">₹ -</span></td>
                        <td><span className="text-align-right">₹ -</span></td>
                        <td><span className="text-align-right">₹ {calculatedValues.hardware_cost}</span></td>
                    </tr>
                    <tr>
                        <td colSpan='2'><span className="text-align-right common_dark_red" >Total implementation Cost</span></td>
                        <td><span className="text-align-right common_dark_red">₹{parseFloat(calculatedValues.total_implementation_cost_1styear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td style={{width:'300px'}}><span className="text-align-right common_dark_red">₹{parseFloat(calculatedValues.total_implementation_cost_2ndyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right common_dark_red">₹{parseFloat(calculatedValues.total_implementation_cost_3rdyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right common_dark_red">₹{parseFloat(calculatedValues.total_implementation_cost_cumulative).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr className='common_row_bg_green'>
                        <td colSpan='2'> <span className="text-align-right">Direct Savings to Customer</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.direct_savings_to_customer_1styear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td style={{width:'300px'}}><span className="text-align-right">₹{parseFloat(calculatedValues.direct_savings_to_customer_2ndyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.direct_savings_to_customer_3rdyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                        <td><span className="text-align-right">₹{parseFloat(calculatedValues.direct_savings_to_customer_4thyear).toLocaleString('en-IN', {maximumFractionDigits: 2,})}</span></td>
                    </tr>
                    <tr className='common_row_bg_green'>
                        <td colSpan='2'> <span className="text-align-right">ROI</span></td>
                        <td> <span className="text-align-right">{calculatedValues.roi_first_year}% </span></td>
                        <td style={{width:'300px'}}> <span className="text-align-right">{calculatedValues.roi_second_year}% </span></td>
                        <td> <span className="text-align-right">{calculatedValues.roi_third_year}% </span></td>
                        <td> <span className="text-align-right">{calculatedValues.roi_fourth_year}% </span></td>
                    </tr>
                    <tr></tr>
                    
                </table>
             
                <div style={{marginBottom: '70px'}}></div>

                <table className="roi-table table-with-border " style={{width: '70%', marginLeft: '2px', color:'#000'}}>
                    <tbody>
                        <tr style={{textAlign: 'center',background:"lightgray"}}>
                             <th colSpan="4" className="header-cell">   Duration of Physical Testing</th>
                          
                        </tr>
                        <tr>
                            <th  rowspan="20" className='rotate-text header-cell1234'>ROI Calculation - Time</th>
                            <td style={{textAlign: 'center', background:"lightgray"}}>Description</td>
                            <td style={{textAlign: 'center', background:"lightgray"}}>Unit</td>
                            <td style={{textAlign: 'center', background:"lightgray"}}>Value</td>
                        </tr>
                        <tr className='common_gary_bg'>
                            <td><span># of Prototype</span></td>
                            <td>Per Product</td>
                            <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout' type="number" name="no_of_prototype_product" defaultValue={calculatedValues.no_of_prototype_product} onChange={handleInputChange}  /></td>
                        </tr>
                        <tr className='common_gary_bg'> 
                            <td>Duration to perform Physical Testing</td>
                            <td>Days</td>
                            <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_of_physical_testing" defaultValue={calculatedValues.duration_of_physical_testing} onChange={handleInputChange} /></td>
                        </tr>
                        <tr className='common_gary_bg'>
                            <td>Duration to make Design changes</td>
                            <td>Days</td>
                            <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_to_make_design_changes" defaultValue={calculatedValues.duration_to_make_design_changes} onChange={handleInputChange} /></td>
                        </tr>
                        <tr className='common_gary_bg'>
                            <td>Duration to build new prototype as per new design</td>
                            <td>Days</td>
                            <td style={{background:"yellow"}}><input style={{background:"yellow"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_build_new_prototype" defaultValue={calculatedValues.duration_build_new_prototype} onChange={handleInputChange} /></td>
                        </tr>
                        <tr>
                            <td><span className="text-align-right common_dark_red" >Total Duration for Physical Testings</span></td>
                            <td className='common_dark_red'>Days</td>
                            <td > <span className="text-align-right common_dark_red">{calculatedValues.total_duration_for_physical_testing} </span> </td>
                        </tr>
                          <tr className='common_bg_orange' style={{textAlign:'center'}}>
                            <th colSpan={3} style={{fontWeight:"900"}}> Virtual Testing</th>
                          </tr>
                        <tr className='common_bg_orange' >
                            <td style={{textAlign:"center"}}>Description</td>
                            <td style={{textAlign:"center"}}>Unit</td>
                            <td style={{textAlign:"center"}}>Value</td>
                        </tr>
                        <tr className='common_bg_orange' >
                            <td>Duration to perform Virtual Simulation - per Iterations</td>
                            <td>Days</td>
                            <td style={{background:"#FAC898"}}><input style={{background:"#FAC898"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_to_virtual_simulation_iterations" defaultValue={calculatedValues.duration_to_virtual_simulation_iterations} onChange={handleInputChange} /></td>
                        </tr>
                        <tr className='common_bg_orange' >
                            <td>Duration to implement Design changes - CAD Design</td>
                            <td>Days</td>
                            <td style={{background:"#FAC898"}}><input style={{background:"#FAC898"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_to_cad_design" defaultValue={calculatedValues.duration_to_cad_design} onChange={handleInputChange} /></td>
                        </tr>
                        <tr className='common_bg_orange' >
                            <td>Duration to perform Virtual Simulation on new Design</td>
                            <td>Days</td>
                            <td style={{background:"#FAC898"}}><input style={{background:"#FAC898"}} placeholder='Enter Value' className='number_input_layout' type="number" name="duration_to_virtual_simulation" defaultValue={calculatedValues.duration_to_virtual_simulation} onChange={handleInputChange} /></td>
                        </tr>
                        <tr className='common_bg_orange' >
                            <td>#Iterations</td>
                            <td>Units</td>
                            <td style={{background:"#FAC898"}}><input type="number" style={{background:"#FAC898"}} placeholder='Enter Value' name="iterations" className='number_input_layout'  defaultValue={calculatedValues.iterations} onChange={handleInputChange} /></td>
                        </tr>
                        <tr>
                            <td><span className="text-align-right common_dark_red">Total Duration for Virtual Testings</span></td>
                            <td className='common_dark_red'>Days</td>
                            <td><span className='text-align-right common_dark_red'>{calculatedValues.total_duration_for_virtual_testing}</span></td>
                        </tr>
                        <tr className='common_row_bg_green'>
                            <td>Total Savings in time due to Ansys implementation(per product)</td>
                            <td>Days</td>
                            <td><span className='text-align-right'>{calculatedValues.total_savings_intime_ansys_implementation}</span></td>
                        </tr>
                        <tr className='common_row_bg_green'>
                            <td>Total Savings in time due to Ansys implementation(all product)</td>
                            <td>Days</td>
                            <td> <span className='text-align-right'>{calculatedValues.total_savings_intime_ansys_implementation_all} </span> </td>
                            {!calculatedValues.account_id ? <td>
                                <span className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" onClick={updateToDatabase} >Save to Database</span>
                            </td> : null}
                        </tr>
                    </tbody>
                </table>
                
              </div>
            </div>
  
          
          <br />
        </div>


        <Snackbar
            severity="success"

            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={snackBarMessage}
            action={
                <React.Fragment>
                    <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                    </ButtonMaterialUi>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />

      </>
    )
}

export default AddRoiCalc;