import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import MenuItem from "@material-ui/core/MenuItem";
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';
import { components } from "react-select";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import {createdata,gettechieslist} from "../../store/Webinar"
import { useDispatch, useSelector } from 'react-redux';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const CreateWebinarModal = (props) => {
  console.log("props values are", props)
  const techieslist = useSelector((state)=> state.Webinarslist.techielists)
  const dispatch = useDispatch();
    var today = new Date();
      var twodays = moment().add(2, 'days');
    const end_time = useRef();
    const start_time = useRef();
    const task_date = useRef();
  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();

  const [filterAccountManager, setFilterAccountManager] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);

  const [filterPersonsInvloved, setFilterPersonsInvloved] = useState([]);
  const [personsInvloved, setPersonsInvloved] = useState([]);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const[typeList,setTypeList]=useState([]);
const formatWeekSelectLabel = (date, invalidLabel) => {
  return `${moment(date).format("MMM Do YYYY")}`
};
  const [webinarData, setWebinarData] = useState({
    title:"",
    task_date : moment(new Date()).format('YYYY-MM-DD'),
    task_start_time: new Date(),
    task_end_time: new Date(),
    speakers:"",
    persons_invloved:"",
    type:"",
    agenda:"",
    description: "",


  });

  const { register: webinarRegister, handleSubmit: webinarHandleSubmit, setError: webinarSetError, reset: reset, formState: { errors: webinarErrors } } = useForm();
  const {user} = useSelector(state => state.auth)

  const userData = user.user;
  const userDataId= userData.user_role_id._id
  const permissions = user.permissions;
const getTypeList = () => {
  setTypeList([{ label: "Seminar", value: 'Seminar' }, { label: "Webinar", value: 'Webinar' }])
}


const getManagers = async () => {
  // dispatch(gettechieslist())

  const res = await axios.get("/user/techies-list");
  setAccountManagers(res.data.techiesList);
}

const getInvolvedPersons = async () => {
  const res = await axios.get("/user/team-members-list");
  setPersonsInvloved(res.data.list);
}
  useEffect(() => {
    getManagers();
    getInvolvedPersons();
    getTypeList();
  }, [])

  const onInputChange = (e) => {
    setWebinarData({ ...webinarData, [e.target.name]: e.target.value }, []);
  };

  const addNewWebinar = async (e) => {
    setDisable(true);
 

    setTimeout(() => { }, 500);
    var account_manager = filterAccountManager.map(item => {
      return item.value;
    });

    var persons_invloved_values = filterPersonsInvloved.map(item => {
      return item.value;
    })
    var newStartTime = moment(webinarData.task_start_time).format('HH:mm:ss')
    var newEndTime =  moment(webinarData.task_end_time).format('HH:mm:ss')
    const updatedData = {
        title:webinarData.title,
        speakers: account_manager,
        persons_involved:persons_invloved_values,
        type:webinarData.type,
        start_date:moment(webinarData.task_date + " " + newStartTime+"Z").toISOString(),
        end_date:moment(webinarData.task_date + " " + newEndTime+"Z").toISOString(),
        agenda:webinarData.agenda,
        description:webinarData.description,
    }
    const filtersDates = {
      from_date: moment().startOf('month').format('YYYY-MM-DD').toString(),
      to_date: moment().endOf('month').format('YYYY-MM-DD').toString()
    }
    dispatch(createdata(updatedData,filtersDates));
  props.onHide();
    props.refreshWebinarData();


    reset({
      keepErrors: false,
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    })



    setDisable(true);

  }


  useEffect(() => {
    setCompaniesList(webinarData.account_id);
  }, [])

  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    webinarData.new_date_time = newDate;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };



  const returnValue = async (contactId) => {
    webinarData.contact_id = contactId;
  }

  const removeFilterUserListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    
    const filterAccountManagers = filterAccountManager.filter(item => item.value !== inputValue);
    setFilterAccountManager(filterAccountManagers);
  }


  const removeFilterUserListBadge1 = (value) => {
    var inputValue = value.target.getAttribute('value');
    
    const filterPersonsInvloveds = filterPersonsInvloved.filter(item => item.value !== inputValue);
    
    setFilterPersonsInvloved(filterPersonsInvloveds);
  }

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Create Event"}
          </Modal.Title>
          <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={webinarHandleSubmit(addNewWebinar)}>
            <div className='row'>
            <div className='col-md-6'>

      <TextField variant="outlined"
         {...webinarRegister("type", {
         })}
         InputLabelProps={{
           shrink: true,
         }}
         value={webinarData.type}
         select
         helperText={webinarErrors.type ? webinarErrors.type.message : ''}
         error={Boolean(webinarErrors.type)}
         onChange={e => onInputChange(e)}
         required
         type="text" className="form-control" name="type" label="Type"
       >
         {typeList.map((option) => (
           <MenuItem key={option.value} value={option.value}>
             {option.label}
           </MenuItem>
         ))
         }
       </TextField>

</div>
            </div>
            <br/>
            <div class="row">
              <div class="col-md-6">
              <TextField variant="outlined"
                  {...webinarRegister("title", {
                    required: {
                      value: true,
                      message: "title is required"
                    },
                    minLength: {
                      value: 2,
                      message: "title must be at least 5 characters"
                    }

                  })}
                  required
                  helperText={webinarErrors.title ? webinarErrors.title.message : ''}
                  error={Boolean(webinarErrors.title)}
                  type="text" className="form-control" value={webinarData.title} name="title" label="Title" onChange={e => onInputChange(e)} />
              </div>
              <div className="col-md-6">

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            className="form-control"
                                            label="Webinar Date"
                                            inputVariant="outlined"
                                            value={webinarData.task_date}
                                            onChange={(date) => setWebinarData({...webinarData, task_date: moment(date).format('YYYY-MM-DD') })}
                                            showTodayButton
                                            labelFunc={formatWeekSelectLabel}
                                            inputRef={task_date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                       </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
            
            <div className="row">

              <div class="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                      className="form-control"
                      label="Start Time"
                      inputVariant="outlined"
                      value={webinarData.task_start_time}
                      onChange={(date) => setWebinarData({...webinarData, task_start_time: date })}
                      showTodayButton
                      inputRef={start_time}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
              </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                          className="form-control"
                          label="End time"
                          inputVariant="outlined"
                          value={webinarData.task_end_time}
                          onChange={(time) => setWebinarData({...webinarData, task_end_time: time })}
                          showTodayButton
                          inputRef={end_time}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </MuiPickersUtilsProvider>
                </div>
            </div>
            <br />
            <div className="row">
              <div class="col-md-6">
                <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterAccountManager}
                        options={accountManagers}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}
                        placeholder="Speakers"
                        name="accountManagers"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterAccountManager(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />

              </div>
         <div className='col-md-6'>
         <div>
              {
              filterAccountManager.length > 0 ?
                filterAccountManager.map((filter) => (
                  <Badge onClick={removeFilterUserListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
            </div>
         </div>
 
       </div>
       <br/>
       <div className="row">
              <div class="col-md-6">
                <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterPersonsInvloved}
                        options={personsInvloved}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}
                        placeholder="Persons Involved"
                        name="personsInvloved"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterPersonsInvloved(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
              </div>
              <br/>
              <div class="col-md-6">
              <div>
              {
              filterPersonsInvloved.length > 0 ?
                filterPersonsInvloved.map((filter) => (
                  <Badge onClick={removeFilterUserListBadge1}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
            </div>
              </div>
       </div>
    <br/>
              <div className='row'>
                <div className='col-md-6'>
                <TextField variant="outlined"
                  multiline row={2}
                  {...webinarRegister("description", {
                  })}
                  helperText={webinarErrors.description ? webinarErrors.description.message : ''}
                  error={Boolean(webinarErrors.description)}
                  required
                  type="text" className="form-control" value={webinarData.description} name="description" label="Description" onChange={e => onInputChange(e)} />
                </div>

                <div className='col-md-6'>
                <TextField variant="outlined"
                  multiline row={2}
                  {...webinarRegister("agenda", {
                  })}
                  helperText={webinarErrors.agenda ? webinarErrors.agenda.message : ''}
                  required
                  error={Boolean(webinarErrors.agenda)}
                  type="text" className="form-control" value={webinarData.agenda} name="agenda" label="Agenda" onChange={e => onInputChange(e)} />
                </div>
              </div>
        
            <br />
            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>


    </>
  )

}
export default CreateWebinarModal;