import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { FiClock } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PostPoneModal from "../pageModals/PostPoneModal";
import Tooltip from '@mui/material/Tooltip';

import {connect}  from "react-redux";
import {getFollowUpsList, updateTaskFollowup} from "../../store/dashboard";

const TasksList = React.memo(({cardElementsLoader, user, followUpsList, opportunitiesTasksList, getFollowUpsListData, updateTaskFollowupData }) => {

    // const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const [showFollowUps, setShowFollowUps] = useState(true);

    console.log("Tasks List Rendering")

    const [showPostPoneModal, setShowPostPoneModal] = useState(false);
    const [TasksDate, setTasksDate] = useState(moment().format('YYYY-MM-DD'));

    const [followUpId, setFollowUpId] = useState(null);
    const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY")}`
      };

    const handleActionDateChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD');
        setTasksDate(newDate);
    }

    useMemo(() => {
        getFollowUpsListData({
            user_id: userData._id,
            task_date: TasksDate
        });
    }, [TasksDate]);

    const completedFollowup = async (event, task_id) => {
        event.stopPropagation();
        var updatedData = {
            'task_status': "completed",
            'followup_id': task_id,
        }
        updateTaskFollowupData(updatedData)
        getFollowUpsListData({user_id: userData._id, task_date: TasksDate})
        
    }

    const setFollowUpIdFn = (followupId) => {
        setFollowUpId(followupId);
        setShowPostPoneModal(true);
    }

    return (
        <>
        {cardElementsLoader ? 

            <div className="parent-div">
            <div className="loader"></div>
          </div>
          
         :
        <>
            <div className="container-fluid py-2 px-2">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mx-auto">
                        <div className="row">

                            <div className="col-md-3 col-lg-3">
                                <button onClick={() => setShowFollowUps(true)} className={showFollowUps === true ? " btn_leads_task" : "btn_leads_task11"}>Followups</button>
                            </div>
                            <div className="col-md-5 col-lg-5 mt-2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        style={{ height: '30px' }}
                                        className="datepicker"
                                        label="Select Date"
                                        inputVariant="outlined"
                                        value={TasksDate}
                                        onChange={handleActionDateChange}
                                        labelFunc={formatWeekSelectLabel}
                                        showTodayButton
                                        disablePast
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-2 col-lg-2">
                                <button onClick={() => setShowFollowUps(false)} className={showFollowUps === false ? " btn_leads_task " : "btn_leads_task11"}>Reminders</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" id="style-10" style={{ minHeight: '440px', maxHeight: '440px', padding: '15px', overflowY: 'auto' }}>

                {
                    showFollowUps ?
                        <>
                            {
                                (followUpsList && followUpsList.length > 0) ?
                                followUpsList.map((task, index) => {
                                        return (
                                            <div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span className="task_title_main">{index + 1}. {task?.user_id?.first_name} -  {(task?.lead_id ? task?.lead_id?.company_name : "null").replace(/\b(\w)/g, s => s.toUpperCase())}
                                                </span>
                                                <div className="icons_postponed_completed" style={{ display: 'flex', flexDirection: 'row' }}>
                                                </div>
                                                <span className="common_fontfamily" style={{ fontSize: '14px', }}>

                                                    <Tooltip title="Mark as completed" arrow >
                                                        <a className="" style={{ cursor: 'pointer' }} onClick={(e) => completedFollowup(e, task._id)} >
                                                            <AiOutlineCheckCircle size={20} className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    <Tooltip title="Postpone task" arrow >
                                                        <a className="" style={{ cursor: 'pointer' }} onClick={() => setFollowUpIdFn(task._id)} >
                                                            <FiClock data-for='toolTip1' size={19} className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    Task - {task.task_name}</span>
                                                <span className="task_description">Desc. - {task.task_description}</span>
                                                <span className="task_description">Reminder -  {moment(task.reminder_date).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}, Action -  {moment(task.action_date).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}</span>
                                                {(task.postponed_date && task.postponed_date != null) ? <span className="task_description">Postponed Date - {moment(task.postponed_date).format('LLL')} </span> : ""}
                                                <br />
                                                <hr />
                                            </div>

                                        )
                                    })
                                    : "No Follow Up Tasks For Today"
                            }

                        </>
                        :
                        <>
                            {
                                (opportunitiesTasksList && opportunitiesTasksList.length > 0) ?
                                opportunitiesTasksList.map((task, index) => {
                                        return (
                                            <div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
                                                <h5 className="task_title_main">{index + 1}.  {task.user_id.first_name}  -   {task.opportunity_id ? task.opportunity_id.company_name : ""}</h5>
                                                <span className="common_fontfamily" style={{ fontSize: '14px', }}>

                                                    <Tooltip title="Mark as completed" arrow >
                                                        <a className="" style={{ cursor: 'pointer' }} onClick={(e) => completedFollowup(e, task._id)} >
                                                            <AiOutlineCheckCircle size={20} className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    <Tooltip title="Postpone task" arrow >
                                                        <a className="" style={{ cursor: 'pointer' }} onClick={() => setFollowUpIdFn(task._id)} >
                                                            <FiClock size={19} className="followup_icons" />
                                                        </a>
                                                    </Tooltip>
                                                    &nbsp;
                                                    Task - {task.task_name}</span>
                                                <span className="task_description">Desc - {task.task_description}</span>
                                                <span className="task_description">Reminder -  {moment(task.reminder_date).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}, Action -  {moment(task.action_date).tz("Asia/Kolkata").format('ddd Do MMM YYYY')}</span>
                                                <br />
                                                <hr />
                                            </div>
                                        )
                                    })
                                    : "No Tasks for Today"
                            }
                        </>

                }
            </div>
            
            {showPostPoneModal ? <PostPoneModal show={showPostPoneModal} folloupId={followUpId} onHide={() => setShowPostPoneModal(false)} /> : ""}
            </>
                                }
        </>
    );
});
// export default TasksList;

const mapStateToProps = (state) => ({
    followUpsList: state.dashboard.followUpsList,
    opportunitiesTasksList: state.dashboard.opportunitiesTasksList,
    user : state.auth.user,
    cardElementsLoader : state.dashboard.cardElementsLoader
});

const mapDispatchToProps = (dispatch) => ({
    getFollowUpsListData: (data) => dispatch(getFollowUpsList(data)),
    updateTaskFollowupData: (data) => dispatch(updateTaskFollowup(data))
});

// export default InstaReport;
export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
