import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useDispatch, useSelector } from 'react-redux';
import {rejectsubmitlist,LeaverequestActions} from "../../store/Leaverequest"

  const LeaveRequestReject = React.memo (({rejectsubmitdata,rejectLeaveRejectId,openshowModal,closemodal}) => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [open, setOpen] = React.useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [updated, setUpdated] = useState(false);
  const { register: rejectRegister, handleSubmit: rejectHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const rejectSubmit = async (data) => {
    setDisable(true);

    var updatedData = {
        'reject_reason': data.reject_reason,
        'approval_status': "rejected",
        'leave_request_id': rejectLeaveRejectId,
      }
      rejectsubmitdata(updatedData)

      
    //   try {
    //     const res = await axios.post("/user/hr-activities/leave-request/update", updatedData);

    //     if(res.data.status == "success"){
    //       setOpen(() => { return true})
    //       setsnackBarMessage("Lead Approval Rejected");
    //       await setTimeout(() => {
    //         setOpen(true)
    //         props.onHide();
    //       }, 1000)
          
          
    //     }else{
    //       handleClick();
    //       setsnackBarMessage(res.data.msg);
    //       setUpdated(!updated);
    //     }
    //     setDisable(true);
    // } catch (err) {
    //     setDisable(false);

    // }
  }


 
  return (
    <>

      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {"Reject Leave Request"}
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={rejectHandleSubmit(rejectSubmit)}>
            <div className="row headerBand">
              <label>Reject Reason</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">
                <TextField variant="outlined"
                  required
                  {...rejectRegister("reject_reason", {
                    minLength: {
                      value: 3,
                      message: "Reject Reason must be at least 3 characters"
                    }

                  })}
                  multiline rows={4}
                  helperText={rejectErrors.reject_reason ? rejectErrors.reject_reason.message : ''}
                  error={Boolean(rejectErrors.reject_reason)}
                  type="text" className="form-control"
                  name="reject_reason"
                  label="Reject Reasons" />
              </div>

            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  isFetching : state.LeaveRequestlists.isFetching, 
  rejectLeaveRejectId : state.LeaveRequestlists.RejectLeaveRejectId,

});

const mapDispatchToProps = (dispatch) => ({

  rejectsubmitdata: (data) => dispatch(rejectsubmitlist(data)),
  openshowModal:(data) => dispatch(LeaverequestActions.Reject_openModal(data)),
  closemodal:(data) => dispatch(LeaverequestActions.Reject_closeModal(data)),


});
export default connect(mapStateToProps,mapDispatchToProps)(LeaveRequestReject);


