import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import { useSelector,useDispatch, connect } from 'react-redux';
import {addnewclientlist} from "../../store/Ticketlist"

const AddNewClient = React.memo ((props) => {
  
    const [isRequired, setRequired] = useState(props.disableRequired);
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();

    const [modalHide, setModalHide] = useState("showopportunitiesmodal");

    const [disable, setDisable] = React.useState(false);

    const co_email = useRef();
    const co_contact_number = useRef();
    const co_designation = useRef();
    const co_department = useRef();
    const co_first_name = useRef();
    const co_last_name = useRef();
    const addContactsHandle = async (e) => {

        setDisable(true);

        setTimeout(() => { }, 500);

        const contactsData = {
            account: props.account_id,
            first_name: co_first_name.current.value,
            last_name: co_last_name.current.value,
            email: co_email.current.value,
            phone: co_contact_number.current.value,
            job_title: co_designation.current.value,
            department: co_department.current.value,
        };
        props?.newclients(contactsData)
        setTimeout(() => {
            props?.refreshContactslist()
        }, 500)

        contactsReset({
            keepErrors: false,
            keepDirty: true,
            keepIsSubmitted: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
        })

    }



    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop={false}
                disableBackdrop={true}
                className={modalHide}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add Client Details
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2} onSubmit={contactshandleSubmit(addContactsHandle)}>

                        <div class="row">
                            <div class="col-md-6">
                                <TextField variant="outlined" inputRef={co_first_name}
                                    {...contactsRegister("co_first_name", {
                                        required: {
                                            value: true,
                                            message: "First Name is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "First Name must be at least 3 characters"
                                        }
                                    })}

                                    helperText={contactsErrors.co_first_name ? contactsErrors.co_first_name.message : ''}
                                    error={Boolean(contactsErrors.co_first_name)}
                                    label="First Name*"
                                    type="text" className="form-control" name="co_first_name" />
                            </div>
                            <div class="col-md-6">
                                <TextField variant="outlined" inputRef={co_last_name}
                                    {...contactsRegister("co_last_name", {
                                        required: {
                                            value: true,
                                            message: "Last Name is required"
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Last Name must be at least 3 characters"
                                        }
                                    })}

                                    helperText={contactsErrors.co_last_name ? contactsErrors.co_last_name.message : ''}
                                    error={Boolean(contactsErrors.co_last_name)}
                                    label="Last Name*"
                                    type="text" className="form-control" name="co_last_name" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField variant="outlined" inputRef={co_email}
                                    {...isRequired !== false &&
                                    {
                                        ...contactsRegister("co_email", {
                                            required: {
                                                value: { isRequired },
                                                message: "Email is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Email must be at least 3 characters"
                                            }
                                        })
                                    }

                                    }

                                    helperText={contactsErrors.co_email ? contactsErrors.co_email.message : ''}
                                    error={Boolean(contactsErrors.co_email)}
                                    label="Email*"
                                    type="email" className="form-control" name="co_email" />
                            </div>
                            <div class="col-md-6">
                                <TextField variant="outlined" inputRef={co_contact_number}
                                    helperText={contactsErrors.co_contact_number ? contactsErrors.co_contact_number.message : ''}
                                    error={Boolean(contactsErrors.co_contact_number)}
                                    label="Contact Number*"
                                    type="number" className="form-control" name="co_contact_number" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField variant="outlined" inputRef={co_designation}
                                    {...isRequired !== false &&
                                    {
                                        ...contactsRegister("co_designation", {
                                            required: {
                                                value: true,
                                                message: "Designation is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Designation must be at least 3 characters"
                                            }
                                        })
                                    }
                                    }
                                    helperText={contactsErrors.co_designation ? contactsErrors.co_designation.message : ''}
                                    error={Boolean(contactsErrors.co_designation)}
                                    label="Designation*"
                                    name="co_designation" type="text" className="form-control" />

                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" inputRef={co_department}
                                    {...isRequired !== false &&
                                        {
                                            ...contactsRegister("co_department", {
                                                required: {
                                                    value: true,
                                                    message: "Department is required"
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Department must be at least 3 characters"
                                                }
                                            })
                                        }
                                    }
                                    helperText={contactsErrors.co_department ? contactsErrors.co_department.message : ''}
                                    error={Boolean(contactsErrors.co_department)}
                                    label="Department*"
                                    type="text" className="form-control" name="co_department" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div >
                                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn">Submit</button>&nbsp;&nbsp;
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        
        </>
    );

});
const mapStateToProps = (state) => ({
    user : state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    newclients:(data) =>dispatch(addnewclientlist(data))
});
export default connect(mapStateToProps,mapDispatchToProps) (AddNewClient)


