
import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    organizationlistdata: [],
  accountupdate:[],
  accountlist:[],
  accountcolumn:[],
  getaccountdetails:[],
  getannualrevenuelists:[],
  columnsList:[]
}

const OrganizationSlice = createSlice({
    name: "Organizationlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },
         

   //CONTACT LIST REDUCERS START HERE

   organizationsuccess(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.organizationlistdata = action.payload;
    // toast.success("Organization list data") 
  },
  organizationFailure(state, action) {
    state.isFetching = false;
    state.error = false;
    state.errorPayload = null;
    state.organizationlistdata = [];
    toast.error("Error in Organization list") 
  },


  ViewaccountupdatedSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = action.payload;
    toast.success("Account updated") 
    state.showModal = false;
  },

  ViewaccountupdatedFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = [];
    toast.error("Error in Account updated") 
    state.showModal = true;
  },

  accountlistSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountlist = action.payload;
    // toast.success(" Account list updated") 
  },

  accountlistFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountlist = [];
    // toast.error("Error Account list updated") 
  },
  
  // get column 
  getaccountColumnsuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountcolumn = action.payload;
    // toast.error("Error Account list updated") 
  },
  getaccountColumnFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountcolumn = [];
    // toast.error("Error Account list updated") 
  },
  //get account details
  getaccountdetailsSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getaccountdetails = action.payload;
    // toast.error("Error Account list updated") 
  },
  getaccountdetailFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getaccountdetails =[];
    // toast.error("Error Account list updated") 
  },

  //get annual reneve
  getannualrevenueSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getannualrevenuelists = action.payload;
    // toast.error("Error Account list updated") 
  },
  getannualrevenueFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getannualrevenuelists = [];
    // toast.error("Error Account list updated") 
  },
  opportunutiesColumnsListSuccess(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.columnsList = action.payload
},
opportunutiesColumnsListFailure(state, action){
    state.isFetch = false
    state.error = true
    state.errorPayload = action.payload
    state.columnsList = []
},
updateColumnsList(state, action){
  state.columnsList = action.payload
}

    }  
});
export const OrganizationActions = OrganizationSlice.actions;


//ORGANIZATION LIST START HERE

export const getorganizationlist = (updatedData) => {
    return async (dispatch) => {
      dispatch(OrganizationActions.loaderStart("meetingLoader"));
      try {
        var userData = JSON.parse(localStorage.getItem("user"))
        await Api.get("/accounts/" + userData?.user?._id).then((res) => {
          if (res.data.status === "success") {
            dispatch(OrganizationActions.organizationsuccess(res?.data));
          }
          if (res.data.status === "failure") {
            dispatch(OrganizationActions.organizationFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(OrganizationActions.organizationFailure("Network Error"));
      }
    };
  };

  
  export const Viewaccountupdated = (data) => {
    return async (dispatch) => {
      dispatch(OrganizationActions.loaderStart("meetingLoader"));
      try {
        await Api.post("accounts/update", data).then((res) => {
          if (res.data.status === "success") {
            dispatch(OrganizationActions.ViewaccountupdatedSuccess(res.data));
            dispatch(getorganizationlist())
          }
          if (res.data.status === "failure") {
            dispatch(OrganizationActions.ViewaccountupdatedFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(OrganizationActions.ViewaccountupdatedFailure("Network Error"));
      }
    };
  };
  
  export const getaccountsdetails = (data) => {
    return async(dispatch) => {
        dispatch(OrganizationActions.loaderStart("meetingloader"))
  
        try {
            await Api.get("/accounts/details/",data).then((res) => {
                if(res.data.status === "success"){
                dispatch(OrganizationActions.accountlistSuccess(res.data));
                }
                if(res.data.status ==="failure") {
                    dispatch(OrganizationActions.accountlistFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(OrganizationActions.accountlistFailure("Network Error"));
        }
    }
  }
  
  export const getaccountColumnListHandle = (userid) => {
    return async (dispatch) => {
      dispatch(OrganizationActions.loaderStart("meetingLoader"));
      try {
            await Api.get(`/columns-hide-show/list/accounts/${userid}`).then((res) => {
          if (res.data.status === "success") {
            dispatch(OrganizationActions.getaccountColumnsuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(OrganizationActions.getaccountColumnFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(OrganizationActions.getaccountColumnFailure("Network Error"));
      }
    };
  };

  export const getaccountdetailsHandle = (userid) => {
    return async (dispatch) => {
      dispatch(OrganizationActions.loaderStart("meetingLoader"));
      try {
            await Api.get(`/accounts/details/${userid}`).then((res) => {
          if (res.data.status === "success") {
            dispatch(OrganizationActions.getaccountdetailsSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(OrganizationActions.getaccountdetailFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(OrganizationActions.getaccountdetailFailure("Network Error"));
      }
    };
  };



  export const getannualrevenue = () => {
    return async (dispatch) => {
      dispatch(OrganizationActions.loaderStart("meetingLoader"));
      try {
            await Api.get("/annual-revenue-range/search").then((res) => {
          if (res.data.status === "success") {
            dispatch(OrganizationActions.getannualrevenueSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(OrganizationActions.getannualrevenueFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(OrganizationActions.getannualrevenueFailure("Network Error"));
      }
    };
  };

  export const getColumnsList = () => {
    return async (dispatch) => {
        dispatch(OrganizationActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/accounts/" + userData?.user?._id).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(OrganizationActions.opportunutiesColumnsListSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(OrganizationActions.opportunutiesColumnsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(OrganizationActions.opportunutiesColumnsListFailure("Network Error"));
        }
    }
  }
  
  export default OrganizationSlice.reducer;