import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import Select from 'react-select';
import { components } from "react-select";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Badge from 'react-bootstrap/Badge'
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import moment from "moment";
import {SelectYear, ProductFamilyFilter, QuartersFilter, MonthlyFilter,ProductsFilter, SessionFilter,CourseFilter,  config} from './chartConfig'
// import { useSelector } from 'react-redux';
import {getProductsList, getProductFamilyList, fetchTechiesList, fetchQueriesClosedChart} from "../../store/dashboard";
import { useDispatch, useSelector, connect } from "react-redux";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'

const PlotlyComponent = createPlotlyComponent(Plotly);
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

  const QueriesClosedReport = React.memo(({fetchQueriesClosedChartData, fetchTechiesListData,getProductsListData,getProductFamilyListData,  techiesList, productsList, productFamilyList, yearsList, queriesClosedChartData,QueriesclosedLoader}) => {
  // const [techieList, setTechieList] = useState([]);
  // const [productsList, setProductsList] = useState([]);
  // const [requestedbyList, setRequestedbyList] = useState([]);

  const [filterTechieList, setFilterTechieList] = useState([]);
  const [filterProductsList, setFilterProductsList] = useState([]);
  const [filterRequestedbyList, setFilterRequestedbyList] = useState([]);
  const [filterProductFamilyList, setFilterProductFamilyList] = useState();

  console.log("queriesclosedreportnew")

  // const [tableHeight, setTableHeight] = useState("310px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  // const [productFamilyList, setProductFamilyList] = useState([]);

  // const years = [{ value: 2021, label: 2021 }, { value: 2022, label: 2022 }, { value: 2023, label: 2023 }, { value: 2024, label: 2024 }]

  var  monthsList = [{ label: "All", value: "All" }, { label: 'January', value: 'January' }, { label: 'February', value: "February" }, { label: 'March', value: "March" }, { label: 'April', value: "April" }, { label: 'May', value: "May" }, { label: 'June', value: "June" }, { label: 'July', value: "July" }, { label: 'August', value: "August" }, { label: 'September', value: "September" }, { label: 'October', value: "October" }, { label: 'November', value: "November" }, { label: 'December', value: "December" }]
  const quartersList = [{ value: "all", label: "Annual" }, { value: 'Q1', label: 'Q1' }, { value: 'Q2', label: "Q2" }, { value: 'Q3', label: 'Q3' }, { value: 'Q4', label: 'Q4' }]
  

  const [filter, setFilter] = useState({});

  var defaultMonth = { label: "All", value: false };
  
  var deYear = moment().format('YY');
  var deYearName = moment().format('YYYY')
  var defaultYear = {value: parseInt(deYear), label:deYearName}

  const currentYear = moment().year();
  // console.log("currentyear",currentYear)

  // const [tableData, setTableData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const handle = useFullScreenHandle();
  // var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const {user} = useSelector(state => state.auth)
  const userData = user.user;

  useMemo(() => {
    fetchTechiesListData();
    getProductsListData();
    getProductFamilyListData();
  }, [])

  const chartButtonClicked = async () => {
    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }
  const getQueriesChartData = async () => {
    
  var yearvalue="";
  let quatervalue;
  let monthvalue;

  // const [data, setData] = useState([]);
  var techiesLists = filterTechieList.map(item => {
    return item.value;
  });
  if(filter.year){
    yearvalue = filter.year.value
  }
  var products_list = filterProductsList.map(item => {
    return item.value;
  });

  var requestedby_list = filterRequestedbyList.map(item => {
    return item.value;
  });

  if(!filter.from_date && !filter.to_date){
    filter.from_date = moment().startOf('month').format("YYYY-MM-DD")
    filter.to_date = moment().endOf('month').format("YYYY-MM-DD")
   }

   if(filter.year || filter.month || filter.quarter){
    delete filter.from_date;
    delete filter.to_date;
   }

     
// Quater //
  if (filter?.quarter === "Q1") {
    quatervalue = 1;
  } else if (filter?.quarter === "Q2") {
    quatervalue = 2;
  } else if (filter?.quarter === "Q3") {
    quatervalue = 3;
  } else if (filter?.quarter === "Q4") {
    quatervalue = 4;
  } else {
    quatervalue = null;
  }
// Months//
    if (filter?.month === "All") {
      monthvalue = false;
    }if (filter?.month === "January") {
    monthvalue = 1;
  } else if (filter?.month === "February") {
    monthvalue = 2;
  } else if (filter?.month === "March") {
    monthvalue = 3;
  } else if (filter?.month === "April") {
    monthvalue = 4;
  }else if (filter?.month === "May") {
    monthvalue = 5;
  }else if (filter?.month === "June") {
    monthvalue = 6;
  }else if (filter?.month === "July") {
    monthvalue = 7;
  }else if (filter?.month === "August") {
    monthvalue = 8;
  }else if (filter?.month === "September") {
    monthvalue = 9;
  } else if (filter?.month === "October") {
    monthvalue = 10;
  }else if (filter?.month === "November") {
    monthvalue = 11;
  } else if (filter?.month === "December") {
    monthvalue = 12;
  } else {
    monthvalue = null;
  }

    // var filtersObj = {
    //   techiesList: techiesLists,
    //   products_list: products_list,
    //   requestedby_list: requestedby_list,
    //   product_family :  filter.product_family?.value,
    //   year : yearvalue,
    //   month : monthvalue,
    //   quarter : quatervalue,
    //   from_date: filter.from_date,
    //   to_date: filter.to_date,

    // }
    const filteredData={
      year : yearvalue?yearvalue:currentYear,
      month : monthvalue,
      quarter : quatervalue,
      products_list: products_list,
      techiesList: techiesLists,
      requestedby_list: requestedby_list,
      product_family :  filter.product_family?.value,
    }


    fetchQueriesClosedChartData([filteredData,currentYear])
    // const res = await axios.post("", )
    // if (res.data.status === "success") {
    //   setData(res.data.data);

    // }
  }

  useMemo(() => {
    
    getQueriesChartData();
  }, [filterTechieList, filterProductsList, filterRequestedbyList,filterProductFamilyList,filter]);

  
  
  const reportChange = (state, handle) => {
    const queriesClosedChartParent = document.getElementById("queriesClosedChartComponents");
    const queriesClosedChart = queriesClosedChartParent?.getElementsByClassName("user-select-none")[0];
  
    if (queriesClosedChart) {
      queriesClosedChart.id = "queriesClosedChat";
  
      if (state) {
        const chartElement = document.getElementById("queriesClosedChart");
        if (chartElement) {
          chartElement.style.height = (window.innerHeight - 60).toString() + 'px';
        }
  
        setColumnsDisplay(false);
      } else {
        const chartElement = document.getElementById("queriesClosedChart");
        if (chartElement) {
          chartElement.style.height = "450px";
        }
  
        setColumnsDisplay(true);
      }
    }
  };
  // const removeYearProductListBadge = (value) => {
  //   var inputValue = value.target.getAttribute('value');
  //   console.log("yearbadge1",inputValue)
  //   var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
  //   setFilter({...filter, year: Yearfilter})
  // }
  const removeYearProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    // var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
    var Yearfilter = filter.year.value.toString() === inputValue.toString() ? "" : filter.year.value;
    setFilter({...filter, year: Yearfilter})
  }
  const removequatervalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var updatedQuarter = filter.quarter === inputValue ? "" : filter.quarter;
    setFilter({ ...filter, quarter: updatedQuarter });
  }
  
  const removeMonthrvalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadgemonth", inputValue);
    var updatedMonth = filter.month === inputValue ? "" : filter.month;
    setFilter({ ...filter, month: updatedMonth });
  }

  const removeFilterTechieListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterTechieLists = filterTechieList.filter(item => item.value !== inputValue);
    setFilterTechieList(filterTechieLists);
  }

  const removeFilterProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterProductsLists = filterProductsList.filter(item => item.value !== inputValue);
    setFilterProductsList(filterProductsLists);
  }

  const removeFilterRequestedListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');

    const filterRequestedbyLists = filterRequestedbyList.filter(item => item.value !== inputValue);
    setFilterRequestedbyList(filterRequestedbyLists);
  }
  const removeFilterproduct_familyTypeBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var filterproduct_familyLists = filter.product_family.value === inputValue ? "" : filter.product_family.value;
    setFilter({ ...filter, product_family: filterproduct_familyLists });
  }

  const layout = {
    paper_bgcolor: '#fff ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    margin: {

    },
    title: "Queries Closed Report (Tickets)",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }

  const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'QueriesClosedchart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:",error)
});

}

 
const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};


  return (
    <>
    {QueriesclosedLoader ? 
      <div className="parent-div">
      <div className="loader"></div>
    </div>
    :
    <> 
    <div ref={containerRef}>  
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >

        <div className="container-fluid py-2 px-2">
          <div className="row" style={{margin:'0px', padding:'0px'}}>

            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter">
                <div className="col-md-1 col-lg-1">
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1">
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                {(userData?.user_role_id?.role_name !== "techie") ?
                <div className="col-md-2 col-lg-2" >
                   <SelectYear defaultYear = {defaultYear} years={yearsList}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                </div>
                : ""}

                <div className="col-md-2 col-lg-2">
                <QuartersFilter values={filter.quarter} quartersList={quartersList}  disableQuarterFilter={false} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
                </div>

                <div className="col-md-2 col-lg-2" >
                <MonthlyFilter defaultMonth={defaultMonth} values={filter.month} monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilter({ ...filter, month: value }) } />
                </div>

                {(userData?.user_role_id?.role_name !== "techie") ?
                <div className="col-md-2 col-lg-2" >
                  <div >
                   
                      <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterTechieList}
                        options={techiesList}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}
                        placeholder="Techie List"
                        name="techiesList"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterTechieList(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                     
                  </div>
                </div>
                : ""}

                {(userData?.user_role_id?.role_name !== "techie") ?
                <div className="col-md-2 col-lg-2" >
                  <div >
                   
                      <Select
                        style={{
                          position: 'relative',
                          width: '200px',
                          height: '50px',
                          overflowX: 'hidden',
                          overflowY: 'hidden'
                        }}
                        isMulti
                        value={filterProductsList}
                        options={productsList}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        controlShouldRenderValue={false}
                        classNamePrefix="select"
                        isSearchable="true"
                        allowSelectAll={true}

                        placeholder="Products"
                        name="productsList"
                        components={{
                          Option
                        }}
                        onChange={value => setFilterProductsList(value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                     
                  </div>
                </div>
                : ""}
                <br/>
                <br/>
                <div  className="col-md-2 col-lg-2" >
                <ProductFamilyFilter values={filter.product_family}  selectSingle ={true} placeholder={"Product Family"} productFamilyList={productFamilyList} disableCategoryFilter={false}  updateFilter={ (value) => setFilter({ ...filter, product_family: value }) } />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
        
          {
            filter.year ?
            filter.year && 
              <Badge onClick={removeYearProductListBadge}
              pill data={filter.year.value} value={filter.year.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter?.year?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          
          :
          <Badge 
          pill data={currentYear} value={currentYear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentYear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }
          {filter.quarter && 
            <Badge onClick={removequatervalueBadge}
                pill data={filter.quarter} value={filter.quarter} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.quarter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }

          {
            filter.month&& 
            <Badge onClick={removeMonthrvalueBadge}
                pill data={filter.month} value={filter.month} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.month}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }

            {
              filterTechieList.length > 0 ?
                filterTechieList.map((filter) => (
                  <Badge onClick={removeFilterTechieListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterProductsList.length > 0 ?
                filterProductsList.map((filter) => (
                  <Badge onClick={removeFilterProductListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }

            {
              filterRequestedbyList.length > 0 ?
                filterRequestedbyList.map((filter) => (
                  <Badge onClick={removeFilterRequestedListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
            {
              filter.product_family && 
              <Badge onClick={removeFilterproduct_familyTypeBadge}
                  pill data={filter.product_family?.value} value={filter.product_family?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.product_family?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }
          </div>
        </div>

        {showChart ?
          <PlotlyComponent
            id="queriesClosedChartComponents"
            data={queriesClosedChartData}
            layout={layout}
            className="w-full h-full"
            config={config}
          />
          : <React.Fragment>
          </React.Fragment>}
      </FullScreen>
      </div>
      </>
    }
    </>
  )
});

const mapStateToProps = (state) => ({
  techiesList : state.dashboard.techiesList,
  productsList : state.dashboard.productsList,
  productFamilyList : state.dashboard.productFamilyList,
  yearsList : state.dashboard.yearsList,
  queriesClosedChartData : state.dashboard.queriesClosedChartData,
  QueriesclosedLoader : state.dashboard.QueriesclosedLoader
});

const mapDispatchToProps = (dispatch) => ({
  fetchQueriesClosedChartData: (data) => dispatch(fetchQueriesClosedChart(data)),
  fetchTechiesListData : () => dispatch(fetchTechiesList()),
  getProductsListData : () => dispatch(getProductsList()),
  getProductFamilyListData : () => dispatch(getProductFamilyList()),

});

export default connect(mapStateToProps, mapDispatchToProps)(QueriesClosedReport);
