import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import axios from "axios";
import MUIDataTable from "mui-datatables";
import ButtonMaterialUi from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { getMuiTheme } from '../commonColumns/commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import Badge from 'react-bootstrap/Badge'
const EmailCampaignContacts = (props) => {
    const {user} = useSelector(state => state.auth)
    const userData = user.user;
    const { register: contactsRegister, handleSubmit: contactshandleSubmit, setError: setError, formState: { errors: contactsErrors }, reset: contactsReset } = useForm();
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [modalHide, setModalHide] = useState("showopportunitiesmodal");
    const [ContactsShow, setContactsShow] = useState(false);
    const co_company_name = useRef();
    const co_contact_name = useRef();
    const co_email = useRef();
    const co_contact_number = useRef();
    const co_designation = useRef();
    const co_department = useRef(); 
    const values = [true,'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [filters, setFilters] = useState({})
    const [tableFilters, setTableFilters] = useState({})
    const [tableData, setTableData] = useState([])
    const[contactListType,setContactListType] =useState([])
console.log("tabel filters are",tableFilters)
    const getContactsList = async() => {
        const res = await axios.post('/contacts/filters/data', tableFilters)
        if(res.data.status === "success"){                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
            setTableData(res.data.contacts)
        }else{                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
            setTableData([])
        }
        
    }

const contactListHandl = () => {
  setContactListType([{label:"Customers List",value:"Customers List"},{label:"Lead & Customers List",value:"Lead & Customers List"},{label:"RawData List",value:"RawData List"}])
}
    const getAllFilters = async() => {
        const res = await axios.get('contacts/filters/list');
        setFilters(res.data)
    }

    useEffect(() => {
        getAllFilters()
        contactListHandl()
    }, [])

    useEffect(() => {
        
        getContactsList();
    }, [tableFilters])
  
    const handleClose = (event, reason) => {
        setAnchorEl(false);

        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const headerProps = () => ({
        style: {
          whiteSpace: "nowrap",
          background: "#e6e6e6",
          fontWeight: 'bold'
        }
      })
      
      const columns = [
        {
            name: "_id",
            label: "",
            options: {
              filter: false,
              display: false,
            }
        },
        {
          name: "company_name",
          label: "Organization Name",
          options: {
            filter: true,
            display: true,
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                left: "0",
                background: "white",
                zIndex: 100,
                color: '#243d6a',
                fontWeight: 'bold',
                maxWidth: '500px',
                overflow: 'auto',
              }
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                left: 0,
                background: "#e6e6e6",
                zIndex: 101,
                maxWidth: '500px',
                overflow: 'auto',
              }
            })
          }
        },
        {
          name: "contact_name",
          label: "Contact Name",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        },
        {
          name: "contact_number",
          label: "Contact Number",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        },
        {
          name: "designation",
          label: "Designation",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        },
        {
          name: "department",
          label: "Department",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        },
        {
          name: "industry",
          label: "Industry",
          options: {
            filter: true,
            display: true,
            setCellHeaderProps: headerProps
          }
        }    
      ];


      const handleRowClick = (rowData, rowMeta) => {
      };


    const uploadEmailContacts = (data) => {
        props.emailContacts(data)
        props.onHide()
        
    }
    
    const options = {
        textLabels: {
          body: {
            noMatch: "No Contacts Found"
          }
        },
        selectableRows:true,
        customToolbarSelect: (selectedRows, displayData) => {
            var lead_ids_data = selectedRows.data.map(item1 => {
              return displayData[item1.index]?.data[0];
            })
            
            return (
              <div className="assing_to_isales">
                <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => uploadEmailContacts(lead_ids_data)} >Submit</Button>
              </div>
            )
          },
        // rowsPerPage: window.innerHeight > 720 ? 20 : 15,
        rowsPerPage: 20, // or any other value you prefer
        rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
        filter: true,
        filterType: "dropdown",
        responsive: "simple",
        tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
        tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
        fixedHeader: true,
        fixedSelectColumn: false,
        selectableRows: true,
        selectableRowsHeader: true,
        download: false,
        print: false,
        onRowClick: handleRowClick,
        onDownload: false
      };

      // const tableFiltersChanged = (data,values) => {
      // // console.log("values and data are",data)
      // //    console.log("values and data are",values)
      //   var dataArr =[]
      //   data.map(item => {
      //       dataArr.push(item.value)
      //   })
      //   let itemName = values.name
        
      //   setTableFilters({...tableFilters, [itemName] : dataArr })


      // }
const tableFiltersChanged = (data, values) => {
  console.log("values are and Data are",data)
  console.log("values are and Data are",values)
    let dataArr = [];

    if (Array?.isArray(data)) {
        // If data is an array, extract values from the 'value' property of each item
        dataArr = data?.map(item => item?.value);
    } else if (typeof data === 'object' && data !== null && data?.value) {
        // If data is an object with 'value' property, directly add its 'value' to the array
        dataArr = data?.value;
    }

    let itemName = values?.name;
    setTableFilters({ ...tableFilters, [itemName]: dataArr });
};


const removeFilterContactTypeBadge = (value) => {
  var inputValue = value.target.getAttribute('value');
  // console.log("yearbadge2", inputValue);
  var filtercontactList = tableFilters.contact_type === inputValue ? "" : tableFilters.contact_type;
  setTableFilters({ ...tableFilters, contact_type: filtercontactList });
}
    return (
        <>
            < Modal
                // fullscreen={fullscreen}
                // size="lg"
                show={props.show}
                onHide={() => setContactsShow(false)}
                backdrop={false}
                disableBackdrop={true}
                className={modalHide}
                dialogClassName="custom-smaller-modal"
                aria-labelledby="example-custom-modal-styling-title"
                // backdrop={false}
                // disableBackdrop={true}
                // className={modalHide}
                // dialogClassName="modal-100w"
                // arialabelledby="example-custom-modal-styling-title"
                >
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                        Select Contacts
                    </Modal.Title>
                    <a onClick={props.onHide} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    <form key={2}>
                            <div class="row">
                                <div class="col-md-4 ">

                                <Select
                                    closeMenuOnSelect={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={true}
                                    options={filters.industriesList}
                                    placeholder="Search Industry"
                                    name="industry"
                                    defaultValue="+91"
                                    isMulti
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </div>

                                <div class="col-md-4 ">

                                <Select
                                    closeMenuOnSelect={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    // options={filters.designationList}
                                    // options={filters.designationList?.filter(item => item.label !== ""  && item.value !== "")}
                                    options={filters.designationList?.filter(item => item && item.label !== null && item.label !== undefined && item.label !== "" && item.value !== null && item.value !== undefined && item.value !== "")}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={true}
                                    placeholder="Search Designation"
                                    name="designation"
                                    defaultValue="+91"
                                    isMulti
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </div>

                                <div class="col-md-4 ">

                                <Select
                                    closeMenuOnSelect={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    // options={filters.departmentsList?.filter(item => item.label?.trim() !== "" && item.value?.trim() !== "")}
                                    options={filters.departmentsList?.filter(item => item && item.label !== null && item.label !== undefined && item.label !== "" && item.value !== null && item.value !== undefined && item.value !== "")}
                                    // options={filters.departmentsList}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={true}
                                    isMulti
                                    placeholder="Search Department"
                                    name="department"
                                    defaultValue="+91"
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </div>

                            </div>
                            <br />
                        <div class="row">
                            <div class="col-md-4">

                                <Select
                                    closeMenuOnSelect={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={filters.citiesList?.filter(item => item && item.label !== null && item.label !== undefined && item.label !== "" && item.value !== null && item.value !== undefined && item.value !== "")}
                                    // options={filters.citiesList}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={true}
                                    placeholder="Search City"
                                    name="city"
                                    isMulti
                                    defaultValue="+91"
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                               
                            </div>

                            <div class="col-md-4">

                                <Select
                                    closeMenuOnSelect={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    // options={filters.statesList}
                                    options={filters.statesList?.filter(item => item && item.label !== null && item.label !== undefined && item.label !== "" && item.value !== null && item.value !== undefined && item.value !== "")}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={true}
                                    placeholder="Search State"
                                    name="state"
                                    isMulti
                                    defaultValue="+91"
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                               
                            </div>
                            <div class="col-md-4">
                              <Select
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={contactListType}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    allowSelectAll={false}
                                    placeholder="Contacts Type"
                                    // value={{label:tableFilters?.contact_type,value:tableFilters?.contact_type}}
                                    name="contact_type"
                                    // isMulti
                                    defaultValue="+91"
                                    onChange={tableFiltersChanged}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            {/* <TextField variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    select
                                    onChange={() => tableFiltersChanged()}
                                    required
                                    type="text" className="form-control" name="contact_list" label="Conatct List"
                                    >
                                    {contactListType?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                        ))
                                    }
                                </TextField> */}

                               
                            </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-6">
                          {
                          tableFilters.contact_type && 
                          <Badge onClick={removeFilterContactTypeBadge}
                              pill data={tableFilters.contact_type} value={tableFilters.contact_type} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{tableFilters.contact_type}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                        }
                          </div>
                        </div>
                        <div className="row">
                        <React.Fragment>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title="Contacts List"
                                    data={tableData}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </React.Fragment>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
            <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}


export default EmailCampaignContacts;

