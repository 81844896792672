import Select from 'react-select';
import { components } from "react-select";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';

export const monthlyLayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Monthly overview chart (Tickets)",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },
}
export const lineChartlayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Monthly Pre Sales Chart",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },
}
export const lineChartPostlayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Monthly Post Sales Chart",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },
}
export const lineChartSaleslayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Sales PO Chart",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },

}
export const OpporunitiesSalesChart = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Sales Stages Chart",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },
    hovermode: 'sticky',
    clickmode: 'event+select'
}


export const quarterlyLayout = {
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    margin: {
    },
    title: "Quarterly Overview Chart (Tickets)",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },

}

export const singleLayout = {
    // stack
    barmode: 'overlay',
    paper_bgcolor: '#fff',
    font: {
        size: 12,
        color: '#6E8898'
    },
    yaxis: {
        tickformat: ' Cr',
        ticksuffix: ' Cr'
    },
    margin: {
    },
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    legend: { "orientation": "h" },

}

export const productTargetLayout = {
    title: "Techie Target vs Achieved",
    barmode: "stack",
    yaxis: {
        tickformat: ' Cr',
        ticksuffix: ' Cr'
    },
    xaxis: {
        domain: [0, 0.18],
        anchor: 'x1',
        title: '',
    },
    xaxis2: {
        domain: [0.23, 0.40],
        anchor: 'x2',
        title: '',
    },
    xaxis3: {
        domain: [0.43, 0.60],
        anchor: 'x3',
        title: '',
    },
    xaxis4: {
        domain: [0.63, 0.80],
        anchor: 'x4',
        title: '',
    },
    xaxis5: {
        domain: [0.83, 1.0],
        anchor: 'x5',
        title: '',
    },
    legend: { "orientation": "h" }
}

export const multipleLayout = {
    barmode: "stack",
    yaxis: {
        tickformat: ' Cr',
        ticksuffix: ' Cr'
    },
    xaxis: {
        domain: [0, 0.18],
        anchor: 'x1',
        title: 'Q1',
    },
    xaxis2: {
        domain: [0.23, 0.40],
        anchor: 'x2',
        title: 'Q2',
    },
    xaxis3: {
        domain: [0.43, 0.60],
        anchor: 'x3',
        title: 'Q3',
    },
    xaxis4: {
        domain: [0.63, 0.80],
        anchor: 'x4',
        title: 'Q4',
    },
    xaxis5: {
        domain: [0.83, 1.0],
        anchor: 'x5',
        title: 'Annual',
    }
}


export const regionalLayout = {
    title: "Regional - Sales Target vs Achieved ",
    barmode: "stack",
    yaxis: {
        tickformat: ' Cr',
        ticksuffix: ' Cr'
    },
    xaxis: {
        domain: [0, 0.18],
        anchor: 'South',
        title: 'South',
    },
    xaxis2: {
        domain: [0.23, 0.40],
        anchor: 'West',
        title: 'West',
    },
    xaxis3: {
        domain: [0.43, 0.60],
        anchor: 'North',
        title: 'North',
    },
    xaxis4: {
        domain: [0.63, 0.80],
        anchor: 'East & North',
        title: 'East & North',
    },
    xaxis5: {
        domain: [0.83, 1.0],
        anchor: 'A & D',
        title: 'A & D',
    },
    xaxis6: {
        domain: [0.83, 1.0],
        anchor: 'Academics',
        title: 'Academics',
    }
}



export const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'pan2d', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian']
}

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export const SelectYear = (props) => {


    return (

        <Select
            id="years"
            isMulti={props?.isMulti === true ? true : false}
            className="select_value"
            classNamePrefix="select"
            closeMenuOnSelect={props?.closeMenuOnSelect === true ? true : false}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isSearchable="false"
            defaultValue={props.defaultYear}
            allowSelectAll={false}
            options={props.years}
            placeholder="Year"
            name="filterSalesStage"
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableYearFilter === false ? props.disableYearFilter : true}
            required
        />







        //     <Select
        //     style={{
        //         position: 'relative',
        //         width: '200px',
        //         height: '50px',
        //         overflowX: 'hidden',
        //         overflowY: 'hidden'
        //     }}
        //     isMulti={false}
        //     value={props.values}
        //     options={props.years}
        //     closeMenuOnSelect={false}
        //     hideSelectedOptions={true}
        //     controlShouldRenderValue={false}
        //     classNamePrefix="select"
        //     isSearchable="true"
        //     allowSelectAll={false}
        //     placeholder="Years"
        //     name="years"
        //     components={{
        //         Option
        //     }}
        //     onChange = {value => props.updateFilter(value)}
        //     InputLabelProps={{
        //         shrink: true,
        //     }}
        //     required
        // />

    )
}

/* export const SelectYearforSales = (props) => {
    

    return (

            <Select
                id="years"
                className="select_value"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                controlShouldRenderValue={true}
                isSearchable="false"
                defaultValue={props.defaultYear}
                allowSelectAll={false}
                options={props.years}
                placeholder="Year"
                name="filterSalesStage"
                onChange = {value => props.updateFilter(value.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                isDisabled={props.disableYearFilter === false ? props.disableYearFilter : true}
                required
            />
    )
} */
export const AccountMangers = (props) => {

    return (

        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti={props.selectSingle ? false : true}
            value={props.values}
            options={props.TechieList ? props.TechieList : props.accountManagers}
            closeMenuOnSelect={props.selectSingle ? true : false}
            hideSelectedOptions={props.selectSingle ? false : true}
            controlShouldRenderValue={props.selectSingle ? true : false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}

            placeholder={props.placeholder}
            name="productsList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            //  onChange={value => props.updateFilter(value ? value.map(item => item.value) : [])} 
            InputLabelProps={{
                shrink: true,
            }}
            required
        />


    )
}

// <Select
//     className="select_value"
//     classNamePrefix="select"
//     closeMenuOnSelect={true}
//     hideSelectedOptions={true}
//     controlShouldRenderValue={true}
//     isMulti={true}
//     isSearchable="true"
//     options={props.accountManagers}
//     allowSelectAll={false}
//     placeholder={props.placeholder}
//     name="filterMonth"
//     // onChange = {value => props.updateFilter(value.value)}
//     onChange={value => props.updateFilter(value ? value.map(item => item.value) : [])} 
//     InputLabelProps={{
//         shrink: true,
//     }}
//     isDisabled={props.disableAccountManagerFilter === false ? props.disableAccountManagerFilter : true}
//     required
// />


export const AccountMangersSingle = (props) => {

    return (

        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti={props.selectSingle ? false : true}
            value={props.values}
            options={props.productFamilyList}
            closeMenuOnSelect={props.selectSingle ? true : false}
            hideSelectedOptions={props.selectSingle ? false : true}
            controlShouldRenderValue={props.selectSingle ? true : false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={props.placeholder}
            name="techielist"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />

        // <Select
        //     className="select_value"
        //     classNamePrefix="select"
        //     closeMenuOnSelect={true}
        //     hideSelectedOptions={true}
        //     controlShouldRenderValue={true}
        //     isMulti={false}
        //     isSearchable="false"
        //     options={props.accountManagers}
        //     allowSelectAll={false}
        //     placeholder={props.placeholder}
        //     name="filterMonth"
        //     onChange={value => props.updateFilter(value ? value.value : null)} 
        //     InputLabelProps={{
        //         shrink: true,
        //     }}
        //     isDisabled={props.disableAccountManagerFilter === false ? props.disableAccountManagerFilter : true}
        //     required
        // />
    )
}

export const QuartersFilter = (props) => {
    return (
        <Select
            className="select_value"
            classNamePrefix="select"
            id="quarters"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isSearchable="false"
            allowSelectAll={false}
            options={props.quartersList}
            placeholder="Quarter"
            name="filterSalesStage"
            onChange={value => props.updateFilter(value.value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableQuarterFilter === false ? props.disableQuarterFilter : true}
            required
        />
    )
}

export const MonthlyFilter = (props) => {

    // Determine the placeholder based on whether values are present
    const placeholderText = props.values ? "Select a Month" : "Months";

    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            value={props.values ? { label: props.values, value: props.values } : null}
            options={props.monthsList}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            defaultValue={props.defaultMonth}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={placeholderText}
            name="filterMonth"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value.value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableMonthFilter === false ? props.disableMonthFilter : true}
            required
        />
    )
}

export const SessionFilter = (props) => {
    return (

        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti
            value={props.values}
            options={props.sessionsList}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder="Session Type"
            name="productsList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />

    )
}

export const CourseFilter = (props) => {

    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti
            value={props.values}
            options={props.courseTypeList}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder="Course Type"
            name="courseTypeList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />
    )
}

export const ProductsFilter = (props) => {


    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti
            value={props.values}
            options={props.productsList}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}

            placeholder="Products"
            name="productsList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />
    )
}

const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
};

export const StartDate = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className="form-control"
                label="From Date"
                inputVariant="outlined"
                value={props.value}
                onChange={(date) => props.updateFilter(moment(date).format('YYYY-MM-DD'))}
                labelFunc={formatWeekSelectLabel}
                showTodayButton
                InputLabelProps={{
                    shrink: true,
                }}
            />

        </MuiPickersUtilsProvider>
    )
}

export const EndDate = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className="form-control"
                label="To Date"
                inputVariant="outlined"
                value={props.value}
                onChange={(date) => props.updateFilter(moment(date).format('YYYY-MM-DD'))}
                labelFunc={formatWeekSelectLabel}
                showTodayButton
                InputLabelProps={{
                    shrink: true,
                }}
            />

        </MuiPickersUtilsProvider>
    )
}

export const CategoriesList = (props) => {
    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            defaultValue={props.defaultValue}
            options={props.categoriesList}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={props.placeholder}
            name="courseTypeList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value.value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />
    )
}

export const ProductFamilyFilter = (props) => {

    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti={props.selectSingle ? false : true}
            value={props.values}
            options={props.productFamilyList}
            closeMenuOnSelect={props.selectSingle ? true : false}
            hideSelectedOptions={props.selectSingle ? false : true}
            controlShouldRenderValue={props.selectSingle ? true : false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={props.placeholder}
            name="productsList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            required
        />
    )
}

export const UsersListFilter = (props) => {

    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            isMulti={props.selectSingle ? false : true}
            value={props.values}
            options={props.productFamilyList}
            closeMenuOnSelect={props.selectSingle ? true : false}
            hideSelectedOptions={props.selectSingle ? false : true}
            controlShouldRenderValue={props.selectSingle ? true : false}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={props.placeholder}
            name="productsList"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.isDisabled === true ? true : false}
            required
        />
    )
}


export const QuartersFilterMultiSelect = (props) => {
    return (
        <Select
            className="select_value"
            classNamePrefix="select"
            id="quarters"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            controlShouldRenderValue={true}
            isSearchable="false"
            allowSelectAll={false}
            options={props.quartersList}
            placeholder="Quarter"
            name="filterSalesStage"
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableQuarterFilter === false ? props.disableQuarterFilter : true}
            required
            isMulti={props.isMulti}
        />
    )
}

// 

export const MonthlyFilterMultiSelect = (props) => {
    // Determine the placeholder based on whether values are present
    const placeholderText = props.values ? "Select a Month" : "Months";
    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            // value={props.values ? { label: props.values, value: props.values } : null}
            options={props.monthsList}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            defaultValue={props.defaultMonth}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder={placeholderText}
            name="filterMonth"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableMonthFilter === false ? props.disableMonthFilter : true}
            required
            isMulti={props.isMulti}
        />
    )
}


export const UsersMultiSelect = (props) => {
    
    return (
        <Select
            style={{
                position: 'relative',
                width: '200px',
                height: '50px',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            // value={props.values ? { label: props.values, value: props.values } : null}
            options={props.usersList}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            // defaultValue={props.defaultMonth}
            classNamePrefix="select"
            isSearchable="true"
            allowSelectAll={true}
            placeholder="Select Users"
            name="filterMonth"
            components={{
                Option
            }}
            onChange={value => props.updateFilter(value)}
            InputLabelProps={{
                shrink: true,
            }}
            isDisabled={props.disableUserSelectFilter === false ? props.disableUserSelectFilter : true}
            required
            isMulti={props.isMulti}
        />
    )
}