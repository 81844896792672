import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../home/Navbar';
import axios from "axios"
import { Button } from 'react-bootstrap';
import HardwareModal from '../../components/pageModals/HardwareModal';
import HardwareTable from '../../components/commonColumns/HardwareTable';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { gethardwarelist,HardwareActions}from "../../store/Hardware"
import { useDispatch, useSelector } from 'react-redux';


const  Hardware = React.memo(({gethardwarelist,user,hardwaremodal}) => {
 

  const dispatch = useDispatch();
  
  const userData = user.user;

  const fetchData = async () => {
    const userid = {
     user_id:userData?._id
 
 
 
    }
    gethardwarelist(userid)
 
   }
 
   useMemo(() => {
 
     fetchData()
   },[])
 

  return (
    <>
    <Navbar value="hardware" className="activity_list_navbar" />
    <div className="main_demo_section d-flex flex-column">
        <div className="request_teche my-3" >
            <>
              <Button className="btn-sm navbarAddBtn btn_add_common"  onClick={() => dispatch(HardwareActions.openModal())}>Create</Button>
              {hardwaremodal ? (
                <HardwareModal
                refreshPayslipData={() => fetchData()}
                show={hardwaremodal}
                onHide={() => dispatch(HardwareActions.closeModal())}
                
                />
              ) : null}

            </>
        </div>
        <div className="hardware_table">
          <HardwareTable/>
        </div>
      </div>
    </>
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  hardwaremodal :state.Hardwarelist.showModal
});
const mapDispatchToProps = (dispatch) => ({
  gethardwarelist : (data) => dispatch(gethardwarelist(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(Hardware)